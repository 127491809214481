import {
  BoxBufferGeometry,
  BufferGeometry,
  Color,
  Geometry,
  Group,
  LineSegments,
  Material,
  Matrix4,
  Mesh,
  MeshLambertMaterial,
  Object3D,
  Plane,
  Vector3,
} from "three";
import { GEOMETRY_CATEGORY, GEOMETRY_TYPE } from "src/app/app.config";
import { Util } from "../utils";
// import { AppComponent } from "../../app.component";
import {
  BUILDING_SIDE,
  EXISTING_BUILDING_CONFIG as CONST,
  CUTOUT_ENABLE,
  EXISTING_BUILDING_CONFIG,
  FIT_FLYOVER_BRAKET_ON_ROOF,
  PANEL_DIRECTION,
  RAKECUT_TYPE,
  RIBSPAN_BARGE_FIT,
  RIBSPAN_PANEL_WIDTH,
} from "src/app/app.constants";
import HighLightBox from "src/app-ribspan/models/HighlightBox";
import { CSG } from "three-csg-ts";
import { PatiosFlyOverManager } from ".";
import { HomeComponent as AppComponent } from "../../containers/home/home.component";
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import {
  GeometryInfo,
  LineType,
  Print2DView,
  Printing2DGeometry,
  Printing2DLine,
  ViewType,
} from "src/app/core/models";
import { UI } from "../ui";

export class RoofManager {
  private scene: Group;
  private APP: AppComponent;
  private MANAGER: PatiosFlyOverManager;
  private material: Material;
  private utils: Util;

  private geometryManager: GeometryManager;

  private geo_RoofPanel: GeometryInfo;
  private gutterRakecutLeftInfo: GeometryInfo;
  private gutterRakecutRightInfo: GeometryInfo;
  private geo_roofPanelBargeCappingLeft: GeometryInfo;
  private geo_roofPanelBargeCappingRight: GeometryInfo;
  private geo_roofPanelBargeCappingBack: GeometryInfo;
  private geo_bargeCutoutLeftHor: GeometryInfo;
  private geo_bargeCutoutRightHor: GeometryInfo;
  private geo_gutterBack: GeometryInfo;
  private geo_gutterCap: GeometryInfo;

  private geo_rakecutLeft: BoxBufferGeometry;
  private geo_rakecutRight: BoxBufferGeometry;
  private mesh_rakecutLeft: Mesh;
  private mesh_rakecutRight: Mesh;
  private csg_rakecutLeft: CSG;
  private csg_rakecutRight: CSG;

  private roofGroup: Group;

  private objectSizeChangedHandle: any;

  private totalBaySize: number;
  private extraOffsetZ: number;

  private eventHandleId: any;

  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;

  private mesh_CutoutLeft: Mesh;
  private csg_cutoutLeft: CSG;
  private mesh_CutoutRight: Mesh;
  private csg_cutoutRight: CSG;
  private geo_cutoutLeft: BoxBufferGeometry;
  private geo_cutoutRight: BoxBufferGeometry;

  private geoCutGutter: BoxBufferGeometry;
  private meshCutGutterLeft1: Mesh;
  private csgCutGutterLeft1: CSG;
  private meshCutGutterLeft2: Mesh;
  private csgCutGutterLeft2: CSG;

  private meshCutGutterRight1: Mesh;
  private csgCutGutterRight1: CSG;
  private meshCutGutterRight2: Mesh;
  private csgCutGutterRight2: CSG;

  private roofWidth: number;
  private roofLength: number;

  private deferHandle;
  private deferTimeout = EXISTING_BUILDING_CONFIG.CUTOUT_DEFFER_TIME_OUT;

  constructor(app: AppComponent, flyOverManager: PatiosFlyOverManager) {
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.APP = app;
    this.MANAGER = flyOverManager;
    this.scene = new Group();
    flyOverManager.patiosGroup.add(this.scene)
    this.material = MaterialManager.Instance().ROOF;

    this.registerEvent();
  }

  public optimize(): Promise<void> {
    return new Promise((resolve) => {
      this.geo_RoofPanel = this.geometryManager.getRoofRibspanPanel();
      this.geo_RoofPanel.geometry.translate(-this.geo_RoofPanel.width / 2, this.geo_RoofPanel.height / 2, -this.geo_RoofPanel.length / 2);
      this.geo_RoofPanel.geometry.rotateY(Math.PI)
      this.utils.changeGeoColor(this.geo_RoofPanel.geometry, new Vector3(0, 1, 0), UI.roofColor.toString());

      //Barge capping
      this.geo_roofPanelBargeCappingLeft = this.geometryManager.getRibspanBarge();
      this.geo_roofPanelBargeCappingLeft.geometry
        .rotateY(Math.PI)
        .translate(
          this.geo_roofPanelBargeCappingLeft.width / 2 - 3,
          this.geo_roofPanelBargeCappingLeft.height / 2 - RIBSPAN_BARGE_FIT,
          this.geo_roofPanelBargeCappingLeft.length / 2
        );

      this.geo_roofPanelBargeCappingRight = this.geometryManager.getRibspanBarge();
      this.geo_roofPanelBargeCappingRight.geometry.translate(
        -this.geo_roofPanelBargeCappingRight.width / 2 + 3,
        this.geo_roofPanelBargeCappingRight.height / 2 - RIBSPAN_BARGE_FIT,
        this.geo_roofPanelBargeCappingRight.length / 2
      );

      this.geo_roofPanelBargeCappingBack = this.geometryManager.getRibspanBarge();
      this.geo_roofPanelBargeCappingBack.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_roofPanelBargeCappingBack.length / 2,
          this.geo_roofPanelBargeCappingBack.height / 2 - RIBSPAN_BARGE_FIT,
          this.geo_roofPanelBargeCappingBack.width / 2 - 3
        );

      //Gutter
      this.geo_gutterBack = this.geometryManager.getGutter();
      this.geo_gutterBack.geometry
        .rotateY(-Math.PI / 2)
        .translate(
          this.geo_gutterBack.length / 2,
          -20,
          this.geo_gutterBack.width / 2
        );

      this.gutterRakecutLeftInfo = this.geometryManager.getGutter();
      this.gutterRakecutLeftInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(
          this.gutterRakecutLeftInfo.length / 2,
          -20,
          this.gutterRakecutLeftInfo.width / 2
        );

      this.gutterRakecutRightInfo = this.geometryManager.getGutter();
      this.gutterRakecutRightInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(
          -this.gutterRakecutRightInfo.length / 2,
          -20,
          this.gutterRakecutRightInfo.width / 2
        );

      //Gutter cap
      this.geo_gutterCap = this.geometryManager.getGuterEndCap();
      this.geo_gutterCap.geometry
        .rotateY(-Math.PI / 2)
        .translate(0, -20, this.geo_gutterCap.length / 2);

      this.geo_cutoutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutLeft.translate(-500, 500, -500);
      this.geo_cutoutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutRight.translate(500, 500, -500);

      this.geo_rakecutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutLeft.translate(-500, 500, 500);
      this.geo_rakecutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutRight.translate(500, 500, 500);

      this.geoCutGutter = new BoxBufferGeometry(500, 10000, 500);
      this.geoCutGutter.translate(0, 0, -250);
      //Barge cutout left
      this.geo_bargeCutoutLeftHor = this.geometryManager.getRibspanBarge();
      this.geo_bargeCutoutLeftHor.geometry
        .rotateY(Math.PI / 2)
        .translate(
          -this.geo_bargeCutoutLeftHor.length / 2,
          this.geo_bargeCutoutLeftHor.height / 2 - RIBSPAN_BARGE_FIT,
          this.geo_bargeCutoutLeftHor.width / 2
        );

      //Barge cutout right
      this.geo_bargeCutoutRightHor = this.geometryManager.getRibspanBarge();
      this.geo_bargeCutoutRightHor.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_bargeCutoutRightHor.length / 2,
          this.geo_bargeCutoutRightHor.height / 2 - RIBSPAN_BARGE_FIT,
          this.geo_bargeCutoutRightHor.width / 2
        );

      resolve();
    });
  }
  public load(): Promise<void> {
    return new Promise((resolve) => {
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PATIOS
        )
      );
      this.APP.scene.remove(
        ...this.APP.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SHEET_OUTLINE
        )
      );

      //test
      //this.scene.remove(...this.scene.children.filter(x => x.userData.type == 'HELPER'));
      this.APP.scene.remove(
        ...this.APP.scene.children.filter((x) => x.userData.type == "RAKECUT")
      );
      this.APP.scene.remove(
        ...this.APP.scene.children.filter((x) => x.userData.type == "CUTGUTTER")
      );
      this.extraOffsetZ =
        this.APP.existingWallManager.geo_existingWallL1.width +
        this.APP.eaveManager.backOverhang;
      this.totalBaySize = this.APP.dialogEditBay.totalBaySize;

      this.addRoof();
      this.addCutout();
      if (UI.rakeCutLeftType == 0) {
        new HighLightBox(this.roofGroup, {
          min: {
            x: -(UI.totalBayLength / 2 + UI.overhangLeft),
            y: 0,
            z: UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront,
          },
          max: {
            x: -(UI.totalBayLength / 2 + UI.overhangLeft) + 300,
            y: 0,
            z:
              UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront - 300,
          },
          userData: { side: BUILDING_SIDE.LEFT },
        });
      }
      if (UI.rakeCutRightType == 0) {
        new HighLightBox(this.roofGroup, {
          min: {
            x: UI.totalBayLength / 2 + UI.overhangRight - 300,
            y: 0,
            z: UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront,
          },
          max: {
            x: UI.totalBayLength / 2 + UI.overhangRight,
            y: 0,
            z:
              UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront - 300,
          },
          userData: { side: BUILDING_SIDE.RIGHT },
        });
      }

      // this.addAngleRakeCut();
      //Add angle rakecut
      if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        this.addAngleRakeCut(BUILDING_SIDE.LEFT);
      }
      if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        this.addAngleRakeCut(BUILDING_SIDE.RIGHT);
      }
      this.addStepRakeCut();

      let offsetLeft =
        this.totalBaySize / 2 +
        this.APP.sldLeftOverhang.currentValue -
        this.MANAGER.patiosGroup.position.x;
      let offsetRight =
        this.totalBaySize / 2 +
        this.APP.sldRightOverhang.currentValue +
        this.MANAGER.patiosGroup.position.x;
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
        let offsetX =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        offsetRight -= offsetX;
        offsetLeft += offsetX;
      }
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
        let offsetX =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        offsetLeft += offsetX;
        offsetRight += offsetX;
      }

      let clipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
      let clipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);

      this.material.clippingPlanes = [clipingPlaneLeft, clipingPlaneRight];
      MaterialManager.Instance().ROOF_BASE.clippingPlanes = [
        clipingPlaneLeft,
        clipingPlaneRight,
      ];
      let width =
        this.totalBaySize +
        this.APP.sldLeftOverhang.currentValue +
        this.APP.sldRightOverhang.currentValue;
      this.roofWidth = width;
      this.roofLength =
        this.APP.sldSpan.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldBackOverhang.currentValue +
        this.APP.sldFrontOverhang.currentValue;
      let roofSheetingCount = Math.ceil(width / RIBSPAN_PANEL_WIDTH);
      let offsetX = -(
        this.totalBaySize / 2 +
        this.APP.sldLeftOverhang.currentValue
      );

      let panelDirectionOfset = 1;
      if (
        this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.RIGHT_TO_LEFT
      ) {
        offsetX =
          this.totalBaySize / 2 +
          this.APP.sldRightOverhang.currentValue -
          RIBSPAN_PANEL_WIDTH;
        panelDirectionOfset = -1;
      } else if (
        this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.LEFT_TO_RIGHT
      ) {
        offsetX = -(
          this.totalBaySize / 2 +
          this.APP.sldLeftOverhang.currentValue
        );
      }

      if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.APP.sltExistingType.currentValue == 1 &&
        this.MANAGER.cutoutCondition
      ) {
        for (let index = 0; index < roofSheetingCount; index++) {
          let last = index == roofSheetingCount - 1;

          this.addRoofSheeting(offsetX, [this.csg_cutoutLeft]);

          offsetX += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      } else if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.APP.sltExistingType.currentValue == 2 &&
        this.MANAGER.cutoutCondition
      ) {
        for (let index = 0; index < roofSheetingCount; index++) {
          let last = index == roofSheetingCount - 1;

          this.addRoofSheeting(offsetX, [this.csg_cutoutRight]);

          offsetX += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      } else if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.APP.sltExistingType.currentValue == 3 &&
        this.MANAGER.cutoutCondition
      ) {
        for (let index = 0; index < roofSheetingCount; index++) {
          let last = index == roofSheetingCount - 1;

          this.addRoofSheeting(offsetX, [
            this.csg_cutoutLeft,
            this.csg_cutoutRight,
          ]);

          offsetX += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      } else {
        for (let index = 0; index < roofSheetingCount; index++) {
          let last = index == roofSheetingCount - 1;

          this.addRoofSheeting(offsetX, null);

          offsetX += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      }
      this.addBargeCapping();
      this.addBargeStepRakecut();
      this.addGutter();
      this.addGutterStepRakecut();
      this.addGutterAngleRakecut();
      this.showPanelOutline();
      //this.getOutLines();

      this.scene.visible = UI.showRoof

      resolve();
    });
  }
  private addCutout() {
    if (this.APP.sltCutOut.currentValue == 1) {
      this.APP.scene.remove(
        ...this.APP.scene.children.filter((x) => x.userData.type == "CUTOUT")
      );

      let offsetX =
        this.APP.sldExistingLength.currentValue / 2 +
        this.APP.existingWallManager.geo_existingWallL2.width +
        100;
      let offsetY = 0;
      let offsetZ = UI.existingWidth1 / 2 - UI.existingWallW1.width - this.APP.eaveManager.backOverhang - UI.overhangBack;

      let scaleX = (this.APP.sldExistingLength2.currentValue + 5000) / 1000;
      if (scaleX == 0) scaleX = 1;
      let scaleY = (this.APP.sldExistingWallHeight.currentValue + 5000) / 1000;
      let scaleZ =
        (this.APP.sldExistingWidth1.currentValue +
          this.APP.sldBackOverhang.currentValue +
          5000) /
        1000;
      if (scaleZ == 0) scaleZ = 1;

      if (
        this.APP.sltExistingType.currentValue == 1 ||
        this.APP.sltExistingType.currentValue == 3
      ) {
        this.mesh_CutoutLeft = new Mesh(
          this.geo_cutoutLeft,
          new MeshLambertMaterial({
            color: "red",
            transparent: true,
            opacity: 0.5,
          })
        );
        this.mesh_CutoutLeft.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: "CUTOUT",
        };

        this.mesh_CutoutLeft.position.set(-offsetX, offsetY, offsetZ);
        this.mesh_CutoutLeft.scale.set(scaleX, scaleY, scaleZ);

        // this.APP.scene.add(this.mesh_CutoutLeft);

        this.mesh_CutoutLeft.updateMatrix();
        this.csg_cutoutLeft = CSG.fromMesh(this.mesh_CutoutLeft);
      }
      if (
        this.APP.sltExistingType.currentValue == 2 ||
        this.APP.sltExistingType.currentValue == 3
      ) {
        this.mesh_CutoutRight = new Mesh(
          this.geo_cutoutRight,
          new MeshLambertMaterial({
            color: "red",
            transparent: true,
            opacity: 0.5,
          })
        );
        this.mesh_CutoutRight.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: "CUTOUT",
        };

        this.mesh_CutoutRight.position.set(offsetX, offsetY, offsetZ);
        this.mesh_CutoutRight.scale.set(scaleX, scaleY, scaleZ);

        // this.APP.scene.add(this.mesh_CutoutRight);

        this.mesh_CutoutRight.updateMatrix();
        this.csg_cutoutRight = CSG.fromMesh(this.mesh_CutoutRight);
      }
    }
  }
  private addAngleRakeCut(cutSide: BUILDING_SIDE) {
    let offsetXL =
      -this.totalBaySize / 2 - this.APP.sldLeftOverhang.currentValue;
    let offsetXR =
      this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;
    let offsetY = -100;

    let offsetZ =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue -
      // + this.APP.sldBackOverhang.currentValue
      this.extraOffsetZ -
      this.APP.sldExistingWidth1.currentValue / 2;
    //
    let angle;
    let _rotY;
    if (cutSide == BUILDING_SIDE.LEFT) {
      angle = Math.atan(
        this.APP.sldLeftCutHorizontal.currentValue /
          this.APP.sldLeftCutVertical.currentValue
      );
      _rotY = Math.atan(
        this.APP.sldLeftCutVertical.currentValue /
          this.APP.sldLeftCutHorizontal.currentValue
      );
    }
    if (cutSide == BUILDING_SIDE.RIGHT) {
      angle = Math.atan(
        this.APP.sldRightCutHorizontal.currentValue /
          this.APP.sldRightCutVertical.currentValue
      );
      _rotY = Math.atan(
        this.APP.sldRightCutVertical.currentValue /
          this.APP.sldRightCutHorizontal.currentValue
      );
    }
    const _offsetZ =
      (this.APP.sldFrontOverhang.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue +
        this.APP.sldBackOverhang.currentValue -
        CONST.GUTTER_ROOF_OFFSET_Z) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);
    let _angle = (Math.PI - _rotY) / 2;

    let hypotenuse = Math.sqrt(
      Math.pow(this.MANAGER.patiosLength, 2) +
        Math.pow(this.MANAGER.patiosWidth, 2)
    );
    let scaleLength = hypotenuse / 1000;
    let scaleWidth =
      (this.MANAGER.patiosLength * this.MANAGER.patiosWidth) /
      hypotenuse /
      1000;
    //
    if (cutSide === BUILDING_SIDE.LEFT) {
      this.mesh_rakecutLeft = new Mesh(
        this.geo_rakecutLeft,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutLeft.position.set(
        offsetXL + this.MANAGER.patiosGroup.position.x,
        offsetY,
        offsetZ - this.APP.sldLeftCutVertical.currentValue
      );
      this.mesh_rakecutLeft.scale.set(scaleWidth, 5, scaleLength);
      this.mesh_rakecutLeft.rotateY(angle);
      this.mesh_rakecutLeft.updateMatrix();
      this.csg_rakecutLeft = CSG.fromMesh(this.mesh_rakecutLeft);
      // this.APP.scene.add(this.mesh_rakecutLeft);

      //cut gutter csg
      let scaleZ = (this.APP.dialogEditBay.totalBaySize + 200) / 500;
      let scaleX = (this.APP.dialogEditBay.totalBaySize + 200) / 500;
      this.meshCutGutterLeft1 = new Mesh(
        this.geoCutGutter,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.meshCutGutterLeft1.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "CUTGUTTER",
      };
      this.meshCutGutterLeft1.position.set(
        offsetXL + this.APP.sldLeftCutHorizontal.currentValue,
        0,
        _offsetZ
      );
      this.meshCutGutterLeft1.scale.set(scaleX, 1, scaleZ);
      this.meshCutGutterLeft1.rotation.set(0, _angle, 0);
      this.meshCutGutterLeft1.updateMatrix();
      this.csgCutGutterLeft1 = CSG.fromMesh(this.meshCutGutterLeft1);
      // this.APP.scene.add(this.meshCutGutterLeft1);

      this.meshCutGutterLeft2 = new Mesh(
        this.geoCutGutter,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.meshCutGutterLeft2.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "CUTGUTTER",
      };
      this.meshCutGutterLeft2.position.set(
        offsetXL + this.APP.sldLeftCutHorizontal.currentValue,
        0,
        _offsetZ
      );
      this.meshCutGutterLeft2.scale.set(scaleX, 1, scaleZ);
      this.meshCutGutterLeft2.rotation.set(0, _angle + Math.PI, 0);
      this.meshCutGutterLeft2.updateMatrix();
      this.csgCutGutterLeft2 = CSG.fromMesh(this.meshCutGutterLeft2);
      // this.APP.scene.add(this.meshCutGutterLeft2);
    }
    if (cutSide === BUILDING_SIDE.RIGHT) {
      this.mesh_rakecutRight = new Mesh(
        this.geo_rakecutRight,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutRight.position.set(
        offsetXR + this.MANAGER.patiosGroup.position.x,
        offsetY,
        offsetZ - this.APP.sldRightCutVertical.currentValue
      );
      this.mesh_rakecutRight.scale.set(scaleWidth, 5, scaleLength);
      this.mesh_rakecutRight.rotateY(-angle);
      this.mesh_rakecutRight.updateMatrix();
      this.csg_rakecutRight = CSG.fromMesh(this.mesh_rakecutRight);
      // this.APP.scene.add(this.mesh_rakecutRight);

      //cut gutter
      let scaleZ = (this.APP.dialogEditBay.totalBaySize + 200) / 500;
      let scaleX = (this.APP.dialogEditBay.totalBaySize + 200) / 500;
      this.meshCutGutterRight1 = new Mesh(
        this.geoCutGutter,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.meshCutGutterRight1.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "CUTGUTTER",
      };
      this.meshCutGutterRight1.position.set(
        offsetXR - this.APP.sldRightCutHorizontal.currentValue,
        0,
        _offsetZ
      );
      this.meshCutGutterRight1.rotation.set(0, -_angle, 0);
      this.meshCutGutterRight1.scale.set(scaleX, 1, scaleZ);
      this.meshCutGutterRight1.updateMatrix();
      this.csgCutGutterRight1 = CSG.fromMesh(this.meshCutGutterRight1);

      this.meshCutGutterRight2 = new Mesh(
        this.geoCutGutter,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.meshCutGutterRight2.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "CUTGUTTER",
      };
      this.meshCutGutterRight2.position.set(
        offsetXR - this.APP.sldRightCutHorizontal.currentValue,
        0,
        _offsetZ
      );
      this.meshCutGutterRight2.scale.set(scaleX, 1, scaleZ);
      this.meshCutGutterRight2.rotation.set(0, -(_angle + Math.PI), 0);
      this.meshCutGutterRight2.updateMatrix();
      this.csgCutGutterRight2 = CSG.fromMesh(this.meshCutGutterRight2);
    }
  }
  private addStepRakeCut() {
    let offsetXL =
      -this.totalBaySize / 2 -
      this.APP.sldLeftOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let offsetXR =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let offsetY = -100; //this.APP.sldExistingWallHeight.currentValue;

    let offsetZ =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue -
      this.extraOffsetZ -
      this.APP.sldExistingWidth1.currentValue / 2;

    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
      this.mesh_rakecutLeft = new Mesh(
        this.geo_rakecutLeft,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutLeft.position.set(
        offsetXL + this.APP.sldLeftCutHorizontal.currentValue,
        offsetY,
        offsetZ - this.APP.sldLeftCutVertical.currentValue
      );
      this.mesh_rakecutLeft.scale.set(5, 5, 5);

      //this.APP.scene.add(this.mesh_rakecutLeft);

      this.mesh_rakecutLeft.updateMatrix();
      this.csg_rakecutLeft = CSG.fromMesh(this.mesh_rakecutLeft);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      this.mesh_rakecutRight = new Mesh(
        this.geo_rakecutRight,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutRight.position.set(
        offsetXR - this.APP.sldRightCutHorizontal.currentValue,
        offsetY,
        offsetZ - this.APP.sldRightCutVertical.currentValue
      );
      this.mesh_rakecutRight.scale.set(5, 5, 5);

      //this.APP.scene.add(this.mesh_rakecutRight);

      this.mesh_rakecutRight.updateMatrix();
      this.csg_rakecutRight = CSG.fromMesh(this.mesh_rakecutRight);
    }
  }
  public getOutLines(): Printing2DGeometry {
    let objs = this.roofGroup.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    );

    let gutterGroupObjs = this.roofGroup.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS
    );
    let gutterObjs: Object3D[] = [];
    for (let group of gutterGroupObjs) {
      gutterObjs.push(...group.children);
    }
    objs = [...objs, ...gutterObjs];

    let lsGeometries: Printing2DLine[] = [];

    let offsetLeft =
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue -
      this.MANAGER.patiosGroup.position.x;
    let offsetRight =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      offsetRight -= offsetX;
      offsetLeft += offsetX;
    }
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      offsetLeft += offsetX;
      offsetRight += offsetX;
    }
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);

    for (let o of objs) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        60
      );

      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);
      outlineGeo.translate(0, 5000, 0);

      if (
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
      ) {
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
      }
      // if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL) {
      //     var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
      //     line.userData = {type: "SHEETING_OUTLINE"};
      //     this.APP.scene.add(line);
      //     // let boxPost = new Box3().setFromObject(o);
      //     // console.log('😋: RoofManager -> getOutLines -> boxPost', boxPost.min.distanceTo(boxPost.max));
      // }

      let simplifiedGeo = this.simplifyGeo(outlineGeo);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: simplifiedGeo.vertices,
        views: o.userData.views,
      });
    }

    lsGeometries.push({
      objectType: GEOMETRY_TYPE.ROOF_COVER,
      vertices: this.makeRoofPolygon(),
      color: this.utils.convertHexColorToRgb(`#${new Color(UI.panelColor).getHexString()}`),
      views: [
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
      ]
    });

    return { lines: lsGeometries, texts: [] };
  }
  makeRoofPolygon() {
    const polygon = this.utils.getRoofCoverPolygonForLRRoof()
    this.roofGroup.updateWorldMatrix(true, true)
    return polygon.map(el => (el as Vector3).applyMatrix4(this.roofGroup.matrixWorld))
  }
  public showPanelOutline() {
    if (this.APP.sltPanelDirectionShow.currentValue == false) return;

    let objs = this.roofGroup.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    );

    let offsetLeft =
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue -
      this.MANAGER.patiosGroup.position.x;
    let offsetRight =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      offsetRight -= offsetX;
      offsetLeft += offsetX;
    }
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      offsetLeft += offsetX;
      offsetRight += offsetX;
    }
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);

    for (let o of objs) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        60
      );

      //let outlineBox = new Box3().setFromBufferAttribute(outlineGeo.getAttribute('position') as Buffer);

      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);
      //outlineGeo.translate(0, 1000, 0);

      if (
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
      ) {
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
      }

      var line = new LineSegments(
        outlineGeo,
        MaterialManager.Instance().MESH_OUTLINE
      );
      line.userData = { type: GEOMETRY_TYPE.SHEET_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let simplifiedGeo = new Geometry();
    let vertices = geo.getAttribute("position").array;
    for (let i = 0; i < vertices.length; i += 3) {
      simplifiedGeo.vertices.push(
        new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2])
      );
    }
    return simplifiedGeo;
  }
  //private getBracketHeight(offset): number{
  //    let angle = this.APP.sltRoofPitch.currentValue;
  //    return (this.APP.sldFlyOverBracketHeight.currentValue) - this.utils.tan(angle) * offset;
  //}

  public addRoof() {
    this.roofGroup = null;
    this.roofGroup = new Group();
    this.roofGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.ROOF_PATIOS,
    };

    let offsetY = this.getRoofOffsetY();
    let offsetZ = -(
      this.APP.sldExistingWidth1.currentValue / 2 +
      this.APP.sldBackOverhang.currentValue +
      this.extraOffsetZ
    );

    this.roofGroup.position.setY(offsetY);
    this.roofGroup.position.setZ(offsetZ);
    this.roofGroup.rotateX(
      this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue)
    );

    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      this.roofGroup.position.setX(-offsetX);
    }

    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      this.roofGroup.position.setX(offsetX);
    }

    this.scene.add(this.roofGroup);
  }
  public addRoofSheeting(offsetX: number, csgObjs: CSG[]) {
    let mesh = new Mesh(this.geo_RoofPanel.geometry, this.material);

    let scaleZ =
      (this.APP.sldSpan.currentValue +
        this.APP.sldFrontOverhang.currentValue +
        this.APP.sldBackOverhang.currentValue +
        this.APP.sldMultiSpan.currentValue) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue) /
      this.geo_RoofPanel.length;

    let offsetY = 0;
    let offsetZ = 0;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    mesh.position.set(offsetX, offsetY, offsetZ);
    mesh.scale.setZ(scaleZ);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.ROOF_PANEL,
      views: views,
    };

    let cutoutCondition =
      this.APP.sltCutOut.currentValue == 1 &&
      this.APP.sltExistingType.currentValue != 0 &&
      this.APP.sldExistingLength2.currentValue > 0;
    let rakeCutCondition =
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE ||
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE;
    if (cutoutCondition || rakeCutCondition) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      mesh.position.set(
        offsetX + this.MANAGER.patiosGroup.position.x,
        offsetY,
        offsetZ + this.roofGroup.position.z
      );
      mesh.updateMatrix();
      let meshResult = mesh.clone();

      if (cutoutCondition) {
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(csgObj),
            mesh.matrix
          );
        }
      }
      if (rakeCutCondition) {
        if (this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(this.csg_rakecutLeft),
            mesh.matrix
          );
        }
        if (this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(this.csg_rakecutRight),
            mesh.matrix
          );
        }
      }
      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix));
      meshResult.applyMatrix4(matrixBefore);

      let buferGeo = new BufferGeometry().fromGeometry((meshResult as Mesh).geometry as Geometry);
      this.utils.changeGeoColor(buferGeo, new Vector3(0, 1, 0), UI.roofColor.toString());
      let newGeo = new Geometry().fromBufferGeometry(buferGeo);
      (meshResult as Mesh).geometry = newGeo;

      this.roofGroup.add(meshResult);
    } else {
      this.roofGroup.add(mesh);
    }
  }
  private addBargeCapping() {
    let mesh: Mesh;

    let extraWallWidth =
      this.APP.existingWallManager.geo_existingWallW1.width +
      EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;

    let offsetXB =
      -this.totalBaySize / 2 - this.APP.sldLeftOverhang.currentValue;
    let offsetXL1 =
      -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue) +
      this.APP.sldExistingLength2.currentValue -
      extraWallWidth;
    let offsetXL2 = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );

    let offsetXR1 =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue -
      this.APP.sldExistingLength2.currentValue +
      extraWallWidth;
    let offsetXR2 =
      this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;

    let offsetZL = 0;
    let offsetZR = 0;

    let bargeBackLength = this.roofWidth;
    let scaleCutoutVer =
      this.utils.getHypotenuseByCos(
        this.APP.sldExistingWidth1.currentValue +
          this.geo_roofPanelBargeCappingLeft.width,
        this.APP.sltRoofPitch.currentValue
      ) / this.geo_roofPanelBargeCappingLeft.length;
    let scaleZLeft =
      this.utils.getHypotenuseByCos(
        this.roofLength,
        this.APP.sltRoofPitch.currentValue
      ) / this.geo_roofPanelBargeCappingLeft.length;
    let scaleZRight =
      this.utils.getHypotenuseByCos(
        this.roofLength,
        this.APP.sltRoofPitch.currentValue
      ) / this.geo_roofPanelBargeCappingLeft.length;

    let offsetY = 0;
    let bargeLeftLength = this.roofLength;
    let bargeRightLength = this.roofLength;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    // cutout
    if (
      this.APP.sltCutOut.currentValue == 1 &&
      this.APP.sldExistingLength2.currentValue > 0 &&
      this.MANAGER.cutoutCondition &&
      (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT ||
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH)
    ) {
      bargeLeftLength -= this.APP.sldExistingWidth1.currentValue;
      scaleZLeft =
        this.utils.getHypotenuseByCos(
          this.roofLength - this.APP.sldExistingWidth1.currentValue,
          this.APP.sltRoofPitch.currentValue
        ) / this.geo_roofPanelBargeCappingLeft.length;

      let bargeCutoutHorLength =
        this.APP.sldExistingLength2.currentValue +
        this.APP.sldLeftOverhang.currentValue -
        extraWallWidth;

      if (this.APP.sldExistingWidth2.currentValue > 0) {
        offsetXB += this.APP.sldExistingLength2.currentValue;
        offsetXL1 =
          -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue) +
          this.APP.sldExistingLength2.currentValue;
        bargeBackLength -= this.APP.sldExistingLength2.currentValue;
        bargeCutoutHorLength += extraWallWidth;
      } else {
        bargeBackLength -=
          this.APP.sldExistingLength2.currentValue - extraWallWidth;
        offsetXB += this.APP.sldExistingLength2.currentValue - extraWallWidth;
      }

      offsetZL += this.utils.getHypotenuseByCos(
        this.APP.sldExistingWidth1.currentValue,
        this.APP.sltRoofPitch.currentValue
      );

      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        offsetXL1 =
          -this.APP.sldExistingLength.currentValue / 2 - extraWallWidth;
        bargeCutoutHorLength =
          (this.roofWidth -
            (this.APP.sldExistingLength.currentValue +
              this.APP.existingWallManager.geo_existingWallW1.width * 2)) /
            2 -
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
      }

      bargeBackLength -= this.APP.sldLeftOverhang.currentValue;
      offsetXL1 +=
        this.APP.sldLeftOverhang.currentValue -
        EXISTING_BUILDING_CONFIG.BARGE_ROOF_OFFSET_X;
      offsetXB += this.APP.sldLeftOverhang.currentValue;

      //cutout Hor
      let offsetZ = this.utils.getHypotenuseByCos(
        this.APP.sldExistingWidth1.currentValue,
        this.APP.sltRoofPitch.currentValue
      );
      mesh = new Mesh(
        this.geo_bargeCutoutLeftHor.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXL1, offsetY, offsetZ);
      mesh.scale.setX(
        bargeCutoutHorLength / this.geo_bargeCutoutLeftHor.length
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);

      //cutout Ver
      mesh = new Mesh(
        this.geo_roofPanelBargeCappingLeft.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXL1, offsetY, 0);
      mesh.scale.setZ(scaleCutoutVer);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    }
    if (
      this.APP.sltCutOut.currentValue == 1 &&
      this.APP.sldExistingLength2.currentValue > 0 &&
      this.MANAGER.cutoutCondition &&
      (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT ||
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH)
    ) {
      bargeRightLength -= this.APP.sldExistingWidth1.currentValue;
      let bargeCutoutHorLength =
        this.APP.sldExistingLength2.currentValue +
        this.APP.sldRightOverhang.currentValue -
        extraWallWidth;
      //scaleXB = (this.roofWidth - this.APP.sldExistingLength2.currentValue + extraWallWidth) / this.geo_roofPanelBargeCappingBack.length;
      //bargeBackLength -= (this.APP.sldExistingLength2.currentValue - extraWallWidth);
      scaleZRight =
        this.utils.getHypotenuseByCos(
          this.roofLength - this.APP.sldExistingWidth1.currentValue,
          this.APP.sltRoofPitch.currentValue
        ) / this.geo_roofPanelBargeCappingRight.length;
      offsetZR += this.utils.getHypotenuseByCos(
        this.APP.sldExistingWidth1.currentValue,
        this.APP.sltRoofPitch.currentValue
      );

      if (this.APP.sldExistingWidth2.currentValue > 0) {
        //scaleXB = (this.roofWidth - this.APP.sldExistingLength2.currentValue) / this.geo_roofPanelBargeCappingBack.length;
        bargeBackLength -= this.APP.sldExistingLength2.currentValue;
        offsetXR1 -= extraWallWidth;

        bargeCutoutHorLength += extraWallWidth;
      } else {
        bargeBackLength -=
          this.APP.sldExistingLength2.currentValue - extraWallWidth;
      }

      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        offsetXR1 =
          this.APP.sldExistingLength.currentValue / 2 +
          this.APP.existingWallManager.geo_existingWallW1.width +
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
        bargeCutoutHorLength =
          (this.roofWidth -
            (this.APP.sldExistingLength.currentValue +
              this.APP.existingWallManager.geo_existingWallW1.width * 2)) /
            2 -
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
      }

      bargeBackLength -= this.APP.sldRightOverhang.currentValue;
      offsetXR1 -=
        this.APP.sldRightOverhang.currentValue -
        EXISTING_BUILDING_CONFIG.BARGE_ROOF_OFFSET_X;

      //cutout hor
      let offsetZ = this.utils.getHypotenuseByCos(
        this.APP.sldExistingWidth1.currentValue,
        this.APP.sltRoofPitch.currentValue
      );
      mesh = new Mesh(
        this.geo_bargeCutoutRightHor.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXR1, offsetY, offsetZ);
      mesh.scale.setX(
        bargeCutoutHorLength / this.geo_bargeCutoutRightHor.length
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);

      //cutout ver
      mesh = new Mesh(
        this.geo_roofPanelBargeCappingRight.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXR1, offsetY, 0);
      mesh.scale.setZ(scaleCutoutVer);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    }
    if (
      this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
      this.APP.sldExistingLength2.currentValue > 0 &&
      this.MANAGER.cutoutCondition &&
      this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH
    ) {
      offsetXB = -this.APP.sldExistingLength.currentValue / 2 - extraWallWidth;
      //scaleXB = (this.APP.sldExistingLength.currentValue + this.APP.existingWallManager.geo_existingWallW1.width * 2 + EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK*2)/this.geo_roofPanelBargeCappingBack.length;
      bargeBackLength =
        this.APP.sldExistingLength.currentValue + extraWallWidth * 2;
    }
    //Rakecut
    if (
      this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE ||
      this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP
    ) {
      bargeLeftLength -= this.APP.sldLeftCutVertical.currentValue;
    }
    if (
      this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE ||
      this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP
    ) {
      bargeRightLength -= this.APP.sldRightCutVertical.currentValue;
    }

    scaleZLeft =
      this.utils.getHypotenuseByCos(
        bargeLeftLength,
        this.APP.sltRoofPitch.currentValue
      ) / this.geo_roofPanelBargeCappingLeft.length;
    scaleZRight =
      this.utils.getHypotenuseByCos(
        bargeRightLength,
        this.APP.sltRoofPitch.currentValue
      ) / this.geo_roofPanelBargeCappingLeft.length;

    //left
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingLeft.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.set(offsetXL2, offsetY, offsetZL);
    mesh.scale.setZ(scaleZLeft);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };
    this.roofGroup.add(mesh);

    //right
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingRight.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.set(offsetXR2, offsetY, offsetZR);
    mesh.scale.setZ(scaleZRight);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };
    this.roofGroup.add(mesh);

    //back
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingBack.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.setX(offsetXB);
    mesh.scale.setX(
      bargeBackLength / this.geo_roofPanelBargeCappingBack.length
    );
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };
    this.roofGroup.add(mesh);
  }
  // private addBargeAngleRakecut() {
  //     if (this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.ANGLE && this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.ANGLE) {
  //         return;
  //     }

  //     let offsetY = 0;
  //     let offsetZ = (this.APP.sldSpan.currentValue
  //         + this.APP.sldMultiSpan.currentValue
  //         + this.APP.sldFrontOverhang.currentValue
  //         + this.APP.sldBackOverhang.currentValue) / this.utils.cos(this.APP.sltRoofPitch.currentValue);

  //     let views: Print2DView[] = [
  //         { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
  //         { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
  //         { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
  //         { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
  //     ];

  //     if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
  //         let length = Math.sqrt(Math.pow(this.APP.sldLeftCutVertical.currentValue, 2) + Math.pow(this.APP.sldLeftCutHorizontal.currentValue, 2));
  //         let scaleZ = length / this.geo_bargeRakecutLeft.length;

  //         let rotY = Math.atan(this.APP.sldLeftCutHorizontal.currentValue / this.APP.sldLeftCutVertical.currentValue);

  //         let offsetX = -this.totalBaySize / 2 - this.APP.sldLeftOverhang.currentValue + this.APP.sldLeftCutHorizontal.currentValue;
  //         let mesh = new Mesh(this.geo_bargeRakecutLeft.geometry, MaterialManager.Instance().BARGE);
  //         mesh.position.set(offsetX, offsetY, offsetZ);
  //         mesh.scale.setZ(scaleZ);
  //         mesh.rotateY(rotY);
  //         mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
  //         this.roofGroup.add(mesh);
  //     }
  //     if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
  //         let length = Math.sqrt(Math.pow(this.APP.sldRightCutVertical.currentValue, 2) + Math.pow(this.APP.sldRightCutHorizontal.currentValue, 2));
  //         let scaleZ = length / this.geo_bargeRakecutLeft.length;

  //         let rotY = Math.atan(this.APP.sldRightCutHorizontal.currentValue / this.APP.sldRightCutVertical.currentValue);

  //         let offsetX = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue - this.APP.sldRightCutHorizontal.currentValue;
  //         let mesh = new Mesh(this.geo_bargeRakecutRight.geometry, MaterialManager.Instance().BARGE);
  //         mesh.position.set(offsetX, offsetY, offsetZ);
  //         mesh.scale.setZ(scaleZ);
  //         mesh.rotateY(-rotY);
  //         mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
  //         this.roofGroup.add(mesh);
  //     }
  // }
  private addGutterStepRakecut() {
    if (
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.STEP &&
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.STEP
    ) {
      return;
    }
    let mesh: Mesh;

    let offsetXLL = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );
    let offsetXLR = offsetXLL + this.APP.sldLeftCutHorizontal.currentValue;
    let offsetXRR = this.totalBaySize / 2 + UI.overhangRight;
    let offsetXRL = offsetXRR - this.APP.sldRightCutHorizontal.currentValue;
    let offsetY = 0;
    let offsetLZ =
      (this.APP.sldFrontOverhang.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue +
        this.APP.sldBackOverhang.currentValue -
        this.APP.sldLeftCutVertical.currentValue -
        50) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);
    let offsetRZ =
      (this.APP.sldFrontOverhang.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue +
        this.APP.sldBackOverhang.currentValue -
        this.APP.sldRightCutVertical.currentValue -
        50) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);

    let gutterLengthL = this.APP.sldLeftCutHorizontal.currentValue;
    let gutterLengthR = this.APP.sldRightCutHorizontal.currentValue;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];
    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
      let scaleX = gutterLengthL / this.geo_gutterBack.length;
      mesh = new Mesh(
        this.geo_gutterBack.geometry,
        MaterialManager.Instance().GUTTER
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.GUTTER_PATIOS,
        views: views,
      };
      mesh.position.set(offsetXLL, 0, 0);
      mesh.scale.setX(scaleX);

      let endCapMesh = new Mesh(
        this.geo_gutterCap.geometry,
        MaterialManager.Instance().GUTTER
      );
      endCapMesh.position.set(offsetXLL, 0, 0);

      let endCapMesh2 = new Mesh(
        this.geo_gutterCap.geometry,
        MaterialManager.Instance().GUTTER
      );
      endCapMesh2.position.set(offsetXLR, 0, 0);

      let gutterGroup = new Group();
      gutterGroup.add(mesh, endCapMesh, endCapMesh2);
      gutterGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.GUTTER_PATIOS,
        views: views,
      };
      gutterGroup.position.set(0, offsetY, offsetLZ);
      gutterGroup.rotation.set(
        -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
        0,
        0
      );
      this.roofGroup.add(gutterGroup);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      let scaleX = gutterLengthR / this.geo_gutterBack.length;
      mesh = new Mesh(
        this.geo_gutterBack.geometry,
        MaterialManager.Instance().GUTTER
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.GUTTER_PATIOS,
        views: views,
      };
      mesh.position.set(offsetXRL, 0, 0);
      mesh.scale.setX(scaleX);

      let endCapMesh = new Mesh(
        this.geo_gutterCap.geometry,
        MaterialManager.Instance().GUTTER
      );
      endCapMesh.position.set(offsetXRL, 0, 0);

      let endCapMesh2 = new Mesh(
        this.geo_gutterCap.geometry,
        MaterialManager.Instance().GUTTER
      );
      endCapMesh2.position.set(offsetXRR, 0, 0);

      let gutterGroup = new Group();
      gutterGroup.add(mesh, endCapMesh, endCapMesh2);
      gutterGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.GUTTER_PATIOS,
        views: views,
      };
      gutterGroup.position.set(0, offsetY, offsetRZ);
      gutterGroup.rotation.set(
        -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
        0,
        0
      );
      this.roofGroup.add(gutterGroup);
    }
  }
  private addGutterAngleRakecut() {
    if (
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.ANGLE &&
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.ANGLE
    ) {
      return;
    }

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    let exLength = 300;
    let rc_offsetXL = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );
    let rc_offsetXR =
      this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;
    const rc_offsetZL =
      (this.APP.sldFrontOverhang.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue +
        this.APP.sldBackOverhang.currentValue -
        this.APP.sldLeftCutVertical.currentValue -
        CONST.GUTTER_ROOF_OFFSET_Z) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);
    const rc_offsetZR =
      (this.APP.sldFrontOverhang.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue +
        this.APP.sldBackOverhang.currentValue -
        this.APP.sldRightCutVertical.currentValue -
        CONST.GUTTER_ROOF_OFFSET_Z) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);
    const rc_offsetY = 0;

    let rc_rotYL = Math.atan(
      this.APP.sldLeftCutVertical.currentValue /
        this.APP.sldLeftCutHorizontal.currentValue
    );
    let rc_lengthL =
      exLength +
      Math.sqrt(
        Math.pow(this.APP.sldLeftCutVertical.currentValue, 2) +
          Math.pow(this.APP.sldLeftCutHorizontal.currentValue, 2)
      );
    let rc_rotYR = Math.atan(
      this.APP.sldRightCutVertical.currentValue /
        this.APP.sldRightCutHorizontal.currentValue
    );
    let rc_lengthR =
      exLength +
      Math.sqrt(
        Math.pow(this.APP.sldRightCutVertical.currentValue, 2) +
          Math.pow(this.APP.sldRightCutHorizontal.currentValue, 2)
      );

    if (
      this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE &&
      this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE
    ) {
      let gutterRakecutGroupLeft = this.utils.createGutterGroup(
        this.gutterRakecutLeftInfo,
        this.geo_gutterCap,
        rc_lengthL,
        new Vector3(rc_offsetXL, rc_offsetY, rc_offsetZL),
        new Vector3(0, -rc_rotYL, 0),
        views,
        1,
        true,
        false,
        this.csgCutGutterLeft2,
        this.roofGroup
      );
      let gutterRakecutGroupRight = this.utils.createGutterGroup(
        this.gutterRakecutRightInfo,
        this.geo_gutterCap,
        rc_lengthR,
        new Vector3(rc_offsetXR, rc_offsetY, rc_offsetZR),
        new Vector3(0, rc_rotYR, 0),
        views,
        1,
        true,
        false,
        this.csgCutGutterRight2,
        this.roofGroup
      );

      this.roofGroup.add(gutterRakecutGroupLeft, gutterRakecutGroupRight);
    } else {
      if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        let gutterRakecutGroup = this.utils.createGutterGroup(
          this.gutterRakecutLeftInfo,
          this.geo_gutterCap,
          rc_lengthL,
          new Vector3(rc_offsetXL, rc_offsetY, rc_offsetZL),
          new Vector3(0, -rc_rotYL, 0),
          views,
          1,
          true,
          false,
          this.csgCutGutterLeft2,
          this.roofGroup
        );

        this.roofGroup.add(gutterRakecutGroup);
      }
      if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        let gutterRakecutGroup = this.utils.createGutterGroup(
          this.gutterRakecutRightInfo,
          this.geo_gutterCap,
          rc_lengthR,
          new Vector3(rc_offsetXR, rc_offsetY, rc_offsetZR),
          new Vector3(0, rc_rotYR, 0),
          views,
          1,
          true,
          false,
          this.csgCutGutterRight2,
          this.roofGroup
        );

        this.roofGroup.add(gutterRakecutGroup);
      }
    }
  }
  private addBargeStepRakecut() {
    if (
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.STEP &&
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.STEP
    ) {
      return;
    }

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    let offsetXL =
      this.APP.dialogEditBay.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue;
    let offsetXR =
      this.APP.dialogEditBay.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue;

    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
      let offsetZ = this.utils.getHypotenuseByCos(
        this.APP.sldSpan.currentValue +
          this.APP.sldMultiSpan.currentValue +
          this.APP.sldFrontOverhang.currentValue +
          this.APP.sldBackOverhang.currentValue -
          this.APP.sldLeftCutVertical.currentValue,
        this.APP.sltRoofPitch.currentValue
      );
      let scaleZ =
        this.APP.sldLeftCutVertical.currentValue /
        this.geo_roofPanelBargeCappingLeft.length;

      let bargeVer = new Mesh(
        this.geo_roofPanelBargeCappingLeft.geometry,
        MaterialManager.Instance().BARGE
      );
      bargeVer.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      bargeVer.position.set(
        -offsetXL -
          CONST.BARGE_ROOF_OFFSET_X +
          this.APP.sldLeftCutHorizontal.currentValue,
        0,
        offsetZ
      );
      bargeVer.scale.set(1, 1, scaleZ);

      this.roofGroup.add(bargeVer);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      let offsetZ = this.utils.getHypotenuseByCos(
        this.APP.sldSpan.currentValue +
          this.APP.sldMultiSpan.currentValue +
          this.APP.sldFrontOverhang.currentValue +
          this.APP.sldBackOverhang.currentValue -
          this.APP.sldRightCutVertical.currentValue,
        this.APP.sltRoofPitch.currentValue
      );
      let scaleZ =
        this.APP.sldRightCutVertical.currentValue /
        this.geo_roofPanelBargeCappingLeft.length;

      let bargeVer = new Mesh(
        this.geo_roofPanelBargeCappingRight.geometry,
        MaterialManager.Instance().BARGE
      );
      bargeVer.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      bargeVer.position.set(
        offsetXR -
          this.APP.sldRightCutHorizontal.currentValue +
          CONST.BARGE_ROOF_OFFSET_X,
        0,
        offsetZ
      );
      bargeVer.scale.set(1, 1, scaleZ);
      this.roofGroup.add(bargeVer);
    }
  }
  public addGutter() {
    let offsetXL = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );
    let offsetY = 0;
    let offsetZ =
      (this.APP.sldFrontOverhang.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue +
        this.APP.sldBackOverhang.currentValue -
        CONST.GUTTER_ROOF_OFFSET_Z) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);

    let gutterLength = this.roofWidth;
    let exLength = 300; //Math.tan(extraAngle) * this.gutterInfo.width;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];
    //Rakecut
    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      offsetXL += this.APP.sldLeftCutHorizontal.currentValue - exLength;
      gutterLength -= this.APP.sldLeftCutHorizontal.currentValue - exLength;
    } else if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
      offsetXL += this.APP.sldLeftCutHorizontal.currentValue;
      gutterLength -= this.APP.sldLeftCutHorizontal.currentValue;
    }

    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      gutterLength -= this.APP.sldRightCutHorizontal.currentValue - exLength;
    } else if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      gutterLength -= this.APP.sldRightCutHorizontal.currentValue;
    }
    if (
      this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE &&
      this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE
    ) {
      let gutterMesh = new Mesh(
        this.geo_gutterBack.geometry,
        MaterialManager.Instance().GUTTER
      );
      gutterMesh.scale.setX(gutterLength / this.geo_gutterBack.length);
      gutterMesh.updateMatrix();
      let matrixBefore = gutterMesh.matrix.clone();

      gutterMesh.position.set(offsetXL, offsetY, offsetZ);
      gutterMesh.rotation.set(
        -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
        0,
        0
      );
      gutterMesh.updateMatrix();

      let meshClipped = CSG.toMesh(
        CSG.fromMesh(gutterMesh).subtract(this.csgCutGutterLeft1),
        gutterMesh.matrix
      );
      meshClipped = CSG.toMesh(
        CSG.fromMesh(meshClipped).subtract(this.csgCutGutterRight1),
        gutterMesh.matrix
      );
      meshClipped.applyMatrix4(new Matrix4().getInverse(gutterMesh.matrix));
      meshClipped.applyMatrix4(matrixBefore);
      meshClipped.material = gutterMesh.material;

      let gutterGroup = this.utils.createGutterGroupWithMesh(
        meshClipped,
        this.geo_gutterCap,
        gutterLength,
        new Vector3(offsetXL, offsetY, offsetZ),
        new Vector3(
          -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
          0,
          0
        ),
        views,
        1,
        false,
        false
      );

      this.roofGroup.add(gutterGroup);
    } else if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      let gutterGroup = this.utils.createGutterGroup(
        this.geo_gutterBack,
        this.geo_gutterCap,
        gutterLength,
        new Vector3(offsetXL, offsetY, offsetZ),
        new Vector3(
          -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
          0,
          0
        ),
        views,
        1,
        false,
        true,
        this.csgCutGutterLeft1,
        this.roofGroup
      );

      this.roofGroup.add(gutterGroup);
    } else if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      let gutterGroup = this.utils.createGutterGroup(
        this.geo_gutterBack,
        this.geo_gutterCap,
        gutterLength,
        new Vector3(offsetXL, offsetY, offsetZ),
        new Vector3(
          -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
          0,
          0
        ),
        views,
        1,
        true,
        false,
        this.csgCutGutterRight1,
        this.roofGroup
      );

      this.roofGroup.add(gutterGroup);
    } else {
      let gutterGroup = this.utils.createGutterGroup(
        this.geo_gutterBack,
        this.geo_gutterCap,
        gutterLength,
        new Vector3(offsetXL, offsetY, offsetZ),
        new Vector3(
          -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
          0,
          0
        ),
        views,
        1,
        true,
        true
      );
      this.roofGroup.add(gutterGroup);
    }
  }
  private totalHeightFromEaveHeightToTopOfExistingRoof(){
    const height = 
      this.geometryManager.EAVE.EAVE.height 
      + UI.fasciaDepth 
      + UI.eaveWidth * this.utils.tan(UI.existingRoofPitch) 
      + this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.height / this.utils.cos(UI.existingRoofPitch)

    return height
  }
  private totalHeightFromEaveHeightToTopOfFlyoverBraket(){
    const height = this.totalHeightFromEaveHeightToTopOfExistingRoof() + UI.braketHeight - FIT_FLYOVER_BRAKET_ON_ROOF;

    return height
  }
  public getRoofOffsetY() {
    return (
      this.APP.sldExistingWallHeight.currentValue +
      this.totalHeightFromEaveHeightToTopOfFlyoverBraket() +
      this.geometryManager.getBeam().height +
      this.utils.tan(this.APP.sltRoofPitch.currentValue) *
        this.APP.sldBackOverhang.currentValue
    );
  }
  public destroy(): void {
    this.unregisterEvent();
  }
  public onColorChanged() {
    if(this.utils && this.geo_RoofPanel.geometry){
      this.utils.changeGeoColor(this.geo_RoofPanel.geometry, new Vector3(0, 1, 0), UI.roofColor.toString());
    }
  }
  private registerEvent(): void {
    this.eventHandleId = this.onColorChanged.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sltColourRoof
    ];
    this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));

    this.controlsToRegisterEvent2 = [
      this.APP.sltRoofSheetingType,
      this.APP.sltRoofThickness,
      this.APP.sltBargeType,
      this.APP.sltDripBarge,
      this.APP.sltGutterType,
    ];
    this.controlsToRegisterEvent2.forEach((c) =>
      c.addAction(this.objectSizeChangedHandle)
    );
  }
  private unregisterEvent(): void {
    this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach((c) =>
      c.removeAction(this.objectSizeChangedHandle)
    );
    this.controlsToRegisterEvent2 = undefined;
  }
  private objectSizeChanged() {
    this.optimize().then(() => {
      this.load();
    });
  }
  public uiChanged(): void {
    this.load();
  }
  uiCHangedDefer() {
    if (this.APP.sltCutOut.currentValue == 1) {
      if (this.deferHandle) {
        clearTimeout(this.deferHandle);
      }
      this.deferHandle = setTimeout(() => {
        this.load();
      }, this.deferTimeout);
    } else {
      this.load();
    }
  }
}
