import { Scene, Mesh, BufferGeometry, Vector3, Object3D, Color, Material, MeshPhongMaterial, DoubleSide, Geometry, LineSegments, Group } from "three";
import { Util } from "./utils";
import { HomeComponent as AppComponent } from 'src/app-ribspan/containers/home/home.component';
import { GeometryManager } from "./geometry.manager";
import { MaterialManager } from "./material.manager";
import { GeometryInfo, Printing2DLine, ViewType, LineType, Printing2DGeometry, Printing2DGeometryType } from 'src/app/core/models';
import { CONFIG as env, GEOMETRY_TYPE } from 'src/app/app.config';
import HighLightBox from "src/app-ribspan/models/HighlightBox";
import { BUILDING_SIDE } from "src/app/app.constants";
import { UI } from "./ui";

export class ExistingWallManager {
    private scene: Scene;
    private container: Group;
    private APP: AppComponent;
    private material: Material;
    private utils: Util;

    private geometryManager: GeometryManager;

    public geo_existingWallW1: GeometryInfo;
    public geo_existingWallL1: GeometryInfo;
    public geo_existingWallL2: GeometryInfo;
    public geo_existingWallW2: GeometryInfo;

    constructor() {
        this.utils = new Util();
        this.geometryManager = GeometryManager.Instance();
    }

    public init(app: AppComponent): Promise<void> {
        return new Promise((resolve, reject) => {
            this.APP = app;
            this.scene = app.scene;
            this.container = new Group();
            this.scene.add(this.container);
            this.material = MaterialManager.Instance().DEFAULT.clone();

            // app.sldBaySize.addAction(this.changeLength.bind(this));
            // app.sldSpan.addAction(this.changeSpan.bind(this));
            app.sldExistingWallHeight.addAction(this.uiChanged.bind(this));
            app.sldExistingLength.addAction(this.uiChanged.bind(this));
            app.sldExistingLength2.addAction(this.uiChanged.bind(this));
            app.sldExistingWidth1.addAction(this.uiChanged.bind(this));
            app.sldExistingWidth2.addAction(this.uiChanged.bind(this));
            app.sltExistingType.addAction(this.uiChanged.bind(this));
            app.sltStructureType.addAction(this.uiChanged.bind(this));
            
            resolve();
        });
    }
    public optimize() : Promise<void> {
        return new Promise((resolve, reject) => {

            let scaleX = env.baySize.default / this.geometryManager.EXISTING_WALL.EXISTING_WALL.length;
            let scaleY = env.eaveHeight.default / this.geometryManager.EXISTING_WALL.EXISTING_WALL.height;
            let scaleZ = env.span.default / this.geometryManager.EXISTING_WALL.EXISTING_WALL.length;

            let translateWidth = this.geometryManager.EXISTING_WALL.EXISTING_WALL.width / 2;
            let translateHeight = this.geometryManager.EXISTING_WALL.EXISTING_WALL.height / 2;
            let translateLength = this.geometryManager.EXISTING_WALL.EXISTING_WALL.length / 2;

            this.geo_existingWallW1 = new GeometryInfo();
            this.geo_existingWallW1.geometry = this.geometryManager.EXISTING_WALL.EXISTING_WALL.geometry
            .clone()
            .rotateY(Math.PI / 2)
            .translate(0, translateHeight, 0);
            this.geo_existingWallW1.width = this.geometryManager.EXISTING_WALL.EXISTING_WALL.width;
            this.geo_existingWallW1.length = this.geometryManager.EXISTING_WALL.EXISTING_WALL.length;
            this.geo_existingWallW1.height = this.geometryManager.EXISTING_WALL.EXISTING_WALL.height;

            this.geo_existingWallW2 = new GeometryInfo();
            this.geo_existingWallW2.geometry = this.geometryManager.EXISTING_WALL.EXISTING_WALL.geometry
            .clone()
            .rotateY(Math.PI / 2)
            .translate(0, translateHeight, translateLength);
            this.geo_existingWallW2.width = this.geometryManager.EXISTING_WALL.EXISTING_WALL.width;
            this.geo_existingWallW2.length = this.geometryManager.EXISTING_WALL.EXISTING_WALL.length;
            this.geo_existingWallW2.height = this.geometryManager.EXISTING_WALL.EXISTING_WALL.height;

            this.geo_existingWallL1 = new GeometryInfo();
            this.geo_existingWallL1.geometry = this.geometryManager.EXISTING_WALL.EXISTING_WALL.geometry
            .clone()
            .translate(0, translateHeight, translateWidth);
            this.geo_existingWallL1.width = this.geometryManager.EXISTING_WALL.EXISTING_WALL.width;
            this.geo_existingWallL1.length = this.geometryManager.EXISTING_WALL.EXISTING_WALL.length;
            this.geo_existingWallL1.height = this.geometryManager.EXISTING_WALL.EXISTING_WALL.height;

            //front
            this.geo_existingWallL2 = new GeometryInfo();
            this.geo_existingWallL2.geometry = this.geometryManager.EXISTING_WALL.EXISTING_WALL.geometry
            .clone()
            .translate(-translateLength, translateHeight, -translateWidth);
            this.geo_existingWallL2.width = this.geometryManager.EXISTING_WALL.EXISTING_WALL.width;
            this.geo_existingWallL2.length = this.geometryManager.EXISTING_WALL.EXISTING_WALL.length;
            this.geo_existingWallL2.height = this.geometryManager.EXISTING_WALL.EXISTING_WALL.height;

            resolve();
        });
    }
    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.container.children = [];
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.EXISTING_WALL));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == "EXISTING_WALL_OUTLINE"));

            if(this.APP.sltStructureType.currentValue == 4){
                resolve();
                return;
            }
            
            this.addEaveWall({length1: true});
            if (this.APP.sldExistingLength2.currentValue > 0) {
                this.addEaveWall({length2: true});
            }
            if (this.APP.sldExistingWidth1.currentValue > 0) {
                this.addEaveWall({width1: true});
            }
            if (this.APP.sldExistingWidth2.currentValue > 0) {
                this.addEaveWall({width2: true});
            }
            
            new HighLightBox(this.container, {
                min: {
                    x: - UI.existingLength1 / 2 - 150 - (this.APP.sldExistingWidth1.currentValue / 2 ? this.geometryManager.EXISTING_WALL.EXISTING_WALL.width : 0), 
                    y: 200, 
                    z: - this.APP.sldExistingWidth1.currentValue / 2 + 75, 
                },
                max: {
                    x: - UI.existingLength1 / 2 + 150 - (this.APP.sldExistingWidth1.currentValue / 2 ? this.geometryManager.EXISTING_WALL.EXISTING_WALL.width : 0), 
                    y: UI.eaveHeight + 200, 
                    z: - this.APP.sldExistingWidth1.currentValue / 2 - 225,
                },
                userData: { wallSide: BUILDING_SIDE.LEFT }
            })
        
            new HighLightBox(this.container, {
                min: {
                    x: UI.existingLength1 / 2 + 150 + (this.APP.sldExistingWidth1.currentValue / 2 ? this.geometryManager.EXISTING_WALL.EXISTING_WALL.width : 0), 
                    y: 200, 
                    z: - this.APP.sldExistingWidth1.currentValue / 2 + 75, 
                },
                max: {
                    x: UI.existingLength1 / 2 - 150 + (this.APP.sldExistingWidth1.currentValue / 2 ? this.geometryManager.EXISTING_WALL.EXISTING_WALL.width : 0), 
                    y: UI.eaveHeight + 200, 
                    z: - this.APP.sldExistingWidth1.currentValue / 2 - 225,
                },
                userData: { wallSide: BUILDING_SIDE.RIGHT }
            })
            
            //this.getOutLines();
            resolve();
        });
    }
    public getOutLines(): Printing2DGeometry{
        let objs = this.scene.children.filter(o => o.userData.type == GEOMETRY_TYPE.EXISTING_WALL );
        let lsGeometries: Printing2DLine[] = [];

        for(let o of objs){            
            let outlineGeo = this.utils.getOutlineGeometryFromMesh((o as Mesh), 10);
            outlineGeo.translate(o.position.x, o.position.y + 5000, o.position.z);

            // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
            // line.userData = {type: "EXISTING_WALL_OUTLINE"};
            // this.scene.add( line );

            let simplifiedGeo = this.simplifyGeo(outlineGeo);
            lsGeometries.push({
                objectType: o.userData.type,
                vertices: simplifiedGeo.vertices, 
                views: o.userData.views
            });
        }

        return { lines: lsGeometries, texts: [] };
    }
    public simplifyGeo(geo: BufferGeometry): Geometry{
        let simplifiedGeo = new Geometry();
        let vertices = geo.getAttribute('position').array;
        for(let i = 0; i < vertices.length; i+=3){
            simplifiedGeo.vertices.push(new Vector3(vertices[i], vertices[i+1] - 5000, vertices[i+2]));
        }
        return simplifiedGeo;
    }
    public addEaveWall(userDataPos: any) {
        let mesh: Mesh;
        let scaleY = this.APP.sldExistingWallHeight.currentValue / this.geometryManager.EXISTING_WALL.EXISTING_WALL.height;

        if (userDataPos.width1) {
            let tt = MaterialManager.Instance().EXISTING_WALL_W1_TEXTURE;
            tt.repeat.set(this.APP.sldExistingWidth1.currentValue / 1024 / 2, this.APP.sldExistingWallHeight.currentValue / 1024 / 2);

            let mat = new MeshPhongMaterial({
                side: DoubleSide,
                map: tt
            });

            let offsetX = this.APP.sldExistingLength.currentValue / 2 + this.geometryManager.EXISTING_WALL.EXISTING_WALL.width / 2;
            let offsetZ = this.geo_existingWallW1.width/2;
            let scaleZ = (this.APP.sldExistingWidth1.currentValue + this.geo_existingWallW1.width) / this.geometryManager.EXISTING_WALL.EXISTING_WALL.length;

            let views = [
                { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
                { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            ]

            if (this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3) {
                mesh = new Mesh(this.geo_existingWallW1.geometry, mat);
                mesh.userData = { type: GEOMETRY_TYPE.EXISTING_WALL, position: {...userDataPos, left: true}, views: [...views, { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS }] };
                mesh.position.setX(-offsetX);
                mesh.position.setZ(-offsetZ);
                mesh.scale.setY(scaleY);
                mesh.scale.setZ(scaleZ);
                this.scene.add(mesh);
            }
            if (this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3) {
                mesh = new Mesh(this.geo_existingWallW1.geometry, mat);
                mesh.userData = { type: GEOMETRY_TYPE.EXISTING_WALL, position: {...userDataPos, right: true}, views: [...views, { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }] };
                mesh.position.setX(offsetX);
                mesh.position.setZ(-offsetZ);
                mesh.scale.setY(scaleY);
                mesh.scale.setZ(scaleZ);
                this.scene.add(mesh);
            }
        } else if (userDataPos.length1) {
            let tt = MaterialManager.Instance().EXISTING_WALL_L1_TEXTURE;
            tt.repeat.set(this.APP.sldExistingLength.currentValue / 1024 / 2, this.APP.sldExistingWallHeight.currentValue / 1024 / 2);

            let mat = new MeshPhongMaterial({
                side: DoubleSide,
                map: tt
            });
            let offsetZ = this.APP.sldExistingWidth1.currentValue / 2 + this.geometryManager.EXISTING_WALL.EXISTING_WALL.width; //this.APP.sldSpan.currentValue/2;
            let scaleX = this.APP.sldExistingLength.currentValue / this.geometryManager.EXISTING_WALL.EXISTING_WALL.length;

            let views = [
                { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
                { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
            ]

            mesh = new Mesh(this.geo_existingWallL1.geometry, mat);
            mesh.userData = { type: GEOMETRY_TYPE.EXISTING_WALL, position: userDataPos, views: views };
            mesh.position.setZ(-offsetZ);
            mesh.scale.setY(scaleY);
            mesh.scale.setX(scaleX);

            this.scene.add(mesh);
        } else if (userDataPos.length2) {
            let tt = MaterialManager.Instance().EXISTING_WALL_L2_TEXTURE;
            tt.repeat.set(this.APP.sldExistingLength2.currentValue / 1024 / 2, this.APP.sldExistingWallHeight.currentValue / 1024 / 2);

            let mat = new MeshPhongMaterial({
                side: DoubleSide,
                map: tt
            });

            let offsetX = this.APP.sldExistingLength.currentValue / 2 + this.geometryManager.EXISTING_WALL.EXISTING_WALL.width;
            let offsetZ = this.APP.sldExistingWidth1.currentValue / 2;
            let scaleX = (this.APP.sldExistingLength2.currentValue - this.geometryManager.EXISTING_WALL.EXISTING_WALL.width) / this.geometryManager.EXISTING_WALL.EXISTING_WALL.length;
            if (scaleX == 0)
                scaleX = 0.1;

            let views = [
                { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
            ];

            if (this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3) {
                views.push({ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS });
                mesh = new Mesh(this.geo_existingWallL2.geometry, mat);
                mesh.userData = { type: GEOMETRY_TYPE.EXISTING_WALL, position: {...userDataPos, left: true}, views: views };
                mesh.position.setX(-offsetX);
                mesh.scale.setY(scaleY);
                mesh.position.setZ(offsetZ);
                mesh.scale.setX(scaleX);
                this.scene.add(mesh);
            }
            if (this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3) {
                views.push({ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS });
                mesh = new Mesh(this.geo_existingWallL2.geometry, mat);
                mesh.userData = { type: GEOMETRY_TYPE.EXISTING_WALL, position: {...userDataPos, right: true}, views: views };
                mesh.position.setX(offsetX);
                mesh.scale.setY(scaleY);
                mesh.position.setZ(offsetZ);
                mesh.scale.setX(-scaleX);
                this.scene.add(mesh);
            }

            //mesh.visible = !(this.APP.sldExistingLength2.currentValue == 0);
        } else if (userDataPos.width2) {
            let tt = MaterialManager.Instance().EXISTING_WALL_W2_TEXTURE;
            tt.repeat.set(this.APP.sldExistingWidth2.currentValue / 1024 / 2, this.APP.sldExistingWallHeight.currentValue / 1024 / 2);

            let mat = new MeshPhongMaterial({
                side: DoubleSide,
                map: tt
            });

            let offsetX = this.APP.sldExistingLength.currentValue / 2 + this.APP.sldExistingLength2.currentValue + this.geometryManager.EXISTING_WALL.EXISTING_WALL.width/2;
            let offsetZ = this.APP.sldExistingWidth1.currentValue / 2 - this.geometryManager.EXISTING_WALL.EXISTING_WALL.width;
            let scaleZ = (this.APP.sldExistingWidth2.currentValue + this.geo_existingWallL2.width) / this.geometryManager.EXISTING_WALL.EXISTING_WALL.length;
            if (scaleZ == 0)
                scaleZ = 0.1;

            let views = [ 
                { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
            ];

            if (this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3) {
                mesh = new Mesh(this.geo_existingWallW2.geometry, mat);
                mesh.userData = { type: GEOMETRY_TYPE.EXISTING_WALL, position: {...userDataPos, left: true}, views: [...views, { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS }] };
                mesh.position.setX(-offsetX);
                mesh.position.setZ(offsetZ);
                mesh.scale.setY(scaleY);
                mesh.scale.setZ(scaleZ);
                this.scene.add(mesh);
            }
            if (this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3) {
                mesh = new Mesh(this.geo_existingWallW2.geometry, mat);
                mesh.userData = { type: GEOMETRY_TYPE.EXISTING_WALL, position: {...userDataPos, right: true}, views: [...views, { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }] };
                mesh.position.setX(offsetX);
                mesh.position.setZ(offsetZ);
                mesh.scale.setY(scaleY);
                mesh.scale.setZ(scaleZ);
                this.scene.add(mesh);
            }

            //mesh.visible = !(this.APP.sldExistingWidth2.currentValue == 0);
        }
        //this.scene.add(mesh);
    }

    public uiChanged(preVal: number, curVal): void {
        this.load();
    }
}
