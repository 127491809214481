import { Component, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Util } from './core/utils';
import { CONFIG, INIT } from './app.config';
import { Router } from '@angular/router';
import { AuthenticationService } from './services';
import { User } from './models';

declare var $: any;

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public env = CONFIG;

  private utils: Util;
  private frameId: number = null;
  public loadCompleted = false;
  currentUser: User;
  public constructor(
    private ngZone: NgZone,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.utils = new Util();
    this.authenticationService.currentUser.subscribe(
      x => (this.currentUser = x)
    );
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  public ngAfterViewInit(): void {

  }
  public ngOnDestroy() {

  }
}
