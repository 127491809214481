import { Mesh, Vector3, Material, Plane, Geometry, BufferGeometry, Group, BoxBufferGeometry, MeshLambertMaterial, BoxHelper, Matrix4, PlaneHelper, Points, PointsMaterial, Color, PlaneBufferGeometry, DoubleSide, Object3D, LineSegments } from 'three';
import { Util } from '../utils';
import { CONFIG as env, GEOMETRY_TYPE, GEOMETRY_CATEGORY } from '../../app.config';
import { BUILDING_SIDE, CUTOUT_ENABLE, EXISTING_BUILDING_CONFIG as CONST, EXISTING_BUILDING_CONFIG, PANEL_DIRECTION, RAKECUT_TYPE } from '../../app.constants';
import { HomeComponent as AppComponent } from '../../containers/home/home.component';
import { GeometryManager } from '../geometry.manager';
import { MaterialManager } from '../material.manager';
import { GeometryInfo, Printing2DGeometry, Printing2DLine, Print2DView, ViewType, LineType } from '../models';
import { GableFBExistingBuildingManager } from '.';
import { CSG } from 'three-csg-ts';
import HighLightBox from 'src/app/models/HighlightBox';
import { UI } from '../ui';
import SelectionManager from '../selection.manager';

export class RoofManager {
  private scene: Group;
  private APP: AppComponent;
  private MANAGER: GableFBExistingBuildingManager;
  private material: Material;
  private utils: Util;

  private geometryManager: GeometryManager;

  public ridgeGeo: GeometryInfo;
  private roofInfo: GeometryInfo;
  private roofBaseInfo: GeometryInfo;
  private bargeLeftInfo: GeometryInfo;
  private bargeRightInfo: GeometryInfo;
  private receiverCutoutLeftHor: GeometryInfo;
  private receiverCutoutRightHor: GeometryInfo;
  private receiverCutoutRightVer: GeometryInfo;
  private receiverCutoutRightVer2: GeometryInfo;
  private receiverCutoutLeftVer: GeometryInfo;
  private receiverCutoutLeftVer2: GeometryInfo;
  private gutterInfo: GeometryInfo;
  private gutterRakecutLeftInfo: GeometryInfo;
  private gutterRakecutRightInfo: GeometryInfo;
  //private flashingInfo: GeometryInfo;
  private geo_receiverChanel: GeometryInfo;
  private zFlashingGeo: GeometryInfo;
  private zFlashingRakecutRightGeo: GeometryInfo;
  private geo_gutterCap: GeometryInfo;
  private roofGroupRight: Group;
  private roofGroupLeft: Group;

  private eventHandleId: any;

  // Var
  private roofLengthPlus: number;
  private roofWidth: number;
  private roofLength: number;
  private totalBaySize: number;

  private objectSizeChangedHandle: any;

  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;

  private mesh_CutoutLeft: Mesh;
  private csg_cutoutLeft: CSG;
  private mesh_CutoutRight: Mesh;
  private csg_cutoutRight: CSG;
  private geo_cutoutLeft: BoxBufferGeometry;
  private geo_cutoutRight: BoxBufferGeometry;

  private geo_rakecutLeft: BoxBufferGeometry;
  private geo_rakecutRight: BoxBufferGeometry;
  private mesh_rakecutLeft: Mesh;
  private mesh_rakecutRight: Mesh;
  private csg_rakecutLeft: CSG;
  private csg_rakecutRight: CSG;

  private geoCutGutter: BoxBufferGeometry;
  private meshCutGutterLeft1: Mesh;
  private csgCutGutterLeft1: CSG;
  private meshCutGutterLeft2: Mesh;
  private csgCutGutterLeft2: CSG;

  private meshCutGutterRight1: Mesh;
  private csgCutGutterRight1: CSG;
  private meshCutGutterRight2: Mesh;
  private csgCutGutterRight2: CSG;

  private enableCutout: boolean;
  private deferHandle;
  private deferTimeout = EXISTING_BUILDING_CONFIG.CUTOUT_DEFFER_TIME_OUT;


  constructor(app: AppComponent, existingManager: GableFBExistingBuildingManager) {
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.APP = app;
    this.MANAGER = existingManager;
    this.scene = new Group();
    existingManager.patiosGroup.add(this.scene)
    this.material = MaterialManager.Instance().ROOF;
    this.registerEvent();
  }

  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.roofInfo = this.geometryManager.getRoofSheetPanel();
      this.roofInfo.geometry
        .translate(-this.roofInfo.width / 2 + 10, -1, this.roofInfo.length / 2)
        .rotateY(Math.PI / 2);

      this.roofBaseInfo = this.geometryManager.getRoofBase();
      this.roofBaseInfo.geometry
        .translate(-this.roofBaseInfo.width / 2, this.roofBaseInfo.height / 2, this.roofBaseInfo.length / 2)
        .rotateY(Math.PI / 2);

      this.zFlashingGeo = this.geometryManager.getZFlashing();
      this.zFlashingGeo.geometry.translate(0, -3, 0).rotateY(Math.PI / 2);
      this.zFlashingRakecutRightGeo = this.geometryManager.getZFlashing();
      this.zFlashingRakecutRightGeo.geometry.translate(-this.zFlashingRakecutRightGeo.length, 0, 0);

      // Barge capping - left
      this.bargeLeftInfo = this.geometryManager.getBarge();
      this.bargeLeftInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(this.bargeLeftInfo.length / 2, this.bargeLeftInfo.height / 2 - (this.APP.sltDripBarge.currentValue ? 9 : 1), -this.bargeLeftInfo.width / 2 + 3);

      // Barge capping - right
      this.bargeRightInfo = this.geometryManager.getBarge();
      this.bargeRightInfo.geometry
        .rotateY(Math.PI / 2)
        .translate(this.bargeRightInfo.length / 2, this.bargeRightInfo.height / 2 - (this.APP.sltDripBarge.currentValue ? 9 : 1), this.bargeRightInfo.width / 2 - 3);

      //Barge cutout left
      this.receiverCutoutLeftHor = this.geometryManager.getReceiverChannel();
      this.receiverCutoutLeftHor.geometry
        .rotateY(Math.PI / 2)
        .translate(-this.receiverCutoutLeftHor.length / 2, this.receiverCutoutLeftHor.height / 2, this.receiverCutoutLeftHor.width / 2);

      //Barge cutout right
      this.receiverCutoutRightHor = this.geometryManager.getReceiverChannel();
      this.receiverCutoutRightHor.geometry
        .rotateY(Math.PI / 2)
        .translate(this.receiverCutoutRightHor.length / 2, this.receiverCutoutRightHor.height / 2, this.receiverCutoutRightHor.width / 2);

        
      //Vertical Barge cutout left 2
      this.receiverCutoutLeftVer2 = this.geometryManager.getReceiverChannel();
      this.receiverCutoutLeftVer2.geometry
        .rotateY(Math.PI)
        .translate(this.receiverCutoutLeftVer2.width / 2, this.receiverCutoutLeftVer2.height / 2, this.receiverCutoutLeftVer2.length / 2);

      //Vertical cutout right 2
      this.receiverCutoutRightVer2 = this.geometryManager.getReceiverChannel();
      this.receiverCutoutRightVer2.geometry
        .translate(-this.receiverCutoutRightVer2.width / 2, this.receiverCutoutRightVer2.height / 2, this.receiverCutoutRightVer2.length / 2);

      //Vertical Barge cutout left
      this.receiverCutoutLeftVer = this.geometryManager.getReceiverChannel();
      this.receiverCutoutLeftVer.geometry
        .rotateY(Math.PI)
        .translate(this.receiverCutoutLeftVer.width / 2, this.receiverCutoutLeftVer.height / 2, this.receiverCutoutLeftVer.length / 2);

      //Vertical cutout right
      this.receiverCutoutRightVer = this.geometryManager.getReceiverChannel();
      this.receiverCutoutRightVer.geometry
        .translate(-this.receiverCutoutRightVer.width / 2, this.receiverCutoutRightVer.height / 2, this.receiverCutoutRightVer.length / 2);

      //Receiver chanel - barge caping back
      this.geo_receiverChanel = this.geometryManager.getReceiverChannel();
      this.geo_receiverChanel.geometry
        .rotateY(Math.PI / 2)
        .translate(this.geo_receiverChanel.length / 2, this.geo_receiverChanel.height / 2, this.geo_receiverChanel.width / 2);

      // Gutter
      let gtExtraOffsetY = 0;
      if (this.APP.sltGutterType.currentValue == 1 || this.APP.sltGutterType.currentValue == 2) {
        gtExtraOffsetY = 20;
      }

      this.gutterInfo = this.geometryManager.getGutter();
      this.gutterInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(this.gutterInfo.length / 2, -20, this.gutterInfo.width / 2);

      this.gutterRakecutLeftInfo = this.geometryManager.getGutter();
      this.gutterRakecutLeftInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(this.gutterRakecutLeftInfo.length / 2, -20, this.gutterRakecutLeftInfo.width / 2);

      this.gutterRakecutRightInfo = this.geometryManager.getGutter();
      this.gutterRakecutRightInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(-this.gutterRakecutRightInfo.length / 2, -20, this.gutterRakecutRightInfo.width / 2);

      //Gutter cap
      this.geo_gutterCap = this.geometryManager.getGuterEndCap();
      this.geo_gutterCap.geometry
        .rotateY(-Math.PI / 2)
        .translate(0, -20, this.geo_gutterCap.length / 2);

      this.geo_cutoutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutLeft.translate(-500, 0, -500);

      this.geo_cutoutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutRight.translate(500, 0, -500);

      this.geo_rakecutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutLeft.translate(-500, 500, 500);
      this.geo_rakecutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutRight.translate(500, 500, 500);

      this.geoCutGutter = new BoxBufferGeometry(500, 10000, 500);
      this.geoCutGutter.translate(0, 0, -250);

      this.ridgeGeo = this.geometryManager.getRidge();
      this.ridgeGeo.geometry.translate(- this.ridgeGeo.width / 2, -this.ridgeGeo.height / 2, 0);

      resolve();
    });
  }
  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(...this.scene.children.filter(x => x.userData.type === GEOMETRY_TYPE.ROOF_PANEL 
        || x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BASE
        || x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING
        || x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        || x.userData.type === GEOMETRY_TYPE.GUTTER_PATIOS
        || x.userData.type === GEOMETRY_TYPE.ROOF_PATIOS
        || x.userData.type === GEOMETRY_TYPE.HIGHLIGHT_BOX));

      // this.scene.remove(...this.scene.children.filter(x => x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BASE));
      // this.scene.remove(...this.scene.children.filter(x => x.userData.type === this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING));
      // this.scene.remove(...this.scene.children.filter(x => x.userData.type === GEOMETRY_TYPE.GUTTER_PATIOS));
      // this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PATIOS));
      // this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PATIOS));
      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.SHEET_OUTLINE));

      //test
      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'HELPER'));
      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'RAKECUT'));
      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'CUTGUTTER'));

      //this.scene.remove(...this.scene.children.filter(x => x.userData.type === 'ROOF_OUTLINE'));

      this.totalBaySize = this.APP.dialogEditBay.totalBaySize;
      this.roofLength = UI.span / 2 + UI.overhangRight;
      this.roofLengthPlus = (UI.span / 2 + UI.overhangRight) / this.utils.cos(UI.patiosPitch);
      this.roofWidth = this.totalBaySize + UI.overhangFront;

      this.addRoofGroupRight();
      this.addRoofGroupLeft();
      this.addCutout();

      let roofPanelCount = Math.ceil(this.roofWidth / this.roofInfo.width);
      this.enableCutout = this.MANAGER.cutoutCondition;

      //clipping plane
      let offsetFront = this.totalBaySize + UI.overhangFront - UI.existingWidth1 / 2;
      let offsetBack = - UI.overhangBack - UI.existingWidth1 / 2;

      let clipingPlaneFront = new Plane(new Vector3(0, 0, -1), offsetFront);
      let clipingPlaneBack = new Plane(new Vector3(0, 0, 1), -offsetBack);
    
      this.material.clippingPlanes = [clipingPlaneFront, clipingPlaneBack];
      MaterialManager.Instance().ROOF_BASE.clippingPlanes = [clipingPlaneFront, clipingPlaneBack];

      //panel direction
      let offsetZ = 0;
      let panelDirectionOfset = 1;
      if(this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.RIGHT_TO_LEFT){
        offsetZ = this.totalBaySize + UI.overhangFront - this.roofInfo.width;
        panelDirectionOfset = -1;
      }
      else if(this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.LEFT_TO_RIGHT){
        offsetZ = 0;
      }

      if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT && this.enableCutout) {
        let totalPanelLength = 0;
        let cuted = true;
        for (let index = 0; index < roofPanelCount; index++) {
          totalPanelLength += this.roofInfo.width;
          let isOver = totalPanelLength < this.APP.sldExistingLength2.currentValue;

          this.addRoofBaseRight(offsetZ, [this.csg_cutoutLeft] );
          this.addRoofRight(offsetZ, [this.csg_cutoutLeft]);

          this.addRoofBaseLeft(offsetZ, [this.csg_cutoutLeft] );
          this.addRoofLeft(offsetZ, [this.csg_cutoutLeft]);

          cuted = isOver;
          offsetZ += this.roofInfo.width * panelDirectionOfset;
        }
      }
      else if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT && this.enableCutout) {
        let totalPanelLength = 0;
        let cuted = false;
        for (let index = 0; index < roofPanelCount; index++) {
          totalPanelLength += this.roofInfo.width;
          let isOver = totalPanelLength >= this.roofWidth - this.APP.sldExistingLength2.currentValue + this.roofInfo.width;
          cuted = totalPanelLength >= this.roofWidth - this.APP.sldExistingLength2.currentValue && !isOver;
          this.addRoofBaseRight(offsetZ, [this.csg_cutoutRight] );
          this.addRoofRight(offsetZ, [this.csg_cutoutRight] );

          this.addRoofBaseLeft(offsetZ, [this.csg_cutoutRight] );
          this.addRoofLeft(offsetZ, [this.csg_cutoutRight]);

          offsetZ += this.roofInfo.width * panelDirectionOfset;
        }
      }
      else if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH && this.enableCutout) {
        let totalPanelLength = 0;
        let cuted = false;
        let cuted2 = false;
        for (let index = 0; index < roofPanelCount; index++) {
          totalPanelLength += this.roofInfo.width;
          let isOver = totalPanelLength < this.APP.sldExistingLength2.currentValue;
          let isOver2 = totalPanelLength >= this.roofWidth - this.APP.sldExistingLength2.currentValue + this.roofInfo.width;
          let flag = totalPanelLength >= this.roofWidth - this.APP.sldExistingLength2.currentValue;

          this.addRoofBaseRight(offsetZ, [this.csg_cutoutLeft, this.csg_cutoutRight]);
          this.addRoofRight(offsetZ, [this.csg_cutoutLeft, this.csg_cutoutRight]);

          this.addRoofBaseLeft(offsetZ, [this.csg_cutoutLeft, this.csg_cutoutRight] );
          this.addRoofLeft(offsetZ, [this.csg_cutoutLeft, this.csg_cutoutRight]);

          offsetZ += this.roofInfo.width * panelDirectionOfset;
        }
      }
      else {
        for (let index = 0; index < roofPanelCount; index++) {
          this.addRoofBaseRight(offsetZ, []);
          this.addRoofRight(offsetZ, []);

          this.addRoofBaseLeft(offsetZ, [] );
          this.addRoofLeft(offsetZ, []);

          offsetZ += this.roofInfo.width * panelDirectionOfset;
        }
      }

      this.addZFlashingRight();
      this.addZFlashingLeft();

      this.addBargeRight();
      this.addBargeLeft();

      this.addGutterRight();
      this.addGutterLeft();

      this.addRidgeCapping();

      this.showPanelOutline();

      this.scene.visible = UI.showRoof

      //this.getOutLines();
      resolve();
    });
  }
  private addCutout() {
    if (this.APP.sltCutOut.currentValue == 1) {
      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'CUTOUT'));

      let offsetX = this.APP.sldExistingLength.currentValue / 2;
      let offsetY = 0;
      let offsetZ = this.APP.sldExistingWidth1.currentValue / 2;

      let scaleX = (this.APP.sldExistingLength2.currentValue + 5000) / 1000;
      if (scaleX == 0) scaleX = 1;
      let scaleY = (this.APP.sldExistingWallHeight.currentValue + 5000) / 1000;
      let scaleZ = (this.APP.sldExistingWidth1.currentValue + 5000) / 1000;
      if (scaleZ == 0) scaleZ = 1;

      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT || this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        this.mesh_CutoutLeft = new Mesh(this.geo_cutoutLeft, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
        this.mesh_CutoutLeft.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: 'CUTOUT' };

        this.mesh_CutoutLeft.position.set(-offsetX, offsetY, offsetZ);
        this.mesh_CutoutLeft.scale.set(scaleX, scaleY, scaleZ);

        //this.APP.scene.add(this.mesh_CutoutLeft);

        this.mesh_CutoutLeft.updateMatrix();
        this.csg_cutoutLeft = CSG.fromMesh(this.mesh_CutoutLeft);
      }
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT || this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        this.mesh_CutoutRight = new Mesh(this.geo_cutoutRight, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
        this.mesh_CutoutRight.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: 'CUTOUT' };

        this.mesh_CutoutRight.position.set(offsetX, offsetY, offsetZ);
        this.mesh_CutoutRight.scale.set(scaleX, scaleY, scaleZ);

        //this.APP.scene.add(this.mesh_CutoutRight);

        this.mesh_CutoutRight.updateMatrix();
        this.csg_cutoutRight = CSG.fromMesh(this.mesh_CutoutRight);
      }
    }
  }
  
  public addRoofGroupRight() {
    this.roofGroupRight = null;
    this.roofGroupRight = new Group();
    this.roofGroupRight.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PATIOS };

    //let offsetX = -this.APP.sldLeftOverhang.currentValue;
    let offsetY = this.getRoofOffsetY();
    let offsetZ = -UI.existingWidth1 / 2;

    this.roofGroupRight.position.set(0, offsetY, offsetZ);
    this.roofGroupRight.rotation.set(0, 0, -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue));

    this.scene.add(this.roofGroupRight);
  }
  public addRoofGroupLeft() {
    this.roofGroupLeft = null;
    this.roofGroupLeft = new Group();
    this.roofGroupLeft.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PATIOS };

    //let offsetX = -this.APP.sldLeftOverhang.currentValue;
    let offsetY = this.getRoofOffsetY();
    let offsetZ = -UI.existingWidth1 / 2;

    this.roofGroupLeft.position.set(0, offsetY, offsetZ);
    this.roofGroupLeft.rotation.set(0, 0, this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue));

    this.scene.add(this.roofGroupLeft);
  }
  public getOutLines(): Printing2DGeometry {

    let objs = this.roofGroupRight.children.filter(o =>
      o.userData.type == GEOMETRY_TYPE.ROOF_PANEL
      || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    ).concat(
      ...this.roofGroupLeft.children.filter(o =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
      )
    );

    let gutterGroupObjs = this.roofGroupRight.children.filter(o => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS).concat(
      ...this.roofGroupLeft.children.filter(o => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS)
    );
    let gutterObjs: Object3D[] = [];
    for(let group of gutterGroupObjs){
      gutterObjs.push(...group.children);
    }

    objs = [...objs, ...gutterObjs];

    let lsGeometries: Printing2DLine[] = [];

    let offsetFront = this.totalBaySize + UI.overhangFront - UI.existingWidth1 / 2;
    let offsetBack = - UI.overhangBack - UI.existingWidth1 / 2;

    let clipingPlaneFront = new Plane(new Vector3(0, 0, -1), offsetFront);
    let clipingPlaneBack = new Plane(new Vector3(0, 0, 1), -offsetBack);

    for (let o of objs) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale((o as Mesh), 60);
      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);
      outlineGeo.translate(0, 5000, 0);

      if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL) {
        outlineGeo = this.utils.clipOutline(outlineGeo, clipingPlaneFront);
        outlineGeo = this.utils.clipOutline(outlineGeo, clipingPlaneBack);
      }
      if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE) {
        outlineGeo = this.utils.clipOutlineBasePanel(outlineGeo, clipingPlaneFront);
        outlineGeo = this.utils.clipOutlineBasePanel(outlineGeo, clipingPlaneBack);
      }

      let simplifiedGeo = this.simplifyGeo(outlineGeo);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: simplifiedGeo.vertices,
        views: o.userData.views
      });
    }

    let ridgeCapping = this.scene.children.find(el => el.userData.type === GEOMETRY_TYPE.RIDGE_CAPPING)
    if(ridgeCapping){
      ridgeCapping.children.forEach(el => {
        this.utils.createOutline(el as Mesh, lsGeometries);
      })
    }

    lsGeometries.push({
      objectType: GEOMETRY_TYPE.ROOF_COVER,
      vertices: this.makeRoofPolygon(),
      color: this.utils.convertHexColorToRgb(`#${new Color(UI.panelColor).getHexString()}`),
      views: [
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
      ]
    });

    return { lines: lsGeometries, texts: [] };
  }
  makeRoofPolygon() {
    const polygon = this.utils.getRoofCoverPolygonForFBRoof()
    this.MANAGER.patiosGroup.updateWorldMatrix(true, true)
    return polygon.map(el => (el as Vector3).applyMatrix4(this.MANAGER.patiosGroup.matrixWorld).add(new Vector3(0, 0, -UI.existingWidth1 / 2 - UI.overhangBack)))
  }
  public showPanelOutline(){
    if(this.APP.sltPanelDirectionShow.currentValue == false)
        return;

    let objs = this.roofGroupRight.children.filter(o =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    ).concat(
      ...this.roofGroupLeft.children.filter(o =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
      )
    );

    let offsetFront = this.totalBaySize + UI.overhangFront - UI.existingWidth1 / 2;
    let offsetBack = - UI.overhangBack - UI.existingWidth1 / 2;

    let clipingPlaneFront = new Plane(new Vector3(0, 0, -1), offsetFront);
    let clipingPlaneBack = new Plane(new Vector3(0, 0, 1), -offsetBack);

    for (let o of objs) {
        let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale((o as Mesh), 60);

        o.updateWorldMatrix(true, true);
        outlineGeo.applyMatrix4(o.matrixWorld);
        //outlineGeo.translate(0, 5000, 0);

        if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE) {
            outlineGeo = this.utils.clipOutline(outlineGeo, clipingPlaneFront);
            outlineGeo = this.utils.clipOutline(outlineGeo, clipingPlaneBack);
        }

        var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
        line.userData = {type: GEOMETRY_TYPE.SHEET_OUTLINE};
        this.APP.scene.add( line );
    }
}
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let simplifiedGeo = new Geometry();
    let vertices = geo.getAttribute('position').array;
    for (let i = 0; i < vertices.length; i += 3) {
      simplifiedGeo.vertices.push(new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2]));
    }
    return simplifiedGeo;
  }
  public addRoofRight(offsetZ: number, csgObjs: CSG[]) {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      // { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];
    const mesh: Mesh = new Mesh(this.roofInfo.geometry, MaterialManager.Instance().ROOF);

    let offsetSheet = 0;
    if (this.APP.sltRoofSheetingType.currentValue == 0 || this.APP.sltRoofSheetingType.currentValue == 1) {
      offsetSheet = 13;
    }
    else {
      offsetSheet = 5;
    }

    const offsetPitch = this.roofBaseInfo.height * this.utils.tan(UI.patiosPitch)

    let scaleZ = ((UI.span / 2 + UI.overhangRight) / this.utils.cos(UI.patiosPitch) - offsetPitch) / this.roofInfo.length;
    const offsetY = this.roofBaseInfo.height - offsetSheet;
    let offsetX = 0;
    mesh.position.set(offsetX, offsetY, offsetZ);
    mesh.scale.setX(scaleZ);
    mesh.userData = { 
      category: GEOMETRY_CATEGORY.PATIOS, 
      type: GEOMETRY_TYPE.ROOF_PANEL, 
      views: views,
      dir: UI.panelDirection === PANEL_DIRECTION.RIGHT_TO_LEFT ? "L-R" : "R-L"
    };
    
    let cutoutCondition = this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue != BUILDING_SIDE.NONE && this.APP.sldExistingLength2.currentValue > 0;
    
    if (cutoutCondition) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      mesh.position.set(offsetX + this.MANAGER.patiosGroup.position.x, offsetY, offsetZ + this.roofGroupRight.position.z);

      mesh.updateMatrix();

      let meshResult = mesh.clone();

      if(cutoutCondition){
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
        }
      }
      
      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix));
      meshResult.applyMatrix4(matrixBefore);

      this.roofGroupRight.add(meshResult);
    }
    else {
      this.roofGroupRight.add(mesh);
    }
  }
  public addRoofLeft(offsetZ: number, csgObjs: CSG[]) {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      // { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];
    const mesh: Mesh = new Mesh(this.roofInfo.geometry, MaterialManager.Instance().ROOF);

    let offsetSheet = 0;
    if (this.APP.sltRoofSheetingType.currentValue == 0 || this.APP.sltRoofSheetingType.currentValue == 1) {
      offsetSheet = 13;
    }
    else {
      offsetSheet = 5;
    }

    const offsetPitch = this.roofBaseInfo.height * this.utils.tan(UI.patiosPitch)

    let scaleZ = ((UI.span / 2 + UI.overhangLeft) / this.utils.cos(UI.patiosPitch) - offsetPitch) / this.roofInfo.length;
    const offsetY = this.roofBaseInfo.height - offsetSheet;
    let offsetX = 0;
    mesh.position.set(offsetX - scaleZ * this.roofInfo.length, offsetY, offsetZ);
    mesh.scale.setX(scaleZ);
    mesh.userData = { 
      category: GEOMETRY_CATEGORY.PATIOS, 
      type: GEOMETRY_TYPE.ROOF_PANEL, 
      views: views,
      dir: UI.panelDirection === PANEL_DIRECTION.LEFT_TO_RIGHT ? "L-R" : "R-L"
    };
    
    let cutoutCondition = this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue != BUILDING_SIDE.NONE && this.APP.sldExistingLength2.currentValue > 0;
    
    if (cutoutCondition) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      mesh.position.set(offsetX - scaleZ * this.roofInfo.length + this.MANAGER.patiosGroup.position.x, offsetY, offsetZ + this.roofGroupRight.position.z);

      mesh.updateMatrix();

      let meshResult = mesh.clone();

      if(cutoutCondition){
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
        }
      }
      
      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix));
      meshResult.applyMatrix4(matrixBefore);

      this.roofGroupLeft.add(meshResult);
    }
    else {
      this.roofGroupLeft.add(mesh);
    }
  }
  public addRoofBaseRight(offsetZ: number, csgObjs: CSG[]) {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      // { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];

    const mesh: Mesh = new Mesh(this.roofBaseInfo.geometry, MaterialManager.Instance().ROOF_BASE);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PANEL_BASE, views: views };

    const offsetPitch = this.roofBaseInfo.height * this.utils.tan(UI.patiosPitch)

    let scaleZ = ((UI.span / 2 + UI.overhangRight) / this.utils.cos(UI.patiosPitch) - offsetPitch - 60) / this.roofBaseInfo.length;
    const offsetY = 0;
    let offsetX = 0;

    mesh.position.set(offsetX, offsetY, offsetZ);
    mesh.scale.setX(scaleZ);

    let cutoutCondition = this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue != BUILDING_SIDE.NONE && this.APP.sldExistingLength2.currentValue > 0;

    if (cutoutCondition) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      mesh.position.set(offsetX + this.MANAGER.patiosGroup.position.x, offsetY, offsetZ + this.roofGroupRight.position.z);
      mesh.updateMatrix();

      let meshResult = mesh.clone();
      
      if(cutoutCondition){
        //meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
        }
      }
      
      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix));
      meshResult.applyMatrix4(matrixBefore);

      this.roofGroupRight.add(meshResult);
    }
    else {
      this.roofGroupRight.add(mesh);
    }
  }
  public addRoofBaseLeft(offsetZ: number, csgObjs: CSG[]) {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      // { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];

    const mesh: Mesh = new Mesh(this.roofBaseInfo.geometry, MaterialManager.Instance().ROOF_BASE);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PANEL_BASE, views: views };

    const offsetPitch = this.roofBaseInfo.height * this.utils.tan(UI.patiosPitch)

    let scaleZ = ((UI.span / 2 + UI.overhangLeft) / this.utils.cos(UI.patiosPitch) - offsetPitch - 60) / this.roofBaseInfo.length;
    const offsetY = 0;
    let offsetX = 0;

    mesh.position.set(offsetX - scaleZ * this.roofBaseInfo.length, offsetY, offsetZ);
    mesh.scale.setX(scaleZ);

    let cutoutCondition = this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue != BUILDING_SIDE.NONE && this.APP.sldExistingLength2.currentValue > 0;

    if (cutoutCondition) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      mesh.position.set(offsetX + this.MANAGER.patiosGroup.position.x - scaleZ * this.roofBaseInfo.length, offsetY, offsetZ + this.roofGroupRight.position.z);
      mesh.updateMatrix();

      let meshResult = mesh.clone();
      
      if(cutoutCondition){
        //meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
        }
      }
      
      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix));
      meshResult.applyMatrix4(matrixBefore);

      this.roofGroupLeft.add(meshResult);
    }
    else {
      this.roofGroupLeft.add(mesh);
    }
  }
  private addBargeRight() {
    let mesh: Mesh;
    let extraWallWidth = this.APP.existingWallManager.geo_existingWallW1.width + EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
    let offsetXL2 = 0;

    let offsetXR2 = 0;

    let offsetZL = this.totalBaySize + UI.overhangFront;
    let offsetZR = 0;

    let offsetY = 0;
    let bargeLeftLength = UI.span / 2 + UI.overhangRight;
    let bargeRightLength = UI.span / 2 + UI.overhangRight;

    let views: Print2DView[] = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];

    const offsetPitch = this.roofBaseInfo.height * this.utils.tan(UI.patiosPitch)

    let scaleZLeft = (this.utils.getHypotenuseByCos(bargeLeftLength, this.APP.sltRoofPitch.currentValue) - offsetPitch) / this.bargeLeftInfo.length;
    let scaleZRight = (this.utils.getHypotenuseByCos(bargeRightLength, this.APP.sltRoofPitch.currentValue) - offsetPitch) / this.bargeLeftInfo.length;

    let fitScale = this.bargeLeftInfo.height * this.utils.tan(UI.patiosPitch) / this.bargeLeftInfo.length;

    //left
    mesh = new Mesh(this.bargeLeftInfo.geometry, MaterialManager.Instance().BARGE);
    mesh.position.set(offsetXL2 - fitScale * this.bargeLeftInfo.length, offsetY, offsetZL);
    mesh.scale.setX(scaleZLeft + fitScale);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
    this.roofGroupRight.add(mesh);
    
    // back
    fitScale = this.geo_receiverChanel.height * this.utils.tan(UI.patiosPitch) / this.geo_receiverChanel.length;
    mesh = new Mesh(this.geo_receiverChanel.geometry, MaterialManager.Instance().RECEIVER_CHANEL);
    mesh.position.set(- fitScale * this.bargeRightInfo.length, offsetY, 0);
    mesh.scale.setX(scaleZRight + fitScale);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RECEIVER_CHANEL, views: views };
    this.roofGroupRight.add(mesh);
  }
  private addBargeLeft() {
    let mesh: Mesh;
    let extraWallWidth = this.APP.existingWallManager.geo_existingWallW1.width + EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
    let offsetXL2 = 0;

    let offsetXR2 = 0;

    let offsetZL = this.totalBaySize + UI.overhangFront;
    let offsetZR = 0;

    let offsetY = 0;
    let bargeLeftLength = UI.span / 2 + UI.overhangLeft;
    let bargeRightLength = UI.span / 2 + UI.overhangLeft;

    let views: Print2DView[] = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];
    const offsetPitch = this.roofBaseInfo.height * this.utils.tan(UI.patiosPitch)
    let scaleZLeft = (this.utils.getHypotenuseByCos(bargeLeftLength, this.APP.sltRoofPitch.currentValue) - offsetPitch) / this.bargeLeftInfo.length;
    let scaleZRight = (this.utils.getHypotenuseByCos(bargeRightLength, this.APP.sltRoofPitch.currentValue) - offsetPitch) / this.bargeLeftInfo.length;

    let fitScale = this.bargeLeftInfo.height * this.utils.tan(UI.patiosPitch) / this.bargeLeftInfo.length;

    //left
    mesh = new Mesh(this.bargeLeftInfo.geometry, MaterialManager.Instance().BARGE);
    mesh.position.set(offsetXL2 - scaleZLeft * this.bargeLeftInfo.length, offsetY, offsetZL);
    mesh.scale.setX(scaleZLeft + fitScale);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
    this.roofGroupLeft.add(mesh);
    
    // back
    fitScale = this.geo_receiverChanel.height * this.utils.tan(UI.patiosPitch) / this.geo_receiverChanel.length;
    mesh = new Mesh(this.geo_receiverChanel.geometry, MaterialManager.Instance().RECEIVER_CHANEL);
    mesh.position.set(- scaleZLeft * this.bargeRightInfo.length, offsetY, 0);
    mesh.scale.setX(scaleZRight + fitScale);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RECEIVER_CHANEL, views: views };
    this.roofGroupLeft.add(mesh);
  }
  public addGutterRight() {
    let offsetXL = (UI.span / 2 + UI.overhangRight - CONST.GUTTER_ROOF_OFFSET_Z) / this.utils.cos(UI.patiosPitch);
    let offsetY = this.roofBaseInfo.height - 25;
    let offsetZ = this.totalBaySize + UI.overhangFront;

    let gutterLength = this.roofWidth;
    let exLength = 300

    let views: Print2DView[] = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        // { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];

    let gutterGroup = this.utils.createGutterGroup(this.gutterInfo, this.geo_gutterCap,
        gutterLength, new Vector3(offsetXL, offsetY, offsetZ), new Vector3(0, Math.PI / 2, 0),
        views, 1, true, true);
    gutterGroup.rotateX(-this.utils.degreesToRadians(UI.patiosPitch))
    this.roofGroupRight.add(gutterGroup); 
  }
  public addGutterLeft() {
    let offsetXL = (UI.span / 2 + UI.overhangLeft - CONST.GUTTER_ROOF_OFFSET_Z) / this.utils.cos(UI.patiosPitch);
    let offsetY = this.roofBaseInfo.height - 25;
    let offsetZ = 0;

    let gutterLength = this.roofWidth;
    let exLength = 300

    let views: Print2DView[] = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        // { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];

    let gutterGroup = this.utils.createGutterGroup(this.gutterInfo, this.geo_gutterCap,
        gutterLength, new Vector3(-offsetXL, offsetY, offsetZ), new Vector3(0, -Math.PI / 2, 0),
        views, 1, true, true);
    gutterGroup.rotateX(-this.utils.degreesToRadians(UI.patiosPitch))
    this.roofGroupLeft.add(gutterGroup); 
  }
  public addZFlashingRight() {
    let offsetX = (UI.span / 2 + UI.overhangRight) / this.utils.cos(UI.patiosPitch);
    let offsetY = this.roofBaseInfo.height - 25;
    const offsetZ = this.totalBaySize + UI.overhangFront;
    let zFlashingLength = this.roofWidth;

    let scaleX = zFlashingLength / this.zFlashingGeo.length;
    
    let mesh = new Mesh(this.zFlashingGeo.geometry, MaterialManager.Instance().ZFLASHING);
    mesh.position.set(offsetX, offsetY, offsetZ);
    mesh.scale.set(1, 1, scaleX);

    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ZFLASHING, scale: mesh.scale };
    this.roofGroupRight.add(mesh);
  }
  public addZFlashingLeft() {
    let offsetX = (UI.span / 2 + UI.overhangLeft) / this.utils.cos(UI.patiosPitch);
    let offsetY = this.roofBaseInfo.height - 25;
    const offsetZ = - UI.overhangBack;
    let zFlashingLength = this.roofWidth;

    let scaleX = zFlashingLength / this.zFlashingGeo.length;
    
    let mesh = new Mesh(this.zFlashingGeo.geometry, MaterialManager.Instance().ZFLASHING);
    mesh.position.set(-offsetX, offsetY, offsetZ);
    mesh.scale.set(1, 1, scaleX);
    mesh.rotateY(Math.PI)

    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ZFLASHING, scale: mesh.scale };
    this.roofGroupLeft.add(mesh);
  }
  public addRidgeCapping(){
    this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.RIDGE_CAPPING));

    let ridgeFront = this.createRidgeCapping();

    const height = this.getRoofOffsetY() + 
        this.roofBaseInfo.height / this.utils.cos(this.APP.sltRoofPitch.currentValue) + 
        this.roofInfo.height / this.utils.cos(this.APP.sltRoofPitch.currentValue) - 
        (this.APP.sltRoofSheetingType.currentValue == 0 || this.APP.sltRoofSheetingType.currentValue == 1 ? 20 : 10);
    
    let offsetX = 0
    
    const ridgeLength = this.totalBaySize + UI.overhangFront;
    ridgeFront.scale.setZ(ridgeLength / this.ridgeGeo.length);
    ridgeFront.position.set( offsetX, height, this.totalBaySize / 2 + UI.overhangFront / 2 - UI.existingWidth1 / 2);
    ridgeFront.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RIDGE_CAPPING, length: ridgeLength, views: [{ viewType: ViewType.FRONT, lineType: LineType.CONTINOUS }]};
    this.scene.add(ridgeFront)
  }
  public createRidgeCapping(){
    let leftCap = new Mesh(this.ridgeGeo.geometry, MaterialManager.Instance().RIDGE_CAPPING);
    let rightCap = new Mesh(this.ridgeGeo.geometry, MaterialManager.Instance().RIDGE_CAPPING);

    rightCap.rotateY(Math.PI)

    leftCap.rotateZ(this.utils.degreesToRadians(UI.patiosPitch))
    rightCap.rotateZ(this.utils.degreesToRadians(UI.patiosPitch))

    leftCap.userData = {views: [{ viewType: ViewType.FRONT, lineType: LineType.CONTINOUS }]}
    rightCap.userData = {views: [{ viewType: ViewType.FRONT, lineType: LineType.CONTINOUS }]}

    return new Group().add(leftCap, rightCap)
  }
  public getRoofOffsetY() {
    return UI.height + this.utils.tan(UI.patiosPitch) * (UI.span / 2);
  }
  public destroy(): void {
    this.unregisterEvent();
  }
  uiCHangedDefer(previousValue: number, currentValue: number) {
    if (this.APP.sltCutOut.currentValue == 1) {
      if (this.deferHandle) {
        clearTimeout(this.deferHandle);
      }
      this.deferHandle = setTimeout(() => { this.load() }, this.deferTimeout);
    }
    else {
      this.load();
    }
  }
  public uiChanged(preVal: number, curVal: number): void {
    this.load();
  }
  private registerEvent(): void {
    this.eventHandleId = this.uiCHangedDefer.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldSpan,
      this.APP.sldMultiSpan,
      this.APP.sldBuildingHeight,
      this.APP.sldFrontOverhang,
      this.APP.sldRightOverhang,
      this.APP.sldLeftOverhang,
      this.APP.sltRoofPitch,
      //this.APP.dialogEditBay,
      //this.APP.sltCutOut,
      //this.APP.sldExistingLength2,
      this.APP.sldExistingWidth1,
      this.APP.sldEaveWidth
    ];
    //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));
    this.controlsToRegisterEvent2 = [
      this.APP.sltRoofSheetingType,
      this.APP.sltRoofThickness,
      this.APP.sltBargeType,
      this.APP.sltDripBarge,
      this.APP.sltGutterType,
      this.APP.sltReceiverType,
      this.APP.sltRoofPitch
    ];
    this.controlsToRegisterEvent2.forEach(c => c.addAction(this.objectSizeChangedHandle));
  }
  private unregisterEvent(): void {
    //this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    //this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach(c => c.removeAction(this.objectSizeChangedHandle));
    this.controlsToRegisterEvent2 = undefined;
  }
  private objectSizeChanged(preVal: number, curVal: number) {
    this.optimize().then(() => { this.load() });
  }
}

