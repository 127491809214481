import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { DropdownInputItemModel } from "../../core/models";
import _ from "lodash"

declare var $: any;

@Component({
  selector: "dropdown-color-input",
  templateUrl: "./dropdown-color-input.component.html",
  styleUrls: ["./dropdown-color-input.component.scss"],
})
export class DropDownColorInputComponent implements OnInit {
  @Input("items") items: Array<DropdownInputItemModel>;
  @Input("selected") public selectedValue: number;

  public get currentItem(): DropdownInputItemModel {
    return this.items.find((c) => c.selected);
  }
  public get currentValue(): number {
    let selectedItem = this.items.find((i) => i.selected);
    if (selectedItem) return selectedItem.id;

    if (this.items.length > 0) return this.items[0].id;

    return null;
  }

  public get currentText(): string {
    let selectedItem = this.items.find((i) => i.selected);
    if (selectedItem) return selectedItem.value;

    if (this.items.length > 0) return this.items[0].value;

    return "";
  }

  public get currentBeamEndCapColorCode(): string {
    let selectedItem = this.items.find((i) => i.selected);
    if (selectedItem) return _.get(selectedItem, ['beamEndCapColorCode'], '');

    if (this.items.length > 0) return _.get(this.items, [0, 'beamEndCapColorCode'], '');

    return '';
  }

  actions: Array<{
    priority: number;
    actions: Array<(previousValue: number, currentValue: number) => void>;
  }> = [];
  isOpen = false;

  ngOnInit(): void {
    this.selectedValue = this.selectedValue || 0;
    this.setSelected(this.selectedValue);
  }
  ngAfterContentInit() {
    document.addEventListener("click", (e) => {
      //console.log('document clicked');
      ////document.getElementsByClassName('ui.fluid.dropdown.selection')
      let container = $(".ui.fluid.dropdown.selection");
      //let menu = $('.menu.transition');
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        this.isOpen = false;
        //$('.ui.fluid.dropdown.selection').removeClass('active visible');
        //menu.removeClass('visible');
        //menu.addClass('hidden');
      }

      e.stopPropagation();
    });
  }
  public addAction(
    action: (previousValue: number, currentValue: number) => void,
    priority?: number
  ) {
    priority = priority || 3;
    let actionsByPriority = this.actions.find((a) => a.priority === priority);
    if (actionsByPriority) {
      actionsByPriority.actions.push(action);
    } else {
      this.actions.push({ priority: priority, actions: [action] });
    }

    this.actions.sort((a, b) => a.priority - b.priority);
  }
  public removeAction(action: any): void {
    for (let item of this.actions) {
      const idx = item.actions.indexOf(action);

      if (idx !== -1) {
        item.actions.splice(idx, 1);
      }
    }
  }
  onItemSelected(item: DropdownInputItemModel) {
    this.items = this.items.map((i) => ({ ...i, selected: i.id == item.id }));

    for (let item of this.actions) {
      item.actions.forEach((action) => {
        action(null, this.currentValue);
      });
    }
  }
  onClick() {
    let container = $(".ui.fluid.dropdown.selection");
    let menu = $(".menu.transition");
    container.removeClass("active visible");
    menu.removeClass("visible");
    menu.addClass("hidden");

    this.isOpen = !this.isOpen;
  }
  setSelected(id) {
    if (!this.items.find((i) => i.id == id)) {
      return;
    }

    this.items = this.items.map((i) => ({ ...i, selected: i.id == id }));
    for (let item of this.actions) {
      item.actions.forEach((action) => {
        action(null, this.currentValue);
      });
    }
  }
  public toHexColor(value: number) {
    return "#" + Number(value).toString(16).padStart(6, "0");
  }
}
