import { Injectable } from "@angular/core";
import { BUILDING_SIDE, CONNECTION_TYPE, CUTOUT_ENABLE, EXISTING_BUILDING_CONFIG, FASCIA_BRACKET_FIT, FIT_FLYOVER_BRAKET_ON_ROOF, OBJECT_TYPE } from "src/app/app.constants";
import { Vector3 } from "three";
import { BaseManager } from "src/app-ribspan/core/base.manager";
import SelectionManager from "../selection.manager";
import { UI } from "src/app-ribspan/core/ui";
import { HomeComponent } from "src/app-ribspan/containers/home";
import ArrowControl from "src/app-ribspan/models/ArrowControl.model";
import { MaterialManager } from "../material.manager";
import { UIControl } from "src/app-ribspan/models/uicontrol.model";
import { PATIOS_ROOF_TYPE } from "src/app/app.config";
import _ from "lodash"
import { Util } from "../utils";
import { GeometryManager } from "../geometry.manager";

@Injectable({ providedIn: 'root' })
export class CutBeamManager extends BaseManager {
    controls = [
        UI.Controls.LENGTH1,
        UI.Controls.LENGTH2,
        UI.Controls.WIDTH1,
        UI.Controls.WIDTH2,
        UI.Controls.EXISTING_TYPE,
        UI.Controls.SPAN,
        UI.Controls.MULTI_SPAN,
        UI.Controls.OVERHANG_FRONT,
        UI.Controls.BAYS,
        UI.Controls.OVERHANG_RIGHT,
        UI.Controls.EAVE_WIDTH,
        UI.Controls.STRUCTURE_TYPE,
        UI.Controls.CUTOUT,
        UI.Controls.PATIOS_ROOF_PITCH,
        UI.Controls.FASCIA_DEPTH,
        UI.Controls.EXISTING_ROOF_PITCH,
        UI.Controls.FLYOVER_BRAKET_HEIGHT,
        UI.Controls.FASCIA_UPSTAND_BRAKET,
        UI.Controls.EXISTING_WALL_HEIGHT,
        UI.Controls.ROOF_SHEETING_TYPE,
        UI.Controls.ROOF_THICKNESS
    ]
    
    userData = undefined;
    offset = 200;
    addControlOptions = undefined;
    private utils: Util;

    constructor(private selMgr: SelectionManager){
        super()
        this.utils = new Util();
    }

    init(): Promise<any> {
        return super.init().then(() => {
            this.userData = { type: OBJECT_TYPE.CUT_BEAM }
            this.addControlOptions = {
                container: this.container,
                userData: { type: OBJECT_TYPE.CUT_BEAM }
            }
        })
    }

    

    load(): Promise<void>{
        return new Promise(resolve => {
            this.container.children = [];
            this.addControl();
            HomeComponent.ins.renderStatic();

            resolve()
        })
    }
    
    addControl(){
        this.container.children = [];
        let offsetZ = UI.span + UI.multiSpan - UI.existingWidth1 / 2;
        let offsetX = - UI.totalBayLength / 2;

        if (UI.patiosRoofType == PATIOS_ROOF_TYPE.GABLE_ROOF && HomeComponent.ins.isFBRoof) {
            offsetX = UI.span / 2;
            if(
                UI.structureType == CONNECTION_TYPE.FASCIA
            ) {
                if (UI.existingType == BUILDING_SIDE.LEFT) {
                    offsetX += UI.eaveWidth
                } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
                    offsetX -= UI.eaveWidth
                }
            } else if(
                UI.structureType == CONNECTION_TYPE.FASCIA_UPSTAND
                || UI.structureType == CONNECTION_TYPE.BACK_FASCIA_UPSTAND
            ) {
                if(UI.existingType == BUILDING_SIDE.LEFT) {
                    offsetX += UI.eaveWidth + FASCIA_BRACKET_FIT
                } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
                    offsetX -= UI.eaveWidth + FASCIA_BRACKET_FIT
                }
            }
            let diff = UI.existingLength1 - UI.span;
            if (UI.existingType == BUILDING_SIDE.LEFT) {
                if(UI.cutOutType == CUTOUT_ENABLE.YES) {
                    offsetX -= (diff / 2 + UI.existingLength2)
                } else {
                    offsetX -= (diff / 2)
                }
            } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
                if(UI.cutOutType == CUTOUT_ENABLE.YES) {
                    offsetX -= (- diff / 2 - UI.existingLength2)
                } else {
                    offsetX -= (- diff / 2)
                }
            }

            offsetZ = - UI.existingWidth1 / 2;
            if(
                UI.structureType == CONNECTION_TYPE.FASCIA
            ) {
                offsetZ += UI.eaveWidth
            } else if(
                UI.structureType == CONNECTION_TYPE.FASCIA_UPSTAND
                || UI.structureType == CONNECTION_TYPE.BACK_FASCIA_UPSTAND
            ) {
                offsetZ += UI.eaveWidth + FASCIA_BRACKET_FIT
            } else if(
                UI.structureType == CONNECTION_TYPE.FLY_OVER
                || UI.structureType == CONNECTION_TYPE.BLACK_FLY_OVER
            ) {
                offsetZ -= 65
            }

            let offsetY = EXISTING_BUILDING_CONFIG.FIT_FROM_TOP_TO_CUTBEAM;
            if(UI.structureType == CONNECTION_TYPE.FLY_OVER) {
                offsetY += UI.eaveHeight + 150 + this.totalHeightFromEaveHeightToTopOfFlyoverBraket()
            } else if(UI.structureType == CONNECTION_TYPE.EXISTING) {
                offsetY += UI.height
            } else if(UI.structureType == CONNECTION_TYPE.FASCIA_UPSTAND) {
                offsetY += UI.eaveHeight + +UI.upstandBraketType + 150
            }

            for (let i = 0; i < UI.listBay.length; i++) {
                offsetZ += UI.listBay[i].value
                if (i == UI.listBay.length - 1) {
                    return
                }

                if (UI.listBay[i].isCut) {
                    this.addControlOptions.material = MaterialManager.Instance().UI_CONTROL_EXIT_CUTBEAM;
                } else {
                    this.addControlOptions.material = MaterialManager.Instance().UI_CONTROL_CUTBEAM;
                }

                new UIControl(
                    [0.08,0.08,0.08], 
                    [offsetX + this.offset, offsetY, offsetZ], 
                    {
                        ...this.addControlOptions,
                        userData: {
                            type: OBJECT_TYPE.CUT_BEAM,
                            bayIndex: i
                        }
                    }
                );
            }
        } else {
            if(
                UI.structureType == CONNECTION_TYPE.FASCIA
            ) {
                offsetZ += UI.eaveWidth
            } else if(
                UI.structureType == CONNECTION_TYPE.FASCIA_UPSTAND
                || UI.structureType == CONNECTION_TYPE.BACK_FASCIA_UPSTAND
            ) {
                offsetZ += UI.eaveWidth + FASCIA_BRACKET_FIT
            }

            if(
                UI.structureType == CONNECTION_TYPE.FASCIA
            ) {
                if (UI.existingType == BUILDING_SIDE.LEFT && !HomeComponent.ins.uiManager.calcShouldSnapRoofToExistingWhenCutout()) {
                    offsetX += UI.eaveWidth
                } else if (UI.existingType == BUILDING_SIDE.RIGHT && !HomeComponent.ins.uiManager.calcShouldSnapRoofToExistingWhenCutout()) {
                    offsetX -= UI.eaveWidth
                }
            } else if(
                UI.structureType == CONNECTION_TYPE.FASCIA_UPSTAND
                || UI.structureType == CONNECTION_TYPE.BACK_FASCIA_UPSTAND
            ) {
                if(UI.existingType == BUILDING_SIDE.LEFT) {
                    offsetX += UI.eaveWidth + FASCIA_BRACKET_FIT
                } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
                    offsetX -= UI.eaveWidth + FASCIA_BRACKET_FIT
                }
            }
            let diff = UI.existingLength1 - UI.totalBayLength;
            if (UI.existingType == BUILDING_SIDE.LEFT) {
                if(UI.cutOutType == CUTOUT_ENABLE.YES) {
                    offsetX -= (diff / 2 + UI.existingLength2)
                } else {
                    offsetX -= (diff / 2)
                }
            } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
                if(UI.cutOutType == CUTOUT_ENABLE.YES) {
                    offsetX -= (- diff / 2 - UI.existingLength2)
                } else {
                    offsetX -= (- diff / 2)
                }
            }

            if(UI.structureType == CONNECTION_TYPE.FREE_STANDING && UI.patiosRoofType == PATIOS_ROOF_TYPE.GABLE_ROOF) {
                offsetZ = (UI.span + UI.multiSpan) / 2
            }

            let offsetY = EXISTING_BUILDING_CONFIG.FIT_FROM_TOP_TO_CUTBEAM;
            if(UI.patiosRoofType == PATIOS_ROOF_TYPE.FLAT_ROOF) {
                if(UI.structureType == CONNECTION_TYPE.EXISTING) {
                    offsetY += UI.height - this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)
                } else if(UI.structureType == CONNECTION_TYPE.FLY_OVER) {
                    offsetY += UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket() - this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)
                } else if(UI.structureType == CONNECTION_TYPE.BLACK_FLY_OVER) {
                    offsetY += UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket() + this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)
                } else if(UI.structureType == CONNECTION_TYPE.FASCIA) {
                    offsetY += UI.eaveHeight - this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)
                } else if(UI.structureType == CONNECTION_TYPE.FASCIA_UPSTAND) {
                    offsetY += UI.eaveHeight + +UI.upstandBraketType - this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)
                } else if(UI.structureType == CONNECTION_TYPE.BACK_FASCIA_UPSTAND) {
                    offsetY += UI.eaveHeight + +UI.upstandBraketType + 100 - this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)
                } else if(UI.structureType == CONNECTION_TYPE.FREE_STANDING) {
                    offsetY += UI.height - 100 - this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)
                }
            } else {
                if(UI.structureType == CONNECTION_TYPE.FLY_OVER) {
                    offsetY += UI.eaveHeight + 150 + this.totalHeightFromEaveHeightToTopOfFlyoverBraket()
                } else if(UI.structureType == CONNECTION_TYPE.FASCIA_UPSTAND) {
                    offsetY += UI.eaveHeight + +UI.upstandBraketType + 150
                } else if(UI.structureType == CONNECTION_TYPE.FREE_STANDING) {
                    offsetY += UI.eaveHeight
                }
            }


            for (let i = 0; i < UI.listBay.length; i++) {
                offsetX += UI.listBay[i].value
                if (i == UI.listBay.length - 1) {
                    return
                }
    
                if (UI.listBay[i].isCut) {
                    this.addControlOptions.material = MaterialManager.Instance().UI_CONTROL_EXIT_CUTBEAM;
                } else {
                    this.addControlOptions.material = MaterialManager.Instance().UI_CONTROL_CUTBEAM;
                }
    
                new UIControl(
                    [0.08,0.08,0.08], 
                    [offsetX, offsetY, offsetZ + this.offset], 
                    {
                        ...this.addControlOptions,
                        userData: {
                            type: OBJECT_TYPE.CUT_BEAM,
                            bayIndex: i
                        }
                    }
                );
            }
        }
    }

    private totalHeightFromEaveHeightToTopOfExistingRoof() {
        const height =
          this.geometryManager.EAVE.EAVE.height +
          UI.fasciaDepth +
          UI.eaveWidth * this.utils.tan(UI.existingRoofPitch) +
          this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.height /
            this.utils.cos(UI.existingRoofPitch);
    
        return height;
    }
    private totalHeightFromEaveHeightToTopOfFlyoverBraket() {
        const height =
          this.totalHeightFromEaveHeightToTopOfExistingRoof() +
          UI.braketHeight -
          FIT_FLYOVER_BRAKET_ON_ROOF;
    
        return height;
    }
}
