import { Scene, Mesh, BufferGeometry, Vector3, Object3D, Color, Material, Plane, Geometry, LineSegments } from "three";
import { Util } from "./utils";
import { HomeComponent as AppComponent } from 'src/app-ribspan/containers/home/home.component';
import { GeometryManager } from "./geometry.manager";
import { MaterialManager } from "./material.manager";
import { GeometryInfo, Printing2DLine, ViewType, LineType, Printing2DGeometry, Printing2DGeometryType } from 'src/app/core/models';
import { GEOMETRY_TYPE } from 'src/app/app.config';

export class FasciaManager {
    private scene: Scene;
    private APP: AppComponent;
    private material: Material;
    private utils: Util;

    private geometryManager: GeometryManager;

    private geo_fasciaW1: BufferGeometry;
    private geo_fasciaW2: BufferGeometry;
    private geo_fasciaL1: BufferGeometry;

    // private matW1: Material;
    // private clippingPlaneW1: Plane;

    constructor() {
        this.utils = new Util();
        this.geometryManager = GeometryManager.Instance();
    }

    public init(app: AppComponent): Promise<void>{
        return new Promise((resolve, reject) => {
            this.APP = app;
            this.scene = app.scene;
            this.material = MaterialManager.Instance().FASCIA.clone();
            //this.matW1 = MaterialManager.Instance().EAVE.clone();

            app.sldExistingWallHeight.addAction(this.uiChanged.bind(this));
            app.sldEaveWidth.addAction(this.uiChanged.bind(this));
            app.sldFasciaDepth.addAction(this.uiChanged.bind(this));
            app.sldExistingLength.addAction(this.uiChanged.bind(this));
            app.sldExistingLength2.addAction(this.uiChanged.bind(this));
            app.sldExistingWidth1.addAction(this.uiChanged.bind(this));
            app.sldExistingWidth2.addAction(this.uiChanged.bind(this));
            app.sltExistingType.addAction(this.uiChanged.bind(this));
            app.sltStructureType.addAction(this.uiChanged.bind(this));

            resolve();
        });
    }
    public optimize() : Promise<void>{
        return new Promise((resolve, reject) => {
            let translateWidth = this.geometryManager.FASCIA.FASCIA.width/2;
            let translateHeight = this.geometryManager.FASCIA.FASCIA.height/2;
            let translateLength = this.geometryManager.FASCIA.FASCIA.length/2;
            
            this.geo_fasciaW1 = this.geometryManager.FASCIA.FASCIA.geometry
            .clone()
            .translate(translateWidth,translateHeight,translateLength);

            this.geo_fasciaL1 = this.geometryManager.FASCIA.FASCIA.geometry
            .clone()
            .rotateY(Math.PI/2)
            .translate(0,translateHeight,-translateWidth)
            
            resolve();
        });
    }
    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.FASCIA));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == 'FASCIA_OUTLINE'));

            // if(this.APP.sldExistingLength2.currentValue == 0){
            //     this.clippingPlaneW1 = new Plane(new Vector3(0,0,-1), this.APP.sldExistingWidth1.currentValue/2);
            //     this.matW1.clippingPlanes = [this.clippingPlaneW1];
            // }
            // else{
            //     this.matW1.clippingPlanes = [];
            // }

            if(this.APP.sltStructureType.currentValue == 4){
                resolve();
                return;
            }

            this.addFascia({length1: true});
            if(this.APP.sldExistingLength2.currentValue > 0){
                this.addFascia({length2: true});
            }
            if(this.APP.sldExistingWidth1.currentValue >= this.APP.sldEaveWidth.currentValue){
                this.addFascia({width1: true});
            }
            if(this.APP.sldExistingWidth2.currentValue >= this.APP.sldEaveWidth.currentValue){
                this.addFascia({width2: true});
            }

            //this.getOutLines();
            resolve();
        });
    }

    public getOutLines(): Printing2DGeometry{
        let objs = this.scene.children.filter(o => o.userData.type == GEOMETRY_TYPE.FASCIA );
        let lsGeometries: Printing2DLine[] = [];

        for(let o of objs){            
            let outlineGeo = this.utils.getOutlineGeometryFromMesh((o as Mesh), 10);
            outlineGeo.translate(o.position.x, o.position.y + 5000, o.position.z);

            // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
            // line.userData = {type: "FASCIA_OUTLINE"};
            // this.scene.add( line );

            let simplifiedGeo = this.simplifyGeo(outlineGeo);
            lsGeometries.push({ 
                objectType: o.userData.type,
                vertices: simplifiedGeo.vertices, 
                views: o.userData.views
            });
        }

        return { lines: lsGeometries, texts: [] };
    }
    public simplifyGeo(geo: BufferGeometry): Geometry{
        let simplifiedGeo = new Geometry();
        let vertices = geo.getAttribute('position').array;
        for(let i = 0; i < vertices.length; i+=3){
            simplifiedGeo.vertices.push(new Vector3(vertices[i], vertices[i+1] - 5000 , vertices[i+2]));
        }
        return simplifiedGeo;
    }
  
    public addFascia(userDataPos: any){
        let mesh: Mesh;
        
        let offsetX = this.APP.sldExistingLength.currentValue/2 - this.APP.sldEaveWidth.currentValue;
        let offsetY = this.APP.sldExistingWallHeight.currentValue + this.geometryManager.EAVE.EAVE.height;
        let offsetZ = this.APP.sldExistingWidth1.currentValue/2;
        let scaleX = this.APP.sldExistingLength.currentValue/this.geometryManager.FASCIA.FASCIA.length;
        let scaleY = this.APP.sldFasciaDepth.currentValue/this.geometryManager.FASCIA.FASCIA.height;
        let scaleZ = (this.APP.sldExistingWidth1.currentValue + this.geometryManager.FASCIA.FASCIA.width)/this.geometryManager.FASCIA.FASCIA.length;
        

        if(userDataPos.width1){
            if(this.APP.sldExistingLength2.currentValue <= 0){
                scaleZ = (this.APP.sldExistingWidth1.currentValue - this.APP.sldEaveWidth.currentValue + this.geometryManager.FASCIA.FASCIA.width)/this.geometryManager.FASCIA.FASCIA.length;
                if(scaleZ <= 0)
                    scaleZ = 0.01;
            }

            let views = [ 
                { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS }
            ];

            if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_fasciaW1, this.material);
                mesh.userData = { type: GEOMETRY_TYPE.FASCIA, position: userDataPos, views: views };
                mesh.position.setX(-offsetX-this.geometryManager.FASCIA.FASCIA.width);
                mesh.position.setY(offsetY);
                mesh.position.setZ(-offsetZ + this.APP.sldEaveWidth.currentValue - this.geometryManager.FASCIA.FASCIA.width);
                mesh.scale.set(1,scaleY,scaleZ);
                this.scene.add(mesh);
            }
            if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_fasciaW1, this.material);
                mesh.userData = { type: GEOMETRY_TYPE.FASCIA, position: userDataPos, views: views };
                mesh.position.setX(offsetX);
                mesh.position.setY(offsetY);
                mesh.position.setZ(-offsetZ + this.APP.sldEaveWidth.currentValue - this.geometryManager.FASCIA.FASCIA.width);
                mesh.scale.set(1,scaleY,scaleZ);
                this.scene.add(mesh);
            }
        }
        else if(userDataPos.width2){
            let _offsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.sldExistingLength2.currentValue - this.APP.sldEaveWidth.currentValue;
            let _offsetY = this.APP.sldExistingWallHeight.currentValue + this.geometryManager.EAVE.EAVE.height;
            let _offsetZ = this.APP.sldExistingWidth1.currentValue/2 + this.APP.sldEaveWidth.currentValue - this.geometryManager.FASCIA.FASCIA.width;
            
            let _scaleY = this.APP.sldFasciaDepth.currentValue/this.geometryManager.FASCIA.FASCIA.height;
            let _scaleZ = (this.APP.sldExistingWidth2.currentValue - this.APP.sldEaveWidth.currentValue + this.geometryManager.FASCIA.FASCIA.width)/this.geometryManager.FASCIA.FASCIA.length;

            let views = [ 
                { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS }
            ];

            if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_fasciaW1, this.material);
                mesh.userData = { type: GEOMETRY_TYPE.FASCIA, position: userDataPos, views: views };
                mesh.position.set(-(_offsetX + this.geometryManager.FASCIA.FASCIA.width), _offsetY, _offsetZ);
                mesh.scale.set(1,_scaleY,_scaleZ);
                this.scene.add(mesh);
            }
            if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_fasciaW1, this.material);
                mesh.userData = { type: GEOMETRY_TYPE.FASCIA, position: userDataPos, views: views };
                mesh.position.set(_offsetX, _offsetY, _offsetZ);
                mesh.scale.set(1,_scaleY,_scaleZ);
                this.scene.add(mesh);
            }
        }
        else if(userDataPos.length1){
            let offsetX = 0;
            if(this.APP.sldExistingWidth1.currentValue >= this.APP.sldEaveWidth.currentValue){
                if(this.APP.sltExistingType.currentValue == 1){
                    offsetX = this.APP.sldEaveWidth.currentValue/2;
                    scaleX = (this.APP.sldExistingLength.currentValue - this.APP.sldEaveWidth.currentValue)/this.geometryManager.FASCIA.FASCIA.length;
                }
                else if(this.APP.sltExistingType.currentValue == 2){
                    offsetX = -this.APP.sldEaveWidth.currentValue/2;
                    scaleX = (this.APP.sldExistingLength.currentValue - this.APP.sldEaveWidth.currentValue)/this.geometryManager.FASCIA.FASCIA.length;
                }
                else if(this.APP.sltExistingType.currentValue == 3){
                    offsetX = 0;
                    scaleX = (this.APP.sldExistingLength.currentValue - this.APP.sldEaveWidth.currentValue*2)/this.geometryManager.FASCIA.FASCIA.length;
                }
            }

            let views = [ 
                { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
            ];

            mesh = new Mesh(this.geo_fasciaL1, this.material);
            mesh.userData = { type: GEOMETRY_TYPE.FASCIA, position: userDataPos, views: views };
            mesh.position.setX(offsetX);
            mesh.position.setY(offsetY);
            mesh.position.setZ(-offsetZ+this.APP.sldEaveWidth.currentValue);
            mesh.scale.set(scaleX,scaleY,1);
            this.scene.add(mesh);
        }
        else if(userDataPos.length2){
            let _offsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.sldExistingLength2.currentValue/2 - this.APP.sldEaveWidth.currentValue/2;
            let _offsetY = this.APP.sldExistingWallHeight.currentValue + this.geometryManager.EAVE.EAVE.height;
            let _offsetZ = this.APP.sldExistingWidth1.currentValue/2 + this.APP.sldEaveWidth.currentValue;
            let _scaleX = (this.APP.sldExistingLength2.currentValue + this.APP.sldEaveWidth.currentValue)/this.geometryManager.FASCIA.FASCIA.length;
            let _scaleY = this.APP.sldFasciaDepth.currentValue/this.geometryManager.FASCIA.FASCIA.height;

            if(this.APP.sldExistingWidth2.currentValue >= this.APP.sldEaveWidth.currentValue){
                _offsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.sldExistingLength2.currentValue/2 - this.APP.sldEaveWidth.currentValue;
                _scaleX = this.APP.sldExistingLength2.currentValue/this.geometryManager.FASCIA.FASCIA.length;
            }

            let views = [ 
                // { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                // { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
            ];

            if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                views.push({ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS });
                mesh = new Mesh(this.geo_fasciaL1, this.material);
                mesh.userData = { type: GEOMETRY_TYPE.FASCIA, position: userDataPos, views: views };
                mesh.position.setX(-_offsetX);
                mesh.position.setY(_offsetY);
                mesh.position.setZ(_offsetZ);
                mesh.scale.set(_scaleX,_scaleY,1);
                this.scene.add(mesh);
            }
            if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                views.push({ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS });
                mesh = new Mesh(this.geo_fasciaL1, this.material);
                mesh.userData = { type: GEOMETRY_TYPE.FASCIA, position: userDataPos, views: views };
                mesh.position.setX(_offsetX);
                mesh.position.setY(_offsetY);
                mesh.position.setZ(_offsetZ);
                mesh.scale.set(_scaleX,_scaleY,1);
                this.scene.add(mesh);
            }
        }
        
        
        
    }

    public uiChanged(preVal: number, curVal): void {
        this.load();
    }
}
