import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './containers/home';
import { LoginComponent } from './containers/login';
import { HomeComponent as HomeRibspanComponent} from 'src/app-ribspan/containers/home';
// import { AppComponent } from './app.component';
import { AuthGuard } from './helpers';

const routes: Routes = [
    //{ path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: '', component: HomeComponent},
    { path: 'ribspan', component: HomeRibspanComponent},
    { path: 'login', component: LoginComponent },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes);