import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IBay } from 'src/app/core/models';
import { HomeComponent as AppComponent } from '../../containers/home/home.component';

declare var $: any;

@Component({
  selector: 'dialog-edit-bay',
  templateUrl: './dialog-edit-bay.component.html',
  styleUrls: ['./dialog-edit-bay.component.scss']
})
export class DialogEditBayComponent implements OnInit {
  private _noOfBay: number;
  private _baySize: number;

  public get noOfBay(): number { return this._noOfBay; }
  public get baySize(): number { return this._baySize; }

  @Input('noOfBay') public set noOfBay(val: number) { this._noOfBay = val; this.onChange(); }
  @Input('baySize') public set baySize(val: number) { this._baySize = val; this.onChange(); }
  @Output('onSubmit') onSubmitEvent = new EventEmitter();
  @Output('onCancel') onCancelEvent = new EventEmitter();

  visible: boolean = false;

  public listBay: IBay[] = [];
  public listBayCached: IBay[] = [];
  public bayMaxValue = 8000;
  public bayMinValue = 1000;

  public previousTotalBaySize: number = this.bayMinValue;
  public get totalBaySize(): number {
    return this.listBay.reduce((total, val) => total + val.value, 0);
  }

  public actions: Array<(listBay: IBay[]) => void> = [];

  ngOnInit(): void {

  }
  ngAfterContentInit() {

  }

  onChange() {
    let bays: IBay[] = [];
    this.listBay = [];
    //this.listBayCached = [];

    for (let i = 0; i < this.noOfBay; i++) {
      //keep previous isCut value if noOfBay not changed
      let isCut = false;
      if (this.listBayCached.length > 0 && this.noOfBay == this.listBayCached.length) {
        isCut = this.listBayCached[i].isCut;
      }

      bays.push({ index: i + 1, value: +this.baySize, isCut });
      //this.listBay.push({ index: i + 1, value: +this.baySize, isCut: false });
      //this.listBayCached.push({ index: i + 1, value: +this.baySize, isCut: false });  
    }

    this.listBay = bays;
    this.listBayCached = bays;

    this.actions.forEach(action => {
      action(this.listBay);
    });

    this.previousTotalBaySize = this.totalBaySize;
  }

  public addAction(action: (listBay: IBay[]) => void) {
    this.actions.push(action);
  }
  public removeAction(action: any): void {
    let idx = this.actions.indexOf(action);
    if (idx !== -1) {
      this.actions.splice(idx, 1);
    }
  }

  onCancel() {
    this.listBay = this.listBayCached.map(b => ({ index: b.index, value: b.value, isCut: b.isCut }));

    this.onCancelEvent.emit();
    this.show(false);
  }
  onOK() {
    this.listBayCached = this.listBay.map(b => ({ index: b.index, value: b.value, isCut: b.isCut }));
    this.onSubmitEvent.emit();
    this.actions.forEach(action => {
      action(this.listBay);
    });
    this.show(false);
  }
  public show(_visible: boolean) {
    this.visible = _visible;
  }
  public setValue(value) {
    if (!value || !value.length || value.length == 0)
      return;

    this.noOfBay = value.length;
    this.listBay = value;
    this.onOK();
  }
}
