<nz-modal
  [(nzVisible)]="visible"
  [nzTitle]="title"
  nzWidth="60%"
  (nzOnCancel)="onCancel()"
  [nzFooter]="null"
>
  <form nz-form [formGroup]="inputForm">
    <nz-divider nzText="Search" style="margin-top: -10px"></nz-divider>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="unit">Group</nz-form-label>
      <nz-form-control [nzSm]="8" [nzXs]="24">
        <nz-select
          [(ngModel)]="group"
          style="width: 100%"
          formControlName="group"
          nzAllowClear
          (ngModelChange)="onGroupChange($event)"
        >
          <nz-option
            *ngFor="let i of groups"
            [nzValue]="i.code"
            [nzLabel]="i.name"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="description"
        >Item Description</nz-form-label
      >
      <nz-form-control [nzSm]="18" [nzXs]="24">
        <input
          formControlName="description"
          placeholder="Type at lease 3 letters to Search"
          nz-input
          [(ngModel)]="description"
          (input)="onSearch($event)"
          [nzAutocomplete]="auto"
        />
        <nz-autocomplete #auto [nzBackfill]="true">
          <nz-auto-option
            *ngFor="let option of optionLists"
            (selectionChange)="fieldSelected($event, option)"
            [nzValue]="option.itemDescription"
            >{{ option.itemDescription }} - ({{
              option.itemNo
            }})</nz-auto-option
          >
        </nz-autocomplete>
      </nz-form-control>
    </nz-form-item>
    <nz-divider></nz-divider>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="itemNo"
        >Item No.</nz-form-label
      >
      <nz-form-control [nzSm]="8" [nzXs]="24">
        <input
          disabled="true"
          nz-input
          formControlName="itemNo"
          id="itemNo"
          [(ngModel)]="itemNo"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="dri"
        >Direction</nz-form-label
      >
      <nz-form-control [nzSm]="8" [nzXs]="24">
        <nz-select formControlName="dri" id="dri" nzAllowClear>
        <nz-option nzValue="" nzLabel=""></nz-option>
        <nz-option nzValue="L-R" nzLabel="L-R"></nz-option>
        <nz-option nzValue="R-L" nzLabel="R-L"></nz-option>
      </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="cutBk"
        >Cut BK</nz-form-label
      >
      <nz-form-control [nzSm]="8" [nzXs]="24">
        <input
          nz-input
          formControlName="cutBk"
          id="cutBk"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="length"
        >Length</nz-form-label
      >
      <nz-form-control [nzSm]="8" [nzXs]="24">
        <nz-input-group nzAddOnBefore="m">
          <input
            class="right"
            currencyMask
            nz-input
            id="length"
            formControlName="length"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="qty">Qty</nz-form-label>
      <nz-form-control [nzSm]="8" [nzXs]="24">
        <nz-input-group [nzAddOnBefore]="unit">
          <input class="right" nz-input id="qty" formControlName="qty" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <div style="margin-top: 20px">
      <button class="small ui button facebook" (click)="onSubmit()">
        <i class="save icon"></i>
        Save
      </button>
      <button class="small ui button" (click)="onCancel()">
        <i class="x icon"></i>
        Cancel
      </button>
    </div>
  </form>
</nz-modal>
