import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../services';
import { NzMessageService } from 'ng-zorro-antd/message';
import { STATUS_USER } from '../../app.config';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    loginType = 'SOFTWARE';
    signUpUrl: string;
    backendUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private message: NzMessageService,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
            window.location.replace('/');
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            userName: ['', Validators.required],
            password: ['', Validators.required],
            loginType: [this.loginType, Validators.required],
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.signUpUrl = environment.backendUrl + 'login?ref=SIGN_UP';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onEncrypt = (plainText: string): string => {
        const b64 = CryptoJS.AES.encrypt(plainText, 'spc@2020').toString();
        const e64 = CryptoJS.enc.Base64.parse(b64);
        return e64.toString(CryptoJS.enc.Hex);
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {

            if (this.loginForm.value.userName === '') {
                this.message.error('Username is required')
                return;
            }
            if (this.loginForm.value.password === '') {
                this.message.error('Password is required')
                return;
            }
        }
        this.loading = true;
        if (this.loginForm.value.loginType === 'BACKEND') {
            const _userName = this.onEncrypt(this.loginForm.value.userName);
            const _password = this.onEncrypt(this.loginForm.value.password);
            window.location.href = environment.backendUrl + `login?e=${_userName}&p=${_password}`;
        } else {
            this.authenticationService.login(this.f.userName.value, this.f.password.value)
                .pipe(first())
                .subscribe(
                    data => {
                        const _status = data.data.user.status;
                        if ([STATUS_USER[0].key, STATUS_USER[2].key].includes(_status)) {
                            window.location.replace(this.returnUrl);
                        } else {
                            this.loading = false;
                            this.error = 'Unauthorized';
                        }
                    },
                    error => {
                        this.error = error;
                        this.loading = false;
                    });
        }
    }
}
