import { Component, ElementRef, NgZone, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import _ from "lodash";
import { NzMessageService } from "ng-zorro-antd/message";
import { first, filter } from "rxjs/operators";
import {
  BUILDING_SIDE,
  CONNECTION_TYPE,
  CUTOUT_ENABLE,
  EXISTING_BUILDING_CONFIG,
  FASCIA_BRACKET_FIT,
  RAKECUT_TYPE,
} from "src/app/app.constants";
import { ICost } from "src/app/core/models";
import SelectionManager from "src/app-ribspan/core/selection.manager";
import { UI } from "src/app-ribspan/core/ui";
import { CutOutManager } from "src/app-ribspan/core/UI/cutOut.manager";
import { EditLength2Manager } from "src/app-ribspan/core/UI/editLength2.manager";
import { EditWallManager } from "src/app-ribspan/core/UI/editWall.manager";
import { EditWidth2Manager } from "src/app-ribspan/core/UI/editWidth2.manager";
import UIRakecutManager from "src/app-ribspan/core/UI/rakecut";
import { environment } from "src/environments/environment";
import {
  Box3,
  BoxHelper,
  Color,
  DirectionalLight,
  PerspectiveCamera,
  Scene,
  Vector3,
  WebGLRenderer,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  ACTIVE_LEFT_MENU,
  APP_VERSION,
  CONFIG,
  GEOMETRY_CATEGORY,
  GEOMETRY_TYPE,
  INIT,
  OFFSET_GUTTER_TO_WALL,
  PANEL_TYPE,
  PATIOS_ROOF_TYPE,
  RELEASE_DATE,
  STATUS_TEXT,
  TEMPLATE,
} from "src/app/app.config";
import { DimensionManager } from "src/app-ribspan/core/dimension.manager";
import { EaveManager } from "src/app-ribspan/core/eave.manager";
import { ExistingRoofManager } from "src/app-ribspan/core/existing-roof.manager";
import { ExistingWallManager } from "src/app-ribspan/core/existing-wall.manager";
import { FasciaManager } from "src/app-ribspan/core/fascia.manager";
import { GeometryManager } from "src/app-ribspan/core/geometry.manager";
import { GroundManager } from "src/app-ribspan/core/ground.manager";
import { MaterialManager } from "src/app-ribspan/core/material.manager";
import { Objects } from "src/app-ribspan/core/objects";
import { PatiosManager } from "src/app-ribspan/core/patios.manager";
import { Printing2DManager } from "src/app-ribspan/core/printing2d.manager";
import { UIManager } from "src/app-ribspan/core/ui.manager";
import { Util } from "src/app-ribspan/core/utils";
import { User } from "src/app/models";
import {
  AuthenticationService,
  JobService,
  PrintingService,
} from "src/app/services";
import { UserService } from "src/app/services/user.service";
import { Album } from "src/app/models/Album";
import { Lightbox } from "ngx-lightbox";
import { DialogBOMComponent } from "src/app/component/dialog-bom/dialog-bom.component";
import { DialogAddCostComponent } from "src/app/component/dialog-add-cost/dialog-add-cost.component";
import { SliderInputComponent } from "src/app/component/slider-input/slider-input.component";
import { DropDownInputComponent } from "src/app/component/dropdown-input/dropdown-input.component";
import { DropDownInputCustomComponent } from "src/app/component/dropdown-input-custom/dropdown-input-custom.component";
import { DialogEditBayComponent } from "src/app/component/dialog-edit-bay/dialog-edit-bay.component";
import { SwitchInputComponent } from "src/app/component/switch-input/switch-input.component";
import { CheckboxInputComponent } from "src/app/component/checkbox-input/checkbox-input.component";
import { DialogRakeCutComponent } from "src/app/component/dialog-rakecut/dialog-rakecut.component";
import { DialogDimensionComponent } from "src/app/component/dialog-dimension/dialog-dimension.component";
import { DialogConfirmComponent } from "src/app/component/dialog-confirm/dialog-confirm.component";
import { PanelBOMComponent } from "src/app/component/panel-bom/panel-bom.component";
import { ToggleButtonComponent } from "src/app/component/toggle-button/toggle-button.component";
import { CutBeamManager } from "src/app-ribspan/core/UI/cutBeam.manager";

declare var $: any;

@Component({
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  @ViewChild("rendererCanvas", { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild("viewerContainer", { static: true })
  public viewerContainer: ElementRef<HTMLDivElement>;
  @ViewChild("sldEaveWidth", { static: true })
  public sldEaveWidth: SliderInputComponent;
  @ViewChild("sldEaveHeight", { static: true })
  public sldExistingWallHeight: SliderInputComponent;
  @ViewChild("sldFasciaDepth", { static: true })
  public sldFasciaDepth: SliderInputComponent;

  @ViewChild("sldBaySize", { static: true })
  public sldBaySize: SliderInputComponent;
  @ViewChild("sldSpan", { static: true }) public sldSpan: SliderInputComponent;

  @ViewChild("sldExistingLength", { static: true })
  public sldExistingLength: SliderInputComponent;
  @ViewChild("sldExistingLength2", { static: true })
  public sldExistingLength2: SliderInputComponent;

  @ViewChild("sldExistingWidth1", { static: true })
  public sldExistingWidth1: SliderInputComponent;
  @ViewChild("sldExistingWidth2", { static: true })
  public sldExistingWidth2: SliderInputComponent;

  @ViewChild("sltExistingType", { static: true })
  public sltExistingType: DropDownInputComponent;
  @ViewChild("sltStructureType", { static: true })
  public sltStructureType: DropDownInputComponent;
  @ViewChild("sltWindClass", { static: true })
  public sltWindClass: DropDownInputComponent;
  @ViewChild("sltWallType", { static: true })
  public sltWallType: DropDownInputComponent;
  @ViewChild("sltExistingRoofType", { static: true })
  public sltExistingRoofType: DropDownInputComponent;

  @ViewChild("sldMultiSpan", { static: true })
  public sldMultiSpan: SliderInputComponent;
  @ViewChild("sldBuildingHeight", { static: true })
  public sldBuildingHeight: SliderInputComponent;
  @ViewChild("sldFrontOverhang", { static: true })
  public sldFrontOverhang: SliderInputComponent;
  @ViewChild("sldBackOverhang", { static: true })
  public sldBackOverhang: SliderInputComponent;
  @ViewChild("sldLeftOverhang", { static: true })
  public sldLeftOverhang: SliderInputComponent;
  @ViewChild("sldRightOverhang", { static: true })
  public sldRightOverhang: SliderInputComponent;
  @ViewChild("sltRoofPitch", { static: true })
  public sltRoofPitch: DropDownInputCustomComponent;
  @ViewChild("sldFlyOverBracketHeight", { static: true })
  public sldFlyOverBracketHeight: SliderInputComponent;
  @ViewChild("sldNoOfBay", { static: true })
  public sldNoOfBay: SliderInputComponent;
  @ViewChild("dialogEditBay", { static: true })
  public dialogEditBay: DialogEditBayComponent;

  @ViewChild("sltPitch", { static: true })
  public sltExistingRoofPitch: DropDownInputComponent;
  @ViewChild("sldRoofOverallLength", { static: true })
  public sldRoofOverallLength: SliderInputComponent;
  @ViewChild("sldRoofOverallWidth", { static: true })
  public sldRoofOverallWidth: SliderInputComponent;
  @ViewChild("sldMinHeight", { static: true })
  public sldMinHeight: SliderInputComponent;
  @ViewChild("sltFlatBottom", { static: true })
  public sltFlatBottom: DropDownInputComponent;
  @ViewChild("sltZFlashingType", { static: true })
  public sltZFlashingType: DropDownInputComponent;
  // -----------------------
  @ViewChild("sltLeftCutType", { static: true })
  public sltLeftCutType: DropDownInputComponent;
  @ViewChild("sltRightCutType", { static: true })
  public sltRightCutType: DropDownInputComponent;
  @ViewChild("sldLeftCutVertical", { static: true })
  public sldLeftCutVertical: SliderInputComponent;
  @ViewChild("sldLeftCutHorizontal", { static: true })
  public sldLeftCutHorizontal: SliderInputComponent;
  @ViewChild("sldRightCutVertical", { static: true })
  public sldRightCutVertical: SliderInputComponent;
  @ViewChild("sldRightCutHorizontal", { static: true })
  public sldRightCutHorizontal: SliderInputComponent;
  // -----------------------
  @ViewChild("sltRoofSheetingType", { static: true })
  public sltRoofSheetingType: DropDownInputComponent;
  @ViewChild("sltBaseFixingType", { static: true })
  public sltBaseFixingType: DropDownInputComponent;
  @ViewChild("sltRoofThickness", { static: true })
  public sltRoofThickness: DropDownInputComponent;
  @ViewChild("sltBargeType", { static: true })
  public sltBargeType: DropDownInputComponent;

  @ViewChild("sltDownpipeType", { static: true })
  public sltDownpipeType: DropDownInputComponent;
  @ViewChild("sltGutterType", { static: true })
  public sltGutterType: DropDownInputComponent;
  @ViewChild("sltBeamType", { static: true })
  public sltBeamType: DropDownInputComponent;
  @ViewChild("sltBeamSize", { static: true })
  public sltBeamSize: DropDownInputComponent;
  @ViewChild("sltHouseBeamSize", { static: true })
  public sltHouseBeamSize: DropDownInputComponent;
  @ViewChild("sltReceiverType", { static: true })
  public sltReceiverType: DropDownInputComponent;
  @ViewChild("sltColumnType", { static: true })
  public sltColumnType: DropDownInputComponent;

  @ViewChild("sltColourRoof", { static: true })
  public sltColourRoof: DropDownInputComponent;
  @ViewChild("sltColourBarge", { static: true })
  public sltColourBarge: DropDownInputComponent;
  @ViewChild("sltColourGutter", { static: true })
  public sltColourGutter: DropDownInputComponent;
  @ViewChild("sltColourZFlashing", { static: true })
  public sltColourZFlashing: DropDownInputComponent;
  @ViewChild("sltColourPost", { static: true })
  public sltColourPost: DropDownInputComponent;
  @ViewChild("sltColourBeam", { static: true })
  public sltColourBeam: DropDownInputComponent;
  @ViewChild("sltColourBracket", { static: true })
  public sltColourBracket: DropDownInputComponent;
  @ViewChild("sltColourFasciaBracket", { static: true })
  public sltColourFasciaBracket: DropDownInputComponent;
  @ViewChild("sltColourDownpipe", { static: true })
  public sltColourDownpipe: DropDownInputComponent;

  @ViewChild("sltCutOut", { static: true })
  public sltCutOut: DropDownInputComponent;

  @ViewChild("sltPanelDirection", { static: true })
  public sltPanelDirection: SwitchInputComponent;
  @ViewChild("sltPanelDirectionShow", { static: true })
  public sltPanelDirectionShow: CheckboxInputComponent;
  @ViewChild("sltBeamLayoutShow", { static: true })
  public sltBeamLayoutShow: CheckboxInputComponent;
  @ViewChild("sltDripBarge", { static: true })
  public sltDripBarge: CheckboxInputComponent;
  @ViewChild("sltFasciaUpstandBrackets", { static: true })
  public sltFasciaUpstandBrackets: DropDownInputComponent;
  @ViewChild("sltUpFasciaUpstandBracket", { static: true })
  public sltUpFasciaUpstandBracket: CheckboxInputComponent;
  @ViewChild("chkRapidSetCement", { static: true })
  public chkRapidSetCement: CheckboxInputComponent;
  @ViewChild("chkHasHouseBeam", { static: true })
  public chkHasHouseBeam: CheckboxInputComponent;

  @ViewChild("dialogRakeCut", { static: true })
  dialogRakeCut: DialogRakeCutComponent;
  @ViewChild("dialogDimension", { static: true })
  dialogDimension: DialogDimensionComponent;
  @ViewChild("dialogComfirm", { static: true })
  dialogComfirm: DialogConfirmComponent;
  @ViewChild("dialogAddCost", { static: true })
  dialogAddCost: DialogAddCostComponent;
  @ViewChild("dialogBOM", { static: true }) dialogBOM: DialogBOMComponent;
  @ViewChild("panelBOM", { static: true }) panelBOM: PanelBOMComponent;

  @ViewChild("btnTurnOffRakeCutLeft", { static: true })
  btnTurnOffRakeCutLeft: ToggleButtonComponent;
  @ViewChild("btnTurnOffRakeCutRight", { static: true })
  btnTurnOffRakeCutRight: ToggleButtonComponent;
  @ViewChild("btnTurnOffCutout", { static: true })
  btnTurnOffCutout: ToggleButtonComponent;
  @ViewChild("btnTurnOnPanel", { static: true })
  btnTurnOnPanel: ToggleButtonComponent;
  @ViewChild("btnEnableControl", { static: true })
  btnEnableControl: ToggleButtonComponent;

  static ins: HomeComponent;

  public objects: Objects;
  public env = CONFIG;

  public utils: Util;

  public scene: THREE.Scene;
  public canvas: HTMLCanvasElement;
  public renderer: THREE.WebGLRenderer;
  public camera: THREE.PerspectiveCamera;
  public control: OrbitControls;
  // private control: TrackballControls;

  private frameId: number = null;

  public loadCompleted = false;
  public oldOverhangBack: number = 0;
  public geometryManager: GeometryManager;
  private materialManager: MaterialManager;
  public eaveManager: EaveManager;
  public existingWallManager: ExistingWallManager;
  public fasciaManager: FasciaManager;
  public existingRoofManager: ExistingRoofManager;
  public uiManager: UIManager;
  public printingManager: Printing2DManager;

  public patiosManager: PatiosManager;

  // public flyOverManager: PatiosFlyOverManager;
  // public backFlyOverManager: PatiosBackFlyOverManager;
  // private freeStandingManager: PatiosFreeStandingManager;
  // private existingBuildingManager: ExistingBuildingManager;
  // private toFasciaManager: ToFasciaManager;

  // private columnAndPurlinManager: ColumnAndPurlinManager;
  // private roofManager: RoofManager;
  public groundManager: GroundManager;
  public dimensionManager: DimensionManager;

  public resizeEventHandle: any;

  public sldExistingWidth2Visible = true;
  public sldExistingLength2Visible = true;
  currentUser: User;
  public isVisible = false;
  isVisibleMoreInfo: boolean = false;
  isVisibleOpenModal = false;
  isVisibleProperty = !environment.production;
  isVisibleDiscount = false;
  isSaveAs = 0; // 0: none, 1: Copy, 2: Revised
  public addCostInstallDes = "";
  public isUploading = false;
  costList: ICost[] = [];
  dataOPEN: any;
  jobNoSearch = "";
  cusNameSearch = "";
  isSearching = false;
  isOpening = false;
  isPricing = false;
  isError = false;
  isAdmin = false;
  statusError = "";
  public statusText = STATUS_TEXT.NEW;
  maxSpan = 0;
  maxSpanText = "";
  public maxThickness = "";
  maxBaySizeText = "";
  maxBaySize = 0;
  maxBeamSizeText = "";
  beamSizeList = [];
  houseBeamSizeList = [];
  thicknessList = [];
  baseFixingTypeList = [];
  columnTypeList = [];
  public oldBuildingInfo = null;
  currentStep = 0;
  currentStepNew = 0;
  newOpenType = 0;
  existingHouseType = -1;
  roofPercent: string = "";
  version: string = APP_VERSION;
  releaseDate: string = RELEASE_DATE;
  rightToLeft: boolean = false;
  public flyoverBracketNumber: 3;
  public flyoverBracketNumberCutOut: 3;
  public fasciaBracketNumber: 3;
  public fasciaBracketNumberCutOut: 3;
  public currentBuildingInfo = null;
  public currentQuoteInfo = null;
  public roofHeight: number = 0;
  public upFasciaBracketList = null;
  public _patiosRoofType = PATIOS_ROOF_TYPE.FLAT_ROOF;
  public _isFBRoof: boolean = false;
  public panelType = PANEL_TYPE.RIBSPAN;

  private _albums: Array<Album> = [];

  set patiosRoofType(value: string) {
    let oldVal = this._patiosRoofType;
    this._patiosRoofType = value;
    if (oldVal != this._patiosRoofType) {
      this.uiManager.onStructureTypeChanged(
        null,
        this.sltStructureType.currentValue
      );
      this.uiManager.setBeamSize();
      this.uiManager.setMaxSpan();
      this.uiManager.validateCutout();
    }
  }
  get patiosRoofType(): string {
    return this._patiosRoofType;
  }

  set isFBRoof(value: boolean) {
    if (value != this._isFBRoof) {
      this._isFBRoof = value;
      this.uiManager.setBeamSize();
      this.uiManager.setMaxSpan();

      this.uiManager.onStructureTypeChanged(
        null,
        this.sltStructureType.currentValue
      );
    }
  }
  get isFBRoof(): boolean {
    return this._isFBRoof;
  }

  public get patiosBoundingBox(): Box3 {
    let boxHelper = new BoxHelper(this.patiosManager.patiosGroup);
    return boxHelper.geometry.boundingBox;
  }

  public APP_CONFIG = {
    activeLeftMenu: ACTIVE_LEFT_MENU,
  };

  public constructor(
    private ngZone: NgZone,
    public printService: PrintingService,
    private router: Router,
    private authenticationService: AuthenticationService,
    public message: NzMessageService,
    private jobService: JobService,
    private userService: UserService,
    private uiRakecutMgr: UIRakecutManager,
    public selectionMgr: SelectionManager,
    // public uiRakecutLeft: WidthManager
    public uiEditWall: EditWallManager,
    public uiEditLength2: EditLength2Manager,
    public uiEditWidth2: EditWidth2Manager,
    public uiCutout: CutOutManager,
    public uiCutBeam: CutBeamManager,
    private _lightbox: Lightbox
  ) {
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.geometryManager.init(this);
    this.materialManager = MaterialManager.Instance();
    this.eaveManager = new EaveManager();
    this.existingWallManager = new ExistingWallManager();
    this.fasciaManager = new FasciaManager();
    this.existingRoofManager = new ExistingRoofManager();
    this.objects = new Objects();
    this.groundManager = new GroundManager();
    this.dimensionManager = DimensionManager.Instance();
    this.printingManager = new Printing2DManager(this);
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.upFasciaBracketList = this.objects.fasciaBracketList.filter(
      (el) => el.id != 0
    );

    this.resizeEventHandle = this.resize.bind(this);

    window["APP"] = this;

    console.log(`App version: ${APP_VERSION}`);
    console.log(`Release date: ${RELEASE_DATE}`);

    HomeComponent.ins = this;
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }
  public ngOnInit(): void {
    if (!this.currentUser || !this.currentUser.user) {
      this.authenticationService.logout();
      this.router.navigate(["/login"]);
      return;
    }
    this.initObject();
  }
  public ngAfterViewInit(): void {
    $(".ui.accordion").accordion();
    this.init3D().then(() => {
      this.uiManager = new UIManager(this);
      // this.sldBaySize.addAction(this.onBaySizeChanged.bind(this));

      this.uiRakecutMgr.init();

      this.geometryManager
        .loadAll()
        .then(() => {
          return Promise.all([
            this.eaveManager.init(this),
            this.existingWallManager.init(this),
            this.fasciaManager.init(this),
            this.existingRoofManager.init(this),
            this.groundManager.init(this),
            this.dimensionManager.init(this),
            this.materialManager.init(this),
            this.selectionMgr.init(),
            // this.uiRakecutLeft.init()
            this.uiEditWall.init(),
            this.uiEditLength2.init(),
            this.uiEditWidth2.init(),
            this.uiCutout.init(),
            this.uiCutBeam.init(),
          ]);
        })
        .then(() => {
          return Promise.all([
            this.existingWallManager.optimize(),
            this.eaveManager.optimize(),
            this.fasciaManager.optimize(),
            this.existingRoofManager.optimize(),
            this.groundManager.optimize(),
            this.dimensionManager.optimize(),
          ]);
        })
        .then(() => {
          return Promise.all([
            this.existingWallManager.load(),
            this.eaveManager.load(),
            this.fasciaManager.load(),
            this.existingRoofManager.load(),
            this.selectionMgr.enable(),
            // this.dimensionManager.load(),
            // this.uiRakecutLeft.load()
            this.uiEditWall.load(),
            this.uiEditLength2.load(),
            this.uiEditWidth2.load(),
            this.uiCutout.load(),
            this.uiCutBeam.load(),
          ]);
        })
        .then(() => {
          // Init for Test
          this.sltExistingType.setSelected(INIT.EXISTING_TYPE);
          this.sltRoofPitch.setSelected(CONFIG.patiosPitch.default);
          //this.sltStructureType.setSelected(INIT.CONNECTION_TYPE);

          this.uiManager.load();
          this.renderStatic();
          this.newOpenType = 0;

          this.loadCompleted = true;
          if (environment.production) {
            this.isVisibleOpenModal = true;
          }
        });
      // this.test();
    });
  }

  public ngOnDestroy() {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
  }

  private init3D(): Promise<any> {
    // The first step is to get the reference of the canvas element from our HTML document
    this.canvas = this.rendererCanvas.nativeElement;

    this.canvas.width = this.viewerContainer.nativeElement.clientWidth;
    this.canvas.height = this.viewerContainer.nativeElement.clientHeight;

    this.renderer = new WebGLRenderer({
      canvas: this.canvas,
      // alpha: true,    // transparent background
      antialias: true, // smooth edges
      preserveDrawingBuffer: true,
    });
    this.renderer.setSize(this.canvas.width, this.canvas.height);
    this.renderer.localClippingEnabled = true;
    // this.renderer.gammaOutput = true;
    // this.renderer.setClearColor( 0x000000 );
    // this.renderer.setPixelRatio( window.devicePixelRatio );
    // this.renderer.shadowMap.enabled = true;

    // create the scene
    this.scene = new Scene();
    this.scene.background = new Color(0xcce0ff);
    // this.scene.fog = new THREE.Fog(0xcce0ff, 500, 10000);

    // Camera
    this.camera = new PerspectiveCamera(
      75,
      this.canvas.width / this.canvas.height,
      100,
      100000
    );
    this.camera.position.set(0, 1000, 12967);
    // this.camera.position.set(91.02454523941192, 18.45966896006735, 5.692036175338248);
    this.scene.add(this.camera);

    this.addShadowedLight(0, 0, 10000, 0xffffff, 0.7);
    this.addShadowedLight(0, 0, -10000, 0xffffff, 0.7);
    this.addShadowedLight(0, 10000, 0, 0xffffff, 0.7);
    this.addShadowedLight(0, -10000, 0, 0xffffff, 0.7);
    this.addShadowedLight(10000, 0, 0, 0xffffff, 0.7);
    this.addShadowedLight(-10000, 0, 0, 0xffffff, 0.7);

    // control
    // this.control = new TrackballControls(this.camera, this.renderer.domElement);
    this.control = new OrbitControls(this.camera, this.renderer.domElement);
    // this.control.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    // this.control.dampingFactor = 0.05;
    // this.control.screenSpacePanning = false;
    //this.control.minDistance = 100;
    this.control.maxDistance = 50000; // max zoom
    this.control.maxPolarAngle = this.utils.degreesToRadians(90);

    window["scene"] = this.scene;
    window["camera"] = this.camera;

    this.animate();
    return Promise.resolve();
  }

  public showEditBay() {
    this.dialogEditBay.show(true);
  }

  private addShadowedLight(x, y, z, color, intensity): void {
    const directionalLight = new DirectionalLight(color, intensity);
    directionalLight.position.set(x, y, z);
    this.scene.add(directionalLight);
  }
  private animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== "loading") {
        this.render();
      } else {
        window.addEventListener("DOMContentLoaded", () => {
          this.render();
        });
      }

      window.addEventListener("resize", this.resizeEventHandle);

      this.control.addEventListener("change", this.renderStatic.bind(this));
    });
  }
  private render(): void {
    // this.frameId = requestAnimationFrame(() => {
    //   this.render();
    // });
    // //this.control.update();
    // this.renderer.render(this.scene, this.camera);
  }
  public renderStatic() {
    this.renderer.render(this.scene, this.camera);
  }
  private resize(): void {
    const width = this.viewerContainer.nativeElement.clientWidth;
    const height = this.viewerContainer.nativeElement.clientHeight;

    this.canvas.width = width;
    this.canvas.height = height;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);

    this.renderStatic();
  }
  round(number, precision = 0) {
    return _.round(number, precision);
  }
  public calcQuoteInfo(): Promise<any> {
    return Promise.resolve().then(() => {
      const _quoteInfo = { ...this.currentQuoteInfo.quoteInfo };
      let _gst = 0;
      let _totalPrice = 0;
      if (this.currentQuoteInfo.clientInfo.supplyType === "supply_only") {
        _gst = this.round(
          ((_quoteInfo.nett +
            _quoteInfo.addCostInstall +
            _quoteInfo.supplyOnlyKits) *
            10) /
            100,
          2
        );
        _totalPrice = this.round(
          _quoteInfo.nett +
            _quoteInfo.addCostInstall +
            _quoteInfo.supplyOnlyKits +
            _gst,
          100
        );
      } else {
        _gst = this.round(
          ((_quoteInfo.nett +
            _quoteInfo.addCostInstall +
            _quoteInfo.fasciaInstall +
            _quoteInfo.flyOverInstall +
            _quoteInfo.installMaterial +
            _quoteInfo.perPostInstall +
            _quoteInfo.addCost) *
            10) /
            100,
          2
        );
        _totalPrice = this.round(
          _quoteInfo.nett +
            _quoteInfo.addCostInstall +
            _quoteInfo.fasciaInstall +
            _quoteInfo.flyOverInstall +
            _quoteInfo.installMaterial +
            _quoteInfo.perPostInstall +
            _quoteInfo.addCost +
            _gst,
          2
        );
      }
      const _info = {
        ...this.currentQuoteInfo.quoteInfo,
        gst: _gst,
        totalPrice: _totalPrice,
        total0: _totalPrice,
      };
      this.jobService
        .calcQuoteInfo(_info)
        .pipe(first())
        .subscribe(
          (data) => {
            if (data.statusCode === 200) {
              this.currentQuoteInfo.quoteInfo = data.data;
            } else {
              this.message.error(data.message);
            }
          },
          (error) => {
            this.message.error(error);
            this.isUploading = false;
          }
        );
    });
    // return Promise.resolve();
  }

  calcPayment(): void {
    /** wait for refresh value */
    const _values = { ...this.currentQuoteInfo.quoteInfo };
    let _totalPriceTmp = 0;
    let _discount = _values.discount;
    let _deposit = this.currentQuoteInfo.quoteInfo.deposit;
    let _stage1 = this.currentQuoteInfo.quoteInfo.stage1;
    let _stage2 = this.currentQuoteInfo.quoteInfo.stage2;
    let _finalPayment = 0;
    _totalPriceTmp = this.currentQuoteInfo.quoteInfo.total0;

    if (_values.discountPer > 0) {
      _discount = this.round((_totalPriceTmp * _values.discountPer) / 100, 2);
    }
    _totalPriceTmp -= _discount;
    if (_values.depositPer > 0) {
      _deposit = this.round((_totalPriceTmp * _values.depositPer) / 100, 2);
    } else {
      _deposit = 0;
    }
    if (_values.stage1Per > 0) {
      _stage1 = this.round((_totalPriceTmp * _values.stage1Per) / 100, 2);
    } else {
      _stage1 = 0;
    }
    if (_values.stage2Per > 0) {
      _stage2 = this.round((_totalPriceTmp * _values.stage2Per) / 100, 2);
    } else {
      _stage2 = 0;
    }
    _finalPayment = this.round(
      _totalPriceTmp - _deposit - _stage1 - _stage2,
      2
    );
    if (_finalPayment < 0) {
      _finalPayment = 0;
    }
    // --------------
    if (
      _values.depositPer + _values.stage1Per + _values.stage2Per === 100 &&
      _finalPayment > 0
    ) {
      _stage1 = this.round(_stage1 + _finalPayment, 2);
      _finalPayment = 0;
    }
    // --------------
    if (
      this.round(_deposit + _stage1 + _stage2 + _finalPayment, 2) >
      _totalPriceTmp
    ) {
      _stage1 -=
        this.round(_deposit + _stage1 + _stage2 + _finalPayment, 2) -
        _totalPriceTmp;
    }
    //
    this.currentQuoteInfo.quoteInfo = {
      ...this.currentQuoteInfo.quoteInfo,
      totalPrice: _totalPriceTmp,
      discount: _discount,
      deposit: _deposit,
      stage1: _stage1,
      stage2: _stage2,
      finalPayment: _finalPayment,
      discountPer: _values.discountPer,
      depositPer: _values.depositPer,
      stage1Per: _values.stage1Per,
      stage2Per: _values.stage2Per,
      addCostInstall: _values.addCostInstall,
    };
  }
  getRoofPercent(value) {
    return new Promise<string>((resolve) => {
      const _count = Math.ceil(value / 762);
      const _ret = `${this.round((value * 100) / (_count * 762), 2)}% (${
        _count * 762
      })`;
      resolve(_ret);
    }).then((value) => (this.roofPercent = value));
  }
  public getMaxOverhang(): number {
    // N2 N3
    if (UI.windClass == 0 || UI.windClass == 1) {
      return 900;
    }
    // N4
    else if (UI.windClass == 2) {
      return 600;
    } else {
      return 600;
    }
  }
  public onSetMaxValue(): void {
    const _maxRibspanOverhang = this.getMaxOverhang();

    if (
      HomeComponent.ins.patiosRoofType == PATIOS_ROOF_TYPE.GABLE_ROOF &&
      !HomeComponent.ins.isFBRoof
    ) {
      this.sldLeftOverhang.setMax(this.round(_maxRibspanOverhang));
      this.sldRightOverhang.setMax(this.round(_maxRibspanOverhang));
      if (this.dialogEditBay.listBay.length > 0) {
        const _maxBay = _.maxBy(this.dialogEditBay.listBay, "value").value;
        const _fasciaUpstand = this.sltFasciaUpstandBrackets.currentValue;
        if (_fasciaUpstand != 0) {
          const _beamType = this.sltBeamType.currentValue;
          const _beamSize = this.sltBeamSize.currentText;
          const _size =
            _beamType == 1
              ? _beamSize.substring(0, 3)
              : _beamSize.substring(2, 5);
          let _sizeInt = parseInt(_size);
          if (_sizeInt) {
            let _maxValue = 1200;
            if (_sizeInt >= 210) _maxValue = 1500;
            else if (_sizeInt <= 110) _maxValue = 900;
            this.sldFrontOverhang.setMax(_maxValue);
            this.sldBackOverhang.setMax(_maxValue);
          }
        } else {
          this.sldFrontOverhang.setMax(this.round(_maxBay / 4));
          this.sldBackOverhang.setMax(this.round(_maxBay / 4));
        }
      }
    } else {
      this.sldFrontOverhang.setMax(this.round(_maxRibspanOverhang));
      this.sldBackOverhang.setMax(this.round(_maxRibspanOverhang));
      if (this.dialogEditBay.listBay.length > 0) {
        const _maxBay = _.maxBy(this.dialogEditBay.listBay, "value").value;
        const _fasciaUpstand = this.sltFasciaUpstandBrackets.currentValue;
        if (_fasciaUpstand != 0) {
          const _beamType = this.sltBeamType.currentValue;
          const _beamSize = this.sltBeamSize.currentText;
          const _size =
            _beamType == 1
              ? _beamSize.substring(0, 3)
              : _beamSize.substring(2, 5);
          let _sizeInt = parseInt(_size);
          if (_sizeInt) {
            let _maxValue = 1200;
            if (_sizeInt >= 210) _maxValue = 1500;
            else if (_sizeInt <= 110) _maxValue = 900;
            this.sldLeftOverhang.setMax(_maxValue);
            this.sldRightOverhang.setMax(_maxValue);
          }
        } else {
          this.sldLeftOverhang.setMax(this.round(_maxBay / 4));
          this.sldRightOverhang.setMax(this.round(_maxBay / 4));
        }
      }
    }
  }
  public calcPostSpacingInfoWhenOverallRoofLengthChanged(): Promise<any> {
    const _info = {
      ...this.currentBuildingInfo,
      buildingInfo: this.bindBuildingInfo(),
      clientInfo: this.currentQuoteInfo.clientInfo,
      quoteInfo: this.currentQuoteInfo.quoteInfo,
    };
    // const _info = {
    //   buildingInfo: {
    //     existingType: UI.existingType,
    //     structureType: UI.structureType,
    //     beamSize: UI.beamSize,
    //     span: UI.span,
    //     multiSpan: UI.multiSpan,
    //     windClass: UI.windClass,
    //     roofOverallLength: UI.roofOverallLength,
    //   },
    // };
    this.jobService
      .calcRibSpanInfo(_info)
      .pipe(first())
      .subscribe((data) => {
        if (data.data.ribSpanInfo) {
          const { maxPostSpacing, noOfPost } = data.data.ribSpanInfo;

          // Set max baysize before set num of bay
          if (this.maxBaySize != maxPostSpacing) {
            this.maxBaySize = maxPostSpacing;
            this.maxBaySizeText = `${maxPostSpacing} max`;
          }

          let overhangLength = UI.overhangLeft + UI.overhangRight;
          if (
            HomeComponent.ins.patiosRoofType == PATIOS_ROOF_TYPE.GABLE_ROOF &&
            HomeComponent.ins.isFBRoof
          ) {
            overhangLength = UI.overhangFront + UI.overhangBack;
          }
          const current3DOverallRoofLength = UI.totalBayLength + overhangLength;

          // If no of bay change, auto seperate list bays. If no change, keep it.
          // If user input overall, we must set listbay from noOfPost
          if (
            UI.noOfBays != noOfPost + 1 ||
            current3DOverallRoofLength != UI.roofOverallLength
          ) {
            const baySpacing =
              (UI.roofOverallLength - overhangLength) / (noOfPost + 1);
            HomeComponent.ins.sldBaySize.setValue(baySpacing);
            HomeComponent.ins.sldNoOfBay.setValue(noOfPost + 1);
          }
        }
      });
    return Promise.resolve();
  }
  public calcRibSpanInfo(): Promise<any> {
    const _info = {
      ...this.currentBuildingInfo,
      buildingInfo: this.bindBuildingInfo(),
      clientInfo: this.currentQuoteInfo.clientInfo,
      quoteInfo: this.currentQuoteInfo.quoteInfo,
    };
    this.jobService
      .calcRibSpanInfo(_info)
      .pipe(first())
      .subscribe((data) => {
        if (data.data.ribSpanInfo) {
          const { noOfInternalRafter, noOfInternalBeam } =
            data.data.ribSpanInfo;

          this.patiosManager.loadRafterBeamAndInternalBeam({
            numOfInternalRafter: noOfInternalRafter,
            numOfSpanBeam: noOfInternalBeam,
            numOfMultiSpanBeam: 0,
          });

          // After call loadRafterBeamAndInternalBeam, some object will be change, need to calc BOM again.
          HomeComponent.ins.uiManager.onUIChanged(null, null);
        }
      });
    return Promise.resolve();
  }
  public onCalcPrice(): Promise<any> {
    if (!this.currentBuildingInfo.isOpen) {
      this.isPricing = true;
      this.currentBuildingInfo.quoteInfo.totalPrice = 0;
    }

    const _info = {
      ...this.currentBuildingInfo,
      buildingInfo: this.bindBuildingInfo(),
      clientInfo: this.currentQuoteInfo.clientInfo,
      quoteInfo: this.currentQuoteInfo.quoteInfo,
    };
    if (!environment.production) {
      this.patiosManager.loadBracket(2);
      this.patiosManager.loadBracketCutout(2);
      return Promise.resolve();
    }
    if (
      (_info.buildingInfo.listPosts.length === 0 &&
        _info.buildingInfo.existingType.value !== 3) ||
      this.currentBuildingInfo.isOpen
    ) {
      return Promise.resolve();
    }
    const _structureType = _info.buildingInfo.structureType.value;
    if (_structureType === 0 || _structureType === 3) {
      if (
        _info.buildingInfo.existingLength +
          _info.buildingInfo.existingLength2 *
            (_info.buildingInfo.existingType.value === 3 ? 2 : 1) <
        _info.buildingInfo.roofOverallLength
      ) {
        this.isError = true;
        this.statusError =
          "The Patios length greater than existing building. Please check existing building length.";
        return Promise.resolve();
      }
    }

    if (_info.buildingInfo.minHeight < 1900) {
      this.isError = true;
      this.statusError =
        "Min. Height Under Beam must be greater than or equal to 1900mm.";
      return Promise.resolve();
    }
    if (_info.buildingInfo.columnType.value < 0) {
      this.isError = true;
      this.statusError = `Patios Height is over ${
        _info.buildingInfo.structureType.value === 4 ? "3600mm" : "4000mm"
      }.`;
      return Promise.resolve();
    }
    if (
      _info.buildingInfo.roofSheetingType.value === 2 &&
      _info.buildingInfo.roofPitch < 5
    ) {
      this.isError = true;
      this.statusError = `${_info.buildingInfo.roofSheetingType.text} must be greater than or equal to 5 degrees pitch`;
      return Promise.resolve();
    }

    if (
      this.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF &&
      UI.span > this.maxSpan
    ) {
      this.isError = true;
      this.statusError = `Span must be less than max span!`;
      return Promise.resolve();
    }

    if (
      this.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF &&
      UI.structureType == CONNECTION_TYPE.EXISTING &&
      UI.height + (UI.span / 2) * this.utils.tan(UI.patiosPitch) >
        UI.existingWallHeight
    ) {
      this.isError = true;
      this.statusError = `Roof top must be lower eave height!`;
      return Promise.resolve();
    }

    this.jobService
      .calcBOMByPara(_info)
      .pipe(first())
      .subscribe(
        (data) => {
          const maxInfo = { ...data.data.maxInfo };
          if (data.statusCode === 200) {
            this.currentBuildingInfo.quoteInfo = { ...data.data.quoteInfo };
            this.currentBuildingInfo.BOMInfo = [...data.data.BOMInfo];
            this.currentBuildingInfo.additionalBOM = [
              ...data.data.additionalBOM,
            ];
            this.currentQuoteInfo.quoteInfo = { ...data.data.quoteInfo };
            this.isError = false;
            this.statusError = "";
          } else {
            if (data.data.error) {
              this.message.error(data.data.error);
              this.isError = true;
              this.statusError = data.data.error;
              // console.log('onCalcPrice :', data.data.error);
            } else {
              this.message.error(maxInfo.error);
              this.isError = true;
              this.statusError = maxInfo.error;
              // console.log('onCalcPrice :', _maxInfo.error);
            }
          }
          if (this.patiosRoofType === PATIOS_ROOF_TYPE.FLAT_ROOF) {
            let _isChanged = false;
            if (maxInfo.beamSize.value === 0) {
              this.beamSizeList = this.objects.beamSizeList.filter(
                (f) => f.id == 0 && f.beamType == this.sltBeamType.currentValue
              );
              this.sltBeamSize.setSelected(0);
              _isChanged = true;
            } else {
              this.beamSizeList = this.objects.beamSizeList.filter(
                (f) =>
                  f.id >= maxInfo.minBeamSize.value &&
                  f.beamType == this.sltBeamType.currentValue
              );
              if (this.sltBeamSize.currentValue < maxInfo.beamSize.value) {
                this.sltBeamSize.setSelected(maxInfo.beamSize.value);
                _isChanged = true;
              }
            }
            //House beam
            if (this.chkHasHouseBeam.currentValue) {
              if (maxInfo.houseBeamSize.value === 0) {
                this.houseBeamSizeList = this.objects.beamSizeList.filter(
                  (f) =>
                    f.id == 0 && f.beamType == this.sltBeamType.currentValue
                );
                this.sltBeamSize.setSelected(0);
                _isChanged = true;
              } else {
                this.houseBeamSizeList = this.objects.beamSizeList.filter(
                  (f) =>
                    f.id >= maxInfo.minHouseBeamSize.value &&
                    f.beamType == this.sltBeamType.currentValue
                );
                if (
                  this.sltHouseBeamSize.currentValue <
                  maxInfo.houseBeamSize.value
                ) {
                  this.sltHouseBeamSize.setSelected(
                    maxInfo.houseBeamSize.value
                  );
                  _isChanged = true;
                }
              }
            } else {
              this.houseBeamSizeList = this.beamSizeList;
              this.sltHouseBeamSize.setSelected(this.sltBeamSize.currentValue);
            }

            if (_isChanged) {
              setTimeout(() => {
                this.patiosManager.load();
              }, 700);
            }
          }
          if (maxInfo.thickness.value === 0) {
            this.thicknessList = this.objects.thicknessList.filter(
              (f) => f.id == 0
            );
            this.sltRoofThickness.setSelectedAndHandleEvent(0);
          } else {
            this.thicknessList = this.objects.thicknessList.filter(
              (f) => f.id >= maxInfo.minThickness.value
            );
            if (this.sltRoofThickness.currentValue < maxInfo.thickness.value) {
              this.sltRoofThickness.setSelectedAndHandleEvent(
                maxInfo.thickness.value
              );
            }
          }
          if (this.patiosRoofType === PATIOS_ROOF_TYPE.FLAT_ROOF) {
            this.maxSpan = maxInfo.maxSpan;
            this.maxSpanText = maxInfo.maxSpanText;
          }
          // this.maxBaySizeText = maxInfo.maxBaySizeText;
          this.maxThickness = maxInfo.maxThickness;
          this.maxBeamSizeText = maxInfo.maxBeamSizeText;
          this.flyoverBracketNumber = maxInfo.flyoverBracketNumber;
          this.flyoverBracketNumberCutOut = maxInfo.flyoverBracketNumberCutOut;
          this.fasciaBracketNumber = maxInfo.fasciaBracketNumber;
          this.fasciaBracketNumberCutOut = maxInfo.fasciaBracketNumberCutOut;

          if ([3, 5, 6].includes(UI.structureType)) {
            if (
              HomeComponent.ins.patiosRoofType ===
                PATIOS_ROOF_TYPE.GABLE_ROOF &&
              HomeComponent.ins.isFBRoof
            ) {
              // Phan nay ve braket cua mai gable phia sau
              // this.patiosManager.loadBracket(this.fasciaBracketNumberCutOut);
              this.patiosManager.loadBracket(UI.span >= 4000 ? 3 : 2);

              // Phan nay ve braket cua mai cutout
              this.patiosManager.loadBracketCutout(
                this.fasciaBracketNumberCutOut
              );

              // Load braket mai chiu luc
              this.patiosManager.loadSideGableBracket(this.fasciaBracketNumber);
            } else {
              this.patiosManager.loadBracket(this.fasciaBracketNumber);
              this.patiosManager.loadBracketCutout(
                this.fasciaBracketNumberCutOut
              );
            }
          } else {
            if (
              HomeComponent.ins.patiosRoofType ===
                PATIOS_ROOF_TYPE.GABLE_ROOF &&
              HomeComponent.ins.isFBRoof
            ) {
              // Phan nay ve braket cua mai gable phia sau
              // this.patiosManager.loadBracket(this.flyoverBracketNumber);
              this.patiosManager.loadBracket(UI.span >= 4000 ? 3 : 2);

              // Phan nay ve braket cua mai cutout
              this.patiosManager.loadBracketCutout(
                this.flyoverBracketNumberCutOut
              );

              // Load braket mai chiu luc
              this.patiosManager.loadSideGableBracket(
                this.flyoverBracketNumber
              );
            } else {
              this.patiosManager.loadBracket(this.flyoverBracketNumber);
              this.patiosManager.loadBracketCutout(
                this.flyoverBracketNumberCutOut
              );
            }
          }
          this.isPricing = false;
        },
        (error) => {
          this.message.error(error);
          this.isError = true;
          this.statusError = error;
          this.isUploading = false;
        }
      );
    return Promise.resolve();
  }
  public onSupplyTypeChange(): Promise<any> {
    const _info = {
      ...this.currentBuildingInfo,
      buildingInfo: this.bindBuildingInfo(),
      clientInfo: {
        ...this.currentQuoteInfo.clientInfo,
      },
      quoteInfo: this.currentQuoteInfo.quoteInfo,
    };
    if (_info.buildingInfo.listPosts.length === 0) {
      return;
    }
    this.jobService
      .calcBOMByPara(_info)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.statusCode === 200) {
            this.currentQuoteInfo.quoteInfo = data.data.quoteInfo;
          } else {
            this.message.error(data.message);
            console.log("onSupplyTypeChange :", data.message);
          }
        },
        (error) => {
          this.message.error(error);
          console.log("onSupplyTypeChange :", error);
          this.isUploading = false;
        }
      );
    return Promise.resolve();
  }
  onNewConnectionType(type: number): void {
    if (type == 4) {
      this.isFBRoof = false;
    }
    if (this.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF) {
      this.sltStructureType.setSelected(type);

      this.uiManager.onStructureTypeChanged(null, type);
    }
    // if (type < 5) {
    if (type == 4) {
      this.existingHouseType = 0;
    }
    this.initObject();
    if (type === 5 || type == 6) {
      this.currentBuildingInfo.buildingInfo.backFascia = type === 6;
      this.currentBuildingInfo.buildingInfo.fasciaBracketHeight =
        type === 5 || type === 6
          ? this.env.fasciaUpstandBrackets.max
          : this.env.fasciaUpstandBrackets.default;
    } else if (type === 3) {
      this.currentBuildingInfo.buildingInfo.fasciaBracketHeight = 0;
    }
    this.currentBuildingInfo.buildingInfo.structureType.value = type;
    //HACK: Existing Type: Alway minHeight > 1900mm
    if (type === CONNECTION_TYPE.EXISTING) {
      this.currentBuildingInfo.buildingInfo.eaveHeight =
        this.currentBuildingInfo.buildingInfo.buildingHeight +
        EXISTING_BUILDING_CONFIG.BUILDING_HEIGHT_OFFSET;
    }
    this.uiManager.loadFromObject(this.currentBuildingInfo.buildingInfo);
    if (this.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF) {
      this.sltRoofPitch.setSelected(10);

      if (this.isFBRoof && UI.structureType == CONNECTION_TYPE.EXISTING) {
        this.sldExistingWallHeight.setValue(4000);
      }
    } else {
      this.sltRoofPitch.setSelected(2);
    }
    this.isVisibleOpenModal = false;
    this.statusText = STATUS_TEXT.NEW;
    this.isSaveAs = 0;
    // }
  }
  onNewExistingHouseType(type: number, FBRoof: boolean = false): void {
    let isReload = false;
    if (
      this.isFBRoof !== FBRoof &&
      (type == this.existingHouseType || this.existingHouseType == -1)
    ) {
      isReload = true;
    }
    this.existingHouseType = type;
    this.currentStepNew = 1;
    this.isFBRoof = FBRoof;
    if (isReload) {
      this.uiManager.onStructureTypeChanged(null, type);
    }
  }
  onShowOpenModal(type: number): void {
    this.newOpenType = type === 0 ? 0 : 1;
    this.existingHouseType = -1;
    this.currentStepNew = 0;
    this.isSearching = false;
    this.isOpening = false;
    this.isVisibleOpenModal = true;
  }
  // Save
  onShowSaveModal(): void {
    this.isVisibleDiscount = false;
    this.isVisibleMoreInfo = false;
    // Validation checks
    this.currentBuildingInfo = {
      ...this.currentBuildingInfo,
      buildingInfo: { ...this.bindBuildingInfo() },
    };
    if (this.currentBuildingInfo.isOpen && this.isSaveAs === 0) {
      this.message.error(
        'This project could not be saved because it is existed project, please <b>"Save As"</b> to edit as new project.'
      );
    }
    if (this.isError) {
      this.message.error(this.statusError);
      return;
    }
    this.panelBOM.onRefresh();
    this.isPricing = true;
    this.jobService
      .checkData(this.currentBuildingInfo)
      .pipe(first())
      .subscribe(
        (cb) => {
          if (cb.statusCode === 200) {
            if (cb.data.status) {
              this.currentQuoteInfo = {
                clientInfo: {
                  ...this.currentBuildingInfo.clientInfo,
                  date:
                    this.isSaveAs > 0
                      ? new Date()
                      : this.currentBuildingInfo.clientInfo.date,
                },
                quoteInfo: { ...this.currentBuildingInfo.quoteInfo },
              };
              this.addCostInstallDes =
                this.currentQuoteInfo.quoteInfo.costList.length > 0
                  ? this.currentQuoteInfo.quoteInfo.costList[0].description
                  : "";
              this.currentStep = 0;
              this.isUploading = false;
              if (!this.currentQuoteInfo.quoteInfo.customText) {
                this.currentQuoteInfo.quoteInfo.customText = cb.data.customText;
              }
              this.currentBuildingInfo.buildingInfo.flyoverBracketNumber =
                cb.data.maxInfo.flyoverBracketNumber;
              this.currentBuildingInfo.buildingInfo.flyoverBracketNumberCutOut =
                cb.data.maxInfo.flyoverBracketNumberCutOut;
              this.currentBuildingInfo.buildingInfo.fasciaBracketNumber =
                cb.data.maxInfo.fasciaBracketNumber;
              this.currentBuildingInfo.buildingInfo.fasciaBracketNumberCutOut =
                cb.data.maxInfo.fasciaBracketNumberCutOut;
              if (
                this.currentBuildingInfo._id === null &&
                this.isSaveAs === 0
              ) {
                this.jobService
                  .genJobNo({ userId: this.currentUser.user._id })
                  .pipe(first())
                  .subscribe(
                    (cbJob) => {
                      if (cbJob.statusCode === 200) {
                        this.isPricing = false;
                        this.currentQuoteInfo.clientInfo.jobNo = cbJob.data;
                        this.isVisible = true;
                        this.isError = false;
                        this.statusError = "";
                      }
                    },
                    (error) => {
                      this.message.error(error);
                      this.isError = true;
                      this.statusError = error;
                    }
                  );
              } else {
                this.isPricing = false;
                this.isVisible = true;
              }
            } else {
              this.message.error(cb.data.error);
              this.isError = true;
              this.statusError = cb.data.error;
              // console.log('onShowSaveModal :', cb.data.error);
            }
          } else {
            this.message.error(cb.message);
            this.isError = true;
            this.statusError = cb.message;
            // console.log('onShowSaveModal :', cb.message);
          }
        },
        (error) => {
          this.message.error(error);
          this.isError = true;
          this.statusError = error;
        }
      );
    // End check
  }

  onNext(): void {
    if (this.currentQuoteInfo.clientInfo.jobNo === "") {
      this.message.warning("Please input your <b>Job No.</b>!");
      return;
    }
    if (this.currentQuoteInfo.clientInfo.cusName === "") {
      this.message.warning("Please input your <b>Client Name</b>!");
      return;
    }
    this.currentStep += 1;
  }
  onPre(): void {
    this.currentStep -= 1;
  }
  onNextNew(): void {
    this.currentStepNew = 1;
  }
  onPreNew(): void {
    this.currentStepNew = 0;
  }
  onCustomTextChange(text, type) {
    let info = null;
    if (type === 0) {
      info = { depositText: text };
    } else if (type === 1) {
      info = { stage1Text: text };
    } else {
      info = { stage2Text: text };
    }
    this.userService
      .updateCustomText(this.currentUser.user._id, info)
      .subscribe((rs) => {});
  }
  onSaveAs(type: number): void {
    if (this.currentBuildingInfo._id === null) {
      this.message.warning(
        `You can't <b>[${
          type === 1 ? "Copy" : "Revised"
        }]</b> because <b>[Job No.]</b> is empty.`
      );
      return;
    }
    this.onQuickSaveAs(type, this.currentBuildingInfo._id);
  }
  onQuickSaveAs(type: number, id: any): void {
    this.isOpening = true;
    this.jobService
      .copyOrRevisedById(id, type)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.statusCode === 200) {
            this.currentBuildingInfo = { ...data.data.list, _id: null };
            if (!this.currentBuildingInfo.quoteInfo.costList) {
              this.currentBuildingInfo.quoteInfo.costList = [];
            }
            if (!this.currentBuildingInfo.additionalBOM) {
              this.currentBuildingInfo.additionalBOM = [];
            }
            if (
              this.currentBuildingInfo.quoteInfo.costList.length === 0 &&
              this.currentBuildingInfo.quoteInfo.addCostInstall > 0
            ) {
              this.currentBuildingInfo.quoteInfo.costList.push({
                index: 1,
                description: "",
                value: this.currentBuildingInfo.quoteInfo.addCostInstall,
              });
            }
            this.currentQuoteInfo = {
              clientInfo: { ...data.data.list.clientInfo },
              quoteInfo: { ...data.data.list.quoteInfo },
            };
            this.currentQuoteInfo.quoteInfo.costList = [
              ...this.currentBuildingInfo.quoteInfo.costList,
            ];
            const _structureType =
              data.data.list.buildingInfo.structureType.value;

            this.uiManager
              .loadFromObject(data.data.list.buildingInfo)
              .then(() => {
                const _maxInfo = { ...data.data.maxInfo };
                if (this.patiosRoofType === PATIOS_ROOF_TYPE.FLAT_ROOF) {
                  if (_maxInfo.beamSize.value === 0) {
                    this.beamSizeList = this.objects.beamSizeList.filter(
                      (f) =>
                        f.id == 0 && f.beamType == this.sltBeamType.currentValue
                    );
                    this.sltBeamSize.setSelected(0);
                  } else {
                    this.beamSizeList = this.objects.beamSizeList.filter(
                      (f) =>
                        f.id >= _maxInfo.minBeamSize.value &&
                        f.beamType == this.sltBeamType.currentValue
                    );
                    if (
                      this.sltBeamSize.currentValue < _maxInfo.beamSize.value
                    ) {
                      this.sltBeamSize.setSelected(_maxInfo.beamSize.value);
                    }
                  }
                  //House beam
                  if (this.chkHasHouseBeam.currentValue) {
                    if (_maxInfo.houseBeamSize.value === 0) {
                      this.houseBeamSizeList = this.objects.beamSizeList.filter(
                        (f) =>
                          f.id == 0 &&
                          f.beamType == this.sltBeamType.currentValue
                      );
                      this.sltHouseBeamSize.setSelected(0);
                    } else {
                      this.houseBeamSizeList = this.objects.beamSizeList.filter(
                        (f) =>
                          f.id >= _maxInfo.minHouseBeamSize.value &&
                          f.beamType == this.sltBeamType.currentValue
                      );
                      if (
                        this.sltHouseBeamSize.currentValue <
                        _maxInfo.houseBeamSize.value
                      ) {
                        this.sltHouseBeamSize.setSelected(
                          _maxInfo.houseBeamSize.value
                        );
                      }
                    }
                  } else {
                    this.houseBeamSizeList = this.beamSizeList;
                    this.sltHouseBeamSize.setSelected(
                      this.sltBeamSize.currentValue
                    );
                  }
                }

                if (_maxInfo.thickness.value === 0) {
                  this.thicknessList = this.objects.thicknessList.filter(
                    (f) => f.id == 0
                  );
                  this.sltRoofThickness.setSelectedAndHandleEvent(0);
                } else {
                  this.thicknessList = this.objects.thicknessList.filter(
                    (f) => f.id >= _maxInfo.minThickness.value
                  );
                  if (
                    this.sltRoofThickness.currentValue <
                    _maxInfo.thickness.value
                  ) {
                    this.sltRoofThickness.setSelectedAndHandleEvent(
                      _maxInfo.thickness.value
                    );
                  }
                }
                if (this.patiosRoofType === PATIOS_ROOF_TYPE.FLAT_ROOF) {
                  this.maxSpan = _maxInfo.maxSpan;
                  this.maxSpanText = _maxInfo.maxSpanText;
                }
                // this.maxBaySizeText = _maxInfo.maxBaySizeText;
                this.maxThickness = _maxInfo.maxThickness;
                this.maxBeamSizeText = _maxInfo.maxBeamSizeText;
                this.flyoverBracketNumber = _maxInfo.flyoverBracketNumber;
                this.flyoverBracketNumberCutOut =
                  _maxInfo.flyoverBracketNumberCutOut;
                this.currentBuildingInfo.buildingInfo.flyoverBracketNumber =
                  this.flyoverBracketNumber;
                this.currentBuildingInfo.buildingInfo.flyoverBracketNumberCutOut =
                  this.flyoverBracketNumberCutOut;
                this.fasciaBracketNumber = _maxInfo.fasciaBracketNumber;
                this.fasciaBracketNumberCutOut =
                  _maxInfo.fasciaBracketNumberCutOut;
                this.currentBuildingInfo.buildingInfo.fasciaBracketNumber =
                  this.fasciaBracketNumber;
                this.currentBuildingInfo.buildingInfo.fasciaBracketNumberCutOut =
                  this.fasciaBracketNumberCutOut;
                if (_structureType == 3) {
                  if (
                    HomeComponent.ins.patiosRoofType ===
                      PATIOS_ROOF_TYPE.GABLE_ROOF &&
                    HomeComponent.ins.isFBRoof
                  ) {
                    // Phan nay ve braket cua mai gable phia sau
                    // this.patiosManager.loadBracket(this.fasciaBracketNumberCutOut);
                    this.patiosManager.loadBracket(UI.span >= 4000 ? 3 : 2);

                    // Phan nay ve braket cua mai cutout
                    this.patiosManager.loadBracketCutout(
                      this.fasciaBracketNumber
                    );

                    // Load braket mai chiu luc
                    this.patiosManager.loadSideGableBracket(
                      this.fasciaBracketNumber
                    );
                  } else {
                    //Fascia
                    this.patiosManager.loadBracket(this.fasciaBracketNumber);
                    this.patiosManager.loadBracketCutout(
                      this.fasciaBracketNumberCutOut
                    );
                  }
                }
                if (_structureType == 1 || _structureType == 2) {
                  if (
                    HomeComponent.ins.patiosRoofType ===
                      PATIOS_ROOF_TYPE.GABLE_ROOF &&
                    HomeComponent.ins.isFBRoof
                  ) {
                    // Phan nay ve braket cua mai gable phia sau
                    // this.patiosManager.loadBracket(this.flyoverBracketNumberCutOut);
                    this.patiosManager.loadBracket(UI.span >= 4000 ? 3 : 2);

                    // Phan nay ve braket cua mai cutout
                    this.patiosManager.loadBracketCutout(
                      this.flyoverBracketNumber
                    );

                    // Load braket mai chiu luc
                    this.patiosManager.loadSideGableBracket(
                      this.flyoverBracketNumber
                    );
                  } else {
                    this.patiosManager.loadBracket(this.flyoverBracketNumber);
                    this.patiosManager.loadBracketCutout(
                      this.flyoverBracketNumberCutOut
                    );
                  }
                }

                this.isVisibleOpenModal = false;
                this.isSaveAs = type;
                this.statusText =
                  type === 1 ? STATUS_TEXT.COPY : STATUS_TEXT.REVISE;
                this.message.info("Operation completed successfully!");
                // set oldValue
                this.oldBuildingInfo = _.clone(
                  this.currentBuildingInfo.buildingInfo
                );
                //
              });
          } else {
            this.message.error(data.data.error);
          }
          this.isOpening = false;
        },
        (error) => {
          this.isOpening = false;
          console.log("error :", error);
        }
      );
  }
  onOpenFiles(type: number): void {
    const _diff = this.compareObject();
    if (_.isEmpty(_diff) || this.currentBuildingInfo.isOpen) {
      if (!this.currentBuildingInfo.files) {
        this.message.warning("File does not exist.");
        return;
      }
      let _url = "";
      if (type === 1) {
        _url = `${environment.uploadUrl}/${this.currentBuildingInfo.files.engineeringFile}`;
      } else {
        _url = `${environment.uploadUrl}/${this.currentBuildingInfo.files.quotationFile}`;
      }
      const _link = document.createElement("a");
      _link.href = _url;
      _link.target = "_blank";

      document.body.appendChild(_link);
      _link.click();
      _link.remove();
    } else {
      this.message.warning("You have to save before to print");
    }
  }
  onUpdateJobFiles(info): void {
    this.jobService
      .updateJobFiles(info.id, info)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.statusCode === 200) {
            this.currentBuildingInfo = {
              ...this.currentBuildingInfo,
              files: info.files,
            };
            this.currentStep = 3;
            this.isVisible = false;
            this.isUploading = false;
            this.message.success(data.message);
          } else {
            this.message.error(data.message);
          }
        },
        (error) => {
          this.message.error(error);
          this.isUploading = false;
        }
      );
  }
  onUpload(): void {
    const _depositPer = this.currentQuoteInfo.quoteInfo.depositPer || 0;
    const _stage1Per = this.currentQuoteInfo.quoteInfo.stage1Per || 0;
    const _stage2Per = this.currentQuoteInfo.quoteInfo.stage2Per || 0;
    if (_depositPer + _stage1Per + _stage2Per > 100) {
      this.message.error("% Deposit + % Stage 1 + % Stage 2 > <b>100%</b>");
      return;
    }
    this.isUploading = true;
    this.currentQuoteInfo.clientInfo.jobNo =
      this.currentQuoteInfo.clientInfo.jobNo.toUpperCase();
    const info = {
      ...this.currentBuildingInfo,
      isSaveAs: this.isSaveAs,
      clientInfo: {
        ...this.currentQuoteInfo.clientInfo,
      },
      quoteInfo: {
        ...this.currentQuoteInfo.quoteInfo,
      },
    };
    this.currentStep = 3;
    if (this.isSaveAs > 0) {
      this.currentBuildingInfo._id = null;
    }
    if (this.currentBuildingInfo._id === null) {
      // New
      this.jobService
        .createJob(info)
        .pipe(first())
        .subscribe(
          (data) => {
            if (data.statusCode === 200) {
              this.currentBuildingInfo = { ...data.data.item };
              this.currentQuoteInfo = {
                quoteInfo: { ...this.currentBuildingInfo.quoteInfo },
                clientInfo: { ...this.currentBuildingInfo.clientInfo },
              };
              let _maxSpan = _.toNumber(
                this.maxThickness.replace("max (capacity)", "")
              );
              if (this.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF) {
                _maxSpan = this.maxSpan;
              }
              const _info = {
                ...data.data.jobInfo,
                maxSpan: _maxSpan,
                listBay: data.data.item.buildingInfo.listBay,
              };
              const _jobInfo = {
                ..._info,
                uploadFolder: environment.uploadFolder,
                pathLogo: `${environment.mediaFolder}/${data.data.jobInfo.pathLogo}`,
                pathPDF:
                  data.data.jobInfo.pathPDF !== ""
                    ? `${environment.mediaFolder}/${data.data.jobInfo.pathPDF}`
                    : "",
              };
              this.statusText = STATUS_TEXT.SAVE;
              this.isSaveAs = 0;
              //set oldValue
              this.oldBuildingInfo = _.clone(
                this.currentBuildingInfo.buildingInfo
              );
              //
              if (this.sltPanelDirectionShow.currentValue) {
                this.sltPanelDirectionShow.setValue(false);
                setTimeout(() => {
                  this.printingManager.print(_jobInfo);
                }, 1000);
              } else {
                this.printingManager.print(_jobInfo);
              }
            } else {
              this.message.error(data.message);
            }
          },
          (error) => {
            this.message.error(error);
            this.isUploading = false;
          }
        );
    } else {
      // Edit
      this.jobService
        .UpdateJob(this.currentBuildingInfo._id, info)
        .pipe(first())
        .subscribe(
          (data) => {
            if (data.statusCode === 200) {
              this.currentBuildingInfo = { ...data.data.item };
              this.currentQuoteInfo = {
                quoteInfo: { ...this.currentBuildingInfo.quoteInfo },
                clientInfo: { ...this.currentBuildingInfo.clientInfo },
              };
              const _maxSpan = _.toNumber(
                this.maxThickness.replace("max (capacity)", "")
              );
              const _info = {
                ...data.data.jobInfo,
                maxSpan: _maxSpan,
                listBay: data.data.item.buildingInfo.listBay,
              };
              const _jobInfo = {
                ..._info,
                uploadFolder: environment.uploadFolder,
                pathLogo: `${environment.mediaFolder}/${data.data.jobInfo.pathLogo}`,
                pathPDF:
                  data.data.jobInfo.pathPDF !== ""
                    ? `${environment.mediaFolder}/${data.data.jobInfo.pathPDF}`
                    : "",
              };
              this.isSaveAs = 0;
              //set oldValue
              this.oldBuildingInfo = _.clone(
                this.currentBuildingInfo.buildingInfo
              );
              //
              if (this.sltPanelDirectionShow.currentValue) {
                this.sltPanelDirectionShow.setValue(false);
                setTimeout(() => {
                  this.printingManager.print(_jobInfo);
                }, 1000);
              } else {
                this.printingManager.print(_jobInfo);
              }
            } else {
              this.message.error(data.message);
            }
          },
          (error) => {
            this.message.error(error);
            this.isUploading = false;
          }
        );
    }
  }
  print2D() {
    // this.sldBuildingHeight.setMax(4000);
    this.printingManager.printTest();

    // this.sltColumnType.setSelected("2");

    //this.sldNoOfBay.setValue(2);
    //console.log(this.dialogEditBay.totalBaySize, this.sldExistingLength.currentValue + this.sldExistingLength2.currentValue *2 + this.existingWallManager.geo_existingWallW1.width*2 + 200);
  }
  getPosts(_groups: any) {
    let _posts = [];
    _posts = _groups.filter(
      (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_POST
    );
    const _patios = _groups.filter(
      (x) => x.userData.category === GEOMETRY_CATEGORY.PATIOS
    );
    _patios.map((value) => {
      _posts.push(
        ...value.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_POST
        )
      );
      return value;
    });
    const listPosts = [];
    _posts.reduce(function (res, value) {
      if (!res[value.scale.y]) {
        res[value.scale.y] = {
          length: value.scale.y,
          qty: 0,
        };
        listPosts.push(res[value.scale.y]);
      }
      res[value.scale.y].qty += 1;
      return res;
    }, {});
    return listPosts;
  }

  public bindBuildingInfo(): any {
    const groups = this.scene.children.filter(
      (x) =>
        (x.userData.category === GEOMETRY_CATEGORY.PATIOS &&
          x.children.length > 0) ||
        x.userData.type === GEOMETRY_TYPE.SUPERIOR_POST ||
        x.userData.type === GEOMETRY_TYPE.FLY_OVER_BRACKET ||
        x.userData.type === GEOMETRY_TYPE.RECEIVER_CHANEL ||
        x.userData.type === GEOMETRY_TYPE.ROOF_PANEL ||
        x.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET ||
        x.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_EXT ||
        x.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT ||
        x.userData.type === GEOMETRY_TYPE.RIDGE_CAPPING ||
        x.userData.type === GEOMETRY_TYPE.SUPERIOR_CENTER_POST
    );
    const listPosts = this.getPosts(groups);
    let cutoutCondition =
      this.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
      this.sltExistingType.currentValue != BUILDING_SIDE.NONE &&
      this.sldExistingLength2.currentValue > 0;
    let rakeCutCondition =
      this.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE ||
      this.sltRightCutType.currentValue != RAKECUT_TYPE.NONE;
    let elementArray = {
      extFlyoverBrackets: [],
      flyoverBrackets: [],
      flyoverBracketCutOuts: [],
      fasciaBrackets: [],
      fasciaBracketCutouts: [],
    };
    if (this.patiosRoofType === PATIOS_ROOF_TYPE.FLAT_ROOF) {
      elementArray = this.utils.getInfoFromGeometry(
        this,
        groups,
        this.geometryManager,
        cutoutCondition,
        rakeCutCondition
      );
    } else {
      elementArray = this.utils.getInfoFromGeometryGableRoof(
        this,
        groups,
        this.geometryManager,
        cutoutCondition,
        rakeCutCondition
      );
    }

    const _bottomColourValue = this.sltColourRoof.items.find(
      (f) => f.id == this.sltColourRoof.currentValue
    )?.bottomValue;

    //Bottom Colour

    const _structureType = _.toNumber(this.sltStructureType.currentValue);
    const _existingType = _.toNumber(this.sltExistingType.currentValue);

    const _buildingInfo = {
      patiosRoofType: this.patiosRoofType,
      isFBRoof: this.isFBRoof,
      listPosts,
      existingType: {
        value: _existingType,
        text: this.sltExistingType.currentText,
      },
      existingLength: this.sldExistingLength.currentValue,
      existingLength2: this.sldExistingLength2.currentValue,
      existingWidth1: this.sldExistingWidth1.currentValue,
      existingWidth2: this.sldExistingWidth2.currentValue,
      existingRoofPitch: _.toNumber(this.sltExistingRoofPitch.currentValue),
      fasciaDepth: this.sldFasciaDepth.currentValue,
      eaveHeight: this.sldExistingWallHeight.currentValue,
      eaveWidth: this.sldEaveWidth.currentValue,
      wallType: {
        value: _.toNumber(this.sltWallType.currentValue),
        text: this.sltWallType.currentText,
      },
      existingRoofType: {
        value: _.toNumber(this.sltExistingRoofType.currentValue),
        text: this.sltExistingRoofType.currentText,
      },
      flyoverBracketHeight: this.sldFlyOverBracketHeight.currentValue,
      extFlyoverBracketNumber: elementArray.extFlyoverBrackets, // _extFlyoverBrackets,
      flyoverBracketNumber: elementArray.flyoverBrackets, // _flyoverBrackets,
      flyoverBracketNumberCutOut: elementArray.flyoverBracketCutOuts, // _flyoverBracketsCutOut,
      fasciaBracketHeight: _.toNumber(
        this.sltFasciaUpstandBrackets.currentValue
      ),
      fasciaBracketNumber: elementArray.fasciaBrackets,
      fasciaBracketNumberCutOut: elementArray.fasciaBracketCutouts,
      // -------------
      backFascia: this.sltUpFasciaUpstandBracket.currentValue,
      rightToLeft: this.sltPanelDirection.currentValue,
      structureType: {
        value: _structureType,
        text: this.sltStructureType.currentText,
      },
      windClass: {
        value: _.toNumber(this.sltWindClass.currentValue),
        text: this.sltWindClass.currentText,
      },
      span: this.sldSpan.currentValue,
      multiSpan: this.sldMultiSpan.currentValue,
      buildingHeight: this.sldBuildingHeight.currentValue,
      frontOverhang: this.sldFrontOverhang.currentValue,
      backOverhang: this.sldBackOverhang.currentValue,
      baySize: this.sldBaySize.currentValue,
      noOfBay: this.sldNoOfBay.currentValue,
      roofPitch: _.toNumber(this.sltRoofPitch.currentValue),
      leftOverhang: this.sldLeftOverhang.currentValue,
      rightOverhang: this.sldRightOverhang.currentValue,
      roofOverallLength: this.sldRoofOverallLength.currentValue,
      roofOverallWidth: this.sldRoofOverallWidth.currentValue,
      minHeight: this.sldMinHeight.currentValue,
      isRapidSetCement: this.chkRapidSetCement.currentValue,
      hasHouseBeam: this.chkHasHouseBeam.currentValue,
      isDripBarge: this.sltDripBarge.currentValue,
      cutOut: {
        value: _.toNumber(this.sltCutOut.currentValue),
        text: this.sltCutOut.currentText,
      },
      //--------RakeCut
      leftCutType: {
        value: _.toNumber(this.sltLeftCutType.currentValue),
        text: this.sltLeftCutType.currentText,
      },
      rightCutType: {
        value: _.toNumber(this.sltRightCutType.currentValue),
        text: this.sltRightCutType.currentText,
      },
      leftCutVertical: this.sldLeftCutVertical.currentValue,
      leftCutHorizontal: this.sldLeftCutHorizontal.currentValue,
      rightCutVertical: this.sldRightCutVertical.currentValue,
      rightCutHorizontal: this.sldRightCutHorizontal.currentValue,
      //--------
      listBay: this.dialogEditBay.listBay,
      // -----------------------------
      roofSheetingType: {
        value: _.toNumber(this.sltRoofSheetingType.currentValue),
        text: this.sltRoofSheetingType.currentText,
      },
      thickness: {
        value: _.toNumber(this.sltRoofThickness.currentValue),
        text: this.sltRoofThickness.currentText,
      },
      flatBottom: {
        value: _.toNumber(this.sltFlatBottom.currentValue),
        text: this.sltFlatBottom.currentText,
      },
      bargeType: {
        value: _.toNumber(this.sltBargeType.currentValue),
        text: this.sltBargeType.currentText,
      },
      receiverType: {
        value: _.toNumber(this.sltReceiverType.currentValue),
        text: this.sltReceiverType.currentText,
      },
      zFlashingType: {
        value: _.toNumber(this.sltZFlashingType.currentValue),
        text: this.sltZFlashingType.currentText,
      },
      downpipeType: {
        value: _.toNumber(this.sltDownpipeType.currentValue),
        text: this.sltDownpipeType.currentText,
      },
      gutterType: {
        value: _.toNumber(this.sltGutterType.currentValue),
        text: this.sltGutterType.currentText,
      },
      beamType: {
        value: _.toNumber(this.sltBeamType.currentValue),
        text: this.sltBeamType.currentText,
      },
      columnType: {
        value: _.toNumber(this.sltColumnType.currentValue),
        text: this.sltColumnType.currentText,
      },
      baseFixingType: {
        value: _.toNumber(this.sltBaseFixingType.currentValue),
        text: this.sltBaseFixingType.currentText,
      },
      beamSize: {
        value: _.toNumber(this.sltBeamSize.currentValue),
        text: this.sltBeamSize.currentText,
      },
      houseBeamSize: {
        value: _.toNumber(this.sltHouseBeamSize.currentValue),
        text: this.sltHouseBeamSize.currentText,
      },
      // ------------------------
      colourBottomRoof: {
        value: _.toNumber(this.sltColourRoof.currentValue),
        text: _bottomColourValue,
      },
      colourRoof: {
        value: _.toNumber(this.sltColourRoof.currentValue),
        text: this.sltColourRoof.currentText,
      },
      colourBarge: {
        value: _.toNumber(this.sltColourBarge.currentValue),
        text: this.sltColourBarge.currentText,
      },
      colourGutter: {
        value: _.toNumber(this.sltColourGutter.currentValue),
        text: this.sltColourGutter.currentText,
      },
      colourZFlashing: {
        value: _.toNumber(this.sltColourZFlashing.currentValue),
        text: this.sltColourZFlashing.currentText,
      },
      colourPost: {
        value: _.toNumber(this.sltColourPost.currentValue),
        text: this.sltColourPost.currentText,
      },
      colourBeam: {
        value: _.toNumber(this.sltColourBeam.currentValue),
        text: this.sltColourBeam.currentText,
      },
      colourBracket: {
        value: _.toNumber(this.sltColourBracket.currentValue),
        text: this.sltColourBracket.currentText,
      },
      colourFasciaBracket: {
        value: _.toNumber(this.sltColourFasciaBracket.currentValue),
        text: this.sltColourFasciaBracket.currentText,
      },
      colourDownpipe: {
        value: _.toNumber(this.sltColourDownpipe.currentValue),
        text: this.sltColourDownpipe.currentText,
      },
      userData: elementArray,
      roofHeight: this.roofHeight,
      panelType: this.panelType,
    };
    return _buildingInfo;
  }
  onCancel(): void {
    this.isVisible = false;
    this.isVisibleOpenModal = false;
    this.isSaveAs = 0;
  }

  public onShowAddCostClick(): void {
    if (!this.currentBuildingInfo.isOpen) {
      const _costList = this.currentQuoteInfo.quoteInfo.costList
        ? [...this.currentQuoteInfo.quoteInfo.costList]
        : [];
      if (_costList.length === 0) {
        _costList.push({ index: 1, description: "", value: 0 });
      }
      this.dialogAddCost.show(true, _costList);
    }
  }

  compareObject(): any {
    const _temp = this.bindBuildingInfo();
    delete _temp.userData;
    delete _temp.roofHeight;
    const _old = _.clone(this.oldBuildingInfo);
    const diff = _.omitBy(_temp, function (v, k) {
      const _value = _.get(_old, k);
      const _equal = _.isEqual(v, _value);
      return _equal;
    });
    return diff;
  }
  onViewBOM(): void {
    const _diff = this.compareObject();
    if (
      (this.currentBuildingInfo._id !== null && _.isEmpty(_diff)) ||
      this.currentBuildingInfo.isOpen
    ) {
      this.dialogBOM.show(
        true,
        this.currentBuildingInfo._id,
        this.currentBuildingInfo.clientInfo.jobNo,
        this.isAdmin,
        this.currentUser
      );
    } else {
      this.message.warning("You have to save before to print BOM");
    }
  }

  onViewBOMTest(): void {
    this.dialogBOM.showTest(
      true,
      this.currentUser,
      this.currentBuildingInfo.BOMInfo,
      this.currentBuildingInfo.additionalBOM,
      this.currentBuildingInfo.quoteInfo
    );
  }

  public onAddBOM(price) {
    this.currentBuildingInfo.additionalBOM.push(price);
    this.onResetIdx();
    this.onCalcPrice();
  }
  public onDeleteBOM(data) {
    this.currentBuildingInfo.additionalBOM =
      this.currentBuildingInfo.additionalBOM.filter((f) => f.idx !== data.idx);
    this.onResetIdx();
    this.onCalcPrice();
  }

  onResetIdx() {
    const maxIdx = _.maxBy(this.currentBuildingInfo.BOMInfo, "idx");
    const _maxIdx = maxIdx.idx + 1;
    this.currentBuildingInfo.additionalBOM =
      this.currentBuildingInfo.additionalBOM.map((m, index) => {
        return { ...m, idx: _maxIdx + index };
      });
  }

  onSearch(): void {
    this.isSearching = true;
    const info = {
      userId: this.currentUser.user._id,
      jobNo: this.jobNoSearch.toUpperCase(),
      cusName: this.cusNameSearch,
    };
    this.jobService
      .getJobByUserId(info)
      .pipe(first())
      .subscribe(
        (data) => {
          this.dataOPEN = data.data.list;
          this.isSearching = false;
        },
        (error) => {
          console.log("error :", error);
          this.isSearching = false;
        }
      );
  }
  onOpenById(id: any): void {
    this.isSearching = true;
    this.jobService
      .getById(id)
      .pipe(first())
      .subscribe(
        (data) => {
          this.currentBuildingInfo = { ...data.data.list, isOpen: true };
          if (!this.currentBuildingInfo.quoteInfo.costList) {
            this.currentBuildingInfo.quoteInfo.costList = [];
          }
          if (!this.currentBuildingInfo.additionalBOM) {
            this.currentBuildingInfo.additionalBOM = [];
          }
          if (
            this.currentBuildingInfo.quoteInfo.costList.length === 0 &&
            this.currentBuildingInfo.quoteInfo.addCostInstall > 0
          ) {
            this.currentBuildingInfo.quoteInfo.costList.push({
              index: 1,
              description: "",
              value: this.currentBuildingInfo.quoteInfo.addCostInstall,
            });
          }
          this.currentQuoteInfo = {
            clientInfo: { ...data.data.list.clientInfo },
            quoteInfo: { ...data.data.list.quoteInfo },
          };
          this.currentQuoteInfo.quoteInfo.costList = [
            ...this.currentBuildingInfo.quoteInfo.costList,
          ];
          const _structureType =
            data.data.list.buildingInfo.structureType.value;
          this.flyoverBracketNumber =
            data.data.list.buildingInfo.flyoverBracketNumber;
          this.flyoverBracketNumberCutOut =
            data.data.list.buildingInfo.flyoverBracketNumberCutOut;
          this.fasciaBracketNumber =
            data.data.list.buildingInfo.fasciaBracketNumber || 0;
          this.fasciaBracketNumberCutOut =
            data.data.list.buildingInfo.fasciaBracketNumberCutOut || 0;
          this.uiManager
            .loadFromObject(data.data.list.buildingInfo)
            .then(() => {
              if (_structureType === 1 || _structureType === 2) {
                if (
                  HomeComponent.ins.patiosRoofType ===
                    PATIOS_ROOF_TYPE.GABLE_ROOF &&
                  HomeComponent.ins.isFBRoof
                ) {
                  // Phan nay ve braket cua mai gable phia sau
                  // this.patiosManager.loadBracket(this.flyoverBracketNumberCutOut);
                  this.patiosManager.loadBracket(UI.span >= 4000 ? 3 : 2);

                  // Phan nay ve braket cua mai cutout
                  this.patiosManager.loadBracketCutout(
                    this.flyoverBracketNumber
                  );

                  // Load braket mai chiu luc
                  this.patiosManager.loadSideGableBracket(
                    this.flyoverBracketNumber
                  );
                } else {
                  this.patiosManager.loadBracket(this.flyoverBracketNumber);
                  this.patiosManager.loadBracketCutout(
                    this.flyoverBracketNumberCutOut
                  );
                }
              }
              if (_structureType == 3) {
                if (
                  HomeComponent.ins.patiosRoofType ===
                    PATIOS_ROOF_TYPE.GABLE_ROOF &&
                  HomeComponent.ins.isFBRoof
                ) {
                  // Phan nay ve braket cua mai gable phia sau
                  // this.patiosManager.loadBracket(this.fasciaBracketNumberCutOut);
                  this.patiosManager.loadBracket(UI.span >= 4000 ? 3 : 2);

                  // Phan nay ve braket cua mai cutout
                  this.patiosManager.loadBracketCutout(
                    this.fasciaBracketNumber
                  );

                  // Load braket mai chiu luc
                  this.patiosManager.loadSideGableBracket(
                    this.fasciaBracketNumber
                  );
                } else {
                  this.patiosManager.loadBracket(this.fasciaBracketNumber);
                  this.patiosManager.loadBracketCutout(
                    this.fasciaBracketNumberCutOut
                  );
                }
              }
              this.isVisibleOpenModal = false;
              this.statusText = STATUS_TEXT.COPY;
              this.isSearching = false;
              // set oldValue
              this.oldBuildingInfo = _.clone(
                this.currentBuildingInfo.buildingInfo
              );
              //
            });
        },
        (error) => {
          console.log("error :", error);
          this.isSearching = false;
        }
      );
  }

  onOpenPropertyClick(): void {
    this.isVisibleProperty = !this.isVisibleProperty;
    setTimeout(() => {
      this.resizeEventHandle();
    }, 300);
  }
  onRoofSheetingTypeChanged(): void {
    this.sltBargeType.setSelected(this.sltRoofSheetingType.currentValue);
    this.sltReceiverType.setSelected(this.sltRoofSheetingType.currentValue);
    this.sltZFlashingType.setSelected(this.sltRoofSheetingType.currentValue);
  }

  private initObject() {
    this.isAdmin =
      this.currentUser.user.roles === this.objects.roles.admin ? true : false;
    if (this.patiosRoofType === PATIOS_ROOF_TYPE.FLAT_ROOF) {
      this.maxSpan = this.env.span.max;
    }
    this.maxBaySize = this.env.baySize.max;
    this.maxBaySizeText = `${this.env.baySize.max} max`;
    this.beamSizeList = this.objects.beamSizeList.filter(
      (f) => f.beamType == 0
    );
    this.houseBeamSizeList = this.objects.beamSizeList.filter(
      (f) => f.beamType == 0
    );
    this.thicknessList = [...this.objects.thicknessList];
    this.baseFixingTypeList = [...this.objects.baseFixingTypeList];
    const _template = TEMPLATE.find(
      (f) =>
        f.type === (this.existingHouseType === -1 ? 1 : this.existingHouseType)
    );
    // Old Job
    this.currentBuildingInfo = {
      _id: null,
      userId: this.currentUser.user._id,
      isOpen: false,
      buildingInfo: {
        ..._template,
        patiosRoofType: this.patiosRoofType,
        isFBRoof: this.isFBRoof,
        existingRoofPitch: this.env.existingRoofPitch.default,
        fasciaDepth: this.env.fasciaDepth.default,
        eaveHeight: this.env.eaveHeight.default,
        eaveWidth: this.env.eaveWidth.default,
        flyoverBracketHeight: this.env.flyOverBracketHeight.default,
        fasciaBracketHeight: this.env.fasciaUpstandBrackets.default,
        backFascia: false,
        hasHouseBeam: false,
        multiSpan: this.env.multiSpan.default,
        buildingHeight: this.env.height.default,
        frontOverhang: this.env.frontOverhang.default,
        backOverhang: this.env.backOverhang.default,
        roofOverallLength: this.env.baySize.default,
        roofPitch: 2,
        leftOverhang: this.env.leftOverhang.default,
        rightOverhang: this.env.rightOverhang.default,
        //RakeCut
        leftCutType: {
          value: 0,
        },
        rightCutType: {
          value: 0,
        },
        leftCutVertical: this.env.rakeCutVertical.default,
        leftCutHorizontal: this.env.rakeCutHorizontal.default,
        rightCutVertical: this.env.rakeCutVertical.default,
        rightCutHorizontal: this.env.rakeCutHorizontal.default,
        //
        rightToLeft: false,
        roofOverallWidth:
          this.env.span.default +
          this.env.frontOverhang.default +
          this.env.backOverhang.default,
        minHeight: 0,
        colourDownpipe: {
          value: 14214630,
        },
        colourBracket: {
          value: 15000277,
        },
        colourFasciaBracket: {
          value: 15000277,
        },
        colourBeam: {
          value: 15000277,
        },
        colourPost: {
          value: 15000277,
        },
        colourZFlashing: {
          value: 15000277,
        },
        colourGutter: {
          value: 15000277,
        },
        colourBarge: {
          value: 15000277,
        },
        colourRoof: {
          value: 15000277,
        },
        beamSize: {
          value: 1,
        },
        houseBeamSize: {
          value: 1,
        },
        baseFixingType: {
          value: 0,
        },
        columnType: {
          value: 0,
        },
        beamType: {
          value: 0,
        },
        gutterType: {
          value: 0,
        },
        downpipeType: {
          value: 0,
        },
        zFlashingType: {
          value: 0,
        },
        receiverType: {
          value: 0,
        },
        bargeType: {
          value: 0,
        },
        flatBottom: {
          value: 15000277,
        },
        thickness: {
          value: 1,
        },
        roofSheetingType: {
          value: 0,
        },
        windClass: {
          value: 0,
        },
        structureType: {
          value: 3,
        },
        wallType: {
          value: 0,
        },
        roofType: {
          value: 0,
        },
        panelType: this.panelType,
      },
      clientInfo: {
        jobNo: "",
        cusName: "",
        address: "",
        suburb: "",
        state: "",
        postcode: "",
        email: "",
        phone: "",
        supplyType: "supply_install",
        date: new Date(),
      },
      quoteInfo: {
        nett: 0,
        total0: 0,
        totalPrice: 0,
        discountPer: 0,
        discount: 0,
        depositPer: 0,
        stage1Per: 0,
        stage2Per: 0,
        deposit: 0,
        stage1: 0,
        stage2: 0,
        finalPayment: 0,
        addCostInstall: 0,
        userNote: "",
        note: "",
        gst: 0,
        fasciaInstall: 0,
        flyOverInstall: 0,
        installMaterial: 0,
        supplyOnlyKits: 0,
        perPostInstall: 0,
        addCost: 0,
        costList: [],
        customText: null,
      },
      additionalBOM: [],
    };
    // Current Job
    this.currentQuoteInfo = { ...this.currentBuildingInfo };
    // this.dataBOM = { quoteInfo: { ...this.currentBuildingInfo.quoteInfo } };
  }
  onApplyToAll(): void {
    this.sltColourBarge.setSelected(this.sltColourRoof.currentValue);
    this.sltColourZFlashing.setSelected(this.sltColourRoof.currentValue);
    this.sltColourGutter.setSelected(this.sltColourRoof.currentValue);
  }

  public setPatiosWidth(totalWidth: number) {
    let realWidth = totalWidth;
    if (
      UI.structureType == CONNECTION_TYPE.EXISTING &&
      UI.existingType == BUILDING_SIDE.BOTH &&
      this.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF &&
      this.isFBRoof
    ) {
      realWidth =
        totalWidth -
        UI.overhangLeft -
        UI.overhangRight -
        OFFSET_GUTTER_TO_WALL * 2;
    }
    if (
      UI.structureType == CONNECTION_TYPE.FASCIA_UPSTAND &&
      UI.existingType == BUILDING_SIDE.BOTH &&
      this.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF &&
      this.isFBRoof
    ) {
      realWidth = Math.ceil(totalWidth - 2 * FASCIA_BRACKET_FIT);
    }
    let baySize = realWidth;
    let noOfBay = 1;
    if (realWidth > CONFIG.baySize.max) {
      noOfBay = Math.ceil(realWidth / CONFIG.baySize.max);
      baySize = Math.ceil(realWidth / noOfBay);
    }
    if (
      this.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF &&
      this.isFBRoof &&
      UI.existingType == BUILDING_SIDE.BOTH
    ) {
      this.sldSpan.setValueAndHandleEvent(realWidth, true);
    }
    if (
      this.patiosRoofType === PATIOS_ROOF_TYPE.FLAT_ROOF ||
      (this.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF && !this.isFBRoof)
    ) {
      this.sldBaySize.setValue(baySize);
      this.sldNoOfBay.setValue(noOfBay);
    }
  }
  public onBtnRakeCutLeftClick(e) {
    HomeComponent.ins.sltLeftCutType.setSelected(0);
  }
  public onBtnRakeCutRightClick(e) {
    HomeComponent.ins.sltRightCutType.setSelected(0);
  }
  public onBtnCutoutClick(e) {
    HomeComponent.ins.sltCutOut.setSelected(0);
  }
  public onsltUpFasciaUpstandBracketChange(e) {
    if (UI.upstandBraketType == 0 && UI.isUpFasciaUpstandardBracket) {
      HomeComponent.ins.sltFasciaUpstandBrackets.setSelected(150);
      HomeComponent.ins.patiosManager.load();
    }
  }

  onChangeToInsulatedPanel(): void {
    this.router.navigateByUrl("/");
  }

  onShowThumbnailClick(data): void {
    this._albums.length = 0;
    var _url = `${environment.uploadUrl}/${data.files.thumbnailFile}`;
    const album: Album = {
      src: _url,
      caption: `${data?.clientInfo?.cusName} [#${data?.clientInfo?.jobNo}]`,
      thumb: _url,
    };

    this._albums.push(album);
    this._lightbox.open(this._albums, 0);
  }
}
