import {
  BUILDING_SIDE,
  CUTOUT_ENABLE,
  EXISTING_BUILDING_CONFIG,
  PANEL_DIRECTION,
  RAKECUT_TYPE,
  RIBSPAN_PANEL_WIDTH,
  RIBSPAN_BARGE_FIT,
  FIT_FLYOVER_BRAKET_ON_ROOF
} from "src/app/app.constants";
import HighLightBox from "src/app-ribspan/models/HighlightBox";
import {
  BoxBufferGeometry,
  BoxHelper,
  BufferGeometry,
  Color,
  Geometry,
  Group,
  LineSegments,
  Material,
  Matrix4,
  Mesh,
  MeshLambertMaterial,
  Object3D,
  Plane,
  Vector3,
} from "three";
import { CSG } from "three-csg-ts";
import { PatiosBackFlyOverManager } from ".";
import { GEOMETRY_CATEGORY, GEOMETRY_TYPE } from "src/app/app.config";
import { HomeComponent as AppComponent } from "src/app-ribspan/containers/home/home.component";
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import {
  GeometryInfo,
  LineType,
  Print2DView,
  Printing2DGeometry,
  Printing2DLine,
  ViewType,
} from "src/app/core/models";
import { UI } from "../ui";
import { Util } from "../utils";

export class RoofManager {
  private scene: Group;
  private APP: AppComponent;
  private MANAGER: PatiosBackFlyOverManager;
  private material: Material;
  private utils: Util;

  private geometryManager: GeometryManager;

  private geo_RoofPanel: GeometryInfo;
  private geo_roofPanelBargeCappingLeft: GeometryInfo;
  private geo_roofPanelBargeCappingRight: GeometryInfo;
  private geo_roofPanelBargeCappingFront: GeometryInfo;
  private geo_bargeCutoutLeftHor: GeometryInfo;
  private geo_bargeCutoutRightHor: GeometryInfo;
  private geo_bargeRakecutLeft: GeometryInfo;
  private geo_bargeRakecutRight: GeometryInfo;
  //private geo_roofPanelBargeCappingBack: BufferGeometry;
  private geo_gutterFront: GeometryInfo;
  private geo_gutterCap: GeometryInfo;

  private roofGroup: Group;

  private eventHandleId: any;
  private objectSizeChangedHandle: any;

  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;

  private totalBaySize: number;
  private extraOffsetZ: number;

  private mesh_CutoutLeft: Mesh;
  private csg_cutoutLeft: CSG;
  private mesh_CutoutRight: Mesh;
  private csg_cutoutRight: CSG;
  private geo_cutoutLeft: BoxBufferGeometry;
  private geo_cutoutRight: BoxBufferGeometry;
  private geo_rakecutLeft: BoxBufferGeometry;
  private geo_rakecutRight: BoxBufferGeometry;
  private mesh_rakecutLeft: Mesh;
  private mesh_rakecutRight: Mesh;
  private csg_rakecutLeft: CSG;
  private csg_rakecutRight: CSG;
  private geo_downPipe: GeometryInfo;

  private roofWidth: number;
  private roofLength: number;

  private deferHandle;
  private deferTimeout = EXISTING_BUILDING_CONFIG.CUTOUT_DEFFER_TIME_OUT;
  private cutoutExistingBothLength: number;

  constructor(app: AppComponent, backFlyOverManager: PatiosBackFlyOverManager) {
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.APP = app;
    this.MANAGER = backFlyOverManager;
    this.scene = new Group();
    backFlyOverManager.patiosGroup.add(this.scene);
    this.material = MaterialManager.Instance().ROOF;

    this.registerEvent();
  }

  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.geo_RoofPanel = this.geometryManager.getRoofRibspanPanel();
      this.geo_RoofPanel.geometry.translate(-this.geo_RoofPanel.width / 2, this.geo_RoofPanel.height / 2, -this.geo_RoofPanel.length / 2);
      this.geo_RoofPanel.geometry.rotateY(Math.PI)
      this.utils.changeGeoColor(this.geo_RoofPanel.geometry, new Vector3(0, 1, 0), UI.roofColor.toString());

      //Barge capping
      this.geo_roofPanelBargeCappingLeft = this.geometryManager.getRibspanBarge();
      this.geo_roofPanelBargeCappingLeft.geometry
        .rotateY(Math.PI)
        .translate(
          this.geo_roofPanelBargeCappingLeft.width / 2 - 3,
          this.geo_roofPanelBargeCappingLeft.height / 2 - RIBSPAN_BARGE_FIT,
          this.geo_roofPanelBargeCappingLeft.length / 2
        );

      this.geo_roofPanelBargeCappingRight = this.geometryManager.getRibspanBarge();
      this.geo_roofPanelBargeCappingRight.geometry.translate(
        -this.geo_roofPanelBargeCappingLeft.width / 2 + 3,
        this.geo_roofPanelBargeCappingLeft.height / 2 - RIBSPAN_BARGE_FIT,
        this.geo_roofPanelBargeCappingLeft.length / 2
      );

      this.geo_roofPanelBargeCappingFront = this.geometryManager.getRibspanBarge();
      this.geo_roofPanelBargeCappingFront.geometry
        .rotateY(-Math.PI / 2)
        .translate(
          this.geo_roofPanelBargeCappingLeft.length / 2,
          this.geo_roofPanelBargeCappingLeft.height / 2 - RIBSPAN_BARGE_FIT,
          -this.geo_roofPanelBargeCappingLeft.width / 2
        );

      this.geo_bargeRakecutLeft = this.geometryManager.getRibspanBarge();
      this.geo_bargeRakecutLeft.geometry
        .rotateY(Math.PI)
        .translate(
          this.geo_bargeRakecutLeft.width / 2,
          this.geo_bargeRakecutLeft.height / 2 - RIBSPAN_BARGE_FIT,
          -this.geo_bargeRakecutLeft.length / 2
        );

      this.geo_bargeRakecutRight = this.geometryManager.getRibspanBarge();
      this.geo_bargeRakecutRight.geometry.translate(
        -this.geo_bargeRakecutRight.width / 2,
        this.geo_bargeRakecutRight.height / 2 - RIBSPAN_BARGE_FIT,
        -this.geo_bargeRakecutRight.length / 2
      );

      //Gutter
      this.geo_gutterFront = this.geometryManager.getGutter();
      this.geo_gutterFront.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_gutterFront.length / 2,
          -20,
          this.geo_gutterFront.width / 2
        );

      //Gutter cap
      this.geo_gutterCap = this.geometryManager.getGuterEndCap();
      this.geo_gutterCap.geometry
        .rotateY(Math.PI / 2)
        .translate(0, -20, this.geo_gutterCap.length / 2);

      this.geo_cutoutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutLeft.translate(-500, 500, -500);
      this.geo_cutoutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutRight.translate(500, 500, -500);

      this.geo_rakecutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutLeft.translate(-500, 500, 500);
      this.geo_rakecutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutRight.translate(500, 500, 500);

      //Barge cutout left
      this.geo_bargeCutoutLeftHor = this.geometryManager.getRibspanBarge();
      this.geo_bargeCutoutLeftHor.geometry
        .rotateY(Math.PI / 2)
        .translate(
          -this.geo_bargeCutoutLeftHor.length / 2,
          this.geo_bargeCutoutLeftHor.height / 2 - RIBSPAN_BARGE_FIT,
          this.geo_bargeCutoutLeftHor.width / 2
        );

      //Barge cutout right
      this.geo_bargeCutoutRightHor = this.geometryManager.getRibspanBarge();
      this.geo_bargeCutoutRightHor.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_bargeCutoutRightHor.length / 2,
          this.geo_bargeCutoutRightHor.height / 2 - RIBSPAN_BARGE_FIT,
          this.geo_bargeCutoutRightHor.width / 2
        );

      this.geo_downPipe = this.geometryManager.getDownPipe();

      resolve();
    });
  }
  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PATIOS
        )
      );
      this.APP.scene.remove(
        ...this.APP.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SHEET_OUTLINE
        )
      );

      this.APP.scene.remove(
        ...this.APP.scene.children.filter((x) => x.userData.type == "RAKECUT")
      );
      this.APP.scene.remove(
        ...this.APP.scene.children.filter((x) => x.userData.type == "HELPER")
      );

      this.extraOffsetZ = this.APP.existingWallManager.geo_existingWallL1.width + this.APP.eaveManager.backOverhang;

      this.totalBaySize = this.APP.dialogEditBay.totalBaySize;

      this.roofLength =
        UI.span +
        UI.multiSpan +
        UI.overhangFront;
      this.cutoutExistingBothLength =
        (this.totalBaySize -
          (this.APP.sldExistingLength.currentValue +
            this.APP.existingWallManager.geo_existingWallW1.width * 2)) /
        2;

      this.addRoof();
      this.addCutout();
      this.addAngelRakeCut();
      this.addStepRakecut();
      if (UI.rakeCutLeftType == 0) {
        new HighLightBox(this.roofGroup, {
          min: {
            x: -(UI.totalBayLength / 2 + UI.overhangLeft),
            y: 0,
            z: UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront,
          },
          max: {
            x: -(UI.totalBayLength / 2 + UI.overhangLeft) + 300,
            y: 0,
            z:
              UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront - 300,
          },
          userData: { side: BUILDING_SIDE.LEFT },
        });
      }
      if (UI.rakeCutRightType == 0) {
        new HighLightBox(this.roofGroup, {
          min: {
            x: UI.totalBayLength / 2 + UI.overhangRight - 300,
            y: 0,
            z: UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront,
          },
          max: {
            x: UI.totalBayLength / 2 + UI.overhangRight,
            y: 0,
            z:
              UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront - 300,
          },
          userData: { side: BUILDING_SIDE.RIGHT },
        });
      }

      let offsetLeft =
        this.totalBaySize / 2 +
        UI.overhangLeft -
        this.MANAGER.patiosGroup.position.x;
      let offsetRight =
        this.totalBaySize / 2 +
        UI.overhangRight +
        this.MANAGER.patiosGroup.position.x;

      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
        let offsetX =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndPurlinManager.geo_bracket?.width || 0);
        offsetRight -= offsetX;
        offsetLeft += offsetX;
      }
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
        let offsetX =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndPurlinManager.geo_bracket?.width || 0);
        offsetLeft += offsetX;
        offsetRight += offsetX;
      }
      let clipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
      let clipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);

      this.material.clippingPlanes = [clipingPlaneLeft, clipingPlaneRight];
      MaterialManager.Instance().ROOF_BASE.clippingPlanes = [
        clipingPlaneLeft,
        clipingPlaneRight,
      ];
      let width =
        this.totalBaySize +
        UI.overhangLeft +
        UI.overhangRight;
      this.roofWidth = width;
      this.roofLength =
        UI.span +
        UI.multiSpan +
        UI.overhangBack +
        UI.overhangFront;
      let roofSheetingCount = Math.ceil(width / RIBSPAN_PANEL_WIDTH);
      let offsetX = -(
        this.totalBaySize / 2 +
        UI.overhangLeft
      );

      //panel direction
      let panelDirectionOfset = 1;
      if (
        this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.RIGHT_TO_LEFT
      ) {
        offsetX =
          this.totalBaySize / 2 +
          UI.overhangRight -
          RIBSPAN_PANEL_WIDTH;
        panelDirectionOfset = -1;
      } else if (
        this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.LEFT_TO_RIGHT
      ) {
        offsetX = -(
          this.totalBaySize / 2 +
          UI.overhangLeft
        );
      }

      if (
        UI.cutOutType == 1 &&
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT &&
        this.MANAGER.cutoutCondition
      ) {
        let totalPanelLength = 0;
        for (let index = 0; index < roofSheetingCount; index++) {
          totalPanelLength += RIBSPAN_PANEL_WIDTH;
          this.addRoofSheeting(offsetX, [this.csg_cutoutLeft]);
          offsetX += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      } else if (
        UI.cutOutType == 1 &&
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT &&
        this.MANAGER.cutoutCondition
      ) {
        let totalPanelLength = 0;
        for (let index = 0; index < roofSheetingCount; index++) {
          totalPanelLength += RIBSPAN_PANEL_WIDTH;
          this.addRoofSheeting(offsetX, [this.csg_cutoutRight]);
          offsetX += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      } else if (
        UI.cutOutType == CUTOUT_ENABLE.YES &&
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH &&
        this.MANAGER.cutoutCondition
      ) {
        for (let index = 0; index < roofSheetingCount; index++) {
          this.addRoofSheeting(offsetX, [
            this.csg_cutoutLeft,
            this.csg_cutoutRight,
          ]);
          offsetX += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      } else {
        for (let index = 0; index < roofSheetingCount; index++) {
          this.addRoofSheeting(offsetX, []);
          offsetX += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      }

      this.addBargeCapping();
      this.addBargeAngelRakecut();
      this.addBargeStepRakecut();
      this.addGutter();

      this.showPanelOutline();

      this.scene.visible = UI.showRoof

      resolve();
    });
  }
  private getHeightByRoofPitch(userDataPos: any): number {
    if (userDataPos.isSpan) {
      return (
        this.utils.tan(this.APP.sltRoofPitch.currentValue) *
        UI.span
      );
    } else if (userDataPos.isMultiSpan) {
      return (
        this.utils.tan(this.APP.sltRoofPitch.currentValue) *
        (UI.span + UI.multiSpan)
      );
    } else {
      return (
        this.utils.tan(this.APP.sltRoofPitch.currentValue) * this.roofLength
      );
    }
  }
  public getOutLines(): Printing2DGeometry {
    let objs = this.roofGroup.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    );

    let gutterGroupObjs = this.roofGroup.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS
    );
    let gutterObjs: Object3D[] = [];
    for (let group of gutterGroupObjs) {
      gutterObjs.push(...group.children);
    }
    objs = [...objs, ...gutterObjs];

    let lsGeometries: Printing2DLine[] = [];

    let offsetLeft =
      this.totalBaySize / 2 +
      UI.overhangLeft -
      this.MANAGER.patiosGroup.position.x;
    let offsetRight =
      this.totalBaySize / 2 +
      UI.overhangRight +
      this.MANAGER.patiosGroup.position.x;
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);

    for (let o of objs) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        60
      );

      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);
      outlineGeo.translate(0, 5000, 0);

      if (
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
      ) {
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
      }

      // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
      // line.userData = {type: "SHEETING_OUTLINE"};
      // this.APP.scene.add( line );

      let simplifiedGeo = this.simplifyGeo(outlineGeo);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: simplifiedGeo.vertices,
        views: o.userData.views,
      });
    }

    lsGeometries.push({
      objectType: GEOMETRY_TYPE.ROOF_COVER,
      vertices: this.makeRoofPolygon(),
      color: this.utils.convertHexColorToRgb(`#${new Color(UI.panelColor).getHexString()}`),
      views: [
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
      ]
    });

    return { lines: lsGeometries, texts: [] };
  }
  makeRoofPolygon() {
    const polygon = this.utils.getRoofCoverPolygonForLRRoof()
    this.roofGroup.updateWorldMatrix(true, true)
    return polygon.map(el => (el as Vector3).applyMatrix4(this.roofGroup.matrixWorld))
  }
  public showPanelOutline() {
    if (this.APP.sltPanelDirectionShow.currentValue == false) return;

    let objs = this.roofGroup.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    );

    let offsetLeft =
      this.totalBaySize / 2 +
      UI.overhangLeft -
      this.MANAGER.patiosGroup.position.x;
    let offsetRight =
      this.totalBaySize / 2 +
      UI.overhangRight +
      this.MANAGER.patiosGroup.position.x;
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);

    for (let o of objs) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        60
      );

      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);
      //outlineGeo.translate(0, 1000, 0);

      if (
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
      ) {
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
      }

      var line = new LineSegments(
        outlineGeo,
        MaterialManager.Instance().MESH_OUTLINE
      );
      line.userData = { type: GEOMETRY_TYPE.SHEET_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  public showPanelOutLineTest() {
    // if(this.APP.sltPanelDirectionShow.currentValue == false)
    //     return;

    //console.log('show layout');

    let objs = this.roofGroup.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    );

    let offsetLeft =
      this.totalBaySize / 2 +
      UI.overhangLeft -
      this.MANAGER.patiosGroup.position.x;
    let offsetRight =
      this.totalBaySize / 2 +
      UI.overhangRight +
      this.MANAGER.patiosGroup.position.x;
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);

    let flag = false;

    //for (let i = 0; i < objs.length-1; i+=2)
    //for(let o of objs)
    {
      // let o1 = objs[i];
      // let o2 = objs[i+1];

      // o1.material = objs[i].material.clone();
      // o2.material = objs[i+1].material.clone();

      // if(flag){
      //     o1.material.color = new Color('gray');
      //     o2.material.color = new Color('gray');
      // }
      // else{
      //     o1.material.color = new Color('white');
      //     o2.material.color = new Color('white');
      // }

      // flag = !flag;

      let o = objs[7];
      //let newGeo = BufferGeometryUtils.toTrianglesDrawMode(new BufferGeometry().fromGeometry((o as Mesh).geometry), TrianglesDrawMode);

      let tmpGeo = (o as Mesh).geometry as Geometry;
      tmpGeo.computeFaceNormals();
      tmpGeo.mergeVertices();
      tmpGeo.computeVertexNormals();
      //let simpifiedGeo = this.modifier.modify(tmpGeo, Math.floor(tmpGeo.vertices.length * 0.9));

      // let simplified = o.clone() as Mesh;
      // simplified.material = (simplified.material as MeshPhongMaterial).clone();
      // simplified.material.flatShading = true;

      //const count = Math.floor( newGeo.attributes['position'].count * 0.9 ); // number of vertices to remove
      //newGeo = this.modifier.modify( newGeo, count );

      // var rs = ((o as Mesh).geometry as Geometry).mergeVertices();
      // console.log('result merge vertice', rs);

      //let newMesh = new Mesh(simpifiedGeo);

      //let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(newMesh, 90);

      //o.updateWorldMatrix(true, true);
      //outlineGeo.applyMatrix4(o.matrixWorld);
      //outlineGeo.translate(0, 1000, 0);

      // if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE) {
      //     outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
      //     outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
      // }

      // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
      // line.userData = {type: GEOMETRY_TYPE.SHEET_OUTLINE};
      // this.APP.scene.add( line );

      // const wireframe = new WireframeGeometry( simpifiedGeo );
      // let line2 = new LineSegments( wireframe );
      // line2.userData = {type: GEOMETRY_TYPE.SHEET_OUTLINE};
      // line2.material.depthTest = false;
      // line2.material.opacity = 0.25;
      // line2.material.transparent = true;

      // o.updateMatrixWorld();
      // line2.applyMatrix4(o.matrixWorld);

      // line2.translateY(1000);

      // this.APP.scene.add(line2);
    }
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let simplifiedGeo = new Geometry();
    let vertices = geo.getAttribute("position").array;
    for (let i = 0; i < vertices.length; i += 3) {
      simplifiedGeo.vertices.push(
        new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2])
      );
    }
    return simplifiedGeo;
  }
  public addRoof() {
    this.roofGroup = null;
    this.roofGroup = new Group();
    this.roofGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.ROOF_PATIOS,
    };

    let offsetY = this.getRoofOffsetY();
    let offsetZ = -(
      UI.existingWidth1 / 2 +
      UI.overhangBack +
      this.extraOffsetZ
    );

    this.roofGroup.position.setY(offsetY);
    this.roofGroup.position.setZ(offsetZ);
    this.roofGroup.rotateX(
      this.utils.degreesToRadians(-this.APP.sltRoofPitch.currentValue)
    );

    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndPurlinManager.geo_bracket?.width || 0);
      this.roofGroup.position.setX(-offsetX);
    }

    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndPurlinManager.geo_bracket?.width || 0);
      this.roofGroup.position.setX(offsetX);
    }

    this.scene.add(this.roofGroup);
  }
  public addRoofSheeting(offsetX: number, csgObjs: CSG[]) {
    let mesh: Mesh = new Mesh(this.geo_RoofPanel.geometry, this.material);

    const panelLength = (UI.span +
      UI.overhangFront +
      UI.overhangBack +
      UI.multiSpan) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue)
    const startZOfPanel = UI.span +
    UI.overhangFront +
    UI.overhangBack +
    UI.multiSpan

    let scaleZ =
      panelLength /
      this.geo_RoofPanel.length;
    
    let offsetY = 0;
    let offsetZ = 0;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    mesh.position.set(offsetX, offsetY, offsetZ);
    mesh.scale.setZ(scaleZ);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.ROOF_PANEL,
      views: views,
    };
    let cutoutCondition =
      UI.cutOutType == 1 &&
      UI.existingType != 0 &&
      UI.existingLength2 > 0;
    let rakeCutCondition =
      UI.rakeCutLeftType != RAKECUT_TYPE.NONE ||
      UI.rakeCutRightType != RAKECUT_TYPE.NONE;

    if (cutoutCondition || rakeCutCondition) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      mesh.position.set(
        offsetX + this.MANAGER.patiosGroup.position.x,
        offsetY,
        offsetZ + this.roofGroup.position.z - (panelLength - startZOfPanel)
      );
      mesh.updateMatrix();

      let meshResult = mesh.clone();

      if (cutoutCondition) {
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(csgObj),
            mesh.matrix
          );
        }
      }
      if (rakeCutCondition) {
        if (UI.rakeCutLeftType != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(this.csg_rakecutLeft),
            mesh.matrix
          );
        }
        if (UI.rakeCutRightType != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(this.csg_rakecutRight),
            mesh.matrix
          );
        }
      }

      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix));
      meshResult.applyMatrix4(matrixBefore);

      let buferGeo = new BufferGeometry().fromGeometry((meshResult as Mesh).geometry as Geometry);
      this.utils.changeGeoColor(buferGeo, new Vector3(0, 1, 0), UI.roofColor.toString());
      let newGeo = new Geometry().fromBufferGeometry(buferGeo);
      (meshResult as Mesh).geometry = newGeo;

      this.roofGroup.add(meshResult);
    } else {
      this.roofGroup.add(mesh);
    }
  }

  addBargeCappingCutRight() {
    //right
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    let offsetRight =
      this.totalBaySize / 2 +
      UI.overhangRight -
      UI.existingLength2;
    let scaleZ =
      (UI.existingWidth1 +
        this.APP.existingWallManager.geo_existingWallL2.width +
        20) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue) /
      this.geo_roofPanelBargeCappingLeft.length;
    let mesh = new Mesh(
      this.geo_roofPanelBargeCappingRight.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.setX(offsetRight);
    mesh.scale.setZ(scaleZ);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };
    this.roofGroup.add(mesh);

    //back
    let offsetLeft = offsetRight + UI.existingLength2;
    let scaleX =
      UI.existingLength2 /
      this.geo_roofPanelBargeCappingFront.length;
    let offsetZ =
      UI.existingWidth1 +
      this.APP.existingWallManager.geo_existingWallL2.width +
      10;
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingFront.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.setX(offsetLeft);
    mesh.position.setZ(offsetZ);
    mesh.rotateY(Math.PI);
    mesh.scale.setX(scaleX);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };

    this.roofGroup.add(mesh);
  }
  addBargeCappingCutLeft() {
    //left
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    let offsetLeft =
      this.totalBaySize / 2 +
      UI.overhangLeft -
      UI.existingLength2;
    let scaleZ =
      (UI.existingWidth1 +
        this.APP.existingWallManager.geo_existingWallL2.width +
        50) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue) /
      this.geo_roofPanelBargeCappingLeft.length;
    let mesh = new Mesh(
      this.geo_roofPanelBargeCappingLeft.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.setX(-offsetLeft);
    mesh.scale.setZ(scaleZ);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };
    this.roofGroup.add(mesh);

    //back
    let scaleX =
      UI.existingLength2 /
      this.geo_roofPanelBargeCappingFront.length;
    let offsetZ =
      UI.existingWidth1 +
      this.APP.existingWallManager.geo_existingWallL2.width +
      10;
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingFront.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.setX(-offsetLeft);
    mesh.position.setZ(offsetZ);
    mesh.rotateY(Math.PI);
    mesh.scale.setX(scaleX);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };

    this.roofGroup.add(mesh);
  }
  private addBargeCapping() {
    let mesh: Mesh;

    let extraWallWidth =
      this.APP.existingWallManager.geo_existingWallW1.width +
      EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;

    let offsetXF = -(
      this.totalBaySize / 2 +
      UI.overhangLeft
    );
    let offsetXL1 =
      -(this.totalBaySize / 2 + UI.overhangLeft) +
      UI.existingLength2 -
      extraWallWidth;
    let offsetXL2 = -(
      this.totalBaySize / 2 +
      UI.overhangLeft
    );

    let offsetXR1 =
      this.totalBaySize / 2 +
      UI.overhangRight -
      UI.existingLength2 +
      this.APP.existingWallManager.geo_existingWallW1.width +
      100;
    let offsetXR2 =
      this.totalBaySize / 2 + UI.overhangRight;

    let offsetZL = 0;
    let offsetZR = 0;
    let offsetZFront =
      (UI.span +
        UI.multiSpan +
        UI.overhangFront +
        UI.overhangBack) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);

    let bargeFrontLength = this.roofWidth;

    let scaleCutoutVer =
      this.utils.getHypotenuseByCos(
        UI.existingWidth1 +
          this.geo_roofPanelBargeCappingLeft.width,
        this.APP.sltRoofPitch.currentValue
      ) / this.geo_roofPanelBargeCappingLeft.length;
    //let scaleCutoutHor = (UI.existingLength2 - extraWallWidth) / this.geo_bargeCutoutLeftHor.length;

    let bargeLeftLength = this.roofLength;
    let bargeRightLength = this.roofLength;

    let offsetY = 0;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    //cutout
    if (
      UI.cutOutType == 1 &&
      UI.existingLength2 > 0 &&
      this.MANAGER.cutoutCondition &&
      (this.APP.sltExistingType.currentValue == 1 ||
        this.APP.sltExistingType.currentValue == 3)
    ) {
      bargeLeftLength -= UI.existingWidth1;
      offsetZL += this.utils.getHypotenuseByCos(
        UI.existingWidth1,
        this.APP.sltRoofPitch.currentValue
      );

      let bargeCutoutHorLength =
        UI.existingLength2 +
        UI.overhangLeft -
        extraWallWidth;

      if (this.APP.sldExistingWidth2.currentValue > 0) {
        offsetXL1 += extraWallWidth;
        //scaleCutoutHor = (UI.existingLength2 ) / this.geo_bargeCutoutLeftHor.length;
        bargeCutoutHorLength += extraWallWidth;
      }

      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        offsetXL1 =
          -this.APP.sldExistingLength.currentValue / 2 -
          this.APP.existingWallManager.geo_existingWallW1.width -
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
        //scaleCutoutHor = ((this.roofWidth - (this.APP.sldExistingLength.currentValue + this.APP.existingWallManager.geo_existingWallW1.width * 2)) / 2 - EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK) / this.geo_bargeCutoutLeftHor.length;
        bargeCutoutHorLength =
          (this.roofWidth -
            (this.APP.sldExistingLength.currentValue +
              this.APP.existingWallManager.geo_existingWallW1.width * 2)) /
            2 -
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
      }

      offsetXL1 +=
        UI.overhangLeft -
        EXISTING_BUILDING_CONFIG.BARGE_ROOF_OFFSET_X;

      //scaleCutoutHor = bargeCutoutHorLength/this.geo_bargeCutoutLeftHor.length;

      //cutout Hor
      let offsetZ = this.utils.getHypotenuseByCos(
        UI.existingWidth1,
        this.APP.sltRoofPitch.currentValue
      );
      mesh = new Mesh(
        this.geo_bargeCutoutLeftHor.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXL1, offsetY, offsetZ);
      mesh.scale.setX(
        bargeCutoutHorLength / this.geo_bargeCutoutLeftHor.length
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);

      //cutout Ver
      mesh = new Mesh(
        this.geo_roofPanelBargeCappingLeft.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXL1, offsetY, 0);
      mesh.scale.setZ(scaleCutoutVer);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    }
    if (
      UI.cutOutType == 1 &&
      UI.existingLength2 > 0 &&
      this.MANAGER.cutoutCondition &&
      (this.APP.sltExistingType.currentValue == 2 ||
        this.APP.sltExistingType.currentValue == 3)
    ) {
      bargeRightLength -= UI.existingWidth1;
      let bargeCutoutHorLength =
        UI.existingLength2 +
        UI.overhangRight -
        extraWallWidth;
      offsetZR += this.utils.getHypotenuseByCos(
        UI.existingWidth1,
        this.APP.sltRoofPitch.currentValue
      );

      if (this.APP.sldExistingWidth2.currentValue > 0) {
        offsetXR1 -= extraWallWidth;
        //scaleCutoutHor = (UI.existingLength2 ) / this.geo_bargeCutoutLeftHor.length;
        bargeCutoutHorLength += extraWallWidth;
      }

      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        offsetXR1 =
          this.APP.sldExistingLength.currentValue / 2 +
          this.APP.existingWallManager.geo_existingWallW1.width +
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
        //scaleCutoutHor = ((this.roofWidth - (this.APP.sldExistingLength.currentValue + this.APP.existingWallManager.geo_existingWallW1.width * 2)) / 2 - EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK) / this.geo_bargeCutoutLeftHor.length;
        bargeCutoutHorLength =
          (this.roofWidth -
            (this.APP.sldExistingLength.currentValue +
              this.APP.existingWallManager.geo_existingWallW1.width * 2)) /
            2 -
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
      }

      offsetXR1 -=
        UI.overhangRight -
        EXISTING_BUILDING_CONFIG.BARGE_ROOF_OFFSET_X;

      //cutout hor
      let offsetZ = this.utils.getHypotenuseByCos(
        UI.existingWidth1,
        this.APP.sltRoofPitch.currentValue
      );
      mesh = new Mesh(
        this.geo_bargeCutoutRightHor.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXR1, offsetY, offsetZ);
      mesh.scale.setX(
        bargeCutoutHorLength / this.geo_bargeCutoutLeftHor.length
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);

      //cutout ver
      mesh = new Mesh(
        this.geo_roofPanelBargeCappingRight.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXR1, offsetY, 0);
      mesh.scale.setZ(scaleCutoutVer);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    }
    if (
      UI.cutOutType == 1 &&
      UI.existingLength2 > 0 &&
      this.MANAGER.cutoutCondition &&
      this.APP.sltExistingType.currentValue == 3
    ) {
    }

    //Rakecut
    if (
      UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE ||
      UI.rakeCutLeftType == RAKECUT_TYPE.STEP
    ) {
      offsetXF += UI.rakeCutLeftHor;
      bargeLeftLength -= UI.rakeCutLeftVer;
      bargeFrontLength -= UI.rakeCutLeftHor;
    }
    if (
      UI.rakeCutRightType == RAKECUT_TYPE.ANGLE ||
      UI.rakeCutRightType == RAKECUT_TYPE.STEP
    ) {
      bargeRightLength -= UI.rakeCutRightVer;
      bargeFrontLength -= UI.rakeCutRightHor;
    }

    let scaleXF = bargeFrontLength / this.geo_roofPanelBargeCappingFront.length;
    let scaleZLeft =
      this.utils.getHypotenuseByCos(
        bargeLeftLength,
        this.APP.sltRoofPitch.currentValue
      ) / this.geo_roofPanelBargeCappingLeft.length;
    let scaleZRight =
      this.utils.getHypotenuseByCos(
        bargeRightLength,
        this.APP.sltRoofPitch.currentValue
      ) / this.geo_roofPanelBargeCappingLeft.length;

    //left
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingLeft.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.set(offsetXL2, offsetY, offsetZL);
    mesh.scale.setZ(scaleZLeft);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };

    this.roofGroup.add(mesh);

    //right
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingRight.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.set(offsetXR2, offsetY, offsetZR);
    mesh.scale.setZ(scaleZRight);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };
    this.roofGroup.add(mesh);

    //front
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingFront.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.set(offsetXF, 0, offsetZFront + 2);
    mesh.scale.setX(scaleXF);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };

    this.roofGroup.add(mesh);
  }
  private addBargeAngelRakecut() {
    if (
      UI.rakeCutLeftType != RAKECUT_TYPE.ANGLE &&
      UI.rakeCutRightType != RAKECUT_TYPE.ANGLE
    ) {
      return;
    }

    let offsetY = 0;
    let offsetZ =
      (UI.span +
        UI.multiSpan +
        UI.overhangFront +
        UI.overhangBack) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
      let length = Math.sqrt(
        Math.pow(UI.rakeCutLeftVer, 2) +
          Math.pow(UI.rakeCutLeftHor, 2)
      );
      let scaleZ = length / this.geo_bargeRakecutLeft.length;

      let rotY = Math.atan(
        UI.rakeCutLeftHor /
          UI.rakeCutLeftVer
      );

      let offsetX =
        -this.totalBaySize / 2 -
        UI.overhangLeft +
        UI.rakeCutLeftHor;
      let mesh = new Mesh(
        this.geo_bargeRakecutLeft.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetX, offsetY, offsetZ);
      mesh.scale.setZ(scaleZ);
      mesh.rotateY(rotY);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
      let length = Math.sqrt(
        Math.pow(UI.rakeCutRightVer, 2) +
          Math.pow(UI.rakeCutRightHor, 2)
      );
      let scaleZ = length / this.geo_bargeRakecutLeft.length;

      let rotY = Math.atan(
        UI.rakeCutRightHor /
          UI.rakeCutRightVer
      );

      let offsetX =
        this.totalBaySize / 2 +
        UI.overhangRight -
        UI.rakeCutRightHor;
      let mesh = new Mesh(
        this.geo_bargeRakecutRight.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetX, offsetY, offsetZ);
      mesh.scale.setZ(scaleZ);
      mesh.rotateY(-rotY);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    }
  }
  private addBargeStepRakecut() {
    if (
      UI.rakeCutLeftType != RAKECUT_TYPE.STEP &&
      UI.rakeCutRightType != RAKECUT_TYPE.STEP
    ) {
      return;
    }

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    let offsetXL =
      this.APP.dialogEditBay.totalBaySize / 2 +
      UI.overhangLeft;
    let offsetXR =
      this.APP.dialogEditBay.totalBaySize / 2 +
      UI.overhangRight;

    if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      let offsetZ = this.utils.getHypotenuseByCos(
        UI.span +
          UI.multiSpan +
          UI.overhangFront +
          UI.overhangBack -
          UI.rakeCutLeftVer,
        this.APP.sltRoofPitch.currentValue
      );
      let scaleZ =
        (UI.rakeCutLeftVer +
          this.geo_roofPanelBargeCappingLeft.width) /
        this.geo_roofPanelBargeCappingLeft.length;
      let scaleX =
        UI.rakeCutLeftHor /
        this.geo_roofPanelBargeCappingLeft.length;

      let bargeVer = new Mesh(
        this.geo_roofPanelBargeCappingLeft.geometry,
        MaterialManager.Instance().BARGE
      );
      bargeVer.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      bargeVer.position.set(
        -offsetXL + UI.rakeCutLeftHor,
        0,
        offsetZ - this.geo_roofPanelBargeCappingLeft.width
      );
      bargeVer.scale.set(1, 1, scaleZ);

      let bargeHor = new Mesh(
        this.geo_roofPanelBargeCappingLeft.geometry,
        MaterialManager.Instance().BARGE
      );
      bargeHor.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      bargeHor.position.set(-offsetXL, 0, offsetZ);
      bargeHor.scale.set(1, 1, scaleX);
      bargeHor.rotateY(Math.PI / 2);

      this.roofGroup.add(bargeVer, bargeHor);
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      let offsetZ = this.utils.getHypotenuseByCos(
        UI.span +
          UI.multiSpan +
          UI.overhangFront +
          UI.overhangBack -
          UI.rakeCutRightVer,
        UI.patiosPitch
      );
      let scaleZ =
        (UI.rakeCutRightVer +
          this.geo_roofPanelBargeCappingLeft.width) /
        this.geo_roofPanelBargeCappingLeft.length;
      let scaleX =
        UI.rakeCutRightHor /
        this.geo_roofPanelBargeCappingLeft.length;

      let bargeVer = new Mesh(
        this.geo_roofPanelBargeCappingRight.geometry,
        MaterialManager.Instance().BARGE
      );
      bargeVer.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      bargeVer.position.set(
        offsetXR - UI.rakeCutRightHor,
        0,
        offsetZ - this.geo_roofPanelBargeCappingLeft.width
      );
      bargeVer.scale.set(1, 1, scaleZ);

      let bargeHor = new Mesh(
        this.geo_roofPanelBargeCappingRight.geometry,
        MaterialManager.Instance().BARGE
      );
      bargeHor.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      bargeHor.position.set(offsetXR, 0, offsetZ);
      bargeHor.scale.set(1, 1, scaleX);
      bargeHor.rotateY(-Math.PI / 2);

      this.roofGroup.add(bargeVer, bargeHor);
    }
  }
  public addGutter() {
    let offsetX = -(
      this.totalBaySize / 2 +
      UI.overhangLeft
    );
    let offsetZ = -100;
    let offsetY = 0;
    let gutterLength =
      this.totalBaySize +
      UI.overhangLeft +
      UI.overhangRight;
    let extraWallWidth =
      this.APP.existingWallManager.geo_existingWallW1.width +
      EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;

    //cutout
    if (
      UI.cutOutType == CUTOUT_ENABLE.YES &&
      this.MANAGER.cutoutCondition
    ) {
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
        if (this.APP.sldExistingWidth2.currentValue > 0) {
          offsetX += UI.existingLength2;
          gutterLength -= UI.existingLength2;
        } else {
          offsetX += UI.existingLength2 - extraWallWidth;
          gutterLength -=
            UI.existingLength2 - extraWallWidth;
        }
        offsetX += UI.overhangLeft;
        gutterLength -= UI.overhangLeft;
      } else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
        if (this.APP.sldExistingWidth2.currentValue > 0) {
          gutterLength -= UI.existingLength2;
        } else {
          gutterLength -=
            UI.existingLength2 - extraWallWidth;
        }
        //offsetX -= UI.overhangRight;
        gutterLength -= UI.overhangRight;
      } else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        offsetX +=
          this.cutoutExistingBothLength -
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
        gutterLength =
          this.APP.sldExistingLength.currentValue + extraWallWidth * 2;
      }
    }

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    //back
    let gutterGroup = this.utils.createGutterGroup(
      this.geo_gutterFront,
      this.geo_gutterCap,
      gutterLength,
      new Vector3(offsetX, offsetY, offsetZ),
      new Vector3(
        this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
        0,
        0
      ),
      views,
      1,
      true,
      true
    );

    this.addDownPipe(gutterGroup, gutterLength);

    this.roofGroup.add(gutterGroup);
  }
  private addDownPipe(gutterGroup: Group, gutterLength: number) {
    let meshPipe = new Mesh(
      this.geo_downPipe.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshPipe.userData = { type: GEOMETRY_TYPE.DOWNPIPE_NOZZLE };
    meshPipe.scale.setY(100 / this.geo_downPipe.height);
    meshPipe.position.set(100, -190, this.geo_downPipe.width / 2 + 20);

    gutterGroup.add(meshPipe);
  }
  private totalHeightFromEaveHeightToTopOfExistingRoof(){
    const height = 
      this.geometryManager.EAVE.EAVE.height 
      + UI.fasciaDepth 
      + UI.eaveWidth * this.utils.tan(UI.existingRoofPitch) 
      + this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.height / this.utils.cos(UI.existingRoofPitch)

    return height
  }
  private totalHeightFromEaveHeightToTopOfFlyoverBraket(){
    const height = this.totalHeightFromEaveHeightToTopOfExistingRoof() + UI.braketHeight - FIT_FLYOVER_BRAKET_ON_ROOF;

    return height
  }
  public getRoofOffsetY() {
    return (
      this.APP.sldExistingWallHeight.currentValue +
      this.totalHeightFromEaveHeightToTopOfFlyoverBraket() +
      this.geometryManager.getBeam().height -
      this.utils.tan(this.APP.sltRoofPitch.currentValue) *
        UI.overhangBack
    );
  }

  public destroy(): void {
    this.unregisterEvent();
  }
  public onColorChanged() {
    if(this.utils && this.geo_RoofPanel.geometry){
      this.utils.changeGeoColor(this.geo_RoofPanel.geometry, new Vector3(0, 1, 0), UI.roofColor.toString());
    }
  }
  private registerEvent(): void {
    this.eventHandleId = this.onColorChanged.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sltColourRoof
    ];
    this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));

    this.controlsToRegisterEvent2 = [
      this.APP.sltRoofSheetingType,
      this.APP.sltRoofThickness,
      this.APP.sltBargeType,
      this.APP.sltDripBarge,
      this.APP.sltGutterType,
    ];
    this.controlsToRegisterEvent2.forEach((c) =>
      c.addAction(this.objectSizeChangedHandle)
    );
  }
  private unregisterEvent(): void {
    this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach((c) =>
      c.removeAction(this.objectSizeChangedHandle)
    );
    this.controlsToRegisterEvent2 = undefined;
  }
  private objectSizeChanged(preVal: number, curVal: number) {
    this.optimize().then(() => {
      this.load();
    });
  }
  private addCutout() {
    if (UI.cutOutType == 1) {
      this.APP.scene.remove(
        ...this.APP.scene.children.filter((x) => x.userData.type == "CUTOUT")
      );

      let offsetX =
        this.APP.sldExistingLength.currentValue / 2 +
        this.APP.existingWallManager.geo_existingWallL2.width +
        100;
      let offsetY = 0;
      let offsetZ = UI.existingWidth1 / 2 - UI.existingWallW1.width - this.APP.eaveManager.backOverhang - UI.overhangBack;

      let scaleX = (UI.existingLength2 + 5000) / 1000;
      if (scaleX == 0) scaleX = 1;
      let scaleY = (this.APP.sldExistingWallHeight.currentValue + 5000) / 1000;
      let scaleZ =
        (UI.existingWidth1 +
          UI.overhangBack +
          5000) /
        1000;
      if (scaleZ == 0) scaleZ = 1;

      // if(this.APP.sltExistingType.currentValue == 3){
      //     offsetX -= 100;
      // }

      if (
        this.APP.sltExistingType.currentValue == 1 ||
        this.APP.sltExistingType.currentValue == 3
      ) {
        this.mesh_CutoutLeft = new Mesh(
          this.geo_cutoutLeft,
          new MeshLambertMaterial({
            color: "red",
            transparent: true,
            opacity: 0.5,
          })
        );
        this.mesh_CutoutLeft.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: "CUTOUT",
        };

        this.mesh_CutoutLeft.position.set(-offsetX, offsetY, offsetZ);
        this.mesh_CutoutLeft.scale.set(scaleX, scaleY, scaleZ);

        //this.APP.scene.add(this.mesh_CutoutLeft);

        this.mesh_CutoutLeft.updateMatrix();
        this.csg_cutoutLeft = CSG.fromMesh(this.mesh_CutoutLeft);
      }
      if (
        this.APP.sltExistingType.currentValue == 2 ||
        this.APP.sltExistingType.currentValue == 3
      ) {
        this.mesh_CutoutRight = new Mesh(
          this.geo_cutoutRight,
          new MeshLambertMaterial({
            color: "red",
            transparent: true,
            opacity: 0.5,
          })
        );
        this.mesh_CutoutRight.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: "CUTOUT",
        };

        this.mesh_CutoutRight.position.set(offsetX, offsetY, offsetZ);
        this.mesh_CutoutRight.scale.set(scaleX, scaleY, scaleZ);

        //this.APP.scene.add(this.mesh_CutoutRight);

        this.mesh_CutoutRight.updateMatrix();
        this.csg_cutoutRight = CSG.fromMesh(this.mesh_CutoutRight);
      }
    }
  }
  private addAngelRakeCut() {
    let offsetXL =
      -this.totalBaySize / 2 -
      UI.overhangLeft +
      this.MANAGER.patiosGroup.position.x;
    let offsetXR =
      this.totalBaySize / 2 +
      UI.overhangRight +
      this.MANAGER.patiosGroup.position.x;
    let offsetY = -100;

    let offsetZ =
      UI.span +
      UI.multiSpan +
      UI.overhangFront -
      this.extraOffsetZ -
      UI.existingWidth1 / 2;

    if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
      let angle = Math.atan(
        UI.rakeCutLeftHor /
          UI.rakeCutLeftVer
      );
      this.mesh_rakecutLeft = new Mesh(
        this.geo_rakecutLeft,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutLeft.position.set(
        offsetXL,
        offsetY,
        offsetZ - UI.rakeCutLeftVer
      );
      this.mesh_rakecutLeft.scale.set(5, 5, 5);
      this.mesh_rakecutLeft.rotateY(angle);

      // this.APP.scene.add(this.mesh_rakecutLeft);

      this.mesh_rakecutLeft.updateMatrix();
      this.csg_rakecutLeft = CSG.fromMesh(this.mesh_rakecutLeft);
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
      let angle = Math.atan(
        UI.rakeCutRightHor /
          UI.rakeCutRightVer
      );
      this.mesh_rakecutRight = new Mesh(
        this.geo_rakecutRight,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutRight.position.set(
        offsetXR,
        offsetY,
        offsetZ - UI.rakeCutRightVer
      );
      this.mesh_rakecutRight.scale.set(5, 5, 5);
      this.mesh_rakecutRight.rotateY(-angle);

      // this.APP.scene.add(this.mesh_rakecutRight);

      this.mesh_rakecutRight.updateMatrix();
      this.csg_rakecutRight = CSG.fromMesh(this.mesh_rakecutRight);
    }
  }
  private addStepRakecut() {
    let offsetXL =
      -this.totalBaySize / 2 -
      UI.overhangLeft +
      this.MANAGER.patiosGroup.position.x;
    let offsetXR =
      this.totalBaySize / 2 +
      UI.overhangRight +
      this.MANAGER.patiosGroup.position.x;
    let offsetY = -100; //this.APP.sldExistingWallHeight.currentValue;

    let offsetZ =
      UI.span +
      UI.multiSpan +
      UI.overhangFront -
      this.extraOffsetZ -
      UI.existingWidth1 / 2;

    if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      this.mesh_rakecutLeft = new Mesh(
        this.geo_rakecutLeft,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutLeft.position.set(
        offsetXL + UI.rakeCutLeftHor,
        offsetY,
        offsetZ - UI.rakeCutLeftVer
      );
      this.mesh_rakecutLeft.scale.set(5, 5, 5);

      // this.APP.scene.add(this.mesh_rakecutLeft);

      this.mesh_rakecutLeft.updateMatrix();
      this.csg_rakecutLeft = CSG.fromMesh(this.mesh_rakecutLeft);
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      this.mesh_rakecutRight = new Mesh(
        this.geo_rakecutRight,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutRight.position.set(
        offsetXR - UI.rakeCutRightHor,
        offsetY,
        offsetZ - UI.rakeCutRightVer
      );
      this.mesh_rakecutRight.scale.set(5, 5, 5);

      // this.APP.scene.add(this.mesh_rakecutRight);

      this.mesh_rakecutRight.updateMatrix();
      this.csg_rakecutRight = CSG.fromMesh(this.mesh_rakecutRight);
    }
  }
  uiChangedDefer(previousValue: number, currentValue: number) {
    if (UI.cutOutType == 1) {
      if (this.deferHandle) {
        clearTimeout(this.deferHandle);
      }
      this.deferHandle = setTimeout(() => {
        this.load();
      }, this.deferTimeout);
    } else {
      this.load();
    }
  }
}
