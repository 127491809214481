import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: "root" })
export class PriceService {
  constructor(private http: HttpClient) { }

  public searchByQuery(group: string, description): Observable<any> {
    return this.http.get<any>(
      `${environment.apiDbUrl}/price/list-by-query?itemGroup=${group}&itemDescription=${description}`
    );
  }
}