import { Vector3, Object3D, MeshLambertMaterial, SpriteMaterial } from "three"
import { MaterialManager } from "../core/material.manager"

export default class BaseUIControlEntity{
    public size: Array<number>
    public position: Array<number>
    public container: Object3D
    public material: SpriteMaterial
    public rotation: Vector3
    public options
    public mesh: Object3D
    public visible = true;

    constructor(size: Array<number>, position: Array<number>, options, visible = true){
        this.size = size
        this.position = position
        this.container = options.container
        this.material = options.material ? options.material : MaterialManager.Instance().UI_CONTROL;
        this.rotation = options.rotation ? options.rotation : new Vector3;
        this.options = options
        this.visible = visible
        this.draw()
    }

    draw(){}
}