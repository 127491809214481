<!-- <select #selectInput class="ui fluid dropdown" (change)="onValueChanged($event)" [disabled]="_isDisable">
    <option *ngFor="let i of items" value="{{i.id}}">{{i.value}}</option>
    <option value="99999" *ngIf="hasCustomValue">Custom value</option>
</select> -->
<div class="ui fluid dropdown selection" [ngClass]="{'active visible': isShowDropDown, 'disabled': isDisabled}" tabindex="0" (click)="onToggleDropDown()">
    <i class="dropdown icon"></i>
    <div class="text">{{currentValue}}</div>
    <div class="menu transition" [ngClass]="{'hidden': !isShowDropDown, 'visible': isShowDropDown}" tabindex="-1">
        <div *ngFor="let i of items" class="item" [ngClass]="{'active selected': currentValue == i.id}" (click)="onValueChanged(i.id)">{{i.value}}</div>
        <div class="item" *ngIf="hasCustom" data-value="99999" (click)="onValueChanged(99999)">Custom value</div>
    </div>
</div>

<nz-modal nzCentered [(nzVisible)]="isVisibleOpenModal" nzTitle="Input the custom value" nzWidth="400px"
    (nzOnCancel)="onCancel()" (nzOnOk)="onModalCustomOk()" nzMaskClosable="false">
    <nz-input-number style="width: 100%" [nzMin]="minCustom" [nzMax]="maxCustom" [nzStep]="1"
        [(ngModel)]="customValue"></nz-input-number>
</nz-modal>