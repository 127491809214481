import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HomeComponent } from 'src/app/containers/home';
import { ICost } from 'src/app/core/models';

@Component({
  selector: 'dialog-add-cost',
  templateUrl: './dialog-add-cost.component.html',
  styleUrls: ['./dialog-add-cost.component.scss']
})
export class DialogAddCostComponent implements OnInit {
  constructor(public message: NzMessageService) { }
  isVisible: boolean = false;
  costList: ICost[] = [];

  ngOnInit(): void {
  }

  public show(_visible: boolean, list: ICost[]) {
    this.isVisible = _visible;

    if (!this.isVisible) {
      return
    }
    this.costList = [...list]
  }

  onCancel(): void {
    this.isVisible = false;
  }

  onDelete(index): void {
    const _temp = this.costList.filter(f => f.index !== index);
    this.costList = _temp.map((value, index) => {
      return ({
        index: index + 1,
        description: value.description,
        value: value.value
      });
    });
  }
  onNew(): void {
    const _temp = this.costList.map((value, index) => {
      return ({
        index: index + 1,
        description: value.description,
        value: value.value
      });
    });
    _temp.push({ index: _temp.length + 1, description: '', value: 0 });
    this.costList = [..._temp];
  }
  onSave(): void {
    const totalCost = this.costList.reduce((total, val) => total + val.value, 0);
    HomeComponent.ins.currentQuoteInfo.quoteInfo.costList = [...this.costList];
    HomeComponent.ins.currentQuoteInfo.quoteInfo.addCostInstall = totalCost;
    if (this.costList.length > 0) {
      HomeComponent.ins.addCostInstallDes = this.costList[0].description;
    } else {
      HomeComponent.ins.addCostInstallDes = '';
    }
    HomeComponent.ins.calcQuoteInfo();
    this.isVisible = false;
  }
}
