import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HomeComponent } from 'src/app/containers/home';
import { HomeComponent as HomeComponentRibSpan } from 'src/app-ribspan/containers/home';
import { SelectInputItemModel } from 'src/app/core/models';

declare var $: any;


@Component({
  selector: "dropdown-input-custom",
  templateUrl: "./dropdown-input-custom.component.html"
  // styleUrls: ['./dropdown-input.component.css']
})
export class DropDownInputCustomComponent implements OnInit {
  _isDisable: boolean = false;
  @Input("disabled") public set isDisabled(val: boolean) {
    this._isDisable = val;
  }
  @Input("items") items: Array<SelectInputItemModel>;
  @Input("selected") public selectedValue: number;
  @Input("minCustom") public minCustom: number;
  @Input("maxCustom") public maxCustom: number;
  @Output("change") onChange = new EventEmitter();
  @ViewChild("selectInput", { static: true }) selectElement;
  @Input("hasCustom") public hasCustom: boolean;

  public isShowDropDown = false;
  public customValue = 5;
  isVisibleOpenModal: boolean = false;
  previousValue: number;
  currentValue = 10;
  disableList = [];
  //actions: Array<(previousValue: number, currentValue: number) => void> = [];
  actions: Array<{
    priority: number;
    actions: Array<(previousValue: number, currentValue: number) => void>;
  }> = [];

  constructor(private route: Router) { }

  public get currentText(): string {
    let selectedItem = this.items.find(i => i.id == this.currentValue);
    if (selectedItem)
        return selectedItem.value;

    if (this.items.length > 0)
        return this.items[0].value;

  }
  public get isDisabled() {
    return this._isDisable
  }
  ngOnInit(): void {
    this.selectedValue = this.selectedValue || 0;
    this.currentValue = this.selectedValue;
  }
  ngAfterContentInit() {
    this.setSelected(this.selectedValue);

    document.addEventListener("click", (e) => {
      let container = $(".ui.fluid.dropdown.selection");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        this.isShowDropDown = false;
      }
      e.stopPropagation();
    });
  }

  public onToggleDropDown(){
    this.isShowDropDown = !this.isShowDropDown
  }
  public addAction(
    action: (previousValue: number, currentValue: number) => void,
    priority?: number
  ) {
    priority = priority || 3;
    let actionsByPriority = this.actions.find(a => a.priority === priority);
    if (actionsByPriority) {
      actionsByPriority.actions.push(action);
    } else {
      this.actions.push({ priority: priority, actions: [action] });
    }

    this.actions.sort((a, b) => a.priority - b.priority);
  }
  public removeAction(action: any): void {
    for (let item of this.actions) {
      const idx = item.actions.indexOf(action);

      if (idx !== -1) {
        item.actions.splice(idx, 1);
      }
    }
  }

  public onValueChanged(val) {
    if(val == 99999){
      this.onClickCustomValue()
      return
    }
    this.currentValue = val;
    if(this.route.url != '/ribspan' && !HomeComponent.ins.loadCompleted){
      return
    }
    if(this.route.url == '/ribspan' && !HomeComponentRibSpan.ins.loadCompleted){
      return
    }
    for (let item of this.actions) {
      item.actions.forEach(action => {
        action(+this.previousValue, +this.currentValue);
      });
    }
    this.previousValue = val;
  }
  public setSelected(value: any) {
    this.currentValue = value;
    this.selectedValue = value;
  }

  public setSelectedAndHandleEvent(value: any) {
    this.currentValue = value;
    this.selectedValue = value;

    this.onValueChanged({target: {value: value}})
  }

  public setDisable(value: boolean) {
    this.isDisabled = value
  }
  public onClickCustomValue(){
    this.isVisibleOpenModal = true;
  }
  public onModalCustomOk(){
    this.onValueChanged(this.customValue)
    this.isVisibleOpenModal = false;
  }
  public onCancel(){
    this.isVisibleOpenModal = false
  }
  disabledItem(value: any) {
    if (this.disableList.length === 0) {
      return false;
    }
    return this.disableList.indexOf(value) > -1;
  }
}
