import { Injectable } from "@angular/core";
import { OBJECT_TYPE } from "src/app/app.constants";
import { BaseManager } from "src/app-ribspan/core/base.manager";
import SelectionManager from "../selection.manager";
import { UI } from "src/app-ribspan/core/ui";
import { HomeComponent } from "src/app-ribspan/containers/home";
import { MaterialManager } from "../material.manager";
import { DeleteControl } from "src/app-ribspan/models/DeleteControl.model";
@Injectable({ providedIn: 'root' })
export class EditWallManager extends BaseManager {
    controls = [
        UI.Controls.LENGTH1,
        UI.Controls.LENGTH2,
        UI.Controls.WIDTH1,
        UI.Controls.WIDTH2,
        UI.Controls.EXISTING_TYPE
    ]
    
    addControlOptions = undefined;
    offset = 1000;
    offsetY = 0;

    constructor(private selMgr: SelectionManager){
        super()
    }

    init(): Promise<void> {
        return super.init().then(() => {
            this.addControlOptions = {
                container: this.container,
                userData: { type: OBJECT_TYPE.UI_CONTROL_EDIT_WALL_LENGTH1 }
            }
        })
    }

    

    load(): Promise<void>{
        return new Promise(resolve => {
            this.addControl();
            HomeComponent.ins.renderStatic();

            resolve()
        })
    }
    
    addControl(){
        this.container.children = [];
        this.addControlOptions.material = MaterialManager.Instance().UI_CONTROL_DELETE;
        if(UI.existingType == 1 || UI.existingType == 3){
            if(UI.existingWidth1){
                let x = - UI.existingLength1 / 2 - this.offset - 200;
                if(UI.existingLength2){
                    x = - UI.existingLength1 / 2 - UI.existingLength2 - 200;
                }
                if(UI.existingWidth2 && UI.existingWidth2 >= 600){
                    x = - UI.existingLength1 / 2 - UI.existingLength2 - this.offset - 200;
                } else if(UI.existingWidth2 && UI.existingWidth2 < 600){
                    x = - UI.existingLength1 / 2 - UI.existingLength2 - this.offset / 2 - 200;
                } 
                let z = 0;
                new DeleteControl([0.06,0.06,0.06], [x, this.offsetY, z], {...this.addControlOptions, userData: { type: OBJECT_TYPE.UI_CONTROL_EDIT_WALL_WIDTH1, left: true }});
            }
            if(UI.existingLength2){
                let x = - UI.existingLength1 / 2 - UI.existingLength2 / 2;
                let z = - UI.existingWidth1 / 2 - this.offset;
                new DeleteControl([0.06,0.06,0.06], [ x, this.offsetY, z], {...this.addControlOptions, userData: { type: OBJECT_TYPE.UI_CONTROL_EDIT_WALL_LENGTH2, left: true }});
            }
            if(UI.existingWidth2){
                let x = - UI.existingLength1 / 2 - UI.existingLength2 - this.offset / 2 - 200;
                if(UI.existingWidth2 > 600){
                    x = - UI.existingLength1 / 2 - UI.existingLength2 - this.offset - 200;
                }
                let z = UI.existingWidth1 / 2 + UI.existingWidth2 / 2;
                new DeleteControl([0.06,0.06,0.06], [x, this.offsetY, z], {...this.addControlOptions, userData: { type: OBJECT_TYPE.UI_CONTROL_EDIT_WALL_WIDTH2, left: true }});
            }
        }
        if(UI.existingType == 2 || UI.existingType == 3){
            if(UI.existingWidth1){
                let x = UI.existingLength1 / 2 + this.offset + 200;
                if(UI.existingLength2){
                    x =  UI.existingLength1 / 2 + UI.existingLength2 + 200;
                }
                if(UI.existingWidth2){
                    x = UI.existingLength1 / 2 + UI.existingLength2 + this.offset + 200;
                }
                let z = 0;
                new DeleteControl([0.06,0.06,0.06], [x, this.offsetY, z], {...this.addControlOptions, userData: { type: OBJECT_TYPE.UI_CONTROL_EDIT_WALL_WIDTH1, right: true }});
            }
            if(UI.existingLength2){
                let x = UI.existingLength1 / 2 + UI.existingLength2 / 2;
                let z = - UI.existingWidth1 / 2 - this.offset;
                new DeleteControl([0.06,0.06,0.06], [ x, this.offsetY, z], {...this.addControlOptions, userData: { type: OBJECT_TYPE.UI_CONTROL_EDIT_WALL_LENGTH2, right: true }});
            }
            if(UI.existingWidth2){
                let x = UI.existingLength1 / 2 + UI.existingLength2 + this.offset + 200;
                let z = UI.existingWidth1 / 2 + UI.existingWidth2 / 2;
                new DeleteControl([0.06,0.06,0.06], [x, this.offsetY, z], {...this.addControlOptions, userData: { type: OBJECT_TYPE.UI_CONTROL_EDIT_WALL_WIDTH2, right: true }});
            }
        }
    }
}
