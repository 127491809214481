<div class="control-container">
  <!-- <input [disabled]="_isDisable" class='main-control' type="range" [min]="minValue" [max]="maxValue"
      [step]="step"
      (input)="onValueChanged($event)" [(ngModel)]="_currentValue"> -->
  <!-- <input #txtInput [disabled]="_isDisable" class='value-control' type="number" [min]="minValue" [max]="maxValue" [step]="step"
      [ngModel]="_currentValue"> -->

  <nz-slider
    [(ngModel)]="_currentValue"
    [nzDisabled]="_isDisable"
    class="main-control"
    [nzMin]="minValue"
    [nzMax]="maxValue"
    [nzStep]="step"
    (ngModelChange)="onValueChanged($event)"
  ></nz-slider>
  <nz-input-number
    [nzDisabled]="_isDisable"
    [nzMin]="minValue"
    [nzMax]="maxValue"
    [nzStep]="step"
    [nzPrecision]="0"
    (ngModelChange)="onValueChanged($event)"
    [ngModel]="_currentValue"
    class="value-control"
  ></nz-input-number>

  <!-- <nz-slider [(ngModel)]="_currentValue" (input)="onValueChanged($event)" [nzDisabled]="_isDisable"
    class='main-control' [nzMin]="minValue" [nzMax]="maxValue"
    [nzStep]="step" (ngModelChange)="onValueChanged($event)"></nz-slider> -->

  <!-- <nz-input-number #txtInput [disabled]="_isDisable" [(ngModel)]="_currentValue" [nzMin]="minValue" [nzMax]="maxValue"
      [nzStep]="step" class='value-control' (ngModelChange)="onValueChanged($event)">
    </nz-input-number> -->
</div>
