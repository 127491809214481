import { Scene, Mesh, BufferGeometry, Vector3, Material, Plane, MeshPhongMaterial, Geometry, Box3 } from "three";
import { Util } from "./utils";
import { CONFIG as env, GEOMETRY_TYPE } from 'src/app/app.config';
import { HomeComponent as AppComponent } from 'src/app-ribspan/containers/home/home.component';
import { GeometryManager } from "./geometry.manager";
import { MaterialManager } from "./material.manager";
import { Printing2DLine, ViewType, LineType, Printing2DGeometry } from 'src/app/core/models';

export class ExistingRoofManager {
    private scene: Scene;
    private APP: AppComponent;

    private material: Material;
    private materialL1: MeshPhongMaterial;
    private materialL2L: MeshPhongMaterial;
    private materialL2R: MeshPhongMaterial;
    private materialW1L: MeshPhongMaterial;
    private materialW1R: MeshPhongMaterial;
    private materialW2L: MeshPhongMaterial;
    private materialW2R: MeshPhongMaterial;

    private utils: Util;

    private geometryManager: GeometryManager;

    private geo_existingWallL: BufferGeometry;
    private geo_existingWallW: BufferGeometry;

    // private geo_clipingW1BL: Geometry;
    // private geo_clipingW1BR: Geometry;
    // private geo_clipingW1FL: Geometry;
    // private geo_clipingW1FR: Geometry;

    // private geo_clipingL1L: Geometry;
    // private geo_clipingL1R: Geometry;

    // private geo_clipingL2LL: Geometry;
    // private geo_clipingL2RL: Geometry;
    // private geo_clipingL2LR: Geometry;
    // private geo_clipingL2RR: Geometry;

    // private geo_clipingW2BL: Geometry;
    // private geo_clipingW2BR: Geometry;


    constructor() {
        this.utils = new Util();
        this.geometryManager = GeometryManager.Instance();
    }

    public init(app: AppComponent): Promise<void>{
        return new Promise((resolve, reject) => {
            this.APP = app;
            this.scene = app.scene;
            this.materialL1 = MaterialManager.Instance().EXISTING_ROOF.clone();
            
            this.materialL2L = MaterialManager.Instance().EXISTING_ROOF.clone();
            this.materialL2R = MaterialManager.Instance().EXISTING_ROOF.clone();

            this.materialW1L = MaterialManager.Instance().EXISTING_ROOF.clone();
            this.materialW1R = MaterialManager.Instance().EXISTING_ROOF.clone();

            this.materialW2L = MaterialManager.Instance().EXISTING_ROOF.clone();
            this.materialW2R = MaterialManager.Instance().EXISTING_ROOF.clone();

            this.material = MaterialManager.Instance().EXISTING_ROOF.clone();

            app.sldExistingWallHeight.addAction(this.uiChanged.bind(this));
            app.sldEaveWidth.addAction(this.uiChanged.bind(this));
            app.sldFasciaDepth.addAction(this.uiChanged.bind(this));
            app.sldExistingLength.addAction(this.uiChanged.bind(this));
            app.sldExistingLength2.addAction(this.uiChanged.bind(this));
            app.sldExistingWidth1.addAction(this.uiChanged.bind(this));
            app.sldExistingWidth2.addAction(this.uiChanged.bind(this));
            app.sltExistingType.addAction(this.uiChanged.bind(this));
            app.sltStructureType.addAction(this.uiChanged.bind(this));
            app.sltExistingRoofPitch.addAction(this.uiChanged.bind(this));
            app.sltExistingRoofType.addAction(this.uiReloadChange.bind(this))

            resolve();
        });
    }
    public optimize() : Promise<void>{
        return new Promise((resolve, reject) => {
            let translateWidth = this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.width/2 - 60;
            let translateHeight = this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.height/2;

            this.geo_existingWallL = this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.geometry
            .clone()
            .translate(0,translateHeight,-translateWidth)
            //.rotateX(this.utils.degreesToRadians(this.APP.sltExistingRoofPitch.currentValue));

            this.geo_existingWallW = this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.geometry
            .clone()
            .translate(0,translateHeight,-translateWidth)
            .rotateY(Math.PI/2)
            //.rotateZ(-this.utils.degreesToRadians(22.5));

            resolve();
        });
    }
    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == "HELPER"));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == "OUTLINE"));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.EXISTING_ROOF));

            if(this.APP.sltStructureType.currentValue == 4){
                resolve();
                return;
            }

            //----cliping geo-----
            let clipingWidth = 5000;
            let clipingLength = 10000;
            let clipingHeight = 10000;
            
            
            
            // let mat = MaterialManager.Instance().DEFAULT.clone();
            // mat.opacity = 0.7;
            // mat.transparent = true;
            // let mesh = new Mesh(this.geo_clipingW1BL, mat);
            // mesh.userData = { type: 'HELPER' };
            // this.APP.scene.add(mesh);

            //----

            this.materialL1.clippingPlanes = [];
            this.materialW1L.clippingPlanes = [];
            this.materialW1R.clippingPlanes = [];

            this.materialW2L.clippingPlanes = [];
            this.materialW2R.clippingPlanes = [];

            this.materialL2L.clippingPlanes = [];
            this.materialL2R.clippingPlanes = [];

            // this.geo_clipingW1BL = null;
            // this.geo_clipingW1BR = null;
            // this.geo_clipingW1FL = null;
            // this.geo_clipingW1FR = null;

            // this.geo_clipingL1L = null;
            // this.geo_clipingL1R = null;

            // this.geo_clipingL2LL = null;
            // this.geo_clipingL2RL = null;
            // this.geo_clipingL2LR = null;
            // this.geo_clipingL2RR = null;

            // this.geo_clipingW2BL = null;
            // this.geo_clipingW2BR = null;


            // if(this.APP.sldExistingWidth1.currentValue >= this.APP.sldEaveWidth.currentValue){
            //     if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
            //         this.geo_clipingL1L = new BoxGeometry(clipingWidth, clipingHeight, clipingLength);
            //         this.geo_clipingL1L.translate(0,0,clipingLength/2);
            //         this.geo_clipingL1L.rotateY(-(Math.PI/4));
            //         this.geo_clipingL1L.translate(-this.APP.sldExistingLength.currentValue/2,0,-this.APP.sldExistingWidth1.currentValue/2);

            //         this.geo_clipingW1BL = new BoxGeometry(clipingWidth, clipingHeight, clipingLength);
            //         this.geo_clipingW1BL.translate(0,0,-clipingLength/2);
            //         this.geo_clipingW1BL.rotateY(-(Math.PI/4));
            //         this.geo_clipingW1BL.translate(-this.APP.sldExistingLength.currentValue/2,0,-this.APP.sldExistingWidth1.currentValue/2);

            //         // let mat = MaterialManager.Instance().DEFAULT.clone();
            //         // mat.opacity = 0.7;
            //         // mat.transparent = true;
            //         // let mesh = new Mesh(this.geo_clipingL1L, mat);
            //         // mesh.userData = { type: 'HELPER' };
            //         // this.APP.scene.add(mesh);
            //     }
            //     if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
            //         this.geo_clipingL1R = new BoxGeometry(clipingWidth, clipingHeight, clipingLength);
            //         this.geo_clipingL1R.translate(0,0,clipingLength/2);
            //         this.geo_clipingL1R.rotateY(Math.PI/4);
            //         this.geo_clipingL1R.translate(this.APP.sldExistingLength.currentValue/2,0,-this.APP.sldExistingWidth1.currentValue/2);

            //         this.geo_clipingW1BR = new BoxGeometry(clipingWidth, clipingHeight, clipingLength);
            //         this.geo_clipingW1BR.translate(0,0,-clipingLength/2);
            //         this.geo_clipingW1BR.rotateY(Math.PI/4);
            //         this.geo_clipingW1BR.translate(this.APP.sldExistingLength.currentValue/2,0,-this.APP.sldExistingWidth1.currentValue/2);
            //     }
            // }



            this.addExistingRoof({length1: true});
            
            if(this.APP.sldExistingWidth1.currentValue >= this.APP.sldEaveWidth.currentValue){
                let dis = this.getClipingPlaneDistance();
                let planeL1L = new Plane( new Vector3(1,0,-1).normalize(), dis );
                let planeW1L = new Plane( new Vector3(-1,0,1).normalize(), -dis );

                let planeL1R = new Plane( new Vector3(-1,0,-1).normalize(), dis );
                let planeW1R = new Plane( new Vector3(1,0,1).normalize(), -dis );

                

                if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                    this.materialL1.clippingPlanes.push(planeL1L);
                    this.materialW1L.clippingPlanes.push(planeW1L);
                }
                if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                    this.materialL1.clippingPlanes.push(planeL1R);
                    this.materialW1R.clippingPlanes.push(planeW1R);
                }

                this.addExistingRoof({width1: true});
            }
            if(this.APP.sldExistingWidth2.currentValue >= this.APP.sldEaveWidth.currentValue){
                let dis = this.getClipingPlaneDistance();
                let planeW2L = new Plane( new Vector3(-1,0,1).normalize(), -dis );
                let planeL2L = new Plane( new Vector3(1,0,-1).normalize(), dis );

                let planeW2R = new Plane( new Vector3(1,0,1).normalize(), -dis );
                let planeL2R = new Plane( new Vector3(-1,0,-1).normalize(), dis );
                
                var p1 = new Vector3(-this.APP.sldExistingLength.currentValue/2, 0, -this.APP.sldExistingWidth1.currentValue/2);
                var p2 = new Vector3(-this.APP.sldExistingLength.currentValue/2, 0, this.APP.sldExistingWidth1.currentValue/2);
                var p3 = new Vector3(-(this.APP.sldExistingLength.currentValue/2 + this.APP.sldExistingLength2.currentValue), 0, this.APP.sldExistingWidth1.currentValue/2);
                
                var v = new Vector3().subVectors(p2,p1);
                var v2 = new Vector3().subVectors(p3,p2);
                var v2p = v2.clone().multiplyScalar(-1);

                planeW2L.translate(v).translate(v2);
                planeW2R.translate(v).translate(v2p);
                planeL2L.translate(v).translate(v2);
                planeL2R.translate(v).translate(v2p);

                if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                    this.materialW2L.clippingPlanes.push(planeW2L);
                    this.materialL2L.clippingPlanes.push(planeL2L);
                }
                if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                    this.materialW2R.clippingPlanes.push(planeW2R);
                    this.materialL2R.clippingPlanes.push(planeL2R);
                }

                this.addExistingRoof({width2: true});
            }
            if(this.APP.sldExistingLength2.currentValue >= this.APP.sldEaveWidth.currentValue + 500){
                let dis = this.getClipingPlaneDistance();
                let planeW1L = new Plane( new Vector3(1,0,-1).normalize(), dis );
                let planeL2L = new Plane( new Vector3(-1,0,1).normalize(), -dis );

                let planeW1R = new Plane( new Vector3(-1,0,-1).normalize(), dis );
                let planeL2R = new Plane( new Vector3(1,0,1).normalize(), -dis );
                
                var p1 = new Vector3(-this.APP.sldExistingLength.currentValue/2, 0, -this.APP.sldExistingWidth1.currentValue/2);
                var p2 = new Vector3(-this.APP.sldExistingLength.currentValue/2, 0, this.APP.sldExistingWidth1.currentValue/2);
                var v = p2.sub(p1);

                planeW1L.translate(v);
                planeW1R.translate(v);
                planeL2L.translate(v);
                planeL2R.translate(v);

                if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                    this.materialW1L.clippingPlanes.push(planeW1L);
                    this.materialL2L.clippingPlanes.push(planeL2L);
                }
                if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                    this.materialW1R.clippingPlanes.push(planeW1R);
                    this.materialL2R.clippingPlanes.push(planeL2R);
                }

                this.addExistingRoof({length2: true});
            }

            //this.getOutLines();

            resolve();
        });
    }
    public getOutLines(): Printing2DGeometry{
        let objs = this.scene.children.filter(o => o.userData.type == GEOMETRY_TYPE.EXISTING_ROOF );
        let lsGeometries: Printing2DLine[] = [];

        for(let o of objs){            
            let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale((o as Mesh), 60);
            o.updateWorldMatrix(true, true);

            outlineGeo.applyMatrix4(o.matrixWorld);
            outlineGeo.translate(0,5000,0);

            let views = [];

            if(o.userData.position.length1){                
                for(let plane of this.materialL1.clippingPlanes){
                    let _plane = plane.clone() as Plane;
                    _plane.translate(new Vector3(0,5000,0));
                    outlineGeo = this.utils.clipOutline(outlineGeo, _plane);
                }

                views.push(
                    { viewType: ViewType.PLAN, lineType: LineType.DASHED },
                    { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                    { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
                );
            }
            if(o.userData.position.length2){
                if(o.userData.position.left){
                    for(let plane of this.materialL2L.clippingPlanes){
                        let _plane = plane.clone() as Plane;
                        _plane.translate(new Vector3(0,5000,0));
                        outlineGeo = this.utils.clipOutline(outlineGeo, _plane);
                    }
                }
                if(o.userData.position.right){
                    for(let plane of this.materialL2R.clippingPlanes){
                        let _plane = plane.clone() as Plane;
                        _plane.translate(new Vector3(0,5000,0));
                        outlineGeo = this.utils.clipOutline(outlineGeo, _plane);
                    }
                }

                if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                    views.push({ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS })
                }
                if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                    views.push({ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS });
                }

                views.push(
                    { viewType: ViewType.PLAN, lineType: LineType.DASHED },
                    // { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                    // { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
                );
            }
            if(o.userData.position.width1){
                if(o.userData.position.left){
                    for(let plane of this.materialW1L.clippingPlanes){
                        let _plane = plane.clone() as Plane;
                        _plane.translate(new Vector3(0,5000,0));
                        outlineGeo = this.utils.clipOutline(outlineGeo, _plane);
                    }
                }
                if(o.userData.position.right){
                    for(let plane of this.materialW1R.clippingPlanes){
                        let _plane = plane.clone() as Plane;
                        _plane.translate(new Vector3(0,5000,0));
                        outlineGeo = this.utils.clipOutline(outlineGeo, _plane);
                    }
                }

                views.push(
                    { viewType: ViewType.PLAN, lineType: LineType.DASHED },
                    { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS }
                );
            }
            if(o.userData.position.width2){
                if(o.userData.position.left){
                    for(let plane of this.materialW2L.clippingPlanes){
                        let _plane = plane.clone() as Plane;
                        _plane.translate(new Vector3(0,5000,0));
                        outlineGeo = this.utils.clipOutline(outlineGeo, _plane);
                    }
                }
                if(o.userData.position.right){
                    for(let plane of this.materialW2R.clippingPlanes){
                        let _plane = plane.clone() as Plane;
                        _plane.translate(new Vector3(0,5000,0));
                        outlineGeo = this.utils.clipOutline(outlineGeo, _plane);
                    }
                }

                views.push(
                    { viewType: ViewType.PLAN, lineType: LineType.DASHED },
                    { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS }
                );
            }
            

            // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
            // line.userData = {type: "OUTLINE"};
            // //line.position.set(o.position.x - 5000, o.position.y, o.position.z);
            // this.APP.scene.add( line );

            let simplifiedGeo = this.simplifyGeo(outlineGeo);
            lsGeometries.push({ 
                objectType: o.userData.type,
                vertices: simplifiedGeo.vertices, 
                views: views
            });
        }

        return { lines: lsGeometries, texts: [] };
    }
    public simplifyGeo(geo: BufferGeometry): Geometry{
        let simplifiedGeo = new Geometry();
        let vertices = geo.getAttribute('position').array;
        for(let i = 0; i < vertices.length; i+=3){
            simplifiedGeo.vertices.push(new Vector3(vertices[i], vertices[i+1]-5000, vertices[i+2]));
        }
        return simplifiedGeo;
    }

    private getClipingPlaneDistance(): number{
        let AC, BC;
        AC = BC = this.APP.sldExistingWidth1.currentValue/2;
        let CD = this.APP.sldExistingLength.currentValue/2;
        let angle = 45;

        let BD = BC - CD;
        let CE = AC * this.utils.sin(angle);

        let DG = (BD*CE)/BC;

        //let n: Vector3 = new Vector3(1,0,-1).normalize();
        // let clipingPlane = new Plane( n, -DG );
        // let helper = new PlaneHelper(clipingPlane, 10000, 0xffff00);
        // helper.userData = { type: 'HELPER' }
        // this.scene.add(helper);

        return -DG;
    }    
  
    public addExistingRoof(userDataPos: any) {
        let mesh: Mesh;
        let extraLength = 2500;
        if(userDataPos.width1){
            let offsetX = this.APP.sldExistingLength.currentValue/2;
            let offsetY = this.APP.sldExistingWallHeight.currentValue + this.APP.sldFasciaDepth.currentValue + this.geometryManager.EAVE.EAVE.height;
            let offsetZ = 0;
            //let scaleZ = (this.APP.sldExistingWidth1.currentValue + extraLength)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;

            let tt = MaterialManager.Instance().EXISTING_ROOF_TEXTURE.W1;
            tt.repeat.set((this.APP.sldExistingWidth1.currentValue + extraLength)/1000/2, this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.width/1000/2);

            this.materialW1L.map = tt;
            this.materialW1R.map = tt;

            // if(this.APP.sldExistingLength2.currentValue > 0){
            //     scaleZ = (this.APP.sldExistingWidth1.currentValue + this.APP.sldEaveWidth.currentValue + extraLength)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
            //     offsetZ = this.APP.sldEaveWidth.currentValue/2;
            // }

            if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_existingWallW, this.materialW1L);
                mesh.rotateZ(-this.utils.degreesToRadians(this.APP.sltExistingRoofPitch.currentValue));
                
                let box = new Box3().setFromObject(mesh);
                let width = box.max.x - box.min.x;
                let extra = width - this.APP.sldEaveWidth.currentValue;
                let scaleZ = (this.APP.sldExistingWidth1.currentValue + extra)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
                
                mesh.position.setX(-offsetX + this.APP.sldEaveWidth.currentValue);
                mesh.position.setY(offsetY);
                mesh.position.setZ(offsetZ - extra/2);
                mesh.scale.set(1,1,scaleZ);

                if(this.APP.sldExistingLength2.currentValue > 0){
                    scaleZ = (this.APP.sldExistingWidth1.currentValue + this.APP.sldEaveWidth.currentValue + extra)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
                    mesh.position.setZ(offsetZ + this.APP.sldEaveWidth.currentValue/2 - extra/2);
                    mesh.scale.set(1,1,scaleZ);
                }
                

                //------clip-----
                // if(this.geo_clipingW1BL){
                //     let clipingObj = this.CSG.fromGeometry(this.geo_clipingW1BL);
                //     let clipedMesh = this.CSG.fromMesh(mesh).subtract(clipingObj);
                //     mesh = this.CSG.toMesh(clipedMesh, this.materialW1L);
                // }
                // if(this.geo_clipingW1FL){
                //     let clipingObj = this.CSG.fromGeometry(this.geo_clipingW1FL);
                //     let clipedMesh = this.CSG.fromMesh(mesh).subtract(clipingObj);
                //     mesh = this.CSG.toMesh(clipedMesh, this.materialW1L);
                // }
                //--------------

                mesh.userData = { type: GEOMETRY_TYPE.EXISTING_ROOF, position: {...userDataPos, left: true} };
                this.scene.add(mesh);
            }
            if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_existingWallW, this.materialW1R);
                mesh.rotateY(Math.PI);
                mesh.rotateZ(-this.utils.degreesToRadians(this.APP.sltExistingRoofPitch.currentValue));

                let box = new Box3().setFromObject(mesh);
                let width = box.max.x - box.min.x;
                let extra = width - this.APP.sldEaveWidth.currentValue;
                let scaleZ = (this.APP.sldExistingWidth1.currentValue + extra)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;

                mesh.position.setX(offsetX - this.APP.sldEaveWidth.currentValue);
                mesh.position.setY(offsetY);
                mesh.position.setZ(offsetZ - extra/2);
                mesh.scale.set(1,1,scaleZ);

                if(this.APP.sldExistingLength2.currentValue > 0){
                    scaleZ = (this.APP.sldExistingWidth1.currentValue + this.APP.sldEaveWidth.currentValue + extra)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
                    mesh.position.setZ(offsetZ + this.APP.sldEaveWidth.currentValue/2 - extra/2);
                    mesh.scale.set(1,1,scaleZ);
                }
                

                //------clip-----
                // if(this.geo_clipingW1BR){
                //     let clipingObj = this.CSG.fromGeometry(this.geo_clipingW1BR);
                //     let clipedMesh = this.CSG.fromMesh(mesh).subtract(clipingObj);
                //     mesh = this.CSG.toMesh(clipedMesh, this.materialW1R);
                // }
                // if(this.geo_clipingW1FR){
                //     let clipingObj = this.CSG.fromGeometry(this.geo_clipingW1FR);
                //     let clipedMesh = this.CSG.fromMesh(mesh).subtract(clipingObj);
                //     mesh = this.CSG.toMesh(clipedMesh, this.materialW1R);
                // }
                //--------------

                mesh.userData = { type: GEOMETRY_TYPE.EXISTING_ROOF, position: {...userDataPos, right: true} };
                this.scene.add(mesh);
            }
        }
        if(userDataPos.width2){
            let offsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.sldExistingLength2.currentValue;
            let offsetY = this.APP.sldExistingWallHeight.currentValue + this.APP.sldFasciaDepth.currentValue + this.geometryManager.EAVE.EAVE.height;
            let offsetZ = this.APP.sldExistingWidth1.currentValue/2 + this.APP.sldExistingWidth2.currentValue/2;
            //let scaleZ = (this.APP.sldExistingWidth2.currentValue + extraLength)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;

            let tt = MaterialManager.Instance().EXISTING_ROOF_TEXTURE.W2;
            tt.repeat.set((this.APP.sldExistingWidth2.currentValue + extraLength)/1000/2, this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.width/1000/2);

            this.materialW2L.map = tt;
            this.materialW2R.map = tt;

            if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_existingWallW, this.materialW2L);
                mesh.userData = { type: GEOMETRY_TYPE.EXISTING_ROOF, position: {...userDataPos, left: true} };
                mesh.rotateZ(-this.utils.degreesToRadians(this.APP.sltExistingRoofPitch.currentValue));

                let box = new Box3().setFromObject(mesh);
                let width = box.max.x - box.min.x;
                let extra = width - this.APP.sldEaveWidth.currentValue;
                let scaleZ = (this.APP.sldExistingWidth2.currentValue + extra)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;

                mesh.position.setX(-offsetX + this.APP.sldEaveWidth.currentValue);
                mesh.position.setY(offsetY);
                mesh.position.setZ(offsetZ - extra/2);
                mesh.scale.set(1,1,scaleZ);
                
                this.scene.add(mesh);
            }
            if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_existingWallW, this.materialW2R);
                mesh.userData = { type: GEOMETRY_TYPE.EXISTING_ROOF, position: {...userDataPos, right: true} };
                mesh.rotateY(Math.PI);
                mesh.rotateZ(-this.utils.degreesToRadians(this.APP.sltExistingRoofPitch.currentValue));

                let box = new Box3().setFromObject(mesh);
                let width = box.max.x - box.min.x;
                let extra = width - this.APP.sldEaveWidth.currentValue;
                let scaleZ = (this.APP.sldExistingWidth2.currentValue + extra)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;

                mesh.position.setX(offsetX - this.APP.sldEaveWidth.currentValue);
                mesh.position.setY(offsetY);
                mesh.position.setZ(offsetZ - extra/2);
                mesh.scale.set(1,1,scaleZ);
                
                this.scene.add(mesh);
            }
        }
        else if(userDataPos.length1){
            let tt = MaterialManager.Instance().EXISTING_ROOF_TEXTURE.L1;
            tt.repeat.set((this.APP.sldExistingLength.currentValue + extraLength)/1000/2, this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.width/1000/2);
            this.materialL1.map = tt;

            
            
            mesh = new Mesh(this.geo_existingWallL, this.materialL1);
            mesh.userData = { type: GEOMETRY_TYPE.EXISTING_ROOF, position: userDataPos };
            mesh.rotateX(this.utils.degreesToRadians(this.APP.sltExistingRoofPitch.currentValue));


            let offsetX = 0;

            let box = new Box3().setFromObject(mesh);
            let width = box.max.z - box.min.z;
            let extra = width - this.APP.sldEaveWidth.currentValue;
            //let scaleX = (this.APP.sldExistingLength2.currentValue + this.APP.sldEaveWidth.currentValue)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
            let scaleX = (this.APP.sldExistingLength.currentValue + extra)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;

            if(this.APP.sltExistingType.currentValue != 0 && this.APP.sldExistingWidth1.currentValue > this.APP.sldEaveWidth.currentValue){
                if(this.APP.sltExistingType.currentValue == 1){
                    offsetX = -extra/2;
                }
                else if(this.APP.sltExistingType.currentValue == 2){
                    offsetX = extra/2;
                }
                else{
                    //offsetX = -extra/2;
                    scaleX = (this.APP.sldExistingLength.currentValue + extra*2)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
                }
            }
            else{
                extra = 0;
                scaleX = (this.APP.sldExistingLength.currentValue + extra)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
            }

            let offsetY = this.APP.sldExistingWallHeight.currentValue + this.APP.sldFasciaDepth.currentValue + this.geometryManager.EAVE.EAVE.height;
            let offsetZ = this.APP.sldExistingWidth1.currentValue/2 ;


            mesh.position.setX(offsetX);
            mesh.position.setY(offsetY);
            mesh.position.setZ(-offsetZ + this.APP.sldEaveWidth.currentValue);
            mesh.scale.setX(scaleX);
            

            //------clip-----
            // if(this.geo_clipingL1L){
            //     let clipingObj = this.CSG.fromGeometry(this.geo_clipingL1L);
            //     let clipedMesh = this.CSG.fromMesh(mesh).subtract(clipingObj);
            //     mesh = this.CSG.toMesh(clipedMesh, this.materialL1);
            //     console.log("cut LEFT");
            // }
            // if(this.geo_clipingL1R){
            //     let clipingObj = this.CSG.fromGeometry(this.geo_clipingL1R);
            //     let clipedMesh = this.CSG.fromMesh(mesh).subtract(clipingObj);
            //     mesh = this.CSG.toMesh(clipedMesh, this.materialL1);
            //     console.log("cut RIHGT");
            // }
            //--------------

            
            
            this.scene.add(mesh);
        }
        else if(userDataPos.length2){
            // let tt = MaterialManager.Instance().EXISTING_ROOF_TEXTURE.L2;
            // tt.repeat.set((this.APP.sldExistingLength2.currentValue + this.APP.sldEaveWidth.currentValue + extraLength)/1000/2, this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.width/1000/2);

            // this.materialL2L.map = tt;
            // this.materialL2R.map = tt;

            // let offsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.sldExistingLength2.currentValue/2;
            // let offsetY = this.APP.sldExistingWallHeight.currentValue + this.APP.sldFasciaDepth.currentValue + this.geometryManager.EAVE.EAVE.height;
            // let offsetZ = this.APP.sldExistingWidth1.currentValue/2;

            // //let scaleX = (this.APP.sldExistingLength2.currentValue + this.APP.sldEaveWidth.currentValue + extraLength)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;

            // if(this.APP.sldExistingWidth2.currentValue <= this.APP.sldEaveWidth.currentValue){
            //     // if(this.APP.sltExistingType.currentValue == 1){
            //     //     offsetX -= extraLength;
            //     // }
            //     // else if(this.APP.sltExistingType.currentValue == 2){
            //     //     offsetX -= extraLength;
            //     // }
            //     offsetX -= extraLength;
            // }

            // if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
            //     mesh = new Mesh(this.geo_existingWallL, this.materialL2L);
            //     mesh.userData = { type: GEOMETRY_TYPE.EXISTING_ROOF, position: {...userDataPos, left: true} };
            //     mesh.position.setX(-offsetX + this.APP.sldEaveWidth.currentValue/2 - extraLength/2);
            //     mesh.position.setY(offsetY);
            //     mesh.position.setZ(offsetZ + this.APP.sldEaveWidth.currentValue);
            //     mesh.rotateX(this.utils.degreesToRadians(this.APP.sltExistingRoofPitch.currentValue));
            //     let box = new Box3().setFromObject(mesh);
            //     let width = box.max.x - box.min.x;
            //     let scaleX = (this.APP.sldExistingLength2.currentValue + width)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
            //     mesh.scale.setX(scaleX);
            //     this.scene.add(mesh);
            // }
            // if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
            //     mesh = new Mesh(this.geo_existingWallL, this.materialL2R);
            //     mesh.userData = { type: GEOMETRY_TYPE.EXISTING_ROOF, position: {...userDataPos, right: true} };
            //     mesh.position.setX(offsetX - this.APP.sldEaveWidth.currentValue/2 + extraLength/2);
            //     mesh.position.setY(offsetY);
            //     mesh.position.setZ(offsetZ + this.APP.sldEaveWidth.currentValue);
            //     mesh.rotateX(this.utils.degreesToRadians(this.APP.sltExistingRoofPitch.currentValue));
            //     let box = new Box3().setFromObject(mesh);
            //     let width = box.max.x - box.min.x;
            //     let scaleX = (this.APP.sldExistingLength2.currentValue + width)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
            //     mesh.scale.setX(scaleX);
            //     this.scene.add(mesh);
            // }

            let tt = MaterialManager.Instance().EXISTING_ROOF_TEXTURE.L2;
            tt.repeat.set((this.APP.sldExistingLength2.currentValue + this.APP.sldEaveWidth.currentValue + extraLength)/1000/2, this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.width/1000/2);

            this.materialL2L.map = tt;
            this.materialL2R.map = tt;

            let offsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.sldExistingLength2.currentValue/2;
            let offsetY = this.APP.sldExistingWallHeight.currentValue + this.APP.sldFasciaDepth.currentValue + this.geometryManager.EAVE.EAVE.height;
            let offsetZ = this.APP.sldExistingWidth1.currentValue/2;

            //let scaleX = (this.APP.sldExistingLength2.currentValue + this.APP.sldEaveWidth.currentValue + extraLength)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;

            if(this.APP.sldExistingWidth2.currentValue <= this.APP.sldEaveWidth.currentValue){
                // if(this.APP.sltExistingType.currentValue == 1){
                //     offsetX -= extraLength;
                // }
                // else if(this.APP.sltExistingType.currentValue == 2){
                //     offsetX -= extraLength;
                // }
                //offsetX -= extraLength;
            }

            if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_existingWallL, this.materialL2L);
                mesh.userData = { type: GEOMETRY_TYPE.EXISTING_ROOF, position: {...userDataPos, left: true} };
                mesh.position.setX(-offsetX + this.APP.sldEaveWidth.currentValue/2);
                mesh.position.setY(offsetY);
                mesh.position.setZ(offsetZ + this.APP.sldEaveWidth.currentValue);
                mesh.rotateX(this.utils.degreesToRadians(this.APP.sltExistingRoofPitch.currentValue));
                let box = new Box3().setFromObject(mesh);
                let width = box.max.z - box.min.z;
                let extra = width - this.APP.sldEaveWidth.currentValue;
                let scaleX = (this.APP.sldExistingLength2.currentValue + this.APP.sldEaveWidth.currentValue)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
                
                if(this.APP.sldExistingWidth2.currentValue >= this.APP.sldEaveWidth.currentValue){
                    mesh.position.setX(-offsetX + this.APP.sldEaveWidth.currentValue/2 - extra/2);
                    scaleX = (this.APP.sldExistingLength2.currentValue  + this.APP.sldEaveWidth.currentValue + extra)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
                }
                mesh.scale.setX(scaleX);
                this.scene.add(mesh);
            }
            if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_existingWallL, this.materialL2R);
                mesh.userData = { type: GEOMETRY_TYPE.EXISTING_ROOF, position: {...userDataPos, right: true} };
                mesh.position.setX(offsetX - this.APP.sldEaveWidth.currentValue/2);
                mesh.position.setY(offsetY);
                mesh.position.setZ(offsetZ + this.APP.sldEaveWidth.currentValue);
                mesh.rotateX(this.utils.degreesToRadians(this.APP.sltExistingRoofPitch.currentValue));
                let box = new Box3().setFromObject(mesh);
                let width = box.max.z - box.min.z;
                let extra = width - this.APP.sldEaveWidth.currentValue;
                let scaleX = (this.APP.sldExistingLength2.currentValue + this.APP.sldEaveWidth.currentValue)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
                if(this.APP.sldExistingWidth2.currentValue >= this.APP.sldEaveWidth.currentValue){
                    mesh.position.setX(offsetX - this.APP.sldEaveWidth.currentValue/2 + extra/2);
                    scaleX = (this.APP.sldExistingLength2.currentValue  + this.APP.sldEaveWidth.currentValue + extra)/this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.length;
                }
                mesh.scale.setX(scaleX);
                this.scene.add(mesh);
            }
        }
    }

    public uiChanged(preVal: number, curVal): void {
        this.load();
    }
    public uiReloadChange(preVal: number, curVal): void {
        this.optimize().then(() => { this.load() });
    }
}
