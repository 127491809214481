import { Util } from "./utils";
// import { AppComponent } from '../app.component';
import { HomeComponent as AppComponent } from "../containers/home/home.component";
import { Scene } from "three";
import { Printing2DLine, Printing2DData } from "./models";
import { environment } from "src/environments/environment";

export class Printing2DManager {
  private scene: Scene;
  private APP: AppComponent;
  private utils: Util;

  public constructor(app: AppComponent) {
    this.utils = new Util();
    this.APP = app;
    this.scene = this.APP.scene;
  }

  public print(jobInfo: any): void {
    let dimemsionGeo = this.APP.dimensionManager.getOutlines();
    let patiosOutlines = this.APP.patiosManager.getOutlines();
    let lines: Printing2DLine[] = [
      ...patiosOutlines.lines,
      ...this.APP.existingRoofManager.getOutLines().lines,
      ...this.APP.existingWallManager.getOutLines().lines,
      ...this.APP.fasciaManager.getOutLines().lines,
      ...this.APP.eaveManager.getOutLines().lines,
      ...dimemsionGeo.lines,
    ];

    let texts = [...dimemsionGeo.texts, ...patiosOutlines.texts];

    let model: Printing2DData = {
      lines: lines,
      texts: texts,
      sceenshoot: this.APP.canvas.toDataURL(),
      meshes: null,
      info: jobInfo,
    };

    this.APP.printService.printPDF(model).subscribe(
      (rs) => {
        const updateInfo = {
          id: jobInfo.id,
          files: {
            engineeringFile: rs.engineeringFile,
            quotationFile: rs.quotationFile,
            thumbnailFile: rs.thumbnailFile,
          },
        };
        this.APP.onUpdateJobFiles(updateInfo);
      },
      (error) => {
        this.APP.message.error("PDF file upload failed");
        this.APP.isUploading = false;
        this.APP.currentStep = 0;
      }
    );
  }

  public printTest() {
    let dimemsionGeo = this.APP.dimensionManager.getOutlines();
    let patiosOutlines = this.APP.patiosManager.getOutlines();
    let lines: Printing2DLine[] = [
      ...patiosOutlines.lines,
      ...this.APP.existingRoofManager.getOutLines().lines,
      ...this.APP.existingWallManager.getOutLines().lines,
      ...this.APP.fasciaManager.getOutLines().lines,
      ...this.APP.eaveManager.getOutLines().lines,
      ...dimemsionGeo.lines,
    ];

    let texts = [...dimemsionGeo.texts, ...patiosOutlines.texts];

    // let meshes = this.getAllMesh(this.scene);
    // console.log('MESHES NE', meshes);

    let model: Printing2DData = {
      lines: lines,
      texts: texts,
      sceenshoot: this.APP.canvas.toDataURL(),
      meshes: null,
      info: {
        userId: "5e437a5b400f7958b4cfdbf7",
        id: "6466fd04426553c1543c10d8",
        uploadFolder: "superiorsoftware_api/upload/dataFiles",
        pathLogo: "superiorsoftware_api/media/",
        pathPDF: "",
        quoteNo: "ABCDE20251",
        quoteBy: "User SPC",
        windClass: "N2(W33)",
        clientName: "TEST",
        siteAddress: "",
        date: "2023-05-19T04:36:41.152Z",
        builder: "SPC Dev",
        caseType: "A",
        structureType: "To Existing Building",
        roofPitch: 2,
        roofSheetingType: "Coolaspan Trimspan",
        roofOverallLength: 3810,
        roofOverallWidth: 4200,
        maxSpan: 5100,
        span: 4000,
        multiSpan: 0,
        beamType: "Superior Beam 110mm x 65mm (Surfmist)",
        columnType: "Superior Post 65mm x 65mm",
        largestBay: 3810,
        maxUpliftOfBeam: 4.55,
        upliftLoadOnBeam: 1.28,
        maxPostUplift: 1.91,
        postUplift: 2.437,
        baseFixingType: "Dynabolt",
        footingSize: "Min 100mm slab",
        maxFootingHoldDown: "16",
        thickness: "50mm",
        ABNNo: "",
        licenseNumber: "",
        company: "SPC Dev",
        userAddress: "43 Commerce Circuit",
        userEmail: "tester@gmail.com",
        userPhone: "0738073955",
        bankName: "",
        accountNumber: "",
        BSBNumber: "",
        suburb: "",
        postcode: "",
        state: "",
        phone: "",
        email: "",
        baySize: 3810,
        noOfBay: 1,
        buildingHeight: 2200,
        frontOverhang: 200,
        backOverhang: 0,
        leftOverhang: 0,
        rightOverhang: 0,
        flatBottom: "Surfmist/Off White flat bottom",
        bargeType: "Coolaspan Trimspan",
        gutterType: "Gutter Square Line",
        zFlashingType: "Coolaspan Trimspan",
        downpipeType: "90mm PVC",
        beamSize: "SB110",
        colourRoof: "Surfmist",
        colourBarge: "Surfmist",
        colourGutter: "Surfmist",
        colourZFlashing: "Surfmist",
        colourPost: "Surfmist",
        colourBeam: "Surfmist",
        colourDownpipe: "White",
        note: "\n",
        supplyType: "Supply and Install",
        totalPrice: 2417.88,
        deposit: 0,
        stage1: 0,
        stage2: 0,
        finalPayment: 2417.88,
        fullAddress: "",
        depositText: "Deposit",
        stage1Text: "Stage 1",
        stage2Text: "Stage 2",
        listBay: [
          {
            index: 1,
            value: 3810,
            isCut: false,
          },
        ],
      },
    };

    // console.log('2D PRINTING DATA', model);

    //return;

    this.APP.printService.printPDF(model).subscribe(
      (rs) => {
        // let a = document.createElement("a");
        // a.href = rs.file_url;
        // a.target = '_blank';
        // document.body.appendChild(a);
        // a.click();
        // a.remove();
      },
      (error) => {
        // this.message.error(error);
        this.APP.message.error("PDF file upload failed");
        this.APP.isUploading = false;
        this.APP.currentStep = 0;
      }
    );
  }
}
