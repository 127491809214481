import _ from "lodash";
import Beam, { BeamOptions } from "src/app-ribspan/models/Beam.model";
import UpstandBraket, {
  UpstandBraketOptions,
} from "src/app-ribspan/models/UpstandBraket.model";
import {
  BoxBufferGeometry,
  BoxHelper,
  BufferGeometry,
  Geometry,
  Group,
  LineSegments,
  Material,
  Matrix4,
  Mesh,
  Plane,
  Vector3,
} from "three";
import { GableLRFasciaManager } from ".";
import { GEOMETRY_CATEGORY, GEOMETRY_TYPE } from "src/app/app.config";
import { BUILDING_SIDE, EXISTING_BUILDING_CONFIG } from "src/app/app.constants";
import {
  HomeComponent as AppComponent,
  HomeComponent,
} from "../../containers/home/home.component";
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import {
  GeometryInfo,
  LineType,
  Print2DView,
  Printing2DGeometry,
  Printing2DLine,
  ViewType,
} from "src/app/core/models";
import { UI } from "../ui";
import { Util, getBeamEndCapCode, getRafterBeamEndCapCode } from "../utils";

export class PostAndBeamManager {
  private scene: Group;
  private APP: AppComponent;
  private MANAGER: GableLRFasciaManager;
  private material: Material;
  private utils: Util;
  private geometryManager: GeometryManager;
  private postInfo: GeometryInfo;
  public beamInfo: GeometryInfo;
  private beamRakecutInfoLeft: GeometryInfo;
  private beamRakecutInfoRight: GeometryInfo;
  private geo_centerSuperiorPost: GeometryInfo;
  private geo_RafterBeam: GeometryInfo;
  private geo_rafterBeamEndCap: GeometryInfo;
  private geo_beamEndCap: GeometryInfo;
  private geo_groundBase: GeometryInfo;
  private eventHandleId: any;
  private roofWidth: number;
  private existingTypeLeftX: number;
  private existingTypeRightX: number;
  private existingType: number;
  private totalBaySize: number;
  private frontPostHeight: number;
  private downpipeGroup: Group;
  private downpipeStepRakeCutGroupLeft: Group;
  private downpipeStepRakeCutGroupRight: Group;
  public geo_downPipe: GeometryInfo;
  private geo_downPipeL: GeometryInfo;
  private geoBraketExtL_2: GeometryInfo;
  private geoBraketExtR_2: GeometryInfo;
  private objectSizeChangedHandle: any;
  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;

  private geo_beamStepRakecutLeft: GeometryInfo;
  private geo_beamStepRakecutRight: GeometryInfo;

  private geoBeamJoint: GeometryInfo;

  private beamLeftCutSizeInfo: any;
  private beamRightCutSizeInfo: any;

  private geoBraket_1: GeometryInfo;
  public geoBraket_2: GeometryInfo;
  private geoBeam: GeometryInfo;
  private geoBeamEndCap: GeometryInfo;

  private offsetYBraket = 0;

  k: number;
  k_: number;
  j: number;
  l: number;
  m: number;
  v: number;

  constructor(app: AppComponent, fasciaManager: GableLRFasciaManager) {
    this.APP = app;
    this.MANAGER = fasciaManager;
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.scene = fasciaManager.patiosGroup;
    this.material = MaterialManager.Instance().DEFAULT.clone();
    this.registerEvent();
  }
  public destroy(): void {
    this.unregisterEvent();
  }
  public uiChanged(preVal: number, curVal: number): void {
    this.load();
  }
  private objectSizeChanged(pre: number, cur: number) {
    this.optimize().then(() => {
      this.load();
    });
  }
  private registerEvent(): void {
    this.eventHandleId = this.uiChanged.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldSpan,
      this.APP.sldMultiSpan,
      this.APP.sldExistingWallHeight,
      this.APP.sltRoofPitch,
      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,
      this.APP.sldExistingWidth1,
      this.APP.sltExistingType,
      this.APP.dialogEditBay,
      this.APP.sldEaveWidth,
      this.APP.sldFrontOverhang,
      this.APP.sltRoofThickness,
      this.APP.sltGutterType,
    ];
    //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));
    this.controlsToRegisterEvent2 = [
      this.APP.sltBeamType,
      this.APP.sltBeamSize,
      this.APP.sltColumnType,
    ];
    this.controlsToRegisterEvent2.forEach((c) =>
      c.addAction(this.objectSizeChangedHandle)
    );
  }
  private unregisterEvent(): void {
    //this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    //this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach((c) =>
      c.removeAction(this.objectSizeChangedHandle)
    );
    this.controlsToRegisterEvent2 = undefined;
  }
  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.beamInfo = this.geometryManager.getBeam();
      this.beamInfo.geometry
        .rotateY(Math.PI / 2)
        .translate(this.beamInfo.length / 2, -this.beamInfo.height / 2, 0);
      //.scale(this.APP.sldBaySize.currentValue / this.beamInfo.length, 1, 1);

      this.geoBeamJoint = this.geometryManager.getBeamJoint()

      this.beamRakecutInfoLeft = this.geometryManager.getBeam();
      this.beamRakecutInfoLeft.geometry.translate(
        this.beamInfo.width / 2,
        -this.beamInfo.height / 2,
        -this.beamInfo.length / 2
      );

      this.beamRakecutInfoRight = this.geometryManager.getBeam();
      this.beamRakecutInfoRight.geometry.translate(
        -this.beamInfo.width / 2,
        -this.beamInfo.height / 2,
        -this.beamInfo.length / 2
      );

      this.geo_beamStepRakecutLeft = this.geometryManager.getBeam();
      this.geo_beamStepRakecutLeft.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_beamStepRakecutLeft.length / 2,
          -this.geo_beamStepRakecutLeft.height / 2,
          -this.geo_beamStepRakecutLeft.width / 2
        );

      this.geo_beamStepRakecutRight = this.geometryManager.getBeam();
      this.geo_beamStepRakecutRight.geometry
        .rotateY(Math.PI / 2)
        .translate(
          -this.geo_beamStepRakecutRight.length / 2,
          -this.geo_beamStepRakecutRight.height / 2,
          -this.geo_beamStepRakecutRight.width / 2
        );

      this.geo_beamEndCap = this.geometryManager.getBeamEndCap();
      this.geo_beamEndCap.geometry.translate(
        0,
        -this.geo_beamEndCap.height / 2,
        0
      );

      this.geo_RafterBeam = this.geometryManager.getRafterBeam();
      this.geo_RafterBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_RafterBeam.length / 2,
          -this.geo_RafterBeam.height / 2,
          this.geo_RafterBeam.width / 2
        );

      this.geo_rafterBeamEndCap = this.geometryManager.getRafterBeamEndCap();
      this.geo_rafterBeamEndCap.geometry.translate(
        0,
        -this.geo_rafterBeamEndCap.height / 2,
        this.geo_rafterBeamEndCap.length / 2
      );

      this.postInfo = this.geometryManager.getPost();
      this.postInfo.geometry
        .rotateX(Math.PI / 2)
        .translate(0, this.postInfo.height / 2, 0);
      //.scale(1, scalePostHeight, 1);

      this.geo_centerSuperiorPost = this.geometryManager.getCenterPost();
      this.geo_centerSuperiorPost.geometry
        .rotateX(Math.PI / 2)
        .translate(
          this.geo_centerSuperiorPost.width / 2,
          this.geo_centerSuperiorPost.height / 2,
          this.geo_centerSuperiorPost.width / 2
        );

      this.geo_groundBase = new GeometryInfo();
      this.geo_groundBase.width = 1000;
      this.geo_groundBase.height = 1;
      this.geo_groundBase.length = 1000;
      this.geo_groundBase.geometry = new BoxBufferGeometry(
        this.geo_groundBase.width,
        this.geo_groundBase.height,
        this.geo_groundBase.length
      );
      this.geo_groundBase.geometry.translate(
        this.geo_groundBase.width / 2,
        0,
        this.geo_groundBase.length / 2
      );

      this.geo_downPipe = this.geometryManager.getDownPipe();
      this.geo_downPipeL = this.geometryManager.getDownPipeL();

      this.scene.remove(
        ...this.scene.children.filter(
          (c) => c.userData.type == GEOMETRY_TYPE.DOWNPIPE
        )
      );
      this.downpipeGroup = new Group();
      this.downpipeGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeGroup);

      this.downpipeStepRakeCutGroupLeft = new Group();
      this.downpipeStepRakeCutGroupLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupLeft);

      this.downpipeStepRakeCutGroupRight = new Group();
      this.downpipeStepRakeCutGroupRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupRight);

      this.geoBraket_1 = this.geometryManager.getUpstandBraket(1);
      this.geoBraket_1.geometry.rotateX(Math.PI / 2);
      this.geoBraket_1.geometry.translate(0, this.geoBraket_1.length / 2, 0);
      this.geoBraket_2 = this.geometryManager.getUpstandBraket(2);
      this.geoBraket_2.geometry.rotateY(Math.PI);
      this.geoBraket_2.geometry.rotateX(Math.PI / 2);
      this.geoBraket_2.geometry.translate(
        0,
        this.geoBraket_2.length / 2,
        -this.geoBraket_2.height / 4
      );

      this.geoBraketExtL_2 = this.geometryManager.getUpstandBraket(2);
      this.geoBraketExtL_2.geometry.rotateX(Math.PI / 2);
      this.geoBraketExtL_2.geometry.rotateY(-Math.PI / 2);
      this.geoBraketExtL_2.geometry.rotateZ(-Math.PI);
      this.geoBraketExtL_2.geometry.translate(
        -this.geoBraketExtL_2.height / 2,
        this.geoBraketExtL_2.length / 2,
        this.geoBraketExtL_2.height / 3 - this.geoBraket_1.width / 2
      );
      //Right
      this.geoBraketExtR_2 = this.geometryManager.getUpstandBraket(2);
      this.geoBraketExtR_2.geometry.rotateX(Math.PI / 2);
      this.geoBraketExtR_2.geometry.rotateY(Math.PI / 2);
      this.geoBraketExtR_2.geometry.rotateZ(-Math.PI);
      this.geoBraketExtR_2.geometry.translate(
        this.geoBraketExtR_2.height / 2,
        this.geoBraketExtR_2.length / 2,
        this.geoBraketExtR_2.height / 3 - this.geoBraket_1.width / 2
      );

      this.geoBeam = this.geometryManager.getBeam();
      this.geoBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(0, this.geoBeam.height / 2, 0);

      this.geoBeamEndCap = this.geometryManager.getBeamEndCap();
      this.geoBeamEndCap.geometry
        .translate(
          0,
          this.geoBeamEndCap.height / 2,
          this.geoBeamEndCap.length / 2
        )
        .rotateY(Math.PI / 2);

      resolve();
    });
  }

  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_POST
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_CENTER_POST
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (o) => o.userData.type == GEOMETRY_TYPE.GROUND_BASE
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (o) => o.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_EXT
        )
      );
      this.downpipeGroup.children = [];
      this.downpipeStepRakeCutGroupLeft.children = [];
      this.downpipeStepRakeCutGroupRight.children = [];

      this.beamLeftCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.LEFT
      );
      this.beamRightCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.RIGHT
      );

      this.totalBaySize = this.APP.dialogEditBay.listBay.reduce(
        (total, val) => total + val.value,
        0
      );
      this.roofWidth = this.totalBaySize;
      this.existingType = +this.APP.sltExistingType.currentValue;
      this.existingTypeLeftX = 0;
      this.existingTypeRightX = 0;
      if (this.existingType === 1 || this.existingType === 3) {
        this.existingTypeLeftX = this.APP.sldEaveWidth.currentValue;
      }
      if (this.existingType === 2 || this.existingType === 3) {
        this.existingTypeRightX = this.APP.sldEaveWidth.currentValue;
      }
      this.offsetYBraket = 0;
      if (UI.upstandBraketType == 150) {
        this.offsetYBraket =
          150 +
          this.beamInfo.height +
          (this.geoBraket_2.height / 4 + this.geoBraket_1.width) *
            this.utils.tan(UI.patiosPitch);
      } else if (UI.upstandBraketType == 300) {
        this.offsetYBraket =
          300 +
          this.beamInfo.height +
          (this.geoBraket_2.height / 4 + this.geoBraket_1.width) *
            this.utils.tan(UI.patiosPitch);
      } else if (UI.upstandBraketType == 450) {
        this.offsetYBraket =
          450 +
          this.beamInfo.height +
          (this.geoBraket_2.height / 4 + this.geoBraket_1.width) *
            this.utils.tan(UI.patiosPitch);
      } else if (UI.upstandBraketType == 600) {
        this.offsetYBraket =
          600 +
          this.beamInfo.height +
          (this.geoBraket_2.height / 4 + this.geoBraket_1.width) *
            this.utils.tan(UI.patiosPitch);
      }
      if (UI.isUpFasciaUpstandardBracket) {
        this.offsetYBraket -=
          (this.geoBraket_2.height / 4 + this.geoBraket_1.width) *
            this.utils.tan(UI.patiosPitch) +
          (this.geoBraket_2.height / 4) * this.utils.tan(UI.patiosPitch);
      }

      this.addBeam({ isMultiSpan: false });
      this.addBeam({ isMultiSpan: true });

      this.addGround();
      let offsetX = -(this.totalBaySize / 2) + this.postInfo.width / 2;
      let offsetXOrigin = -(this.totalBaySize / 2) + this.postInfo.width / 2;
      let idx = 0;

      this.addPost(offsetX, { isMultiSpan: false, left: true });
      this.addUpstandBraketExt({ isMultiSpan: false, left: true });

      this.addPost(offsetX, { isMultiSpan: true, left: true });
      this.addUpstandBraketExt({ isMultiSpan: true, left: true });

      this.addCenterSuperiorBeam();

      this.addHorSuperiorBeam(offsetX, {
        front: true,
        first: true,
        last: false,
      });
      this.addCenterPost(offsetX, { center: true, first: true, last: false });

      this.addRafterSuperiorBeam(offsetX - this.beamInfo.width / 2, {
        front: true,
        first: true,
        last: false,
      });
      this.addRafterSuperiorBeam(offsetX - this.beamInfo.width / 2, {
        back: true,
        first: true,
        last: false,
      });

      this.APP.dialogEditBay.listBay.map((m) => {
        offsetX += m.value;
        this.addHorSuperiorBeam(
          offsetX -
            (idx === this.APP.dialogEditBay.listBay.length - 1
              ? this.geoBeam.width
              : this.geoBeam.width / 2),
          {
            front: true,
            first: false,
            last: idx === this.APP.dialogEditBay.listBay.length - 1,
          }
        );

        this.addCenterPost(offsetX, {
          center: true,
          first: false,
          last: idx === this.APP.dialogEditBay.listBay.length - 1,
        });

        this.addRafterSuperiorBeam(
          offsetX -
            (idx === this.APP.dialogEditBay.listBay.length - 1
              ? this.beamInfo.width * 1.5
              : this.beamInfo.width),
          {
            front: true,
            first: false,
            last: idx === this.APP.dialogEditBay.listBay.length - 1,
          }
        );
        this.addRafterSuperiorBeam(
          offsetX -
            (idx === this.APP.dialogEditBay.listBay.length - 1
              ? this.beamInfo.width * 1.5
              : this.beamInfo.width),
          {
            back: true,
            first: false,
            last: idx === this.APP.dialogEditBay.listBay.length - 1,
          }
        );

        if (idx === this.APP.dialogEditBay.listBay.length - 1) {
          offsetX -= this.postInfo.width;
          this.addPost(offsetX, { isMultiSpan: false, right: true });
          this.addPost(offsetX, { isMultiSpan: true, right: true });
        } else {
          this.addPost(offsetX - this.postInfo.width / 2, {
            isMultiSpan: false,
          });
          this.addPost(offsetX - this.postInfo.width / 2, {
            isMultiSpan: true,
          });
        }
        idx++;
      });

      this.addUpstandBraketExt({ isMultiSpan: false, right: true });
      this.addUpstandBraketExt({ isMultiSpan: true, right: true });

      this.addDownPipe();

      this.addUpstandBeam();

      this.addUpstandBraket();
      this.addUpstandBraketCutOut();
      this.showBeamOutline()
      this.updateUI();
      resolve();
    });
  }
  public showBeamOutline() {
    this.APP.scene.remove(
      ...this.APP.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.BEAM_OUTLINE
      )
    );

    if (!UI.beamLayoutShow) return;

    const objs = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    const meshes = []
    objs.forEach(el => {
      if(el.type == 'Group') {
        meshes.push(...el.children.filter((o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM))
      }
    })

    for (let o of meshes) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        10
      );
      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);

      var line = new LineSegments(
        outlineGeo,
        MaterialManager.Instance().BEAM_OUTLINE
      );
      line.userData = { type: GEOMETRY_TYPE.BEAM_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  public addUpstandBeam() {
    if (UI.upstandBraketType == 0 || UI.listBay.length == 0) {
      return;
    }
    let height = +UI.upstandBraketType + this.beamInfo.height;
    let pos = new Vector3(
      - UI.totalBayLength / 2 - UI.overhangLeft,
      UI.existingWallHeight + height,
      UI.eaveWidth +
        this.geoBraket_2.height / 4 +
        this.geoBraket_1.width / 2 -
        UI.existingWidth1 / 2
    );
    let totalLength = UI.totalBayLength + UI.overhangLeft + UI.overhangRight;
    if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
      if (UI.existingType == BUILDING_SIDE.LEFT || UI.existingType == BUILDING_SIDE.BOTH) {
        totalLength -= UI.existingLength2;
        pos.setX(pos.x + UI.existingLength2);
      }
      if (UI.existingType == BUILDING_SIDE.RIGHT || UI.existingType == BUILDING_SIDE.BOTH) {
        totalLength -= UI.existingLength2;
      }
    }
    let views: Print2DView[] = [
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    this.cutStandardBeamToEqualLengthBeam(
      this.beamInfo,
      this.geo_beamEndCap,
      totalLength,
      pos,
      new Vector3(),
      views,
      1,
      { back: true },
      0,
      "x",
      'EB',
      this.geoBeamJoint
    )
    // Beam cutout
    if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
      let offsetHeightCutout = +UI.upstandBraketType + this.beamInfo.height;
      if (UI.isUpFasciaUpstandardBracket) {
        offsetHeightCutout +=
          (UI.existingWidth1 + this.geoBraket_2.height / 4) *
          this.utils.tan(UI.patiosPitch);
      } else {
        offsetHeightCutout -=
          (UI.existingWidth1 + this.geoBraket_2.height / 4) *
          this.utils.tan(UI.patiosPitch);
      }

      if (offsetHeightCutout < 0) {
        return;
      }
      if (UI.existingType == 1 || UI.existingType == 3) {
        this.cutStandardBeamToEqualLengthBeam(
          this.beamInfo,
          this.geo_beamEndCap,
          UI.existingLength2,
          new Vector3(
            -UI.totalBayLength / 2 - UI.overhangLeft,
            UI.existingWallHeight + offsetHeightCutout,
            UI.eaveWidth +
              this.geoBraket_2.height / 4 +
              this.geoBraket_1.width / 2 +
              UI.existingWidth1 / 2
          ),
          new Vector3(),
          views,
          1,
          { back: true },
          0,
          "x",
          'EB',
          this.geoBeamJoint
        );
      }
      if (UI.existingType == 2 || UI.existingType == 3) {
        this.cutStandardBeamToEqualLengthBeam(
          this.beamInfo,
          this.geo_beamEndCap,
          UI.existingLength2,
          new Vector3(
            UI.totalBayLength / 2 - UI.existingLength2 + UI.overhangRight,
            UI.existingWallHeight + offsetHeightCutout,
            UI.eaveWidth +
              this.geoBraket_2.height / 4 +
              this.geoBraket_1.width / 2 +
              UI.existingWidth1 / 2
          ),
          new Vector3(),
          views,
          1,
          { back: true },
          0,
          "x",
          'EB',
          this.geoBeamJoint
        );
      }
    }
  }
  public addUpstandBraket(numOfBraket = 2) {
    this.scene.remove(
      ...this.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET
      )
    );
    if (UI.upstandBraketType == 0 || UI.listBay.length == 0) {
      return;
    }
    let height = +UI.upstandBraketType;

    const _bracketOffsetXExt = this.geoBraket_2.height / 5;
    let distance = 1000;
    let offsetX = -UI.existingLength1 / 2;
    let bayMainLength = UI.totalBayLength;

    if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
      if (UI.existingType == 1 || UI.existingType == 2) {
        bayMainLength -= UI.existingLength2;
      }
      if (UI.existingType == 3) {
        bayMainLength -= 2 * UI.existingLength2;
      }
    }

    if (UI.existingType == 0) {
      let startX =
        -bayMainLength / 2 > -UI.existingLength1 / 2
          ? -bayMainLength / 2
          : -UI.existingLength1 / 2;
      let endX =
        bayMainLength / 2 < UI.existingLength1 / 2
          ? bayMainLength / 2
          : UI.existingLength1 / 2;
      distance = (endX - startX) / (numOfBraket - 1);
      offsetX = startX;
    } else if (UI.existingType == 1) {
      distance = (bayMainLength - _bracketOffsetXExt) / (numOfBraket - 1);
      offsetX = -bayMainLength / 2 + _bracketOffsetXExt;
      if (bayMainLength > UI.existingLength1 - UI.eaveWidth) {
        distance =
          (UI.existingLength1 - UI.eaveWidth - _bracketOffsetXExt) /
          (numOfBraket - 1);
      }
      if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
        offsetX =
          -bayMainLength / 2 + UI.existingLength2 / 2 + _bracketOffsetXExt;
      }
    } else if (UI.existingType == 2) {
      offsetX = -bayMainLength / 2;
      distance = (bayMainLength - _bracketOffsetXExt) / (numOfBraket - 1);

      if (bayMainLength > UI.existingLength1 - UI.eaveWidth) {
        offsetX =
          -bayMainLength / 2 +
          (bayMainLength - UI.existingLength1 + UI.eaveWidth);
        distance =
          (UI.existingLength1 - _bracketOffsetXExt - UI.eaveWidth) /
          (numOfBraket - 1);
      }

      if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
        offsetX -= UI.existingLength2 / 2;
      }
    } else if (UI.existingType == 3) {
      distance = (bayMainLength - _bracketOffsetXExt * 2) / (numOfBraket - 1);
      offsetX = -bayMainLength / 2 + _bracketOffsetXExt;
    }

    let optionBrakets: UpstandBraketOptions = {
      container: this.scene,
      position: new Vector3(),
      geoBraket_1: this.geoBraket_1,
      geoBraket_2: this.geoBraket_2,
      height: height,
      material: MaterialManager.Instance().FASCIA_BRACKET.clone(),
    };
    let views: Print2DView[] = [
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    let userData = {
      type: GEOMETRY_TYPE.UPSTAND_BRAKET,
      views: views,
    };
    for (let i = 0; i < numOfBraket; i++) {
      let posX = offsetX;
      if (i == 0) {
        posX = posX + this.geoBraket_1.width / 2;
      } else if (i == numOfBraket - 1) {
        posX = posX - this.geoBraket_1.width / 2;
      }
      new UpstandBraket({
        ...optionBrakets,
        position: new Vector3(
          posX,
          UI.existingWallHeight,
          UI.eaveWidth - UI.existingWidth1 / 2
        ),
        userData: userData,
      });
      offsetX += distance;
    }
  }

  public addUpstandBraketCutOut(numOfBraket = 2) {
    this.scene.remove(
      ...this.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT
      )
    );
    if (HomeComponent.ins.sltCutOut._isDisable || UI.cutOutType == 0) {
      return;
    }
    if (UI.upstandBraketType == 0 || UI.listBay.length == 0) {
      return;
    }
    let height = +UI.upstandBraketType;
    // if (UI.isUpFasciaUpstandardBracket) {
    //     height += (UI.existingWidth1 + this.geoBraket_2.height / 4) * this.utils.tan(UI.patiosPitch);
    // } else {
    //     height -= (UI.existingWidth1 + this.geoBraket_2.height / 4) * this.utils.tan(UI.patiosPitch);
    // }

    if (height < 0) {
      return;
    }
    const _bracketOffsetXExt = this.geoBraket_2.height / 5;
    if (UI.existingType == 1 || UI.existingType == 3) {
      let _offsetZ = UI.existingWidth2 > 0 ? UI.overhangLeft : 0;
      let distance =
        (UI.existingLength2 - UI.eaveWidth - UI.overhangLeft + _offsetZ) /
        (numOfBraket - 1);
      let offsetZ = -UI.totalBayLength / 2 - _offsetZ + _bracketOffsetXExt;

      let optionBrakets: UpstandBraketOptions = {
        container: this.scene,
        position: new Vector3(),
        geoBraket_1: this.geoBraket_1,
        geoBraket_2: this.geoBraket_2,
        height: height,
        material: MaterialManager.Instance().FASCIA_BRACKET.clone(),
      };
      let views: Print2DView[] = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
      let userData = {
        type: GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT,
        views: views,
      };
      for (let i = 0; i < numOfBraket; i++) {
        let posX = offsetZ;
        if (i == 0) {
          posX = posX + this.geoBraket_1.width / 2;
        } else if (i == numOfBraket - 1) {
          posX = posX - this.geoBraket_1.width / 2;
        }
        new UpstandBraket({
          ...optionBrakets,
          position: new Vector3(
            posX,
            UI.existingWallHeight,
            UI.eaveWidth + UI.existingWidth1 / 2
          ),
          userData: userData,
        });
        offsetZ += distance;
      }
    }
    if (UI.existingType == 2 || UI.existingType == 3) {
      let _offsetZ = UI.existingWidth2 > 0 ? UI.overhangRight : 0;
      let distance =
        (UI.existingLength2 - (UI.overhangRight + UI.eaveWidth) + _offsetZ) /
        (numOfBraket - 1);
      let offsetZ =
        UI.totalBayLength / 2 -
        UI.existingLength2 +
        UI.eaveWidth +
        UI.overhangRight -
        _bracketOffsetXExt;
      let optionBrakets: UpstandBraketOptions = {
        container: this.scene,
        position: new Vector3(),
        geoBraket_1: this.geoBraket_1,
        geoBraket_2: this.geoBraket_2,
        height: height,
        material: MaterialManager.Instance().FASCIA_BRACKET.clone(),
      };
      let views: Print2DView[] = [
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
      let userData = {
        type: GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT,
        views: views,
      };
      for (let i = 0; i < numOfBraket; i++) {
        let posX = offsetZ;
        if (i == 0) {
          posX = posX + this.geoBraket_1.width / 2;
        } else if (i == numOfBraket - 1) {
          posX = posX - this.geoBraket_1.width / 2;
        }
        new UpstandBraket({
          ...optionBrakets,
          position: new Vector3(
            posX,
            UI.existingWallHeight,
            UI.eaveWidth + UI.existingWidth1 / 2
          ),
          userData: userData,
        });
        offsetZ += distance;
      }
    }
  }
  private addDownPipe() {
    let offsetZ =
      UI.span +
      UI.multiSpan +
      UI.eaveWidth +
      100 -
      UI.existingWidth1 / 2 +
      this.geoBraket_2.height / 4;
    let offsetX = -this.totalBaySize / 2 + 50;
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      offsetX = this.totalBaySize / 2 - 50;
    }

    this.downpipeGroup.position.set(offsetX, 0, offsetZ);

    let offsetY =
      this.utils.getHeightByAngle(
        UI.eaveHeight + +UI.upstandBraketType + this.geoBeam.height,
        UI.overhangFront,
        this.APP.sltRoofPitch.currentValue,
        -1
      ) + 10;

    this.downpipeGroup = this.utils.createDownpipeGroup(
      this.APP,
      this.downpipeGroup,
      this.geo_downPipe,
      this.geo_downPipeL,
      offsetX,
      0,
      offsetZ,
      offsetZ,
      0,
      this.APP.sldFrontOverhang.currentValue,
      offsetY,
      true
    );
  }
  private addGround() {
    let offsetX = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );
    let offsetZ =
      -this.APP.sldExistingWidth1.currentValue / 2 - UI.overhangBack;
    let width =
      this.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    let length =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue +
      this.APP.sldBackOverhang.currentValue +
      this.APP.sldEaveWidth.currentValue +
      this.geoBraket_2.height / 4;

    let base = new Mesh(
      this.geo_groundBase.geometry,
      MaterialManager.Instance().BASE
    );
    base.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.GROUND_BASE,
    };
    base.position.set(offsetX, 0, offsetZ);
    base.scale.set(
      width / this.geo_groundBase.width,
      1,
      length / this.geo_groundBase.length
    );

    this.scene.add(base);
  }
  private getRoofHeight() {
    const _height = this.utils.getHeightByAngle(
      this.APP.sldExistingWallHeight.currentValue,
      this.APP.sldSpan.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldFrontOverhang.currentValue,
      this.APP.sltRoofPitch.currentValue,
      -1
    );
    return this.APP.sldExistingWallHeight.currentValue - _height;
  }
  private updateUI() {
    this.APP.roofHeight = this.getRoofHeight();
    this.APP.sldMinHeight.setValue(_.round(this.frontPostHeight, 2));
  }
  public getSection(): Printing2DGeometry {
    let objs = this.scene.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_POST ||
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_CENTER_POST
    );

    objs = [...objs];

    let lsGeometries: Printing2DLine[] = [];

    for (let o of objs) {
      let box = new BoxHelper(o);
      box.geometry.translate(0, 5000, 0);
      box.userData = { type: "COLUMN_OUTLINE" };

      let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: outlineGeo.vertices,
        views: o.userData.views,
      });
    }
    let beamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    for (let g of beamGroups) {
      for (let c of g.children) {
        c.updateMatrix();

        let objCloned = c.clone();
        objCloned.applyMatrix4(new Matrix4().getInverse(c.matrix));

        let box = new BoxHelper(objCloned);

        c.updateWorldMatrix(true, true);

        box.geometry.applyMatrix4(c.matrixWorld);
        box.geometry.translate(0, 5000, 0);
        box.userData = { type: "COLUMN_OUTLINE" };

        let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
        const planView = _.find(g.userData.views, (el) => el.viewType == ViewType.PLAN)
        const anotherViews = _.filter(g.userData.views, (el) => el.viewType != ViewType.PLAN)
        if(anotherViews.length > 0) {
          lsGeometries.push({
            objectType: g.userData.type,
            vertices: outlineGeo.vertices,
            views: anotherViews,
          });
        } 
        if(planView) {
          lsGeometries.push({
            objectType: g.userData.type,
            vertices: outlineGeo.vertices.slice(0, 8).filter((el, index) => [0, 1, 4, 5].includes(index)),
            views: [planView],
          });
        }
      }
    }

    let braketGroup = this.scene.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET ||
        o.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT
    );

    for (let g of braketGroup) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        g.children[1] as Mesh,
        60
      );
      g.children[1].updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(g.children[1].matrixWorld);
      outlineGeo.translate(0, 5000, 0);

      let simplifiedGeo = this.simplifyGeo(outlineGeo);
      lsGeometries.push({
        objectType: g.userData.type,
        vertices: simplifiedGeo.vertices,
        views: g.userData.views,
      });

      g.children[0].updateMatrix();
      let objCloned = g.children[0].clone();
      objCloned.applyMatrix4(new Matrix4().getInverse(g.children[0].matrix));

      let box = new BoxHelper(objCloned);

      g.children[0].updateWorldMatrix(true, true);

      box.geometry.applyMatrix4(g.children[0].matrixWorld);
      box.geometry.translate(0, 5000, 0);
      box.userData = { type: "COLUMN_OUTLINE" };
      //this.APP.scene.add(box);

      let outlineGeo1 = this.simplifyGeo(box.geometry as BufferGeometry);
      lsGeometries.push({
        objectType: g.userData.type,
        vertices: outlineGeo1.vertices,
        views: g.userData.views,
      });
    }

    let rafterBeamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
    );
    for (let o of rafterBeamGroups) {
      o.children.forEach((el) => {
        this.utils.getOutlineRafterBeam(el as Mesh, lsGeometries);
      });
    }

    return { lines: lsGeometries, texts: [] };
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let vertices = geo.getAttribute("position").array;
    let lineGeo = new Geometry();
    for (let i = 0; i < vertices.length; i += 3) {
      lineGeo.vertices.push(
        new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2])
      );
    }

    //2-3
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    //3-7
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    //7-6
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    //6-2
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );

    //1-4
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    //4-8
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    //8-5
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    //5-1
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );

    return lineGeo;
  }
  private addCenterPost(offsetX, userDataPos) {
    let meshPost = new Mesh(
      this.geo_centerSuperiorPost.geometry,
      MaterialManager.Instance().BEAM
    );
    meshPost.userData = {
      ...userDataPos,
      type: GEOMETRY_TYPE.SUPERIOR_CENTER_POST,
      category: GEOMETRY_CATEGORY.PATIOS,
    };

    let scalePostY = 1;

    let views: Print2DView[];

    let postOffsetZ =
      (UI.span + UI.multiSpan) / 2 -
      this.geo_centerSuperiorPost.width / 2 -
      UI.existingWidth1 / 2 +
      UI.eaveWidth +
      this.geoBraket_2.height / 4;

    this.frontPostHeight =
      UI.existingWallHeight + +UI.upstandBraketType + this.geoBeam.height;

    scalePostY =
      (((UI.span + UI.multiSpan) / 2 - this.geoBeam.width / 2) *
        this.utils.tan(UI.patiosPitch) -
        this.geoBeam.height) /
      this.geo_centerSuperiorPost.height;
    views = [
      // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    let postOffsetX = offsetX - this.geo_centerSuperiorPost.width;

    if (userDataPos.first) {
      postOffsetX += this.geo_centerSuperiorPost.width / 2;
    } else if (userDataPos.last) {
      postOffsetX -= this.geo_centerSuperiorPost.width / 2;
    } else {
    }

    // if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT){
    //     let offsetX = this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + 100 - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
    //     postOffsetX -= offsetX
    // }
    // if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT){
    //     let offsetX = this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + 100 - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
    //     postOffsetX += offsetX
    // }

    meshPost.userData.views = views;
    meshPost.position.set(
      postOffsetX,
      UI.existingWallHeight + +UI.upstandBraketType + this.geoBeam.height,
      postOffsetZ
    );
    meshPost.scale.setY(scalePostY);

    this.scene.add(meshPost);
  }
  public addPost(offsetX: number, userDataPos: any) {
    if (userDataPos.isMultiSpan && this.APP.sldMultiSpan.currentValue === 0) {
      return;
    }
    // // let _flag = false;
    // if (userDataPos.left && this.APP.sldLeftOverhang.currentValue > 0 && this.APP.sltExistingType.currentValue == 1) {
    //     // _flag = true;
    //     return;
    // } else if (userDataPos.right && this.APP.sldRightOverhang.currentValue > 0 && this.APP.sltExistingType.currentValue == 2) {
    //     // _flag = true;
    //     return;
    // }
    const condition =
      this.APP.sldSpan.currentValue + this.APP.sldEaveWidth.currentValue;
    if (!userDataPos.isMultiSpan) {
      if (
        (this.existingType === 1 || this.existingType === 3) &&
        userDataPos.left &&
        (UI.existingWidth1 >= condition ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition &&
            UI.cutOutType == 1))
      ) {
        return;
      }
      if (
        (this.existingType === 2 || this.existingType === 3) &&
        userDataPos.right &&
        (UI.existingWidth1 >= condition ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition &&
            UI.cutOutType == 1))
      ) {
        return;
      }
    } else {
      if (
        (this.existingType === 1 || this.existingType === 3) &&
        userDataPos.left &&
        (UI.existingWidth1 >= condition + UI.multiSpan ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
            UI.cutOutType == 1))
      ) {
        return;
      }
      if (
        (this.existingType === 2 || this.existingType === 3) &&
        userDataPos.right &&
        (UI.existingWidth1 >= condition + UI.multiSpan ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
            UI.cutOutType == 1))
      ) {
        return;
      }
    }
    let views: Print2DView[] = [];
    if (userDataPos.isMultiSpan) {
      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    const mesh = new Mesh(
      this.postInfo.geometry,
      MaterialManager.Instance().POST
    );
    let offsetZ =
      -this.APP.sldExistingWidth1.currentValue / 2 +
      this.APP.sldEaveWidth.currentValue +
      this.geoBraket_2.height / 4;
    if (userDataPos.isMultiSpan) {
      offsetZ +=
        this.APP.sldSpan.currentValue +
        this.APP.sldMultiSpan.currentValue -
        this.beamInfo.width / 2;
    } else {
      offsetZ +=
        this.APP.sldSpan.currentValue -
        (this.APP.sldMultiSpan.currentValue > 0 ? 0 : this.beamInfo.width / 2);
    }
    let scaleY = 0;
    let length = 0;

    if (userDataPos.isMultiSpan) {
      length = UI.existingWallHeight + +UI.upstandBraketType;
      scaleY = length / this.postInfo.height;
    } else {
      length = UI.existingWallHeight + +UI.upstandBraketType;
      scaleY = length / this.postInfo.height;
    }

    //in case of multi span & apply to back post only
    if (
      !userDataPos.isMultiSpan &&
      this.APP.sltCutOut.currentValue == 1 &&
      this.MANAGER.cutoutCondition
    ) {
      if (
        this.APP.sldSpan.currentValue + this.APP.sldEaveWidth.currentValue >
          this.APP.sldExistingWidth1.currentValue &&
        this.APP.sldSpan.currentValue + this.APP.sldEaveWidth.currentValue <
          this.APP.sldExistingWidth1.currentValue +
            this.APP.sldEaveWidth.currentValue
      ) {
        if (this.APP.sltExistingType.currentValue == 1 && userDataPos.left) {
          offsetX += this.APP.sldExistingLength2.currentValue;
        } else if (
          this.APP.sltExistingType.currentValue == 2 &&
          userDataPos.right
        ) {
          offsetX -= this.APP.sldExistingLength2.currentValue;
        } else if (this.APP.sltExistingType.currentValue == 3) {
          if (userDataPos.left) {
            offsetX += this.APP.sldExistingLength2.currentValue;
          }
          if (userDataPos.right) {
            offsetX -= this.APP.sldExistingLength2.currentValue;
          }
        }
      }
    }

    let exceedMoveBackLimit = false;

    mesh.position.set(offsetX, 0, offsetZ);
    mesh.scale.setY(scaleY);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_POST,
      position: userDataPos,
      views: views,
    };
    this.scene.add(mesh);
  }

  private addUpstandBraketExt(userDataPos: any) {
    if (
      UI.upstandBraketType == 0 ||
      UI.listBay.length == 0 ||
      UI.existingType == 0 ||
      (userDataPos.isMultiSpan && UI.multiSpan == 0)
    ) {
      return;
    }
    if (
      (this.existingType == 1 && userDataPos.right) ||
      (this.existingType == 2 && userDataPos.left)
    ) {
      return;
    }
    let _flag = false;

    const condition =
      this.APP.sldSpan.currentValue + this.APP.sldEaveWidth.currentValue;
    if (!userDataPos.isMultiSpan && !_flag) {
      if (
        (this.existingType === 1 || this.existingType === 3) &&
        userDataPos.left &&
        (UI.existingWidth1 >= condition ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition &&
            UI.cutOutType == 1))
      ) {
        _flag = true;
        // return;
      }
      if (
        (this.existingType === 2 || this.existingType === 3) &&
        userDataPos.right &&
        (UI.existingWidth1 >= condition ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition &&
            UI.cutOutType == 1))
      ) {
        _flag = true;
        // return;
      }
    } else if (!_flag) {
      if (
        (this.existingType === 1 || this.existingType === 3) &&
        userDataPos.left &&
        (UI.existingWidth1 >= condition + UI.multiSpan ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
            UI.cutOutType == 1))
      ) {
        _flag = true;
        // return;
      }
      if (
        (this.existingType === 2 || this.existingType === 3) &&
        userDataPos.right &&
        (UI.existingWidth1 >= condition + UI.multiSpan ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
            UI.cutOutType == 1))
      ) {
        _flag = true;
        // return;
      }
    }
    if (!_flag) {
      return;
    }
    // if (userDataPos.left && UI.overhangLeft > 0 || userDataPos.right && UI.overhangRight > 0)
    //     return;

    const _bracketOffsetXExt = this.geoBraket_2.height / 5; // + this.geoBraket_1.width / 4;
    let height = 0;
    let offsetX = -UI.existingLength1 / 2;
    let bayMainLength = UI.totalBayLength;
    if (!AppComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
      if (UI.existingType == 1 || UI.existingType == 2) {
        bayMainLength -= UI.existingLength2;
      }
      if (UI.existingType == 3) {
        bayMainLength -= 2 * UI.existingLength2;
      }
    }
    const _cond1 =
      UI.existingWidth1 < condition &&
      UI.existingWidth1 + UI.existingWidth2 > condition;
    const _cond2 =
      userDataPos.isMultiSpan &&
      UI.existingWidth1 < condition + UI.multiSpan &&
      UI.existingWidth1 + UI.existingWidth2 > condition + UI.multiSpan;

    if (UI.existingType == 1) {
      offsetX = -bayMainLength / 2;
      if (!AppComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
        offsetX = -bayMainLength / 2 + UI.existingLength2 / 2;
      }
      if (
        UI.existingWidth1 < condition ||
        (userDataPos.isMultiSpan &&
          UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan)
      ) {
        offsetX -= UI.existingLength2;
      }
      offsetX += _bracketOffsetXExt;
    } else if (UI.existingType == 2) {
      offsetX = bayMainLength / 2 - this.geoBraket_1.width;
      if (!AppComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
        offsetX -= UI.existingLength2 / 2;
      }
      if (
        UI.existingWidth1 < condition ||
        (userDataPos.isMultiSpan &&
          UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan)
      ) {
        offsetX += UI.existingLength2;
      }
      offsetX -= _bracketOffsetXExt;
    } else if (UI.existingType == 3) {
      offsetX =
        (-bayMainLength / 2) * (userDataPos.left ? 1 : -1) -
        (userDataPos.right ? this.geoBraket_1.width : 0);
      if ((!userDataPos.isMultiSpan && _cond1) || _cond2) {
        offsetX -=
          UI.existingLength2 * (userDataPos.left ? 1 : -1) +
          (userDataPos.right ? this.geoBraket_1.width : 0);
      }
      offsetX += _bracketOffsetXExt * (userDataPos.left ? 1 : -1);
    }
    let offsetZ =
      -this.APP.sldExistingWidth1.currentValue / 2 +
      this.APP.sldEaveWidth.currentValue -
      this.geoBraket_1.width / 2;
    if (userDataPos.isMultiSpan) {
      offsetZ += UI.span + UI.multiSpan - this.beamInfo.width / 2;
    } else {
      offsetZ += UI.span - (UI.multiSpan > 0 ? 0 : this.beamInfo.width / 2);
    }
    if (userDataPos.isMultiSpan) {
      height = +UI.upstandBraketType;
    } else {
      height = +UI.upstandBraketType;
    }
    let optionBrakets: UpstandBraketOptions = {
      container: this.scene,
      position: new Vector3(),
      geoBraket_1: this.geoBraket_1,
      geoBraket_2:
        UI.existingType == 2 ? this.geoBraketExtR_2 : this.geoBraketExtL_2,
      height: height,
      material: MaterialManager.Instance().FASCIA_BRACKET.clone(),
    };
    if (UI.existingType == 1) {
      optionBrakets = { ...optionBrakets, geoBraket_2: this.geoBraketExtL_2 };
    } else if (UI.existingType == 2) {
      optionBrakets = { ...optionBrakets, geoBraket_2: this.geoBraketExtR_2 };
    } else {
      optionBrakets = {
        ...optionBrakets,
        geoBraket_2: userDataPos.right
          ? this.geoBraketExtR_2
          : this.geoBraketExtL_2,
      };
    }
    new UpstandBraket({
      ...optionBrakets,
      position: new Vector3(offsetX, UI.existingWallHeight, offsetZ),
      userData: { type: GEOMETRY_TYPE.UPSTAND_BRAKET_EXT },
    });
  }

  public addBeam(userDataPos: any) {
    if (userDataPos.isMultiSpan && this.APP.sldMultiSpan.currentValue === 0) {
      return;
    }

    let offsetY = 0;
    let offsetZ =
      -UI.existingWidth1 / 2 + UI.eaveWidth + this.geoBraket_2.height / 4;
    let offsetXL = -(this.totalBaySize / 2 + UI.overhangLeft);
    let offsetXR = this.totalBaySize / 2;

    let beamLength = this.roofWidth + UI.overhangLeft + UI.overhangRight;

    const condition = UI.span + UI.eaveWidth;
    let _flag = false;
    if (
      (userDataPos.isMultiSpan &&
        this.APP.sldExistingWidth1.currentValue >=
          condition + this.APP.sldMultiSpan.currentValue) ||
      (!userDataPos.isMultiSpan &&
        this.APP.sldExistingWidth1.currentValue >= condition)
    ) {
      _flag = true;
    }
    if (userDataPos.isMultiSpan) {
      offsetY =
        UI.existingWallHeight + +UI.upstandBraketType + this.geoBeam.height;
      offsetZ +=
        this.APP.sldSpan.currentValue +
        this.APP.sldMultiSpan.currentValue -
        this.beamInfo.width / 2;
    } else {
      offsetY =
        UI.existingWallHeight + +UI.upstandBraketType + this.geoBeam.height;
      offsetZ +=
        this.APP.sldSpan.currentValue -
        (this.APP.sldMultiSpan.currentValue > 0 ? 0 : this.beamInfo.width / 2);
    }

    let views: Print2DView[] = [];
    if (userDataPos.isMultiSpan) {
      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    //Cutout
    if (
      !userDataPos.isMultiSpan &&
      this.APP.sltCutOut.currentValue == 1 &&
      this.MANAGER.cutoutCondition
    ) {
      if (
        this.APP.sldSpan.currentValue + this.APP.sldEaveWidth.currentValue <=
        this.APP.sldExistingWidth1.currentValue
      ) {
        if (this.APP.sltExistingType.currentValue == 1) {
          offsetXL += this.APP.sldExistingLength2.currentValue;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue; // + this.APP.sldEaveWidth.currentValue;
        } else if (this.APP.sltExistingType.currentValue == 2) {
          offsetXR -= this.APP.sldExistingLength2.currentValue;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue; // + this.APP.sldEaveWidth.currentValue;
        } else if (this.APP.sltExistingType.currentValue == 3) {
          offsetXL += this.APP.sldExistingLength2.currentValue;
          offsetXR -= this.APP.sldExistingLength2.currentValue;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue * 2; // + this.APP.sldEaveWidth.currentValue;
        }
      } else if (
        this.APP.sldSpan.currentValue + this.APP.sldEaveWidth.currentValue >
          this.APP.sldExistingWidth1.currentValue &&
        this.APP.sldSpan.currentValue + this.APP.sldEaveWidth.currentValue <
          this.APP.sldExistingWidth1.currentValue +
            this.APP.sldEaveWidth.currentValue
      ) {
        if (this.APP.sltExistingType.currentValue == 1) {
          offsetXL += this.APP.sldExistingLength2.currentValue;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue;
        } else if (this.APP.sltExistingType.currentValue == 2) {
          offsetXR -= this.APP.sldExistingLength2.currentValue;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue;
        } else if (this.APP.sltExistingType.currentValue == 3) {
          offsetXL += this.APP.sldExistingLength2.currentValue;
          offsetXR -= this.APP.sldExistingLength2.currentValue;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue * 2;
        }
      }
    }

    this.cutStandardBeamByCutBeamWithinBayControl(
      this.beamInfo,
      this.geo_beamEndCap,
      beamLength,
      new Vector3(offsetXL, offsetY, offsetZ),
      new Vector3(),
      views,
      1,
      userDataPos,
      0,
      "x",
      'EB',
      this.geoBeamJoint
    );
  }
  private cutStandardBeamToEqualLengthBeam(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    beamGeoTranslationZ: number,
    beamDirection: string,
    beamName = '',
    jointGeo: GeometryInfo,
  ){
    const beamStartX = pos.x
    const beamEndX = pos.x + length

    const numOfBeam = Math.ceil(length / EXISTING_BUILDING_CONFIG.MAXIMUM_LENGTH_PER_BEAM);
    const lengthPerBeam = Math.abs(beamEndX - beamStartX) / numOfBeam
    for(let i = 0; i < numOfBeam; i++) {
      let beamGroup = this.utils.createBeamGroup2(
        beamGeo,
        beamCapGeo,
        lengthPerBeam,
        new Vector3(beamStartX + lengthPerBeam * i, pos.y, pos.z),
        rot,
        views,
        directionOffset,
        userDataPos,
        beamGeoTranslationZ,
        beamDirection,
        UI.beamLayoutShow,
        {
          hasStartCap: UI.beamType == 0 && (i == 0),
          hasEndCap: UI.beamType == 0 && (i == numOfBeam - 1 || (i == 0 && numOfBeam == 1)),
          hasStartJoint: i !== 0,
          hasEndJoint: false,
          jointGeo: jointGeo,
        }
      )
      beamGroup.userData = {
        ...beamGroup.userData,
        name: beamName,
      };
      this.scene.add(beamGroup);
    }
  }
  private cutStandardBeamByCutBeamWithinBayControl(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    beamGeoTranslationZ: number,
    beamDirection: string,
    beamName = '',
    jointGeo: GeometryInfo,
  ){
    // Map list cut beam here
    const beamStartX = pos.x
    const beamEndX = pos.x + length

    let endOfBayX = - UI.totalBayLength / 2
    let startCutBeamX = beamStartX
    let endCutBeamX = beamStartX

    const beams = this.utils.getListSeperateBeamsByBays(endOfBayX, beamStartX, beamEndX, endCutBeamX, startCutBeamX)

    for(let i = 0; i < beams.length; i++) {
      endCutBeamX = beams[i][1]
      startCutBeamX = beams[i][0]
      let beamGroup = this.utils.createBeamGroup2(
        beamGeo,
        beamCapGeo,
        endCutBeamX - startCutBeamX,
        new Vector3(startCutBeamX, pos.y, pos.z),
        rot,
        views,
        directionOffset,
        userDataPos,
        beamGeoTranslationZ,
        beamDirection,
        UI.beamLayoutShow,
        {
          hasStartCap: UI.beamType == 0 && (i == 0),
          hasEndCap: UI.beamType == 0 && (i == beams.length - 1 || (i == 0 && beams.length == 1)),
          hasStartJoint: i !== 0,
          hasEndJoint: false,
          jointGeo: jointGeo,
        }
      )
      beamGroup.userData = {
        ...beamGroup.userData,
        name: beamName,
      };
      this.scene.add(beamGroup);
    }
  }
  public addHorSuperiorBeam(offsetX, userDataPos: any) {
    let mesh = new Mesh(this.geoBeam.geometry, MaterialManager.Instance().BEAM);

    let offsetXL = 0;
    let offsetXR = UI.span + UI.multiSpan - this.geoBeam.width * 2;
    let offsetZ = 0;
    let offsetY = 0;

    let scaleX =
      (UI.span + UI.multiSpan - this.geoBeam.width * 2) / this.geoBeam.length;

    let views: Print2DView[];

    if (userDataPos.front) {
      offsetY = UI.existingWallHeight + +UI.upstandBraketType;
      offsetZ =
        UI.span +
        UI.multiSpan -
        this.geoBeam.width -
        UI.existingWidth1 / 2 +
        UI.eaveWidth +
        this.geoBraket_2.height / 4;

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_BEAM,
      position: userDataPos,
      views: views,
    };
    mesh.position.set(offsetXL, 0, 0);
    mesh.scale.setX(scaleX);

    let capL = new Mesh(
      this.geo_beamEndCap.geometry,
      MaterialManager.Instance().BEAM
    );
    capL.position.set(
      offsetXL +
        this.geo_beamEndCap.length / 2 -
        2 -
        (scaleX * this.geoBeam.length) / 2,
      this.geo_beamEndCap.height,
      0
    );
    capL.rotateY(Math.PI / 2);
    capL.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: getBeamEndCapCode( this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode)
    }
    let capR = new Mesh(
      this.geo_beamEndCap.geometry,
      MaterialManager.Instance().BEAM
    );
    capR.position.set(
      offsetXR -
        this.geo_beamEndCap.length / 2 +
        2 -
        (scaleX * this.geoBeam.length) / 2,
      this.geo_beamEndCap.height,
      0
    );
    capR.rotateY(-Math.PI / 2);
    capR.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: getBeamEndCapCode( this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode)
    }

    let beamOffsetX = offsetX;

    let beamGroup = new Group();
    beamGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_BEAM,
      position: userDataPos,
      views: views,
      name: "GB",
    };
    beamGroup.position.set(
      beamOffsetX,
      offsetY,
      offsetZ - (scaleX * this.geoBeam.length) / 2
    );
    if (this.APP.sltBeamType.currentValue == 0) {
      beamGroup.add(mesh, capL, capR);
    } else {
      beamGroup.add(mesh);
    }

    beamGroup.rotateY(Math.PI / 2);

    this.scene.add(beamGroup);
  }
  public addRafterSuperiorBeam(offsetX, userDataPos: any) {
    if (
      this.APP.sltWindClass.currentValue == 0 ||
      this.APP.sltWindClass.currentValue == 1
    ) {
      if (this.totalBaySize <= 6000 && this.APP.sldSpan.currentValue <= 7000) {
        return;
      }
    }
    if (
      this.APP.sltWindClass.currentValue == 3 ||
      this.APP.sltWindClass.currentValue == 4 ||
      this.APP.sltWindClass.currentValue == 5
    ) {
      return;
    }
    let mesh;

    let offsetXL = 0;
    let offsetXR =
      ((UI.span + UI.multiSpan) / 2 - this.beamInfo.width / 2) /
      this.utils.cos(UI.patiosPitch);
    let offsetZ = 0;
    let offsetY = 0;

    let scaleX =
      ((UI.span + UI.multiSpan) / 2 - this.beamInfo.width / 2) /
      this.utils.cos(UI.patiosPitch) /
      this.geo_RafterBeam.length;

    let views: Print2DView[];

    if (userDataPos.front) {
      let material = MaterialManager.Instance().BEAM.clone();

      let nBottom = new Vector3(0, 1, 0).normalize();
      let pBottom = new Vector3(
        0,
        UI.existingWallHeight + +UI.upstandBraketType + this.beamInfo.height,
        0
      );
      let planeBottom = new Plane().setFromNormalAndCoplanarPoint(
        nBottom,
        pBottom
      );

      let nVer = new Vector3(0, 0, 1).normalize();
      let pVer = new Vector3(
        0,
        0,
        (UI.span + UI.multiSpan) / 2 +
          this.beamInfo.width / 2 -
          UI.existingWidth1 / 2 +
          UI.eaveWidth +
          this.geoBraket_2.height / 4
      );
      let planeVer = new Plane().setFromNormalAndCoplanarPoint(nVer, pVer);

      material.clippingPlanes = [planeBottom, planeVer];

      mesh = new Mesh(this.geo_RafterBeam.geometry, material);

      offsetY =
        UI.existingWallHeight + +UI.upstandBraketType + this.beamInfo.height;
      offsetZ =
        UI.span +
        UI.multiSpan -
        UI.existingWidth1 / 2 +
        UI.eaveWidth +
        this.geoBraket_2.height / 4;

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.back) {
      let material = MaterialManager.Instance().BEAM.clone();

      let nBottom = new Vector3(0, 1, 0).normalize();
      let pBottom = new Vector3(
        0,
        UI.existingWallHeight + +UI.upstandBraketType + this.beamInfo.height,
        0
      );
      let planeBottom = new Plane().setFromNormalAndCoplanarPoint(
        nBottom,
        pBottom
      );

      let nVer = new Vector3(0, 0, -1).normalize();
      let pVer = new Vector3(
        0,
        0,
        (UI.span + UI.multiSpan) / 2 -
          this.beamInfo.width / 2 -
          UI.existingWidth1 / 2 +
          UI.eaveWidth +
          this.geoBraket_2.height / 4
      );
      let planeVer = new Plane().setFromNormalAndCoplanarPoint(nVer, pVer);

      material.clippingPlanes = [planeBottom, planeVer];

      mesh = new Mesh(this.geo_RafterBeam.geometry, material);

      offsetY =
        UI.existingWallHeight +
        +UI.upstandBraketType +
        this.beamInfo.height +
        ((UI.span + UI.multiSpan) / 2 - this.beamInfo.width / 2) *
          this.utils.tan(UI.patiosPitch);
      offsetZ =
        (UI.span + UI.multiSpan) / 2 -
        this.beamInfo.width / 2 -
        UI.existingWidth1 / 2 +
        UI.eaveWidth +
        this.geoBraket_2.height / 4;

      views = [
        // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM,
      position: userDataPos,
      views: views,
    };
    mesh.position.set(offsetXL, 0, 0);
    mesh.scale.setX(scaleX);

    let checkExec = /\w{1}\d{1,3}x(\d{1,3})/.exec(this.geo_RafterBeam.name);
    let beamSize = checkExec && checkExec.length == 2 ? checkExec[1] : "";
    let lowerBraketName = getRafterBeamEndCapCode(EXISTING_BUILDING_CONFIG.RAFTER_BEAM_SIZE, false);
    let higherBraketName = getRafterBeamEndCapCode(EXISTING_BUILDING_CONFIG.RAFTER_BEAM_SIZE, true);

    let capL;
    let capR;
    if (userDataPos.front) {
      let matEndCap = MaterialManager.Instance().BEAM.clone();

      let heightReal =
        UI.existingWallHeight +
        +UI.upstandBraketType +
        this.beamInfo.height +
        (this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)) / 2;
      let heightPlane = heightReal * this.utils.cos(UI.patiosPitch);
      let translateZ = this.utils.sin(UI.patiosPitch) * heightPlane;

      let planeTop = new Plane(new Vector3(0, -1, 0));
      let matrixTranslateTop = new Matrix4().makeTranslation(
        0,
        heightPlane,
        translateZ / 2
      );
      let matrixRotateTop = new Matrix4().makeRotationX(
        this.utils.degreesToRadians(UI.patiosPitch)
      ); //tao voi z pitch do
      let matrixTotalTop = matrixTranslateTop.multiply(matrixRotateTop);
      planeTop.applyMatrix4(matrixTotalTop);

      let heightRealBottom =
        UI.existingWallHeight +
        +UI.upstandBraketType +
        this.beamInfo.height -
        this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
        (this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)) / 2;
      let heightPlaneBottom = heightRealBottom * this.utils.cos(UI.patiosPitch);
      let translateZBottom = this.utils.sin(UI.patiosPitch) * heightPlaneBottom;

      let planeBottom = new Plane(new Vector3(0, 1, 0));
      let matrixTranslateBottom = new Matrix4().makeTranslation(
        0,
        heightPlaneBottom,
        translateZBottom / 2
      );
      let matrixRotateBottom = new Matrix4().makeRotationX(
        this.utils.degreesToRadians(UI.patiosPitch)
      ); //tao voi z pitch do
      let matrixTotalBottom =
        matrixTranslateBottom.multiply(matrixRotateBottom);
      planeBottom.applyMatrix4(matrixTotalBottom);

      planeTop.translate(
        new Vector3(
          0,
          0,
          (UI.span + UI.multiSpan) / 2 -
            UI.existingWidth1 / 2 +
            UI.eaveWidth +
            this.geoBraket_2.height / 4 +
            this.scene.position.z
        )
      );
      planeBottom.translate(
        new Vector3(
          0,
          0,
          (UI.span + UI.multiSpan) / 2 -
            UI.existingWidth1 / 2 +
            UI.eaveWidth +
            this.geoBraket_2.height / 4 +
            this.scene.position.z
        )
      );

      matEndCap.clippingPlanes = [planeTop, planeBottom];

      capL = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capL.position.set(offsetXL - 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capL.scale.setY(
        (this.geo_RafterBeam.height / this.utils.sin(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length / this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capL.rotateY(Math.PI / 2);
      capL.rotateX(-Math.PI / 2 + this.utils.degreesToRadians(UI.patiosPitch));
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: lowerBraketName,
      };

      capR = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capR.position.set(offsetXR + 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capR.scale.setY(
        (this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length * this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capR.rotateY(-Math.PI / 2);
      capR.rotateX(-this.utils.degreesToRadians(UI.patiosPitch));
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: higherBraketName,
      };
    } else {
      let matEndCap = MaterialManager.Instance().BEAM.clone();

      let heightReal =
        UI.existingWallHeight +
        +UI.upstandBraketType +
        this.beamInfo.height +
        (this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)) / 2;
      let heightPlane = heightReal * this.utils.cos(UI.patiosPitch);
      let translateZ = this.utils.sin(UI.patiosPitch) * heightPlane;

      let planeTop = new Plane(new Vector3(0, -1, 0));
      let matrixTranslateTop = new Matrix4().makeTranslation(
        0,
        heightPlane,
        -translateZ / 2
      );
      let matrixRotateTop = new Matrix4().makeRotationX(
        -this.utils.degreesToRadians(UI.patiosPitch)
      ); //tao voi z pitch do
      let matrixTotalTop = matrixTranslateTop.multiply(matrixRotateTop);
      planeTop.applyMatrix4(matrixTotalTop);

      let heightRealBottom =
        UI.existingWallHeight +
        +UI.upstandBraketType +
        this.beamInfo.height -
        this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
        (this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)) / 2;
      let heightPlaneBottom = heightRealBottom * this.utils.cos(UI.patiosPitch);
      let translateZBottom = this.utils.sin(UI.patiosPitch) * heightPlaneBottom;

      let planeBottom = new Plane(new Vector3(0, 1, 0));
      let matrixTranslateBottom = new Matrix4().makeTranslation(
        0,
        heightPlaneBottom,
        -translateZBottom / 2
      );
      let matrixRotateBottom = new Matrix4().makeRotationX(
        -this.utils.degreesToRadians(UI.patiosPitch)
      ); //tao voi z pitch do
      let matrixTotalBottom =
        matrixTranslateBottom.multiply(matrixRotateBottom);
      planeBottom.applyMatrix4(matrixTotalBottom);

      planeTop.translate(
        new Vector3(
          0,
          0,
          (UI.span + UI.multiSpan) / 2 -
            UI.existingWidth1 / 2 +
            UI.eaveWidth +
            this.geoBraket_2.height / 4 +
            this.scene.position.z
        )
      );
      planeBottom.translate(
        new Vector3(
          0,
          0,
          (UI.span + UI.multiSpan) / 2 -
            UI.existingWidth1 / 2 +
            UI.eaveWidth +
            this.geoBraket_2.height / 4 +
            this.scene.position.z
        )
      );

      matEndCap.clippingPlanes = [planeTop, planeBottom];

      capL = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capL.position.set(offsetXL - 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capL.scale.setY(
        (this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length * this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capL.rotateY(Math.PI / 2);
      capL.rotateX(-this.utils.degreesToRadians(UI.patiosPitch));
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: higherBraketName,
      };

      capR = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capR.position.set(offsetXR + 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capR.scale.setY(
        (this.geo_RafterBeam.height / this.utils.sin(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length / this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capR.rotateY(-Math.PI / 2);
      capR.rotateX(-Math.PI / 2 + this.utils.degreesToRadians(UI.patiosPitch));
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: lowerBraketName,
      };
    }

    let beamGroup = new Group();
    beamGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM,
      position: userDataPos,
      views: views,
    };
    beamGroup.position.set(offsetX, offsetY, offsetZ);
    if (this.APP.sltBeamType.currentValue == 0) {
      beamGroup.add(mesh, capL, capR);
    } else {
      beamGroup.add(mesh);
    }

    beamGroup.rotateY(Math.PI / 2);

    if (userDataPos.front) {
      beamGroup.rotateZ(this.utils.degreesToRadians(UI.patiosPitch));
    } else if (userDataPos.back) {
      beamGroup.rotateZ(-this.utils.degreesToRadians(UI.patiosPitch));
    }

    this.scene.add(beamGroup);
  }
  public addCenterSuperiorBeam() {
    let offsetXL = -(this.totalBaySize / 2 + UI.overhangLeft);
    let offsetZ = 0;
    let offsetY = 0;

    let beamLength = this.totalBaySize + UI.overhangLeft + UI.overhangRight;

    let views: Print2DView[];

    offsetY =
      UI.existingWallHeight +
      +UI.upstandBraketType +
      this.beamInfo.height +
      ((UI.span + UI.multiSpan) / 2 - this.geoBeam.width / 2) *
        this.utils.tan(UI.patiosPitch);
    offsetZ =
      (UI.span + UI.multiSpan) / 2 -
      UI.existingWidth1 / 2 +
      UI.eaveWidth +
      this.geoBraket_2.height / 4;

    views = [
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    this.cutStandardBeamByCutBeamWithinBayControl(
      this.beamInfo,
      this.geo_beamEndCap,
      beamLength,
      new Vector3(offsetXL, offsetY, offsetZ),
      new Vector3(),
      views,
      1,
      { center: true },
      0,
      "x",
      'RB',
      this.geoBeamJoint
    );
  }
  private getHeightByRoofPitch(isSpan: boolean): number {
    if (isSpan) {
      if (UI.isUpFasciaUpstandardBracket) {
        return (
          -this.utils.tan(this.APP.sltRoofPitch.currentValue) *
          this.APP.sldSpan.currentValue
        );
      } else {
        return (
          this.utils.tan(this.APP.sltRoofPitch.currentValue) *
          this.APP.sldSpan.currentValue
        );
      }
    } else {
      if (UI.isUpFasciaUpstandardBracket) {
        return (
          -this.utils.tan(this.APP.sltRoofPitch.currentValue) *
          (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue)
        );
      } else {
        return (
          this.utils.tan(this.APP.sltRoofPitch.currentValue) *
          (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue)
        );
      }
    }
  }
}
