import { BoxBufferGeometry, BufferGeometry, Color, Geometry, Group, LineSegments, Material, Matrix4, Mesh, MeshLambertMaterial, Object3D, Plane, Vector3 } from 'three';
import { GEOMETRY_CATEGORY, GEOMETRY_TYPE } from '../../app.config';
import { BUILDING_SIDE, CUTOUT_ENABLE, EXISTING_BUILDING_CONFIG as CONST, EXISTING_BUILDING_CONFIG, PANEL_DIRECTION, RAKECUT_TYPE } from '../../app.constants';
import { Util } from '../utils';
// import { AppComponent } from '../../app.component';
import HighLightBox from 'src/app/models/HighlightBox';
import { CSG } from 'three-csg-ts';
import { GableLRFasciaManager } from '.';
import { HomeComponent as AppComponent } from '../../containers/home/home.component';
import { GeometryManager } from '../geometry.manager';
import { MaterialManager } from '../material.manager';
import { GeometryInfo, LineType, Print2DView, Printing2DGeometry, Printing2DLine, ViewType } from '../models';
import { UI } from '../ui';

export class RoofManager {
  private scene: Group;
  private APP: AppComponent;
  private MANAGER: GableLRFasciaManager;
  private material: Material;
  private utils: Util;

  private geometryManager: GeometryManager;

  private roofInfo: GeometryInfo;
  private roofBaseInfo: GeometryInfo;
  private zFlashingGeo: GeometryInfo
  private zFlashingRakecutRightGeo: GeometryInfo;
  private bargeLeftInfo: GeometryInfo;
  private bargeRightInfo: GeometryInfo;
  private bargeFrontInfo: GeometryInfo;
  private bargeBackInfo: GeometryInfo;
  private geo_bargeCutoutLeftHor: GeometryInfo;
  private geo_bargeCutoutRightHor: GeometryInfo;
  private geo_bargeRakecutLeft: GeometryInfo;
  private geo_bargeRakecutRight: GeometryInfo;
  private receiverCutoutLeftHor: GeometryInfo;
  private receiverCutoutLeftVer: GeometryInfo;
  private receiverCutoutRightHor: GeometryInfo;
  private receiverCutoutRightVer: GeometryInfo;
  private gutterInfo: GeometryInfo;
  private gutterRakecutLeftInfo: GeometryInfo;
  private gutterRakecutRightInfo: GeometryInfo;
  public ridgeGeo: GeometryInfo;
  //private flashingInfo: GeometryInfo;
  private geo_receiverChanel: GeometryInfo;
  private geo_gutterCap: GeometryInfo;
  public geoBraket_1: GeometryInfo;
  public geoBraket_2: GeometryInfo;
  private geoBeam: GeometryInfo;
  private geoBeamEndCap: GeometryInfo;
  private eventHandleId: any;
  // Var
  private roofLength: number;
  private roofLengthPlus: number;
  private roofWidth: number;
  //private existingTypeLeftX: number;
  //private existingTypeRightX: number;
  private existingType: number;
  private totalBaySize: number;
  private objectSizeChangedHandle: any;
  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;

  private mesh_CutoutLeft: Mesh;
  private csg_cutoutLeft: CSG;
  private mesh_CutoutRight: Mesh;
  private csg_cutoutRight: CSG;
  private geo_cutoutLeft: BoxBufferGeometry;
  private geo_cutoutRight: BoxBufferGeometry;

  private geo_rakecutLeft: BoxBufferGeometry;
  private geo_rakecutRight: BoxBufferGeometry;
  private mesh_rakecutLeft: Mesh;
  private mesh_rakecutRight: Mesh;
  private csg_rakecutLeft: CSG;
  private csg_rakecutRight: CSG;

  private geoCutGutter: BoxBufferGeometry;
  private meshCutGutterLeft1: Mesh;
  private csgCutGutterLeft1: CSG;
  private meshCutGutterLeft2: Mesh;
  private csgCutGutterLeft2: CSG;

  private meshCutGutterRight1: Mesh;
  private csgCutGutterRight1: CSG;
  private meshCutGutterRight2: Mesh;
  private csgCutGutterRight2: CSG;

  private roofGroupFront: Group;
  private roofGroupBack: Group;
  private cutoutExistingBothLength: number;

  constructor(app: AppComponent, fasciaManager: GableLRFasciaManager) {
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.APP = app;
    this.MANAGER = fasciaManager;
    this.scene = new Group();
    fasciaManager.patiosGroup.add(this.scene)
    this.material = MaterialManager.Instance().ROOF;
    this.registerEvent();
  }
  public destroy(): void {
    this.unregisterEvent();
  }
  public uiChanged(preVal: number, curVal: number): void {
    this.load();
  }
  private objectSizeChanged(preVal: number, curVal: number) {
    this.optimize().then(() => { this.load() });
  }
  private registerEvent(): void {
    this.eventHandleId = this.uiChanged.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldBaySize,
      this.APP.sldSpan,
      this.APP.sldMultiSpan,
      this.APP.sldExistingWallHeight,
      this.APP.sldFrontOverhang,
      this.APP.sldRightOverhang,
      this.APP.sldLeftOverhang,
      this.APP.sldBackOverhang,
      this.APP.sltRoofPitch,
      this.APP.sldExistingWidth1,
      this.APP.sldNoOfBay,
      this.APP.dialogEditBay,
      this.APP.sldEaveWidth,
      this.APP.sltExistingType,
      this.APP.sltColourDownpipe,
    ];
    //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));
    this.controlsToRegisterEvent2 = [
      this.APP.sltRoofSheetingType,
      this.APP.sltRoofThickness,
      this.APP.sltBargeType,
      this.APP.sltDripBarge,
      this.APP.sltGutterType,
      this.APP.sltRoofPitch
    ];
    this.controlsToRegisterEvent2.forEach(c => c.addAction(this.objectSizeChangedHandle));
  }
  private unregisterEvent(): void {
    //this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    //this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach(c => c.removeAction(this.objectSizeChangedHandle));
    this.controlsToRegisterEvent2 = undefined;
  }
  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.roofInfo = this.geometryManager.getRoofSheetPanel();
      this.roofInfo.geometry
        .translate(this.roofInfo.width / 2 + 3, -1, this.roofInfo.length / 2);

      this.roofBaseInfo = this.geometryManager.getRoofBase();
      this.roofBaseInfo.geometry
        .translate(this.roofBaseInfo.width / 2, this.roofBaseInfo.height / 2, this.roofBaseInfo.length / 2);

      this.zFlashingGeo = this.geometryManager.getZFlashing();
      this.zFlashingRakecutRightGeo = this.geometryManager.getZFlashing();
      this.zFlashingRakecutRightGeo.geometry.translate(-this.zFlashingRakecutRightGeo.length, 0, 0);

      // Barge capping
      this.bargeLeftInfo = this.geometryManager.getBarge();
      this.bargeLeftInfo.geometry
        .rotateY(Math.PI)
        .translate(this.bargeLeftInfo.width / 2 - 3, this.bargeLeftInfo.height / 2 - (this.APP.sltDripBarge.currentValue ? 9 : 1), this.bargeLeftInfo.length / 2);

      this.bargeRightInfo = this.geometryManager.getBarge();
      this.bargeRightInfo.geometry
        .translate(-this.bargeRightInfo.width / 2 + 3, this.bargeRightInfo.height / 2 - (this.APP.sltDripBarge.currentValue ? 9 : 1), this.bargeRightInfo.length / 2);

      //Barge cutout left
      this.receiverCutoutLeftHor = this.geometryManager.getReceiverChannel();
      this.receiverCutoutLeftHor.geometry
        .rotateY(Math.PI / 2)
        .translate(-this.receiverCutoutLeftHor.length / 2, this.receiverCutoutLeftHor.height / 2, this.receiverCutoutLeftHor.width / 2);

      //Barge cutout right
      this.receiverCutoutRightHor = this.geometryManager.getReceiverChannel();
      this.receiverCutoutRightHor.geometry
        .rotateY(Math.PI / 2)
        .translate(this.receiverCutoutLeftHor.length / 2, this.receiverCutoutLeftHor.height / 2, this.receiverCutoutLeftHor.width / 2);

      //Vertical Barge cutout left
      this.receiverCutoutLeftVer = this.geometryManager.getReceiverChannel();
      this.receiverCutoutLeftVer.geometry
        .rotateY(Math.PI)
        .translate(this.receiverCutoutLeftVer.width / 2, this.receiverCutoutLeftVer.height / 2, this.receiverCutoutLeftVer.length / 2);

      //Vertical cutout right
      this.receiverCutoutRightVer = this.geometryManager.getReceiverChannel();
      this.receiverCutoutRightVer.geometry
        .translate(-this.receiverCutoutRightVer.width / 2, this.receiverCutoutRightVer.height / 2, this.receiverCutoutRightVer.length / 2);

      //Receiver chanel - barge caping back
      this.geo_receiverChanel = this.geometryManager.getReceiverChannel();
      this.geo_receiverChanel.geometry
        .rotateY(Math.PI / 2)
        .translate(this.geo_receiverChanel.length / 2, this.geo_receiverChanel.height / 2 + 2, this.geo_receiverChanel.width / 2);

      // Gutter
      this.gutterInfo = this.geometryManager.getGutter();
      this.gutterInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(this.gutterInfo.length / 2, -20, this.gutterInfo.width / 2);

      this.gutterRakecutLeftInfo = this.geometryManager.getGutter();
      this.gutterRakecutLeftInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(this.gutterRakecutLeftInfo.length / 2, -20, this.gutterRakecutLeftInfo.width / 2);

      this.gutterRakecutRightInfo = this.geometryManager.getGutter();
      this.gutterRakecutRightInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(-this.gutterRakecutRightInfo.length / 2, -20, this.gutterRakecutRightInfo.width / 2);

      //Gutter cap
      this.geo_gutterCap = this.geometryManager.getGuterEndCap();
      this.geo_gutterCap.geometry
        .rotateY(-Math.PI / 2)
        .translate(0, -20, this.geo_gutterCap.length / 2);


      this.geo_cutoutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutLeft.translate(-500, 0, -500);

      this.geo_cutoutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutRight.translate(500, 0, -500);

      this.geo_rakecutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutLeft.translate(-500, 500, 500);
      this.geo_rakecutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutRight.translate(500, 500, 500);

      this.geoCutGutter = new BoxBufferGeometry(800, 10000, 500);
      this.geoCutGutter.translate(0, 0, -250);

      this.geoBraket_1 = this.geometryManager.getUpstandBraket(1);
      this.geoBraket_1.geometry.rotateX(Math.PI / 2);
      this.geoBraket_1.geometry.translate(0, this.geoBraket_1.length / 2, 0);

      this.geoBraket_2 = this.geometryManager.getUpstandBraket(2);
      this.geoBraket_2.geometry.rotateY(Math.PI);
      this.geoBraket_2.geometry.rotateX(Math.PI / 2);
      this.geoBraket_2.geometry.translate(0, this.geoBraket_2.length / 2, - this.geoBraket_2.height / 4);

      this.geoBeam = this.geometryManager.getBeam();
      this.geoBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(0, this.geoBeam.height / 2, 0);

      this.geoBeamEndCap = this.geometryManager.getBeamEndCap();
      this.geoBeamEndCap.geometry
        .translate(0, this.geoBeamEndCap.height / 2, this.geoBeamEndCap.length / 2)
        .rotateY(Math.PI / 2);

      this.bargeFrontInfo = this.geometryManager.getBarge();
      this.bargeFrontInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(this.bargeFrontInfo.length / 2, this.bargeFrontInfo.height / 2 - (this.APP.sltDripBarge.currentValue ? 9 : 1), -this.bargeFrontInfo.width / 2);

      this.bargeBackInfo = this.geometryManager.getBarge();
      this.bargeBackInfo.geometry
        .rotateY(Math.PI / 2)
        .translate(this.bargeBackInfo.length / 2, this.bargeBackInfo.height / 2 - (this.APP.sltDripBarge.currentValue ? 9 : 1), this.bargeBackInfo.width / 2 - 3);

      this.geo_bargeRakecutLeft = this.geometryManager.getBarge();
      this.geo_bargeRakecutLeft.geometry
        .rotateY(Math.PI)
        .translate(this.geo_bargeRakecutLeft.width / 2, this.geo_bargeRakecutLeft.height / 2 - (this.APP.sltDripBarge.currentValue ? 9 : 1), -this.geo_bargeRakecutLeft.length / 2);

      this.geo_bargeRakecutRight = this.geometryManager.getBarge();
      this.geo_bargeRakecutRight.geometry
        .translate(-this.geo_bargeRakecutRight.width / 2, this.geo_bargeRakecutRight.height / 2 - (this.APP.sltDripBarge.currentValue ? 9 : 1), -this.geo_bargeRakecutRight.length / 2);

      this.geo_bargeRakecutLeft = this.geometryManager.getBarge();
      this.geo_bargeRakecutLeft.geometry
        .rotateY(Math.PI)
        .translate(this.geo_bargeRakecutLeft.width / 2, this.geo_bargeRakecutLeft.height / 2 - (this.APP.sltDripBarge.currentValue ? 9 : 1), -this.geo_bargeRakecutLeft.length / 2);

      this.geo_bargeRakecutRight = this.geometryManager.getBarge();
      this.geo_bargeRakecutRight.geometry
        .translate(-this.geo_bargeRakecutRight.width / 2, this.geo_bargeRakecutRight.height / 2 - (this.APP.sltDripBarge.currentValue ? 9 : 1), -this.geo_bargeRakecutRight.length / 2);

      //Barge cutout left
      this.geo_bargeCutoutLeftHor = this.geometryManager.getBarge();
      this.geo_bargeCutoutLeftHor.geometry
        .rotateY(Math.PI / 2)
        .translate(-this.geo_bargeCutoutLeftHor.length / 2, this.geo_bargeCutoutLeftHor.height / 2 - (this.APP.sltDripBarge.currentValue ? 9 : 1), this.geo_bargeCutoutLeftHor.width / 2);

      //Barge cutout right
      this.geo_bargeCutoutRightHor = this.geometryManager.getBarge();
      this.geo_bargeCutoutRightHor.geometry
        .rotateY(Math.PI / 2)
        .translate(this.geo_bargeCutoutRightHor.length / 2, this.geo_bargeCutoutRightHor.height / 2 - (this.APP.sltDripBarge.currentValue ? 9 : 1), this.geo_bargeCutoutRightHor.width / 2);

      this.ridgeGeo = this.geometryManager.getRidge();
      this.ridgeGeo.geometry.translate(- this.ridgeGeo.width / 2, - this.ridgeGeo.height / 2, 0);

      resolve();
    });
  }
  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(...this.scene.children.filter(x => x.userData.type === GEOMETRY_TYPE.ROOF_PANEL));
      this.scene.remove(...this.scene.children.filter(x => x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BASE));
      this.scene.remove(...this.scene.children.filter(x => x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING));
      this.scene.remove(...this.scene.children.filter(x => x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING));
      this.scene.remove(...this.scene.children.filter(x => x.userData.type === GEOMETRY_TYPE.GUTTER_PATIOS));
      this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PATIOS));
      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.SHEET_OUTLINE));
      //test
      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'RAKECUT'));

      this.totalBaySize = this.APP.dialogEditBay.listBay.reduce((total, val) => total + val.value, 0);
      this.roofLength = (UI.span + UI.multiSpan) / 2 + UI.overhangFront;
      this.roofLengthPlus = this.roofLength / this.utils.cos(UI.patiosPitch);
      this.roofWidth = this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue;
      this.cutoutExistingBothLength = (this.totalBaySize - (this.APP.sldExistingLength.currentValue + this.APP.existingWallManager.geo_existingWallW1.width * 2)) / 2;

      this.addRoofGroupFront();
      this.addRoofGroupBack();
      this.addCutout();

      this.existingType = +this.APP.sltExistingType.currentValue;


      let offsetXR = this.totalBaySize / 2 + this.MANAGER.patiosGroup.position.x + this.APP.sldRightOverhang.currentValue;

      let offsetLeft = this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue - this.MANAGER.patiosGroup.position.x;
      let clipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
      const clipingPlane = new Plane(new Vector3(-1, 0, 0), offsetXR); // (this.APP.sldBaySize.currentValue / 2) + this.APP.sldRightOverhang.currentValue );
      this.material.clippingPlanes = [clipingPlaneLeft, clipingPlane];
      MaterialManager.Instance().ROOF_BASE.clippingPlanes = [clipingPlaneLeft, clipingPlane];
      const roofPanelCount = Math.ceil(this.roofWidth / this.roofInfo.width);
      let offsetXL = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);

      //panel direction
      let panelDirectionOfset = 1;
      if (this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.RIGHT_TO_LEFT) {
        offsetXL = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue - this.roofInfo.width;
        panelDirectionOfset = -1;
      }
      else if (this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.LEFT_TO_RIGHT) {
        offsetXL = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
      }


      if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT && this.MANAGER.cutoutCondition) {
        let totalPanelLength = 0;
        let cuted = true;

        for (let index = 0; index < roofPanelCount; index++) {
          totalPanelLength += this.roofInfo.width;
          let isOver = totalPanelLength < this.APP.sldExistingLength2.currentValue;

          this.addRoofFrontBaseFront(offsetXL, [this.csg_cutoutLeft]);
          this.addRoofFront(offsetXL, [this.csg_cutoutLeft]);

          this.addRoofFrontBaseBack(offsetXL, [this.csg_cutoutLeft]);
          this.addRoofBack(offsetXL, [this.csg_cutoutLeft]);
          cuted = isOver;

          offsetXL += this.roofInfo.width * panelDirectionOfset;
        }
      }
      else if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT && this.MANAGER.cutoutCondition) {
        let totalPanelLength = 0;
        let cuted = false;
        for (let index = 0; index < roofPanelCount; index++) {
          totalPanelLength += this.roofInfo.width;
          let isOver = totalPanelLength >= this.roofWidth - this.APP.sldExistingLength2.currentValue + this.roofInfo.width;
          cuted = totalPanelLength >= this.roofWidth - this.APP.sldExistingLength2.currentValue && !isOver;

          this.addRoofFrontBaseFront(offsetXL, [this.csg_cutoutRight]);
          this.addRoofFront(offsetXL, [this.csg_cutoutRight]);

          this.addRoofFrontBaseBack(offsetXL, [this.csg_cutoutRight]);
          this.addRoofBack(offsetXL, [this.csg_cutoutRight]);
          offsetXL += this.roofInfo.width * panelDirectionOfset;
        }
      }
      else if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH && this.MANAGER.cutoutCondition) {
        let totalPanelLength = 0;
        let cuted = false;
        let cuted2 = false;
        for (let index = 0; index < roofPanelCount; index++) {
          totalPanelLength += this.roofInfo.width;
          let isOver = totalPanelLength < this.APP.sldExistingLength2.currentValue;
          let isOver2 = totalPanelLength >= this.roofWidth - this.APP.sldExistingLength2.currentValue + this.roofInfo.width;
          let flag = totalPanelLength >= this.roofWidth - this.APP.sldExistingLength2.currentValue;

          this.addRoofFrontBaseFront(offsetXL, [this.csg_cutoutLeft, this.csg_cutoutRight]);
          this.addRoofFront(offsetXL, [this.csg_cutoutLeft, this.csg_cutoutRight]);

          this.addRoofFrontBaseBack(offsetXL, [this.csg_cutoutLeft, this.csg_cutoutRight]);
          this.addRoofBack(offsetXL, [this.csg_cutoutLeft, this.csg_cutoutRight]);

          offsetXL += this.roofInfo.width * panelDirectionOfset;
        }
      }
      else {
        for (let index = 0; index < roofPanelCount; index++) {
          this.addRoofFrontBaseFront(offsetXL, []);
          this.addRoofFront(offsetXL, []);

          this.addRoofFrontBaseBack(offsetXL, []);
          this.addRoofBack(offsetXL, []);
          offsetXL += this.roofInfo.width * panelDirectionOfset;
        }
      }

      this.addZFlashingFront();
      this.addZFlashingBack();

      this.addBargeFront();
      this.addBargeBack();

      this.addGutterFront();
      this.addGutterBack();

      this.addRidgeCapping();

      this.showPanelOutline();

      this.scene.visible = UI.showRoof

      resolve();
    });
  }

  public addRidgeCapping(){
    this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.RIDGE_CAPPING));

    let ridgeFront = this.createRidgeCapping();
    ridgeFront.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RIDGE_CAPPING};
    const height = this.getRoofOffsetY() + 
        this.roofBaseInfo.height / this.utils.cos(this.APP.sltRoofPitch.currentValue) + 
        this.roofInfo.height / this.utils.cos(this.APP.sltRoofPitch.currentValue) - 
        (this.APP.sltRoofSheetingType.currentValue == 0 || this.APP.sltRoofSheetingType.currentValue == 1 ? 20 : 10);
    
    let offsetX = (UI.overhangRight - UI.overhangLeft) / 2
    
    const ridgeLength = this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue;
    ridgeFront.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RIDGE_CAPPING, length: ridgeLength, views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },{ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }]};
    ridgeFront.scale.setZ(ridgeLength / this.ridgeGeo.length);
    ridgeFront.rotation.set(0, Math.PI / 2, 0)
    ridgeFront.position.set( offsetX, height, ( UI.span + UI.multiSpan ) / 2 - UI.existingWidth1 / 2 + UI.eaveWidth + this.geoBraket_2.height / 4);
    this.scene.add(ridgeFront)
  }

  public createRidgeCapping(){
    let leftCap = new Mesh(this.ridgeGeo.geometry, MaterialManager.Instance().RIDGE_CAPPING);
    let rightCap = new Mesh(this.ridgeGeo.geometry, MaterialManager.Instance().RIDGE_CAPPING);

    rightCap.rotateY(Math.PI)

    leftCap.rotateZ(this.utils.degreesToRadians(UI.patiosPitch))
    rightCap.rotateZ(this.utils.degreesToRadians(UI.patiosPitch))

    leftCap.userData = {views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },{ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }]}
    rightCap.userData = {views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },{ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }]}

    return new Group().add(leftCap, rightCap)
  }

  private addCutout() {
    if (this.APP.sltCutOut.currentValue == 1) {
      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'CUTOUT'));

      let offsetX = this.APP.sldExistingLength.currentValue / 2 - this.APP.sldEaveWidth.currentValue;
      let offsetY = 0;
      const eaveThickness = 50;
      let offsetZ = this.APP.sldExistingWidth1.currentValue / 2 + UI.eaveWidth + eaveThickness - UI.overhangBack;

      //extra length to prevent lack when cut
      let extra = 5000;

      let scaleX = (this.APP.sldExistingLength2.currentValue + extra) / 1000;
      if (scaleX == 0) scaleX = 1;
      let scaleY = (this.APP.sldExistingWallHeight.currentValue + extra) / 1000;
      let scaleZ = (this.APP.sldExistingWidth1.currentValue + UI.overhangBack + extra) / 1000;
      if (scaleZ == 0) scaleZ = 1;

      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT || this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        this.mesh_CutoutLeft = new Mesh(this.geo_cutoutLeft, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
        this.mesh_CutoutLeft.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: 'CUTOUT' };

        this.mesh_CutoutLeft.position.set(-offsetX - this.APP.sldLeftOverhang.currentValue, offsetY, offsetZ);
        this.mesh_CutoutLeft.scale.set(scaleX, scaleY, scaleZ);

        //this.APP.scene.add(this.mesh_CutoutLeft);

        this.mesh_CutoutLeft.updateMatrix();
        this.csg_cutoutLeft = CSG.fromMesh(this.mesh_CutoutLeft);
      }
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT || this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        this.mesh_CutoutRight = new Mesh(this.geo_cutoutRight, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
        this.mesh_CutoutRight.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: 'CUTOUT' };

        this.mesh_CutoutRight.position.set(offsetX + this.APP.sldRightOverhang.currentValue, offsetY, offsetZ);
        this.mesh_CutoutRight.scale.set(scaleX, scaleY, scaleZ);

        //this.APP.scene.add(this.mesh_CutoutRight);

        this.mesh_CutoutRight.updateMatrix();
        this.csg_cutoutRight = CSG.fromMesh(this.mesh_CutoutRight);
      }
    }
  }

  public addZFlashingFront() {
    let offsetX = -(this.totalBaySize / 2 + UI.overhangLeft);
    let offsetY = this.roofBaseInfo.height - 25;
    const offsetZ = ((UI.span + UI.multiSpan) / 2 + UI.overhangFront) / this.utils.cos(UI.patiosPitch);

    let flashingLength = this.roofWidth;

    let scaleX = flashingLength / this.zFlashingGeo.length;


    let mesh = new Mesh(this.zFlashingGeo.geometry, MaterialManager.Instance().ZFLASHING);
    mesh.position.set(offsetX, offsetY, offsetZ);
    mesh.scale.set(scaleX, 1, 1);

    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ZFLASHING, scale: mesh.scale };
    this.roofGroupFront.add(mesh);
  }
  public addZFlashingBack() {
    let offsetY = this.roofBaseInfo.height - 25;
    const offsetZ = -((UI.span + UI.multiSpan) / 2 + UI.overhangBack) / this.utils.cos(UI.patiosPitch);
    let offsetX = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
    let scaleX = (this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue) / this.zFlashingGeo.length;
    let flashingLength = UI.totalBayLength + UI.overhangLeft + UI.overhangRight;
    // let extraWallWidth = this.APP.existingWallManager.geo_existingWallW1.width + EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
    if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.MANAGER.cutoutCondition) {
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
        if (UI.existingWidth2 > 0) {
          offsetX += UI.existingLength2;
          flashingLength -= UI.existingLength2;
        }
        else {
          offsetX += UI.existingLength2;
          flashingLength -= UI.existingLength2;
        }

        offsetX += UI.overhangLeft;
        flashingLength -= UI.overhangLeft;
      }
      else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
        if (UI.existingWidth2 > 0) {
          flashingLength -= UI.existingLength2;
        }
        else {
          flashingLength -= UI.existingLength2;
        }
        flashingLength -= UI.overhangRight;
      }
      else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        offsetX += UI.existingLength2;
        flashingLength = UI.existingLength1 - UI.eaveWidth * 2;
      }
    }

    scaleX = flashingLength / this.zFlashingGeo.length;
    let mesh = new Mesh(this.zFlashingGeo.geometry, MaterialManager.Instance().ZFLASHING);
    mesh.position.set(offsetX + flashingLength, offsetY, offsetZ);
    mesh.rotateY(Math.PI);
    mesh.scale.set(scaleX, 1, 1);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ZFLASHING, scale: mesh.scale };

    this.roofGroupBack.add(mesh);
  }
  public addRoofGroupFront() {
    this.roofGroupFront = null;
    this.roofGroupFront = new Group();
    this.roofGroupFront.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PATIOS };

    let offsetX = 0;
    let offsetY = this.getRoofOffsetY();

    let offsetZ = (UI.span + UI.multiSpan) / 2 - UI.existingWidth1 / 2 + UI.eaveWidth + this.geoBraket_2.height / 4;
    this.roofGroupFront.position.set(offsetX, offsetY, offsetZ);
    
    this.roofGroupFront.rotation.set(this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), 0, 0);

    this.scene.add(this.roofGroupFront);
  }
  public addRoofGroupBack() {
    this.roofGroupBack = null;
    this.roofGroupBack = new Group();
    this.roofGroupBack.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PATIOS };

    let offsetX = 0;
    let offsetY = this.getRoofOffsetY();

    let offsetZ = (UI.span + UI.multiSpan) / 2 - UI.existingWidth1 / 2 + UI.eaveWidth + this.geoBraket_2.height / 4;
    this.roofGroupBack.position.set(offsetX, offsetY, offsetZ);
    
    this.roofGroupBack.rotation.set(-this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), 0, 0);

    this.scene.add(this.roofGroupBack);
  }
  public getOutLines(): Printing2DGeometry {

    let objs = this.roofGroupFront.children.filter(o =>
      o.userData.type == GEOMETRY_TYPE.ROOF_PANEL
      || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    ).concat(
      ...this.roofGroupBack.children.filter(o =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
      )
    );

    let gutterGroupObjs = this.roofGroupFront.children.filter(o => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS).concat(
      ...this.roofGroupBack.children.filter(o => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS)
    );
    let gutterObjs: Object3D[] = [];
    for (let group of gutterGroupObjs) {
      gutterObjs.push(...group.children);
    }

    objs = [...objs, ...gutterObjs];


    let lsGeometries: Printing2DLine[] = [];

    let offsetLeft = this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue - this.MANAGER.patiosGroup.position.x;
    let offsetRight = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue + this.MANAGER.patiosGroup.position.x;
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlane = new Plane(new Vector3(-1, 0, 0), offsetRight);

    for (let o of objs) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale((o as Mesh), 60);
      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);
      outlineGeo.translate(0, 5000, 0);

      if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL) {
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlane);
      }
      if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE) {
          outlineGeo = this.utils.clipOutlineBasePanel(outlineGeo, roofClipingPlaneLeft);
          outlineGeo = this.utils.clipOutlineBasePanel(outlineGeo, roofClipingPlane);
      }


      // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
      // line.userData = {type: "ROOF_OUTLINE"};
      // this.APP.scene.add( line );

      let simplifiedGeo = this.simplifyGeo(outlineGeo);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: simplifiedGeo.vertices,
        views: o.userData.views
      });

      let ridgeCapping = this.scene.children.find(el => el.userData.type === GEOMETRY_TYPE.RIDGE_CAPPING)
      if(ridgeCapping){
        ridgeCapping.children.forEach(el => {
          this.utils.createOutline(el as Mesh, lsGeometries);
        })
      }
    }

    lsGeometries.push({
      objectType: GEOMETRY_TYPE.ROOF_COVER,
      vertices: this.makeRoofPolygon(),
      color: this.utils.convertHexColorToRgb(`#${new Color(UI.panelColor).getHexString()}`),
      views: [
          { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
      ]
    });

    return { lines: lsGeometries, texts: [] };
  }
  makeRoofPolygon() {
    const polygon = this.utils.getRoofCoverPolygonForLRRoof()
    this.MANAGER.patiosGroup.updateWorldMatrix(true, true)
    return polygon.map(el => (el as Vector3).applyMatrix4(this.MANAGER.patiosGroup.matrixWorld).add(new Vector3(0, 0, - UI.existingWidth1 / 2 - UI.overhangBack + UI.eaveWidth + this.geoBraket_2.height / 4)))
  }
  public showPanelOutline() {
    if (this.APP.sltPanelDirectionShow.currentValue == false)
      return;

    let objs = this.roofGroupFront.children.filter(o =>
      o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
      o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    ).concat(
      ...this.roofGroupBack.children.filter(o =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
      )
    );

    let offsetLeft = this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue - this.MANAGER.patiosGroup.position.x;
    let offsetRight = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue + this.MANAGER.patiosGroup.position.x;
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);


    for (let o of objs) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale((o as Mesh), 60);

      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);
      //outlineGeo.translate(0, 5000, 0);

      if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE) {
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
      }

      var line = new LineSegments(outlineGeo, MaterialManager.Instance().MESH_OUTLINE);
      line.userData = { type: GEOMETRY_TYPE.SHEET_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let simplifiedGeo = new Geometry();
    let vertices = geo.getAttribute('position').array;
    for (let i = 0; i < vertices.length; i += 3) {
      simplifiedGeo.vertices.push(new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2]));
    }
    return simplifiedGeo;
  }
  public addRoofFront(offsetX: number, csgObjs: CSG[]) {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];
    const mesh: Mesh = new Mesh(this.roofInfo.geometry, MaterialManager.Instance().ROOF);

    let offsetSheet = 0;
    if (this.APP.sltRoofSheetingType.currentValue == 0 || this.APP.sltRoofSheetingType.currentValue == 1) {
      offsetSheet = 13;
    }
    else {
      offsetSheet = 5;
    }

    //if ((this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3) && this.APP.sldExistingWidth1.currentValue > this.APP.sldEaveWidth.currentValue) {
    //    offsetX += this.APP.sldEaveWidth.currentValue;
    //}
    const offsetPitch = this.roofBaseInfo.height * this.utils.tan(UI.patiosPitch)
    let scaleZ = (this.roofLengthPlus - offsetPitch) / this.roofInfo.length;
    const offsetY = this.roofBaseInfo.height - offsetSheet;
    let offsetZ = 0;

    // if (this.APP.sltCutOut.currentValue == 1 && this.APP.sltExistingType.currentValue != 0 && over && this.APP.sldExistingLength2.currentValue > 0) {
    //     scaleZ = (this.roofLengthPlus - this.APP.sldExistingWidth1.currentValue) / this.roofInfo.length;
    //     offsetZ += this.APP.sldExistingWidth1.currentValue;
    // }

    mesh.position.set(offsetX, offsetY, offsetZ);
    mesh.scale.setZ(scaleZ);
    mesh.userData = { 
      category: GEOMETRY_CATEGORY.PATIOS, 
      type: GEOMETRY_TYPE.ROOF_PANEL, 
      views: views, 
      length: scaleZ,
      dir: UI.panelDirection === PANEL_DIRECTION.LEFT_TO_RIGHT ? "L-R" : "R-L"
    };

    let cutoutCondition = this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue != BUILDING_SIDE.NONE && this.APP.sldExistingLength2.currentValue > 0;
    let rakeCutCondition = this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE || this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE;

    if (cutoutCondition || rakeCutCondition) {

      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      //cho no cung he toa do
      mesh.position.set(offsetX + this.MANAGER.patiosGroup.position.x, offsetY, offsetZ + this.roofGroupFront.position.z);
      mesh.updateMatrix();

      // let meshResult = new Mesh();
      // let meshTemp = mesh;

      // for (let csgObj of csgObjs) {
      //     meshResult = CSG.toMesh(CSG.fromMesh(meshTemp).subtract(csgObj), mesh.matrix);
      //     meshTemp = meshResult;
      // }

      let meshResult = mesh.clone();

      if (cutoutCondition) {
        //meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
        }
      }
      if (rakeCutCondition) {
        if (this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(this.csg_rakecutLeft), mesh.matrix);
        }
        if (this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(this.csg_rakecutRight), mesh.matrix);
        }
      }
      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix)); //=> tra ve goc toa do
      meshResult.applyMatrix4(matrixBefore);
      this.roofGroupFront.add(meshResult);
    }
    else {
      this.roofGroupFront.add(mesh);
    }
  }
  public addRoofBack(offsetX: number, csgObjs: CSG[]) {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];
    const mesh: Mesh = new Mesh(this.roofInfo.geometry, MaterialManager.Instance().ROOF);

    let offsetSheet = 0;
    if (this.APP.sltRoofSheetingType.currentValue == 0 || this.APP.sltRoofSheetingType.currentValue == 1) {
      offsetSheet = 13;
    }
    else {
      offsetSheet = 5;
    }

    const offsetPitch = this.roofBaseInfo.height * this.utils.tan(UI.patiosPitch)

    let scaleZ = (((UI.span + UI.multiSpan) / 2 + UI.overhangBack) / this.utils.cos(UI.patiosPitch) - offsetPitch) / this.roofInfo.length;
    const offsetY = this.roofBaseInfo.height - offsetSheet;
    let offsetZ = 0;

    mesh.position.set(offsetX, offsetY, offsetZ - scaleZ * this.roofInfo.length);
    mesh.scale.setZ(scaleZ);
    mesh.userData = { 
      category: GEOMETRY_CATEGORY.PATIOS, 
      type: GEOMETRY_TYPE.ROOF_PANEL, 
      views: views, 
      length: scaleZ,
      dir: UI.panelDirection === PANEL_DIRECTION.RIGHT_TO_LEFT ? "L-R" : "R-L"
    };

    let cutoutCondition = this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue != BUILDING_SIDE.NONE && this.APP.sldExistingLength2.currentValue > 0;
    let rakeCutCondition = this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE || this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE;

    if (cutoutCondition || rakeCutCondition) {

      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      //cho no cung he toa do
      mesh.position.set(offsetX + this.MANAGER.patiosGroup.position.x, offsetY, offsetZ + this.roofGroupBack.position.z);
      mesh.updateMatrix();

      let meshResult = mesh.clone();

      if (cutoutCondition) {
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
        }
      }
      if (rakeCutCondition) {
        if (this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(this.csg_rakecutLeft), mesh.matrix);
        }
        if (this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(this.csg_rakecutRight), mesh.matrix);
        }
      }
      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix)); //=> tra ve goc toa do
      meshResult.applyMatrix4(matrixBefore);
      this.roofGroupBack.add(meshResult);
    }
    else {
      this.roofGroupBack.add(mesh);
    }
  }
  public addRoofFrontBaseFront(offsetX: number, csgObjs: CSG[]) {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];
    const mesh: Mesh = new Mesh(this.roofBaseInfo.geometry, MaterialManager.Instance().ROOF_BASE);
    const offsetPitch = this.roofBaseInfo.height * this.utils.tan(UI.patiosPitch)
    let scaleZ = (this.roofLengthPlus - CONST.ROOF_BASE_SUBTRACT - offsetPitch) / this.roofBaseInfo.length;
    let offsetY = 0
    let offsetZ = 0

    mesh.position.set(offsetX, offsetY, offsetZ);
    mesh.scale.setZ(scaleZ);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PANEL_BASE, views: views };

    let cutoutCondition = this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue != BUILDING_SIDE.NONE && this.APP.sldExistingLength2.currentValue > 0;

    if (cutoutCondition) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      mesh.position.set(offsetX + this.MANAGER.patiosGroup.position.x, offsetY, offsetZ + this.roofGroupFront.position.z);
      mesh.updateMatrix();

      let meshResult = mesh.clone();

      if (cutoutCondition) {
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
        }
      }

      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix));
      meshResult.applyMatrix4(matrixBefore);

      this.roofGroupFront.add(meshResult);
    }
    else {
      this.roofGroupFront.add(mesh);
    }
  }
  public addRoofFrontBaseBack(offsetX: number, csgObjs: CSG[]) {
    let views: Print2DView[] = [
      // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];
    const mesh: Mesh = new Mesh(this.roofBaseInfo.geometry, MaterialManager.Instance().ROOF_BASE);
    const offsetPitch = this.roofBaseInfo.height * this.utils.tan(UI.patiosPitch)
    let scaleZ = ((((UI.span + UI.multiSpan) / 2 + UI.overhangBack) / this.utils.cos(UI.patiosPitch)) - offsetPitch  - CONST.ROOF_BASE_SUBTRACT) / this.roofBaseInfo.length;
    let offsetY = 0
    let offsetZ = 0

    mesh.position.set(offsetX, offsetY, offsetZ - scaleZ * this.roofBaseInfo.length);
    mesh.scale.setZ(scaleZ);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PANEL_BASE, views: views };

    let cutoutCondition = this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue != BUILDING_SIDE.NONE && this.APP.sldExistingLength2.currentValue > 0;

    if (cutoutCondition) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      mesh.position.set(offsetX + this.MANAGER.patiosGroup.position.x, offsetY, offsetZ + this.roofGroupBack.position.z);
      mesh.updateMatrix();

      let meshResult = mesh.clone();

      if (cutoutCondition) {
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
        }
      }

      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix));
      meshResult.applyMatrix4(matrixBefore);

      this.roofGroupBack.add(meshResult);
    }
    else {
      this.roofGroupBack.add(mesh);
    }
  }
  private addBargeFront() {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];
    let mesh: Mesh;
    let offsetXL2 = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);

    let offsetXR2 = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;

    let offsetZL = 0;
    let offsetZR = 0;

    const offsetY = 0;

    let scaleZLeft = this.roofLengthPlus / this.bargeLeftInfo.length;
    let bargeLeftLength = this.roofLengthPlus;
    let scaleZRight = this.roofLengthPlus / this.bargeRightInfo.length;
    let bargeRightLength = this.roofLengthPlus;

    const offsetPitch = this.roofBaseInfo.height * this.utils.tan(UI.patiosPitch)

    scaleZLeft = (bargeLeftLength - offsetPitch) / this.bargeLeftInfo.length;
    scaleZRight = (bargeRightLength - offsetPitch) / this.bargeRightInfo.length;

    let fitScale = this.bargeLeftInfo.height * this.utils.tan(UI.patiosPitch) / this.bargeLeftInfo.length;

    // left
    mesh = new Mesh(this.bargeLeftInfo.geometry, MaterialManager.Instance().BARGE);
    mesh.position.set(offsetXL2, offsetY, offsetZL - fitScale * this.bargeLeftInfo.length);
    mesh.scale.setZ(scaleZLeft + fitScale);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
    this.roofGroupFront.add(mesh);

    // right
    mesh = new Mesh(this.bargeRightInfo.geometry, MaterialManager.Instance().BARGE);
    mesh.position.set(offsetXR2, offsetY, offsetZR - fitScale * this.bargeLeftInfo.length);
    mesh.scale.setZ(scaleZRight + fitScale);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
    this.roofGroupFront.add(mesh);
  }
  private addBargeBack() {
    let views: Print2DView[] = [
      // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];
    let mesh: Mesh;
    let offsetXL2 = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);

    let offsetXR2 = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;

    let offsetZL = 0;
    let offsetZR = 0;

    const offsetY = 0;

    const offsetPitch = this.roofBaseInfo.height * this.utils.tan(UI.patiosPitch)

    let bargeLeftLength = ((UI.span + UI.multiSpan) / 2 + UI.overhangBack) / this.utils.cos(UI.patiosPitch);
    let bargeRightLength = ((UI.span + UI.multiSpan) / 2 + UI.overhangBack) / this.utils.cos(UI.patiosPitch);

    const scaleZLeft = (bargeLeftLength - offsetPitch) / this.bargeLeftInfo.length;
    const scaleZRight = (bargeRightLength - offsetPitch) / this.bargeRightInfo.length;

    let fitScale = this.bargeLeftInfo.height * this.utils.tan(UI.patiosPitch) / this.bargeLeftInfo.length;

    // left
    mesh = new Mesh(this.bargeLeftInfo.geometry, MaterialManager.Instance().BARGE);
    mesh.position.set(offsetXL2, offsetY, offsetZL - scaleZLeft * this.bargeLeftInfo.length);
    mesh.scale.setZ(scaleZLeft + fitScale);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
    this.roofGroupBack.add(mesh);

    // right
    mesh = new Mesh(this.bargeRightInfo.geometry, MaterialManager.Instance().BARGE);
    mesh.position.set(offsetXR2, offsetY, offsetZR - scaleZRight * this.bargeLeftInfo.length);
    mesh.scale.setZ(scaleZRight + fitScale);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
    this.roofGroupBack.add(mesh);
  }
  public addGutterFront() {
      let offsetXL = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
      const offsetZ = ((UI.span + UI.multiSpan) / 2 + UI.overhangFront - CONST.GUTTER_ROOF_OFFSET_Z) / this.utils.cos(UI.patiosPitch);
      const offsetY = this.roofBaseInfo.height - 25;

      let gutterLength = this.roofWidth;

      let views: Print2DView[] = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
      ];

      let gutterGroup = this.utils.createGutterGroup(this.gutterInfo, this.geo_gutterCap,
        gutterLength, new Vector3(offsetXL, offsetY, offsetZ), new Vector3(-this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), 0, 0),
        views, 1, true, true);
      this.roofGroupFront.add(gutterGroup);
  }
  public addGutterBack() {
    let offsetX = UI.totalBayLength / 2 + UI.overhangRight;
    let offsetZ = -((UI.span + UI.multiSpan) / 2 + UI.overhangBack - CONST.GUTTER_ROOF_OFFSET_Z) / this.utils.cos(UI.patiosPitch);
    let offsetY = this.roofBaseInfo.height - 25;
    let gutterLength = UI.totalBayLength + UI.overhangLeft + UI.overhangRight;
    //cutout
    if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.MANAGER.cutoutCondition) {
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
        gutterLength = UI.totalBayLength + UI.overhangLeft + UI.overhangRight - UI.existingLength2;
      }
      else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
        gutterLength = UI.totalBayLength + UI.overhangLeft + UI.overhangRight - UI.existingLength2;
        offsetX = UI.totalBayLength / 2 + UI.overhangRight - UI.existingLength2;
      }
      else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        offsetX = UI.existingLength1 / 2 - UI.eaveWidth;
        gutterLength = UI.existingLength1 - 2 * UI.eaveWidth;
      }
    }

    let views: Print2DView[] = [
      // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];

    //Add gutter back
    let gutterGroup = this.utils.createGutterGroup(this.gutterInfo, this.geo_gutterCap, gutterLength,
      new Vector3(offsetX, offsetY, offsetZ), new Vector3(this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), Math.PI, 0), views, 1, true, true);
    this.roofGroupBack.add(gutterGroup);
    this.addDownPipe(gutterGroup, gutterLength);
  }
  private addDownPipe(gutterGroup: Group, gutterLength: number) {
    let meshPipe = new Mesh(this.MANAGER.postAndBeamManager.geo_downPipe.geometry, MaterialManager.Instance().DOWNPIPE);
    meshPipe.userData = { type: GEOMETRY_TYPE.DOWNPIPE_NOZZLE };
    meshPipe.scale.setY(100 / this.MANAGER.postAndBeamManager.geo_downPipe.height);
    meshPipe.position.set(gutterLength - 100, -190, this.MANAGER.postAndBeamManager.geo_downPipe.width / 2 + 20);

    gutterGroup.add(meshPipe);
  }
  public getRoofOffsetY() {
    return UI.existingWallHeight
        + +UI.upstandBraketType
        + this.geometryManager.getBeam().height
        + this.utils.tan(UI.patiosPitch) * ((UI.span + UI.multiSpan) / 2);
  }
}

