import { HomeComponent as AppComponent, HomeComponent } from "../../containers/home/home.component";
import {
  Material,
  Mesh,
  BufferGeometry,
  Box3,
  Group,
  Plane,
  Vector3,
  Geometry,
  BoxHelper,
  BoxBufferGeometry,
  Matrix4,
  LineSegments,
} from "three";
import { Util, getBeamEndCapCode, getRafterBeamEndCapCode } from "../utils";
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import {
  CONFIG as env,
  GEOMETRY_TYPE,
  GEOMETRY_CATEGORY,
  INIT,
} from "src/app/app.config";
import {
  GeometryInfo,
  Printing2DLine,
  ViewType,
  LineType,
  Print2DView,
  Printing2DGeometry,
} from "src/app/core/models";
import { PatiosGableLRFlyOverManager } from ".";
import {
  EXISTING_BUILDING_CONFIG as CONST,
  BUILDING_SIDE,
  CUTOUT_ENABLE,
  FIT_FLYOVER_BRAKET_ON_ROOF,
  EXISTING_BUILDING_CONFIG,
} from "src/app/app.constants";
import { UI } from "../ui";
import _ from "lodash"

export class ColumnAndBeamManager {
  private scene: Group;
  private APP: AppComponent;
  private MANAGER: PatiosGableLRFlyOverManager;
  private material: Material;
  //private beamMaterial: Material;
  private utils: Util;

  private geometryManager: GeometryManager;

  private geo_RafterBeam: GeometryInfo;
  private geo_rafterBeamEndCap: GeometryInfo;

  private geo_superiorPost: GeometryInfo;
  private geo_centerSuperiorPost: GeometryInfo;
  public geo_superiorBeam: GeometryInfo;
  private geo_beamRakecutLeft: GeometryInfo;
  private geo_beamRakecutRight: GeometryInfo;
  private geo_beamStepRakecutLeft: GeometryInfo;
  private geo_beamStepRakecutRight: GeometryInfo;
  private geo_beamEndCap: GeometryInfo;
  public geo_bracket: GeometryInfo;
  private geo_groundBase: GeometryInfo;
  private geo_downPipe: GeometryInfo;
  private geo_downPipeL: GeometryInfo;
  private downpipeGroup: Group;
  private downpipeStepRakeCutGroupLeft: Group;
  private downpipeStepRakeCutGroupRight: Group;
  private eventHandleId: any;
  private objectSizeChangedHandle: any;
  private totalBaySize: number;
  private extraOffsetZ: number;

  private geoBeamJoint: GeometryInfo;

  private frontPostHeight: number;
  //private extraBracketHeight = 250;
  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;

  private deferHandle;
  private deferTimeout = CONST.CUTOUT_DEFFER_TIME_OUT;

  public numberOfBracketsBack = INIT.DEFAULT_BRACKET_NUMBER;
  public numberOfBracketCutout = INIT.DEFAULT_BRACKET_CUTOUT_NUMBER;
  private cutoutExistingBothLength: number;
  private beamLeftCutSizeInfo: any;
  private beamRightCutSizeInfo: any;

  constructor(app: AppComponent, flyOverManager: PatiosGableLRFlyOverManager) {
    this.APP = app;
    this.MANAGER = flyOverManager;
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();

    this.scene = flyOverManager.patiosGroup;
    this.material = MaterialManager.Instance().DEFAULT.clone();
    //this.beamMaterial = MaterialManager.Instance().DEFAULT.clone();

    this.registerEvent();
  }

  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      let bracketHeight =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.height / 2;
      let bracketWidth = this.geometryManager.FLY_OVER_BRACKET.S65x3.width;

      this.geo_bracket = new GeometryInfo();
      this.geo_bracket.geometry =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.geometry
          .clone()
          .rotateX(Math.PI / 2)
          .translate(bracketWidth / 2, bracketHeight, bracketWidth / 2);
      this.geo_bracket.width =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.width;
      this.geo_bracket.length =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.length;
      this.geo_bracket.height =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.height;

      this.geo_superiorBeam = this.geometryManager.getBeam();
      this.geo_superiorBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_superiorBeam.length / 2,
          this.geo_superiorBeam.height / 2,
          this.geo_superiorBeam.width / 2
        );

      this.geoBeamJoint = this.geometryManager.getBeamJoint()

      this.geo_beamRakecutLeft = this.geometryManager.getBeam();
      this.geo_beamRakecutLeft.geometry.translate(
        this.geo_beamRakecutLeft.width / 2,
        this.geo_beamRakecutLeft.height / 2,
        -this.geo_beamRakecutLeft.length / 2
      );

      this.geo_beamRakecutRight = this.geometryManager.getBeam();
      this.geo_beamRakecutRight.geometry.translate(
        -this.geo_beamRakecutRight.width / 2,
        this.geo_beamRakecutRight.height / 2,
        -this.geo_beamRakecutRight.length / 2
      );

      this.geo_beamStepRakecutLeft = this.geometryManager.getBeam();
      this.geo_beamStepRakecutLeft.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_beamStepRakecutLeft.length / 2,
          this.geo_beamStepRakecutLeft.height / 2,
          -this.geo_beamStepRakecutLeft.width / 2
        );

      this.geo_beamStepRakecutRight = this.geometryManager.getBeam();
      this.geo_beamStepRakecutRight.geometry
        .rotateY(Math.PI / 2)
        .translate(
          -this.geo_beamStepRakecutRight.length / 2,
          this.geo_beamStepRakecutRight.height / 2,
          -this.geo_beamStepRakecutRight.width / 2
        );

      this.geo_beamEndCap = this.geometryManager.getBeamEndCap();
      this.geo_beamEndCap.geometry.translate(
        0,
        this.geo_beamEndCap.height / 2,
        0
      );

      this.geo_superiorPost = this.geometryManager.getPost();
      this.geo_superiorPost.geometry
        .rotateX(Math.PI / 2)
        .translate(
          this.geo_superiorPost.width / 2,
          this.geo_superiorPost.height / 2,
          this.geo_superiorPost.width / 2
        );

      this.geo_centerSuperiorPost = this.geometryManager.getCenterPost();
      this.geo_centerSuperiorPost.geometry
        .rotateX(Math.PI / 2)
        .translate(
          this.geo_centerSuperiorPost.width / 2,
          this.geo_centerSuperiorPost.height / 2,
          this.geo_centerSuperiorPost.width / 2
        );

      this.geo_RafterBeam = this.geometryManager.getRafterBeam();
      this.geo_RafterBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_RafterBeam.length / 2,
          -this.geo_RafterBeam.height / 2,
          this.geo_RafterBeam.width / 2
        );

      this.geo_rafterBeamEndCap = this.geometryManager.getRafterBeamEndCap();
      this.geo_rafterBeamEndCap.geometry.translate(
        0,
        -this.geo_rafterBeamEndCap.height / 2,
        this.geo_rafterBeamEndCap.length / 2
      );

      this.geo_groundBase = new GeometryInfo();
      this.geo_groundBase.width = 1000;
      this.geo_groundBase.height = 1;
      this.geo_groundBase.length = 1000;
      this.geo_groundBase.geometry = new BoxBufferGeometry(
        this.geo_groundBase.width,
        this.geo_groundBase.height,
        this.geo_groundBase.length
      );
      this.geo_groundBase.geometry.translate(
        0,
        0,
        this.geo_groundBase.length / 2
      );

      this.geo_downPipe = this.geometryManager.getDownPipe();
      this.geo_downPipeL = this.geometryManager.getDownPipeL();

      this.scene.remove(
        ...this.scene.children.filter(
          (c) => c.userData.type == GEOMETRY_TYPE.DOWNPIPE
        )
      );
      this.downpipeGroup = new Group();
      this.downpipeGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeGroup);
      this.downpipeStepRakeCutGroupLeft = new Group();
      this.downpipeStepRakeCutGroupLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupLeft);

      this.downpipeStepRakeCutGroupRight = new Group();
      this.downpipeStepRakeCutGroupRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupRight);
      resolve();
    });
  }

  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(
        ...this.scene.children.filter(
          (x) =>
            x.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET &&
            !x.userData?.position?.back
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SUPERIOR_CENTER_POST
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) =>
            x.userData.type == GEOMETRY_TYPE.SUPERIOR_POST &&
            !x.userData?.position?.back
        )
      );
      // this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.MESH_OUTLINE));
      this.scene.remove(
        ...this.scene.children.filter((x) => x.userData.type == "BOXHELPER")
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (o) => o.userData.type == GEOMETRY_TYPE.GROUND_BASE
        )
      );
      this.APP.scene.remove(
        ...this.APP.scene.children.filter(
          (o) =>
            o.userData.type == "OUTLINE" || o.userData.type == "COLUMN_OUTLINE"
        )
      );

      this.beamLeftCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.LEFT
      );
      this.beamRightCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.RIGHT
      );
      this.downpipeGroup.children = [];
      this.downpipeStepRakeCutGroupLeft.children = [];
      this.downpipeStepRakeCutGroupRight.children = [];

      this.extraOffsetZ =
        this.APP.existingWallManager.geo_existingWallL1.width +
        this.APP.eaveManager.backOverhang;
      this.totalBaySize = this.APP.dialogEditBay.totalBaySize;
      this.cutoutExistingBothLength =
        (this.totalBaySize - this.APP.sldExistingLength.currentValue) / 2;

      this.addSuperiorBeam({ front: true });
      this.addSuperiorBeam({ back: true });

      if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.MANAGER.cutoutCondition
      ) {
        this.addSuperiorBeam({ cutout: true });
      }
      if (this.APP.sldMultiSpan.currentValue > 0) {
        this.addSuperiorBeam({ multiSpan: true });
      }

      this.addCenterSuperiorBeam();
      this.addGround();

      let offsetX = -this.totalBaySize / 2;
      let first = true;
      let last = false;
      for (let i = 0; i <= this.APP.dialogEditBay.listBay.length; i++) {
        if (i == this.APP.dialogEditBay.listBay.length) {
          last = true;
        }

        this.addCenterPost(offsetX, { center: true, first, last });

        let fitBeam = 0;
        if (i !== 0) {
          if (i == this.APP.dialogEditBay.listBay.length) {
            fitBeam = this.geo_superiorBeam.width;
          } else {
            fitBeam = this.geo_superiorBeam.width / 2;
          }
        }

        this.addHorSuperiorBeam(offsetX - fitBeam, {
          front: true,
          first,
          last,
        });
        this.addRafterSuperiorBeam(offsetX - fitBeam, {
          front: true,
          first,
          last,
        });
        this.addRafterSuperiorBeam(offsetX - fitBeam, {
          back: true,
          first,
          last,
        });

        this.addPostOrBracket(offsetX, {
          front: true,
          first,
          last,
          left: first,
          right: last,
        });

        if (this.APP.sldMultiSpan.currentValue > 0) {
          this.addPostOrBracket(offsetX, {
            multiSpan: true,
            first,
            last,
            left: first,
            right: last,
          });
        }
        if (i < this.APP.dialogEditBay.listBay.length) {
          let b = this.APP.dialogEditBay.listBay[i];
          offsetX += b.value;
        }
        first = false;
      }

      offsetX = -this.totalBaySize / 2;
      first = true;
      last = false;

      this.addPostOrBracketBack();
      this.addPostOrBracketBackCutout();
      this.showBeamOutline()
      this.addDownPipe();
      this.updateUI();
      resolve();
    });
  }
  public showBeamOutline() {
    this.APP.scene.remove(
      ...this.APP.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.BEAM_OUTLINE
      )
    );

    if (!UI.beamLayoutShow) return;

    const objs = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    const meshes = []
    objs.forEach(el => {
      if(el.type == 'Group') {
        meshes.push(...el.children.filter((o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM))
      }
    })

    for (let o of meshes) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        10
      );
      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);

      var line = new LineSegments(
        outlineGeo,
        MaterialManager.Instance().BEAM_OUTLINE
      );
      line.userData = { type: GEOMETRY_TYPE.BEAM_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  private totalHeightFromEaveHeightToTopOfExistingRoof(){
    const height = 
      this.geometryManager.EAVE.EAVE.height 
      + UI.fasciaDepth 
      + UI.eaveWidth * this.utils.tan(UI.existingRoofPitch) 
      + this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.height / this.utils.cos(UI.existingRoofPitch)

    return height
  }
  private totalHeightFromEaveHeightToTopOfFlyoverBraket(){
    const height = this.totalHeightFromEaveHeightToTopOfExistingRoof() + UI.braketHeight - FIT_FLYOVER_BRAKET_ON_ROOF;

    return height
  }
  private addDownPipe() {
    let offsetZ =
      this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue - 170;
    let offsetX = -this.totalBaySize / 2 + 50;
    if (this.APP.sltExistingType.currentValue == 1) {
      offsetX = this.totalBaySize / 2 - 50;
    }
    if (this.APP.sltExistingType.currentValue != 0) {
      offsetZ -= this.APP.sldExistingWidth1.currentValue / 2;
    }

    let offsetY =
      this.utils.getHeightByAngle(
        UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
        UI.overhangFront,
        UI.patiosPitch,
        -1
      ) +
      this.geo_superiorBeam.height +
      90;

    if (this.APP.sltGutterType.currentValue == 0) {
      offsetY -= 80;
    } else {
      offsetY -= 60;
    }

    this.utils.createDownpipeGroup(
      this.APP,
      this.downpipeGroup,
      this.geo_downPipe,
      this.geo_downPipeL,
      offsetX,
      offsetY,
      offsetZ,
      offsetZ +
        this.APP.sldFrontOverhang.currentValue -
        this.APP.sldFrontOverhang.currentValue,
      0,
      this.APP.sldFrontOverhang.currentValue,
      offsetY,
      true
    );
  }
  private updateUI() {
    this.APP.sldMinHeight.setValue(this.frontPostHeight);
  }
  public getSection(): Printing2DGeometry {
    let objs = this.scene.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_POST ||
        o.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET ||
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_CENTER_POST
    );

    objs = [...objs];

    let lsGeometries: Printing2DLine[] = [];

    for (let o of objs) {
      let box = new BoxHelper(o);
      box.geometry.translate(0, 5000, 0);
      box.userData = { type: "COLUMN_OUTLINE" };

      let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: outlineGeo.vertices,
        views: o.userData.views,
      });
    }
    let beamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    for (let g of beamGroups) {
      for (let c of g.children) {
        c.updateMatrix();

        let objCloned = c.clone();
        objCloned.applyMatrix4(new Matrix4().getInverse(c.matrix));

        let box = new BoxHelper(objCloned);

        c.updateWorldMatrix(true, true);

        box.geometry.applyMatrix4(c.matrixWorld);
        box.geometry.translate(0, 5000, 0);
        box.userData = { type: "COLUMN_OUTLINE" };

        let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
        const planView = _.find(g.userData.views, (el) => el.viewType == ViewType.PLAN)
        const anotherViews = _.filter(g.userData.views, (el) => el.viewType != ViewType.PLAN)
        if(anotherViews.length > 0) {
          lsGeometries.push({
            objectType: g.userData.type,
            vertices: outlineGeo.vertices,
            views: anotherViews,
          });
        } 
        if(planView) {
          lsGeometries.push({
            objectType: g.userData.type,
            vertices: outlineGeo.vertices.slice(0, 8).filter((el, index) => [0, 1, 4, 5].includes(index)),
            views: [planView],
          });
        }
      }
    }

    let rafterBeamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
    );
    for (let o of rafterBeamGroups) {
      o.children.forEach((el) => {
        this.utils.getOutlineRafterBeam(el as Mesh, lsGeometries);
      });
    }

    return { lines: lsGeometries, texts: [] };
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    //let simplifiedGeo = new Geometry();
    let vertices = geo.getAttribute("position").array;
    let lineGeo = new Geometry();
    for (let i = 0; i < vertices.length; i += 3) {
      //simplifiedGeo.vertices.push();

      lineGeo.vertices.push(
        new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2])
      );
      //lineGeo.vertices.push(new Vector3(vertices[i+3], vertices[i+4], vertices[i+5]));

      //if(i == 18) break;
    }

    //2-3
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    //3-7
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    //7-6
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    //6-2
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );

    //1-4
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    //4-8
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    //8-5
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    //5-1
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );

    //let line = new LineSegments(lineGeo, new LineBasicMaterial({color: new Color('red')}));
    //this.scene.add(line);
    return lineGeo;
  }

  private addGround() {
    let offsetZ = -(
      this.APP.sldExistingWidth1.currentValue / 2 +
      this.extraOffsetZ +
      this.APP.sldBackOverhang.currentValue
    );
    let width =
      this.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    let length =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue +
      this.APP.sldBackOverhang.currentValue;

    let base = new Mesh(
      this.geo_groundBase.geometry,
      MaterialManager.Instance().BASE
    );
    base.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.GROUND_BASE,
    };
    base.position.set((UI.overhangRight - UI.overhangLeft) / 2, 0, offsetZ);
    let offsetX =
      this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
      (this.geo_bracket?.width || 0) / 2;
    if (this.APP.sltExistingType.currentValue == 1) {
      base.position.setX(base.position.x + offsetX);
    }
    if (this.APP.sltExistingType.currentValue == 2) {
      base.position.setX(base.position.x - offsetX);
    }
    base.scale.set(
      width / this.geo_groundBase.width,
      1,
      length / this.geo_groundBase.length
    );

    this.scene.add(base);
  }
  private addPostOrBracket(offsetX: number, userDataPos: any): void {
    let meshPost = new Mesh(
      this.geo_superiorPost.geometry,
      MaterialManager.Instance().POST
    );
    meshPost.userData = {
      position: userDataPos,
      type: GEOMETRY_TYPE.SUPERIOR_POST,
      category: GEOMETRY_CATEGORY.PATIOS,
    };

    let meshBracket = new Mesh(
      this.geo_bracket.geometry,
      MaterialManager.Instance().BRACKET
    );
    meshBracket.userData = {
      position: userDataPos,
      type: GEOMETRY_TYPE.FLY_OVER_BRACKET,
      category: GEOMETRY_CATEGORY.PATIOS,
    };

    let offsetY = UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfExistingRoof() - FIT_FLYOVER_BRAKET_ON_ROOF;
    let postOffsetZ = 0;
    let bracketOffsetZ = 0;
    let scalePostY = 1;
    let scaleBracketY = 1;

    let views: Print2DView[];
    if (userDataPos.front) {
      postOffsetZ =
        this.APP.sldSpan.currentValue -
        this.APP.sldExistingWidth1.currentValue / 2 -
        this.extraOffsetZ;
      bracketOffsetZ =
        this.APP.sldSpan.currentValue -
        this.APP.sldExistingWidth1.currentValue / 2 -
        this.extraOffsetZ;
      scalePostY =
        (this.APP.sldExistingWallHeight.currentValue +
          this.totalHeightFromEaveHeightToTopOfFlyoverBraket()) /
        this.geo_superiorPost.height;
      scaleBracketY =
        UI.braketHeight / this.geo_bracket.height;

      if (this.APP.sldMultiSpan.currentValue > 0) {
        postOffsetZ -= this.geo_superiorPost.width / 2;
        bracketOffsetZ -= this.geometryManager.FLY_OVER_BRACKET.S65x3.width / 2;
      } else {
        postOffsetZ -= this.geo_superiorPost.width;
        bracketOffsetZ -= this.geometryManager.FLY_OVER_BRACKET.S65x3.width;
      }

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }
    if (userDataPos.back) {
      postOffsetZ =
        -this.APP.sldExistingWidth1.currentValue / 2 - this.extraOffsetZ;
      bracketOffsetZ =
        -this.APP.sldExistingWidth1.currentValue / 2 - this.extraOffsetZ;
      scalePostY =
        (this.APP.sldExistingWallHeight.currentValue +
          this.totalHeightFromEaveHeightToTopOfFlyoverBraket()) /
        this.geo_superiorPost.height;
      scaleBracketY =
        UI.braketHeight / this.geo_bracket.height;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }
    if (userDataPos.multiSpan) {
      postOffsetZ =
        UI.span +
        UI.multiSpan -
        this.APP.sldExistingWidth1.currentValue / 2 -
        this.geo_superiorPost.width -
        this.extraOffsetZ;
      bracketOffsetZ =
        UI.span +
        UI.multiSpan -
        this.APP.sldExistingWidth1.currentValue / 2 -
        this.geometryManager.FLY_OVER_BRACKET.S65x3.width -
        this.extraOffsetZ;
      scalePostY =
        (this.APP.sldExistingWallHeight.currentValue +
          this.totalHeightFromEaveHeightToTopOfFlyoverBraket()) /
        this.geo_superiorPost.height;
      scaleBracketY =
        UI.braketHeight / this.geo_bracket.height;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    meshPost.userData.views = views;
    meshBracket.userData.views = views;

    let bracketOffsetX = offsetX;
    let postOffsetX = offsetX;
    if (userDataPos.first) {
    } else if (userDataPos.last) {
      bracketOffsetX -= this.geo_bracket.width;
      postOffsetX -= this.geo_superiorPost.width;
    } else {
      bracketOffsetX -= this.geo_bracket.width / 2;
      postOffsetX -= this.geo_superiorPost.width / 2;
    }

    let exceedMoveBackLimit = false;

    //post can not go behind existing wall
    if (exceedMoveBackLimit) {
      return;
    }

    meshPost.position.set(postOffsetX, 0, postOffsetZ);
    meshPost.scale.setY(scalePostY);
    meshBracket.position.set(bracketOffsetX, offsetY, bracketOffsetZ);
    meshBracket.scale.setY(scaleBracketY);
    let boxPost = new Box3().setFromObject(meshPost);
    // let boxPostHelper = new Box3Helper(boxPost);
    // this.APP.scene.add(boxPostHelper);
    boxPost.translate(this.scene.position);
    let isIntersect = false;
    for (let eave of this.APP.eaveManager.listEave) {
      let boxEave = new Box3().setFromObject(eave);
      if (boxPost.intersectsBox(boxEave)) {
        isIntersect = true;
        break;
      }
    }

    if (isIntersect) {
      if (this.APP.sltExistingType.currentValue != 3) {
        if (meshBracket.userData.position.left) {
          meshBracket.translateX(
            this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
              (this.geo_bracket?.width || 0) / 2
          );
        } else if (meshBracket.userData.position.right) {
          meshBracket.translateX(
            -(
              this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
              (this.geo_bracket?.width || 0) / 2
            )
          );
        }
      }
      this.scene.add(meshBracket);
    } else {
      if (this.APP.sltExistingType.currentValue == 1) {
        meshPost.translateX(
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
            (this.geo_bracket?.width || 0) / 2
        );
      } else if (this.APP.sltExistingType.currentValue == 2) {
        meshPost.translateX(
          -(
            this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
            (this.geo_bracket?.width || 0) / 2
          )
        );
      }
      this.scene.add(meshPost);
    }
  }
  public addPostOrBracketBack() {
    this.scene.remove(
      ...this.scene.children.filter(
        (x) =>
          x.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET &&
          x.userData?.position?.back
      )
    );
    this.scene.remove(
      ...this.scene.children.filter(
        (x) =>
          x.userData.type == GEOMETRY_TYPE.SUPERIOR_POST &&
          x.userData?.position?.back
      )
    );

    let first = true;
    let last = false;
    let offsetX = -this.totalBaySize / 2;
    let userDataPos = { back: true };

    let bracketDistance = this.totalBaySize / (this.numberOfBracketsBack - 1);
    let cutoutLengthInExistingBoth =
      (this.totalBaySize - this.APP.sldExistingLength.currentValue) / 2;
    let extraWalWidth =
      this.APP.existingWallManager.geo_existingWallL1.width +
      CONST.EAVE_OFFSET_BACK;

    if (
      this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
      this.MANAGER.cutoutCondition
    ) {
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
        if (this.APP.sldExistingWidth2.currentValue > 0) {
          offsetX += this.APP.sldExistingLength2.currentValue;
          bracketDistance =
            (this.totalBaySize - this.APP.sldExistingLength2.currentValue) /
            (this.numberOfBracketsBack - 1);
        } else {
          offsetX +=
            this.APP.sldExistingLength2.currentValue -
            (this.APP.existingWallManager.geo_existingWallW1.width +
              CONST.EAVE_OFFSET_BACK);
          bracketDistance =
            (this.totalBaySize -
              this.APP.sldExistingLength2.currentValue +
              extraWalWidth) /
            (this.numberOfBracketsBack - 1);
        }
      } else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
        if (this.APP.sldExistingWidth2.currentValue > 0) {
          bracketDistance =
            (this.totalBaySize - this.APP.sldExistingLength2.currentValue) /
            (this.numberOfBracketsBack - 1);
        } else {
          bracketDistance =
            (this.totalBaySize -
              this.APP.sldExistingLength2.currentValue +
              extraWalWidth) /
            (this.numberOfBracketsBack - 1);
        }
      } else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        offsetX += cutoutLengthInExistingBoth - extraWalWidth;
        bracketDistance =
          (this.APP.sldExistingLength.currentValue + extraWalWidth * 2) /
          (this.numberOfBracketsBack - 1);
      }
    }

    for (let i = 0; i < this.numberOfBracketsBack; i++) {
      if (i == this.numberOfBracketsBack - 1) {
        last = true;
      }
      let meshPost = new Mesh(
        this.geo_superiorPost.geometry,
        MaterialManager.Instance().POST
      );
      meshPost.userData = {
        position: userDataPos,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        category: GEOMETRY_CATEGORY.PATIOS,
      };

      let meshBracket = new Mesh(
        this.geo_bracket.geometry,
        MaterialManager.Instance().BRACKET
      );
      meshBracket.userData = {
        position: userDataPos,
        type: GEOMETRY_TYPE.FLY_OVER_BRACKET,
        category: GEOMETRY_CATEGORY.PATIOS,
      };

      let bracketOffsetY = UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfExistingRoof() - FIT_FLYOVER_BRAKET_ON_ROOF;
      let postOffsetZ = 0;
      let bracketOffsetZ = 0;

      let scalePostY = 1;
      let scaleBracketY = 1;

      let views: Print2DView[];

      postOffsetZ =
        -this.APP.sldExistingWidth1.currentValue / 2 - this.extraOffsetZ;
      bracketOffsetZ =
        -this.APP.sldExistingWidth1.currentValue / 2 - this.extraOffsetZ;
      scalePostY =
        (this.APP.sldExistingWallHeight.currentValue +
          this.totalHeightFromEaveHeightToTopOfFlyoverBraket()) /
        this.geo_superiorPost.height;
      scaleBracketY =
        UI.braketHeight /
        this.geometryManager.FLY_OVER_BRACKET.S65x3.height;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];

      meshPost.userData.views = views;
      meshBracket.userData.views = views;

      let bracketOffsetX = offsetX;
      let postOffsetX = offsetX;

      if (first) {
      } else if (last) {
        bracketOffsetX -= this.geo_bracket.width;
        postOffsetX -= this.geo_superiorPost.width;
      } else {
        bracketOffsetX -= this.geo_bracket.width;
        postOffsetX -= this.geo_superiorPost.width;
      }

      meshPost.position.set(postOffsetX, 0, postOffsetZ);
      meshPost.scale.setY(scalePostY);
      meshBracket.position.set(bracketOffsetX, bracketOffsetY, bracketOffsetZ);
      meshBracket.scale.setY(scaleBracketY);

      //Check intersect eave
      let boxPost = new Box3().setFromObject(meshPost);
      boxPost.translate(this.scene.position);

      let isIntersect = false;
      //let isCutoutInterset = false;
      for (let eave of this.APP.eaveManager.listEave) {
        let boxEave = new Box3().setFromObject(eave);
        if (boxPost.intersectsBox(boxEave)) {
          isIntersect = true;
        }
      }

      if (isIntersect) {
        if (
          i == 0 &&
          (this.APP.sltExistingType.currentValue == 1 ||
            this.APP.sltExistingType.currentValue == 3)
        ) {
          meshBracket.translateX(
            this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
              (this.geo_bracket?.width || 0) / 2
          );
        } else if (
          last &&
          (this.APP.sltExistingType.currentValue == 2 ||
            this.APP.sltExistingType.currentValue == 3)
        ) {
          meshBracket.translateX(
            -(
              this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
              (this.geo_bracket?.width || 0) / 2
            )
          );
        }

        this.scene.add(meshBracket);
      } else {
        this.scene.add(meshPost);
      }

      if (i < this.numberOfBracketsBack) {
        offsetX += bracketDistance;
      }
      first = false;
    }
  }
  public addPostOrBracketBackCutout(): void {
    this.scene.remove(
      ...this.scene.children.filter(
        (x) =>
          x.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET &&
          x.userData?.position?.back &&
          x.userData?.position?.cutout
      )
    );
    this.scene.remove(
      ...this.scene.children.filter(
        (x) =>
          x.userData.type == GEOMETRY_TYPE.SUPERIOR_POST &&
          x.userData?.position?.back &&
          x.userData?.position?.cutout
      )
    );

    if (
      this.APP.sltCutOut.currentValue != CUTOUT_ENABLE.YES ||
      !this.MANAGER.cutoutCondition
    )
      return;

    let first = true;
    let last = false;
    let offsetXL = -this.totalBaySize / 2;
    let offsetXR = this.totalBaySize / 2;

    let userDataPos = { back: true, cutout: true };

    let extraWalWidth =
      this.APP.existingWallManager.geo_existingWallL1.width +
      CONST.EAVE_OFFSET_BACK;
    let bracketDistance =
      (this.APP.sldExistingLength2.currentValue - extraWalWidth) /
      (this.numberOfBracketCutout - 1);
    if (this.APP.sldExistingWidth2.currentValue > 0) {
      bracketDistance =
        this.APP.sldExistingLength2.currentValue /
        (this.numberOfBracketCutout - 1);
    }

    if (
      this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT ||
      this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH
    ) {
      for (let i = 0; i < this.numberOfBracketCutout; i++) {
        if (i == this.numberOfBracketCutout - 1) {
          last = true;
        }

        let meshPost = new Mesh(
          this.geo_superiorPost.geometry,
          MaterialManager.Instance().POST
        );
        meshPost.userData = {
          position: userDataPos,
          type: GEOMETRY_TYPE.SUPERIOR_POST,
          category: GEOMETRY_CATEGORY.PATIOS,
        };

        let meshBracket = new Mesh(
          this.geo_bracket.geometry,
          MaterialManager.Instance().BRACKET
        );
        meshBracket.userData = {
          position: userDataPos,
          type: GEOMETRY_TYPE.FLY_OVER_BRACKET,
          category: GEOMETRY_CATEGORY.PATIOS,
        };

        //let offsetX = 0;
        let offsetY = this.APP.sldExistingWallHeight.currentValue;

        let postOffsetZ =
          -this.APP.sldExistingWidth1.currentValue / 2 -
          this.extraOffsetZ +
          this.APP.sldExistingWidth1.currentValue;
        let bracketOffsetZ =
          -this.APP.sldExistingWidth1.currentValue / 2 -
          this.extraOffsetZ +
          this.APP.sldExistingWidth1.currentValue;

        let views: Print2DView[];

        let scalePostY =
          this.utils.getHeightByAngle(
            this.APP.sldExistingWallHeight.currentValue +
              this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
            UI.existingWidth1 > (UI.span + UI.multiSpan) / 2
              ? Math.abs(UI.span + UI.multiSpan - UI.existingWidth1)
              : UI.existingWidth1,
            UI.patiosPitch,
            1
          ) / this.geo_superiorPost.height;

        let scaleBracketY =
          this.utils.getHeightByAngle(
            this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
            UI.existingWidth1 > (UI.span + UI.multiSpan) / 2
              ? Math.abs(UI.span + UI.multiSpan - UI.existingWidth1)
              : UI.existingWidth1,
            UI.patiosPitch,
            1
          ) / this.geometryManager.FLY_OVER_BRACKET.S65x3.height;

        views = [
          { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
          { viewType: ViewType.PLAN, lineType: LineType.DASHED },
        ];

        meshPost.userData.views = views;
        meshBracket.userData.views = views;

        let bracketOffsetX = offsetXL;
        let postOffsetX = offsetXL;
        if (first) {
        } else if (last) {
        } else {
          bracketOffsetX -= this.geo_bracket.width / 2;
          postOffsetX -= this.geo_superiorPost.width / 2;
        }

        meshPost.position.set(postOffsetX, 0, postOffsetZ);
        meshPost.scale.setY(scalePostY);

        meshBracket.position.set(bracketOffsetX, offsetY, bracketOffsetZ);
        meshBracket.scale.setY(scaleBracketY);

        //Check intersect eave
        let boxPost = new Box3().setFromObject(meshPost);
        boxPost.translate(this.scene.position);
        let isIntersect = false;
        for (let eave of this.APP.eaveManager.listEave) {
          let boxEave = new Box3().setFromObject(eave);
          if (boxPost.intersectsBox(boxEave)) {
            isIntersect = true;
            break;
          }
        }

        if (isIntersect) {
          if (this.APP.sltExistingType.currentValue == 1) {
            meshBracket.translateX(
              this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
                (this.geo_bracket?.width || 0) / 2
            );
          }
          this.scene.add(meshBracket);
        } else {
          this.scene.add(meshPost);
        }

        if (i < this.numberOfBracketCutout) {
          offsetXL += bracketDistance;
        }
        first = false;
      }
    }

    if (
      this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT ||
      this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH
    ) {
      first = true;

      for (let i = 0; i < this.numberOfBracketCutout; i++) {
        if (i == this.numberOfBracketCutout - 1) {
          last = true;
        }

        let meshPost = new Mesh(
          this.geo_superiorPost.geometry,
          MaterialManager.Instance().POST
        );
        meshPost.userData = {
          position: userDataPos,
          type: GEOMETRY_TYPE.SUPERIOR_POST,
          category: GEOMETRY_CATEGORY.PATIOS,
        };

        let meshBracket = new Mesh(
          this.geo_bracket.geometry,
          MaterialManager.Instance().BRACKET
        );
        meshBracket.userData = {
          position: userDataPos,
          type: GEOMETRY_TYPE.FLY_OVER_BRACKET,
          category: GEOMETRY_CATEGORY.PATIOS,
        };

        let offsetY = this.APP.sldExistingWallHeight.currentValue;

        let postOffsetZ =
          -this.APP.sldExistingWidth1.currentValue / 2 -
          this.extraOffsetZ +
          this.APP.sldExistingWidth1.currentValue;
        let bracketOffsetZ =
          -this.APP.sldExistingWidth1.currentValue / 2 -
          this.extraOffsetZ +
          this.APP.sldExistingWidth1.currentValue;

        let views: Print2DView[];

        let scalePostY =
          this.utils.getHeightByAngle(
            this.APP.sldExistingWallHeight.currentValue +
              this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
            UI.existingWidth1 > (UI.span + UI.multiSpan) / 2
              ? Math.abs(UI.span + UI.multiSpan - UI.existingWidth1)
              : UI.existingWidth1,
            UI.patiosPitch,
            1
          ) / this.geo_superiorPost.height;

        let scaleBracketY =
          this.utils.getHeightByAngle(
            this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
            UI.existingWidth1 > (UI.span + UI.multiSpan) / 2
              ? Math.abs(UI.span + UI.multiSpan - UI.existingWidth1)
              : UI.existingWidth1,
            UI.patiosPitch,
            1
          ) / this.geometryManager.FLY_OVER_BRACKET.S65x3.height;

        views = [
          { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
          { viewType: ViewType.PLAN, lineType: LineType.DASHED },
        ];

        meshPost.userData.views = views;
        meshBracket.userData.views = views;

        let bracketOffsetX = offsetXR;
        let postOffsetX = offsetXR;
        if (first) {
          if (
            this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT ||
            this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH
          ) {
            bracketOffsetX -= this.geo_bracket.width;
            postOffsetX -= this.geo_superiorPost.width;
          }
        } else if (last) {
          bracketOffsetX -= this.geo_bracket.width;
          postOffsetX -= this.geo_superiorPost.width;
        } else {
          bracketOffsetX -= this.geo_bracket.width / 2;
          postOffsetX -= this.geo_superiorPost.width / 2;
        }

        meshPost.position.set(postOffsetX, 0, postOffsetZ);
        meshPost.scale.setY(scalePostY);

        meshBracket.position.set(bracketOffsetX, offsetY, bracketOffsetZ);
        meshBracket.scale.setY(scaleBracketY);

        //Check intersect eave
        let boxPost = new Box3().setFromObject(meshPost);
        boxPost.translate(this.scene.position);
        let isIntersect = false;
        for (let eave of this.APP.eaveManager.listEave) {
          let boxEave = new Box3().setFromObject(eave);
          if (boxPost.intersectsBox(boxEave)) {
            isIntersect = true;
            break;
          }
        }

        if (isIntersect) {
          if (this.APP.sltExistingType.currentValue == 2) {
            meshBracket.translateX(
              -(
                this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
                (this.geo_bracket?.width || 0) / 2
              )
            );
          }

          this.scene.add(meshBracket);
        } else {
          this.scene.add(meshPost);
        }

        if (i < this.numberOfBracketCutout) {
          offsetXR -= bracketDistance;
        }
        first = false;
      }
    }
  }

  public addCenterSuperiorBeam() {
    let offsetXL = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );
    let offsetZ = 0;
    let offsetY = 0;

    let beamLength = this.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue

    let views: Print2DView[];

    offsetY =
      UI.existingWallHeight +
      this.totalHeightFromEaveHeightToTopOfFlyoverBraket() +
      ((UI.span + UI.multiSpan) / 2 - this.geo_superiorBeam.width / 2) *
        this.utils.tan(UI.patiosPitch);
    offsetZ =
      (UI.span + UI.multiSpan) / 2 -
      this.geo_superiorBeam.width / 2 -
      UI.existingWidth1 / 2 -
      this.extraOffsetZ;

    views = [
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    const fitX = this.getContainerOffsetXToFitBraketToExistingWall();

    this.cutStandardBeamByCutBeamWithinBayControl(
      this.geo_superiorBeam,
      this.geo_beamEndCap,
      beamLength,
      new Vector3(offsetXL + fitX, offsetY, offsetZ),
      new Vector3(),
      views,
      1,
      { center: true },
      'RB',
      this.geoBeamJoint
    )
  }

  private addCenterPost(offsetX, userDataPos) {
    let meshPost = new Mesh(
      this.geo_centerSuperiorPost.geometry,
      MaterialManager.Instance().BEAM
    );
    meshPost.userData = {
      ...userDataPos,
      type: GEOMETRY_TYPE.SUPERIOR_CENTER_POST,
      category: GEOMETRY_CATEGORY.PATIOS,
    };

    let scalePostY = 1;

    let views: Print2DView[];

    let postOffsetZ =
      (UI.span + UI.multiSpan) / 2 -
      this.geo_centerSuperiorPost.width / 2 -
      UI.existingWidth1 / 2 -
      this.extraOffsetZ;

    this.frontPostHeight = UI.existingWallHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket();

    scalePostY =
      (((UI.span + UI.multiSpan) / 2 - this.geo_superiorBeam.width / 2) *
        this.utils.tan(UI.patiosPitch) -
        this.geo_superiorBeam.height) /
      this.geo_centerSuperiorPost.height;
    views = [
      // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    let postOffsetX = offsetX - this.geo_centerSuperiorPost.width / 2;

    if (userDataPos.first) {
      postOffsetX += this.geo_centerSuperiorPost.width / 2;
    } else if (userDataPos.last) {
      postOffsetX -= this.geo_centerSuperiorPost.width / 2;
    } else {
    }

    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      postOffsetX -= offsetX;
    }
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      postOffsetX += offsetX;
    }

    meshPost.userData.views = views;
    meshPost.position.set(
      postOffsetX,
      UI.existingWallHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket() + this.geo_superiorBeam.height,
      postOffsetZ
    );
    meshPost.scale.setY(scalePostY);

    this.scene.add(meshPost);
  }

  public addHorSuperiorBeam(offsetX, userDataPos: any) {
    let mesh = new Mesh(
      this.geo_superiorBeam.geometry,
      MaterialManager.Instance().BEAM
    );

    let offsetXL = 0;
    let offsetXR = UI.span + UI.multiSpan - 2 * this.geo_superiorBeam.width;
    let offsetZ = 0;
    let offsetY = 0;

    let scaleX =
      (UI.span + UI.multiSpan - 2 * this.geo_superiorBeam.width) /
      this.geo_superiorBeam.length;

    let views: Print2DView[];

    if (userDataPos.front) {
      offsetY = UI.existingWallHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket();
      offsetZ =
        UI.span +
        UI.multiSpan -
        this.geo_superiorBeam.width -
        UI.existingWidth1 / 2 -
        this.extraOffsetZ;

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_BEAM,
      position: userDataPos,
      views: views,
    };
    mesh.position.set(offsetXL, 0, 0);
    mesh.scale.setX(scaleX);

    let capL = new Mesh(
      this.geo_beamEndCap.geometry,
      MaterialManager.Instance().BEAM
    );
    capL.position.set(
      offsetXL + this.geo_beamEndCap.length / 2 - 2,
      0,
      this.geo_beamEndCap.width / 2
    );
    capL.rotateY(Math.PI / 2);
    capL.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: getBeamEndCapCode( this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode)
    }
    let capR = new Mesh(
      this.geo_beamEndCap.geometry,
      MaterialManager.Instance().BEAM
    );
    capR.position.set(
      offsetXR - this.geo_beamEndCap.length / 2 + 2,
      0,
      this.geo_beamEndCap.width / 2
    );
    capR.rotateY(-Math.PI / 2);
    capR.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: getBeamEndCapCode( this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode)
    }

    let beamOffsetX = offsetX;

    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      beamOffsetX -= offsetX;
    }
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      beamOffsetX += offsetX;
    }

    let beamGroup = new Group();
    beamGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_BEAM,
      position: userDataPos,
      views: views,
      name: "GB",
    };
    beamGroup.position.set(beamOffsetX, offsetY, offsetZ);
    if (this.APP.sltBeamType.currentValue == 0) {
      beamGroup.add(mesh, capL, capR);
    } else {
      beamGroup.add(mesh);
    }

    beamGroup.rotateY(Math.PI / 2);

    this.scene.add(beamGroup);
  }

  public addRafterSuperiorBeam(offsetX, userDataPos: any) {
    if (
      this.APP.sltWindClass.currentValue == 0 ||
      this.APP.sltWindClass.currentValue == 1
    ) {
      if (this.totalBaySize <= 6000 && this.APP.sldSpan.currentValue <= 7000) {
        return;
      }
    }
    if (
      this.APP.sltWindClass.currentValue == 3 ||
      this.APP.sltWindClass.currentValue == 4 ||
      this.APP.sltWindClass.currentValue == 5
    ) {
      return;
    }
    let mesh;
    const beamWidth = this.geo_superiorBeam.width
    const braketWidth = this.geo_bracket.width
    let offsetXL = 0;
    let offsetXR =
      ((UI.span + UI.multiSpan) / 2 - beamWidth / 2) /
      this.utils.cos(UI.patiosPitch);
    let offsetZ = 0;
    let offsetY = 0;

    let scaleX =
      ((UI.span + UI.multiSpan) / 2 - beamWidth / 2) /
      this.utils.cos(UI.patiosPitch) /
      this.geo_RafterBeam.length;

    let views: Print2DView[];

    if (userDataPos.front) {
      let material = MaterialManager.Instance().BEAM.clone();

      let nBottom = new Vector3(0, 1, 0).normalize();
      let pBottom = new Vector3(
        0,
        UI.existingWallHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket() + this.geo_superiorBeam.height,
        0
      );
      let planeBottom = new Plane().setFromNormalAndCoplanarPoint(
        nBottom,
        pBottom
      );

      let nVer = new Vector3(0, 0, 1).normalize();
      let pVer = new Vector3(
        0,
        0,
        (UI.span + UI.multiSpan) / 2 - braketWidth +
        beamWidth / 2 -
          UI.existingWidth1 / 2
      );
      let planeVer = new Plane().setFromNormalAndCoplanarPoint(nVer, pVer);

      material.clippingPlanes = [planeBottom, planeVer];

      mesh = new Mesh(this.geo_RafterBeam.geometry, material);

      offsetY =
        UI.existingWallHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket() + this.geo_superiorBeam.height;
      offsetZ =
        UI.span + UI.multiSpan - UI.existingWidth1 / 2 - this.extraOffsetZ;

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.back) {
      let material = MaterialManager.Instance().BEAM.clone();

      let nBottom = new Vector3(0, 1, 0).normalize();
      let pBottom = new Vector3(
        0,
        UI.existingWallHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket() + this.geo_superiorBeam.height,
        0
      );
      let planeBottom = new Plane().setFromNormalAndCoplanarPoint(
        nBottom,
        pBottom
      );

      let nVer = new Vector3(0, 0, -1).normalize();
      let pVer = new Vector3(
        0,
        0,
        (UI.span + UI.multiSpan) / 2 - braketWidth -
        this.geo_superiorBeam.width * 0.5 -
          UI.existingWidth1 / 2
      );
      let planeVer = new Plane().setFromNormalAndCoplanarPoint(nVer, pVer);

      material.clippingPlanes = [planeBottom, planeVer];

      mesh = new Mesh(this.geo_RafterBeam.geometry, material);

      offsetY =
        UI.existingWallHeight +
        this.totalHeightFromEaveHeightToTopOfFlyoverBraket() +
        this.geo_superiorBeam.height +
        ((UI.span + UI.multiSpan) / 2 - this.geo_superiorBeam.width / 2) *
          this.utils.tan(UI.patiosPitch);
      offsetZ =
        (UI.span + UI.multiSpan) / 2 -
        this.geo_superiorBeam.width / 2 -
        UI.existingWidth1 / 2 -
        this.extraOffsetZ;

      views = [
        // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM,
      position: userDataPos,
      views: views,
    };
    mesh.position.set(offsetXL, 0, 0);
    mesh.scale.setX(scaleX);

    let checkExec = /\w{1}\d{1,3}x(\d{1,3})/.exec(this.geo_RafterBeam.name);
    let beamSize = checkExec && checkExec.length == 2 ? checkExec[1] : "";
    let lowerBraketName = getRafterBeamEndCapCode(EXISTING_BUILDING_CONFIG.RAFTER_BEAM_SIZE, false);
    let higherBraketName = getRafterBeamEndCapCode(EXISTING_BUILDING_CONFIG.RAFTER_BEAM_SIZE, true);

    let capL;
    let capR;
    if (userDataPos.front) {
      let matEndCap = MaterialManager.Instance().BEAM.clone();

      let heightReal =
        UI.existingWallHeight +
        this.totalHeightFromEaveHeightToTopOfFlyoverBraket() +
        this.geo_superiorBeam.height +
        (this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)) / 2;
      let heightPlane = heightReal * this.utils.cos(UI.patiosPitch);
      let translateZ = this.utils.sin(UI.patiosPitch) * heightPlane;

      let planeTop = new Plane(new Vector3(0, -1, 0));
      let matrixTranslateTop = new Matrix4().makeTranslation(
        0,
        heightPlane,
        translateZ / 2
      );
      let matrixRotateTop = new Matrix4().makeRotationX(
        this.utils.degreesToRadians(UI.patiosPitch)
      ); //tao voi z pitch do
      let matrixTotalTop = matrixTranslateTop.multiply(matrixRotateTop);
      planeTop.applyMatrix4(matrixTotalTop);

      let heightRealBottom =
        UI.existingWallHeight +
        this.totalHeightFromEaveHeightToTopOfFlyoverBraket() +
        this.geo_superiorBeam.height -
        this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
        (this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)) / 2;
      let heightPlaneBottom = heightRealBottom * this.utils.cos(UI.patiosPitch);
      let translateZBottom = this.utils.sin(UI.patiosPitch) * heightPlaneBottom;

      let planeBottom = new Plane(new Vector3(0, 1, 0));
      let matrixTranslateBottom = new Matrix4().makeTranslation(
        0,
        heightPlaneBottom,
        translateZBottom / 2
      );
      let matrixRotateBottom = new Matrix4().makeRotationX(
        this.utils.degreesToRadians(UI.patiosPitch)
      ); //tao voi z pitch do
      let matrixTotalBottom =
        matrixTranslateBottom.multiply(matrixRotateBottom);
      planeBottom.applyMatrix4(matrixTotalBottom);

      planeTop.translate(
        new Vector3(
          0,
          0,
          (UI.span + UI.multiSpan) / 2 -
            UI.existingWidth1 / 2 -
            this.extraOffsetZ +
            this.scene.position.z
        )
      );
      planeBottom.translate(
        new Vector3(
          0,
          0,
          (UI.span + UI.multiSpan) / 2 -
            UI.existingWidth1 / 2 -
            this.extraOffsetZ +
            this.scene.position.z
        )
      );

      matEndCap.clippingPlanes = [planeTop, planeBottom];

      capL = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capL.position.set(offsetXL - 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capL.scale.setY(
        (this.geo_RafterBeam.height / this.utils.sin(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length / this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capL.rotateY(Math.PI / 2);
      capL.rotateX(-Math.PI / 2 + this.utils.degreesToRadians(UI.patiosPitch));
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: lowerBraketName,
      };

      capR = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capR.position.set(offsetXR + 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capR.scale.setY(
        (this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length * this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capR.rotateY(-Math.PI / 2);
      capR.rotateX(-this.utils.degreesToRadians(UI.patiosPitch));
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: higherBraketName,
      };
    } else {
      let matEndCap = MaterialManager.Instance().BEAM.clone();

      let heightReal =
        UI.existingWallHeight +
        this.totalHeightFromEaveHeightToTopOfFlyoverBraket() +
        this.geo_superiorBeam.height +
        (this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)) / 2;
      let heightPlane = heightReal * this.utils.cos(UI.patiosPitch);
      let translateZ = this.utils.sin(UI.patiosPitch) * heightPlane;

      let planeTop = new Plane(new Vector3(0, -1, 0));
      let matrixTranslateTop = new Matrix4().makeTranslation(
        0,
        heightPlane,
        -translateZ / 2
      );
      let matrixRotateTop = new Matrix4().makeRotationX(
        -this.utils.degreesToRadians(UI.patiosPitch)
      ); //tao voi z pitch do
      let matrixTotalTop = matrixTranslateTop.multiply(matrixRotateTop);
      planeTop.applyMatrix4(matrixTotalTop);

      let heightRealBottom =
        UI.existingWallHeight +
        this.totalHeightFromEaveHeightToTopOfFlyoverBraket() +
        this.geo_superiorBeam.height -
        this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
        (this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan)) / 2;
      let heightPlaneBottom = heightRealBottom * this.utils.cos(UI.patiosPitch);
      let translateZBottom = this.utils.sin(UI.patiosPitch) * heightPlaneBottom;

      let planeBottom = new Plane(new Vector3(0, 1, 0));
      let matrixTranslateBottom = new Matrix4().makeTranslation(
        0,
        heightPlaneBottom,
        -translateZBottom / 2
      );
      let matrixRotateBottom = new Matrix4().makeRotationX(
        -this.utils.degreesToRadians(UI.patiosPitch)
      ); //tao voi z pitch do
      let matrixTotalBottom =
        matrixTranslateBottom.multiply(matrixRotateBottom);
      planeBottom.applyMatrix4(matrixTotalBottom);

      planeTop.translate(
        new Vector3(
          0,
          0,
          (UI.span + UI.multiSpan) / 2 -
            UI.existingWidth1 / 2 -
            this.extraOffsetZ +
            this.scene.position.z
        )
      );
      planeBottom.translate(
        new Vector3(
          0,
          0,
          (UI.span + UI.multiSpan) / 2 -
            UI.existingWidth1 / 2 -
            this.extraOffsetZ +
            this.scene.position.z
        )
      );

      matEndCap.clippingPlanes = [planeTop, planeBottom];

      capL = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capL.position.set(offsetXL - 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capL.scale.setY(
        (this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length * this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capL.rotateY(Math.PI / 2);
      capL.rotateX(-this.utils.degreesToRadians(UI.patiosPitch));
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: higherBraketName,
      };

      capR = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capR.position.set(offsetXR + 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capR.scale.setY(
        (this.geo_RafterBeam.height / this.utils.sin(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length / this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capR.rotateY(-Math.PI / 2);
      capR.rotateX(-Math.PI / 2 + this.utils.degreesToRadians(UI.patiosPitch));
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: lowerBraketName,
      };
    }

    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
      let fitZ =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      offsetX -= fitZ;
    }
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      let fitZ =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      offsetX += fitZ;
    }

    let beamGroup = new Group();
    beamGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM,
      position: userDataPos,
      views: views,
    };
    beamGroup.position.set(offsetX, offsetY, offsetZ);
    if (this.APP.sltBeamType.currentValue == 0) {
      beamGroup.add(mesh, capL, capR);
    } else {
      beamGroup.add(mesh);
    }

    beamGroup.rotateY(Math.PI / 2);

    if (userDataPos.front) {
      beamGroup.rotateZ(this.utils.degreesToRadians(UI.patiosPitch));
    } else if (userDataPos.back) {
      beamGroup.rotateZ(-this.utils.degreesToRadians(UI.patiosPitch));
    }

    this.scene.add(beamGroup);
  }

  public addSuperiorBeam(userDataPos: any) {
    let offsetX =
      -this.totalBaySize / 2 - this.APP.sldLeftOverhang.currentValue;
    let offsetZ = 0;
    let offsetY = 0;

    let beamLength =
      this.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    let extraWallWidth =
      this.APP.existingWallManager.geo_existingWallW1.width +
      CONST.EAVE_OFFSET_BACK;
    let views: Print2DView[];

    if (userDataPos.front) {
      offsetY =
        this.APP.sldExistingWallHeight.currentValue +
        this.totalHeightFromEaveHeightToTopOfFlyoverBraket();
      offsetZ =
        this.APP.sldSpan.currentValue -
        this.APP.sldExistingWidth1.currentValue / 2 -
        this.extraOffsetZ;
      if (this.APP.sldMultiSpan.currentValue > 0) {
        offsetZ -= this.geo_superiorBeam.width / 2;
      } else {
        offsetZ -= this.geo_superiorBeam.width;
      }

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.back) {
      offsetY =
        this.APP.sldExistingWallHeight.currentValue +
        this.totalHeightFromEaveHeightToTopOfFlyoverBraket();
      offsetZ = -(
        this.APP.sldExistingWidth1.currentValue / 2 +
        this.extraOffsetZ
      );

      if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.MANAGER.cutoutCondition
      ) {
        if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
          if (this.APP.sldExistingWidth2.currentValue > 0) {
            offsetX += this.APP.sldExistingLength2.currentValue;
            beamLength =
              this.totalBaySize +
              this.APP.sldLeftOverhang.currentValue +
              this.APP.sldRightOverhang.currentValue -
              this.APP.sldExistingLength2.currentValue;
          } else {
            offsetX +=
              this.APP.sldExistingLength2.currentValue - extraWallWidth;
            beamLength =
              this.totalBaySize +
              this.APP.sldLeftOverhang.currentValue +
              this.APP.sldRightOverhang.currentValue -
              this.APP.sldExistingLength2.currentValue +
              extraWallWidth;
          }

          offsetX += this.APP.sldLeftOverhang.currentValue;
          beamLength -= this.APP.sldLeftOverhang.currentValue;
        } else if (
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT
        ) {
          if (this.APP.sldExistingWidth2.currentValue > 0) {
            beamLength =
              this.totalBaySize +
              this.APP.sldLeftOverhang.currentValue +
              this.APP.sldRightOverhang.currentValue -
              this.APP.sldExistingLength2.currentValue;
          } else {
            beamLength =
              this.totalBaySize +
              this.APP.sldLeftOverhang.currentValue +
              this.APP.sldRightOverhang.currentValue -
              this.APP.sldExistingLength2.currentValue +
              extraWallWidth;
          }

          beamLength -= this.APP.sldRightOverhang.currentValue;
        } else if (
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH
        ) {
          offsetX +=
            (this.totalBaySize -
              (this.APP.sldExistingLength.currentValue +
                this.APP.existingWallManager.geo_existingWallW1.width * 2)) /
              2 -
            CONST.EAVE_OFFSET_BACK;
          beamLength =
            this.APP.sldExistingLength.currentValue +
            this.APP.existingWallManager.geo_existingWallW1.width * 2 +
            CONST.EAVE_OFFSET_BACK * 2;
        }
      }

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.multiSpan) {
      offsetY =
        this.APP.sldExistingWallHeight.currentValue +
        this.totalHeightFromEaveHeightToTopOfFlyoverBraket();
      offsetZ =
        UI.span +
        UI.multiSpan -
        this.APP.sldExistingWidth1.currentValue / 2 -
        this.geo_superiorBeam.width -
        this.extraOffsetZ;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.cutout) {
      offsetY =
        UI.eaveHeight +
        this.totalHeightFromEaveHeightToTopOfFlyoverBraket() +
        this.getBracketHeight(
          UI.existingWidth1 > (UI.span + UI.multiSpan) / 2
            ? Math.abs(UI.span + UI.multiSpan - UI.existingWidth1)
            : UI.existingWidth1
        );

      offsetZ =
        this.APP.sldExistingWidth1.currentValue -
        this.APP.sldExistingWidth1.currentValue / 2 -
        this.extraOffsetZ;
      beamLength =
        this.APP.sldExistingLength2.currentValue -
        this.APP.existingWallManager.geo_existingWallW1.width;

      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
        views = [
          { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
          { viewType: ViewType.PLAN, lineType: LineType.DASHED },
        ];

        if (this.APP.sldExistingWidth2.currentValue > 0) {
          beamLength += extraWallWidth;
        }

        beamLength +=
          this.APP.sldLeftOverhang.currentValue +
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          (this.geo_bracket?.width || 0) / 2;
      }
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
        views = [
          { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
          { viewType: ViewType.PLAN, lineType: LineType.DASHED },
        ];

        if (this.APP.sldExistingWidth2.currentValue > 0) {
          beamLength += extraWallWidth;
          offsetX =
            this.totalBaySize / 2 +
            this.APP.sldRightOverhang.currentValue -
            this.APP.sldExistingLength2.currentValue -
            CONST.EAVE_OFFSET_BACK;
        } else {
          offsetX =
            this.totalBaySize / 2 +
            this.APP.sldRightOverhang.currentValue -
            this.APP.sldExistingLength2.currentValue +
            this.APP.existingWallManager.geo_existingWallW1.width;
        }

        offsetX -=
          this.APP.sldRightOverhang.currentValue +
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          (this.geo_bracket?.width || 0) / 2;
        beamLength +=
          this.APP.sldRightOverhang.currentValue +
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          (this.geo_bracket?.width || 0) / 2;
      } else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        beamLength =
          (this.totalBaySize - this.APP.sldExistingLength.currentValue) / 2 -
          this.APP.existingWallManager.geo_existingWallW1.width -
          CONST.EAVE_OFFSET_BACK +
          this.geo_bracket.width +
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          (this.geo_bracket?.width || 0) / 2;
      }
    }

    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
      let fitZ =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      offsetX -= fitZ;
    }
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      let fitZ =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
      offsetX += fitZ;
    }

    if(userDataPos.front || userDataPos.multiSpan) {
      this.cutStandardBeamByCutBeamWithinBayControl(
        this.geo_superiorBeam,
        this.geo_beamEndCap,
        beamLength,
        new Vector3(offsetX, offsetY, offsetZ),
        new Vector3(),
        views,
        1,
        userDataPos,
        'EB',
        this.geoBeamJoint
      )
    } else {
      this.cutStandardBeamToEqualLengthBeam(
        this.geo_superiorBeam,
        this.geo_beamEndCap,
        beamLength,
        new Vector3(offsetX, offsetY, offsetZ),
        new Vector3(),
        views,
        1,
        userDataPos,
        'EB',
        this.geoBeamJoint
      )
    }

    //Add one more beam cutout right
    if (userDataPos.cutout && this.APP.sltExistingType.currentValue == 3) {
      offsetX =
        this.totalBaySize / 2 -
        (this.totalBaySize - this.APP.sldExistingLength.currentValue) / 2 +
        this.APP.existingWallManager.geo_existingWallW1.width +
        CONST.EAVE_OFFSET_BACK -
        this.geo_superiorBeam.width -
        (this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          (this.geo_bracket?.width || 0) / 2);
      
      this.cutStandardBeamToEqualLengthBeam(
        this.geo_superiorBeam,
        this.geo_beamEndCap,
        beamLength,
        new Vector3(offsetX, offsetY, offsetZ),
        new Vector3(),
        views,
        1,
        userDataPos,
        'EB',
        this.geoBeamJoint
      )
    }
  }
  private cutStandardBeamToEqualLengthBeam(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    beamName = '',
    jointGeo: GeometryInfo,
  ){
    const beamStartX = pos.x
    const beamEndX = pos.x + length

    const numOfBeam = Math.ceil(length / EXISTING_BUILDING_CONFIG.MAXIMUM_LENGTH_PER_BEAM);
    const lengthPerBeam = Math.abs(beamEndX - beamStartX) / numOfBeam
    for(let i = 0; i < numOfBeam; i++) {
      let beamGroup = this.utils.createBeamGroup(
        beamGeo,
        beamCapGeo,
        lengthPerBeam,
        new Vector3(beamStartX + lengthPerBeam * i, pos.y, pos.z),
        rot,
        views,
        directionOffset,
        userDataPos,
        UI.beamLayoutShow,
        {
          hasStartCap: UI.beamType == 0 && (i == 0),
          hasEndCap: UI.beamType == 0 && (i == numOfBeam - 1 || (i == 0 && numOfBeam == 1)),
          hasStartJoint: i !== 0,
          hasEndJoint: false,
          jointGeo: jointGeo,
        }
      )
      beamGroup.userData = {
        ...beamGroup.userData,
        name: beamName,
      };
      this.scene.add(beamGroup);
    }
  }
  private cutStandardBeamByCutBeamWithinBayControl(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    beamName = '',
    jointGeo: GeometryInfo,
  ){
    const fitX = this.getContainerOffsetXToFitBraketToExistingWall();
    // Map list cut beam here
    const beamStartX = pos.x
    const beamEndX = pos.x + length

    let endOfBayX = - UI.totalBayLength / 2 + fitX
    let startCutBeamX = beamStartX
    let endCutBeamX = beamStartX

    const beams = this.utils.getListSeperateBeamsByBays(endOfBayX, beamStartX, beamEndX, endCutBeamX, startCutBeamX)

    for(let i = 0; i < beams.length; i++) {
      endCutBeamX = beams[i][1]
      startCutBeamX = beams[i][0]
      let beamGroup = this.utils.createBeamGroup(
        beamGeo,
        beamCapGeo,
        endCutBeamX - startCutBeamX,
        new Vector3(startCutBeamX, pos.y, pos.z),
        rot,
        views,
        directionOffset,
        userDataPos,
        UI.beamLayoutShow,
        {
          hasStartCap: UI.beamType == 0 && (i == 0),
          hasEndCap: UI.beamType == 0 && (i == beams.length - 1 || (i == 0 && beams.length == 1)),
          hasStartJoint: i !== 0,
          hasEndJoint: false,
          jointGeo: jointGeo,
        }
      )
      beamGroup.userData = {
        ...beamGroup.userData,
        name: beamName,
      };
      this.scene.add(beamGroup);
    }
  }
  private getContainerOffsetXToFitBraketToExistingWall() {
    let fitX = 0;
    if (UI.existingType == BUILDING_SIDE.RIGHT) {
      fitX = -(
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0)
      );
    }
    if (UI.existingType == BUILDING_SIDE.LEFT) {
      fitX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
    }

    return fitX;
  }
  public getBracketHeight(offset): number {
    let angle = UI.patiosPitch;
    return this.utils.tan(angle) * offset;
  }

  public destroy(): void {
    this.unregisterEvent();
  }

  private registerEvent(): void {
    this.eventHandleId = this.uiChangedDefer.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldSpan,
      this.APP.sldExistingWallHeight,
      this.APP.sldEaveWidth,
      this.APP.sldFasciaDepth,

      this.APP.sldExistingLength,
      this.APP.sldExistingLength2,
      this.APP.sldExistingWidth1,
      this.APP.sldExistingWidth2,

      this.APP.sltExistingType,
      this.APP.dialogEditBay,

      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,
      this.APP.sldFrontOverhang,
      this.APP.sltRoofPitch,
      this.APP.sldMultiSpan,
      this.APP.sldFlyOverBracketHeight,

      this.APP.sltColourBeam,
      this.APP.sltColourPost,
      this.APP.sltColourBracket,
      this.APP.sltColourFasciaBracket,
      this.APP.sltRoofThickness,
      this.APP.sltGutterType,
    ];
    //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));

    this.controlsToRegisterEvent2 = [
      this.APP.sltBeamType,
      this.APP.sltBeamSize,
      this.APP.sltColumnType,
    ];
    this.controlsToRegisterEvent2.forEach((c) =>
      c.addAction(this.objectSizeChangedHandle)
    );
  }
  private unregisterEvent(): void {
    //this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    //this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach((c) =>
      c.removeAction(this.objectSizeChangedHandle)
    );
    this.controlsToRegisterEvent2 = undefined;
  }
  private objectSizeChanged(pre: number, cur: number) {
    this.optimize().then(() => {
      this.load();
    });
  }
  uiChangedDefer(previousValue: number, currentValue: number) {
    if (this.APP.sltCutOut.currentValue == 1) {
      if (this.deferHandle) {
        clearTimeout(this.deferHandle);
      }
      this.deferHandle = setTimeout(() => {
        this.load();
      }, this.deferTimeout);
    } else {
      this.load();
    }
  }
}
