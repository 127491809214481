import { Mesh, MeshBasicMaterial, Object3D, DoubleSide, Vector3, Shape, ShapeBufferGeometry } from "three";
import { utils } from "../core/utils";

export default class ArrowControl extends Mesh {
    container: Object3D
    options: any
    mat: MeshBasicMaterial

    constructor(container, options: {
        pos: Vector3,
        userData: any,
        rotation?: Vector3
    }){
        let arrowLength = 500;
        let angle = 20;
        let y = utils.tan(angle) * arrowLength;
        let shape1 = new Shape();
        shape1.moveTo(0, 0,);
        shape1.lineTo(arrowLength, y);
        shape1.lineTo(arrowLength, -y);
        shape1.closePath();
        const geo = new ShapeBufferGeometry(shape1);
        geo.translate( - arrowLength, 0, 0)
        const mat =  new MeshBasicMaterial({ color: 0xffff00, side: DoubleSide });
        super(geo, mat)

        this.position.set(options.pos.x, options.pos.y, options.pos.z)
        if(options.rotation){
            this.rotateX(options.rotation.x)
            this.rotateY(options.rotation.y)
            this.rotateZ(options.rotation.z)
        }

        this.userData = options.userData;
        container.add(this)

        this.mat = mat;
        this.container = container
        this.options = options
    }
}