import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    updateCustomText(id: any, info: any) {
        return this.http
            .put<any>(`${environment.apiDbUrl}/user/update-custom-text/${id}`, info)
            .pipe(
                map(ret => {
                    return ret;
                })
            );
    }
}