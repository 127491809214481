import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { SelectInputItemModel } from 'src/app/core/models';

declare var $: any;


@Component({
  selector: "select-input",
  templateUrl: "./select-input.component.html"
  // styleUrls: ['./dropdown-input.component.css']
})
export class SelectInputComponent implements OnInit {
  _isDisable: boolean;
  @Input("disabled") public set isDisabled(val: boolean) {
    this._isDisable = val;
  }
  @Input("items") items: Array<SelectInputItemModel>;
  @Input("selected") public selectedValue: number;
  @Output("change") onChange = new EventEmitter();
  @ViewChild("selectInput", { static: true }) selectElement;

  previousValue: number;
  currentValue = 10;
  currentText = "";
  //actions: Array<(previousValue: number, currentValue: number) => void> = [];
  actions: Array<{
    priority: number;
    actions: Array<(previousValue: number, currentValue: number) => void>;
  }> = [];

  ngOnInit(): void {
    this.selectedValue = this.selectedValue || 0;
    this.currentValue = this.selectedValue;
  }
  ngAfterContentInit() {
    this.setSelected(this.selectedValue);
    console.log('items :', this.items);
    const item = this.items.find(f => f.id === this.selectedValue);
    if (item) {
      this.currentText = item.value;
    }
  }

  public addAction(
    action: (previousValue: number, currentValue: number) => void,
    priority?: number
  ) {
    priority = priority || 3;
    let actionsByPriority = this.actions.find(a => a.priority === priority);
    if (actionsByPriority) {
      actionsByPriority.actions.push(action);
    } else {
      this.actions.push({ priority: priority, actions: [action] });
    }

    this.actions.sort((a, b) => a.priority - b.priority);
  }
  public removeAction(action: any): void {
    for (let item of this.actions) {
      const idx = item.actions.indexOf(action);

      if (idx !== -1) {
        item.actions.splice(idx, 1);
      }
    }
  }

  public onValueChanged(e) {
    let val = e.target.value;
    this.currentValue = val;
    this.currentText = e.target.options[e.target.options.selectedIndex].text;
    for (let item of this.actions) {
      item.actions.forEach(action => {
        action(this.previousValue, this.currentValue);
      });
    }

    // this.onChange.emit();
    this.previousValue = val;
  }
  public setSelected(value: any) {
    this.currentValue = value;
    this.selectedValue = value;
    const item = this.items.find(f => f.id == value);
    if (item) {
      this.currentText = item.value;
    } else {
      this.currentText = '';
    }
    // setTimeout(() => {
    //   $(this.selectElement.nativeElement).dropdown("set selected", value);
    // }, 200);
  }

  public setDisable(value: boolean) {
    this.isDisabled = value;
  }
  filterItems() {
    return this.items;
  }
  isNotSelected(value: string): boolean {
    return true;
    // return this.listOfSelectedValue.indexOf(value) === -1;
  }
}
