import { Scene, Mesh, Object3D, Material, Group, FontLoader, TextBufferGeometry, PlaneBufferGeometry, Vector3, BoxHelper, Box3Helper, Matrix4, Box3, Geometry, LineSegments, LineBasicMaterial, Color, BoxBufferGeometry, Line, Line3 } from "three";
import { HomeComponent as AppComponent, HomeComponent } from '../../containers/home/home.component';
import { Util } from '../utils';
import { MaterialManager } from '../material.manager';
import { GeometryInfo, ViewType, LineType, IBay, Printing2DGeometry, Printing2DLine, Print2DText, Printing2DGeometryType } from '../models';
import { DIMENSION_LABEL_TYPE, GEOMETRY_TYPE } from 'src/app/app.config';
import { environment } from 'src/environments/environment';
import Dimension, { AddDimensionOptions } from "src/app/models/Dimension";
import {CONFIG as conf} from '../../app.config'
import { UI } from "../ui";
import { PatiosFreeStandingManager } from ".";
import { FIT_DIMENSION } from "src/app/app.constants";

export class DimensionManager {
    private scene: Scene;
    private APP: AppComponent;
    public listText: THREE.Object3D[] = [];
    private utils: Util;

    private MANAGER: PatiosFreeStandingManager;

    private eventHandleId: any;

    private material: Material;

    private materialManager: MaterialManager;

    private dimensionPatiosFrontBottom: Object3D;
    private dimensionPatiosFrontRight: Object3D;
    
    private dimensionPatiosRightBottom: Object3D;
    private dimensionPatiosRightLeft: Object3D;
    private dimensionPatiosRightRight: Object3D;

    private dimensionPatiosLeftBottom: Object3D;
    private dimensionPatiosLeftRight: Object3D;
    private dimensionPatiosLeftLeft: Object3D;

    private dimensionPatiosPlanRight: Object3D;
    private dimensionPatiosPlanBottom: Object3D;
    private dimensionPlanMiddle: Object3D;
    private dimensionPlanMidleLeft: Object3D;

    private dimensionPatiosLowerHeightDim: Object3D;
    private dimensionPatiosHigherHeightDim: Object3D;

    private lineCapGeo: GeometryInfo;
    private lineGeo: GeometryInfo;
    private textGeo: TextBufferGeometry;

    private dimensionDistance = 1500;
    private dimensionDistance2 = 500;

    private textDistance = 5000;

    private totalBaySize: number;

    private font: any
    private textParameter: any;
    private isReady = false;

    private controlsToRegisterEvent: Array<any>;
    private options : AddDimensionOptions;
    private startPos: Vector3;
    private startMatrix: Matrix4;
    private startBayLength: number;

    constructor(app: AppComponent, freeStandingManager: PatiosFreeStandingManager) {
        this.utils = new Util();
        this.materialManager = MaterialManager.Instance();
        this.APP = app;
        this.scene = this.APP.scene;
        this.material = MaterialManager.Instance().DIMENSION_TEXT;

        this.MANAGER = freeStandingManager;

        this.registerEvent();
    }

    
    public optimize() : Promise<void>{
        return new Promise((resolve, reject) => {
            this.lineGeo = new GeometryInfo();
            this.lineGeo.geometry = new PlaneBufferGeometry(1000, 50);
            this.lineGeo.width = 50;
            this.lineGeo.length = 1000;

            this.lineCapGeo = new GeometryInfo();
            this.lineCapGeo.geometry = new PlaneBufferGeometry(50, 200);
            this.lineCapGeo.width = 50;
            this.lineCapGeo.length = 200;

            resolve();
        });
    }
    public load(): Promise<void> {    
        return new Promise((resolve, reject) => {
            this.unload();
            var loader = new FontLoader();
            
            this.dimensionPatiosFrontBottom = new Group();
            this.dimensionPatiosFrontBottom.userData = { type: GEOMETRY_TYPE.DIMENSION };
            // this.dimensionPatiosFrontBottom.rotation.set(0,Math.PI,0);
            this.dimensionPatiosFrontBottom.visible = false;

            this.dimensionPatiosFrontRight = new Group();
            this.dimensionPatiosFrontRight.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosFrontRight.rotation.set(0,0,-Math.PI/2);
            this.dimensionPatiosFrontRight.visible = false;
            
            this.dimensionPatiosRightBottom = new Group();
            this.dimensionPatiosRightBottom.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosRightBottom.rotation.set(0,-Math.PI/2,0);
            this.dimensionPatiosRightBottom.visible = false;

            this.dimensionPatiosRightLeft = new Group();
            this.dimensionPatiosRightLeft.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosRightLeft.rotation.set(0,-Math.PI/2,-Math.PI/2);
            this.dimensionPatiosRightLeft.visible = false;

            this.dimensionPatiosRightRight = new Group();
            this.dimensionPatiosRightRight.rotation.set(0,-Math.PI/2,-Math.PI/2);
            this.dimensionPatiosRightRight.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosRightRight.visible = false;

            this.dimensionPatiosLeftBottom = new Group();
            this.dimensionPatiosLeftBottom.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosLeftBottom.rotation.set(0,-Math.PI/2,0);
            this.dimensionPatiosLeftBottom.visible = false;

            this.dimensionPatiosLeftRight = new Group();
            this.dimensionPatiosLeftRight.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosLeftRight.rotation.set(Math.PI/2,-Math.PI/2,0);
            this.dimensionPatiosLeftRight.visible = false;

            this.dimensionPatiosLeftLeft = new Group();
            this.dimensionPatiosLeftLeft.rotation.set(0, -Math.PI / 2, Math.PI / 2);
            this.dimensionPatiosLeftLeft.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosLeftLeft.visible = false;

            this.dimensionPatiosPlanRight = new Group();
            this.dimensionPatiosPlanRight.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosPlanRight.rotation.set(-Math.PI/2, Math.PI, -Math.PI/2);

            this.dimensionPatiosPlanBottom = new Group();
            this.dimensionPatiosPlanBottom.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosPlanBottom.rotation.set(-Math.PI / 2, 0, 0);

            this.dimensionPatiosLowerHeightDim = new Group();
            this.dimensionPatiosLowerHeightDim.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosLowerHeightDim.rotation.set(0, 0, Math.PI / 2);
            
            this.dimensionPatiosHigherHeightDim = new Group();
            this.dimensionPatiosHigherHeightDim.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosHigherHeightDim.rotation.set(0, 0, Math.PI / 2);

            this.dimensionPlanMiddle = new Group();
            this.dimensionPlanMidleLeft = new Group();

            this.scene.add(
                this.dimensionPatiosPlanRight,
                this.dimensionPatiosFrontBottom, 
                this.dimensionPatiosFrontRight, 
                this.dimensionPatiosRightBottom,
                this.dimensionPatiosRightLeft,
                this.dimensionPatiosRightRight,
                this.dimensionPatiosLeftBottom, 
                this.dimensionPatiosLeftRight,     
                this.dimensionPatiosLeftLeft,            
                this.dimensionPatiosPlanBottom,
                this.dimensionPlanMiddle,
                this.dimensionPlanMidleLeft,
                this.dimensionPatiosLowerHeightDim,
                this.dimensionPatiosHigherHeightDim,
            );

            loader.load( environment.modelBaseUrl + '/../assets/fonts/helvetiker_regular.typeface.json', (font) => {
                this.font  = font;

                this.textParameter = {
                    font: this.font,
                    size: 200,
                    height: 5,
                    curveSegments: 10
                };
                this.options = {
                    container: HomeComponent.ins.scene,
                    start: new Vector3(),
                    end: new Vector3(),
                    linePos: new Vector3(),
                    views: [],
                    allowDrag: true,
                    textParameter: this.textParameter,
                    textPos: new Vector3(),
                    labelType: null,
                    fitZ: 10,
                    hasTextClone: false,
                    axis: 'x',
                    lineLength: 0,
                    subtype: null,
                    textUserData: null,
                    onDragCallback: this.onDimDrag.bind(this),
                    onDragStartCallback: this.onDimDragStart.bind(this),
                }

                this.isReady = true;
                this.draw();

                resolve();
            })
        });
    }

    public unload(){
        this.scene.remove(
            this.dimensionPatiosPlanRight,
            this.dimensionPatiosFrontBottom, 
            this.dimensionPatiosFrontRight, 
            this.dimensionPatiosRightBottom,
            this.dimensionPatiosRightLeft,
            this.dimensionPatiosRightRight,
            this.dimensionPatiosLeftBottom, 
            this.dimensionPatiosLeftRight,
            this.dimensionPatiosLeftLeft,         
            this.dimensionPatiosPlanBottom,
            this.dimensionPlanMiddle,
            this.dimensionPlanMidleLeft,
            this.dimensionPatiosLowerHeightDim,
            this.dimensionPatiosHigherHeightDim,
        );
    }
    
    public draw(){
        if (!this.isReady) {
            return;
        }
        // this.dimensionPatiosPlanBottom.children = [];
        // this.dimensionPatiosPlanRight.children = [];
        this.dimensionPatiosLowerHeightDim.children = this.dimensionPatiosLowerHeightDim.children.filter( el => 
            el.userData?.groupType == DIMENSION_LABEL_TYPE.LOWER_HEIGHT_DIM
        );
        this.dimensionPatiosHigherHeightDim.children = this.dimensionPatiosHigherHeightDim.children.filter( el => 
            el.userData?.groupType == DIMENSION_LABEL_TYPE.BRAKET_HEIGHT_DIM
        );
        this.dimensionPatiosPlanBottom.children = this.dimensionPatiosPlanBottom.children.filter( el => 
            el.userData?.groupType == DIMENSION_LABEL_TYPE.LEFT_OVERHANG
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.RIGHT_OVERHANG
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.BAY
        );
        this.dimensionPatiosPlanRight.children = this.dimensionPatiosPlanRight.children.filter( el => 
            el.userData?.groupType == DIMENSION_LABEL_TYPE.SPAN
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.MULTI_SPAN
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.FRONT_OVERHANG
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.BACK_OVERHANG
        );
        this.dimensionPatiosFrontBottom.children = [];
        this.dimensionPatiosFrontRight.children = [];
        this.dimensionPatiosLeftBottom.children = [];
        this.dimensionPatiosLeftRight.children = [];
        this.dimensionPatiosLeftLeft.children = [];
        this.dimensionPatiosRightBottom.children = [];
        this.dimensionPatiosRightLeft.children = [];
        this.dimensionPatiosRightRight.children = [];
        this.dimensionPlanMiddle.children = [];
        this.dimensionPlanMidleLeft.children = [];
        
        this.totalBaySize = 0;
        for(let b of this.APP.dialogEditBay.listBay){
            this.totalBaySize += b.value;
        }

        this.drawDimensionLowerHeightDim();
        this.drawDimensionHigherHeightDim();

        this.drawDimensionPatiosPlanBottom();
        this.drawDimensionPatiosPlanRight();

        this.drawDimensionPatiosFrontBottom();
        this.drawDimensionPatiosFrontRight();

        this.drawDimensionPatiosLeftBottom();
        this.drawDimensionPatiosLeftRight();
        this.drawDimensionPatiosLeftLeft();

        this.drawDimensionPatiosRightBottom();
        this.drawDimensionPatiosRightLeft();
        this.drawDimensionPatiosRightRight();

        this.drawDimensionMidle();
        this.drawDimensionMidleLeft();

        this.APP.dimensionManager.draw();
    }

    private drawDimensionLowerHeightDim(){
        const frontPostHeight = this.utils.getHeightByAngle(UI.height - this.MANAGER.columnAndPurlinManager.geo_superiorBeam.height, UI.span + UI.multiSpan, UI.patiosPitch, -1)
        const offsetZFrontPos = UI.span + UI.multiSpan + FIT_DIMENSION;

        this.dimensionPatiosLowerHeightDim.position.set(0, frontPostHeight / 2, offsetZFrontPos);

        let textStr = this.utils.getDimensionValue(frontPostHeight);
        let options : AddDimensionOptions = {
            ... this.options,
            lineLength: frontPostHeight,
            container: this.dimensionPatiosLowerHeightDim,
            views: [],
            linePos: new Vector3(0, 0, 0),
            textPos: new Vector3(0, 200, 0),
            labelType: DIMENSION_LABEL_TYPE.LOWER_HEIGHT_DIM,
            textUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: [] },
            allowDrag: false
        }
        let dimBay = this.dimensionPatiosLowerHeightDim.children.find(el => 
            el.userData?.groupType == DIMENSION_LABEL_TYPE.LOWER_HEIGHT_DIM
        ) as Dimension;
        if(!dimBay){
            new Dimension(options);
        } else {
            dimBay.update(options);
        }
    }

    private drawDimensionHigherHeightDim(){
        const backPostHeight = UI.height - this.MANAGER.columnAndPurlinManager.geo_superiorBeam.height
        const offsetZBackPos = - FIT_DIMENSION;

        this.dimensionPatiosHigherHeightDim.position.set(0, backPostHeight / 2, offsetZBackPos);

        let textStr = this.utils.getDimensionValue(backPostHeight);
        let options : AddDimensionOptions = {
            ... this.options,
            lineLength: backPostHeight,
            container: this.dimensionPatiosHigherHeightDim,
            views: [],
            linePos: new Vector3(0, 0, 0),
            textPos: new Vector3(0, 200, 0),
            textRotation: new Vector3(Math.PI, 0, 0),
            labelType: DIMENSION_LABEL_TYPE.HIGHER_HEIGHT_DIM,
            textUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: [] },
            allowDrag: false
        }
        let dimBay = this.dimensionPatiosHigherHeightDim.children.find(el => 
            el.userData?.groupType == DIMENSION_LABEL_TYPE.HIGHER_HEIGHT_DIM
        ) as Dimension;
        if(!dimBay){
            new Dimension(options);
        } else {
            dimBay.update(options);
        }
    }

    private drawDimensionMidleLeft() {
        let offsetX = this.totalBaySize / 2 + this.APP.patiosManager.patiosGroup.position.x;
        let offsetZ = this.APP.sldSpan.currentValue / 2 + this.APP.sldMultiSpan.currentValue / 2 - this.APP.sldExistingWidth1.currentValue / 2;
        let origin = new Vector3(0, 0, 0);
        let head = new Vector3(0, 0, 1000);
        let hand1 = new Vector3(-50, 0, 950);
        let hand2 = new Vector3(50, 0, 950);

        //arrow
        let views = [{ viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }];
        //let material = new LineBasicMaterial({ color: 0x0000ff });
        let geoLine = new Geometry();
        geoLine.vertices.push(origin);
        geoLine.vertices.push(head);

        geoLine.vertices.push(head);
        geoLine.vertices.push(hand1);

        geoLine.vertices.push(hand1);
        geoLine.vertices.push(hand2);

        geoLine.vertices.push(hand2);
        geoLine.vertices.push(head);
        let dim = new Line(geoLine);
        dim.userData = { type: "Arrow", views: views };

        //text of degree
        let textStr = "Panel direction";
        this.textGeo = new TextBufferGeometry(textStr, this.textParameter);
        this.textGeo.center();

        let text = new Mesh(this.textGeo, MaterialManager.Instance().DIMENSION_TEXT);

        text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0, 0, 0) };
        //text.position.setZ(-100);
        text.position.setX(100);

        dim.visible = false;
        text.visible = false;

        this.dimensionPlanMidleLeft.add(dim);
        this.dimensionPlanMidleLeft.add(text);


        if (this.APP.sltExistingType.currentValue == 2) {
            text.position.set(-100, 0, 1000);
            this.dimensionPlanMidleLeft.rotation.set(0, -Math.PI / 2, 0);
            this.dimensionPlanMidleLeft.position.set(offsetX, 0, offsetZ);
        }
        else {
            this.dimensionPlanMidleLeft.rotation.set(0, Math.PI / 2, 0);
            this.dimensionPlanMidleLeft.position.set(-offsetX, 0, offsetZ);
        }
    }
  private drawDimensionMidle() {
    let offsetZ = this.APP.sldSpan.currentValue / 2 + this.APP.sldMultiSpan.currentValue / 2;
    let origin = new Vector3(0, 0, 0);
    let head = new Vector3(0, 0, 1000);
    let hand1 = new Vector3(-50, 0, 950);
    let hand2 = new Vector3(50, 0, 950);

    let views = [{ viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }];
    let material = new LineBasicMaterial({ color: 0x0000ff });
    let geoLine = new Geometry();
    geoLine.vertices.push(origin);
    geoLine.vertices.push(head);

    geoLine.vertices.push(head);
    geoLine.vertices.push(hand1);

    geoLine.vertices.push(hand1);
    geoLine.vertices.push(hand2);

    geoLine.vertices.push(hand2);
    geoLine.vertices.push(head);
    let dim = new Line(geoLine, material);
    dim.userData = { type: "Arrow", views: views };

    //text of degree
    let textStr = this.APP.sltRoofPitch.currentValue + ' degree';
    this.textGeo = new TextBufferGeometry(textStr, this.textParameter);
    this.textGeo.center();

    let text = new Mesh(this.textGeo, MaterialManager.Instance().DIMENSION_TEXT);
    
    text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0, Math.PI / 2, 0) };
    text.position.setZ(900);
    text.position.setX(200);

    dim.visible = false;
    text.visible = false;

    this.dimensionPlanMiddle.add(dim);
    this.dimensionPlanMiddle.add(text);

    this.dimensionPlanMiddle.position.set(0, 0, offsetZ);

    //console.log(text);
  }

    private drawDimensionPatiosPlanBottom(){
        let offsetX = -this.totalBaySize/2;
        let offsetZ = this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + this.APP.sldFrontOverhang.currentValue + this.dimensionDistance2;

        let views = [ { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS } ];

        this.dimensionPatiosPlanBottom.position.setZ(offsetZ);

        this.dimensionPatiosPlanBottom.children = this.dimensionPatiosPlanBottom.children.filter(el => 
            el.userData?.groupType != DIMENSION_LABEL_TYPE.BAY
            || (el.userData?.groupType == DIMENSION_LABEL_TYPE.BAY && el.userData?.bayNum < HomeComponent.ins.dialogEditBay.listBay.length)
        );
        
        for(let i = 0; i <= this.APP.dialogEditBay.listBay.length; i++){
            let b: IBay = {index: 0, value: 0, isCut: false};
            if(i < this.APP.dialogEditBay.listBay.length){
                b = this.APP.dialogEditBay.listBay[i];
            }

            if(i < this.APP.dialogEditBay.listBay.length){
                let textStr = this.utils.getDimensionValue(b.value);
                let options : AddDimensionOptions = {... this.options,
                    lineLength: b.value,
                    container: this.dimensionPatiosPlanBottom,
                    views: views,
                    linePos: new Vector3(offsetX + b.value / 2, 0, 0),
                    textPos: new Vector3(offsetX + b.value / 2, -200, 0),
                    labelType: DIMENSION_LABEL_TYPE.BAY,
                    bayNum: i,
                }
                let dimBay = this.dimensionPatiosPlanBottom.children.find(el => 
                    el.userData?.groupType == DIMENSION_LABEL_TYPE.BAY 
                    && el.userData?.bayNum == i
                ) as Dimension;
                if(!dimBay){
                    new Dimension(options);
                } else {
                    dimBay.update(options);
                }
            }
            offsetX += b.value;
        }

        //Left over hang
        this.addOverhang(this.dimensionPatiosPlanBottom, offsetX, this.APP.sldLeftOverhang.currentValue, views, "Left", 0, "z", DIMENSION_LABEL_TYPE.LEFT_OVERHANG);
        this.addOverhang(this.dimensionPatiosPlanBottom, offsetX, this.APP.sldRightOverhang.currentValue, views, "Right", 0, "z", DIMENSION_LABEL_TYPE.RIGHT_OVERHANG);
    }
    onDimDrag(event){
        this.utils.handlerDimDragEvent(event, this.startPos, this.startMatrix, this.startBayLength);
        this.startPos = event.object.position.clone();
    }
    onDimDragStart(event){
        this.startPos = event.object.position.clone();
        this.startMatrix = event.object.matrixWorld.clone();
        if(event.object.userData?.dimType === DIMENSION_LABEL_TYPE.BAY){
            let bayNum = event.object.userData.bayNum;
            this.startBayLength = UI.listBay[+bayNum].value;
          }
    }
    private drawDimensionPatiosPlanRight(){
        
        let views = [ { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS } ];
        let offsetDimensionZ = this.APP.sldSpan.currentValue/2;
        

        let _dimensionDistane = this.dimensionDistance2;
        // if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
        //     if(this.APP.sldExistingWidth1.currentValue > 0){
        //         _dimensionDistane = this.dimensionDistance;
        //     }
        // }

        let offsetDimensionX = this.totalBaySize/2 + this.APP.sldRightOverhang.currentValue + this.APP.patiosManager.patiosGroup.position.x + _dimensionDistane;
        // if(this.APP.sldExistingLength.currentValue/2 > this.totalBaySize/2){
        //     offsetDimensionX = this.APP.sldExistingLength.currentValue/2 + _dimensionDistane;
        // }
        
        // if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
        //     offsetDimensionX += this.APP.sldExistingLength2.currentValue;
        // }

        this.dimensionPatiosPlanRight.position.set(offsetDimensionX, 0, offsetDimensionZ);

        let textStr = this.utils.getDimensionValue(this.APP.sldSpan.currentValue);

        let options : AddDimensionOptions = {... this.options,
            lineLength: HomeComponent.ins.sldSpan.currentValue,
            container: this.dimensionPatiosPlanRight,
            views: views,
            linePos: new Vector3(0, 0, 0),
            textPos: new Vector3(0, -200, 0),
            labelType: DIMENSION_LABEL_TYPE.SPAN,
            hasTextClone: false,
            textUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0, -Math.PI / 2, 0) },
            textCloneUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0, -Math.PI / 2, 0) },
            textCloneRotation: new Vector3(0, Math.PI, -Math.PI),
            textCloneVisible: false,
            textRotation: new Vector3(0, -Math.PI, 0),
            fitZ: -10
        }
        let dimSpan = this.dimensionPatiosPlanRight.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.SPAN) as Dimension;
        if(!dimSpan){
            new Dimension(options);
        } else {
            dimSpan.update(options);
        }

        if(this.APP.sldMultiSpan.currentValue > 0){
            let offsetX = this.APP.sldSpan.currentValue/2 + this.APP.sldMultiSpan.currentValue;
            // - this.APP.existingWallManager.geo_existingWallL1.width 
            // - this.APP.eaveManager.backOverhang;

            let optionsMul : AddDimensionOptions = {... this.options,
                lineLength: HomeComponent.ins.sldMultiSpan.currentValue,
                container: this.dimensionPatiosPlanRight,
                views: views,
                linePos: new Vector3(this.APP.sldSpan.currentValue / 2 + this.APP.sldMultiSpan.currentValue / 2, 0, 0),
                textPos: new Vector3(this.APP.sldSpan.currentValue / 2 + this.APP.sldMultiSpan.currentValue / 2, -200, 0),
                labelType: DIMENSION_LABEL_TYPE.MULTI_SPAN,
                hasTextClone: false,
                textUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0, -Math.PI / 2, 0) },
                textCloneUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0, -Math.PI / 2, 0) },
                textCloneRotation: new Vector3(0, Math.PI, -Math.PI),
                textCloneVisible: false,
                textRotation: new Vector3(0, -Math.PI, 0),
                fitZ: -10
            }
            let dimMul = this.dimensionPatiosPlanRight.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.MULTI_SPAN) as Dimension;
            if(!dimMul){
                new Dimension(optionsMul);
            } else {
                dimMul.update(optionsMul);
            }
        } else {
            this.dimensionPatiosPlanRight.children = this.dimensionPatiosPlanRight.children.filter(el => el.userData?.groupType != DIMENSION_LABEL_TYPE.MULTI_SPAN)
        }

      let offsetRight = this.APP.sldSpan.currentValue / 2 + this.APP.sldMultiSpan.currentValue;
      let offsetLeft = this.APP.sldSpan.currentValue / 2;// + this.APP.sldMultiSpan.currentValue;

      this.addOverhang(this.dimensionPatiosPlanRight, offsetRight, this.APP.sldFrontOverhang.currentValue, views, "Right", Math.PI, "y", DIMENSION_LABEL_TYPE.FRONT_OVERHANG);
      this.addOverhang(this.dimensionPatiosPlanRight, offsetLeft, this.APP.sldBackOverhang.currentValue, views, "Left", Math.PI, "y", DIMENSION_LABEL_TYPE.BACK_OVERHANG);

    }
    private drawDimensionPatiosFrontBottom(){
        let dimOffsetY = -this.dimensionDistance2;
        this.dimensionPatiosFrontBottom.position.set(0,dimOffsetY,0);

        let views = [ { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS } ];

        this.dimensionPatiosPlanBottom.children.forEach( groupDim => {
            groupDim.children.filter(c => c.userData.subtype != GEOMETRY_TYPE.DIMENSION_LINE_RAKECUT).forEach(e => {
                let dim = e.clone();
                dim.userData['views'] = views;
                if(dim.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT){
                    dim.rotation.set(0,Math.PI,0);
                    dim.userData['rotation'] = new Vector3(0,0,0);
                }
                this.dimensionPatiosFrontBottom.add(dim);
            });
        })
    }
    private drawDimensionPatiosFrontRight(){
        let views = [ { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS } ];

        let _dimensionDistane = this.dimensionDistance2;
        if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
            if(this.APP.sldExistingWidth1.currentValue > 0){
                _dimensionDistane = this.dimensionDistance;
            }
        }
        let height = this.utils.getHeightByAngle(this.APP.sldBuildingHeight.currentValue, this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue, this.APP.sltRoofPitch.currentValue, -1);
        let dimOffsetX = this.totalBaySize/2 + this.APP.patiosManager.patiosGroup.position.x + _dimensionDistane;
        let dimOffsetY = height/2;
        if(this.APP.sldExistingLength.currentValue/2 > this.totalBaySize/2){
            dimOffsetX = this.APP.sldExistingLength.currentValue/2 + _dimensionDistane;
        }
        
        if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
            dimOffsetX += this.APP.sldExistingLength2.currentValue;
        }

        this.dimensionPatiosFrontRight.position.set(dimOffsetX,dimOffsetY,0);
        

        //--------
        let scaleLength = height/this.lineGeo.length

        let cap1 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap1.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap1.position.setX(-dimOffsetY);

        let cap2 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap2.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap2.position.setX(dimOffsetY);

        let line = new Mesh(this.lineGeo.geometry, this.materialManager.DIMENSION_TEXT);
        line.userData = { type: GEOMETRY_TYPE.DIMENSION_LINE, views: views };
        //line.position.setX(this.APP.sldSpan.currentValue/2 + this.APP.sldMultiSpan.currentValue/2);
        line.scale.setX(scaleLength);

        let textStr = this.utils.getDimensionValue(height);
        this.textGeo = new TextBufferGeometry(textStr, this.textParameter);
        this.textGeo.center();

        let text = new Mesh(this.textGeo, MaterialManager.Instance().DIMENSION_TEXT);
        text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0,0,-Math.PI/2) };
        text.position.set(0,200,0);
        //text.rotation.set(0,-Math.PI,0);

        this.dimensionPatiosFrontRight.add(line, cap1, cap2, text);
    }
    private drawDimensionPatiosLeftBottom(){
        let dimOffsetY = -this.dimensionDistance2;
        let dimOffsetZ = this.dimensionPatiosPlanRight.position.z;
        
        this.dimensionPatiosLeftBottom.position.set(0,dimOffsetY,dimOffsetZ);

        let views = [ 
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
        ];

        this.dimensionPatiosPlanRight.children.forEach( groupDim => {
            groupDim.children.forEach(e => {
                let dim = e.clone();
                dim.userData['views'] = views;
                if(dim.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT){
                    dim.rotation.set(0,0,0);
                    dim.userData['views'] = [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS }];
                    dim.userData['rotation'] = new Vector3(0,0,0);
                }
                this.dimensionPatiosLeftBottom.add(dim);
             });
         })
    }
    private drawDimensionPatiosLeftRight(){
        let dimOffsetY = this.utils.getHeightByAngle(this.APP.sldBuildingHeight.currentValue, this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue, this.APP.sltRoofPitch.currentValue, -1);
        let dimOffsetZ = this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + this.APP.sldFrontOverhang.currentValue + this.dimensionDistance2;
       

        this.dimensionPatiosLeftRight.position.set(0,dimOffsetY/2,dimOffsetZ);

        let views = [ 
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
        ];

        
        let scaleLength = dimOffsetY / this.lineGeo.length

        let cap1 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap1.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap1.position.setX(-dimOffsetY/2);

        let cap2 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap2.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap2.position.setX(dimOffsetY/2);

        let line = new Mesh(this.lineGeo.geometry, this.materialManager.DIMENSION_TEXT);
        line.userData = { type: GEOMETRY_TYPE.DIMENSION_LINE, views: views };
        //line.position.setX(this.APP.sldSpan.currentValue/2 + this.APP.sldMultiSpan.currentValue/2);
        line.scale.setX(scaleLength);

        let textStr = this.utils.getDimensionValue(dimOffsetY);
        this.textGeo = new TextBufferGeometry(textStr, this.textParameter);
        this.textGeo.center();

        let text = new Mesh(this.textGeo, MaterialManager.Instance().DIMENSION_TEXT);
        text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS }], rotation: new Vector3(-Math.PI / 2, 0, 0) };
        text.position.set(0, 200, 0);
        //text.rotation.set(0,-Math.PI,0);

        this.dimensionPatiosLeftRight.add(line, cap1, cap2, text);
    }
    private drawDimensionPatiosLeftLeft() {
        let dimOffsetY = this.APP.sldBuildingHeight.currentValue;
        let dimOffsetZ = this.APP.sldBackOverhang.currentValue + 500;

        this.dimensionPatiosLeftLeft.position.set(0, dimOffsetY / 2, -dimOffsetZ);

        let views = [
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
        ];

        let offsetLength = dimOffsetY;
        let scaleLength = offsetLength / this.lineGeo.length

        let cap1 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap1.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap1.position.setX(-offsetLength / 2);

        let cap2 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap2.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap2.position.setX(offsetLength / 2);

        let line = new Mesh(this.lineGeo.geometry, this.materialManager.DIMENSION_TEXT);
        line.userData = { type: GEOMETRY_TYPE.DIMENSION_LINE, views: views };
        //line.position.setX(this.APP.sldSpan.currentValue/2 + this.APP.sldMultiSpan.currentValue/2);
        line.scale.setX(scaleLength);

        let textStr = this.utils.getDimensionValue(offsetLength);
        this.textGeo = new TextBufferGeometry(textStr, this.textParameter);
        this.textGeo.center();

        let text = new Mesh(this.textGeo, MaterialManager.Instance().DIMENSION_TEXT);
        text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS }], rotation: new Vector3(Math.PI / 2, 0, 0) };
        text.position.set(0, 200, 0);
        //text.rotation.set(0,-Math.PI,0);

        this.dimensionPatiosLeftLeft.add(line, cap1, cap2, text);

        //this.dimensionPatiosFrontRight.children.forEach(e => {
        //    let dim = e.clone();
        //    dim.userData['views'] = views;
        //    if(dim.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT){
        //        dim.userData['views'] = [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS }];
        //        dim.userData['rotation'] = new Vector3(-Math.PI/2,0,0);
        //    }
        //    this.dimensionPatiosLeftRight.add(dim);
        //});
    }
    private drawDimensionPatiosRightBottom(){
        let dimOffsetY = -this.dimensionDistance2;
        let dimOffsetZ = this.dimensionPatiosPlanRight.position.z;
        
        this.dimensionPatiosRightBottom.position.set(0,dimOffsetY,dimOffsetZ);

        let views = [ { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS } ];

        this.dimensionPatiosPlanRight.children.forEach(groupDim => {
            groupDim.children.forEach(e => {
                if(e.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT){
                    let dim = e.clone();
                    dim.userData['views'] = views;
                    dim.userData['rotation'] = new Vector3(0,0,0);
    
                    this.dimensionPatiosRightBottom.add(dim);
                }
            });
        })
    }
    private drawDimensionPatiosRightLeft(){
        let dimOffsetY = this.utils.getHeightByAngle(this.APP.sldBuildingHeight.currentValue, this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue, this.APP.sltRoofPitch.currentValue, -1);
        let dimOffsetZ = this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + this.APP.sldFrontOverhang.currentValue + this.dimensionDistance2;

        this.dimensionPatiosRightLeft.position.set(0,dimOffsetY/2,dimOffsetZ);

        let views = [ { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS } ];

        this.dimensionPatiosLeftRight.children.forEach(e => {
            if(e.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT){
                let dim = e.clone();
                dim.userData['views'] = views;
                dim.userData['rotation'] = new Vector3(Math.PI/2,0,0);
                dim.rotation.set(0,Math.PI,0);
                this.dimensionPatiosRightLeft.add(dim);
            }
        });
    }
    private drawDimensionPatiosRightRight(){
        let dimOffsetY = this.APP.sldBuildingHeight.currentValue;
        let dimOffsetZ = this.APP.sldBackOverhang.currentValue + 500;
        
        this.dimensionPatiosRightRight.position.set(0,dimOffsetY/2,-dimOffsetZ);

        let views = [ { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS } ];

        this.dimensionPatiosLeftLeft.children.forEach(e => {
            if(e.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT){                
                let dim = e.clone();
                dim.userData['views'] = views;
                dim.userData['rotation'] = new Vector3(-Math.PI/2,0,0);
                dim.rotation.set(0,Math.PI,0);
                dim.position.set(dim.position.x, -dim.position.y, dim.position.z);
                this.dimensionPatiosRightRight.add(dim);
            }
        });
    }
    
    
    private addOverhang(groupDim: Object3D, offset: number, overhangValue: number, views: any, side: string, rotateText: number, axis: string, labelType?: string) {
        if (overhangValue == 0){
            let dim = groupDim.children.find(el => el.userData?.groupType == labelType) as Dimension;
            if(dim){
                groupDim.remove(dim)
            }
            return;
        }

        let textStr = this.utils.getDimensionValue(overhangValue);

        let textRotation = new Vector3();
        let textUserData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, position: { overhang: true }};
        if (axis === "z") {
            textRotation.setZ(rotateText);
            textUserData['rotation'] = new Vector3(0, 0, -rotateText / 2);
            textUserData['position']['bottom'] = true;
        }
        else if (axis === "y") {
            textRotation.setY(rotateText);
            textUserData['rotation'] = new Vector3(0, -rotateText / 2, 0);
        }
        else if (axis === "x") {
            textRotation.setX(rotateText);
            textUserData['rotation'] = new Vector3(-rotateText / 2, 0, 0);
        }

        let linePos = new Vector3();
        if (side === "Left") {
            linePos.setX(-offset - overhangValue / 2);
        }
        else if (side === "Right") {
            linePos.setX(offset + overhangValue / 2);
        }
        let fitZ = 0;
        if(labelType){
            if(axis === 'z'){
                fitZ = 10;
            } else if(axis === 'y'){
                fitZ = -10;
            }
        }
        let options : AddDimensionOptions = {... this.options,
            lineLength: overhangValue,
            container: groupDim,
            views: views,
            linePos: linePos,
            textPos: linePos.clone().setY(linePos.y - 200),
            labelType: labelType ? labelType : null,
            fitZ: fitZ,
            textRotation: textRotation,
            textUserData: textUserData
        }
        let dim = groupDim.children.find(el => el.userData?.groupType == labelType) as Dimension;
        if(!dim){
            new Dimension(options);
        } else {
            dim.update(options)
        }
    }
    private getTextCover(textGeo: TextBufferGeometry, labelType: string, pos: Vector3, fitZ = 0, bayNum?: number){
        let textSize = new Vector3();
        textGeo.boundingBox.getSize(textSize)

        let textCover = new Mesh(new BoxBufferGeometry(textSize.x, textSize.y, textSize.z))
        textCover.position.set(pos.x , pos.y, pos.z + fitZ);
        textCover.userData = { type: GEOMETRY_TYPE.TEXT_COVER, labelType };
        if(bayNum !== undefined){
            textCover.userData['bayNum'] = bayNum;
        }
        textCover.material['opacity'] = 0;
        textCover.material['transparent'] = true;

        return textCover
    }

    public getOutlines(): Printing2DGeometry{
        let lsGeometries: Printing2DLine[] = [];
        let lsText: Print2DText[] = [];
        
        for(let dim of [
            ...this.dimensionPatiosPlanBottom.children,
            ...this.dimensionPatiosPlanRight.children,
            ...this.dimensionPatiosFrontBottom.children,
            ...this.dimensionPatiosFrontRight.children,
            ...this.dimensionPatiosLeftBottom.children,
            ...this.dimensionPatiosLeftRight.children,
            ...this.dimensionPatiosLeftLeft.children,
            ...this.dimensionPatiosRightBottom.children,
            ...this.dimensionPatiosRightLeft.children,
            ...this.dimensionPatiosRightRight.children,
            ...this.dimensionPlanMiddle.children,
            ...this.dimensionPlanMidleLeft.children
        ]){
            if(!dim.userData.views){
                console.log("TEXT NO VIEW", dim);
                continue;
            }
                
            if(dim.type == 'Group'){
                dim.children.forEach(el => {
                    el.updateMatrix();
                    let _clone = el.clone();
                    _clone.applyMatrix4(new Matrix4().getInverse(el.matrix));
                    let _box = new Box3().setFromObject(_clone);
        
                    let _points = this.utils.getStartEndPoint(_box);
                    let _line = new Line(new Geometry().setFromPoints(_points));
                    
                    el.updateWorldMatrix(true, true);
                    _line.geometry.applyMatrix4(el.matrixWorld);
        
                    let points = (_line.geometry as Geometry).vertices;
                    let pos = new Vector3().applyMatrix4(el.matrixWorld);
        
                    let textRotation = el.userData.rotation || new Vector3();
                    this.getDimInfo(lsGeometries, lsText, el, pos, textRotation, points)
                })
            } else{
                dim.updateMatrix();
                let _clone = dim.clone();
                _clone.applyMatrix4(new Matrix4().getInverse(dim.matrix));
                let _box = new Box3().setFromObject(_clone);
    
                let _points = this.utils.getStartEndPoint(_box);
                let _line = new Line(new Geometry().setFromPoints(_points));
                
                dim.updateWorldMatrix(true, true);
                _line.geometry.applyMatrix4(dim.matrixWorld);
    
                let points = (_line.geometry as Geometry).vertices;
                let pos = new Vector3().applyMatrix4(dim.matrixWorld);
    
                let textRotation = dim.userData.rotation || new Vector3();
                this.getDimInfo(lsGeometries, lsText, dim, pos, textRotation, points)
            }
        }
        
        return { lines: lsGeometries, texts: lsText };
    }
    private getDimInfo(lsGeometries: Printing2DLine[], lsText: Print2DText[], dim: Object3D, pos: Vector3, textRotation: Vector3, points: Vector3[]){
        if (dim.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && dim.userData.views && dim.userData.value) {
            let box = new Box3().setFromObject(dim.parent);
            let startEnd = this.utils.getStartEndPoint(box);
            let line = new Line3(startEnd[0], startEnd[1]);
    
            let projectPoint = new Vector3();
            line.closestPointToPoint(pos, true, projectPoint);
            let _pos = new Vector3().addVectors(projectPoint, new Vector3().subVectors(pos, projectPoint).normalize().multiplyScalar(10));
    
            if(dim.userData.position?.bottom){
                if(dim.userData.position?.overhang){
                    _pos = new Vector3().addVectors(projectPoint, new Vector3().subVectors(pos, projectPoint).normalize().multiplyScalar(180));
                }
                else{
                    _pos = new Vector3().addVectors(projectPoint, new Vector3().subVectors(pos, projectPoint).normalize().multiplyScalar(120));
                }
            }
            else{
                if(dim.userData.position?.overhang){
                    _pos = new Vector3().addVectors(projectPoint, new Vector3().subVectors(pos, projectPoint).normalize().multiplyScalar(100));
                }
            }
    
            let p = new Vector3().applyMatrix4(dim.matrixWorld);
    
            lsText.push({
                value: dim.userData.value,
                position: p,
                rotation: textRotation,
                views: dim.userData.views
            });
    
            
        }
        else if (dim.userData.type == GEOMETRY_TYPE.DIMENSION_LINE) {
            // this.scene.add(new LineSegments(new Geometry().setFromPoints(points), new LineBasicMaterial({color: new Color('red')})));
    
            lsGeometries.push({
                vertices: points,
                objectType: dim.userData.type,
                views: dim.userData.views
            });
        }
        else if (dim.userData.type == GEOMETRY_TYPE.DIMENSION_CAP) {
            lsGeometries.push({
                vertices: points,
                objectType: dim.userData.type,
                views: dim.userData.views
            });
        }
        else if (dim.userData.type == "Arrow") {
            let geo = ((dim as Line).geometry as Geometry).clone();
            geo.applyMatrix4(dim.matrixWorld);
            lsGeometries.push({
                vertices: geo.vertices,
                objectType: dim.userData.type,
                views: dim.userData.views
            });
        }
      }
    public destroy(): void {
        this.unregisterEvent();
        this.unload();
    }

    private registerEvent(): void {
        this.eventHandleId = this.uiChanged.bind(this);

        this.controlsToRegisterEvent = [
            this.APP.sldSpan,
            this.APP.sldMultiSpan,
            this.APP.sldFrontOverhang,
            this.APP.sldExistingWidth1,
            this.APP.sldExistingWidth2,
            this.APP.sldExistingLength,
            this.APP.sldExistingLength2,
            this.APP.sltExistingType,
            this.APP.sldExistingWallHeight,
            this.APP.dialogEditBay,
            this.APP.sldLeftOverhang,
            this.APP.sldRightOverhang,
            this.APP.sldBackOverhang,
            this.APP.sltRoofPitch
        ];
        this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));        
    }
    private unregisterEvent(): void{
        this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
        this.controlsToRegisterEvent = undefined;
    }


    public uiChanged(preVal: number, curVal: number): void {
        this.draw();
    }
}
