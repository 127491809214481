import { DialogAddBOMComponent } from "./component/dialog-add-bom/dialog-add-bom.component";
import { PanelBOMComponent } from "./component/panel-bom/panel-bom.component";
import { DialogBOMComponent } from "./component/dialog-bom/dialog-bom.component";
import { DialogAddCostComponent } from "./component/dialog-add-cost/dialog-add-cost.component";
import { SliderInputComponent } from "./component/slider-input/slider-input.component";
import { DropDownInputComponent } from "./component/dropdown-input/dropdown-input.component";
import { DropDownInputCustomComponent } from "./component/dropdown-input-custom/dropdown-input-custom.component";
import { SelectInputComponent } from "./component/select-input/select-input.component";
import { DialogEditBayComponent } from "./component/dialog-edit-bay/dialog-edit-bay.component";
import { DropDownColorInputComponent } from "./component/dropdown-color-input/dropdown-color-input.component";
import { SwitchInputComponent } from "./component/switch-input/switch-input.component";
import { CheckboxInputComponent } from "./component/checkbox-input/checkbox-input.component";
import { DialogRakeCutComponent } from "./component/dialog-rakecut/dialog-rakecut.component";
import { DialogDimensionComponent } from "./component/dialog-dimension/dialog-dimension.component";
import { DialogConfirmComponent } from "./component/dialog-confirm/dialog-confirm.component";
import { ToggleButtonComponent } from "./component/toggle-button/toggle-button.component";

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { APP_BASE_HREF } from "@angular/common";
import { appRoutingModule } from "./app.routing";
import { JwtInterceptor, ErrorInterceptor } from "./helpers";
import { HomeComponent } from "./containers/home";
import { HomeComponent as HomeRibspanComponent } from "src/app-ribspan/containers/home";
import { LoginComponent } from "./containers/login";
import { NgZorroAntdModule, NZ_ICONS } from "ng-zorro-antd";
import { IconDefinition } from "@ant-design/icons-angular";
import * as AllIcons from "@ant-design/icons-angular/icons";
import { NZ_I18N, en_US } from "ng-zorro-antd";
import { registerLocaleData } from "@angular/common";
import { NgxCurrencyModule } from "ngx-currency";
import en from "@angular/common/locales/en";
import { LightboxModule } from "ngx-lightbox";

registerLocaleData(en);
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};

const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);
export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ",",
  nullable: true,
};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeRibspanComponent,
    LoginComponent,

    DropDownInputComponent,
    ToggleButtonComponent,
    DialogConfirmComponent,
    DialogDimensionComponent,
    DialogRakeCutComponent,
    CheckboxInputComponent,
    SwitchInputComponent,
    DropDownColorInputComponent,
    DialogEditBayComponent,
    SelectInputComponent,
    DropDownInputCustomComponent,
    SliderInputComponent,
    DialogAddCostComponent,
    DialogBOMComponent,
    PanelBOMComponent,
    DialogAddBOMComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    NgZorroAntdModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    appRoutingModule,
    BrowserAnimationsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    LightboxModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: "/" },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
