import { Injectable } from "@angular/core";
import { Workbook } from "exceljs";
import * as fs from "file-saver";

@Injectable({ providedIn: "root" })
export class ExcelService {
  exportExcel(dataSource, dataSourceTotal, jobNo) {
    // const { dataSource, dataSourceTotal } = this.state;
    const _fileName = `${jobNo}-BOM.xlsx`;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("BOM");

    // add column headers
    const _headerStyle = {
      font: { name: "tahoma" },
      // alignment: { vertical: "middle", horizontal: "center" },
    };
    const _headerWidth = 10;
    const _headerList = [
      {
        header: "#",
        key: "idx",
        width: _headerWidth,
        style: _headerStyle,
      },
      { header: "Item No.", key: "itemNo", width: 25, style: _headerStyle },
      { header: "Qty", key: "qty", width: _headerWidth, style: _headerStyle },
      {
        header: "Length",
        key: "length",
        width: _headerWidth,
        style: _headerStyle,
      },
      {
        header: "Cut BK",
        key: "cutBk",
        width: _headerWidth,
        style: _headerStyle,
      },
      { header: "Dir", key: "dri", width: _headerWidth, style: _headerStyle },
      {
        header: "Description",
        key: "description",
        width: 70,
        style: _headerStyle,
      },
      { header: "UoM", key: "uom", width: _headerWidth, style: _headerStyle },
      { header: "Total UoM", key: "totalUom", width: 20, style: _headerStyle },
      {
        header: "Unit Price",
        key: "unitPrice",
        width: 20,
        style: { ..._headerStyle, numFmt: '"$"#,##0.00;[Red]-"$"#,##0.00' },
      },
      {
        header: "Price",
        key: "price",
        width: 20,
        style: { ..._headerStyle, numFmt: '"$"#,##0.00;[Red]-"$"#,##0.00' },
      },
    ];
    worksheet.columns = _headerList;
    let rowHeader = worksheet.getRow(1);
    for (let i = 0; i < _headerList.length; i++) {
      const cell = rowHeader.getCell(_headerList[i].key);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "2295ec" },
      };
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    }
    // Add row using key mapping to columns
    dataSource.map((value, index) => {
      worksheet.addRow([
        value.idx,
        value.itemNo,
        value.qty,
        value.length,
        value.cutBk,
        value.dri,
        value.description,
        value.uom,
        value.totalUom,
        value.unitPrice,
        value.price,
      ]);
      if (value.isAdd) {
        const _row = worksheet.lastRow;
        _row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "CCFFCC" },
          };
        });
      }
    });
    let _rowCount = dataSource.length + 1;
    const _totalPanelStyle = {
      font: { bold: true },
      numFmt: '"$"#,##0.00;[Red]-"$"#,##0.00',
      alignment: { horizontal: "right" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "cbe8fb" },
      },
    };
    dataSourceTotal.map((value, index) => {
      _rowCount++;
      const _newRow = worksheet.addRow({
        totalUom: value.description,
        price: value.price,
      });
      const _mergeRange = `I${_rowCount}:J${_rowCount}`;
      worksheet.mergeCells(_mergeRange);
      const _unitPricecell = _newRow.getCell("price");
      const _totalUomcell = _newRow.getCell("totalUom");
      if (_rowCount === dataSource.length + dataSourceTotal.length + 1) {
        _totalUomcell.font = {
          ..._totalPanelStyle.font,
          color: { argb: "f00000" },
        };
        _totalUomcell.numFmt = _totalPanelStyle.numFmt;
        _totalUomcell.alignment = { horizontal: "right" };
        _totalUomcell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "cbe8fb" },
        };
        _unitPricecell.style = _totalUomcell.style;
      } else {
        _totalUomcell.font = _totalPanelStyle.font;
        _totalUomcell.numFmt = _totalPanelStyle.numFmt;
        _totalUomcell.alignment = { horizontal: "right" };
        _totalUomcell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "cbe8fb" },
        };
        _unitPricecell.style = _totalUomcell.style;
      }
    });
    // save workbook to disk
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, _fileName);
    });
  }
}
