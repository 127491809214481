import _ from "lodash";
import Beam, { BeamOptions } from "src/app/models/Beam.model";
import UpstandBraket, {
  UpstandBraketOptions,
} from "src/app/models/UpstandBraket.model";
import {
  Box3,
  BoxBufferGeometry,
  BoxHelper,
  BufferGeometry,
  Geometry,
  Group,
  LineSegments,
  Material,
  Matrix4,
  Mesh,
  Plane,
  Vector3,
} from "three";
import { GableFBFasciaManager } from ".";
import { GEOMETRY_CATEGORY, GEOMETRY_TYPE, INIT } from "../../app.config";
import {
  BUILDING_SIDE,
  EXISTING_BUILDING_CONFIG,
  FASCIA_BRACKET_FIT,
  RAKECUT_TYPE,
} from "../../app.constants";
import {
  HomeComponent as AppComponent,
  HomeComponent,
} from "../../containers/home/home.component";
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import {
  GeometryInfo,
  LineType,
  Print2DView,
  Printing2DGeometry,
  Printing2DLine,
  ViewType,
} from "../models";
import { UI } from "../ui";
import { Util, getRafterBeamEndCapCode } from "../utils";

export class PostAndBeamManager {
  private scene: Group;
  private APP: AppComponent;
  private MANAGER: GableFBFasciaManager;
  private material: Material;
  private utils: Util;
  private geometryManager: GeometryManager;
  private postInfo: GeometryInfo;
  public beamInfo: GeometryInfo;
  private beamRakecutInfoLeft: GeometryInfo;
  private beamRakecutInfoRight: GeometryInfo;
  private geo_centerSuperiorPost: GeometryInfo;
  private geo_RafterBeam: GeometryInfo;
  private geo_rafterBeamEndCap: GeometryInfo;
  private geo_beamEndCap: GeometryInfo;
  private geo_groundBase: GeometryInfo;
  private eventHandleId: any;
  private roofWidth: number;
  private existingTypeLeftX: number;
  private existingTypeRightX: number;
  private existingType: number;
  private totalBaySize: number;
  private frontPostHeight: number;
  private downpipeGroupRight: Group;
  private downpipeGroupLeft: Group;
  private downpipeStepRakeCutGroupLeft: Group;
  private downpipeStepRakeCutGroupRight: Group;
  public geo_downPipe: GeometryInfo;
  private geo_downPipeL: GeometryInfo;
  private geo_roofBase: GeometryInfo;
  private geoBraketExtL_2: GeometryInfo;
  private geoBraketExtR_2: GeometryInfo;
  private objectSizeChangedHandle: any;
  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;

  private geo_beamStepRakecutLeft: GeometryInfo;
  private geo_beamStepRakecutRight: GeometryInfo;

  private geoBeamJoint: GeometryInfo;
  private geoHouseBeamJoint: GeometryInfo;

  private beamLeftCutSizeInfo: any;
  private beamRightCutSizeInfo: any;

  private geoBraket_1: GeometryInfo;
  public geoBraket_2: GeometryInfo;
  private geoBeam: GeometryInfo;
  private geoBeamEndCap: GeometryInfo;

  private offsetYBraket = 0;
  public numberOfBracketsSide = INIT.DEFAULT_BRACKET_NUMBER;
  public numOfBraket = INIT.DEFAULT_BRACKET_NUMBER;

  k: number;
  k_: number;
  j: number;
  l: number;
  m: number;
  v: number;

  constructor(app: AppComponent, fasciaManager: GableFBFasciaManager) {
    this.APP = app;
    this.MANAGER = fasciaManager;
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.scene = fasciaManager.patiosGroup;
    this.material = MaterialManager.Instance().DEFAULT.clone();
    this.registerEvent();
  }
  public destroy(): void {
    this.unregisterEvent();
  }
  public uiChanged(preVal: number, curVal: number): void {
    this.load();
  }
  private objectSizeChanged(pre: number, cur: number) {
    this.optimize().then(() => {
      this.load();
    });
  }
  private registerEvent(): void {
    this.eventHandleId = this.uiChanged.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldSpan,
      this.APP.sldMultiSpan,
      this.APP.sldExistingWallHeight,
      this.APP.sltRoofPitch,
      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,
      this.APP.sldExistingWidth1,
      this.APP.sltExistingType,
      this.APP.dialogEditBay,
      this.APP.sldEaveWidth,
      this.APP.sldFrontOverhang,
      this.APP.sltRoofThickness,
      this.APP.sltGutterType,
    ];
    //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));
    this.controlsToRegisterEvent2 = [
      this.APP.sltBeamType,
      this.APP.sltBeamSize,
      this.APP.sltColumnType,
    ];
    this.controlsToRegisterEvent2.forEach((c) =>
      c.addAction(this.objectSizeChangedHandle)
    );
  }
  private unregisterEvent(): void {
    //this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    //this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach((c) =>
      c.removeAction(this.objectSizeChangedHandle)
    );
    this.controlsToRegisterEvent2 = undefined;
  }
  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.beamInfo = this.geometryManager.getBeam();
      this.beamInfo.geometry
        .rotateY(Math.PI / 2)
        .translate(this.beamInfo.length / 2, -this.beamInfo.height / 2, 0);
      //.scale(this.APP.sldBaySize.currentValue / this.beamInfo.length, 1, 1);

      this.geoBeamJoint = this.geometryManager.getBeamJoint()
      this.geoHouseBeamJoint = this.geometryManager.getHouseBeamJoint()

      this.beamRakecutInfoLeft = this.geometryManager.getBeam();
      this.beamRakecutInfoLeft.geometry.translate(
        this.beamInfo.width / 2,
        -this.beamInfo.height / 2,
        -this.beamInfo.length / 2
      );

      this.beamRakecutInfoRight = this.geometryManager.getBeam();
      this.beamRakecutInfoRight.geometry.translate(
        -this.beamInfo.width / 2,
        -this.beamInfo.height / 2,
        -this.beamInfo.length / 2
      );

      this.geo_beamStepRakecutLeft = this.geometryManager.getBeam();
      this.geo_beamStepRakecutLeft.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_beamStepRakecutLeft.length / 2,
          -this.geo_beamStepRakecutLeft.height / 2,
          -this.geo_beamStepRakecutLeft.width / 2
        );

      this.geo_beamStepRakecutRight = this.geometryManager.getBeam();
      this.geo_beamStepRakecutRight.geometry
        .rotateY(Math.PI / 2)
        .translate(
          -this.geo_beamStepRakecutRight.length / 2,
          -this.geo_beamStepRakecutRight.height / 2,
          -this.geo_beamStepRakecutRight.width / 2
        );

      this.geo_beamEndCap = this.geometryManager.getBeamEndCap();
      this.geo_beamEndCap.geometry.translate(
        0,
        -this.geo_beamEndCap.height / 2,
        0
      );

      this.geo_RafterBeam = this.geometryManager.getRafterBeam();
      this.geo_RafterBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_RafterBeam.length / 2,
          -this.geo_RafterBeam.height / 2,
          this.geo_RafterBeam.width / 2
        );

      this.geo_rafterBeamEndCap = this.geometryManager.getRafterBeamEndCap();
      this.geo_rafterBeamEndCap.geometry.translate(
        0,
        -this.geo_rafterBeamEndCap.height / 2,
        this.geo_rafterBeamEndCap.length / 2
      );

      this.postInfo = this.geometryManager.getPost();
      this.postInfo.geometry
        .rotateX(Math.PI / 2)
        .translate(0, this.postInfo.height / 2, 0);
      //.scale(1, scalePostHeight, 1);

      this.geo_centerSuperiorPost = this.geometryManager.getCenterPost();
      this.geo_centerSuperiorPost.geometry
        .rotateX(Math.PI / 2)
        .translate(
          this.geo_centerSuperiorPost.width / 2,
          this.geo_centerSuperiorPost.height / 2,
          this.geo_centerSuperiorPost.width / 2
        );

      this.geo_groundBase = new GeometryInfo();
      this.geo_groundBase.width = 1000;
      this.geo_groundBase.height = 1;
      this.geo_groundBase.length = 1000;
      this.geo_groundBase.geometry = new BoxBufferGeometry(
        this.geo_groundBase.width,
        this.geo_groundBase.height,
        this.geo_groundBase.length
      );
      this.geo_groundBase.geometry.translate(
        this.geo_groundBase.width / 2,
        0,
        this.geo_groundBase.length / 2
      );

      this.geo_downPipe = this.geometryManager.getDownPipe();
      this.geo_downPipeL = this.geometryManager.getDownPipeL();
      this.geo_roofBase = this.geometryManager.getRoofBase();

      this.scene.remove(
        ...this.scene.children.filter(
          (c) => c.userData.type == GEOMETRY_TYPE.DOWNPIPE
        )
      );
      this.downpipeGroupRight = new Group();
      this.downpipeGroupRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.downpipeGroupRight.rotateY(Math.PI / 2);
      this.scene.add(this.downpipeGroupRight);

      this.downpipeGroupLeft = new Group();
      this.downpipeGroupLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.downpipeGroupLeft.rotateY(-Math.PI / 2);
      this.scene.add(this.downpipeGroupLeft);

      this.downpipeStepRakeCutGroupLeft = new Group();
      this.downpipeStepRakeCutGroupLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupLeft);

      this.downpipeStepRakeCutGroupRight = new Group();
      this.downpipeStepRakeCutGroupRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupRight);

      this.geoBraket_1 = this.geometryManager.getUpstandBraket(1);
      this.geoBraket_1.geometry.rotateX(Math.PI / 2);
      this.geoBraket_1.geometry.translate(0, this.geoBraket_1.length / 2, 0);
      this.geoBraket_2 = this.geometryManager.getUpstandBraket(2);
      this.geoBraket_2.geometry.rotateY(Math.PI);
      this.geoBraket_2.geometry.rotateX(Math.PI / 2);
      this.geoBraket_2.geometry.translate(
        0,
        this.geoBraket_2.length / 2,
        -this.geoBraket_2.height / 4
      );

      this.geoBraketExtL_2 = this.geometryManager.getUpstandBraket(2);
      this.geoBraketExtL_2.geometry.rotateX(Math.PI / 2);
      this.geoBraketExtL_2.geometry.rotateY(-Math.PI / 2);
      this.geoBraketExtL_2.geometry.rotateZ(-Math.PI);
      this.geoBraketExtL_2.geometry.translate(
        -this.geoBraketExtL_2.height / 2,
        this.geoBraketExtL_2.length / 2,
        this.geoBraketExtL_2.height / 3 - this.geoBraket_1.width / 2
      );
      //Right
      this.geoBraketExtR_2 = this.geometryManager.getUpstandBraket(2);
      this.geoBraketExtR_2.geometry.rotateX(Math.PI / 2);
      this.geoBraketExtR_2.geometry.rotateY(Math.PI / 2);
      this.geoBraketExtR_2.geometry.rotateZ(-Math.PI);
      this.geoBraketExtR_2.geometry.translate(
        this.geoBraketExtR_2.height / 2,
        this.geoBraketExtR_2.length / 2,
        this.geoBraketExtR_2.height / 3 - this.geoBraket_1.width / 2
      );

      this.geoBeam = this.geometryManager.getBeam();
      this.geoBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(0, this.geoBeam.height / 2, 0);

      this.geoBeamEndCap = this.geometryManager.getBeamEndCap();
      this.geoBeamEndCap.geometry
        .translate(
          0,
          this.geoBeamEndCap.height / 2,
          this.geoBeamEndCap.length / 2
        )
        .rotateY(Math.PI / 2);

      resolve();
    });
  }

  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_POST
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_CENTER_POST
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (o) => o.userData.type == GEOMETRY_TYPE.GROUND_BASE
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (o) => o.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_EXT
        )
      );
      this.downpipeGroupRight.children = [];
      this.downpipeGroupLeft.children = [];
      this.downpipeStepRakeCutGroupLeft.children = [];
      this.downpipeStepRakeCutGroupRight.children = [];

      this.beamLeftCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.LEFT
      );
      this.beamRightCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.RIGHT
      );

      this.totalBaySize = this.APP.dialogEditBay.listBay.reduce(
        (total, val) => total + val.value,
        0
      );
      this.roofWidth = UI.span;
      this.existingType = +this.APP.sltExistingType.currentValue;
      this.existingTypeLeftX = 0;
      this.existingTypeRightX = 0;
      if (this.existingType === 1 || this.existingType === 3) {
        this.existingTypeLeftX = this.APP.sldEaveWidth.currentValue;
      }
      if (this.existingType === 2 || this.existingType === 3) {
        this.existingTypeRightX = this.APP.sldEaveWidth.currentValue;
      }
      this.offsetYBraket = 0;
      if (UI.upstandBraketType == 150) {
        this.offsetYBraket =
          150 +
          this.beamInfo.height +
          (this.geoBraket_2.height / 4 + this.geoBraket_1.width) *
            this.utils.tan(UI.patiosPitch);
      } else if (UI.upstandBraketType == 300) {
        this.offsetYBraket =
          300 +
          this.beamInfo.height +
          (this.geoBraket_2.height / 4 + this.geoBraket_1.width) *
            this.utils.tan(UI.patiosPitch);
      } else if (UI.upstandBraketType == 450) {
        this.offsetYBraket =
          450 +
          this.beamInfo.height +
          (this.geoBraket_2.height / 4 + this.geoBraket_1.width) *
            this.utils.tan(UI.patiosPitch);
      } else if (UI.upstandBraketType == 600) {
        this.offsetYBraket =
          600 +
          this.beamInfo.height +
          (this.geoBraket_2.height / 4 + this.geoBraket_1.width) *
            this.utils.tan(UI.patiosPitch);
      }
      if (UI.isUpFasciaUpstandardBracket) {
        this.offsetYBraket -=
          (this.geoBraket_2.height / 4 + this.geoBraket_1.width) *
            this.utils.tan(UI.patiosPitch) +
          (this.geoBraket_2.height / 4) * this.utils.tan(UI.patiosPitch);
      }

      this.addCenterSuperiorBeam();
      this.addGround();
      let offsetZ = 0;
      let idx = 0;

      this.addHorSuperiorBeam(-UI.span / 2 + this.geoBeam.width / 2, {
        left: true,
        first: true,
        last: false,
      });
      this.addHorSuperiorBeam(UI.span / 2 - this.geoBeam.width / 2, {
        right: true,
        first: false,
        last: true,
      });

      this.addBeam(offsetZ, {
        back: idx == 0,
        center: !(idx == this.APP.dialogEditBay.listBay.length) && !(idx == 0),
        front: idx == this.APP.dialogEditBay.listBay.length,
      });

      this.addCenterPost(offsetZ, {
        center: !(idx == this.APP.dialogEditBay.listBay.length) && !(idx == 0),
        first: idx == 0,
        last: idx == this.APP.dialogEditBay.listBay.length,
      });

      this.addRafterSuperiorBeam(
        -UI.existingWidth1 / 2 + UI.eaveWidth + this.geoBraket_2.height / 4,
        {
          front: idx == this.APP.dialogEditBay.listBay.length,
          right: true,
          first: idx === 0,
          last: idx == this.APP.dialogEditBay.listBay.length,
        }
      );
      this.addRafterSuperiorBeam(
        -UI.existingWidth1 / 2 + UI.eaveWidth + this.geoBraket_2.height / 4,
        {
          back: idx == 0,
          left: true,
          first: idx === 0,
          last: idx == this.APP.dialogEditBay.listBay.length,
        }
      );

      this.APP.dialogEditBay.listBay.map((m) => {
        offsetZ += m.value;

        this.addBeam(offsetZ, {
          back: idx + 1 == 0,
          center:
            !(idx + 1 == this.APP.dialogEditBay.listBay.length) &&
            !(idx + 1 == 0),
          front: idx + 1 == this.APP.dialogEditBay.listBay.length,
        });

        this.addCenterPost(offsetZ, {
          center:
            !(idx + 1 == this.APP.dialogEditBay.listBay.length) &&
            !(idx + 1 == 0),
          first: idx + 1 == 0,
          last: idx + 1 == this.APP.dialogEditBay.listBay.length,
        });

        let fitBeam = 0;
        if (idx + 1 > 0 && idx + 1 < this.APP.dialogEditBay.listBay.length) {
          fitBeam = this.geo_RafterBeam.width / 2;
        } else if (idx + 1 == this.APP.dialogEditBay.listBay.length) {
          fitBeam = this.geo_RafterBeam.width;
        }

        this.addRafterSuperiorBeam(
          offsetZ -
            fitBeam -
            UI.existingWidth1 / 2 +
            UI.eaveWidth +
            this.geoBraket_2.height / 4,
          {
            front: idx + 1 == this.APP.dialogEditBay.listBay.length,
            right: true,
            first: idx + 1 === 0,
            last: idx + 1 == this.APP.dialogEditBay.listBay.length,
          }
        );
        this.addRafterSuperiorBeam(
          offsetZ -
            fitBeam -
            UI.existingWidth1 / 2 +
            UI.eaveWidth +
            this.geoBraket_2.height / 4,
          {
            back: idx + 1 == 0,
            left: true,
            first: idx + 1 === 0,
            last: idx + 1 == this.APP.dialogEditBay.listBay.length,
          }
        );

        idx++;
      });

      this.addBraketOrBeamSideRoof();
      this.showBeamOutline()
      this.addDownPipeRight();
      this.addDownPipeLeft();
      this.addUpstandBraket();

      this.updateUI();
      resolve();
    });
  }
  public showBeamOutline() {
    this.APP.scene.remove(
      ...this.APP.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.BEAM_OUTLINE
      )
    );

    if (!UI.beamLayoutShow) return;

    const objs = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    const meshes = []
    objs.forEach(el => {
      if(el.type == 'Group') {
        meshes.push(...el.children.filter((o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM))
      }
    })

    for (let o of meshes) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        10
      );
      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);

      var line = new LineSegments(
        outlineGeo,
        MaterialManager.Instance().BEAM_OUTLINE
      );
      line.userData = { type: GEOMETRY_TYPE.BEAM_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  public addBraketOrBeamSideRoof() {
    this.scene.remove(
      ...this.scene.children.filter(
        (o) => o.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_EXT
      )
    );
    this.scene.remove(
      ...this.scene.children.filter(
        (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_POST
      )
    );

    let offsetZ = 0;
    for (let i = 0; i <= UI.listBay.length; i++) {
      if (i == 0) {
        offsetZ += UI.listBay[i].value;
        continue;
      }
      this.addPost(offsetZ, {
        back: i + 1 == 0,
        center: !(i == this.APP.dialogEditBay.listBay.length) && !(i == 0),
        front: i == this.APP.dialogEditBay.listBay.length,
        first: i == 0,
        last: i == this.APP.dialogEditBay.listBay.length,
        left: true,
        right: false,
      });
      this.addPost(offsetZ, {
        back: i == 0,
        center: !(i == this.APP.dialogEditBay.listBay.length) && !(i == 0),
        front: i == this.APP.dialogEditBay.listBay.length,
        first: i == 0,
        last: i == this.APP.dialogEditBay.listBay.length,
        left: false,
        right: true,
      });

      if (i < UI.listBay.length) {
        offsetZ += UI.listBay[i].value;
      }
    }

    //LEFT SIDE
    if (
      UI.existingType == BUILDING_SIDE.LEFT ||
      UI.existingType == BUILDING_SIDE.BOTH
    ) {
      offsetZ = 0;
      let numOfBrakets = this.numberOfBracketsSide + 1;
      let distance =
        (UI.existingWidth1 - UI.eaveWidth - this.geoBraket_2.height / 4 >
        UI.totalBayLength
          ? UI.totalBayLength
          : UI.existingWidth1 - UI.eaveWidth - this.geoBraket_2.height / 4) /
        (numOfBrakets - 1);
      for (let i = 0; i < numOfBrakets; i++) {
        this.addUpstandBraketExt(offsetZ, {
          back: i == 0,
          center: !(i + 1 == numOfBrakets) && !(i == 0),
          front: i + 1 == numOfBrakets,
          first: i == 0,
          last: i + 1 == numOfBrakets,
          left: true,
          right: false,
        });

        offsetZ += distance;
      }
    }

    //RIGHT SIDE
    if (
      UI.existingType == BUILDING_SIDE.RIGHT ||
      UI.existingType == BUILDING_SIDE.BOTH
    ) {
      offsetZ = 0;
      let numOfBrakets = this.numberOfBracketsSide + 1;
      let distance =
        (UI.existingWidth1 - UI.eaveWidth - this.geoBraket_2.height / 4 >
        UI.totalBayLength
          ? UI.totalBayLength
          : UI.existingWidth1 - UI.eaveWidth - this.geoBraket_2.height / 4) /
        (numOfBrakets - 1);
      for (let i = 0; i < numOfBrakets; i++) {
        this.addUpstandBraketExt(offsetZ, {
          back: i == 0,
          center: !(i + 1 == numOfBrakets) && !(i == 0),
          front: i + 1 == numOfBrakets,
          first: i == 0,
          last: i + 1 == numOfBrakets,
          left: false,
          right: true,
        });

        offsetZ += distance;
      }
    }
  }
  public addUpstandBraket() {
    this.scene.remove(
      ...this.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET
      )
    );
    if (UI.upstandBraketType == 0 || UI.listBay.length == 0) {
      return;
    }
    let height = +UI.upstandBraketType;

    const _bracketOffsetXExt = this.geoBraket_2.height / 5;
    let distance = 1000;
    let offsetX = -UI.existingLength1 / 2;
    let spanLength = UI.span;

    if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
      if (UI.existingType == 1 || UI.existingType == 2) {
        spanLength -= UI.existingLength2;
      }
      if (UI.existingType == 3) {
        spanLength -= 2 * UI.existingLength2;
      }
    }

    if (UI.existingType == 0) {
      let startX =
        -spanLength / 2 > -UI.existingLength1 / 2
          ? -spanLength / 2
          : -UI.existingLength1 / 2;
      let endX =
        spanLength / 2 < UI.existingLength1 / 2
          ? spanLength / 2
          : UI.existingLength1 / 2;
      distance = (endX - startX) / (this.numOfBraket - 1);
      offsetX = startX;
    } else if (UI.existingType == 1) {
      distance = (spanLength - _bracketOffsetXExt) / (this.numOfBraket - 1);
      offsetX = -spanLength / 2 + _bracketOffsetXExt;
      if (spanLength > UI.existingLength1 - UI.eaveWidth) {
        distance =
          (UI.existingLength1 - UI.eaveWidth - _bracketOffsetXExt) /
          (this.numOfBraket - 1);
      }
      if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
        offsetX = -spanLength / 2 + UI.existingLength2 / 2 + _bracketOffsetXExt;
      }
    } else if (UI.existingType == 2) {
      offsetX = -spanLength / 2;
      distance = (spanLength - _bracketOffsetXExt) / (this.numOfBraket - 1);

      if (spanLength > UI.existingLength1 - UI.eaveWidth) {
        offsetX =
          -spanLength / 2 + (spanLength - UI.existingLength1 + UI.eaveWidth);
        distance =
          (UI.existingLength1 - _bracketOffsetXExt - UI.eaveWidth) /
          (this.numOfBraket - 1);
      }

      if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
        offsetX -= UI.existingLength2 / 2;
      }
    } else if (UI.existingType == 3) {
      distance = (spanLength - _bracketOffsetXExt * 2) / (this.numOfBraket - 1);
      offsetX = -spanLength / 2 + _bracketOffsetXExt;
    }

    let optionBrakets: UpstandBraketOptions = {
      container: this.scene,
      position: new Vector3(),
      geoBraket_1: this.geoBraket_1,
      geoBraket_2: this.geoBraket_2,
      height: height,
      material: MaterialManager.Instance().FASCIA_BRACKET.clone(),
    };
    let views: Print2DView[] = [
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    let userData = {
      type: GEOMETRY_TYPE.UPSTAND_BRAKET,
      views: views,
    };
    for (let i = 0; i < this.numOfBraket; i++) {
      let posX = offsetX;
      if (i == 0) {
        posX = posX + this.geoBraket_1.width / 2;
      } else if (i == this.numOfBraket - 1) {
        posX = posX - this.geoBraket_1.width / 2;
      }
      if (
        (UI.existingType == BUILDING_SIDE.LEFT ||
          UI.existingType == BUILDING_SIDE.BOTH) &&
        i == 0
      ) {
        offsetX += distance;
        continue;
      }
      if (
        (UI.existingType == BUILDING_SIDE.RIGHT ||
          UI.existingType == BUILDING_SIDE.BOTH) &&
        i == this.numOfBraket - 1
      ) {
        offsetX += distance;
        continue;
      }

      new UpstandBraket({
        ...optionBrakets,
        position: new Vector3(
          posX,
          UI.existingWallHeight,
          UI.eaveWidth - UI.existingWidth1 / 2
        ),
        userData: userData,
      });
      offsetX += distance;
    }
  }
  private addDownPipeRight() {
    let offsetZ =
      this.totalBaySize - 50 + UI.eaveWidth + this.geoBraket_2.height / 4;
    let offsetX = UI.span / 2 + 100;

    if (this.APP.sltExistingType.currentValue != 0) {
      offsetZ -= this.APP.sldExistingWidth1.currentValue / 2;
    }

    let offsetY =
      this.utils.getHeightByAngle(
        UI.eaveHeight + +UI.upstandBraketType,
        UI.overhangRight,
        UI.patiosPitch,
        -1
      ) +
      this.geo_roofBase.height +
      this.geoBeam.height +
      90;

    if (this.APP.sltGutterType.currentValue == 0) {
      offsetY -= 80;
    } else {
      offsetY -= 60;
    }

    if (
      this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT ||
      this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH
    ) {
      let meshPipe = new Mesh(
        this.geo_downPipe.geometry,
        MaterialManager.Instance().DOWNPIPE
      );
      meshPipe.userData = { type: GEOMETRY_TYPE.DOWNPIPE_NOZZLE };
      meshPipe.scale.setY(100 / this.geo_downPipe.height);
      meshPipe.position.set(
        0,
        -190,
        UI.overhangRight / this.utils.cos(UI.patiosPitch) -
          this.geo_downPipe.width / 2 -
          20
      );

      this.downpipeGroupRight.add(meshPipe);
      this.downpipeGroupRight.position.set(offsetX, offsetY, offsetZ);
    } else {
      this.utils.createDownpipeGroup(
        this.APP,
        this.downpipeGroupRight,
        this.geo_downPipe,
        this.geo_downPipeL,
        offsetX,
        offsetY,
        offsetZ,
        0,
        0,
        UI.overhangRight,
        offsetY,
        true
      );
    }
  }
  private addDownPipeLeft() {
    let offsetZ =
      this.totalBaySize - 50 + UI.eaveWidth + this.geoBraket_2.height / 4;
    let offsetX = -UI.span / 2 - 100;

    if (this.APP.sltExistingType.currentValue != 0) {
      offsetZ -= this.APP.sldExistingWidth1.currentValue / 2;
    }

    let offsetY =
      this.utils.getHeightByAngle(
        UI.eaveHeight + +UI.upstandBraketType,
        UI.overhangLeft,
        UI.patiosPitch,
        -1
      ) +
      this.geo_roofBase.height +
      this.geoBeam.height +
      90;

    if (this.APP.sltGutterType.currentValue == 0) {
      offsetY -= 80;
    } else {
      offsetY -= 60;
    }

    if (
      this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT ||
      this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH
    ) {
      let meshPipe = new Mesh(
        this.geo_downPipe.geometry,
        MaterialManager.Instance().DOWNPIPE
      );
      meshPipe.userData = { type: GEOMETRY_TYPE.DOWNPIPE_NOZZLE };
      meshPipe.scale.setY(100 / this.geo_downPipe.height);
      meshPipe.position.set(
        0,
        -190,
        UI.overhangLeft / this.utils.cos(UI.patiosPitch) -
          this.geo_downPipe.width / 2 -
          20
      );

      this.downpipeGroupLeft.add(meshPipe);
      this.downpipeGroupLeft.position.set(offsetX, offsetY, offsetZ);
    } else {
      this.utils.createDownpipeGroup(
        this.APP,
        this.downpipeGroupLeft,
        this.geo_downPipe,
        this.geo_downPipeL,
        offsetX,
        offsetY,
        offsetZ,
        0,
        0,
        UI.overhangLeft,
        offsetY,
        true
      );
    }
  }
  private addGround() {
    let offsetX = -(UI.span / 2 + UI.overhangLeft);
    let offsetZ =
      -this.APP.sldExistingWidth1.currentValue / 2 - UI.overhangBack;
    let width = UI.span + UI.overhangLeft + UI.overhangRight;
    let length =
      this.totalBaySize +
      UI.overhangFront +
      UI.overhangBack +
      this.APP.sldEaveWidth.currentValue +
      this.geoBraket_2.height / 4;

    let base = new Mesh(
      this.geo_groundBase.geometry,
      MaterialManager.Instance().BASE
    );
    base.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.GROUND_BASE,
    };
    base.position.set(offsetX, 0, offsetZ);
    base.scale.set(
      width / this.geo_groundBase.width,
      1,
      length / this.geo_groundBase.length
    );

    this.scene.add(base);
  }
  private getRoofHeight() {
    const _height = this.utils.getHeightByAngle(
      this.APP.sldExistingWallHeight.currentValue,
      UI.span / 2,
      this.APP.sltRoofPitch.currentValue,
      1
    );
    return this.APP.sldExistingWallHeight.currentValue - _height;
  }
  private updateUI() {
    this.APP.roofHeight = this.getRoofHeight();
    this.APP.sldMinHeight.setValue(_.round(this.frontPostHeight, 2));
  }
  public getSection(): Printing2DGeometry {
    let objs = this.scene.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_POST ||
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_CENTER_POST
    );

    objs = [...objs];

    let lsGeometries: Printing2DLine[] = [];

    for (let o of objs) {
      let box = new BoxHelper(o);
      box.geometry.translate(0, 5000, 0);
      box.userData = { type: "COLUMN_OUTLINE" };
      //this.scene.add(box);

      let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: outlineGeo.vertices,
        views: o.userData.views,
      });

      // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
      // line.userData = {type: "OUTLINE"};
      // //line.position.set(o.position.x - 5000, o.position.y, o.position.z);
      // this.scene.add( line );
    }
    let beamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    for (let g of beamGroups) {
      for (let c of g.children) {
        c.updateMatrix();

        let objCloned = c.clone();
        objCloned.applyMatrix4(new Matrix4().getInverse(c.matrix));

        let box = new BoxHelper(objCloned);

        c.updateWorldMatrix(true, true);

        box.geometry.applyMatrix4(c.matrixWorld);
        box.geometry.translate(0, 5000, 0);
        box.userData = { type: "COLUMN_OUTLINE" };

        let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
        const planView = _.find(g.userData.views, (el) => el.viewType == ViewType.PLAN)
        const anotherViews = _.filter(g.userData.views, (el) => el.viewType != ViewType.PLAN)
        if(anotherViews.length > 0) {
          lsGeometries.push({
            objectType: g.userData.type,
            vertices: outlineGeo.vertices,
            views: anotherViews,
          });
        } 
        if(planView) {
          lsGeometries.push({
            objectType: g.userData.type,
            vertices: outlineGeo.vertices.slice(0, 8).filter((el, index) => [0, 1, 4, 5].includes(index)),
            views: [planView],
          });
        }
      }
    }

    let braketGroup = this.scene.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET ||
        o.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT ||
        o.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET_EXT
    );

    for (let g of braketGroup) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        g.children[1] as Mesh,
        60
      );
      g.children[1].updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(g.children[1].matrixWorld);
      outlineGeo.translate(0, 5000, 0);

      let simplifiedGeo = this.simplifyGeo(outlineGeo);
      lsGeometries.push({
        objectType: g.userData.type,
        vertices: simplifiedGeo.vertices,
        views: g.userData.views,
      });

      g.children[0].updateMatrix();
      let objCloned = g.children[0].clone();
      objCloned.applyMatrix4(new Matrix4().getInverse(g.children[0].matrix));

      let box = new BoxHelper(objCloned);

      g.children[0].updateWorldMatrix(true, true);

      box.geometry.applyMatrix4(g.children[0].matrixWorld);
      box.geometry.translate(0, 5000, 0);
      box.userData = { type: "COLUMN_OUTLINE" };
      //this.APP.scene.add(box);

      let outlineGeo1 = this.simplifyGeo(box.geometry as BufferGeometry);
      lsGeometries.push({
        objectType: g.userData.type,
        vertices: outlineGeo1.vertices,
        views: g.userData.views,
      });
    }

    let rafterBeamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
    );
    for (let o of rafterBeamGroups) {
      o.children.forEach((el) => {
        this.utils.getOutlineRafterBeam(el as Mesh, lsGeometries);
      });
    }

    return { lines: lsGeometries, texts: [] };
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let vertices = geo.getAttribute("position").array;
    let lineGeo = new Geometry();
    for (let i = 0; i < vertices.length; i += 3) {
      lineGeo.vertices.push(
        new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2])
      );
    }

    //2-3
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    //3-7
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    //7-6
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    //6-2
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );

    //1-4
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    //4-8
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    //8-5
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    //5-1
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );

    return lineGeo;
  }
  private addCenterPost(offsetZ, userDataPos) {
    let meshPost = new Mesh(
      this.geo_centerSuperiorPost.geometry,
      MaterialManager.Instance().BEAM
    );
    meshPost.userData = {
      ...userDataPos,
      type: GEOMETRY_TYPE.SUPERIOR_CENTER_POST,
      category: GEOMETRY_CATEGORY.PATIOS,
    };

    let scalePostY = 1;

    let views: Print2DView[];

    this.frontPostHeight = UI.existingWallHeight + +UI.upstandBraketType;

    scalePostY =
      ((UI.span / 2 - this.geoBeam.width / 2) * this.utils.tan(UI.patiosPitch) -
        this.geoBeam.height) /
      this.geo_centerSuperiorPost.height;

    if (userDataPos.last) {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else {
      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    let postOffsetX = -this.geo_centerSuperiorPost.width / 2;

    let postOffsetZ =
      offsetZ -
      this.geo_centerSuperiorPost.width / 2 -
      UI.existingWidth1 / 2 +
      UI.eaveWidth +
      this.geoBraket_2.height / 4;
    if (userDataPos.first) {
      postOffsetZ =
        offsetZ -
        UI.existingWidth1 / 2 +
        UI.eaveWidth +
        this.geoBraket_2.height / 4;
    } else if (userDataPos.last) {
      postOffsetZ =
        offsetZ -
        this.geo_centerSuperiorPost.width -
        UI.existingWidth1 / 2 +
        UI.eaveWidth +
        this.geoBraket_2.height / 4;
    }

    meshPost.userData.views = views;
    meshPost.position.set(
      postOffsetX,
      UI.existingWallHeight + +UI.upstandBraketType + this.geoBeam.height,
      postOffsetZ
    );
    meshPost.scale.setY(scalePostY);

    this.scene.add(meshPost);
  }
  public addPost(offsetZ: number, userDataPos: any) {
    const condition = offsetZ + this.APP.sldEaveWidth.currentValue;
    if (
      (this.existingType === 1 || this.existingType === 3) &&
      userDataPos.left &&
      (UI.existingWidth1 >= condition + UI.multiSpan ||
        (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
          UI.cutOutType == 1))
    ) {
      return;
    }
    if (
      (this.existingType === 2 || this.existingType === 3) &&
      userDataPos.right &&
      (UI.existingWidth1 >= condition + UI.multiSpan ||
        (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
          UI.cutOutType == 1))
    ) {
      return;
    }

    let views: Print2DView[] = [];
    if (userDataPos.back || userDataPos.center) {
      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    const mesh = new Mesh(
      this.postInfo.geometry,
      MaterialManager.Instance().POST
    );
    let posZ =
      offsetZ -
      UI.existingWidth1 / 2 +
      UI.eaveWidth +
      this.geoBraket_2.height / 4;
    if (userDataPos.back) {
      posZ =
        offsetZ +
        this.postInfo.width / 2 -
        UI.existingWidth1 / 2 +
        UI.eaveWidth +
        this.geoBraket_2.height / 4;
    } else if (userDataPos.front) {
      posZ =
        offsetZ -
        this.postInfo.width / 2 -
        UI.existingWidth1 / 2 +
        UI.eaveWidth +
        this.geoBraket_2.height / 4;
    }

    let length =
      UI.existingWallHeight + +UI.upstandBraketType + this.geoBeam.height;
    let scaleY = length / this.postInfo.height;

    let offsetX = 0;
    if (userDataPos.left) {
      offsetX = -UI.span / 2 + this.postInfo.width / 2;
    } else if (userDataPos.right) {
      offsetX = UI.span / 2 - this.postInfo.width / 2;
    }

    mesh.position.set(offsetX, 0, posZ);
    mesh.scale.setY(scaleY);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_POST,
      position: userDataPos,
      views: views,
    };
    this.scene.add(mesh);
  }

  private addUpstandBraketExt(offsetZ: number, userDataPos: any) {
    if (
      UI.upstandBraketType == 0 ||
      UI.listBay.length == 0 ||
      UI.existingType == 0
    ) {
      return;
    }
    if (
      (this.existingType == 1 && userDataPos.right) ||
      (this.existingType == 2 && userDataPos.left)
    ) {
      return;
    }
    let _flag = false;

    const condition = offsetZ + this.APP.sldEaveWidth.currentValue;
    if (!userDataPos.isMultiSpan && !_flag) {
      if (
        (this.existingType === 1 || this.existingType === 3) &&
        userDataPos.left &&
        (UI.existingWidth1 >= condition ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition &&
            UI.cutOutType == 1))
      ) {
        _flag = true;
        // return;
      }
      if (
        (this.existingType === 2 || this.existingType === 3) &&
        userDataPos.right &&
        (UI.existingWidth1 >= condition ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition &&
            UI.cutOutType == 1))
      ) {
        _flag = true;
        // return;
      }
    } else if (!_flag) {
      if (
        (this.existingType === 1 || this.existingType === 3) &&
        userDataPos.left &&
        (UI.existingWidth1 >= condition + UI.multiSpan ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
            UI.cutOutType == 1))
      ) {
        _flag = true;
        // return;
      }
      if (
        (this.existingType === 2 || this.existingType === 3) &&
        userDataPos.right &&
        (UI.existingWidth1 >= condition + UI.multiSpan ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
            UI.cutOutType == 1))
      ) {
        _flag = true;
        // return;
      }
    }
    if (!_flag) {
      return;
    }

    const _bracketOffsetXExt = this.geoBraket_2.height / 5; // + this.geoBraket_1.width / 4;
    let height = 0;
    let offsetX = -UI.existingLength1 / 2;
    let spanLength = UI.span;

    if (!AppComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
      if (UI.existingType == 1 || UI.existingType == 2) {
        spanLength -= UI.existingLength2;
      }
      if (UI.existingType == 3) {
        spanLength -= 2 * UI.existingLength2;
      }
    }

    const _cond1 =
      UI.existingWidth1 < condition &&
      UI.existingWidth1 + UI.existingWidth2 > condition;
    const _cond2 =
      userDataPos.isMultiSpan &&
      UI.existingWidth1 < condition + UI.multiSpan &&
      UI.existingWidth1 + UI.existingWidth2 > condition + UI.multiSpan;

    if (UI.existingType == BUILDING_SIDE.LEFT) {
      offsetX = -spanLength / 2 + this.geoBraket_1.width - FASCIA_BRACKET_FIT;
      if (!AppComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
        offsetX = -spanLength / 2 + UI.existingLength2 / 2;
      }
      offsetX += _bracketOffsetXExt;
    } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
      offsetX = spanLength / 2 - this.geoBraket_1.width + FASCIA_BRACKET_FIT;
      if (!AppComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
        offsetX -= UI.existingLength2 / 2;
      }
      offsetX -= _bracketOffsetXExt;
    } else if (UI.existingType == 3) {
      offsetX =
        (-spanLength / 2) * (userDataPos.left ? 1 : -1) +
        (userDataPos.left ? this.geoBraket_1.width - FASCIA_BRACKET_FIT : 0) -
        (userDataPos.right ? this.geoBraket_1.width - FASCIA_BRACKET_FIT : 0);
      if ((!userDataPos.isMultiSpan && _cond1) || _cond2) {
        offsetX -=
          UI.existingLength2 * (userDataPos.left ? 1 : -1) +
          (userDataPos.right ? this.geoBraket_1.width : 0);
      }
      offsetX += _bracketOffsetXExt * (userDataPos.left ? 1 : -1);
    }

    let posZ =
      offsetZ -
      this.geoBraket_1.width / 2 -
      UI.existingWidth1 / 2 +
      UI.eaveWidth;
    if (userDataPos.front) {
      posZ =
        offsetZ - this.geoBraket_1.width - UI.existingWidth1 / 2 + UI.eaveWidth;
    }
    if (userDataPos.back) {
      posZ =
        offsetZ +
        this.geoBraket_1.width / 2 -
        UI.existingWidth1 / 2 +
        UI.eaveWidth;
    }

    height = +UI.upstandBraketType;

    let optionBrakets: UpstandBraketOptions = {
      container: this.scene,
      position: new Vector3(),
      geoBraket_1: this.geoBraket_1,
      geoBraket_2:
        UI.existingType == 2 ? this.geoBraketExtR_2 : this.geoBraketExtL_2,
      height: height,
      material: MaterialManager.Instance().FASCIA_BRACKET.clone(),
    };
    if (UI.existingType == 1) {
      optionBrakets = { ...optionBrakets, geoBraket_2: this.geoBraketExtL_2 };
    } else if (UI.existingType == 2) {
      optionBrakets = { ...optionBrakets, geoBraket_2: this.geoBraketExtR_2 };
    } else {
      optionBrakets = {
        ...optionBrakets,
        geoBraket_2: userDataPos.right
          ? this.geoBraketExtR_2
          : this.geoBraketExtL_2,
      };
    }
    let views: Print2DView[] = [
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    if (userDataPos.right) {
      views = [
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }
    new UpstandBraket({
      ...optionBrakets,
      position: new Vector3(offsetX, UI.existingWallHeight, posZ),
      userData: { type: GEOMETRY_TYPE.UPSTAND_BRAKET_EXT, views },
    });
  }

  public addBeam(offsetZ: number, userDataPos: any) {
    let offsetY = 0;
    let offsetX = -UI.span / 2 + this.postInfo.width;
    if (userDataPos.back) {
      offsetX = -UI.span / 2 + this.geoBeam.width;
    }

    let beamLength = UI.span - 2 * this.postInfo.width;
    if (userDataPos.back) {
      beamLength = UI.span - 2 * this.geoBeam.width;
    }

    let posZ = 0;
    let views: Print2DView[] = [];
    if (userDataPos.front) {
      offsetY =
        UI.existingWallHeight + +UI.upstandBraketType + this.geoBeam.height;
      posZ =
        offsetZ -
        this.geoBeam.width / 2 -
        UI.existingWidth1 / 2 +
        UI.eaveWidth +
        this.geoBraket_2.height / 4;

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.center) {
      offsetY =
        UI.existingWallHeight + +UI.upstandBraketType + this.geoBeam.height;
      posZ =
        offsetZ -
        UI.existingWidth1 / 2 +
        UI.eaveWidth +
        this.geoBraket_2.height / 4;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.back) {
      offsetY =
        UI.existingWallHeight + +UI.upstandBraketType + this.geoBeam.height;
      posZ =
        offsetZ +
        this.geoBeam.width / 2 -
        UI.existingWidth1 / 2 +
        UI.eaveWidth +
        this.geoBraket_2.height / 4;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    let beamGroup: Group;
    if (this.APP.sltBeamType.currentValue == 0) {
      beamGroup = this.utils.createBeamGroup2(
        this.beamInfo,
        this.geo_beamEndCap,
        beamLength,
        new Vector3(offsetX, offsetY, posZ),
        new Vector3(),
        views,
        1,
        userDataPos,
        0,
        "x",
        false,
        {
          hasStartCap: UI.beamType == 0,
          hasEndCap: UI.beamType == 0,
          hasStartJoint: false,
          hasEndJoint: false,
          jointGeo: null,
        }
      );
    } else {
      beamGroup = this.utils.createBeamGroup2(
        this.beamInfo,
        this.geo_beamEndCap,
        beamLength,
        new Vector3(offsetX, offsetY, posZ),
        new Vector3(),
        views,
        1,
        userDataPos,
        0,
        "x",
        false,
        {
          hasStartCap: UI.beamType == 0,
          hasEndCap: UI.beamType == 0,
          hasStartJoint: false,
          hasEndJoint: false,
          jointGeo: null,
        }
      );
    }

    beamGroup.userData = {
      ...beamGroup.userData,
      name: "GB",
    };

    this.scene.add(beamGroup);
  }
  public addHorSuperiorBeam(offsetX, userDataPos: any) {
    let offsetZ = 0;
    let offsetY = 0;

    let beamLength = this.totalBaySize + UI.overhangBack + UI.overhangFront;

    let views: Print2DView[];

    offsetY = UI.existingWallHeight + +UI.upstandBraketType;
    offsetZ =
      this.totalBaySize +
      UI.overhangFront -
      UI.existingWidth1 / 2 +
      UI.eaveWidth +
      this.geoBraket_2.height / 4;

    if (userDataPos.left) {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    this.cutStandardBeamByCutBeamWithinBayControl(
      this.beamInfo,
      this.geo_beamEndCap,
      beamLength,
      new Vector3(offsetX, offsetY + this.beamInfo.height, offsetZ),
      new Vector3(0, Math.PI / 2, 0),
      views,
      1,
      userDataPos,
      0,
      'x',
      "EB",
      this.geoBeamJoint
    )
  }
  private cutStandardBeamByCutBeamWithinBayControl(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    beamGeoTranslationZ: number,
    beamDirection: string,
    beamName = '',
    jointGeo: GeometryInfo,
  ){
    // Map list cut beam here
    const beamStartZ = pos.z - length
    const beamEndZ = pos.z

    let endOfBayZ = - UI.existingWidth1 / 2 + UI.eaveWidth + this.geoBraket_2.height / 4
    let startCutBeamZ = beamStartZ
    let endCutBeamZ = beamStartZ

    const beams = this.utils.getListSeperateBeamsByBaysForFbGable(endOfBayZ, beamStartZ, beamEndZ, endCutBeamZ, startCutBeamZ)
    
    for(let i = 0; i < beams.length; i++) {
      endCutBeamZ = beams[i][1]
      startCutBeamZ = beams[i][0]
      let beamGroup = this.utils.createBeamGroup2(
        beamGeo,
        beamCapGeo,
        endCutBeamZ - startCutBeamZ,
        new Vector3(pos.x, pos.y, endCutBeamZ),
        rot,
        views,
        directionOffset,
        userDataPos,
        beamGeoTranslationZ,
        beamDirection,
        UI.beamLayoutShow,
        {
          hasStartCap: UI.beamType == 0 && (i == beams.length - 1 || (i == 0 && beams.length == 1)),
          hasEndCap: UI.beamType == 0 && (i == 0),
          hasStartJoint: false,
          hasEndJoint: i !== 0,
          jointGeo: jointGeo,
        }
      )
      beamGroup.userData = {
        ...beamGroup.userData,
        name: beamName,
      };
      this.scene.add(beamGroup);
    }
  }
  public addRafterSuperiorBeam(offsetZ, userDataPos: any) {
    if (
      this.APP.sltWindClass.currentValue == 0 ||
      this.APP.sltWindClass.currentValue == 1
    ) {
      if (this.totalBaySize <= 6000 && this.APP.sldSpan.currentValue <= 7000) {
        return;
      }
    }
    if (
      this.APP.sltWindClass.currentValue == 3 ||
      this.APP.sltWindClass.currentValue == 4 ||
      this.APP.sltWindClass.currentValue == 5
    ) {
      return;
    }
    let mesh;

    let offsetXL = 0;
    let offsetXR =
      (UI.span / 2 - this.geoBeam.width / 2) / this.utils.cos(UI.patiosPitch);
    let offsetY = 0;

    let scaleX =
      (UI.span / 2 - this.geoBeam.width / 2) /
      this.utils.cos(UI.patiosPitch) /
      this.geo_RafterBeam.length;

    let views: Print2DView[];

    let fitZ = 0;

    if (userDataPos.left) {
      let material = MaterialManager.Instance().BEAM.clone();

      let nBottom = new Vector3(0, 1, 0).normalize();
      let pBottom = new Vector3(
        0,
        UI.existingWallHeight + +UI.upstandBraketType + this.geoBeam.height,
        0
      );
      let planeBottom = new Plane().setFromNormalAndCoplanarPoint(
        nBottom,
        pBottom
      );

      let beamOffsetX = 0;

      let nVer = new Vector3(-1, 0, 0).normalize();
      let pVer = new Vector3(
        -this.geoBeam.width / 2 + beamOffsetX + this.scene.position.x + fitZ,
        0,
        0
      );
      let planeVer = new Plane().setFromNormalAndCoplanarPoint(nVer, pVer);

      material.clippingPlanes = [planeBottom, planeVer];

      mesh = new Mesh(this.geo_RafterBeam.geometry, material);

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.right) {
      let material = MaterialManager.Instance().BEAM.clone();

      let nBottom = new Vector3(0, 1, 0).normalize();
      let pBottom = new Vector3(
        0,
        UI.existingWallHeight + +UI.upstandBraketType + this.geoBeam.height,
        0
      );
      let planeBottom = new Plane().setFromNormalAndCoplanarPoint(
        nBottom,
        pBottom
      );

      let beamOffsetX = 0;
      let nVer = new Vector3(1, 0, 0).normalize();
      let pVer = new Vector3(
        this.geoBeam.width / 2 + beamOffsetX + this.scene.position.x + fitZ,
        0,
        0
      );
      let planeVer = new Plane().setFromNormalAndCoplanarPoint(nVer, pVer);

      material.clippingPlanes = [planeBottom, planeVer];

      mesh = new Mesh(this.geo_RafterBeam.geometry, material);

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM,
      position: userDataPos,
      views: views,
    };
    mesh.position.set(offsetXL, 0, 0);
    mesh.scale.setX(scaleX);

    let checkExec = /\w{1}\d{1,3}x(\d{1,3})/.exec(this.geo_RafterBeam.name);
    let beamSize = checkExec && checkExec.length == 2 ? checkExec[1] : "";
    let lowerBraketName = getRafterBeamEndCapCode(EXISTING_BUILDING_CONFIG.RAFTER_BEAM_SIZE, false);
    let higherBraketName = getRafterBeamEndCapCode(EXISTING_BUILDING_CONFIG.RAFTER_BEAM_SIZE, true);

    let capL;
    let capR;
    if (userDataPos.left) {
      let matEndCap = MaterialManager.Instance().BEAM.clone();

      let heightReal =
        UI.existingWallHeight +
        +UI.upstandBraketType +
        this.geoBeam.height +
        (this.utils.tan(UI.patiosPitch) * UI.span) / 2;
      let heightPlane = heightReal * this.utils.cos(UI.patiosPitch);
      let translateZ = this.utils.sin(UI.patiosPitch) * heightPlane;

      let planeTop = new Plane(new Vector3(0, -1, 0));
      let matrixTranslateTop = new Matrix4().makeTranslation(
        -translateZ / 2,
        heightPlane,
        0
      );
      let matrixRotateTop = new Matrix4().makeRotationZ(
        this.utils.degreesToRadians(UI.patiosPitch)
      );
      let matrixTotalTop = matrixTranslateTop.multiply(matrixRotateTop);
      planeTop.applyMatrix4(matrixTotalTop);

      let heightRealBottom =
        UI.existingWallHeight +
        +UI.upstandBraketType +
        this.geoBeam.height -
        this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
        (this.utils.tan(UI.patiosPitch) * UI.span) / 2;
      let heightPlaneBottom = heightRealBottom * this.utils.cos(UI.patiosPitch);
      let translateZBottom = this.utils.sin(UI.patiosPitch) * heightPlaneBottom;

      let planeBottom = new Plane(new Vector3(0, 1, 0));
      let matrixTranslateBottom = new Matrix4().makeTranslation(
        -translateZBottom / 2,
        heightPlaneBottom,
        0
      );
      let matrixRotateBottom = new Matrix4().makeRotationZ(
        this.utils.degreesToRadians(UI.patiosPitch)
      );
      let matrixTotalBottom =
        matrixTranslateBottom.multiply(matrixRotateBottom);
      planeBottom.applyMatrix4(matrixTotalBottom);

      let beamOffsetX = 0;

      planeTop.translate(
        new Vector3(beamOffsetX + this.scene.position.x, 0, 0)
      );
      planeBottom.translate(
        new Vector3(beamOffsetX + this.scene.position.x, 0, 0)
      );

      matEndCap.clippingPlanes = [planeTop, planeBottom];

      capL = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capL.position.set(offsetXL - 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capL.scale.setY(
        (this.geo_RafterBeam.height / this.utils.sin(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length / this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capL.rotateY(Math.PI / 2);
      capL.rotateX(-Math.PI / 2 + this.utils.degreesToRadians(UI.patiosPitch));
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: lowerBraketName,
      };

      capR = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capR.position.set(offsetXR + 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capR.scale.setY(
        (this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length * this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capR.rotateY(-Math.PI / 2);
      capR.rotateX(-this.utils.degreesToRadians(UI.patiosPitch));
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: higherBraketName,
      };
    } else {
      let matEndCap = MaterialManager.Instance().BEAM.clone();

      let heightReal =
        UI.existingWallHeight +
        +UI.upstandBraketType +
        this.geoBeam.height +
        (this.utils.tan(UI.patiosPitch) * UI.span) / 2;
      let heightPlane = heightReal * this.utils.cos(UI.patiosPitch);
      let translateZ = this.utils.sin(UI.patiosPitch) * heightPlane;

      let planeTop = new Plane(new Vector3(0, -1, 0));
      let matrixTranslateTop = new Matrix4().makeTranslation(
        translateZ / 2,
        heightPlane,
        0
      );
      let matrixRotateTop = new Matrix4().makeRotationZ(
        -this.utils.degreesToRadians(UI.patiosPitch)
      ); //tao voi z pitch do
      let matrixTotalTop = matrixTranslateTop.multiply(matrixRotateTop);
      planeTop.applyMatrix4(matrixTotalTop);

      let heightRealBottom =
        UI.existingWallHeight +
        +UI.upstandBraketType +
        this.geoBeam.height -
        this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
        (this.utils.tan(UI.patiosPitch) * UI.span) / 2;
      let heightPlaneBottom = heightRealBottom * this.utils.cos(UI.patiosPitch);
      let translateZBottom = this.utils.sin(UI.patiosPitch) * heightPlaneBottom;

      let planeBottom = new Plane(new Vector3(0, 1, 0));
      let matrixTranslateBottom = new Matrix4().makeTranslation(
        translateZBottom / 2,
        heightPlaneBottom,
        0
      );
      let matrixRotateBottom = new Matrix4().makeRotationZ(
        -this.utils.degreesToRadians(UI.patiosPitch)
      ); //tao voi z pitch do
      let matrixTotalBottom =
        matrixTranslateBottom.multiply(matrixRotateBottom);
      planeBottom.applyMatrix4(matrixTotalBottom);

      let beamOffsetX = 0;

      planeTop.translate(
        new Vector3(beamOffsetX + this.scene.position.x, 0, 0)
      );
      planeBottom.translate(
        new Vector3(beamOffsetX + this.scene.position.x, 0, 0)
      );

      matEndCap.clippingPlanes = [planeTop, planeBottom];

      capL = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capL.position.set(offsetXL - 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capL.scale.setY(
        (this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length * this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capL.rotateY(Math.PI / 2);
      capL.rotateX(-this.utils.degreesToRadians(UI.patiosPitch));
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: higherBraketName,
      };

      capR = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capR.position.set(offsetXR + 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capR.scale.setY(
        (this.geo_RafterBeam.height / this.utils.sin(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length / this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capR.rotateY(-Math.PI / 2);
      capR.rotateX(-Math.PI / 2 + this.utils.degreesToRadians(UI.patiosPitch));
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: lowerBraketName,
      };
    }

    let offsetX = 0;
    if (userDataPos.left) {
      offsetX = -UI.span / 2;
      offsetY =
        UI.existingWallHeight + +UI.upstandBraketType + this.geoBeam.height;
    } else if (userDataPos.right) {
      offsetX = this.geo_centerSuperiorPost.width / 2;
      offsetY =
        UI.existingWallHeight +
        +UI.upstandBraketType +
        this.geoBeam.height +
        (UI.span / 2 - this.geoBeam.width / 2) * this.utils.tan(UI.patiosPitch);
    }

    let beamGroup = new Group();
    beamGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM,
      position: userDataPos,
      views: views,
    };
    beamGroup.position.set(offsetX, offsetY, offsetZ);
    if (this.APP.sltBeamType.currentValue == 0) {
      beamGroup.add(mesh, capL, capR);
    } else {
      beamGroup.add(mesh);
    }

    if (userDataPos.left) {
      beamGroup.rotateZ(this.utils.degreesToRadians(UI.patiosPitch));
    } else if (userDataPos.right) {
      beamGroup.rotateZ(-this.utils.degreesToRadians(UI.patiosPitch));
    }

    this.scene.add(beamGroup);
  }
  public addCenterSuperiorBeam() {
    let beamLength = this.totalBaySize + UI.overhangBack + UI.overhangFront;

    let views: Print2DView[];

    let offsetY =
      UI.existingWallHeight +
      +UI.upstandBraketType +
      (UI.span / 2 - this.geoBeam.width / 2) * this.utils.tan(UI.patiosPitch);
    let offsetZ =
      this.totalBaySize +
      UI.overhangFront -
      UI.existingWidth1 / 2 +
      UI.eaveWidth +
      this.geoBraket_2.height / 4;

    views = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    this.cutStandardBeamByCutBeamWithinBayControl(
      this.beamInfo,
      this.geo_beamEndCap,
      beamLength,
      new Vector3(0, offsetY + this.beamInfo.height, offsetZ),
      new Vector3(0, Math.PI / 2, 0),
      views,
      1,
      { center: true },
      0,
      'x',
      "RB",
      this.geoBeamJoint
    )
  }
  private getHeightByRoofPitch(isSpan: boolean): number {
    if (isSpan) {
      if (UI.isUpFasciaUpstandardBracket) {
        return (
          -this.utils.tan(this.APP.sltRoofPitch.currentValue) *
          this.APP.sldSpan.currentValue
        );
      } else {
        return (
          this.utils.tan(this.APP.sltRoofPitch.currentValue) *
          this.APP.sldSpan.currentValue
        );
      }
    } else {
      if (UI.isUpFasciaUpstandardBracket) {
        return (
          -this.utils.tan(this.APP.sltRoofPitch.currentValue) *
          (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue)
        );
      } else {
        return (
          this.utils.tan(this.APP.sltRoofPitch.currentValue) *
          (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue)
        );
      }
    }
  }
}
