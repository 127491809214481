import { HomeComponent as AppComponent } from '../../containers/home/home.component';
import { ColumnAndPurlinManager } from './column-and-purlin.manager';
import { RoofManager } from './roof.manager';
import { PatiosManager, RafterBeamAndInternalBeamInfo } from '../patios.manager';
import { Printing2DGeometry } from 'src/app/core/models';
import { Group, Box3 } from 'three';
import { GEOMETRY_CATEGORY } from 'src/app/app.config';
import { DimensionManager } from './dimension.manager';

export class PatiosGableFreeStandingManager implements PatiosManager{
    patiosGroup: Group;
    public boundingBox: Box3;
    public columnAndPurlinManager: ColumnAndPurlinManager;
    public roofManager: RoofManager;
    public dimesionManager: DimensionManager;
    public cutoutCondition: boolean;
    public get rakecutCondition(): boolean{
        return true;
    }
    public ignoreBayEvent: boolean = false;
    public patiosWidth: number;
    public patiosLength: number;
    public firstLoad = true;
    private controlsToRegisterEvent: Array<any>;
    private APP: AppComponent;
    private updateEventHandle: any;

    constructor(app: AppComponent){
        this.APP = app;
        this.patiosGroup = new Group();
        this.patiosGroup.userData = { category: GEOMETRY_CATEGORY.PATIOS }
        app.scene.add(this.patiosGroup);

        this.roofManager = new RoofManager(app, this);
        this.columnAndPurlinManager = new ColumnAndPurlinManager(app, this);
        this.dimesionManager = new DimensionManager(app, this);

        this.registerEvents();
    }

    load(): Promise<any> {
        return Promise.all([
            this.roofManager.optimize(),
            this.columnAndPurlinManager.optimize(),
            this.dimesionManager.optimize()
        ])
        .then(() => {
            this.roofManager.load();
            this.columnAndPurlinManager.load();
            this.dimesionManager.load();
        });
    }
    loadBracket(bracketQuantity: number): void {
        
    }
    loadBracketCutout(bracketQuantity: number): void{
        
    }
    loadSideGableBracket(bracketQuantity: number): void{
        
    }
    enableCutout(enable: boolean){
        
    }
    changeToExistingTypeBoth(){
        
    }
    loadRafterBeamAndInternalBeam(info: RafterBeamAndInternalBeamInfo): void{
        
    }
    getOutlines(): Printing2DGeometry {
        let dimensionOutline = this.dimesionManager.getOutlines();
        return { 
            lines: [
                ...this.columnAndPurlinManager.getSection().lines,
                ...this.roofManager.getOutLines().lines,
                ...dimensionOutline.lines
            ], 
            texts: dimensionOutline.texts
        }
    }
    public destroy(): void {
        this.columnAndPurlinManager.destroy();
        this.roofManager.destroy();
        this.dimesionManager.destroy();
        this.unRegisterEvent();
    }
    registerEvents(): void {
        this.updateEventHandle = this.update.bind(this);
        this.controlsToRegisterEvent = [
            this.APP.sldSpan,
            this.APP.sldBuildingHeight,
            this.APP.sltExistingType,
            this.APP.dialogEditBay,
            this.APP.sldLeftOverhang,
            this.APP.sldRightOverhang,
            this.APP.sltRoofPitch,
            this.APP.sldMultiSpan,
            this.APP.sldFlyOverBracketHeight,
            this.APP.sldBackOverhang,
            this.APP.sldFrontOverhang,
            this.APP.sltRoofThickness, 
            this.APP.sltGutterType,
            this.APP.sldExistingWidth1,
            this.APP.sldExistingWidth2,
            this.APP.sldExistingLength,
            this.APP.sldExistingLength2,
            this.APP.sldExistingWallHeight,
            this.APP.sltPanelDirection,
            this.APP.sltPanelDirectionShow,
            this.APP.sltBeamLayoutShow,
            this.APP.sltColourBeam,
            this.APP.btnTurnOnPanel
        ];
        this.controlsToRegisterEvent.forEach(c => { c.addAction(this.updateEventHandle); });
    }
    unRegisterEvent(): void {
        this.controlsToRegisterEvent.forEach(c => c.removeAction(this.updateEventHandle));
    }
    update(preVal: number, curVal: number): void {
        this.patiosGroup.position.setX(0);
        this.patiosGroup.position.setZ(- (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2);
        Promise.all([
            this.roofManager.load(),
            this.columnAndPurlinManager.load()
        ]).then(() => {
            // this.boundingBox = this.APP.utils.getObjectsBoundingBox(
            //     this.patiosGroup.children.filter(c => c.userData.type != GEOMETRY_TYPE.DIMENSION && c.userData.type != GEOMETRY_TYPE.ROOF_PATIOS)
            // );
            //this.boundingBox = this.getPatiosBox();
            this.dimesionManager.draw();
        })
    }
}
