import { HomeComponent as AppComponent } from '../../containers/home/home.component';
import { Scene, Material, Mesh, BufferGeometry, Box3, Group, LineSegments, LineBasicMaterial, Plane, Vector3, BoxHelper, Geometry, BoxBufferGeometry, Object3D } from 'three';
import { Util } from '../utils';
import { GeometryManager } from '../geometry.manager';
import { MaterialManager } from '../material.manager';
import { CONFIG as env, GEOMETRY_TYPE, GEOMETRY_CATEGORY } from '../../app.config';
import { GeometryInfo, Printing2DGeometry, Printing2DLine, Printing2DGeometryType, Print2DView, ViewType, LineType } from '../models';
import { PatiosFreeStandingManager } from '.';
import { UI } from '../ui';
import _ from "lodash"

export class ColumnAndPurlinManager {
    private scene: Group;
    private APP: AppComponent;
    private material: Material;
    //private beamMaterial: Material;
    private utils: Util;

    private geometryManager: GeometryManager;

    private geo_superiorPost: GeometryInfo;
    public geo_superiorBeam: GeometryInfo;
    private geo_beamEndCap: GeometryInfo;
    private geo_bracket: GeometryInfo;
    private geo_groundBase: GeometryInfo;
    private geoBeamJoint: GeometryInfo;
    private geoHouseBeamJoint: GeometryInfo;

    private eventHandleId: any;
    private totalBaySize: number;    
    private frontPostHeight: number;

    private geo_downPipe: GeometryInfo;
    private geo_downPipeL: GeometryInfo;
    private downpipeGroup: Group;
    private objectSizeChangedHandle: any;
    private controlsToRegisterEvent: Array<any>;
    private controlsToRegisterEvent2: Array<any>;

    constructor(app: AppComponent, manager: PatiosFreeStandingManager){
        this.APP = app;
        this.utils = new Util();
        this.geometryManager = GeometryManager.Instance();

        this.scene = manager.patiosGroup;
        this.material = MaterialManager.Instance().DEFAULT.clone();
        //this.beamMaterial = MaterialManager.Instance().DEFAULT.clone();
        

        this.registerEvent();
    }

    public optimize() : Promise<void>{
        return new Promise((resolve, reject) => {
            let bracketHeight = this.geometryManager.FLY_OVER_BRACKET.S65x3.height/2;
            let bracketWidth = this.geometryManager.FLY_OVER_BRACKET.S65x3.width;
           
            this.geo_bracket = new GeometryInfo();
            this.geo_bracket.geometry = this.geometryManager.FLY_OVER_BRACKET.S65x3.geometry
            .clone()
            .rotateX(Math.PI/2)
            .translate(bracketWidth/2,bracketHeight,bracketWidth/2);
            this.geo_bracket.width = this.geometryManager.FLY_OVER_BRACKET.S65x3.width;
            this.geo_bracket.length = this.geometryManager.FLY_OVER_BRACKET.S65x3.length;
            this.geo_bracket.height = this.geometryManager.FLY_OVER_BRACKET.S65x3.height;

            this.geo_superiorBeam = this.geometryManager.getBeam();
            this.geo_superiorBeam.geometry
            .rotateY(Math.PI/2)
            .translate(this.geo_superiorBeam.length/2,this.geo_superiorBeam.height/2, this.geo_superiorBeam.width/2);

            this.geoBeamJoint = this.geometryManager.getBeamJoint()
            this.geoHouseBeamJoint = this.geometryManager.getHouseBeamJoint()

            this.geo_beamEndCap = this.geometryManager.getBeamEndCap();
            this.geo_beamEndCap.geometry.translate(0, this.geo_beamEndCap.height / 2, 0);
            
            this.geo_superiorPost = this.geometryManager.getPost();
            this.geo_superiorPost.geometry
            .rotateX(Math.PI/2)
            .translate(this.geo_superiorPost.width/2, this.geo_superiorPost.height/2, this.geo_superiorPost.width/2);
            

            this.geo_groundBase = new GeometryInfo();
            this.geo_groundBase.width = 1000;
            this.geo_groundBase.height = 1;
            this.geo_groundBase.length = 1000;
            this.geo_groundBase.geometry = new BoxBufferGeometry(this.geo_groundBase.width, this.geo_groundBase.height, this.geo_groundBase.length);
            this.geo_groundBase.geometry.translate(0, 0, this.geo_groundBase.length / 2);

            this.geo_downPipe = this.geometryManager.getDownPipe();
            this.geo_downPipeL = this.geometryManager.getDownPipeL();

            this.scene.remove(...this.scene.children.filter(c => c.userData.type == GEOMETRY_TYPE.DOWNPIPE));
            this.downpipeGroup = new Group();
            this.downpipeGroup.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.DOWNPIPE };
            this.scene.add(this.downpipeGroup);

            resolve();
        });
    }

    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.SUPERIOR_POST));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.MESH_OUTLINE));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == "COLUMN_OUTLINE"));
            this.scene.remove(...this.scene.children.filter(o => o.userData.type == GEOMETRY_TYPE.GROUND_BASE));
            this.downpipeGroup.children = [];

            // this.outlineGroup = new Group();
            // this.outlineGroup.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.MESH_OUTLINE };
            // this.scene.add(this.outlineGroup);            

            this.totalBaySize = 0;
            for(let b of this.APP.dialogEditBay.listBay){
                this.totalBaySize += b.value;
            }

            this.addSuperiorBeam({front: true});
            this.addSuperiorBeam({ back: true });
            if (this.APP.sldMultiSpan.currentValue > 0) {
                this.addSuperiorBeam({ multiSpan: true });
            }
            this.addGround();

            let offsetX = -this.totalBaySize/2;
            let first = true;
            let last = false;
            for (let i = 0; i <= this.APP.dialogEditBay.listBay.length; i++){
                if (i == this.APP.dialogEditBay.listBay.length) {
                    last = true;
                }
                this.addPost(offsetX, { back: true, first, last});
                this.addPost(offsetX, { front: true, first, last});

                if(this.APP.sldMultiSpan.currentValue > 0){                    
                    this.addPost(offsetX, { multiSpan: true, first, last});
                }
                if(i < this.APP.dialogEditBay.listBay.length){
                    let b = this.APP.dialogEditBay.listBay[i];
                    offsetX += b.value;
                }
                first = false;
            }

            //this.getSection();
            this.showBeamOutline();
            this.addDownPipe();
            this.updateUI();
            resolve();
        });
    }
    public showBeamOutline() {
        this.APP.scene.remove(
          ...this.APP.scene.children.filter(
            (x) => x.userData.type == GEOMETRY_TYPE.BEAM_OUTLINE
          )
        );
    
        if (!UI.beamLayoutShow) return;
    
        const objs = this.scene.children.filter(
          (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
        );
        const meshes = []
        objs.forEach(el => {
          if(el.type == 'Group') {
            meshes.push(...el.children.filter((o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM))
          }
        })
    
        for (let o of meshes) {
          let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
            o as Mesh,
            10
          );
          o.updateWorldMatrix(true, true);
          outlineGeo.applyMatrix4(o.matrixWorld);
    
          var line = new LineSegments(
            outlineGeo,
            MaterialManager.Instance().BEAM_OUTLINE
          );
          line.userData = { type: GEOMETRY_TYPE.BEAM_OUTLINE };
          this.APP.scene.add(line);
        }
      }
    private updateUI() {
        this.APP.sldMinHeight.setValue(this.frontPostHeight);
    }
    private addDownPipe() {
        let offsetZ = this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + 100;
        let offsetX = -this.totalBaySize/2 + 50;

        this.downpipeGroup.position.set(offsetX, 0, offsetZ);
        let offsetY = this.utils.getHeightByAngle(this.APP.sldBuildingHeight.currentValue, 
            this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + this.APP.sldFrontOverhang.currentValue, this.APP.sltRoofPitch.currentValue, -1)
            + this.geometryManager.getRoofBase().height;

        if(this.APP.sltGutterType.currentValue == 0){
            offsetY -= 80;
        }
        else{
            offsetY -= 60;
        }

        let userData = { type: GEOMETRY_TYPE.DOWNPIPE };

        if(this.APP.sldFrontOverhang.currentValue > 100){
            offsetY -= 250;

            let meshLBottom = new Mesh(this.geo_downPipeL.geometry, MaterialManager.Instance().DOWNPIPE);
            meshLBottom.userData = userData;
            meshLBottom.position.set(0, offsetY, 20);
            meshLBottom.rotateY(Math.PI/2);

            let meshLTop = new Mesh(this.geo_downPipeL.geometry, MaterialManager.Instance().DOWNPIPE);
            meshLTop.userData = userData;
            meshLTop.rotation.set(Math.PI, Math.PI/2, 0);
            meshLTop.position.set(0, offsetY + 230, this.APP.sldFrontOverhang.currentValue - 100);

            let meshPipeConnect = new Mesh(this.geo_downPipe.geometry, MaterialManager.Instance().DOWNPIPE);
            meshPipeConnect.userData = userData;
            meshPipeConnect.rotation.set(Math.PI/2,0,0);
            meshPipeConnect.position.set(0, offsetY + 115, 50);
            meshPipeConnect.scale.set(1,(this.APP.sldFrontOverhang.currentValue - 140)/this.geo_downPipe.height,1);

            this.downpipeGroup.add(meshLBottom, meshLTop, meshPipeConnect);
        }

        let scaleY = offsetY/this.geo_downPipe.height;

        let meshPipe = new Mesh(this.geo_downPipe.geometry, MaterialManager.Instance().DOWNPIPE);
        meshPipe.userData = userData;
        meshPipe.scale.setY(scaleY);

        this.downpipeGroup.add(meshPipe);
    }
    private addGround() {
        let offsetZ = - this.APP.sldBackOverhang.currentValue;
        let width = this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue;
        let length = this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + this.APP.sldFrontOverhang.currentValue + this.APP.sldBackOverhang.currentValue;
        let offsetX = (this.APP.sldRightOverhang.currentValue - this.APP.sldLeftOverhang.currentValue)/2;

        let base = new Mesh(this.geo_groundBase.geometry, MaterialManager.Instance().BASE);
        base.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.GROUND_BASE };
        base.position.set(offsetX, 0, offsetZ);
        base.scale.set((width) / this.geo_groundBase.width, 1, (length) / this.geo_groundBase.length);

        this.scene.add(base);
    }
    private addPost(offsetX: number, userDataPos: any): void{
        let meshPost = new Mesh(this.geo_superiorPost.geometry, MaterialManager.Instance().POST);
        meshPost.userData = {...userDataPos, type: GEOMETRY_TYPE.SUPERIOR_POST, category: GEOMETRY_CATEGORY.PATIOS}

        
        let postOffsetZ = 0;


        let scalePostY = 1;

        let views: Print2DView[];
        
        if(userDataPos.front){
            postOffsetZ = this.APP.sldSpan.currentValue ;
            this.frontPostHeight = this.utils.getHeightByAngle(this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height, 
                this.APP.sldSpan.currentValue, 
                this.APP.sltRoofPitch.currentValue, -1);

            scalePostY = this.frontPostHeight/ this.geo_superiorPost.height;

            if (this.APP.sldMultiSpan.currentValue > 0) {
                postOffsetZ -= this.geo_superiorPost.width / 2;
            }
            else {
                postOffsetZ -= this.geo_superiorPost.width;
            }

            views = [ 
                { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.PLAN, lineType: LineType.DASHED }
            ];
        }
        if(userDataPos.back){
            postOffsetZ = 0;
            scalePostY = (this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height )/this.geo_superiorPost.height;
            views = [ 
                { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.PLAN, lineType: LineType.DASHED }
            ];
        }
        if(userDataPos.multiSpan){
            postOffsetZ = this.APP.sldMultiSpan.currentValue + this.APP.sldSpan.currentValue - this.geo_superiorPost.width;
            scalePostY = this.utils.getHeightByAngle(this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height,
                this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue,
                this.APP.sltRoofPitch.currentValue, -1)/this.geo_superiorPost.height;

            views = [ 
                { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.PLAN, lineType: LineType.DASHED }
            ];
        }

        meshPost.userData.views = views;

        let postOffsetX = offsetX;
        if (userDataPos.first) {

        }
        else if (userDataPos.last) {
            postOffsetX -= this.geo_superiorPost.width;
        }
        else {
            postOffsetX -= this.geo_superiorPost.width / 2;
        }
        
        meshPost.position.set(postOffsetX, 0, postOffsetZ);
        meshPost.scale.setY(scalePostY);

        this.scene.add(meshPost);
    }

    public addSuperiorBeam(userDataPos: any){
        let offsetXL = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
        let offsetZ = 0;
        let offsetY = 0;

        let beamLength =
        this.totalBaySize +
          this.APP.sldLeftOverhang.currentValue +
          this.APP.sldRightOverhang.currentValue

        let views: Print2DView[];
        
        if(userDataPos.front){
            offsetY = this.utils.getHeightByAngle(this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height,
                this.APP.sldSpan.currentValue,
                this.APP.sltRoofPitch.currentValue, -1);

            //this.APP.sldBuildingHeight.currentValue + this.getBracketHeight(this.APP.sldSpan.currentValue);
            offsetZ = this.APP.sldSpan.currentValue;
            if (this.APP.sldMultiSpan.currentValue > 0) {
                offsetZ -= this.geo_superiorBeam.width / 2;
            }
            else {
                offsetZ -= this.geo_superiorBeam.width;
            }

            views = [ 
                { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.PLAN, lineType: LineType.DASHED }
            ];
        }
        else if(userDataPos.back){
            offsetY = this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height;
            offsetZ = 0;

            views = [ 
                { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.PLAN, lineType: LineType.DASHED }
            ];
        }
        else if(userDataPos.multiSpan){
            offsetY = this.utils.getHeightByAngle(this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height,
                this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue,
                this.APP.sltRoofPitch.currentValue, -1);
            offsetZ = this.APP.sldMultiSpan.currentValue + this.APP.sldSpan.currentValue - this.geo_superiorBeam.width;

            views = [ 
                { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.PLAN, lineType: LineType.DASHED }
            ];
        }

        this.cutStandardBeamByCutBeamWithinBayControl(
            this.geo_superiorBeam,
            this.geo_beamEndCap,
            beamLength,
            new Vector3(offsetXL, offsetY, offsetZ),
            new Vector3(),
            views,
            1,
            userDataPos,
            this.geoBeamJoint
        )
    }
    private cutStandardBeamByCutBeamWithinBayControl(
        beamGeo: GeometryInfo,
        beamCapGeo: GeometryInfo,
        length: number,
        pos: Vector3,
        rot: Vector3,
        views: any,
        directionOffset: number,
        userDataPos: any,
        jointGeo: GeometryInfo,
      ){
        // Map list cut beam here
        const beamStartX = pos.x
        const beamEndX = pos.x + length
    
        let endOfBayX = - UI.totalBayLength / 2
        let startCutBeamX = beamStartX
        let endCutBeamX = beamStartX
    
        const beams = this.utils.getListSeperateBeamsByBays(endOfBayX, beamStartX, beamEndX, endCutBeamX, startCutBeamX)

        for(let i = 0; i < beams.length; i++) {
            endCutBeamX = beams[i][1]
            startCutBeamX = beams[i][0]
            let beamGroup = this.utils.createBeamGroup(
                beamGeo,
                beamCapGeo,
                endCutBeamX - startCutBeamX,
                new Vector3(startCutBeamX, pos.y, pos.z),
                rot,
                views,
                directionOffset,
                userDataPos,
                UI.beamLayoutShow,
                {
                    hasStartCap: UI.beamType == 0 && (i == 0),
                    hasEndCap: UI.beamType == 0 && (i == beams.length - 1 || (i == 0 && beams.length == 1)),
                    hasStartJoint: i !== 0,
                    hasEndJoint: false,
                    jointGeo: jointGeo,
                  }
            )
            this.scene.add(beamGroup);
        }
    }
    public getSection(): Printing2DGeometry{
        let objs = this.scene.children.filter(o =>            
            o.userData.type == GEOMETRY_TYPE.SUPERIOR_POST
            || o.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET
            );
      let beamGroups = this.scene.children.filter(o => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM);
      let beamObjs: Object3D[] = [];
      for (let g of beamGroups) {
        beamObjs = beamObjs.concat(g.children);
      }

      objs = [...objs, ...beamObjs];
        let lsGeometries: Printing2DLine[] = [];

        for(let o of objs){
            let box = new BoxHelper((o));
            box.geometry.translate(0, 5000, 0);

            let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
            const planView = _.find(o.userData.views, (el) => el.viewType == ViewType.PLAN)
            const anotherViews = _.filter(o.userData.views, (el) => el.viewType != ViewType.PLAN)
            if(anotherViews.length > 0) {
                lsGeometries.push({
                    objectType: o.userData.type,
                    vertices: outlineGeo.vertices,
                    views: anotherViews,
                });
            } 
            if(planView) {
                lsGeometries.push({
                    objectType: o.userData.type,
                    vertices: outlineGeo.vertices.slice(0, 8).filter((el, index) => [0, 1, 4, 5].includes(index)),
                    views: [planView],
                });
            }
        }

        return { lines: lsGeometries, texts: [] };
    }
    public simplifyGeo(geo: BufferGeometry): Geometry{
        let vertices = geo.getAttribute('position').array;
        let lineGeo = new Geometry();
        for(let i = 0; i < vertices.length; i+=3){
            lineGeo.vertices.push(new Vector3(vertices[i], vertices[i+1]-5000, vertices[i+2]));
        }

        //2-3
        lineGeo.vertices.push(new Vector3(vertices[3], vertices[4]-5000, vertices[5]));
        lineGeo.vertices.push(new Vector3(vertices[6], vertices[7]-5000, vertices[8]));
        //3-7
        lineGeo.vertices.push(new Vector3(vertices[6], vertices[7]-5000, vertices[8]));
        lineGeo.vertices.push(new Vector3(vertices[18], vertices[19]-5000, vertices[20]));
        //7-6
        lineGeo.vertices.push(new Vector3(vertices[18], vertices[19]-5000, vertices[20]));
        lineGeo.vertices.push(new Vector3(vertices[15], vertices[16]-5000, vertices[17]));
        //6-2
        lineGeo.vertices.push(new Vector3(vertices[15], vertices[16]-5000, vertices[17]));
        lineGeo.vertices.push(new Vector3(vertices[3], vertices[4]-5000, vertices[5]));

        //1-4
        lineGeo.vertices.push(new Vector3(vertices[0], vertices[1]-5000, vertices[2]));
        lineGeo.vertices.push(new Vector3(vertices[9], vertices[10]-5000, vertices[11]));
        //4-8
        lineGeo.vertices.push(new Vector3(vertices[9], vertices[10]-5000, vertices[11]));
        lineGeo.vertices.push(new Vector3(vertices[21], vertices[22]-5000, vertices[23]));
        //8-5
        lineGeo.vertices.push(new Vector3(vertices[21], vertices[22]-5000, vertices[23]));
        lineGeo.vertices.push(new Vector3(vertices[12], vertices[13]-5000, vertices[14]));
        //5-1
        lineGeo.vertices.push(new Vector3(vertices[12], vertices[13]-5000, vertices[14]));
        lineGeo.vertices.push(new Vector3(vertices[0], vertices[1]-5000, vertices[2]));
        return lineGeo;
    }

    private getBracketHeight(offset): number{
        let angle = this.APP.sltRoofPitch.currentValue;
        return (this.APP.sldFlyOverBracketHeight.currentValue) - this.utils.tan(angle) * offset;
    }

    public destroy(): void {
        this.unregisterEvent();        
    }
    private registerEvent(): void {
        this.eventHandleId = this.uiChanged.bind(this);
        this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

        this.controlsToRegisterEvent = [
            this.APP.sldSpan,
            this.APP.sldBuildingHeight,
            this.APP.sltExistingType,
            this.APP.dialogEditBay,
            this.APP.sldLeftOverhang,
            this.APP.sldRightOverhang,
            this.APP.sltRoofPitch,
            this.APP.sldMultiSpan,
            this.APP.sldFlyOverBracketHeight,
            this.APP.sldBackOverhang,
            this.APP.sldFrontOverhang,
            this.APP.sltRoofThickness, 
            this.APP.sltGutterType
        ];
        //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));

        this.controlsToRegisterEvent2 = [
            this.APP.sltBeamType,
            this.APP.sltBeamSize,
            this.APP.sltColumnType
        ];
        this.controlsToRegisterEvent2.forEach(c => c.addAction(this.objectSizeChangedHandle));
    }
    private unregisterEvent(): void{
        // this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
        // this.controlsToRegisterEvent = undefined;

        this.controlsToRegisterEvent2.forEach(c => c.removeAction(this.objectSizeChangedHandle));
        this.controlsToRegisterEvent2 = undefined;
    }
    public uiChanged(preVal: number, curVal: number): void {
        this.load();
    }  
    private objectSizeChanged(pre: number, cur: number){
        this.optimize().then(() => { this.load() });
    }
}
