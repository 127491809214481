import { Injectable } from "@angular/core";
import { Object3D, Raycaster, Vector2 } from "three";
import { DragControls } from "src/app/libs/DragControls";
import { HomeComponent } from "src/app-ribspan/containers/home/home.component";
import { MOUSE_EVENT, OBJECT_TYPE } from "src/app/app.constants";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { DIMENSION_LABEL_TYPE, GEOMETRY_TYPE } from "src/app/app.config";
import { UI } from "./ui";

@Injectable({
    providedIn: 'root'
})
export default class SelectionManager {
    static ins: SelectionManager

    mouse: Vector2;

    onMouseClickPtr = this.onMouseClick.bind(this)
    onMouseDownPtr = this.onMouseDown.bind(this)
    onMouseMovePtr = this.onMouseMove.bind(this)
    onMouseUpPtr = this.onMouseUp.bind(this)

    onDragStartPtr = this.onDragStart.bind(this)
    onDragEndPtr = this.onDragEnd.bind(this)
    onDragPtr = this.onDrag.bind(this)

    private raycaster: Raycaster;
    selectEnabled = true;
    debounceMouseMoveHld = undefined;

    dragControl: DragControls
    transformControl: TransformControls

    dragObjects: Object3D[] = []

    clickEventHandlers = []
    mouseHoverEventHandlers = []
    mouseMoveEventHandlers = []
    mouseDownEventHandlers = []
    mouseUpEventHandlers = []

    dragStartEventHandlers = []
    dragEndEventHandlers = []
    dragEventHandlers = []

    init() {
        SelectionManager.ins = this
        return new Promise<void>(resolve => {
            this.dragControl = new DragControls(this.dragObjects, HomeComponent.ins.camera, HomeComponent.ins.renderer.domElement);
            this.transformControl = new TransformControls(HomeComponent.ins.camera, HomeComponent.ins.renderer.domElement);
            this.transformControl.addEventListener('change', () => { HomeComponent.ins.renderStatic() });
            this.transformControl.addEventListener('dragging-changed', function (event) {
                HomeComponent.ins.control.enabled = !event.value;
            });
            HomeComponent.ins.scene.add(this.transformControl)
            this.raycaster = this.dragControl.raycaster
            this.dragControl.addEventListener('dragstart', (event) => {
                HomeComponent.ins.control.enabled = false;
                this.onDragStart(event)
            });
            this.dragControl.addEventListener('drag', (event) => {
                this.onDrag(event)
            })
            this.dragControl.addEventListener('dragend', (event) => {
                HomeComponent.ins.control.enabled = true;
                this.onDragEnd(event)
            });
            console.log('drag control', this.dragControl.raycaster)
            resolve()
        })
    }

    enable() {
        this.mouse = new Vector2(0, 0);
        //this.raycaster = new Raycaster();

        HomeComponent.ins.canvas.addEventListener(MOUSE_EVENT.DOWN, this.onMouseDownPtr, false);
        HomeComponent.ins.canvas.addEventListener(MOUSE_EVENT.MOVE, this.onMouseMovePtr, false);
        HomeComponent.ins.canvas.addEventListener(MOUSE_EVENT.UP, this.onMouseUpPtr, false);
        HomeComponent.ins.canvas.addEventListener(MOUSE_EVENT.CLICK, this.onMouseClickPtr, false);


    }
    disable() {
        this.dragControl.deactivate()
        HomeComponent.ins.canvas.removeEventListener(MOUSE_EVENT.DOWN, this.onMouseDownPtr, false);
        HomeComponent.ins.canvas.removeEventListener(MOUSE_EVENT.MOVE, this.onMouseMovePtr, false);
        HomeComponent.ins.canvas.removeEventListener(MOUSE_EVENT.UP, this.onMouseUpPtr, false);
        HomeComponent.ins.canvas.removeEventListener(MOUSE_EVENT.CLICK, this.onMouseClickPtr, false);
    }
    enableTransformObject(intersects) {
        const obj = intersects.reduce((prev, cur) => {
            if (cur.distance < prev.distance
                && cur.object.name !== 'ground'
                && (!cur.object.userData || cur.object.userData.type !== 'PANEL_BACK')
                && (!cur.object.userData || cur.object.userData.type !== 'PANEL_FRONT')
                && (!cur.object.userData.wallPlane)
            ) {
                prev = cur
            }
            return prev
        }, { distance: 999999, object: null })

        if (!obj.object) {
            return;
        }

        this.transformControl.attach(obj.object)
    }
    executeRayCast() {
        this.raycaster.setFromCamera(this.mouse, HomeComponent.ins.camera);
        const objects = []
        HomeComponent.ins.scene.traverse((o) => {
            if(o.userData?.type === OBJECT_TYPE.HIGHLIGHT_BOX 
                || o.userData?.type === GEOMETRY_TYPE.TEXT_COVER
                || o.userData?.type === OBJECT_TYPE.UI_CONTROL_EDIT_WALL_LENGTH2
                || o.userData?.type === OBJECT_TYPE.UI_CONTROL_EDIT_WALL_WIDTH1
                || o.userData?.type === OBJECT_TYPE.UI_CONTROL_EDIT_WALL_WIDTH2
                || o.userData?.type === OBJECT_TYPE.CLICK_CONTROL_CUTOUT
                || o.userData?.type === OBJECT_TYPE.CUT_BEAM
                ){
            //if(o.type === 'Mesh'){
                objects.push(o)
            }
        })
        return this.raycaster.intersectObjects(objects, true);
    }
    executeRayCastScene(){
        this.raycaster.setFromCamera(this.mouse, HomeComponent.ins.camera);
        return this.raycaster.intersectObjects(HomeComponent.ins.scene.children, true);
    }
    onMouseDown(event) {
        //this.mouseDownEventHandlers.forEach(a => { a(event) })
    }
    onMouseMove(event) {
        event.preventDefault();
        const offsetX = HomeComponent.ins.isVisibleProperty ? 400 : 0;
        this.mouse.x =
            ((event.clientX - offsetX) / HomeComponent.ins.canvas.width) * 2 - 1;
        this.mouse.y =
            -((event.clientY - 90) / HomeComponent.ins.canvas.height) * 2 + 1;

        // if(this.debounceMouseMoveHld){
        //     clearTimeout(this.debounceMouseMoveHld)
        // }

        const intersects = this.executeRayCast()
        // if(intersects.length > 0){
        //     this.mouseHoverEventHandlers.forEach(a => { a(intersects) })
        // }

        this.mouseHoverEventHandlers.forEach(a => { a(intersects) })

        // //temporary treat drag = ctrl + move
        // //if(event.ctrlKey){
        //     this.mouseMoveEventHandlers.forEach(a => { a(event) })
        // //}
    }
    onMouseUp(event) {
        //this.mouseUpEventHandlers.forEach(a => { a(event) })
    }
    onMouseClick(event) {
        const intersects = this.executeRayCast()

        if (intersects.length === 0) {
            return;
        }
        // intersects.forEach(el => console.log(el.object.userData))

        this.selectObject(intersects, event)
        // this.enableTransformObject(intersects)

        this.clickEventHandlers.forEach(a => { a(intersects) })
    }
    selectObject(intersects, event){
        if (!this.selectEnabled) {
            return
        }
        const obj = intersects.reduce((prev, cur) => {
            if (cur.distance < prev.distance
                && cur.object.type != 'TransformControlsPlane'
                && cur.object.type != 'HIGHLIGHT_BOX'
            ) {
                prev = cur
            }
            return prev
        }, { distance: 999999, object: null })

        if (!obj.object) {
            return;
        }
        if(!UI.enableControl) {
            return
        }
        if(obj.object.userData.type && obj.object.userData.type === GEOMETRY_TYPE.TEXT_COVER){
            const labelType = obj.object.userData.labelType
            if(!labelType){
                return
            }

            switch(labelType){
                case DIMENSION_LABEL_TYPE.LENGTH_1:
                case DIMENSION_LABEL_TYPE.LENGTH_2:
                case DIMENSION_LABEL_TYPE.WIDTH_1:
                case DIMENSION_LABEL_TYPE.WIDTH_2:
                case DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_HORIZONTAL:
                case DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_HORIZONTAL:
                case DIMENSION_LABEL_TYPE.FRONT_OVERHANG:
                case DIMENSION_LABEL_TYPE.BACK_OVERHANG:
                case DIMENSION_LABEL_TYPE.LEFT_OVERHANG:
                case DIMENSION_LABEL_TYPE.RIGHT_OVERHANG:
                case DIMENSION_LABEL_TYPE.SPAN:
                case DIMENSION_LABEL_TYPE.MULTI_SPAN:
                case DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_VERTICAL:
                case DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_VERTICAL:
                    HomeComponent.ins.dialogDimension.show(true, labelType)
                    break
                case DIMENSION_LABEL_TYPE.BAY:
                    HomeComponent.ins.dialogDimension.show(true, labelType, { bayIndex: obj.object.userData.bayNum })
                    break
            }
        }
        if(obj.object?.userData?.type === OBJECT_TYPE.UI_CONTROL_EDIT_WALL_WIDTH1){
            if(obj.object?.userData?.left){
                if(UI.existingType == 3){
                    HomeComponent.ins.sltExistingType.setSelected(2)
                } else {
                    HomeComponent.ins.sltExistingType.setSelected(0)
                }
            } else if (obj.object?.userData?.right){
                if(UI.existingType == 3){
                    HomeComponent.ins.sltExistingType.setSelected(1)
                } else {
                    HomeComponent.ins.sltExistingType.setSelected(0)
                }
            }
        } else if(obj.object?.userData?.type === OBJECT_TYPE.UI_CONTROL_EDIT_WALL_LENGTH2){
            HomeComponent.ins.sldExistingLength2.setValue(0);
        } else if(obj.object?.userData?.type === OBJECT_TYPE.UI_CONTROL_EDIT_WALL_WIDTH2){
            HomeComponent.ins.sldExistingWidth2.setValue(0);
        }
        if(obj.object?.userData?.type === OBJECT_TYPE.CLICK_CONTROL_CUTOUT){
            HomeComponent.ins.sltCutOut.setSelected(1);
        } else if(obj.object?.userData?.type === OBJECT_TYPE.CUT_BEAM){
            const bayIndex = obj.object?.userData.bayIndex
            UI.listBay[bayIndex].isCut = !!!UI.listBay[bayIndex].isCut
            HomeComponent.ins.dialogEditBay.onOK()
        }
    }
    onDragStart(event) {
        this.dragStartEventHandlers.forEach(a => { a(event) })
    }
    onDragEnd(event) {
        this.dragEndEventHandlers.forEach(a => { a(event) })
    }
    onDrag(event) {
        this.dragEventHandlers.forEach(a => { a(event) })
    }


    addEventListener(event, handler, dragObject?) {
        if (event === MOUSE_EVENT.CLICK) {
            if(this.clickEventHandlers.indexOf(handler) < 0){
                this.clickEventHandlers.push(handler)
            }
        }
        if (event === MOUSE_EVENT.HOVER) {
            if(this.mouseHoverEventHandlers.indexOf(handler) < 0){
                this.mouseHoverEventHandlers.push(handler)
            }
        }
        if (event === MOUSE_EVENT.DOWN) {
            if(this.mouseDownEventHandlers.indexOf(handler) < 0){
                this.mouseDownEventHandlers.push(handler)
            }
        }
        if (event === MOUSE_EVENT.MOVE) {
            if(this.mouseMoveEventHandlers.indexOf(handler) < 0){
                this.mouseMoveEventHandlers.push(handler)
            }
        }
        if (event === MOUSE_EVENT.UP) {
            if(this.mouseUpEventHandlers.indexOf(handler) < 0){
                this.mouseUpEventHandlers.push(handler)
            }
        }
        if (event == MOUSE_EVENT.DRAG_START) {
            if(this.dragStartEventHandlers.indexOf(handler) < 0){
                this.dragStartEventHandlers.push(handler)
            }
        }
        if (event == MOUSE_EVENT.DRAG_END) {
            if(this.dragEndEventHandlers.indexOf(handler) < 0){
                this.dragEndEventHandlers.push(handler)
            }
        }
        if (event == MOUSE_EVENT.DRAG) {
            if(this.dragEventHandlers.indexOf(handler) < 0){
                this.dragEventHandlers.push(handler)
            }
            
            this.dragObjects.push(dragObject)
        }
    }
    removeEventListener(event, handler) {
        if (event === MOUSE_EVENT.CLICK) {
            const idx = this.clickEventHandlers.indexOf(handler)
            if (idx > -1) {
                this.clickEventHandlers.splice(idx, 1)
            }

        }
        if (event === MOUSE_EVENT.HOVER) {
            const idx = this.mouseHoverEventHandlers.indexOf(handler)
            if (idx > -1) {
                this.mouseHoverEventHandlers.splice(idx, 1)
            }
        }
        if (event === MOUSE_EVENT.DOWN) {
            const idx = this.mouseDownEventHandlers.indexOf(handler)
            if (idx > -1) {
                this.mouseDownEventHandlers.splice(idx, 1)
            }
        }
        if (event === MOUSE_EVENT.MOVE) {
            const idx = this.mouseMoveEventHandlers.indexOf(handler)
            if (idx > -1) {
                this.mouseMoveEventHandlers.splice(idx, 1)
            }
        }
        if (event === MOUSE_EVENT.UP) {
            const idx = this.mouseUpEventHandlers.indexOf(handler)
            if (idx > -1) {
                this.mouseUpEventHandlers.splice(idx, 1)
            }
        }
    }
}