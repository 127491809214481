import {
  CONFIG,
  OFFSET_GUTTER_TO_WALL,
  PATIOS_ROOF_TYPE,
} from "src/app/app.config";
import { environment } from "src/environments/environment";
import { Scene } from "three";
import { GEOMETRY_CATEGORY, GEOMETRY_TYPE, INIT } from "../app.config";
import {
  BUILDING_SIDE,
  CONNECTION_TYPE,
  CUTOUT_ENABLE,
  EXISTING_BUILDING_CONFIG,
  FASCIA_BRACKET_FIT,
  RAKECUT_TYPE,
} from "../app.constants";
import { HomeComponent } from "../containers/home";
import { PatiosBackFlyOverManager } from "./back_flyover";
import { ExistingBuildingManager } from "./existing_building";
import { FasciaManager } from "./fascia/index";
import { PatiosFlyOverManager } from "./flyover";
import { PatiosFreeStandingManager } from "./free_standing";
import { PatiosGableFreeStandingManager } from "./gable_free_standing";
import { PatiosGableLRFlyOverManager } from "./gable_lr_flyover";
import { GeometryManager } from "./geometry.manager";
import { UI } from "./ui";
import { GableFBExistingBuildingManager } from "./gable_fb_existing_building";
import { PatiosGableFBFlyOverManager } from "./gable_fb_flyover";
import { GableLRFasciaManager } from "./gable_lr_fascia";
import { GableFBFasciaManager } from "./gable_fb_fascia";
import _ from "lodash";
import { Util } from "./utils";

declare var $;

export class UIManager {
  private scene: Scene;
  private APP: HomeComponent;
  private debouncingHandle;
  private debouncingTimeout = 500; //ms
  private ignoreEnableCutoutEvent = false;
  private utils: Util;
  public previousShouldSnapFasciaRoofToWall: Boolean = false
  public shouldSnapFasciaRoofToWall: Boolean = false

  // private totalBaySize: number;
  constructor(app: HomeComponent) {
    this.APP = app;
    this.scene = app.scene;
    this.utils = new Util();

    // Should register this event before handle any action
    this.APP.sldBackOverhang.addAction(this.onBackOverhangChanged.bind(this));

    this.APP.sltFasciaUpstandBrackets.addAction(
      this.onFasciaUpstandBracketChanged.bind(this)
    );

    this.APP.sldFlyOverBracketHeight.addAction(this.onSetColumnType.bind(this));
    this.APP.sldSpan.addAction(this.updateOverallValue.bind(this));
    this.APP.sldMultiSpan.addAction(this.updateOverallValue.bind(this));
    this.APP.sldLeftOverhang.addAction(this.updateOverallValue.bind(this));
    this.APP.sldRightOverhang.addAction(this.updateOverallValue.bind(this));
    this.APP.sldBackOverhang.addAction(this.updateOverallValue.bind(this));
    this.APP.sldFrontOverhang.addAction(this.updateOverallValue.bind(this));
    this.APP.dialogEditBay.addAction(this.updateOverallValue.bind(this));
    this.APP.dialogEditBay.addAction(this.onDialogEditBayChanged.bind(this));
    this.APP.sldSpan.addAction(this.onSpanChanged.bind(this));
    this.APP.sldBaySize.addAction(this.onBaySizeChanged.bind(this));

    this.APP.sltStructureType.addAction(this.onStructureTypeChanged.bind(this));
    this.APP.sldExistingLength2.addAction(this.onLength2Changed.bind(this));
    this.APP.sldExistingWidth1.addAction(this.onWidth1Changed.bind(this));
    this.APP.sldExistingWidth2.addAction(this.onWidth2Changed.bind(this));
    this.APP.sldBuildingHeight.addAction(
      this.onBuildingHeightChanged.bind(this)
    );
    this.APP.sldExistingWallHeight.addAction(
      this.onExistingWallHeightChanged.bind(this),
      1
    );

    this.APP.sltBeamType.addAction(this.onBeamTypeChanged.bind(this));
    this.APP.sltColumnType.addAction(this.onColumnTypeChanged.bind(this));
    this.APP.sltBaseFixingType.addAction(
      this.onBaseFixingTypeChanged.bind(this)
    );

    this.APP.sltCutOut.addAction(this.onCutoutChanged.bind(this));
    this.APP.sltLeftCutType.addAction(this.onRakeCutLeftChanged.bind(this));
    this.APP.sltRightCutType.addAction(this.onRakeCutRightChanged.bind(this));
    this.APP.sldSpan.addAction(this.onSetColumnType.bind(this));
    this.APP.sldMultiSpan.addAction(this.onSetColumnType.bind(this));
    [
      this.APP.sldEaveWidth,
      this.APP.sldExistingWallHeight,
      this.APP.sldFasciaDepth,
      this.APP.sldBaySize,
      this.APP.sldExistingLength,
      this.APP.sldExistingLength2,
      this.APP.sldExistingWidth1,
      this.APP.sldExistingWidth2,
      this.APP.sltExistingType,
      this.APP.sltStructureType,
      this.APP.sltWindClass,
      this.APP.sltWallType,
      this.APP.sltExistingRoofType,
      this.APP.sldMultiSpan,
      this.APP.sldBuildingHeight,
      this.APP.sldFrontOverhang,
      this.APP.sldBackOverhang,
      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,
      this.APP.sltRoofPitch,
      this.APP.sldFlyOverBracketHeight,
      this.APP.sldNoOfBay,
      this.APP.dialogEditBay,
      this.APP.sltExistingRoofPitch,
      this.APP.sldRoofOverallLength,
      this.APP.sldRoofOverallWidth,
      this.APP.sldMinHeight,
      this.APP.sltLeftCutType,
      this.APP.sltRightCutType,
      this.APP.sldLeftCutVertical,
      this.APP.sldLeftCutHorizontal,
      this.APP.sldRightCutVertical,
      this.APP.sldRightCutHorizontal,
      this.APP.sltFlatBottom,
      this.APP.sltZFlashingType,
      // -----------------------
      this.APP.sltRoofSheetingType,
      this.APP.sltBaseFixingType,
      this.APP.sltRoofThickness,
      this.APP.sltBargeType,
      this.APP.sltDripBarge,
      this.APP.sltDownpipeType,
      this.APP.sltGutterType,
      this.APP.sltBeamType,
      this.APP.sltBeamSize,
      this.APP.sltHouseBeamSize,
      this.APP.sltReceiverType,
      this.APP.sltColumnType,
      this.APP.sltColourRoof,
      this.APP.sltColourBarge,
      this.APP.sltColourGutter,
      this.APP.sltColourZFlashing,
      this.APP.sltColourPost,
      this.APP.sltColourBeam,
      this.APP.sltColourBracket,
      this.APP.sltColourFasciaBracket,
      this.APP.sltColourDownpipe,
      this.APP.sltFasciaUpstandBrackets,
      this.APP.sltUpFasciaUpstandBracket,
      this.APP.chkRapidSetCement,
      this.APP.chkHasHouseBeam,
    ].forEach((c) => c.addAction(this.onUIChanged.bind(this), 4));

    this.APP.dialogEditBay.addAction(this.setBeamSize.bind(this));
    this.APP.sldBaySize.addAction(this.setBeamSize.bind(this));
    this.APP.sldSpan.addAction(this.setBeamSize.bind(this));
    this.APP.sltWindClass.addAction(this.setBeamSize.bind(this));
    this.APP.sltBeamType.addAction(this.setBeamSize.bind(this));
    this.APP.sltBeamSize.addAction(this.setMaxSpan.bind(this));

    this.APP.sltWindClass.addAction(this.setMaxSpan.bind(this));

    [
      this.APP.sltCutOut,
      this.APP.sltStructureType,
      this.APP.sldExistingWallHeight,
      this.APP.sldExistingWidth1,
      this.APP.sldEaveWidth,
      this.APP.sltRoofPitch,
      this.APP.sltExistingType,
      this.APP.sltRoofSheetingType,
      this.APP.sltRoofThickness
    ].forEach((c) => c.addAction(this.handleSnapRoofToExistingWhenCutOut.bind(this), 5));
  }

  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.updateOverallValue(null, null);
      this.APP.sltStructureType.setSelected(INIT.CONNECTION_TYPE); // <= setSelected doesn't work in case value = 0, need to call onStructureTypeChanged instead
      this.onStructureTypeChanged(null, INIT.CONNECTION_TYPE);
      this.validateCutout();

      //this.setDefaultValueForTest();
      resolve();
    });
  }

  setDefaultValueForTest() {
    if (environment.production) {
      return;
    }

    // this.APP.sltExistingType.setSelected(1);
    // this.APP.sldExistingWidth1.setValue(2000);
    // this.APP.sldExistingLength2.setValue(3000);
    // this.APP.sldExistingWidth2.setValue(2800);

    setTimeout(() => {
      //this.APP.sltCutOut.setSelected(1);
      this.APP.sltStructureType.setSelected(CONNECTION_TYPE.FASCIA);
      this.APP.sltColourRoof.setSelected(0x304c3c);
    }, 1000);
  }

  onUIChanged(previousValue: number, currentValue: number) {
    this.validateCutout();
    this.validateRakeCut();
    this.APP.onSetMaxValue();
    if (this.debouncingHandle) {
      clearTimeout(this.debouncingHandle);
    }
    this.debouncingHandle = setTimeout(() => {
      this.onUIChangedDefer(previousValue, currentValue);
    }, this.debouncingTimeout);

    this.APP.renderStatic();
  }

  onUIChangedDefer(previousValue: number, currentValue: number) {
    this.APP.onCalcPrice();
    this.APP.renderStatic();
  }
  onStructureTypeChanged(previousValue: number, currentValue: number) {
    this.scene.remove(
      ...this.scene.children.filter(
        (o) => o.userData.category === GEOMETRY_CATEGORY.PATIOS
      )
    );
    // this.APP.dimensionManager.unload();

    if (this.APP.patiosManager) {
      this.APP.patiosManager.destroy();
      this.APP.patiosManager = null;
    }

    this.APP.sldBackOverhang.isDisabled = false;
    this.APP.sldBuildingHeight.isDisabled = true;
    let _flag = false;
    const _curVal = +currentValue;
    if (_curVal === CONNECTION_TYPE.FREE_STANDING) {
      this.APP.baseFixingTypeList = this.APP.objects.baseFixingTypeList.filter(
        (f) => f.id === 1
      );
      this.APP.sltBaseFixingType.setSelected(1);
    } else {
      this.APP.baseFixingTypeList = [...this.APP.objects.baseFixingTypeList];
    }
    switch (_curVal) {
      case CONNECTION_TYPE.EXISTING:
        if (
          this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF &&
          this.APP.isFBRoof
        ) {
          this.APP.patiosManager = new GableFBExistingBuildingManager(this.APP);
        } else {
          this.APP.patiosManager = new ExistingBuildingManager(this.APP);
        }
        break;
      case CONNECTION_TYPE.FLY_OVER:
        if (
          this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF &&
          !this.APP.isFBRoof
        ) {
          this.APP.patiosManager = new PatiosGableLRFlyOverManager(this.APP);
        } else if (
          this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF &&
          this.APP.isFBRoof
        ) {
          this.APP.patiosManager = new PatiosGableFBFlyOverManager(this.APP);
        } else {
          this.APP.patiosManager = new PatiosFlyOverManager(this.APP);
        }
        break;
      case CONNECTION_TYPE.BLACK_FLY_OVER:
        this.APP.patiosManager = new PatiosBackFlyOverManager(this.APP);
        break;
      case CONNECTION_TYPE.FASCIA:
      case CONNECTION_TYPE.BACK_FASCIA_UPSTAND:
      case CONNECTION_TYPE.FASCIA_UPSTAND:
        if (
          this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF &&
          !this.APP.isFBRoof
        ) {
          this.APP.patiosManager = new GableLRFasciaManager(this.APP);
        } else if (
          this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF &&
          this.APP.isFBRoof
        ) {
          this.APP.patiosManager = new GableFBFasciaManager(this.APP);
        } else {
          this.APP.patiosManager = new FasciaManager(this.APP);
        }
        break;
      case CONNECTION_TYPE.FREE_STANDING:
        if (this.APP.patiosRoofType === PATIOS_ROOF_TYPE.FLAT_ROOF) {
          this.APP.patiosManager = new PatiosFreeStandingManager(this.APP);
          break;
        } else {
          this.APP.patiosManager = new PatiosGableFreeStandingManager(this.APP);
          break;
        }
    }

    UI.oldBackOverhang = this.APP.sldBackOverhang.currentValue;

    this.APP.patiosManager
      .load()
      .then(() => {
        this.APP.dimensionManager.load();
        // this.APP.patiosManager.loadBracket(this.APP.flyoverBracketNumber); // số lượng bracket mặc định load lần đầu
        return this.APP.groundManager.load();
      })
      .then(() => {
        this.APP.sltFasciaUpstandBrackets.setDisable(true);

        if (this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF) {
          if (!this.APP.isFBRoof) {
            this.APP.sldBackOverhang.setValue(200);
            this.APP.sldBackOverhang.setMin(200);
            this.APP.sldFrontOverhang.setValue(200);
            this.APP.sldFrontOverhang.setMin(200);
            this.APP.sldBackOverhang.setDisable(false);
            this.APP.sldBackOverhang.setDisable(false);

            this.APP.sldLeftOverhang.setMin(0);
            this.APP.sldRightOverhang.setMin(0);
          } else {
            this.APP.sldLeftOverhang.setValue(200);
            this.APP.sldLeftOverhang.setMin(200);
            this.APP.sldRightOverhang.setValue(200);
            this.APP.sldRightOverhang.setMin(200);

            this.APP.sldBackOverhang.setMin(0);
            this.APP.sldFrontOverhang.setMin(0);
          }

          this.APP.sltLeftCutType.setSelected(0);
          this.APP.sltRightCutType.setSelected(0);
          this.APP.sltLeftCutType.setDisable(true);
          this.APP.sltRightCutType.setDisable(true);

          this.APP.sldMultiSpan.setValue(0);
          this.APP.sldMultiSpan.setDisable(true);
        } else {
          this.APP.sldBackOverhang.setMin(0);
          this.APP.sldFrontOverhang.setMin(0);

          this.APP.sltLeftCutType.setDisable(false);
          this.APP.sltRightCutType.setDisable(false);

          this.APP.sldMultiSpan.setDisable(false);
        }

        switch (_curVal) {
          case CONNECTION_TYPE.EXISTING:
            this.APP.sldBackOverhang.setValue(0);
            this.APP.sldBuildingHeight.isDisabled = false;
            this.APP.sldBackOverhang.isDisabled = true;
            this.APP.sldExistingWallHeight.isDisabled = false;
            if (this.APP.sltExistingType.currentValue == 3) {
              _flag = true;
            }
            break;
          case CONNECTION_TYPE.FLY_OVER:
            this.APP.sldExistingWallHeight.isDisabled = false;
            if (this.APP.sldBackOverhang.currentValue == 0) {
              this.APP.sldBackOverhang.setValue(UI.oldBackOverhang);
            }
            break;
          case CONNECTION_TYPE.BLACK_FLY_OVER:
            if (this.APP.sldBackOverhang.currentValue == 0) {
              this.APP.sldBackOverhang.setValue(UI.oldBackOverhang);
            }
            this.APP.sldExistingWallHeight.isDisabled = false;
            break;
          case CONNECTION_TYPE.FASCIA:
          case CONNECTION_TYPE.BACK_FASCIA_UPSTAND:
          case CONNECTION_TYPE.FASCIA_UPSTAND:
            this.APP.sltFasciaUpstandBrackets.setSelected(
              _curVal == CONNECTION_TYPE.FASCIA_UPSTAND ||
                _curVal == CONNECTION_TYPE.BACK_FASCIA_UPSTAND
                ? CONFIG.fasciaUpstandBrackets.max
                : 0
            );
            this.APP.sltUpFasciaUpstandBracket.setValue(
              _curVal == CONNECTION_TYPE.BACK_FASCIA_UPSTAND
            );
            this.APP.sldBackOverhang.setValue(0);
            this.APP.sldBackOverhang.isDisabled = true;
            //Bang - 2022.03.22
            if (this.APP.sltFasciaUpstandBrackets.currentValue > 0) {
              this.APP.sldBackOverhang.setValue(UI.oldBackOverhang);
              this.APP.sldBackOverhang.isDisabled = false;
            } else {
              this.APP.sldBackOverhang.setValue(0);
              this.APP.sldBackOverhang.isDisabled = true;
            }
            this.APP.sldBuildingHeight.setValue(
              this.APP.sldExistingWallHeight.currentValue
            );
            this.APP.sldExistingWallHeight.isDisabled = false;
            if (
              UI.structureType == CONNECTION_TYPE.FASCIA 
              && UI.existingRoofType == BUILDING_SIDE.BOTH 
              && UI.patiosRoofType == PATIOS_ROOF_TYPE.FLAT_ROOF
            ) {
              if(!this.calcShouldSnapRoofToExistingWhenCutout()) {
                this.APP.sldExistingLength.setValue(
                  UI.totalBayLength + UI.eaveWidth * 2
                );
              }
              _flag = true;
            }
            this.APP.sltFasciaUpstandBrackets.setDisable(
              _curVal == CONNECTION_TYPE.FASCIA_UPSTAND ? false : true
            );

            if (_curVal === CONNECTION_TYPE.FASCIA) {
              this.APP.sltUpFasciaUpstandBracket.setValue(false);
            }
            break;
          case CONNECTION_TYPE.FREE_STANDING:
            if (this.APP.sldBackOverhang.currentValue == 0) {
              this.APP.sldBackOverhang.setValue(UI.oldBackOverhang);
            }
            this.APP.sldBuildingHeight.isDisabled = false;
            this.APP.sldExistingWallHeight.isDisabled = true;
            // this.sldExistingWidth1.setValue(0);
            break;
        }
        if (this.APP.sltExistingType.currentValue == 3) {
          this.onExistingTypeChanged(null, 3);
        }
        this.APP.sldLeftOverhang.isDisabled = _flag;
        this.APP.sldRightOverhang.isDisabled = _flag;
        this.APP.patiosManager.update(null, null);
        setTimeout(() => {
          this.setColumnType("", _curVal, -1);
        }, 500);
        this.onExistingWallHeightChanged(
          0,
          this.APP.sldExistingWallHeight.currentValue
        );
        this.APP.renderStatic();
      });
  }

  updateOverallValue(pre: number, cur: number) {
    let totalBaySize = this.APP.dialogEditBay.totalBaySize;
    let _roofOverallWidth =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue +
      this.APP.sldBackOverhang.currentValue;
    if (
      HomeComponent.ins.patiosRoofType == PATIOS_ROOF_TYPE.GABLE_ROOF &&
      HomeComponent.ins.isFBRoof
    ) {
      _roofOverallWidth =
        UI.span + UI.multiSpan + UI.overhangLeft + UI.overhangRight;
    }

    this.APP.sldRoofOverallWidth.setValue(_roofOverallWidth);

    let _roofOverallLength =
      totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    if (
      HomeComponent.ins.patiosRoofType == PATIOS_ROOF_TYPE.GABLE_ROOF &&
      HomeComponent.ins.isFBRoof
    ) {
      _roofOverallLength = totalBaySize + UI.overhangFront + UI.overhangBack;
    }
    this.APP.sldRoofOverallLength.setValue(_roofOverallLength);
    this.APP.getRoofPercent(_roofOverallLength);
    //this.onDialogEditBayChanged();
    //this.APP.renderStatic();
  }

  public setMaxSpan() {
    if (this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF) {
      if (UI.beamType == 0) {
        // const findCondition = this.APP.objects.gableConditionList.find((f) =>
        //   // f.beamSize.includes(UI.beamSize) && //Bang - 2024.01.02
        //   f.windClass.includes(UI.windClass)
        // );
        const findCondition = this.APP.objects.gableConditionList.filter((f) =>
          f.windClass.includes(UI.windClass)
        );
        if (findCondition.length > 0) {
          this.APP.maxSpan = findCondition[findCondition.length - 1].maxSpan;
          this.APP.maxSpanText = `${
            findCondition[findCondition.length - 1].maxSpan
          } max`;
          this.APP.maxBaySize = findCondition[findCondition.length - 1].maxBay;
          this.APP.maxBaySizeText = `${
            findCondition[findCondition.length - 1].maxBay
          } max`;
        }
      } else {
        if (UI.windClass == 0 || UI.windClass == 1) {
          this.APP.maxSpan = 8500;
          this.APP.maxSpanText = "8500 max";
          this.APP.maxBaySize = 8000;
          this.APP.maxBaySizeText = "8000 max";
        } else {
          this.APP.maxSpan = 7000;
          this.APP.maxSpanText = "7000 max";
          this.APP.maxBaySize = 6000;
          this.APP.maxBaySizeText = "6000 max";
        }
      }
    }
  }

  public setBeamSize() {
    if (this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF) {
      let minBeam = 1;
      let maxBeam = 4;

      let length = this.APP.dialogEditBay.totalBaySize;
      let width = UI.span;
      let windClass = UI.windClass;

      const filterValue = Math.max(length, width);
      const filterField = length > width ? "lengthMax" : "widthMax";
      const findCondition = this.APP.objects.gableBeamConditionList.find(
        (f) => f.windClass.includes(windClass) && f[filterField] >= filterValue
      );
      if (findCondition) {
        minBeam = findCondition.beamSizeIndex;
      }

      this.APP.beamSizeList = this.APP.objects.beamSizeList.filter(
        (el) =>
          el.beamType == this.APP.sltBeamType.currentValue &&
          el.id >= minBeam &&
          el.id <= maxBeam
      );
      this.setMaxSpan();
      this.APP.sltBeamSize.setSelectedAndHandleEvent(minBeam);
    }
  }

  public onFasciaUpstandBracketChanged(prevVal: number, curVal: number): void {
    // Bang - 2022.03.22
    if (
      UI.upstandBraketType > 0 &&
      !HomeComponent.ins.sltFasciaUpstandBrackets.isDisabled
    ) {
      this.APP.sldBackOverhang.isDisabled = false;
      this.APP.sldBackOverhang.setValue(UI.oldBackOverhang);
    } else {
      this.APP.sldBackOverhang.setValue(0);
      this.APP.sldBackOverhang.isDisabled = true;
    }
    this.onSetColumnType(null, null);
  }

  public onWidth1Changed(prevVal: number, curVal: number): void {
    if (curVal < 620) {
      this.APP.sldExistingLength2.setValue(0);
      this.APP.sldExistingLength2Visible = false;
    } else {
      this.APP.sldExistingLength2Visible = true;
    }

    //change baysize when can cutout and can not cutout
    if (this.APP.sltCutOut.currentValue == 1) {
      //let cutoutCondition = this.APP.patiosManager.cutoutCondition.map(x => x.currentValue).reduce((a,b) => a + b, 0) - this.APP.sldExistingWidth1.currentValue >= EXISTING_BUILDING_CONFIG.CUTOUT_ALLOW;
      if (this.APP.patiosManager.cutoutCondition && !window["cutout"]) {
        this.setEnableCutout(true);
        window["cutout"] = true;
      } else if (!this.APP.patiosManager.cutoutCondition) {
        this.setEnableCutout(false);
        window["cutout"] = false;
      }
    }
    //this.validateCutout();
  }
  public onWidth2Changed(prevVal: number, curVal: number): void {
    if (
      this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
      this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH
    ) {
      if (curVal == 0) {
        this.APP.patiosManager.ignoreBayEvent = true;
        setTimeout(() => {
          this.APP.patiosManager.ignoreBayEvent = false;
        }, 2000);

        this.APP.setPatiosWidth(
          this.APP.dialogEditBay.totalBaySize -
            (this.APP.existingWallManager.geo_existingWallW2.width +
              EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK) *
              2
        );
      } else {
        let totalExistingWidth =
          this.APP.sldExistingLength.currentValue +
          this.APP.sldExistingLength2.currentValue * 2 +
          (this.APP.existingWallManager.geo_existingWallW2.width +
            EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK) *
            2;
        if (
          this.APP.sltStructureType.currentValue == 1 ||
          this.APP.sltStructureType.currentValue == 2
        ) {
          totalExistingWidth =
            this.APP.sldExistingLength.currentValue +
            this.APP.sldExistingLength2.currentValue * 2 +
            EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK * 2;
        }
        if (this.APP.dialogEditBay.totalBaySize < totalExistingWidth) {
          this.APP.patiosManager.ignoreBayEvent = true;
          setTimeout(() => {
            this.APP.patiosManager.ignoreBayEvent = false;
          }, 2000);

          this.APP.setPatiosWidth(totalExistingWidth);
        }
      }
    }
  }
  public onLength2Changed(prevVal: number, curVal: number): void {
    if (curVal < 1200) {
      this.APP.sldExistingWidth2.setValue(0);
      this.APP.sldExistingWidth2Visible = false;
    } else {
      this.APP.sldExistingWidth2Visible = true;
    }

    if (!prevVal) prevVal = curVal;
    let diff = curVal - prevVal;

    this.onAdjustListBayWhenCutOut(diff)
  }
  public onAdjustListBayWhenCutOut(diff) {
    if (UI.cutOutType == CUTOUT_ENABLE.YES) {
      if (
        UI.existingType == BUILDING_SIDE.LEFT ||
        UI.existingType == BUILDING_SIDE.RIGHT 
      ) {
        let baySize = UI.baySize + diff / UI.noOfBay;
        if (baySize > CONFIG.baySize.max) {
          let noOfBay = UI.noOfBay + 1;
          this.ignoreEnableCutoutEvent = true;
          this.APP.sldNoOfBay.setValue(noOfBay);
          baySize = UI.totalBayLength / noOfBay;
        }
        this.ignoreEnableCutoutEvent = true;
        this.APP.sldBaySize.setValue(Math.ceil(baySize));
      } else {
        let baySize =  UI.baySize + (diff * 2) / this.APP.sldNoOfBay.currentValue;
        if (baySize > CONFIG.baySize.max) {
          let noOfBay = this.APP.sldNoOfBay.currentValue + 1;
          this.ignoreEnableCutoutEvent = true;
          this.APP.sldNoOfBay.setValue(noOfBay);
          baySize = UI.totalBayLength / noOfBay;
        }
        this.ignoreEnableCutoutEvent = true;
        this.APP.sldBaySize.setValue(Math.ceil(baySize));
      }
    }
  }
  public onCutoutChanged(prevVal: number, curVal: number) {
    //this.APP.patiosManager.enableCutout(curVal == 1 && this.APP.patiosManager.cutoutCondition);
    //window['baySize'] = this.APP.sldBaySize.currentValue;
    this.setEnableCutout(curVal == 1 && this.APP.patiosManager.cutoutCondition);
  }
  public onRakeCutLeftChanged(pre: number, cur: number) {
    //If left cut is ON disable left over hang
    this.APP.sldLeftOverhang.setDisable(false);
    if (cur != RAKECUT_TYPE.NONE) {
      this.APP.sldLeftOverhang.setValue(0);
      this.APP.sldLeftOverhang.setDisable(true);
    }

    if (cur == RAKECUT_TYPE.NONE) {
      this.APP.sldLeftCutHorizontal.setValue(0);
    }
  }
  public onRakeCutRightChanged(pre: number, cur: number) {
    //If right cut is ON disable right over hang
    this.APP.sldRightOverhang.setDisable(false);
    if (cur != RAKECUT_TYPE.NONE) {
      this.APP.sldRightOverhang.setValue(0);
      this.APP.sldRightOverhang.setDisable(true);
    }

    if (cur == RAKECUT_TYPE.NONE) {
      this.APP.sldRightCutHorizontal.setValue(0);
    }
  }

  // this is hight priority (1), must be called first to setup BUILDING HEIGHT for patios manager to use
  private onExistingWallHeightChanged(prevVal: number, curVal: number): void {
    const currentBeam = GeometryManager.Instance().getBeam();
    const _typeVal = +this.APP.sltStructureType.currentValue;
    let _buildingHeightMax = 0;
    if (_typeVal === CONNECTION_TYPE.EXISTING) {
      // Existing Building
      _buildingHeightMax = curVal - EXISTING_BUILDING_CONFIG.BUILDING_HEIGHT_OFFSET;
      this.APP.sldBuildingHeight.setMax(_buildingHeightMax);
      if (
        curVal - this.APP.sldBuildingHeight.currentValue <= EXISTING_BUILDING_CONFIG.BUILDING_HEIGHT_OFFSET
      ) {
        this.APP.sldBuildingHeight.setValue(_buildingHeightMax);
      }
    } else if (_typeVal === CONNECTION_TYPE.FASCIA || _typeVal === CONNECTION_TYPE.FASCIA_UPSTAND) {
      // Fascia
      _buildingHeightMax = CONFIG.eaveHeight.max;
      this.APP.sldBuildingHeight.setMax(_buildingHeightMax);
      this.APP.sldBuildingHeight.setValue(curVal);
    } else if (_typeVal === CONNECTION_TYPE.FREE_STANDING) {
      _buildingHeightMax = CONFIG.height.free_standing_max;
      this.APP.sldBuildingHeight.setMax(_buildingHeightMax);
      this.APP.sldBuildingHeight.setValue(curVal);
    } else {
      // _buildingHeightMax= CONFIG.height.max;
      _buildingHeightMax = CONFIG.eaveHeight.max;
      this.APP.sldBuildingHeight.setMax(_buildingHeightMax);
      this.APP.sldBuildingHeight.setValue(
        curVal +
          this.APP.sldFlyOverBracketHeight.currentValue +
          currentBeam.height
      );
    }

    setTimeout(() => {
      this.setColumnType("", _typeVal, -1);
    }, 500);
    this.APP.renderStatic();
  }

  public onExistingWallHeightChanged2(prevVal: number, curVal: number): void {}

  private changeToExistingTypeBoth() {
    this.setEnableCutout(
      this.APP.sltCutOut.currentValue == 1 &&
        this.APP.patiosManager.cutoutCondition
    );
  }
  private setEnableCutout(enable: boolean) {
    if(HomeComponent.ins.currentBuildingInfo.isOpen) {
      return
    }
    if (
      this.APP.sltStructureType.currentValue == CONNECTION_TYPE.FLY_OVER ||
      this.APP.sltStructureType.currentValue == CONNECTION_TYPE.BLACK_FLY_OVER
    ) {
      //Change patios width o day thi khong cho length1 chay theo patios width
      //set bien nay true thi length1 khong chay theo patios width
      this.APP.patiosManager.ignoreBayEvent = true;
      setTimeout(() => {
        this.APP.patiosManager.ignoreBayEvent = false;
      }, 3000);

      if (enable) {
        if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.NONE) {
          this.APP.setPatiosWidth(this.APP.sldExistingLength.currentValue);
        } else if (
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT ||
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT
        ) {
          this.APP.setPatiosWidth(
            CONFIG.baySize.min + this.APP.sldExistingLength2.currentValue
          );
        } else {
          if (this.APP.sldExistingWidth2.currentValue > 0) {
            this.APP.setPatiosWidth(
              this.APP.sldExistingLength.currentValue +
                EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK * 2 +
                this.APP.sldExistingLength2.currentValue * 2
            );
          } else {
            this.APP.setPatiosWidth(
              this.APP.sldExistingLength.currentValue +
                this.APP.sldExistingLength2.currentValue * 2
            );
          }
        }
      } else {
        this.APP.setPatiosWidth(
          this.APP.sldExistingLength.currentValue +
            EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK * 2
        );
        this.APP.sltCutOut.setSelected("0");
      }
    } else if (
      this.APP.sltStructureType.currentValue == CONNECTION_TYPE.FASCIA ||
      this.APP.sltStructureType.currentValue ==
        CONNECTION_TYPE.BACK_FASCIA_UPSTAND ||
      this.APP.sltStructureType.currentValue == CONNECTION_TYPE.FASCIA_UPSTAND
    ) {
      this.APP.patiosManager.ignoreBayEvent = true;
      setTimeout(() => {
        this.APP.patiosManager.ignoreBayEvent = false;
      }, 1000);

      if (enable) {
        if (
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT ||
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT
        ) {
          this.APP.setPatiosWidth(
            CONFIG.baySize.min + this.APP.sldExistingLength2.currentValue
          );
        }
        if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
          if(UI.structureType == CONNECTION_TYPE.FASCIA && this.calcShouldSnapRoofToExistingWhenCutout()) {
            this.APP.setPatiosWidth(UI.existingLength1 + UI.existingLength2 * 2);
          } else {
            this.APP.setPatiosWidth(UI.existingLength1 + UI.existingLength2 * 2 - UI.eaveWidth * 2);
          }
        }
      } else {
        this.APP.setPatiosWidth(
          this.APP.sldExistingLength.currentValue -
            this.APP.sldEaveWidth.currentValue * 2
        );
        this.APP.sltCutOut.setSelected("0");
      }
    } else if (
      this.APP.sltStructureType.currentValue == CONNECTION_TYPE.EXISTING
    ) {
      //Change patios width o day thi khong cho length1 chay theo patios width
      //set bien nay true thi length1 khong chay theo patios width
      this.APP.patiosManager.ignoreBayEvent = true;
      setTimeout(() => {
        this.APP.patiosManager.ignoreBayEvent = false;
      }, 3000);

      if (enable) {
        if (
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT ||
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT
        ) {
          this.APP.setPatiosWidth(
            CONFIG.baySize.min + this.APP.sldExistingLength2.currentValue
          );
        }
        if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
          this.APP.setPatiosWidth(
            this.APP.sldExistingLength.currentValue +
              this.APP.sldExistingLength2.currentValue * 2
          );
        }
      } else {
        this.APP.setPatiosWidth(this.APP.sldExistingLength.currentValue);
        this.APP.sltCutOut.setSelected("0");
      }
    }
  }
  // When the highest point of cutout roof (To fascia) is lower eave height, we should snap this roof to wall, like existing roof
  public handleSnapRoofToExistingWhenCutOut(): void {
    this.previousShouldSnapFasciaRoofToWall = this.shouldSnapFasciaRoofToWall
    this.shouldSnapFasciaRoofToWall = this.calcShouldSnapRoofToExistingWhenCutout()

    if(UI.structureType == CONNECTION_TYPE.FASCIA && UI.existingType != BUILDING_SIDE.NONE && UI.cutOutType == CUTOUT_ENABLE.YES) {
      this.setEnableCutout(UI.cutOutType == CUTOUT_ENABLE.YES && HomeComponent.ins.patiosManager.cutoutCondition)
    }
  }
  public calcShouldSnapRoofToExistingWhenCutout(): boolean {
    const roofBaseHeight = HomeComponent.ins.geometryManager.getRoofBaseHeight()

    if(
      UI.structureType == CONNECTION_TYPE.FASCIA 
      && UI.existingType != BUILDING_SIDE.NONE 
      && UI.cutOutType == CUTOUT_ENABLE.YES 
      && HomeComponent.ins.patiosRoofType == PATIOS_ROOF_TYPE.FLAT_ROOF
    ) {
      const cutOutRoofHeightIfSnapToExisting = UI.eaveHeight 
        - ((UI.existingWidth1 - UI.eaveWidth) * this.utils.tan(UI.patiosPitch)) 
        + (roofBaseHeight / this.utils.cos(UI.patiosPitch))
      if(cutOutRoofHeightIfSnapToExisting < UI.eaveHeight) {
        return true
      }
    }

    return false
  }

  public onBackOverhangChanged() {
    UI.oldBackOverhang = UI.overhangBack
  }

  public onExistingTypeChanged(prevVal: number, val: number) {
    this.APP.sldLeftOverhang.setDisable(false);
    this.APP.sldRightOverhang.setDisable(false);

    //this.validateCutout();

    if (val == 0) {
      this.alignWallToPatios(false);
      this.APP.sldExistingWidth1.setValue(0);
      this.APP.sldExistingWidth2.setValue(0);
      this.APP.sldExistingLength2.setValue(0);
    }
    if (val == 1 || val == 2) {
      this.alignWallToPatios(true);
      this.setEnableCutout(
        this.APP.sltCutOut.currentValue == 1 &&
          this.APP.patiosManager.cutoutCondition
      );
    }
    if (val == 3) {
      this.alignWallToPatios(true);

      this.APP.sldLeftOverhang.setValue(0);
      this.APP.sldLeftOverhang.setDisable(true);
      this.APP.sldRightOverhang.setValue(0);
      this.APP.sldRightOverhang.setDisable(true);

      if (
        this.APP.isFBRoof &&
        UI.structureType == CONNECTION_TYPE.EXISTING &&
        UI.existingType == BUILDING_SIDE.BOTH &&
        this.APP.patiosRoofType == PATIOS_ROOF_TYPE.GABLE_ROOF
      ) {
        this.APP.sldLeftOverhang.setValue(200);
        this.APP.sldRightOverhang.setValue(200);
      }
      this.changeToExistingTypeBoth();
    }

    const _structureType = +this.APP.sltStructureType.currentValue;
    if (_structureType === 0) {
      this.APP.sldBackOverhang.isDisabled = true;
    } else if (_structureType === 3 || _structureType === 5) {
      this.APP.sldBackOverhang.isDisabled = true;
      // Bang - 2022.03.22
      if (this.APP.sltFasciaUpstandBrackets.currentValue > 0) {
        this.APP.sldBackOverhang.isDisabled = false;
        this.APP.sldBackOverhang.setValue(UI.oldBackOverhang);
      } else {
        this.APP.sldBackOverhang.isDisabled = true;
      }
    } else {
      this.APP.sldBackOverhang.isDisabled = false;
    }
    // Disable Length1
    this.APP.sldExistingLength.isDisabled = val === 3;

    //Change panel direction
    // if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT){
    //     this.APP.sltPanelDirection.setValue(PANEL_DIRECTION.RIGHT_TO_LEFT);
    // }
    // else{
    //     this.APP.sltPanelDirection.setValue(PANEL_DIRECTION.LEFT_TO_RIGHT);
    // }
  }

  public onSpanChanged() {
    if (
      this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF &&
      this.APP.isFBRoof
    ) {
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        if (!this.APP.patiosManager.ignoreBayEvent) {
          if (
            this.APP.sltStructureType.currentValue ==
            CONNECTION_TYPE.FASCIA_UPSTAND
          ) {
            this.APP.sldExistingLength.setValue(
              Math.ceil(
                UI.span +
                  this.APP.sldEaveWidth.currentValue * 2 +
                  2 * FASCIA_BRACKET_FIT
              )
            );
          } else if (
            this.APP.sltStructureType.currentValue == CONNECTION_TYPE.FLY_OVER
          ) {
            this.APP.sldExistingLength.setValue(
              UI.span - EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK * 2
            );
          } else {
            this.APP.sldExistingLength.setValue(
              UI.span +
                UI.overhangLeft +
                UI.overhangRight +
                OFFSET_GUTTER_TO_WALL * 2
            );
          }
        }
      }
    }
  }

  public onDialogEditBayChanged() {
    // this.APP.patiosManager.loadBracket(5);
    if (
      this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF &&
      this.APP.isFBRoof
    ) {
      return;
    }

    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
      let totalBaySize = this.APP.dialogEditBay.totalBaySize;

      if (!this.APP.patiosManager.ignoreBayEvent) {
        if (
          UI.structureType == CONNECTION_TYPE.FASCIA ||
          UI.structureType == CONNECTION_TYPE.BACK_FASCIA_UPSTAND ||
          UI.structureType == CONNECTION_TYPE.FASCIA_UPSTAND
        ) {
          //Facia
          if (
            this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
            this.APP.patiosManager.cutoutCondition
          ) {
            if(this.calcShouldSnapRoofToExistingWhenCutout()) {
              this.APP.sldExistingLength.setValue(
                totalBaySize - UI.existingLength2 * 2
              );
            } else {
              this.APP.sldExistingLength.setValue(
                totalBaySize - UI.existingLength2 * 2 + UI.eaveWidth * 2
              );
            }
          } else {
            this.APP.sldExistingLength.setValue(
              totalBaySize + this.APP.sldEaveWidth.currentValue * 2
            );
          }
        } else if (
          this.APP.sltStructureType.currentValue == CONNECTION_TYPE.FLY_OVER ||
          this.APP.sltStructureType.currentValue ==
            CONNECTION_TYPE.BLACK_FLY_OVER
        ) {
          if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES) {
            if (this.APP.sldExistingWidth2.currentValue > 0) {
              this.APP.sldExistingLength.setValue(
                totalBaySize -
                  this.APP.sldExistingLength2.currentValue * 2 -
                  EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK * 2
              );
            } else {
              this.APP.sldExistingLength.setValue(
                totalBaySize - this.APP.sldExistingLength2.currentValue * 2
              );
            }
          } else {
            this.APP.sldExistingLength.setValue(
              totalBaySize - EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK * 2
            );
          }
        } else {
          if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES) {
            this.APP.sldExistingLength.setValue(
              totalBaySize - this.APP.sldExistingLength2.currentValue * 2
            );
          } else {
            this.APP.sldExistingLength.setValue(totalBaySize);
          }
        }
      }
    }

    if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES) {
      //this.APP.setNoOfBayFromBaySize(this.APP.dialogEditBay.totalBaySize , this.APP.sldBaySize.currentValue);
      if (
        this.APP.dialogEditBay.totalBaySize <
          this.APP.sldExistingLength2.currentValue + CONFIG.baySize.min &&
        !this.ignoreEnableCutoutEvent
      ) {
        this.setEnableCutout(false);
      }
    }
    //validate rake cut
    this.onBaySizeChanged(null, null);

    let roofOverallLength = UI.totalBayLength + UI.overhangLeft + UI.overhangRight
    if(HomeComponent.ins.patiosRoofType == PATIOS_ROOF_TYPE.GABLE_ROOF && HomeComponent.ins.isFBRoof) {
      roofOverallLength = UI.totalBayLength + UI.overhangFront + UI.overhangBack
    }

    // Check beam length > 9m, 2 bay, and not cut, auto cut this
    if(roofOverallLength > EXISTING_BUILDING_CONFIG.MAXIMUM_LENGTH_PER_BEAM && UI.listBay.length == 2) {
      if(!UI.listBay[0].isCut) {
        UI.listBay[0].isCut = true
        HomeComponent.ins.dialogEditBay.onOK()
      }
    }
  }
  public onBaySizeChanged(preVal: number, curVal: number) {
    // this.APP.patiosManager.loadBracket(this.APP.flyoverBracketNumber); // khi thay đổi UI truyền lại số lượng bracket
    //Reduce cut horizotal if totalcut larger than baymax
    let bayMax =
      this.APP.dialogEditBay.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    let totalCut =
      this.APP.sldLeftCutHorizontal.currentValue +
      this.APP.sldRightCutHorizontal.currentValue;

    //if(this.app)

    if (totalCut >= bayMax) {
      this.APP.sldLeftCutHorizontal.setValue(
        this.APP.sldLeftCutHorizontal.maxValue
      );
      this.APP.sldRightCutHorizontal.setValue(
        this.APP.sldRightCutHorizontal.maxValue
      );
    }
  }
  onBeamTypeChanged(pre, cur: number) {
    this.APP.beamSizeList = this.APP.objects.beamSizeList.filter(
      (f) => f.beamType == cur
    );

    this.APP.houseBeamSizeList = this.APP.objects.beamSizeList.filter(
      (f) => f.beamType == cur
    );
    if (cur === 1) {
      this.APP.sltColourBeam.setSelected(0xcabfa4);
    } else {
      this.APP.sltColourBeam.setSelected(0xe4e2d5);
    }
  }

  onColumnTypeChanged(pre, cur: number) {
    if (cur === 3) {
      this.APP.sltColourPost.setSelected(0xcabfa4);
    } else {
      this.APP.sltColourPost.setSelected(0xe4e2d5);
    }
  }
  onBaseFixingTypeChanged(pre, cur: number) {
    setTimeout(() => {
      this.setColumnType(cur.toString(), -1, -1);
      if (cur === 0) {
        this.APP.chkRapidSetCement.setValue(false);
      }
    }, 500);
  }
  onBuildingHeightChanged(pre, cur: number) {
    setTimeout(() => {
      this.setColumnType("", -1, cur);
    }, 500);
  }

  onSetColumnType(pre, cur: number) {
    setTimeout(() => {
      this.setColumnType("", -1, -1);
    }, 500);
  }

  setColumnType(
    baseFixingType: string,
    structureType: number,
    buildingHeight: number
  ) {
    const groups = this.scene.children.filter(
      (x) =>
        (x.userData.category === GEOMETRY_CATEGORY.PATIOS &&
          x.children.length > 0) ||
        x.userData.type === GEOMETRY_TYPE.SUPERIOR_POST
    );
    const listPosts = this.APP.getPosts(groups);
    if (listPosts.length > 0) {
      buildingHeight = _.round(
        _.maxBy(listPosts, (o) => o.length).length * 1000,
        0
      );
    } else {
      buildingHeight = this.APP.sldBuildingHeight.currentValue;
    }

    // get column type list
    const _columnType = +this.APP.sltColumnType.currentValue;
    if (baseFixingType === "") {
      baseFixingType = this.APP.sltBaseFixingType.currentValue.toString();
    }
    if (structureType === -1) {
      structureType = this.APP.sltStructureType.currentValue;
    }
    // if (buildingHeight === -1) {
    //   buildingHeight = this.APP.sldBuildingHeight.currentValue;
    // }
    if (buildingHeight) {
      if (structureType == 4) {
        this.APP.columnTypeList = this.APP.objects.columnTypeList2.filter(
          (f) =>
            f.baseFixingType.includes(baseFixingType) &&
            f.columnHeight >= buildingHeight &&
            f.idx >= 2
        );
      } else {
        this.APP.columnTypeList = this.APP.objects.columnTypeList2.filter(
          (f) =>
            f.baseFixingType.includes(baseFixingType) &&
            f.columnHeight >= buildingHeight
        );
      }
      if (this.APP.columnTypeList.length === 0) {
        this.APP.columnTypeList = this.APP.objects.columnTypeList2.filter(
          (f) => f.id === -1
        );
        this.APP.sltColumnType.setSelected(-1);
      } else {
        if (
          this.APP.columnTypeList.findIndex(
            (value, index) => value.id === _columnType
          ) >= 0
        ) {
          this.APP.sltColumnType.setSelected(_columnType);
        } else {
          this.APP.sltColumnType.setSelected(this.APP.columnTypeList[0].id);
        }
      }
    }
    //
  }
  loadFromObject(obj) {
    var promise = new Promise<void>((resolve, rejects) => {
      this.APP.patiosManager.ignoreBayEvent = true;
      setTimeout(() => {
        this.APP.patiosManager.ignoreBayEvent = false;
        resolve();
      }, 3000);
      const _rightToLeft = obj.rightToLeft || false;
      this.APP.sltPanelDirection.setValue(_rightToLeft);
      let _structureType = obj.structureType.value.toString();
      const _fasciaBracketHeight = obj.fasciaBracketHeight || 0;
      if (_structureType == 3 && _fasciaBracketHeight > 0) {
        _structureType = "5";
      }
      const _hasHouseBeam = obj.hasHouseBeam || false;
      this.APP.chkHasHouseBeam.setValue(_hasHouseBeam);
      this.APP.sltStructureType.setSelected(_structureType);
      this.APP.sltExistingType.setSelected(obj.existingType.value.toString());
      this.APP.sldExistingLength.setValue(obj.existingLength);
      this.APP.sldExistingLength2.setValue(obj.existingLength2);
      this.APP.sldExistingWidth1.setValue(obj.existingWidth1);
      this.APP.sldExistingWidth2.setValue(obj.existingWidth2);
      this.APP.sldFasciaDepth.setValue(obj.fasciaDepth);
      this.APP.sldExistingWallHeight.setValue(obj.eaveHeight);
      this.APP.sldBuildingHeight.setValue(obj.buildingHeight);
      this.APP.sldEaveWidth.setValue(obj.eaveWidth);
      this.APP.sldFlyOverBracketHeight.setValue(obj.flyoverBracketHeight);
      this.APP.sldSpan.setValue(obj.span);
      this.APP.sldMultiSpan.setValue(obj.multiSpan);
      this.APP.sldBaySize.setValue(obj.baySize);
      this.APP.sldRoofOverallLength.setValue(obj.roofOverallLength);
      this.APP.sldRoofOverallWidth.setValue(obj.roofOverallWidth);
      this.APP.sldMinHeight.setValue(obj.minHeight);
      //HACK: Xử lý lỗi cho các job cũ
      const _cutOut = obj.cutOut ? obj.cutOut.value : 0;
      this.APP.sltFasciaUpstandBrackets.setSelected(_fasciaBracketHeight);
      const _backFascia = obj.backFascia || false;
      this.APP.sltUpFasciaUpstandBracket.setValue(_backFascia);

      this.APP.sltWindClass.setSelected(obj.windClass.value);
      this.APP.sltBaseFixingType.setSelected(obj.baseFixingType.value);
      this.APP.sltBeamType.setSelected(obj.beamType.value);
      this.APP.sltCutOut.setSelected(_cutOut);

      this.APP.sltGutterType.setSelected(obj.gutterType.value);
      this.APP.sltDownpipeType.setSelected(obj.downpipeType.value);
      this.APP.sltZFlashingType.setSelected(obj.zFlashingType.value);
      this.APP.sltReceiverType.setSelected(obj.receiverType.value);
      this.APP.sltBargeType.setSelected(obj.bargeType.value);
      this.APP.sltFlatBottom.setSelected(obj.flatBottom.value);
      this.APP.sltRoofThickness.setSelected(obj.thickness.value);

      this.APP.sltRoofSheetingType.setSelected(obj.roofSheetingType.value);
      this.APP.sltWallType.setSelected(obj.wallType.value);
      const _existingRoofType = obj.existingRoofType
        ? obj.existingRoofType.value
        : 0;
      this.APP.sltExistingRoofType.setSelected(_existingRoofType);

      this.APP.sltExistingRoofPitch.setSelected(obj.existingRoofPitch);
      this.APP.sltRoofPitch.setSelected(obj.roofPitch);
      this.APP.sltColumnType.setSelected(obj.columnType.value);
      this.APP.sltBeamSize.setSelected(obj.beamSize.value);
      const _houseBeamSize = obj.hasHouseBeam
        ? obj.houseBeamSize.value
        : obj.beamSize.value;
      this.APP.sltHouseBeamSize.setSelected(_houseBeamSize);
      const _isRapidSetCement = obj.isRapidSetCement || false;
      this.APP.chkRapidSetCement.setValue(_isRapidSetCement);
      const _isDripBarge = obj.isDripBarge || false;
      this.APP.sltDripBarge.setValue(_isDripBarge);

      this.APP.sltColourDownpipe.setSelected(obj.colourDownpipe.value);
      this.APP.sltColourBracket.setSelected(obj.colourBracket.value);
      const _colourFasciaBracket = obj.colourFasciaBracket
        ? obj.colourFasciaBracket.value
        : 0xe4e2d50;
      this.APP.sltColourFasciaBracket.setSelected(_colourFasciaBracket);
      this.APP.sltColourZFlashing.setSelected(obj.colourZFlashing.value);
      this.APP.sltColourGutter.setSelected(obj.colourGutter.value);
      this.APP.sltColourBarge.setSelected(obj.colourBarge.value);
      this.APP.sltColourRoof.setSelected(obj.colourRoof.value);

      this.APP.isFBRoof = obj.isFBRoof;
      this.APP.patiosRoofType =
        obj.patiosRoofType || PATIOS_ROOF_TYPE.FLAT_ROOF;

      setTimeout(() => {
        this.APP.sldNoOfBay.setValue(obj.noOfBay);
        //----RakeCut
        const _leftCutType = obj.leftCutType ? obj.leftCutType.value : 0;
        const _rightCutType = obj.rightCutType ? obj.rightCutType.value : 0;
        const _leftCutVertical = obj.leftCutVertical || 0;
        const _rightCutVertical = obj.rightCutVertical || 0;
        const _leftCutHorizontal = obj.leftCutHorizontal || 0;
        const _rightCutHorizontal = obj.rightCutHorizontal || 0;

        this.APP.sltLeftCutType.setSelected(_leftCutType);
        this.APP.sltRightCutType.setSelected(_rightCutType);
        this.APP.sldLeftCutVertical.setValue(_leftCutVertical);
        this.APP.sldLeftCutHorizontal.setValue(_leftCutHorizontal);
        this.APP.sldRightCutVertical.setValue(_rightCutVertical);
        this.APP.sldRightCutHorizontal.setValue(_rightCutHorizontal);
        //Bang - 2021.04.06 -> Tranh loi khi default lại mau khi xay ra su kien onColumnTypeChanged()
        this.APP.sltColourPost.setSelected(obj.colourPost.value);
        this.APP.sltColourBeam.setSelected(obj.colourBeam.value);
        this.APP.sldBackOverhang.setValue(obj.backOverhang);
        this.APP.sldFrontOverhang.setValue(obj.frontOverhang);
        // this.APP.dialogEditBay.setValue(obj.listBay);
        setTimeout(() => {
          // Bang - 2022.08.15 -> Lỗi khi open lại thì Bay load về default 4400
          this.APP.dialogEditBay.setValue(obj.listBay);
          this.APP.sldRightOverhang.setValue(obj.rightOverhang);
          this.APP.sldLeftOverhang.setValue(obj.leftOverhang);
          this.APP.sldBuildingHeight.setValueAndHandleEvent(
            obj.buildingHeight,
            true
          );

          if (this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF) {
            this.APP.sldSpan.setValue(obj.span);
          }
        }, 300);
        //----
      }, 500);
    });
    return promise;
  }
  alignWallToPatios(enable) {
    if(HomeComponent.ins.currentBuildingInfo.isOpen) {
      return
    }
    let patiosLength =
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldSpan.currentValue +
      this.APP.sldBackOverhang.currentValue +
      this.APP.sldFrontOverhang.currentValue;
    if (enable && this.APP.sldExistingWidth1.currentValue < 1000) {
      this.APP.sldExistingWidth1.setValue(patiosLength);
      this.APP.sldExistingWidth1.setMin(1000);
    } else if (!enable) {
      this.APP.sldExistingWidth1.setMin(0);
    }
  }
  validateCutout() {
    if(HomeComponent.ins.currentBuildingInfo.isOpen) {
      return
    }
    let cutoutCondition =
      this.APP.sltExistingType.currentValue != 0 &&
      this.APP.patiosManager.cutoutCondition &&
      this.APP.sldExistingWidth1.currentValue > 0 &&
      this.APP.sldExistingLength2.currentValue > 0;

    if (
      cutoutCondition &&
      this.APP.patiosRoofType === PATIOS_ROOF_TYPE.FLAT_ROOF
    ) {
      this.APP.sltCutOut.setDisable(false);
    } else {
      this.APP.sltCutOut.setSelected("0");
      this.APP.sltCutOut.setDisable(true);
    }
  }
  validateRakeCut() {
    let maxSpanLeft =
      this.APP.sldSpan.currentValue + this.APP.sldFrontOverhang.currentValue;
    let maxSpanRight =
      this.APP.sldSpan.currentValue + this.APP.sldFrontOverhang.currentValue;

    //
    if (
      this.APP.sldMultiSpan.currentValue > 0 &&
      this.APP.sldSpan.currentValue
    ) {
      maxSpanLeft =
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldFrontOverhang.currentValue;
      maxSpanRight =
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldFrontOverhang.currentValue;
    }

    if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES) {
      let cond =
        this.APP.sldMultiSpan.currentValue == 0 ||
        (this.APP.sldMultiSpan.currentValue > 0 &&
          this.APP.sldSpan.currentValue <
            this.APP.sldExistingWidth1.currentValue);
      if (
        cond &&
        (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT ||
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH)
      ) {
        maxSpanLeft =
          this.APP.patiosManager.patiosLength -
          this.APP.sldExistingWidth1.currentValue;
      }
      if (
        cond &&
        (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT ||
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH)
      ) {
        maxSpanRight =
          this.APP.patiosManager.patiosLength -
          this.APP.sldExistingWidth1.currentValue;
      }
    }
    //In case of step cut the remaining space at least 500
    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
      maxSpanLeft -= 500;
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      maxSpanRight -= 500;
    }

    let sizeLeft = this.APP.utils.getBeamRakeCutInfo(
      this.APP,
      BUILDING_SIDE.LEFT
    );
    let sizeRight = this.APP.utils.getBeamRakeCutInfo(
      this.APP,
      BUILDING_SIDE.RIGHT
    );

    //Ensure left cut and right cut do not overlap each other
    //And can not be over first bay
    let bayMax =
      this.APP.dialogEditBay.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    let postInfo = null;
    if (this.APP.geometryManager.SUPERIOR_POST.S65x65) {
      postInfo = this.APP.geometryManager.getPost();
    }
    if (this.APP.sldNoOfBay.currentValue == 1) {
      let maxLeft1 = bayMax - sizeRight.l;
      let maxLeft2 =
        (maxLeft1 * this.APP.sldLeftCutVertical.currentValue) / sizeLeft.j;

      let maxRight1 = bayMax - sizeLeft.l;
      let maxRight2 =
        (maxRight1 * this.APP.sldRightCutVertical.currentValue) / sizeRight.j;

      let FO = this.APP.sldFrontOverhang.currentValue;
      let CHL = this.APP.sldLeftCutHorizontal.currentValue;
      let CVL = this.APP.sldLeftCutVertical.currentValue;
      let dMaxleft =
        (FO * Math.sqrt(Math.pow(CHL, 2) + Math.pow(CVL, 2))) / CVL -
        (FO * CHL) / CVL;

      maxLeft2 =
        this.APP.dialogEditBay.listBay[0].value +
        this.APP.sldLeftOverhang.currentValue -
        dMaxleft -
        (postInfo?.width || 0);

      let CHR = this.APP.sldRightCutHorizontal.currentValue;
      let CVR = this.APP.sldRightCutVertical.currentValue;
      let dMaxRight =
        (FO * Math.sqrt(Math.pow(CHR, 2) + Math.pow(CVR, 2))) / CVR -
        (FO * CHR) / CVR;

      maxRight2 =
        this.APP.dialogEditBay.listBay[0].value +
        this.APP.sldRightOverhang.currentValue -
        dMaxRight -
        (postInfo?.width || 0);

      this.APP.sldRightCutHorizontal.setMax(Math.round(maxRight2));
      this.APP.sldLeftCutHorizontal.setMax(Math.round(maxLeft2));

      if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        if (
          bayMax -
            this.APP.sldLeftCutHorizontal.currentValue -
            dMaxRight -
            (postInfo?.width || 0) >
          0
        ) {
          this.APP.sldRightCutHorizontal.setMax(
            Math.round(
              bayMax -
                this.APP.sldLeftCutHorizontal.currentValue -
                dMaxRight -
                (postInfo?.width || 0)
            )
          );
        } else {
          this.APP.sldRightCutHorizontal.setMax(
            this.APP.sldRightCutHorizontal.minValue
          );
          this.APP.sltRightCutType.setSelected("0");
        }
      }
      if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        if (
          bayMax -
            this.APP.sldRightCutHorizontal.currentValue -
            dMaxleft -
            (postInfo?.width || 0) >
          0
        ) {
          this.APP.sldLeftCutHorizontal.setMax(
            Math.round(
              bayMax -
                this.APP.sldRightCutHorizontal.currentValue -
                dMaxleft -
                (postInfo?.width || 0)
            )
          );
        } else {
          this.APP.sldLeftCutHorizontal.setMax(
            this.APP.sldRightCutHorizontal.minValue
          );
          this.APP.sltLeftCutType.setSelected("0");
        }
      }

      if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
        this.APP.sldLeftCutHorizontal.setMax(
          Math.round(bayMax - this.APP.sldRightCutHorizontal.currentValue - 762)
        );
      }
      if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
        this.APP.sldRightCutHorizontal.setMax(
          Math.round(bayMax - this.APP.sldLeftCutHorizontal.currentValue - 762)
        );
      }
    } else {
      let maxBayLeft =
        this.APP.dialogEditBay.listBay[0].value +
        this.APP.sldLeftOverhang.currentValue;
      let maxBayRight =
        this.APP.dialogEditBay.listBay[this.APP.dialogEditBay.noOfBay - 1]
          .value + this.APP.sldRightOverhang.currentValue;
      let FO = this.APP.sldFrontOverhang.currentValue;
      let CHL = this.APP.sldLeftCutHorizontal.currentValue;
      let CVL = this.APP.sldLeftCutVertical.currentValue;
      let dMaxleft =
        (FO * Math.sqrt(Math.pow(CHL, 2) + Math.pow(CVL, 2))) / CVL -
        (FO * CHL) / CVL;

      maxBayLeft =
        this.APP.dialogEditBay.listBay[0].value +
        this.APP.sldLeftOverhang.currentValue -
        dMaxleft -
        (postInfo?.width || 0);

      let CHR = this.APP.sldRightCutHorizontal.currentValue;
      let CVR = this.APP.sldRightCutVertical.currentValue;
      let dMaxRight =
        (FO * Math.sqrt(Math.pow(CHR, 2) + Math.pow(CVR, 2))) / CVR -
        (FO * CHR) / CVR;

      maxBayRight =
        this.APP.dialogEditBay.listBay[this.APP.dialogEditBay.noOfBay - 1]
          .value +
        this.APP.sldRightOverhang.currentValue -
        dMaxRight -
        (postInfo?.width || 0);

      this.APP.sldLeftCutHorizontal.setMax(Math.round(maxBayLeft));
      this.APP.sldRightCutHorizontal.setMax(Math.round(maxBayRight));

      if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
        this.APP.sldLeftCutHorizontal.setMax(
          Math.round(bayMax - this.APP.sldRightCutHorizontal.currentValue - 762)
        );
      }
      if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
        this.APP.sldRightCutHorizontal.setMax(
          Math.round(bayMax - this.APP.sldLeftCutHorizontal.currentValue - 762)
        );
      }
    }

    //Vertical cut can not be over first span
    this.APP.sldLeftCutVertical.setMax(maxSpanLeft);
    this.APP.sldRightCutVertical.setMax(maxSpanRight);

    //If vertical cut is over span size reduce vertical cut
    if (this.APP.sldLeftCutVertical.currentValue > maxSpanLeft) {
      this.APP.sldLeftCutVertical.setValue(maxSpanLeft);
    }
    if (this.APP.sldRightCutVertical.currentValue > maxSpanRight) {
      this.APP.sldRightCutVertical.setValue(maxSpanRight);
    }
    //Only allow rakecut if have post
    if (this.APP.patiosManager) {
      // this.f();
      this.APP.patiosManager.firstLoad = false;
    }
  }

  f() {
    let postLeft = this.APP.patiosManager?.patiosGroup?.children.find(
      (c) =>
        c.userData.type == GEOMETRY_TYPE.SUPERIOR_POST &&
        c.userData.position?.left
    );
    if (!postLeft) {
      this.APP.sltLeftCutType.setSelected("0");
      this.APP.sltLeftCutType.setDisable(true);
    } else {
      this.APP.sltLeftCutType.setDisable(false);
    }

    let postRight = this.APP.patiosManager?.patiosGroup?.children.find(
      (c) =>
        c.userData.type == GEOMETRY_TYPE.SUPERIOR_POST &&
        c.userData.position?.right
    );
    if (!postRight) {
      this.APP.sltRightCutType.setSelected("0");
      this.APP.sltRightCutType.setDisable(true);
    } else {
      this.APP.sltRightCutType.setDisable(false);
    }
  }
}
export function registerEvents(controls, action) {
  controls.forEach((control) => {
    if (HomeComponent.ins[control]) {
      HomeComponent.ins[control].addAction(action);
    }
  });
}
export function unRegisterEvents(controls, action) {
  controls.forEach((control) => {
    if (HomeComponent.ins[control]) {
      HomeComponent.ins[control].removeAction(action);
    }
  });
}
