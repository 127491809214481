import { Group, Mesh, MeshPhongMaterial, Vector3} from "three";
import { GEOMETRY_TYPE } from "../app.config";
import { GeometryInfo } from "../core/models";

export interface UpstandBraketOptions {
    container: Group, 
    geoBraket_1: GeometryInfo, 
    geoBraket_2: GeometryInfo, 
    material: MeshPhongMaterial,
    height: number,
    position: Vector3,
    rotation?: Vector3,
    userData?: {}
}


export default class UpstandBraket extends Group{
    options: UpstandBraketOptions

    static create(options: UpstandBraketOptions){
        return new UpstandBraket(options)
    }

    constructor(options: UpstandBraketOptions){
        super()
        this.options = options;
        let braket1 = new Mesh(this.options.geoBraket_1.geometry, this.options.material);
        braket1.translateZ(this.options.geoBraket_2.height / 4 + this.options.geoBraket_1.width / 2);
        braket1.scale.setY(this.options.height / this.options.geoBraket_1.length);
        this.add(braket1)

        let braket2 = new Mesh(this.options.geoBraket_2.geometry, this.options.material);
        this.add(braket2)

        this.position.set( this.options.position.x, this.options.position.y, this.options.position.z);
        if(this.options.userData){
            this.userData = this.options.userData;
        } else {
            this.userData = { type: GEOMETRY_TYPE.UPSTAND_BRAKET};
        }

        this.options.container.add(this);
    }
}