export const EXISTING_BUILDING_CONFIG = {
  CUTOUT_ALLOW: 1500,
  CUTOUT_DEFFER_TIME_OUT: 50,
  // Offset min from top of existing height to top of patios roof
  BUILDING_HEIGHT_OFFSET: 200,
  ROOF_AND_BASE_OFFSET_X: 10, // Roof và base bị lệch
  ROOF_BASE_SUBTRACT: 60,
  BARGE_ROOF_OFFSET_Y: 20, // Khoảng cách từ roof lên barge
  BARGE_ROOF_OFFSET_X: 5, // Khoảng cách từ roof lên barge
  GUTTER_ROOF_OFFSET_Y: 15, // Khoảng cách từ roof lên barge
  GUTTER_ROOF_OFFSET_Z: 40, // Khoảng cách từ roof lên barge
  EXTRA_BRACKET_HEIGHT: 250,
  EAVE_OFFSET_BACK: 100,
  FIT_FROM_CORNER_TO_CENTER_GUTTER: 100,
  MAXIMUM_LENGTH_PER_BEAM: 9000,
  FIT_FROM_TOP_TO_CUTBEAM: 500,
  FIT_JOINT: 2.5,
  RAFTER_BEAM_SIZE: 160
};

export const MIN_INTERNAL_BEAM_LENGTH = 200

export const RIBSPAN_PANEL_WIDTH = 310.5;

export const RIBSPAN_RECEIVER_CHANNEL_FIT = 5;

export const RIBSPAN_BARGE_FIT = 3;

export const FASCIA_BRACKET_FIT = 185.1875;

export enum RAKECUT_TYPE {
  NONE,
  ANGLE,
  STEP,
}

export enum BUILDING_SIDE {
  NONE,
  LEFT,
  RIGHT,
  BOTH,
}
export enum CUTOUT_ENABLE {
  NO,
  YES,
}
export enum CONNECTION_TYPE {
  EXISTING,
  FLY_OVER,
  BLACK_FLY_OVER,
  FASCIA,
  FREE_STANDING,
  FASCIA_UPSTAND,
  BACK_FASCIA_UPSTAND,
}

export const PANEL_DIRECTION = {
  LEFT_TO_RIGHT: false,
  RIGHT_TO_LEFT: true,
};
export const MOUSE_EVENT = {
  DOWN: "mousedown",
  MOVE: "mousemove",
  UP: "mouseup",
  HOVER: "hover",
  CLICK: "click",
  DRAG_START: "dragstart",
  DRAG_END: "dragend",
  DRAG: "drag",
};

export const OBJECT_TYPE = {
  UI_CONTROL_LEFT_RAKECUT_HORIZONTAL: "UI_CONTROL_LEFT_RAKECUT_HORIZONTAL",
  UI_CONTROL_LEFT_RAKECUT_VERTICAL: "UI_CONTROL_LEFT_RAKECUT_VERTICAL",
  UI_CONTROL_RIGHT_RAKECUT_HORIZONTAL: "UI_CONTROL_RIGHT_RAKECUT_HORIZONTAL",
  UI_CONTROL_RIGHT_RAKECUT_VERTICAL: "UI_CONTROL_RIGHT_RAKECUT_VERTICAL",
  UI_CONTROL_EDIT_WALL_LENGTH1: "UI_CONTROL_EDIT_WALL_LENGTH1",
  UI_CONTROL_EDIT_WALL_LENGTH2: "UI_CONTROL_EDIT_WALL_LENGTH2",
  UI_CONTROL_EDIT_WALL_WIDTH1: "UI_CONTROL_EDIT_WALL_WIDTH1",
  UI_CONTROL_EDIT_WALL_WIDTH2: "UI_CONTROL_EDIT_WALL_WIDTH2",
  UI_CONTROL_WIDTH1: "UI_CONTROL_WIDTH1",
  UI_CONTROL_WIDTH2: "UI_CONTROL_WIDTH2",
  UI_CONTROL_LENGTH2: "UI_CONTROL_LENGTH2",
  HIGHLIGHT_BOX: "HIGHLIGHT_BOX",
  CLICK_CONTROL_CUTOUT: "CLICK_CONTROL_CUTOUT",
  CUT_BEAM: "CUT_BEAM",
  UI_CONTROL_CUTBEAM_CONTAINER: "UI_CONTROL_CUTBEAM_CONTAINER"
};

export const FIT_DIMENSION = 20
export const FIT_DIMENSION_FAR = 1000
export const FIT_DIMENSION_MEDIUM = 500

export const FIT_FLYOVER_BRAKET_ON_ROOF = 200