import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject } from "rxjs";
import { debounceTime } from 'rxjs/operators';
import { MaterialService } from './../../services/material.service';
import { PriceService } from './../../services/price.service';

@Component({
  selector: "dialog-add-bom",
  templateUrl: "./dialog-add-bom.component.html",
  styleUrls: ["./dialog-add-bom.component.scss"],
  providers: [],
})
export class DialogAddBOMComponent implements OnInit {
  @Output("submit")
  _submit: EventEmitter<any> = new EventEmitter<any>();
  public visible = false;
  public title: string = "Additional BOM";
  groups = []
  inputForm: FormGroup;
  group: string = ''
  unit: string = ''
  itemNo: string = ''
  description: string = ''
  optionLists: any[] = [];
  currentOption: any = null
  searchChange$ = new BehaviorSubject('');
  get f() {
    return this.inputForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private materialService: MaterialService,
    private priceService: PriceService,
    private message: NzMessageService,
  ) { }

  ngOnInit() {
    this.inputForm = this.formBuilder.group({
      itemNo: ["", Validators.required],
      group: '',
      dri: '',
      cutBk: '',
      description: '',
      qty: 1,
      length: 1,
    });
    this.searchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .subscribe(this.getOptionList.bind(this))
  }

  ngAfterViewInit() {
    this.materialService.getAll().subscribe(rs => {
      if (rs.statusCode === 200) {
        this.groups = rs.data
      }
    })
  }

  private getOptionList(value: string) {
    if (value.length < 3) {
      this.optionLists = []
      return
    }
    const _value = value.toLowerCase()
    this.priceService.searchByQuery(this.group, _value).subscribe((rs) => {
      if (rs.statusCode === 200) {
        this.optionLists = rs.data;
      }
    })
  }

  fieldSelected(event: any, option: any) {
    if (event.isUserInput && option) {
      this.currentOption = { ...option, description: option.itemDescription };
      this.itemNo = option.itemNo;
      this.unit = option.uom;
    }
  }

  onSearch(e: Event): void {
    const value = (e.target as HTMLInputElement).value;
    this.searchChange$.next(value);
  }
  onGroupChange(value) {
    this.description = null
    this.searchChange$.next('');
  }

  onShow(visible: boolean, data?: any) {
    if (visible) {
      if (!data) {
        this.inputForm.setValue({
          itemNo: '',
          dri: '',
          cutBk: '',
          group: '',
          description: '',
          qty: 1,
          length: 1,
        })
      } else {
        this.inputForm.setValue({
          itemNo: data.itemNo,
          dri: data.dri,
          cutBk: data.cutBk,
          group: '',
          description: data.description,
          qty: 1,
          length: 1,
        })
        this.currentOption = data
        this.searchChange$.next(data.description);
      }
    }
    this.visible = visible;
  }

  onCancel() {
    this.visible = false;
  }
  onSubmit() {
    if (this.inputForm.invalid) {
      this.message.error("Item No. is required");
      return;
    }

    const values = this.inputForm.value
    const _length = values.length;
    if (!_length || _length == 0) {
      this.message.error("Length > 0");
      return;
    }
    const _qty = values.qty;
    if (!_qty || _qty == 0) {
      this.message.error("Qty > 0");
      return;
    }
    const _price = {
      cutBk: values.cutBk,
      description: this.currentOption.description,
      dri: values.dri,
      idx: 0,
      itemNo: this.currentOption.itemNo,
      length: _length || 1,
      length2: _length * 1000,
      price: 0,
      qty: _qty,
      totalUom: 0,
      unitPrice: 0,
      uom: this.currentOption.uom,
    }
    this._submit.emit(_price);
    this.visible = false
  }
}
