<!-- Add cost install modal -->
<nz-modal [(nzVisible)]="isVisible" nzTitle="Additional Cost Install" (nzOnCancel)="onCancel()"
  nzWrapClassName="vertical-center-modal" nzWidth="60%" [nzFooter]="null">
  <div nz-row nzType="flex" nzJustify="start">
    <div nz-col>
      <button class="small facebook ui button" (click)="onNew()">
        <i class="plus circle icon"></i>
        <span>New</span>
      </button>
    </div>
  </div>
  <nz-divider></nz-divider>
  <table style="width: 100%;">
    <div nz-row class="div-row">
      <div nz-col nzSpan="1" class="div-center bold">No.</div>
      <div nz-col nzSpan="16" class="div-center bold">Description</div>
      <div nz-col nzSpan="6" class="div-center bold">Value Ex GST</div>
      <div nz-col nzSpan="1" class="div-center"></div>
    </div>
    <div nz-row class="div-row" *ngFor="let cost of costList">
      <div nz-col nzSpan="1">
        <span>#{{cost.index}}</span>
      </div>
      <div nz-col nzSpan="16" class="margin-right-5">
        <input nz-input [(ngModel)]="cost.description" />
      </div>
      <div nz-col nzSpan="6" class="margin-right-5">
        <nz-input-group nzAddOnBefore="$">
          <input currencyMask nz-input [(ngModel)]="cost.value" />
        </nz-input-group>
        <!-- <nz-input-number class="full-width div-right" [(ngModel)]="cost.value" [nzMin]="0" [nzStep]="1"></nz-input-number> -->
      </div>
      <div nz-col nzSpan="1">
        <button class="tiny red ui button icon" (click)="onDelete(cost.index)">
          <i class="close icon"></i>
        </button>
      </div>
    </div>
  </table>
  <nz-divider></nz-divider>
  <div nz-row nzType="flex" nzJustify="end">
    <div nz-col>
      <button class="small danger ui button" (click)="onCancel()">
        <!-- <i class="close icon"></i> -->
        Close
      </button>
      <button class="small facebook ui button" (click)="onSave()">
        <i class="save icon"></i>
        <span>Save</span>
      </button>
    </div>
  </div>
</nz-modal>