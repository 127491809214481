<nz-select [(ngModel)]="selectedValue" [nzDisabled]="_isDisable">
  <!-- <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
    <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
    <nz-option nzValue="disabled" nzLabel="Disabled" nzDisabled></nz-option> -->
  <nz-option
    *ngFor="let i of items"
    nzValue="{{ i.id }}"
    nzLabel="{{ i.value }}"
    [nzHide]="!isNotSelected(i.id)"
  ></nz-option>
</nz-select>
<!-- <select #selectInput class="ui fluid dropdown" (change)="onValueChanged($event)" [disabled]="_isDisable">
    <option *ngFor="let i of filterItems()" value="{{i.id}}">{{i.value}}</option>
</select> -->
