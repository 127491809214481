import { BoxBufferGeometry, Color, Mesh, MeshLambertMaterial, Object3D } from "three";
import { HIGHLIGHT_BOX_COLOR } from "src/app/app.config";
import { MOUSE_EVENT } from "src/app/app.constants";
import { HomeComponent } from "../containers/home";
import SelectionManager from "../core/selection.manager";
import { UI } from "../core/ui";

export default class HighLightBox extends Mesh {
    container: Object3D
    options: any
    mat: MeshLambertMaterial

    constructor(container, options: {
        min: {x: number, y: number, z: number},
        max: {x: number, y: number, z: number},
        userData: any
    }){
        const geo = new BoxBufferGeometry(100, 120, 100)
        geo.translate(0,50,0)
        const mat = new MeshLambertMaterial({ color: HIGHLIGHT_BOX_COLOR.GREEN, transparent: true, opacity: 0 })
        super(geo, mat)

        const width = options.max.x - options.min.x
        const _length = options.max.z - options.min.z
        const height = Math.abs(options.max.y - options.min.y)

        this.position.set(options.min.x + width/2, options.min.y, options.min.z + _length/2)
        this.scale.set(width/100, height / 120 > 0 ? height / 120 : 1, _length/100)
        

        this.userData = { ...options.userData, type: 'HIGHLIGHT_BOX' }
        container.add(this)

        SelectionManager.ins.addEventListener(MOUSE_EVENT.HOVER, (intersects) => {
            this.onHover(intersects)
        })
        SelectionManager.ins.addEventListener(MOUSE_EVENT.CLICK, (intersects) => {
            this.onClick(intersects)
        })

        this.mat = mat;
        this.container = container
        this.options = options
    }
    
    update(){
        this.position.set(this.options.x, this.options.y, this.options.z)
    }

    onHover(intersects){
        if(intersects.length == 0){
            if(!this.material || (this.material as MeshLambertMaterial).opacity == 0){
                return
            }
        }
        if(intersects.find(o => o.object.uuid === this.uuid)){
            if((this.material as MeshLambertMaterial).opacity == 1){
                return
            }
            (this.material as MeshLambertMaterial).opacity = 1
            HomeComponent.ins.renderStatic()
        }
        else{
            // (this.material as MeshLambertMaterial).color = new Color('green')
            //this.visible = false
            if((this.material as MeshLambertMaterial).opacity == 0){
                return
            }
            
            (this.material as MeshLambertMaterial).opacity = 0
            HomeComponent.ins.renderStatic()
        }
    }
    onClick(intersects){
        if(!UI.enableControl) {
            return
        }
        const obj = intersects.find(o => o.object.uuid === this.uuid)
        if(obj){
            if(obj.object?.userData?.side){
                HomeComponent.ins.dialogRakeCut.show(true, obj.object?.userData?.side)
            } else if (obj.object?.userData?.wallSide){
                HomeComponent.ins.dialogComfirm.show(true, obj.object?.userData?.wallSide)
            }
        }
    }
}