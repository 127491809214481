<nz-modal
  [(nzVisible)]="visible"
  nzTitle="Edit Bay"
  (nzOnCancel)="onCancel()"
  (nzOnOk)="onOK()"
  nzWrapClassName="vertical-center-modal"
  nzWidth="350px"
>
  <table style="width: 100%">
    <div nz-row class="div-row" *ngFor="let bay of listBay; let i = index">
      <div nz-col nzSpan="7">
        <span>Bay #{{ bay.index }}</span>
      </div>
      <div nz-col nzSpan="9">
        <nz-input-number
          [(ngModel)]="bay.value"
          [nzMin]="bayMinValue"
          [nzMax]="bayMaxValue"
          [nzStep]="1"
        >
        </nz-input-number>
      </div>
      <div nz-col nzSpan="8">
        <label
          [disabled]="listBay.length === 1 || i === listBay.length - 1"
          nz-checkbox
          [(ngModel)]="bay.isCut"
          >Cut Beam</label
        >
      </div>
    </div>
  </table>
</nz-modal>
