import { Scene, Mesh, BufferGeometry, Vector3, Object3D, Color, Material, PlaneBufferGeometry, BoxBufferGeometry, CubeTextureLoader } from "three";
import { Util } from "./utils";
import { environment } from 'src/environments/environment';
import { CONFIG as env, GEOMETRY_TYPE } from '../app.config';
import { HomeComponent as AppComponent } from '../containers/home/home.component';
import { GeometryManager } from "./geometry.manager";
import { MaterialManager } from "./material.manager";
import { GeometryInfo } from './models';

export class GroundManager {
    private scene: Scene;
    private APP: AppComponent;
    private material: Material;
    private utils: Util;

    private geometryManager: GeometryManager;

    private geo_ground: BufferGeometry;
    private geo_groundBase: GeometryInfo;

    private totalBaySize: number;

    private isReady = false;


    constructor() {
        this.utils = new Util();
        this.geometryManager = GeometryManager.Instance();
    }

    public init(app: AppComponent): Promise<void> {
        return new Promise((resolve, reject) => {
            this.APP = app;
            this.scene = app.scene;
            this.material = MaterialManager.Instance().GROUND.clone();

            this.APP.sldExistingLength.addAction(this.uiChanged.bind(this));
            this.APP.sldExistingLength2.addAction(this.uiChanged.bind(this));
            this.APP.sldExistingWidth1.addAction(this.uiChanged.bind(this));
            this.APP.sldExistingWidth2.addAction(this.uiChanged.bind(this));
            this.APP.sldSpan.addAction(this.uiChanged.bind(this));
            this.APP.sldMultiSpan.addAction(this.uiChanged.bind(this));
            this.APP.dialogEditBay.addAction(this.uiChanged.bind(this));


            resolve();
        });
    }
    public optimize(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.geo_ground = new PlaneBufferGeometry(200000, 200000);
            this.geo_groundBase = new GeometryInfo();
            this.geo_groundBase.width = 1000;
            this.geo_groundBase.height = 1;
            this.geo_groundBase.length = 1000;
            this.geo_groundBase.geometry = new BoxBufferGeometry(this.geo_groundBase.width, this.geo_groundBase.height, this.geo_groundBase.length);
            this.geo_groundBase.geometry.translate(0,0,this.geo_groundBase.length/2);


            resolve();
        });
    }
    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            //this.scene.remove(...this.scene.children.filter(o => o.userData.type == GEOMETRY_TYPE.GROUND_BASE));

            this.totalBaySize = this.APP.dialogEditBay.listBay.reduce((total, val) => total + val.value, 0);

            this.isReady = true;

            this.addGround();
            this.addBase();

            resolve();
        });
    }

    public addGround() {
        let mesh = new Mesh(this.geo_ground, this.material);
        mesh.userData = { type: "GROUND" };
        mesh.position.y = -100;
        mesh.rotation.x = - Math.PI / 2;

        this.scene.add(mesh);
        //Skybox
        const cubeTextureLoader = new CubeTextureLoader();
        cubeTextureLoader.setPath(environment.modelBaseUrl + '/assets/images/skybox/' );
        const cubeTexture = cubeTextureLoader.load( [
            "cube_f.jpg", "cube_b.jpg",
            "cube_u.jpg", "cube_d.jpg",
            "cube_l.jpg", "cube_r.jpg"
        ] );
        this.scene.background = cubeTexture;
    }

    private addBase() {
        //return new Promise((resolve, reject) => {
        //    if(!this.isReady){
        //        resolve();
        //        return;
        //    }

        //    this.APP.patiosManager.patiosGroup.remove(...this.APP.patiosManager.patiosGroup.children.filter(o => o.userData.type == GEOMETRY_TYPE.GROUND_BASE));
        //    this.totalBaySize = this.APP.dialogEditBay.listBay.reduce((total, val) => total + val.value, 0);

        //    let base = new Mesh(this.geo_groundBase.geometry, MaterialManager.Instance().BASE);
        //    base.userData = { type: GEOMETRY_TYPE.GROUND_BASE };

        //    let width =  this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue;
        //    let length =  this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + this.APP.sldFrontOverhang.currentValue + this.APP.sldBackOverhang.currentValue;

        //    let extraWidth = 0;

        //    let offsetZ = this.APP.sldExistingWidth1.currentValue/2;

        //    base.position.set(0,0,-offsetZ);
        //    base.scale.set((width + extraWidth) / this.geo_groundBase.width, 1, (length + extraWidth) / this.geo_groundBase.length);

        //    this.APP.patiosManager.patiosGroup.add(base);


            
        //    resolve();
        //});
    }

    private uiChanged(pre: number, cur: number) {
        this.addBase();
    }
}
