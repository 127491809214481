<div style="margin-top: 10px">
  <nz-table
    #tBOM
    [nzData]="boms"
    [nzShowPagination]="false"
    [nzPageSize]="200"
    nzSize="small"
    [nzScroll]="{ y: '350px' }"
  >
    <thead>
      <tr>
        <th nzWidth="45px">Idx.</th>
        <th nzWidth="120px">Item No.</th>
        <th nzWidth="70px">Qty</th>
        <th nzWidth="70px">Length</th>
        <th nzWidth="90px">Cut BK</th>
        <th nzWidth="60px">Dir</th>
        <th>Description</th>
        <th nzWidth="70px">UoM</th>
        <th nzWidth="70px">Copy</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of tBOM.data">
        <td>{{ data.idx }}</td>
        <td>{{ data.itemNo }}</td>
        <td nzAlign="right">{{ data.qty }}</td>
        <td>{{ data.length }}</td>
        <td>{{ data.cutBk }}</td>
        <td>{{ data.dri }}</td>
        <td>{{ data.description }}</td>
        <td>{{ data.uom }}</td>
        <td>
          <button
            class="ui compact icon button mini facebook"
            (click)="onCopyBOM(data)"
          >
            <i class="copy alternate outline icon"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <nz-divider nzText="Additional BOM"></nz-divider>
  <!-- Extras -->
  <div nz-row nzType="flex" nzJustify="space-between">
    <button class="small ui button facebook" (click)="onNew()">
      <i class="plus circle icon"></i>
      Add
    </button>
  </div>
  <nz-table
    #tAddBOM
    [nzData]="addBOMs"
    [nzShowPagination]="false"
    [nzPageSize]="100"
    nzSize="small"
    [nzScroll]="{ y: '200px' }"
    style="margin-top: 5px"
  >
    <thead>
      <tr>
        <th nzWidth="45px">Idx.</th>
        <th nzWidth="120px">Item No.</th>
        <th nzWidth="70px">Qty</th>
        <th nzWidth="70px">Length</th>
        <th>Description</th>
        <th nzWidth="90px">Cut BK</th>
        <th nzWidth="60px">Dri</th>
        <th nzWidth="70px">UoM</th>
        <th nzWidth="70px">Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of tAddBOM.data">
        <td>{{ data.idx }}</td>
        <td>{{ data.itemNo }}</td>
        <td nzAlign="right">{{ data.qty }}</td>
        <td>{{ data.length }}</td>
        <td>{{ data.description }}</td>
        <td>{{ data.cutBk }}</td>
        <td>{{ data.dri }}</td>
        <td>{{ data.uom }}</td>
        <td>
          <button
            class="ui compact icon button red mini"
            (click)="onDeleteBOM(data)"
          >
            <i class="trash alternate outline icon"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <dialog-add-bom #dialogAddBOM (submit)="onAddBOM($event)"></dialog-add-bom>
</div>
