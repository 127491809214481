import {
  BoxBufferGeometry,
  BufferGeometry,
  Color,
  Geometry,
  Group,
  LineSegments,
  Material,
  Matrix4,
  Mesh,
  MeshLambertMaterial,
  Object3D,
  Plane,
  Vector3,
} from "three";
import { GEOMETRY_CATEGORY, GEOMETRY_TYPE } from "src/app/app.config";
import {
  BUILDING_SIDE,
  CUTOUT_ENABLE,
  EXISTING_BUILDING_CONFIG as CONST,
  EXISTING_BUILDING_CONFIG,
  PANEL_DIRECTION,
  RAKECUT_TYPE,
  RIBSPAN_PANEL_WIDTH,
  RIBSPAN_RECEIVER_CHANNEL_FIT,
  RIBSPAN_BARGE_FIT,
  CONNECTION_TYPE,
} from "src/app/app.constants";
import { Util } from "../utils";
// import { AppComponent } from '../../app.component';
import HighLightBox from "src/app-ribspan/models/HighlightBox";
import { CSG } from "three-csg-ts";
import { FasciaManager } from ".";
import { HomeComponent as AppComponent } from "../../containers/home/home.component";
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import {
  GeometryInfo,
  LineType,
  Print2DView,
  Printing2DGeometry,
  Printing2DLine,
  ViewType,
} from "src/app/core/models";
import { UI } from "../ui";

export class RoofManager {
  private scene: Group;
  private APP: AppComponent;
  private MANAGER: FasciaManager;
  private material: Material;
  private utils: Util;

  private geometryManager: GeometryManager;

  private roofInfo: GeometryInfo;
  private zFlashingGeo: GeometryInfo;
  private zFlashingRakecutRightGeo: GeometryInfo;
  private bargeLeftInfo: GeometryInfo;
  private bargeRightInfo: GeometryInfo;
  private bargeFrontInfo: GeometryInfo;
  private bargeBackInfo: GeometryInfo;
  private geoReceiveChannelLeftInfo: GeometryInfo;
  private geoReceiveChannelRightInfo: GeometryInfo;
  private geo_bargeCutoutLeftHor: GeometryInfo;
  private geo_bargeCutoutRightHor: GeometryInfo;
  private geo_bargeRakecutLeft: GeometryInfo;
  private geo_bargeRakecutRight: GeometryInfo;
  private receiverCutoutLeftHor: GeometryInfo;
  private receiverCutoutLeftVer: GeometryInfo;
  private receiverCutoutRightHor: GeometryInfo;
  private receiverCutoutRightVer: GeometryInfo;
  private gutterInfo: GeometryInfo;
  private gutterRakecutLeftInfo: GeometryInfo;
  private gutterRakecutRightInfo: GeometryInfo;
  //private flashingInfo: GeometryInfo;
  private geo_receiverChanel: GeometryInfo;
  private geo_gutterCap: GeometryInfo;
  public geoBraket_1: GeometryInfo;
  public geoBraket_2: GeometryInfo;
  private geoBeam: GeometryInfo;
  private geoBeamEndCap: GeometryInfo;
  private eventHandleId: any;
  // Var
  private roofLength: number;
  private roofLengthPlus: number;
  private roofWidth: number;
  //private existingTypeLeftX: number;
  //private existingTypeRightX: number;
  private existingType: number;
  private totalBaySize: number;
  private objectSizeChangedHandle: any;
  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;

  private mesh_CutoutLeft: Mesh;
  private csg_cutoutLeft: CSG;
  private mesh_CutoutRight: Mesh;
  private csg_cutoutRight: CSG;
  private geo_cutoutLeft: BoxBufferGeometry;
  private geo_cutoutRight: BoxBufferGeometry;

  private geo_rakecutLeft: BoxBufferGeometry;
  private geo_rakecutRight: BoxBufferGeometry;
  private mesh_rakecutLeft: Mesh;
  private mesh_rakecutRight: Mesh;
  private csg_rakecutLeft: CSG;
  private csg_rakecutRight: CSG;

  private geoCutGutter: BoxBufferGeometry;
  private meshCutGutterLeft1: Mesh;
  private csgCutGutterLeft1: CSG;
  private meshCutGutterLeft2: Mesh;
  private csgCutGutterLeft2: CSG;

  private meshCutGutterRight1: Mesh;
  private csgCutGutterRight1: CSG;
  private meshCutGutterRight2: Mesh;
  private csgCutGutterRight2: CSG;

  private roofGroup: Group;
  private cutoutExistingBothLength: number;

  private offsetBraketZ: number = 0;

  public shouldSnapRoofToExistingWhenCutout: boolean = false;

  constructor(app: AppComponent, fasciaManager: FasciaManager) {
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.APP = app;
    this.MANAGER = fasciaManager;
    this.scene = new Group();
    fasciaManager.patiosGroup.add(this.scene);
    this.material = MaterialManager.Instance().ROOF;
    this.registerEvent();
  }
  public destroy(): void {
    this.unregisterEvent();
  }
  public uiChanged(preVal: number, curVal: number): void {
    this.load();
  }
  private objectSizeChanged(preVal: number, curVal: number) {
    this.optimize().then(() => {
      this.load();
    });
  }
  public onColorChanged() {
    if(this.utils && this.roofInfo.geometry){
      this.utils.changeGeoColor(this.roofInfo.geometry, new Vector3(0, 1, 0), UI.roofColor.toString());
    }
  }
  private registerEvent(): void {
    this.eventHandleId = this.onColorChanged.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sltColourRoof
    ];
    this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));
    this.controlsToRegisterEvent2 = [
      this.APP.sltRoofSheetingType,
      this.APP.sltRoofThickness,
      this.APP.sltBargeType,
      this.APP.sltDripBarge,
      this.APP.sltGutterType,
      this.APP.sltBeamSize,
      this.APP.sltHouseBeamSize,
    ];
    this.controlsToRegisterEvent2.forEach((c) =>
      c.addAction(this.objectSizeChangedHandle)
    );
  }
  private unregisterEvent(): void {
    this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach((c) =>
      c.removeAction(this.objectSizeChangedHandle)
    );
    this.controlsToRegisterEvent2 = undefined;
  }
  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.roofInfo = this.geometryManager.getRoofRibspanPanel();
      this.roofInfo.geometry
        .translate(-this.roofInfo.width / 2, this.roofInfo.height / 2, -this.roofInfo.length / 2);
      this.roofInfo.geometry.rotateY(Math.PI)
      this.utils.changeGeoColor(this.roofInfo.geometry, new Vector3(0, 1, 0), UI.roofColor.toString());

      this.zFlashingGeo = this.geometryManager.getZFlashing();
      this.zFlashingRakecutRightGeo = this.geometryManager.getZFlashing();
      this.zFlashingRakecutRightGeo.geometry.translate(
        -this.zFlashingRakecutRightGeo.length,
        0,
        0
      );

      // Barge capping
      this.bargeLeftInfo = this.geometryManager.getRibspanBarge();
      this.bargeLeftInfo.geometry
        .rotateY(Math.PI)
        .translate(
          this.bargeLeftInfo.width / 2 - 3,
          this.bargeLeftInfo.height / 2 - RIBSPAN_BARGE_FIT,
          this.bargeLeftInfo.length / 2
        );

      this.geoReceiveChannelLeftInfo = this.geometryManager.getRibspanReceiverChannel();
      this.geoReceiveChannelLeftInfo.geometry
        .rotateY(Math.PI)
        .translate(
          this.geoReceiveChannelLeftInfo.width / 2,
          this.geoReceiveChannelLeftInfo.height / 2- RIBSPAN_BARGE_FIT,
          this.geoReceiveChannelLeftInfo.length / 2
        );

      this.bargeRightInfo = this.geometryManager.getRibspanBarge();
      this.bargeRightInfo.geometry.translate(
        -this.bargeRightInfo.width / 2 + 3,
        this.bargeRightInfo.height / 2 - RIBSPAN_BARGE_FIT,
        this.bargeRightInfo.length / 2
      );

      this.geoReceiveChannelRightInfo = this.geometryManager.getRibspanReceiverChannel();
      this.geoReceiveChannelRightInfo.geometry
        .translate(
          - this.geoReceiveChannelRightInfo.width / 2,
          this.geoReceiveChannelRightInfo.height / 2 - RIBSPAN_BARGE_FIT,
          this.geoReceiveChannelRightInfo.length / 2
        );

      //Barge cutout left
      this.receiverCutoutLeftHor = this.geometryManager.getRibspanReceiverChannel();
      this.receiverCutoutLeftHor.geometry
        .rotateY(Math.PI / 2)
        .translate(
          -this.receiverCutoutLeftHor.length / 2,
          this.receiverCutoutLeftHor.height / 2 - RIBSPAN_RECEIVER_CHANNEL_FIT,
          this.receiverCutoutLeftHor.width / 2
        );

      //Barge cutout right
      this.receiverCutoutRightHor = this.geometryManager.getRibspanReceiverChannel();
      this.receiverCutoutRightHor.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.receiverCutoutLeftHor.length / 2,
          this.receiverCutoutLeftHor.height / 2 - RIBSPAN_RECEIVER_CHANNEL_FIT,
          this.receiverCutoutLeftHor.width / 2
        );

      //Vertical Barge cutout left
      this.receiverCutoutLeftVer = this.geometryManager.getRibspanReceiverChannel();
      this.receiverCutoutLeftVer.geometry
        .rotateY(Math.PI)
        .translate(
          this.receiverCutoutLeftVer.width / 2,
          this.receiverCutoutLeftVer.height / 2 - RIBSPAN_RECEIVER_CHANNEL_FIT,
          this.receiverCutoutLeftVer.length / 2
        );

      //Vertical cutout right
      this.receiverCutoutRightVer = this.geometryManager.getRibspanReceiverChannel();
      this.receiverCutoutRightVer.geometry.translate(
        -this.receiverCutoutRightVer.width / 2,
        this.receiverCutoutRightVer.height / 2 - RIBSPAN_RECEIVER_CHANNEL_FIT,
        this.receiverCutoutRightVer.length / 2
      );

      //Receiver chanel - barge caping back
      this.geo_receiverChanel = this.geometryManager.getRibspanReceiverChannel();
      this.geo_receiverChanel.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_receiverChanel.length / 2,
          this.geo_receiverChanel.height / 2 - RIBSPAN_RECEIVER_CHANNEL_FIT,
          this.geo_receiverChanel.width / 2
        );

      // Gutter
      this.gutterInfo = this.geometryManager.getGutter();
      this.gutterInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(this.gutterInfo.length / 2, -20, this.gutterInfo.width / 2);

      this.gutterRakecutLeftInfo = this.geometryManager.getGutter();
      this.gutterRakecutLeftInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(
          this.gutterRakecutLeftInfo.length / 2,
          -20,
          this.gutterRakecutLeftInfo.width / 2
        );

      this.gutterRakecutRightInfo = this.geometryManager.getGutter();
      this.gutterRakecutRightInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(
          -this.gutterRakecutRightInfo.length / 2,
          -20,
          this.gutterRakecutRightInfo.width / 2
        );

      //Gutter cap
      this.geo_gutterCap = this.geometryManager.getGuterEndCap();
      this.geo_gutterCap.geometry
        .rotateY(-Math.PI / 2)
        .translate(0, -20, this.geo_gutterCap.length / 2);

      this.geo_cutoutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutLeft.translate(-500, 0, -500);

      this.geo_cutoutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutRight.translate(500, 0, -500);

      this.geo_rakecutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutLeft.translate(-500, 500, 500);
      this.geo_rakecutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutRight.translate(500, 500, 500);

      this.geoCutGutter = new BoxBufferGeometry(800, 10000, 500);
      this.geoCutGutter.translate(0, 0, -250);

      this.geoBraket_1 = this.geometryManager.getUpstandBraket(1);
      this.geoBraket_1.geometry.rotateX(Math.PI / 2);
      this.geoBraket_1.geometry.translate(0, this.geoBraket_1.length / 2, 0);

      this.geoBraket_2 = this.geometryManager.getUpstandBraket(2);
      this.geoBraket_2.geometry.rotateY(Math.PI);
      this.geoBraket_2.geometry.rotateX(Math.PI / 2);
      this.geoBraket_2.geometry.translate(
        0,
        this.geoBraket_2.length / 2,
        -this.geoBraket_2.height / 4
      );

      this.geoBeam = this.geometryManager.getBeam();
      this.geoBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(0, this.geoBeam.height / 2, 0);

      this.geoBeamEndCap = this.geometryManager.getBeamEndCap();
      this.geoBeamEndCap.geometry
        .translate(
          0,
          this.geoBeamEndCap.height / 2,
          this.geoBeamEndCap.length / 2
        )
        .rotateY(Math.PI / 2);

      this.bargeFrontInfo = this.geometryManager.getRibspanBarge();
      this.bargeFrontInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(
          this.bargeFrontInfo.length / 2,
          this.bargeFrontInfo.height / 2 - RIBSPAN_BARGE_FIT,
          -this.bargeFrontInfo.width / 2
        );

      this.bargeBackInfo = this.geometryManager.getRibspanBarge();
      this.bargeBackInfo.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.bargeBackInfo.length / 2,
          this.bargeBackInfo.height / 2 - RIBSPAN_BARGE_FIT,
          this.bargeBackInfo.width / 2 - 3
        );

      this.geo_bargeRakecutLeft = this.geometryManager.getRibspanBarge();
      this.geo_bargeRakecutLeft.geometry
        .rotateY(Math.PI)
        .translate(
          this.geo_bargeRakecutLeft.width / 2,
          this.geo_bargeRakecutLeft.height / 2 - RIBSPAN_BARGE_FIT,
          -this.geo_bargeRakecutLeft.length / 2
        );

      this.geo_bargeRakecutRight = this.geometryManager.getRibspanBarge();
      this.geo_bargeRakecutRight.geometry.translate(
        -this.geo_bargeRakecutRight.width / 2,
        this.geo_bargeRakecutRight.height / 2 - RIBSPAN_BARGE_FIT,
        -this.geo_bargeRakecutRight.length / 2
      );

      this.geo_bargeRakecutLeft = this.geometryManager.getRibspanBarge();
      this.geo_bargeRakecutLeft.geometry
        .rotateY(Math.PI)
        .translate(
          this.geo_bargeRakecutLeft.width / 2,
          this.geo_bargeRakecutLeft.height / 2 - RIBSPAN_BARGE_FIT,
          -this.geo_bargeRakecutLeft.length / 2
        );

      this.geo_bargeRakecutRight = this.geometryManager.getRibspanBarge();
      this.geo_bargeRakecutRight.geometry.translate(
        -this.geo_bargeRakecutRight.width / 2,
        this.geo_bargeRakecutRight.height / 2 - RIBSPAN_BARGE_FIT,
        -this.geo_bargeRakecutRight.length / 2
      );

      //Barge cutout left
      this.geo_bargeCutoutLeftHor = this.geometryManager.getRibspanBarge();
      this.geo_bargeCutoutLeftHor.geometry
        .rotateY(Math.PI / 2)
        .translate(
          -this.geo_bargeCutoutLeftHor.length / 2,
          this.geo_bargeCutoutLeftHor.height / 2 - RIBSPAN_BARGE_FIT,
          this.geo_bargeCutoutLeftHor.width / 2
        );

      //Barge cutout right
      this.geo_bargeCutoutRightHor = this.geometryManager.getRibspanBarge();
      this.geo_bargeCutoutRightHor.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_bargeCutoutRightHor.length / 2,
          this.geo_bargeCutoutRightHor.height / 2 - RIBSPAN_BARGE_FIT,
          this.geo_bargeCutoutRightHor.width / 2
        );

      resolve();
    });
  }
  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.ROOF_PANEL
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BASE
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.GUTTER_PATIOS
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PATIOS
        )
      );
      this.APP.scene.remove(
        ...this.APP.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SHEET_OUTLINE
        )
      );
      //test
      this.APP.scene.remove(
        ...this.APP.scene.children.filter((x) => x.userData.type == "RAKECUT")
      );

      this.totalBaySize = this.APP.dialogEditBay.listBay.reduce(
        (total, val) => total + val.value,
        0
      );
      this.roofLength =
        this.APP.sldSpan.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldFrontOverhang.currentValue +
        this.APP.sldBackOverhang.currentValue;
      const _roofLength =
        Math.pow(this.roofLength, 2) +
        Math.pow(this.getHeightByRoofPitch({ isMultiSpan: true }), 2);
      this.roofLengthPlus = Math.sqrt(_roofLength); // this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + this.APP.sldFrontOverhang.currentValue;
      this.roofWidth =
        this.totalBaySize +
        this.APP.sldLeftOverhang.currentValue +
        this.APP.sldRightOverhang.currentValue;
      this.cutoutExistingBothLength =
        (this.totalBaySize -
          (this.APP.sldExistingLength.currentValue +
            this.APP.existingWallManager.geo_existingWallW1.width * 2)) /
        2;

      this.shouldSnapRoofToExistingWhenCutout = AppComponent.ins.uiManager.calcShouldSnapRoofToExistingWhenCutout()

      this.offsetBraketZ = 0;
      if (+UI.upstandBraketType !== 0) {
        this.offsetBraketZ = this.geoBraket_2.height / 4;
      }
      this.addRoofGroup();
      this.addCutout();
      this.addAngelRakeCut();
      this.addStepRakecut();

      if (UI.rakeCutLeftType == 0) {
        new HighLightBox(this.roofGroup, {
          min: {
            x: -(UI.totalBayLength / 2 + UI.overhangLeft),
            y: 0,
            z: UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront,
          },
          max: {
            x: -(UI.totalBayLength / 2 + UI.overhangLeft) + 300,
            y: 0,
            z:
              UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront - 300,
          },
          userData: { side: BUILDING_SIDE.LEFT },
        });
      }

      if (UI.rakeCutRightType == 0) {
        new HighLightBox(this.roofGroup, {
          min: {
            x: UI.totalBayLength / 2 + UI.overhangRight - 300,
            y: 0,
            z: UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront,
          },
          max: {
            x: UI.totalBayLength / 2 + UI.overhangRight,
            y: 0,
            z:
              UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront - 300,
          },
          userData: { side: BUILDING_SIDE.RIGHT },
        });
      }

      this.existingType = +this.APP.sltExistingType.currentValue;

      let offsetXR =
        this.totalBaySize / 2 +
        this.MANAGER.patiosGroup.position.x +
        this.APP.sldRightOverhang.currentValue;
      let offsetLeft =
        this.totalBaySize / 2 +
        this.APP.sldLeftOverhang.currentValue -
        this.MANAGER.patiosGroup.position.x;
      let clipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
      const clipingPlane = new Plane(new Vector3(-1, 0, 0), offsetXR); // (this.APP.sldBaySize.currentValue / 2) + this.APP.sldRightOverhang.currentValue );
      this.material.clippingPlanes = [clipingPlaneLeft, clipingPlane];
      MaterialManager.Instance().ROOF_BASE.clippingPlanes = [
        clipingPlaneLeft,
        clipingPlane,
      ];
      const roofPanelCount = Math.ceil(this.roofWidth / RIBSPAN_PANEL_WIDTH);
      let offsetXL = -(
        this.totalBaySize / 2 +
        this.APP.sldLeftOverhang.currentValue
      );

      //panel direction
      let panelDirectionOfset = 1;
      if (
        this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.RIGHT_TO_LEFT
      ) {
        offsetXL =
          this.totalBaySize / 2 +
          this.APP.sldRightOverhang.currentValue -
          RIBSPAN_PANEL_WIDTH;
        panelDirectionOfset = -1;
      } else if (
        this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.LEFT_TO_RIGHT
      ) {
        offsetXL = -(
          this.totalBaySize / 2 +
          this.APP.sldLeftOverhang.currentValue
        );
      }

      if (
        this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT &&
        this.MANAGER.cutoutCondition
      ) {
        let totalPanelLength = 0;
        let cuted = true;

        for (let index = 0; index < roofPanelCount; index++) {
          totalPanelLength += RIBSPAN_PANEL_WIDTH;
          let isOver =
            totalPanelLength < this.APP.sldExistingLength2.currentValue;

          this.addRoof(offsetXL, [this.csg_cutoutLeft]);
          cuted = isOver;

          offsetXL += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      } else if (
        this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT &&
        this.MANAGER.cutoutCondition
      ) {
        let totalPanelLength = 0;
        let cuted = false;
        for (let index = 0; index < roofPanelCount; index++) {
          totalPanelLength += RIBSPAN_PANEL_WIDTH;
          let isOver =
            totalPanelLength >=
            this.roofWidth -
              this.APP.sldExistingLength2.currentValue +
              RIBSPAN_PANEL_WIDTH;
          cuted =
            totalPanelLength >=
              this.roofWidth - this.APP.sldExistingLength2.currentValue &&
            !isOver;

          this.addRoof(offsetXL, [this.csg_cutoutRight]);
          offsetXL += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      } else if (
        this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH &&
        this.MANAGER.cutoutCondition
      ) {
        let totalPanelLength = 0;
        let cuted = false;
        let cuted2 = false;
        for (let index = 0; index < roofPanelCount; index++) {
          totalPanelLength += RIBSPAN_PANEL_WIDTH;
          let isOver =
            totalPanelLength < this.APP.sldExistingLength2.currentValue;
          let isOver2 =
            totalPanelLength >=
            this.roofWidth -
              this.APP.sldExistingLength2.currentValue +
              RIBSPAN_PANEL_WIDTH;
          let flag =
            totalPanelLength >=
            this.roofWidth - this.APP.sldExistingLength2.currentValue;

          this.addRoof(offsetXL, [this.csg_cutoutLeft, this.csg_cutoutRight]);

          offsetXL += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      } else {
        for (let index = 0; index < roofPanelCount; index++) {
          this.addRoof(offsetXL, []);
          offsetXL += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      }

      this.addBarge();
      this.addBargeStepRakecut();
      this.addBargeAngelRakecut();
      this.addGutter();
      this.addGutterAngelRakeCut();
      this.addGutterStepRakeCut();
      this.showPanelOutline();

      this.scene.visible = UI.showRoof

      resolve();
    });
  }

  private addCutout() {
    if (this.APP.sltCutOut.currentValue == 1) {
      this.APP.scene.remove(
        ...this.APP.scene.children.filter((x) => x.userData.type == "CUTOUT")
      );

      let offsetX =
        this.APP.sldExistingLength.currentValue / 2 -
        this.APP.sldEaveWidth.currentValue;
      let offsetY = 0;
      let offsetZ =
        this.APP.sldExistingWidth1.currentValue / 2 +
        UI.eaveWidth -
        UI.overhangBack +
        this.offsetBraketZ;
      
      // To fascia, move roof snap to existing wall
      if(this.shouldSnapRoofToExistingWhenCutout) {
        offsetZ = UI.existingWidth1 / 2 - UI.overhangBack + this.offsetBraketZ;
      }

      // Apply pitch to offsetZ, because we cut panel before rotate roof
      offsetZ = offsetZ / this.utils.cos(UI.patiosPitch)

      //extra length to prevent lack when cut
      let extra = 5000;

      let scaleX = (this.APP.sldExistingLength2.currentValue + extra) / 1000;
      if (scaleX == 0) scaleX = 1;
      let scaleY = (this.APP.sldExistingWallHeight.currentValue + extra) / 1000;
      let scaleZ =
        (this.APP.sldExistingWidth1.currentValue + UI.overhangBack + extra) /
        1000;
      if (scaleZ == 0) scaleZ = 1;

      if (
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT ||
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH
      ) {
        this.mesh_CutoutLeft = new Mesh(
          this.geo_cutoutLeft,
          new MeshLambertMaterial({
            color: "red",
            transparent: true,
            opacity: 0.5,
          })
        );
        this.mesh_CutoutLeft.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: "CUTOUT",
        };

        this.mesh_CutoutLeft.position.set(
          -offsetX - this.APP.sldLeftOverhang.currentValue,
          offsetY,
          offsetZ
        );
        this.mesh_CutoutLeft.scale.set(scaleX, scaleY, scaleZ);

        //this.APP.scene.add(this.mesh_CutoutLeft);

        this.mesh_CutoutLeft.updateMatrix();
        this.csg_cutoutLeft = CSG.fromMesh(this.mesh_CutoutLeft);
      }
      if (
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT ||
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH
      ) {
        this.mesh_CutoutRight = new Mesh(
          this.geo_cutoutRight,
          new MeshLambertMaterial({
            color: "red",
            transparent: true,
            opacity: 0.5,
          })
        );
        this.mesh_CutoutRight.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: "CUTOUT",
        };

        this.mesh_CutoutRight.position.set(
          offsetX + this.APP.sldRightOverhang.currentValue,
          offsetY,
          offsetZ
        );
        this.mesh_CutoutRight.scale.set(scaleX, scaleY, scaleZ);

        //this.APP.scene.add(this.mesh_CutoutRight);

        this.mesh_CutoutRight.updateMatrix();
        this.csg_cutoutRight = CSG.fromMesh(this.mesh_CutoutRight);
      }
    }
  }
  private addAngelRakeCut() {
    let offsetXL =
      -this.totalBaySize / 2 - this.APP.sldLeftOverhang.currentValue;
    let offsetXR =
      this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;
    let offsetY = -100;

    let offsetZ =
      this.MANAGER.patiosLength +
      this.APP.sldEaveWidth.currentValue -
      this.APP.sldExistingWidth1.currentValue / 2 +
      this.offsetBraketZ;
    const _offsetZ =
      (this.MANAGER.patiosLength +
        UI.overhangBack -
        //- this.APP.sldExistingWidth1.currentValue/2
        CONST.GUTTER_ROOF_OFFSET_Z) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);

    let hypotenuse = Math.sqrt(
      Math.pow(this.MANAGER.patiosLength, 2) +
        Math.pow(this.MANAGER.patiosWidth, 2)
    );
    let scaleLength = hypotenuse / 1000;
    let scaleWidth =
      (this.MANAGER.patiosLength * this.MANAGER.patiosWidth) /
      hypotenuse /
      1000;

    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      let angle = Math.atan(
        this.APP.sldLeftCutHorizontal.currentValue /
          this.APP.sldLeftCutVertical.currentValue
      );
      let _rotY = Math.atan(
        this.APP.sldLeftCutVertical.currentValue /
          this.APP.sldLeftCutHorizontal.currentValue
      );
      let _angle = (Math.PI - _rotY) / 2;

      this.mesh_rakecutLeft = new Mesh(
        this.geo_rakecutLeft,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutLeft.position.set(
        offsetXL + this.MANAGER.patiosGroup.position.x,
        offsetY,
        offsetZ - this.APP.sldLeftCutVertical.currentValue
      );
      this.mesh_rakecutLeft.scale.set(scaleWidth, 5, scaleLength);
      this.mesh_rakecutLeft.rotateY(angle);
      this.mesh_rakecutLeft.updateMatrix();
      this.csg_rakecutLeft = CSG.fromMesh(this.mesh_rakecutLeft);
      //this.APP.scene.add(this.mesh_rakecutLeft);

      //cut gutter csg
      let scaleZ = (this.APP.dialogEditBay.totalBaySize + 200) / 500;
      let scaleX = (this.APP.dialogEditBay.totalBaySize + 200) / 500;
      this.meshCutGutterLeft1 = new Mesh(
        this.geoCutGutter,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.meshCutGutterLeft1.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "CUTGUTTER",
      };
      this.meshCutGutterLeft1.position.set(
        offsetXL + this.APP.sldLeftCutHorizontal.currentValue,
        0,
        _offsetZ
      );
      this.meshCutGutterLeft1.scale.set(scaleX, 1, scaleZ);
      this.meshCutGutterLeft1.rotation.set(0, _angle, 0);
      this.meshCutGutterLeft1.updateMatrix();
      this.csgCutGutterLeft1 = CSG.fromMesh(this.meshCutGutterLeft1);
      //this.APP.scene.add(this.meshCutGutterLeft1);

      this.meshCutGutterLeft2 = new Mesh(
        this.geoCutGutter,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.meshCutGutterLeft2.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "CUTGUTTER",
      };
      this.meshCutGutterLeft2.position.set(
        offsetXL + this.APP.sldLeftCutHorizontal.currentValue,
        0,
        _offsetZ
      );
      this.meshCutGutterLeft2.scale.set(scaleX, 1, scaleZ);
      this.meshCutGutterLeft2.rotation.set(0, _angle + Math.PI, 0);
      this.meshCutGutterLeft2.updateMatrix();
      this.csgCutGutterLeft2 = CSG.fromMesh(this.meshCutGutterLeft2);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      let angle = Math.atan(
        this.APP.sldRightCutHorizontal.currentValue /
          this.APP.sldRightCutVertical.currentValue
      );

      let _rotY = Math.atan(
        this.APP.sldRightCutVertical.currentValue /
          this.APP.sldRightCutHorizontal.currentValue
      );
      let _angle = (Math.PI - _rotY) / 2;
      this.mesh_rakecutRight = new Mesh(
        this.geo_rakecutRight,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutRight.position.set(
        offsetXR + this.MANAGER.patiosGroup.position.x,
        offsetY,
        offsetZ - this.APP.sldRightCutVertical.currentValue
      );
      this.mesh_rakecutRight.scale.set(scaleWidth, 5, scaleLength);
      this.mesh_rakecutRight.rotateY(-angle);

      //this.APP.scene.add(this.mesh_rakecutRight);

      this.mesh_rakecutRight.updateMatrix();
      this.csg_rakecutRight = CSG.fromMesh(this.mesh_rakecutRight);

      //cut gutter
      let scaleZ = (this.APP.dialogEditBay.totalBaySize + 200) / 500;
      let scaleX = (this.APP.dialogEditBay.totalBaySize + 200) / 500;
      this.meshCutGutterRight1 = new Mesh(
        this.geoCutGutter,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.meshCutGutterRight1.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "CUTGUTTER",
      };
      this.meshCutGutterRight1.position.set(
        offsetXR - this.APP.sldRightCutHorizontal.currentValue,
        0,
        _offsetZ
      );
      this.meshCutGutterRight1.rotation.set(0, -_angle, 0);
      this.meshCutGutterRight1.scale.set(scaleX, 1, scaleZ);
      this.meshCutGutterRight1.updateMatrix();
      this.csgCutGutterRight1 = CSG.fromMesh(this.meshCutGutterRight1);

      this.meshCutGutterRight2 = new Mesh(
        this.geoCutGutter,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.meshCutGutterRight2.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "CUTGUTTER",
      };
      this.meshCutGutterRight2.position.set(
        offsetXR - this.APP.sldRightCutHorizontal.currentValue,
        0,
        _offsetZ
      );
      this.meshCutGutterRight2.scale.set(scaleX, 1, scaleZ);
      this.meshCutGutterRight2.rotation.set(0, -(_angle + Math.PI), 0);
      this.meshCutGutterRight2.updateMatrix();
      this.csgCutGutterRight2 = CSG.fromMesh(this.meshCutGutterRight2);
      //this.APP.scene.add(this.meshCutGutterRight2);
    }
  }
  private addStepRakecut() {
    let offsetXL =
      -this.totalBaySize / 2 -
      this.APP.sldLeftOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let offsetXR =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let offsetY = -100; //this.APP.sldExistingWallHeight.currentValue;
    // let offsetY = this.utils.getHeightByAngle(
    //     this.APP.sldExistingWallHeight.currentValue + this.APP.sldFlyOverBracketHeight.currentValue,
    //     this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue,
    //     this.APP.sltRoofPitch.currentValue,
    //     1
    // );

    let offsetZ =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue +
      this.APP.sldEaveWidth.currentValue -
      this.APP.sldExistingWidth1.currentValue / 2 +
      this.offsetBraketZ;

    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
      let scaleLength = (this.APP.sldLeftCutVertical.currentValue + 100) / 1000;
      let scaleWidth =
        (this.APP.sldLeftCutHorizontal.currentValue + 100) / 1000;

      this.mesh_rakecutLeft = new Mesh(
        this.geo_rakecutLeft,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutLeft.position.set(
        offsetXL + this.APP.sldLeftCutHorizontal.currentValue,
        offsetY,
        offsetZ - this.APP.sldLeftCutVertical.currentValue
      );
      this.mesh_rakecutLeft.scale.set(scaleWidth, 5, scaleLength);

      //this.APP.scene.add(this.mesh_rakecutLeft);

      this.mesh_rakecutLeft.updateMatrix();
      this.csg_rakecutLeft = CSG.fromMesh(this.mesh_rakecutLeft);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      let scaleLength =
        (this.APP.sldRightCutVertical.currentValue + 100) / 1000;
      let scaleWidth =
        (this.APP.sldRightCutHorizontal.currentValue + 100) / 1000;

      this.mesh_rakecutRight = new Mesh(
        this.geo_rakecutRight,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutRight.position.set(
        offsetXR - this.APP.sldRightCutHorizontal.currentValue,
        offsetY,
        offsetZ - this.APP.sldRightCutVertical.currentValue
      );
      this.mesh_rakecutRight.scale.set(scaleWidth, 5, scaleLength);

      //this.APP.scene.add(this.mesh_rakecutRight);

      this.mesh_rakecutRight.updateMatrix();
      this.csg_rakecutRight = CSG.fromMesh(this.mesh_rakecutRight);
    }
  }
  public addRoofGroup() {
    this.roofGroup = null;
    this.roofGroup = new Group();
    this.roofGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.ROOF_PATIOS,
    };

    let offsetX = 0;
    // if (this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3) {
    //    if (this.APP.sldExistingWidth1.currentValue > this.APP.sldEaveWidth.currentValue) {
    //        offsetX = this.APP.sldEaveWidth.currentValue;
    //    }
    // }
    let height = 0;
    if (+UI.upstandBraketType !== 0) {
      height =
        +UI.upstandBraketType +
        this.geoBeam.height +
        this.geoBraket_1.width * this.utils.tan(UI.patiosPitch);
    }
    if (UI.isUpFasciaUpstandardBracket) {
      height -= this.geoBraket_1.width * this.utils.tan(UI.patiosPitch);
    }
    let offsetY =
      UI.eaveHeight + height + UI.overhangBack * this.utils.tan(UI.patiosPitch);
    if (UI.isUpFasciaUpstandardBracket) {
      offsetY =
        UI.eaveHeight +
        height -
        UI.overhangBack * this.utils.tan(UI.patiosPitch);
    }

    let offsetZ =
      this.APP.sldEaveWidth.currentValue -
      this.APP.sldExistingWidth1.currentValue / 2 -
      UI.overhangBack;

    this.roofGroup.position.set(offsetX, offsetY, offsetZ + this.offsetBraketZ);
    if (UI.isUpFasciaUpstandardBracket) {
      this.roofGroup.rotation.set(
        -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
        0,
        0
      );
    } else {
      this.roofGroup.rotation.set(
        this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
        0,
        0
      );
    }

    this.scene.add(this.roofGroup);
  }
  public getOutLines(): Printing2DGeometry {
    let objs = this.roofGroup.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    );

    let gutterGroupObjs = this.roofGroup.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS
    );
    let gutterObjs: Object3D[] = [];
    for (let group of gutterGroupObjs) {
      gutterObjs.push(...group.children);
    }

    objs = [...objs, ...gutterObjs];

    let lsGeometries: Printing2DLine[] = [];

    let offsetLeft =
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue -
      this.MANAGER.patiosGroup.position.x;
    let offsetRight =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlane = new Plane(new Vector3(-1, 0, 0), offsetRight);

    for (let o of objs) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        60
      );
      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);
      outlineGeo.translate(0, 5000, 0);

      if (
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
      ) {
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlane);
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
      }

      // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
      // line.userData = {type: "ROOF_OUTLINE"};
      // this.APP.scene.add( line );

      let simplifiedGeo = this.simplifyGeo(outlineGeo);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: simplifiedGeo.vertices,
        views: o.userData.views,
      });

      // var line = new LineSegments( simplifiedGeo, MaterialManager.Instance().MESH_OUTLINE );
      // line.userData = {type: "SHEETING_OUTLINE"};
      // this.APP.scene.add( line );
    }

    lsGeometries.push({
      objectType: GEOMETRY_TYPE.ROOF_COVER,
      vertices: this.makeRoofPolygon(),
      color: this.utils.convertHexColorToRgb(`#${new Color(UI.panelColor).getHexString()}`),
      views: [
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
      ]
    });

    return { lines: lsGeometries, texts: [] };
  }
  makeRoofPolygon() {
    const polygon = this.utils.getRoofCoverPolygonForLRRoof()
    this.roofGroup.updateWorldMatrix(true, true)
    return polygon.map(el => (el as Vector3).applyMatrix4(this.roofGroup.matrixWorld))
  }
  public showPanelOutline() {
    if (this.APP.sltPanelDirectionShow.currentValue == false) return;

    let objs = this.roofGroup.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    );

    let offsetLeft =
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue -
      this.MANAGER.patiosGroup.position.x;
    let offsetRight =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);

    for (let o of objs) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        60
      );

      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);
      //outlineGeo.translate(0, 5000, 0);

      if (
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
      ) {
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
      }

      var line = new LineSegments(
        outlineGeo,
        MaterialManager.Instance().MESH_OUTLINE
      );
      line.userData = { type: GEOMETRY_TYPE.SHEET_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let simplifiedGeo = new Geometry();
    let vertices = geo.getAttribute("position").array;
    for (let i = 0; i < vertices.length; i += 3) {
      simplifiedGeo.vertices.push(
        new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2])
      );
    }
    return simplifiedGeo;
  }
  public addRoof(offsetX: number, csgObjs: CSG[]) {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];
    const mesh: Mesh = new Mesh(
      this.roofInfo.geometry,
      MaterialManager.Instance().ROOF
    );

    let offsetSheet = 0;
    if (
      this.APP.sltRoofSheetingType.currentValue == 0 ||
      this.APP.sltRoofSheetingType.currentValue == 1
    ) {
      offsetSheet = 13;
    } else {
      offsetSheet = 5;
    }

    //if ((this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3) && this.APP.sldExistingWidth1.currentValue > this.APP.sldEaveWidth.currentValue) {
    //    offsetX += this.APP.sldEaveWidth.currentValue;
    //}

    let scaleZ = this.roofLengthPlus / this.roofInfo.length;
    const offsetY = 0;
    let offsetZ = 0;

    // if (this.APP.sltCutOut.currentValue == 1 && this.APP.sltExistingType.currentValue != 0 && over && this.APP.sldExistingLength2.currentValue > 0) {
    //     scaleZ = (this.roofLengthPlus - this.APP.sldExistingWidth1.currentValue) / this.roofInfo.length;
    //     offsetZ += this.APP.sldExistingWidth1.currentValue;
    // }

    mesh.position.set(offsetX, offsetY, offsetZ);
    mesh.scale.setZ(scaleZ);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.ROOF_PANEL,
      views: views,
      length: scaleZ,
    };

    let cutoutCondition =
      this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
      this.APP.sltExistingType.currentValue != BUILDING_SIDE.NONE &&
      this.APP.sldExistingLength2.currentValue > 0;
    let rakeCutCondition =
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE ||
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE;

    if (cutoutCondition || rakeCutCondition) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      //cho no cung he toa do
      mesh.position.set(
        offsetX + this.MANAGER.patiosGroup.position.x,
        offsetY,
        offsetZ + this.roofGroup.position.z
      );
      mesh.updateMatrix();

      let meshResult = mesh.clone();

      if (cutoutCondition) {
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(csgObj),
            mesh.matrix
          );
        }
      }
      if (rakeCutCondition) {
        if (this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(this.csg_rakecutLeft),
            mesh.matrix
          );
        }
        if (this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(this.csg_rakecutRight),
            mesh.matrix
          );
        }
      }
      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix)); //=> tra ve goc toa do
      meshResult.applyMatrix4(matrixBefore);

      let buferGeo = new BufferGeometry().fromGeometry((meshResult as Mesh).geometry as Geometry);
      this.utils.changeGeoColor(buferGeo, new Vector3(0, 1, 0), UI.roofColor.toString());
      let newGeo = new Geometry().fromBufferGeometry(buferGeo);
      (meshResult as Mesh).geometry = newGeo;

      this.roofGroup.add(meshResult);
    } else {
      this.roofGroup.add(mesh);
    }
  }
  private addBarge() {
    if (UI.isUpFasciaUpstandardBracket) {
      let mesh: Mesh;
      let extraWallWidth =
        this.APP.existingWallManager.geo_existingWallW1.width +
        EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
      let offsetXF = -(
        this.totalBaySize / 2 +
        this.APP.sldLeftOverhang.currentValue
      );
      let offsetXL1 =
        -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue) +
        this.APP.sldExistingLength2.currentValue;
      let offsetXL2 = -(
        this.totalBaySize / 2 +
        this.APP.sldLeftOverhang.currentValue
      );
      let offsetXR1 =
        this.totalBaySize / 2 +
        this.APP.sldRightOverhang.currentValue -
        this.APP.sldExistingLength2.currentValue;
      let offsetXR2 =
        this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;
      let offsetZL = 0;
      let offsetZR = 0;
      let offsetZFront =
        (this.APP.sldSpan.currentValue +
          this.APP.sldMultiSpan.currentValue +
          this.APP.sldFrontOverhang.currentValue +
          this.APP.sldBackOverhang.currentValue) /
        this.utils.cos(this.APP.sltRoofPitch.currentValue);
      let bargeFrontLength = this.roofWidth;
      let scaleCutoutVer =
        this.utils.getHypotenuseByCos(
          this.APP.sldExistingWidth1.currentValue + this.bargeLeftInfo.width,
          this.APP.sltRoofPitch.currentValue
        ) / this.bargeLeftInfo.length;
      let bargeLeftLength = this.roofLength;
      let bargeRightLength = this.roofLength;
      let offsetY = 0;
      let views: Print2DView[] = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
      ];
      //cutout
      if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.APP.sldExistingLength2.currentValue > 0 &&
        this.MANAGER.cutoutCondition &&
        (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT ||
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH)
      ) {
        bargeLeftLength -= this.APP.sldExistingWidth1.currentValue;
        offsetZL += this.utils.getHypotenuseByCos(
          this.APP.sldExistingWidth1.currentValue,
          this.APP.sltRoofPitch.currentValue
        );
        //TODO: Temp ==> this.geoBraket_1.width / 10
        //cutout Hor
        let offsetZ = this.utils.getHypotenuseByCos(
          UI.existingWidth1 - this.geoBraket_1.width / 10,
          UI.patiosPitch
        );
        mesh = new Mesh(
          this.geo_bargeCutoutLeftHor.geometry,
          MaterialManager.Instance().BARGE
        );
        mesh.position.set(offsetXL1, offsetY, offsetZ);
        mesh.scale.setX(
          UI.existingLength2 / this.geo_bargeCutoutLeftHor.length
        );
        mesh.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
        this.roofGroup.add(mesh);
        //cutout Ver
        mesh = new Mesh(
          this.bargeLeftInfo.geometry,
          MaterialManager.Instance().BARGE
        );
        mesh.position.set(offsetXL1, offsetY, 0);
        mesh.scale.setZ(scaleCutoutVer);
        mesh.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
        this.roofGroup.add(mesh);
      }
      if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.APP.sldExistingLength2.currentValue > 0 &&
        this.MANAGER.cutoutCondition &&
        (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT ||
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH)
      ) {
        bargeRightLength -= this.APP.sldExistingWidth1.currentValue;
        offsetZR += this.utils.getHypotenuseByCos(
          this.APP.sldExistingWidth1.currentValue,
          this.APP.sltRoofPitch.currentValue
        );
        //TODO: Temp ==> this.geoBraket_1.width / 10
        //cutout hor
        let offsetZ = this.utils.getHypotenuseByCos(
          this.APP.sldExistingWidth1.currentValue - this.geoBraket_1.width / 10,
          this.APP.sltRoofPitch.currentValue
        );
        mesh = new Mesh(
          this.geo_bargeCutoutRightHor.geometry,
          MaterialManager.Instance().BARGE
        );
        mesh.position.set(offsetXR1, offsetY, offsetZ);
        mesh.scale.setX(
          UI.existingLength2 / this.geo_bargeCutoutLeftHor.length
        );
        mesh.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
        this.roofGroup.add(mesh);
        // cutout ver
        mesh = new Mesh(
          this.bargeRightInfo.geometry,
          MaterialManager.Instance().BARGE
        );
        mesh.position.set(offsetXR1, offsetY, 0);
        mesh.scale.setZ(scaleCutoutVer);
        mesh.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
        this.roofGroup.add(mesh);
      }
      //Rakecut
      if (
        this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE ||
        this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP
      ) {
        offsetXF += this.APP.sldLeftCutHorizontal.currentValue;
        bargeLeftLength -= this.APP.sldLeftCutVertical.currentValue;
        bargeFrontLength -= this.APP.sldLeftCutHorizontal.currentValue;
      }
      if (
        this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE ||
        this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP
      ) {
        bargeRightLength -= this.APP.sldRightCutVertical.currentValue;
        bargeFrontLength -= this.APP.sldRightCutHorizontal.currentValue;
      }
      let scaleXF = bargeFrontLength / this.bargeFrontInfo.length;
      let scaleZLeft =
        this.utils.getHypotenuseByCos(
          bargeLeftLength,
          this.APP.sltRoofPitch.currentValue
        ) / this.bargeLeftInfo.length;
      let scaleZRight =
        this.utils.getHypotenuseByCos(
          bargeRightLength,
          this.APP.sltRoofPitch.currentValue
        ) / this.bargeRightInfo.length;
      //left
      mesh = new Mesh(
        this.bargeLeftInfo.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXL2, offsetY, offsetZL);
      mesh.scale.setZ(scaleZLeft);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
      //right
      mesh = new Mesh(
        this.bargeRightInfo.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXR2, offsetY, offsetZR);
      mesh.scale.setZ(scaleZRight);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
      //front
      mesh = new Mesh(
        this.bargeFrontInfo.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXF, 0, offsetZFront + 2);
      mesh.scale.setX(scaleXF);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    } else {
      let views: Print2DView[] = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
      ];
      let mesh: Mesh;
      let offsetXB = -(
        this.totalBaySize / 2 +
        this.APP.sldLeftOverhang.currentValue
      );
      let offsetXL1 = -(UI.totalBayLength / 2 + UI.overhangLeft) + UI.existingLength2 + (this.shouldSnapRoofToExistingWhenCutout ? 1 : 0) * UI.eaveWidth;
      let offsetXL2 = -( UI.totalBayLength / 2 + UI.overhangLeft);
      let offsetXR1 = this.totalBaySize / 2 + UI.overhangRight - UI.existingLength2 - (this.shouldSnapRoofToExistingWhenCutout ? 1 : 0) * UI.eaveWidth;
      let offsetXR2 = UI.totalBayLength / 2 + UI.overhangRight;
      let offsetZL = 0;
      let offsetZR = 0;
      const offsetY = 0;
      let scaleXB = this.roofWidth / this.geo_receiverChanel.length;
      let scaleZLeft = this.roofLengthPlus / this.bargeLeftInfo.length;
      let bargeLeftLength = this.roofLengthPlus;
      let scaleZRight = this.roofLengthPlus / this.bargeRightInfo.length;
      let bargeRightLength = this.roofLengthPlus;

      let bargeCutOutVerLength = this.utils.getHypotenuseByCos(
        this.APP.sldExistingWidth1.currentValue + this.bargeLeftInfo.width,
        this.APP.sltRoofPitch.currentValue
      )
      if(UI.structureType == CONNECTION_TYPE.FASCIA) {
        if(this.shouldSnapRoofToExistingWhenCutout) {
          bargeCutOutVerLength = this.utils.getHypotenuseByCos(
            UI.existingWidth1 - UI.eaveWidth + this.geo_receiverChanel.width,
            this.APP.sltRoofPitch.currentValue
          )
        } else {
          bargeCutOutVerLength = this.utils.getHypotenuseByCos(
            UI.existingWidth1 + this.geo_receiverChanel.width,
            this.APP.sltRoofPitch.currentValue
          )
        }
      }
      let scaleCutoutVer = bargeCutOutVerLength / this.bargeLeftInfo.length;
      let scaleCutoutHor =
        this.APP.sldExistingLength2.currentValue /
        this.geo_bargeCutoutLeftHor.length;
      //cutout
      if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.APP.sldExistingLength2.currentValue > 0 &&
        this.MANAGER.cutoutCondition &&
        (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT ||
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH)
      ) {
        scaleXB =
          (this.roofWidth - this.APP.sldExistingLength2.currentValue) /
          this.geo_receiverChanel.length;
        scaleZLeft =
          this.utils.getHypotenuseByCos(
            this.roofLength - this.APP.sldExistingWidth1.currentValue,
            this.APP.sltRoofPitch.currentValue
          ) / this.bargeLeftInfo.length;
        bargeLeftLength = this.utils.getHypotenuseByCos(
          this.roofLength - UI.existingWidth1,
          this.APP.sltRoofPitch.currentValue
        );
        offsetXB += this.APP.sldExistingLength2.currentValue;
        if(this.shouldSnapRoofToExistingWhenCutout) {
          bargeLeftLength = this.utils.getHypotenuseByCos(
            this.roofLength - UI.existingWidth1 + UI.eaveWidth,
            this.APP.sltRoofPitch.currentValue
          );
          offsetZL += this.utils.getHypotenuseByCos(
            UI.existingWidth1 - UI.eaveWidth,
            this.APP.sltRoofPitch.currentValue
          );
        } else {
          offsetZL += this.utils.getHypotenuseByCos(
            UI.existingWidth1,
            this.APP.sltRoofPitch.currentValue
          );
        }

        //TODO: Temp ==> this.geoBraket_1.width / 10
        //cutout Hor
        let offsetZ = this.utils.getHypotenuseByCos(
          UI.existingWidth1 - this.geoBraket_1.width / 10,
          this.APP.sltRoofPitch.currentValue
        );
        if(this.shouldSnapRoofToExistingWhenCutout) {
          offsetZ = this.utils.getHypotenuseByCos(
            UI.existingWidth1 - UI.eaveWidth - this.geoBraket_1.width / 10,
            this.APP.sltRoofPitch.currentValue
          );
        }
       
        // If normal fascia, the back barge is receive chanel
        if (UI.structureType == CONNECTION_TYPE.FASCIA) {
          const receiveChanelLength = scaleCutoutHor * this.geo_bargeCutoutLeftHor.length + (this.shouldSnapRoofToExistingWhenCutout ? 1 : 0) * UI.eaveWidth
          const receiveChanelScale = receiveChanelLength / this.geo_receiverChanel.length
          mesh = new Mesh(
            this.geo_receiverChanel.geometry,
            MaterialManager.Instance().RECEIVER_CHANEL
          );
          mesh.position.set(offsetXL1 - receiveChanelLength, offsetY, offsetZ);
          mesh.scale.setX(receiveChanelScale);
          mesh.userData = {
            category: GEOMETRY_CATEGORY.PATIOS,
            type: GEOMETRY_TYPE.RECEIVER_CHANEL,
            views: views,
          };
        } else {
          mesh = new Mesh(
            this.geo_bargeCutoutLeftHor.geometry,
            MaterialManager.Instance().BARGE
          );
          mesh.position.set(offsetXL1, offsetY, offsetZ);
          mesh.scale.setX(scaleCutoutHor);
          mesh.userData = {
            category: GEOMETRY_CATEGORY.PATIOS,
            type: this.APP.sltDripBarge.currentValue
              ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
              : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
            views: views,
          };
          
        }
        
        this.roofGroup.add(mesh);

        // Cutout vertical (Left of existing length 1)
        if(UI.structureType == CONNECTION_TYPE.FASCIA) {
          mesh = new Mesh(
            this.geoReceiveChannelLeftInfo.geometry,
            MaterialManager.Instance().RECEIVER_CHANEL
          );
          mesh.userData = {
            category: GEOMETRY_CATEGORY.PATIOS,
            type: GEOMETRY_TYPE.RECEIVER_CHANEL,
            views: views,
          };
        } else {
          mesh = new Mesh(
            this.bargeLeftInfo.geometry,
            MaterialManager.Instance().BARGE
          );
          mesh.userData = {
            category: GEOMETRY_CATEGORY.PATIOS,
            type: this.APP.sltDripBarge.currentValue
              ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
              : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
            views: views,
          };
        }
        mesh.position.set(offsetXL1, offsetY, 0);
        mesh.scale.setZ(scaleCutoutVer);
        this.roofGroup.add(mesh);
      }
      if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.APP.sldExistingLength2.currentValue > 0 &&
        this.MANAGER.cutoutCondition &&
        (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT ||
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH)
      ) {
        scaleXB =
          (this.roofWidth - this.APP.sldExistingLength2.currentValue) /
          this.geo_receiverChanel.length;
        scaleZRight =
          this.utils.getHypotenuseByCos(
            this.roofLength - this.APP.sldExistingWidth1.currentValue,
            this.APP.sltRoofPitch.currentValue
          ) / this.bargeRightInfo.length;
        bargeRightLength = this.utils.getHypotenuseByCos(
          this.roofLength - UI.existingWidth1,
          UI.patiosPitch
        );
        
        if(this.shouldSnapRoofToExistingWhenCutout) {
          bargeRightLength = this.utils.getHypotenuseByCos(
            this.roofLength - UI.existingWidth1 + UI.eaveWidth,
            UI.patiosPitch
          );
          offsetZR += this.utils.getHypotenuseByCos(
            UI.existingWidth1 - UI.eaveWidth,
            UI.patiosPitch
          );
        } else {
          offsetZR += this.utils.getHypotenuseByCos(
            UI.existingWidth1,
            UI.patiosPitch
          );
        }

        //TODO: Temp ==> this.geoBraket_1.width / 10
        //cutout hor
        let offsetZ = this.utils.getHypotenuseByCos(
          UI.existingWidth1 - this.geoBraket_1.width / 10,
          this.APP.sltRoofPitch.currentValue
        );
        if(this.shouldSnapRoofToExistingWhenCutout) {
          offsetZ = this.utils.getHypotenuseByCos(
            UI.existingWidth1 - UI.eaveWidth - this.geoBraket_1.width / 10,
            this.APP.sltRoofPitch.currentValue
          );
        }

        if (UI.structureType == CONNECTION_TYPE.FASCIA) {
          const receiveChanelLength = scaleCutoutHor * this.geo_bargeCutoutLeftHor.length + (this.shouldSnapRoofToExistingWhenCutout ? 1 : 0) * UI.eaveWidth
          const receiveChanelScale = receiveChanelLength / this.geo_receiverChanel.length
          mesh = new Mesh(
            this.geo_receiverChanel.geometry,
            MaterialManager.Instance().RECEIVER_CHANEL
          );
          mesh.scale.setX(receiveChanelScale);
          mesh.userData = {
            category: GEOMETRY_CATEGORY.PATIOS,
            type: GEOMETRY_TYPE.RECEIVER_CHANEL,
            views: views,
          };
         
        } else {
          mesh = new Mesh(
            this.geo_bargeCutoutRightHor.geometry,
            MaterialManager.Instance().BARGE
          );
          mesh.scale.setX(scaleCutoutHor);
          mesh.userData = {
            category: GEOMETRY_CATEGORY.PATIOS,
            type: this.APP.sltDripBarge.currentValue
              ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
              : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
            views: views,
          };
        }
        mesh.position.set(offsetXR1, offsetY, offsetZ);
        this.roofGroup.add(mesh);
        
        //cutout ver
       
        if(UI.structureType == CONNECTION_TYPE.FASCIA) {
          mesh = new Mesh(
            this.geoReceiveChannelRightInfo.geometry,
            MaterialManager.Instance().RECEIVER_CHANEL
          );
          mesh.userData = {
            category: GEOMETRY_CATEGORY.PATIOS,
            type: GEOMETRY_TYPE.RECEIVER_CHANEL,
            views: views,
          };
        } else {
          mesh = new Mesh(
            this.bargeRightInfo.geometry,
            MaterialManager.Instance().BARGE
          );
          mesh.userData = {
            category: GEOMETRY_CATEGORY.PATIOS,
            type: this.APP.sltDripBarge.currentValue
              ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
              : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
            views: views,
          };
        }
        mesh.position.set(offsetXR1, offsetY, 0);
        mesh.scale.setZ(scaleCutoutVer);
        this.roofGroup.add(mesh);
      }

      if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.APP.sldExistingLength2.currentValue > 0 &&
        this.MANAGER.cutoutCondition &&
        this.APP.sltExistingType.currentValue == 3
      ) {
        scaleXB =
          (this.roofWidth - this.APP.sldExistingLength2.currentValue * 2) /
          this.geo_receiverChanel.length;
      }
      //Rake cut
      if (this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE) {
        bargeLeftLength -= this.APP.sldLeftCutVertical.currentValue;
      }
      if (this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE) {
        bargeRightLength -= this.APP.sldRightCutVertical.currentValue;
      }
      scaleZLeft = bargeLeftLength / this.bargeLeftInfo.length;
      scaleZRight = bargeRightLength / this.bargeRightInfo.length;

      // Left barge (Left side of existing length 1 and existing length 2)
      if(
        UI.structureType == CONNECTION_TYPE.FASCIA 
        && (UI.existingType == BUILDING_SIDE.LEFT || UI.existingType == BUILDING_SIDE.BOTH)
        && this.shouldSnapRoofToExistingWhenCutout
        && UI.existingWidth2 > 0
      ) {
        mesh = new Mesh(
          this.geoReceiveChannelLeftInfo.geometry,
          MaterialManager.Instance().RECEIVER_CHANEL
        );
        mesh.scale.setZ(scaleZLeft);
        mesh.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: GEOMETRY_TYPE.RECEIVER_CHANEL,
          views: views,
        };
      } else {
        mesh = new Mesh(
          this.bargeLeftInfo.geometry,
          MaterialManager.Instance().BARGE
        );
        mesh.scale.setZ(scaleZLeft);
        mesh.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
      }
      mesh.position.set(offsetXL2, offsetY, offsetZL);
      this.roofGroup.add(mesh);

      // Right barge (Right side of existing length 1 and existing length 2)
      if(
        UI.structureType == CONNECTION_TYPE.FASCIA 
        && (UI.existingType == BUILDING_SIDE.RIGHT || UI.existingType == BUILDING_SIDE.BOTH)
        && this.shouldSnapRoofToExistingWhenCutout
        && UI.existingWidth2 > 0
      ) {
        mesh = new Mesh(
          this.geoReceiveChannelRightInfo.geometry,
          MaterialManager.Instance().RECEIVER_CHANEL
        );
        mesh.scale.setZ(scaleZRight);
        mesh.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: GEOMETRY_TYPE.RECEIVER_CHANEL,
          views: views,
        };
      } else {
        mesh = new Mesh(
          this.bargeRightInfo.geometry,
          MaterialManager.Instance().BARGE
        );
        mesh.scale.setZ(scaleZRight);
        mesh.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
      }
      mesh.position.set(offsetXR2, offsetY, offsetZR);
      this.roofGroup.add(mesh);
      if(this.shouldSnapRoofToExistingWhenCutout) {
        if(UI.existingType == BUILDING_SIDE.LEFT || UI.existingType == BUILDING_SIDE.RIGHT) {
          scaleXB -= UI.eaveWidth / this.geo_receiverChanel.length;
        } else if(UI.existingType == BUILDING_SIDE.BOTH) {
          scaleXB -= 2 * UI.eaveWidth / this.geo_receiverChanel.length;
        }
        if(UI.existingType == BUILDING_SIDE.LEFT || UI.existingType == BUILDING_SIDE.BOTH) {
          offsetXB += UI.eaveWidth;
        }
      }
      if (UI.upstandBraketType == 0) {
        mesh = new Mesh(
          this.geo_receiverChanel.geometry,
          MaterialManager.Instance().RECEIVER_CHANEL
        );
        mesh.position.set(offsetXB, offsetY, 0);
        mesh.scale.setX(scaleXB);
        mesh.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: GEOMETRY_TYPE.RECEIVER_CHANEL,
          views: views,
        };
        this.roofGroup.add(mesh);
      } else {
        mesh = new Mesh(
          this.bargeBackInfo.geometry,
          MaterialManager.Instance().BARGE
        );
        mesh.position.set(offsetXB, offsetY, 0);
        mesh.scale.setX(scaleXB);
        mesh.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
        this.roofGroup.add(mesh);
      }
    }
  }
  private addBargeStepRakecut() {
    if (UI.isUpFasciaUpstandardBracket) {
      if (
        this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.STEP &&
        this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.STEP
      ) {
        return;
      }

      let views: Print2DView[] = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
      ];

      let offsetXL =
        this.APP.dialogEditBay.totalBaySize / 2 +
        this.APP.sldLeftOverhang.currentValue;
      let offsetXR =
        this.APP.dialogEditBay.totalBaySize / 2 +
        this.APP.sldRightOverhang.currentValue;

      if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
        let offsetZ = this.utils.getHypotenuseByCos(
          this.APP.sldSpan.currentValue +
            this.APP.sldMultiSpan.currentValue +
            this.APP.sldFrontOverhang.currentValue +
            this.APP.sldBackOverhang.currentValue -
            this.APP.sldLeftCutVertical.currentValue,
          this.APP.sltRoofPitch.currentValue
        );
        let scaleZ =
          (this.APP.sldLeftCutVertical.currentValue +
            this.bargeLeftInfo.width) /
          this.bargeLeftInfo.length;
        let scaleX =
          this.APP.sldLeftCutHorizontal.currentValue /
          this.bargeLeftInfo.length;

        let bargeVer = new Mesh(
          this.bargeLeftInfo.geometry,
          MaterialManager.Instance().BARGE
        );
        bargeVer.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
        bargeVer.position.set(
          -offsetXL + this.APP.sldLeftCutHorizontal.currentValue,
          0,
          offsetZ - this.bargeLeftInfo.width
        );
        bargeVer.scale.set(1, 1, scaleZ);

        let bargeHor = new Mesh(
          this.bargeLeftInfo.geometry,
          MaterialManager.Instance().BARGE
        );
        bargeHor.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
        bargeHor.position.set(-offsetXL, 0, offsetZ);
        bargeHor.scale.set(1, 1, scaleX);
        bargeHor.rotateY(Math.PI / 2);

        this.roofGroup.add(bargeVer, bargeHor);
      }
      if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
        let offsetZ = this.utils.getHypotenuseByCos(
          this.APP.sldSpan.currentValue +
            this.APP.sldMultiSpan.currentValue +
            this.APP.sldFrontOverhang.currentValue +
            this.APP.sldBackOverhang.currentValue -
            this.APP.sldRightCutVertical.currentValue,
          this.APP.sltRoofPitch.currentValue
        );
        let scaleZ =
          (this.APP.sldRightCutVertical.currentValue +
            this.bargeRightInfo.width) /
          this.bargeRightInfo.length;
        let scaleX =
          this.APP.sldRightCutHorizontal.currentValue /
          this.bargeRightInfo.length;

        let bargeVer = new Mesh(
          this.bargeRightInfo.geometry,
          MaterialManager.Instance().BARGE
        );
        bargeVer.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
        bargeVer.position.set(
          offsetXR - this.APP.sldRightCutHorizontal.currentValue,
          0,
          offsetZ - this.bargeRightInfo.width
        );
        bargeVer.scale.set(1, 1, scaleZ);

        let bargeHor = new Mesh(
          this.bargeRightInfo.geometry,
          MaterialManager.Instance().BARGE
        );
        bargeHor.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
        bargeHor.position.set(offsetXR, 0, offsetZ);
        bargeHor.scale.set(1, 1, scaleX);
        bargeHor.rotateY(-Math.PI / 2);

        this.roofGroup.add(bargeVer, bargeHor);
      }
    } else {
      if (
        !(
          this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP ||
          this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP
        )
      ) {
        return;
      }

      let views: Print2DView[] = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
      ];

      let offsetXL =
        this.APP.dialogEditBay.totalBaySize / 2 +
        this.APP.sldLeftOverhang.currentValue;
      let offsetXR =
        this.APP.dialogEditBay.totalBaySize / 2 +
        this.APP.sldRightOverhang.currentValue;
      let offsetZL = this.utils.getHypotenuseByCos(
        this.APP.sldSpan.currentValue +
          this.APP.sldMultiSpan.currentValue +
          this.APP.sldFrontOverhang.currentValue +
          UI.overhangBack -
          this.APP.sldLeftCutVertical.currentValue,
        this.APP.sltRoofPitch.currentValue
      );
      let offsetZR = this.utils.getHypotenuseByCos(
        this.APP.sldSpan.currentValue +
          this.APP.sldMultiSpan.currentValue +
          UI.overhangBack +
          this.APP.sldFrontOverhang.currentValue -
          this.APP.sldRightCutVertical.currentValue,
        this.APP.sltRoofPitch.currentValue
      );
      let scaleZL =
        this.APP.sldLeftCutVertical.currentValue / this.bargeLeftInfo.length;
      let scaleZR =
        this.APP.sldRightCutVertical.currentValue / this.bargeRightInfo.length;
      //let scaleX = (this.APP.sldRakeCutHorizontal.currentValue) / this.bargeLeftInfo.length;

      if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
        let bargeVer = new Mesh(
          this.bargeLeftInfo.geometry,
          MaterialManager.Instance().BARGE
        );
        bargeVer.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
        bargeVer.position.set(
          -offsetXL + this.APP.sldLeftCutHorizontal.currentValue,
          0,
          offsetZL
        );
        bargeVer.scale.set(1, 1, scaleZL);

        // let bargeHor = new Mesh(this.bargeLeftInfo.geometry, MaterialManager.Instance().BARGE);
        // bargeHor.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
        // bargeHor.position.set(-offsetXL, 0, offsetZ);
        // bargeHor.scale.set(1, 1, scaleX);
        // bargeHor.rotateY(Math.PI / 2);

        this.roofGroup.add(bargeVer);
      }
      if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
        let bargeVer = new Mesh(
          this.bargeRightInfo.geometry,
          MaterialManager.Instance().BARGE
        );
        bargeVer.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: this.APP.sltDripBarge.currentValue
            ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
            : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
          views: views,
        };
        bargeVer.position.set(
          offsetXR - this.APP.sldRightCutHorizontal.currentValue,
          0,
          offsetZR
        );
        bargeVer.scale.set(1, 1, scaleZR);

        // let bargeHor = new Mesh(this.bargeRightInfo.geometry, MaterialManager.Instance().BARGE);
        // bargeHor.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
        // bargeHor.position.set(offsetXR, 0, offsetZ);
        // bargeHor.scale.set(1, 1, scaleX);
        // bargeHor.rotateY(-Math.PI / 2);

        this.roofGroup.add(bargeVer);
      }
    }
  }
  private addBargeAngelRakecut() {
    if (!UI.isUpFasciaUpstandardBracket) {
      return;
    }
    if (
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.ANGLE &&
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.ANGLE
    ) {
      return;
    }

    let offsetY = 0;
    let offsetZ =
      (this.APP.sldSpan.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldFrontOverhang.currentValue +
        this.APP.sldBackOverhang.currentValue) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      let length = Math.sqrt(
        Math.pow(this.APP.sldLeftCutVertical.currentValue, 2) +
          Math.pow(this.APP.sldLeftCutHorizontal.currentValue, 2)
      );
      let scaleZ = length / this.geo_bargeRakecutLeft.length;

      let rotY = Math.atan(
        this.APP.sldLeftCutHorizontal.currentValue /
          this.APP.sldLeftCutVertical.currentValue
      );

      let offsetX =
        -this.totalBaySize / 2 -
        this.APP.sldLeftOverhang.currentValue +
        this.APP.sldLeftCutHorizontal.currentValue;
      let mesh = new Mesh(
        this.geo_bargeRakecutLeft.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetX, offsetY, offsetZ);
      mesh.scale.setZ(scaleZ);
      mesh.rotateY(rotY);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      let length = Math.sqrt(
        Math.pow(this.APP.sldRightCutVertical.currentValue, 2) +
          Math.pow(this.APP.sldRightCutHorizontal.currentValue, 2)
      );
      let scaleZ = length / this.geo_bargeRakecutLeft.length;

      let rotY = Math.atan(
        this.APP.sldRightCutHorizontal.currentValue /
          this.APP.sldRightCutVertical.currentValue
      );

      let offsetX =
        this.totalBaySize / 2 +
        this.APP.sldRightOverhang.currentValue -
        this.APP.sldRightCutHorizontal.currentValue;
      let mesh = new Mesh(
        this.geo_bargeRakecutRight.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetX, offsetY, offsetZ);
      mesh.scale.setZ(scaleZ);
      mesh.rotateY(-rotY);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    }
  }
  public addGutter() {
    if (UI.isUpFasciaUpstandardBracket) {
      let offsetX = UI.totalBayLength / 2 + UI.overhangRight;
      let offsetZ = 0;
      let offsetY = 0;
      let gutterLength = UI.totalBayLength + UI.overhangLeft + UI.overhangRight;
      //cutout
      if (
        this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
        this.MANAGER.cutoutCondition
      ) {
        if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
          gutterLength =
            UI.totalBayLength +
            UI.overhangLeft +
            UI.overhangRight -
            UI.existingLength2;
        } else if (
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT
        ) {
          gutterLength =
            UI.totalBayLength +
            UI.overhangLeft +
            UI.overhangRight -
            UI.existingLength2;
          offsetX =
            UI.totalBayLength / 2 + UI.overhangRight - UI.existingLength2;
        } else if (
          this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH
        ) {
          offsetX = UI.existingLength1 / 2 - UI.eaveWidth;
          gutterLength = UI.existingLength1 - 2 * UI.eaveWidth;
        }
      }

      let views: Print2DView[] = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
      ];

      //Add gutter back
      let gutterGroup = this.utils.createGutterGroup(
        this.gutterInfo,
        this.geo_gutterCap,
        gutterLength,
        new Vector3(offsetX, offsetY, offsetZ),
        new Vector3(
          this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
          Math.PI,
          0
        ),
        views,
        1,
        true,
        true
      );
      this.roofGroup.add(gutterGroup);
      this.addDownPipe(gutterGroup, gutterLength);
    } else {
      let offsetXL = -(
        this.totalBaySize / 2 +
        this.APP.sldLeftOverhang.currentValue
      );
      const offsetZ =
        (this.APP.sldFrontOverhang.currentValue +
          UI.overhangBack +
          this.APP.sldMultiSpan.currentValue +
          this.APP.sldSpan.currentValue -
          CONST.GUTTER_ROOF_OFFSET_Z) /
        this.utils.cos(this.APP.sltRoofPitch.currentValue);
      const offsetY = 0;

      let gutterLength = this.roofWidth;
      let exLength = 300; //Math.tan(extraAngle) * this.gutterInfo.width;

      let views: Print2DView[] = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
      ];

      if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        offsetXL += this.APP.sldLeftCutHorizontal.currentValue - exLength;
        gutterLength -= this.APP.sldLeftCutHorizontal.currentValue - exLength;
      } else if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
        offsetXL += this.APP.sldLeftCutHorizontal.currentValue;
        gutterLength -= this.APP.sldLeftCutHorizontal.currentValue;
      }

      if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        gutterLength -= this.APP.sldRightCutHorizontal.currentValue - exLength;
      } else if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
        gutterLength -= this.APP.sldRightCutHorizontal.currentValue;
      }

      if (
        this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE &&
        this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE
      ) {
        let gutterMesh = new Mesh(
          this.gutterInfo.geometry,
          MaterialManager.Instance().GUTTER
        );
        gutterMesh.scale.setX(gutterLength / this.gutterInfo.length);
        gutterMesh.updateMatrix();
        let matrixBefore = gutterMesh.matrix.clone();

        gutterMesh.position.set(offsetXL, offsetY, offsetZ);
        gutterMesh.rotation.set(
          -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
          0,
          0
        );
        gutterMesh.updateMatrix();

        let meshClipped = CSG.toMesh(
          CSG.fromMesh(gutterMesh).subtract(this.csgCutGutterLeft1),
          gutterMesh.matrix
        );
        meshClipped = CSG.toMesh(
          CSG.fromMesh(meshClipped).subtract(this.csgCutGutterRight1),
          gutterMesh.matrix
        );
        meshClipped.applyMatrix4(new Matrix4().getInverse(gutterMesh.matrix));
        meshClipped.applyMatrix4(matrixBefore);
        meshClipped.material = gutterMesh.material;
        meshClipped.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: GEOMETRY_TYPE.GUTTER_PATIOS,
          views: views,
        };

        let gutterGroup = this.utils.createGutterGroupWithMesh(
          meshClipped,
          this.geo_gutterCap,
          gutterLength,
          new Vector3(offsetXL, offsetY, offsetZ),
          new Vector3(
            -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
            0,
            0
          ),
          views,
          1,
          false,
          false
        );

        this.roofGroup.add(gutterGroup);
      } else if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        {
          let gutterGroup = this.utils.createGutterGroup(
            this.gutterInfo,
            this.geo_gutterCap,
            gutterLength,
            new Vector3(offsetXL, offsetY, offsetZ),
            new Vector3(
              -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
              0,
              0
            ),
            views,
            1,
            false,
            true,
            this.csgCutGutterLeft1,
            this.roofGroup
          );

          this.roofGroup.add(gutterGroup);
        }
      } else if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        {
          let gutterGroup = this.utils.createGutterGroup(
            this.gutterInfo,
            this.geo_gutterCap,
            gutterLength,
            new Vector3(offsetXL, offsetY, offsetZ),
            new Vector3(
              -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
              0,
              0
            ),
            views,
            1,
            true,
            false,
            this.csgCutGutterRight1,
            this.roofGroup
          );

          this.roofGroup.add(gutterGroup);
        }
      } else {
        let gutterGroup = this.utils.createGutterGroup(
          this.gutterInfo,
          this.geo_gutterCap,
          gutterLength,
          new Vector3(offsetXL, offsetY, offsetZ),
          new Vector3(
            -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
            0,
            0
          ),
          views,
          1,
          true,
          true
        );
        this.roofGroup.add(gutterGroup);
      }
    }
  }
  private addDownPipe(gutterGroup: Group, gutterLength: number) {
    let meshPipe = new Mesh(
      this.MANAGER.postAndBeamManager.geo_downPipe.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshPipe.userData = { type: GEOMETRY_TYPE.DOWNPIPE_NOZZLE };
    meshPipe.scale.setY(
      100 / this.MANAGER.postAndBeamManager.geo_downPipe.height
    );
    meshPipe.position.set(
      gutterLength - 100,
      -190,
      this.MANAGER.postAndBeamManager.geo_downPipe.width / 2 + 20
    );

    gutterGroup.add(meshPipe);
  }
  private addGutterAngelRakeCut() {
    if (UI.isUpFasciaUpstandardBracket) {
      return;
    }
    if (
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.ANGLE &&
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.ANGLE
    ) {
      return;
    }

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    let exLength = 300;
    let rc_offsetXL = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );
    let rc_offsetXR =
      this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;
    const rc_offsetZL =
      (UI.overhangFront +
        UI.overhangBack +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue -
        this.APP.sldLeftCutVertical.currentValue -
        CONST.GUTTER_ROOF_OFFSET_Z) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);
    const rc_offsetZR =
      (UI.overhangFront +
        UI.overhangBack +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue -
        this.APP.sldRightCutVertical.currentValue -
        CONST.GUTTER_ROOF_OFFSET_Z) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);
    const rc_offsetY = 0;

    let rc_rotYL = Math.atan(
      this.APP.sldLeftCutVertical.currentValue /
        this.APP.sldLeftCutHorizontal.currentValue
    );
    let rc_lengthL =
      exLength +
      Math.sqrt(
        Math.pow(this.APP.sldLeftCutVertical.currentValue, 2) +
          Math.pow(this.APP.sldLeftCutHorizontal.currentValue, 2)
      );
    let rc_rotYR = Math.atan(
      this.APP.sldRightCutVertical.currentValue /
        this.APP.sldRightCutHorizontal.currentValue
    );
    let rc_lengthR =
      exLength +
      Math.sqrt(
        Math.pow(this.APP.sldRightCutVertical.currentValue, 2) +
          Math.pow(this.APP.sldRightCutHorizontal.currentValue, 2)
      );
    if (
      this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE &&
      this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE
    ) {
      let gutterRakecutGroupLeft = this.utils.createGutterGroup(
        this.gutterRakecutLeftInfo,
        this.geo_gutterCap,
        rc_lengthL,
        new Vector3(rc_offsetXL, rc_offsetY, rc_offsetZL),
        new Vector3(0, -rc_rotYL, 0),
        views,
        1,
        true,
        false,
        this.csgCutGutterLeft2,
        this.roofGroup
      );
      let gutterRakecutGroupRight = this.utils.createGutterGroup(
        this.gutterRakecutRightInfo,
        this.geo_gutterCap,
        rc_lengthR,
        new Vector3(rc_offsetXR, rc_offsetY, rc_offsetZR),
        new Vector3(0, rc_rotYR, 0),
        views,
        1,
        true,
        false,
        this.csgCutGutterRight2,
        this.roofGroup
      );

      this.roofGroup.add(gutterRakecutGroupLeft, gutterRakecutGroupRight);
    } else {
      if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        let gutterRakecutGroup = this.utils.createGutterGroup(
          this.gutterRakecutLeftInfo,
          this.geo_gutterCap,
          rc_lengthL,
          new Vector3(rc_offsetXL, rc_offsetY, rc_offsetZL),
          new Vector3(0, -rc_rotYL, 0),
          views,
          1,
          true,
          false,
          this.csgCutGutterLeft2,
          this.roofGroup
        );

        this.roofGroup.add(gutterRakecutGroup);
      }
      if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        let gutterRakecutGroup = this.utils.createGutterGroup(
          this.gutterRakecutRightInfo,
          this.geo_gutterCap,
          rc_lengthR,
          new Vector3(rc_offsetXR, rc_offsetY, rc_offsetZR),
          new Vector3(0, rc_rotYR, 0),
          views,
          1,
          true,
          false,
          this.csgCutGutterRight2,
          this.roofGroup
        );

        this.roofGroup.add(gutterRakecutGroup);
      }
    }
  }
  private addGutterStepRakeCut() {
    if (UI.isUpFasciaUpstandardBracket) {
      return;
    }
    if (
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.STEP &&
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.STEP
    ) {
      return;
    }

    let offsetXL = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );
    let offsetXR =
      this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;
    const offsetZL =
      (this.APP.sldFrontOverhang.currentValue +
        UI.overhangBack +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue -
        this.APP.sldLeftCutVertical.currentValue -
        CONST.GUTTER_ROOF_OFFSET_Z) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);

    const offsetZR =
      (this.APP.sldFrontOverhang.currentValue +
        UI.overhangBack +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue -
        this.APP.sldRightCutVertical.currentValue -
        CONST.GUTTER_ROOF_OFFSET_Z) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);

    const offsetY = 0;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    //Step rakecut
    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
      let gutterLength = this.APP.sldLeftCutHorizontal.currentValue;
      let gutterHor = this.utils.createGutterGroup(
        this.gutterInfo,
        this.geo_gutterCap,
        gutterLength,
        new Vector3(offsetXL, offsetY, offsetZL),
        new Vector3(0, 0, 0),
        views,
        1,
        true,
        true
      );

      this.roofGroup.add(gutterHor);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      let gutterLength = this.APP.sldRightCutHorizontal.currentValue;
      let gutterHor = this.utils.createGutterGroup(
        this.gutterInfo,
        this.geo_gutterCap,
        gutterLength,
        new Vector3(
          offsetXR - this.APP.sldRightCutHorizontal.currentValue,
          offsetY,
          offsetZR
        ),
        new Vector3(0, 0, 0),
        views,
        1,
        true,
        true
      );

      this.roofGroup.add(gutterHor);
    }
  }

  private getHeightByRoofPitch(userDataPos: any): number {
    if (userDataPos.isSpan) {
      return (
        this.utils.tan(this.APP.sltRoofPitch.currentValue) *
        this.APP.sldSpan.currentValue
      );
    } else if (userDataPos.isMultiSpan) {
      return (
        this.utils.tan(this.APP.sltRoofPitch.currentValue) *
        (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue)
      );
    } else {
      return (
        this.utils.tan(this.APP.sltRoofPitch.currentValue) * this.roofLength
      );
    }
  }
}
