<div id="main_wrapper_login">
  <header id="header_wrapper">
    <div id="logo"></div>
    <div id="heading_text">THE FUTURE IN INSULATED ROOFING AND PANELS</div>
    <!-- /* The Future In Insulated Roofing And Wall Panels*/ -->
  </header>

  <div id="login_content">
    <div id="login_content_wrapper">
      <div id="login_form_wrapper">
        <!-- Login Form Code -->
        <div class="column">
            <div class="ui tall stacked segment">
                <h2 class="ui image header">
                    <!-- <img src="assets/images/logo.png" class="image"> -->
                    <div class="login-text">
                    LOG-IN TO YOUR ACCOUNT
                    </div>
                </h2>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="ui piled segment">
                    <div class="form-group">
                        <div class="field">
                            <div class="ui left icon input" style="width: 100%;">
                                <i class="user icon"></i>
                                <input type="text" formControlName="userName" class="form-control"
                                  placeholder="User Name or Email"
                                    [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="field">
                            <div class="ui left icon input" style="width: 100%;">
                                <i class="lock icon"></i>
                                <input type="password" formControlName="password" class="form-control"
                                  placeholder="Password"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                            </div>
                        </div>
                    </div>
                    <button [disabled]="loading" nz-button nzSize="large" class="form_btn" style="width: 100%;">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    LOGIN
                    </button>
                    <div class="form-group">
                      <div class="field">
                        <nz-radio-group class="margin-top-5" formControlName="loginType">
                          <label nz-radio nzValue="SOFTWARE">Software</label>
                          <label nz-radio nzValue="BACKEND">Administrator Page</label>
                        </nz-radio-group>
                      </div>
                    </div>
                    
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </div>
            </form>
            <div class="ui message">
                New to us? <a href={{signUpUrl}}>Sign Up</a>
            </div>
        </div>

      </div>
      <div id="global_image_icon">
      </div>
      <div id="global_image_panel" class="hidden-xs">
        Superior Panel Construction is one of Australia’s leading suppliers<br> of high quality insulated roofing and
        wall cladding panels.
      </div>
    </div>
    <div id="ssc_info">
    </div>

  </div>

  <footer>
    <div id="footer_links">
      <ul>
        <li><a href="#">Help Center</a></li>
        <li><a href="http://superiorpanels.com.au/">About Us</a></li>
      </ul>
    </div>
    <div id="copyright">
      <span><img src="../../../assets/images/ui/eip_image.png" /></span>
      <span>Copyright @ 2020 - SUPERIOR PANEL CONSTRUCTION. All Rights Reserved.</span>
    </div>
  </footer>
  <input type="hidden" id="progress_width" value="0">
</div>
<!-- <div class="col-md-6 offset-md-3 mt-5">
    <div class="alert alert-info">
        Username: admin@gmail.com<br />
        Password: 123456
    </div>
    <div class="card">
        <h4 class="card-header">Login</h4>
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="userName">Username</label>
                    <input type="text" formControlName="userName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" />
                    <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                        <div *ngIf="f.userName.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div> -->