import { Injectable } from "@angular/core";
import { OBJECT_TYPE } from "src/app/app.constants";
import {  Vector3 } from "three";
import { BaseManager } from "src/app/core/base.manager";
import SelectionManager from "../selection.manager";
import { UI } from "src/app/core/ui";
import { HomeComponent } from "src/app/containers/home";
import ClickControl from "src/app/models/ClickControl.model";
import { PATIOS_ROOF_TYPE } from "src/app/app.config";

@Injectable({ providedIn: 'root' })
export class CutOutManager extends BaseManager {
    controls = [
        'sldEaveWidth',
        'sldExistingWallHeight',
        'sldFasciaDepth',
        'sldBaySize',
        'sldExistingLength',
        'sldExistingLength2',
        'sldExistingWidth1',
        'sldExistingWidth2',
        'sltExistingType',
        'sltStructureType',
        'sltWindClass',
        'sltWallType',
        'sltExistingRoofType',
        'sldMultiSpan',
        'sldBuildingHeight',
        'sldFrontOverhang',
        'sldBackOverhang',
        'sldLeftOverhang',
        'sldRightOverhang',
        'sltRoofPitch',
        'sldFlyOverBracketHeight',
        'sldNoOfBay',
        'dialogEditBay',
        'sltExistingRoofPitch',
        'sldRoofOverallLength',
        'sldRoofOverallWidth',
        'sldMinHeight',
        'sltLeftCutType',
        'sltRightCutType',
        'sldLeftCutVertical',
        'sldLeftCutHorizontal',
        'sldRightCutVertical',
        'sldRightCutHorizontal',
        'sltFlatBottom',
        'sltZFlashingType',
        'sltRoofSheetingType',
        'sltBaseFixingType',
        'sltRoofThickness',
        'sltBargeType',
        'sltDownpipeType',
        'sltGutterType',
        'sltBeamType',
        'sltBeamSize',
        'sltReceiverType',
        'sltColumnType',
        'sltColourRoof',
        'sltColourBarge',
        'sltColourGutter',
        'sltColourZFlashing',
        'sltColourPost',
        'sltColourBeam',
        'sltColourBracket',
        'sltColourFasciaBracket',
        'sltColourDownpipe',
        'sltFasciaUpstandBrackets',
    ]
    
    userData = undefined;
    offset = 200;
    startX = 0;

    constructor(private selMgr: SelectionManager){
        super()
    }

    init(): Promise<any> {
        return super.init().then(() => {
            this.userData = { type: OBJECT_TYPE.CLICK_CONTROL_CUTOUT }
        })
    }

    

    load(): Promise<void>{
        return new Promise(resolve => {
            this.addControl();
            HomeComponent.ins.renderStatic();

            resolve()
        })
    }
    
    addControl(){
        this.container.children = [];

        if(this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF){
            return
        }

        let cutoutCondition =
        HomeComponent.ins.sltExistingType.currentValue != 0 &&
        HomeComponent.ins.patiosManager.cutoutCondition &&
        HomeComponent.ins.sldExistingWidth1.currentValue > 0 &&
        HomeComponent.ins.sldExistingLength2.currentValue > 0;

        if (cutoutCondition) {
            let offsetZ = UI.span + UI.multiSpan + UI.overhangFront - UI.existingWidth1 / 2 - (UI.span + UI.multiSpan + UI.overhangFront - UI.existingWidth1) / 2 ;
            if(UI.cutOutType == 0){
                if(UI.existingType == 1 || UI.existingType == 3){
                    new ClickControl(
                        this.container, 
                        { 
                            pos: new Vector3(- UI.existingLength1 / 2 - this.offset, 0, offsetZ),
                            userData: {...this.userData, left: true},
                            rotation: new Vector3(Math.PI / 2,  0, Math.PI)
                        }
                    );
                }
                if(UI.existingType == 2 || UI.existingType == 3){
                    new ClickControl(
                        this.container, 
                        { 
                            pos: new Vector3( UI.existingLength1 / 2 + this.offset, 0, offsetZ),
                            userData: {...this.userData, right: true},
                            rotation: new Vector3(Math.PI / 2,  0, 0)
                        }
                    );
                }
            }
        }
    }
}
