import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { HomeComponent } from 'src/app/containers/home';
import { HomeComponent as HomeComponentRibSpan } from 'src/app-ribspan/containers/home';
import { Router } from '@angular/router';

@Component({
  selector: 'toggle-button',
  templateUrl: './toggle-button.component.html',
  // styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {
  @Input('default') public defaultValue: boolean;
  @Input('defaultContent') public set _defaultContent(val: string){
    this.defaultContent = val;
  };
  @Input('activeContent') public set _activeContent(val: string){
    this.activeContent = val;
  };
  @Input('inactiveContent') public set _inactiveContent(val: string){
    this.inactiveContent = val;
  };
  @Input('defaultShow') public set _show(val: boolean){
    this.isShow = val;
  };
  @Output('onClick') onClick = new EventEmitter();

  currentValue: boolean;
  previousValue: boolean;
  defaultContent: string;
  activeContent: string;
  inactiveContent: string;
  isShow: boolean = true;
  actions: Array<(previousValue: boolean, currentValue: boolean) => void> = [];
  constructor(private router: Router) { }

  ngOnInit() {
    this.currentValue = this.defaultValue || false;
  }

  public addAction(action: (previousValue: boolean, currentValue: boolean) => void) {
    this.actions.push(action);
  }
  public removeAction(action: any): void {
    let idx = this.actions.indexOf(action);
    if (idx !== -1) {
      this.actions.splice(idx, 1);
    }
  }
  onValueChanged(e){
    this.currentValue = !this.currentValue;
    this.onClick.emit(this);
    Promise.all([
      ...this.actions.map(action =>  action(this.previousValue, this.currentValue))
    ]).then(()=>{
      if(this.router.url == '/ribspan'){
        HomeComponentRibSpan.ins.renderStatic();
      } else {
        HomeComponent.ins.renderStatic();
      }
    })
    this.previousValue = this.currentValue;
  }
  public setValue(value: boolean) {
    this.currentValue = value;
    this.onClick.emit(this);
    Promise.all([
      ...this.actions.map(action =>  action(this.previousValue, this.currentValue))
    ]).then(()=>{
      if(this.router.url == '/ribspan'){
        HomeComponentRibSpan.ins.renderStatic();
      } else {
        HomeComponent.ins.renderStatic();
      }
    })
    this.previousValue = this.currentValue;
  }
  public show(value: boolean){
    this.isShow = value;
  }

}
