// import { DropdownInputItemModel } from "./models"
export class Objects {
  public baseColourList = [
    { id: 0x6d6c6e, value: "Basalt", disabled: false },
    {
      id: 0x969799,
      value: "Bluegum",
      disabled: false,
    },
    { id: 0xe9dcb8, value: "Classic Cream", disabled: false },
    { id: 0x304c3c, value: "Cottage Green", disabled: false },
    { id: 0xa59f8a, value: "Cove", disabled: true },
    { id: 0x364152, value: "Deep Ocean", disabled: false },
    {
      id: 0xf9fbf1,
      value: "Dover White",
      disabled: false,
    },
    { id: 0xb1ada3, value: "Dune", disabled: false },
    { id: 0xc5c2aa, value: "Evening Haze", disabled: false },
    { id: 0x857e73, value: "Gully", disabled: false },
    { id: 0x3e434c, value: "Ironstone", disabled: false },
    { id: 0x6c6153, value: "Jasper", disabled: false },
    { id: 0x737562, value: "Mangrove", disabled: true },
    { id: 0x5e1d0e, value: "Manor Red", disabled: false },
    { id: 0x323233, value: "Monument", disabled: false },
    { id: 0x000000, value: "Night Sky", disabled: false },
    { id: 0x7c846a, value: "Pale Eucalypt", disabled: false },
    { id: 0xcabfa4, value: "Paperbark", disabled: false },
    { id: 0xbdbfba, value: "Shale Grey", disabled: false },
    {
      id: 0xd2d1cb,
      value: "Southerly",
      disabled: false,
    },
    { id: 0xe4e2d5, value: "Surfmist", disabled: false },
    { id: 0x67432e, value: "Terrain", disabled: true },
    { id: 0x7f7c78, value: "Wallaby", disabled: false },
    { id: 0xd8e5e6, value: "White", disabled: false },
    { id: 0x888b8a, value: "Windspray", disabled: false },
    { id: 0x4b4c46, value: "Woodland Grey", disabled: false },
    { id: 0xfcffff, value: "Zinc", disabled: false },
  ];
  public beamColourList = [
    { id: 0x000000, value: "Black", beamEndCapColorCode: "B" },
    { id: 0xe9dcb8, value: "Cream", beamEndCapColorCode: "C" },
    { id: 0xe4e2d5, value: "Surfmist", beamEndCapColorCode: "SM" },
    { id: 0xcabfa4, value: "Paperbark", beamEndCapColorCode: "M" },
    { id: 0x323233, value: "Monument", beamEndCapColorCode: "MM" },
  ];
  public postColourList = [
    { id: 0x000000, value: "Black" },
    { id: 0xe9dcb8, value: "Cream" },
    { id: 0xe4e2d5, value: "Surfmist" },
    { id: 0xcabfa4, value: "Paperbark" },
    { id: 0x323233, value: "Monument" },
  ];
  public rakeCutTypeList = [
    { id: 0, value: "None" },
    { id: 1, value: "Left" },
    { id: 2, value: "Right" },
    { id: 3, value: "Left & Right" },
  ];
  public rakeCutType = [
    { id: 0, value: "None" },
    { id: 1, value: "Angle" },
    { id: 2, value: "Step" },
  ];
  public downpipeColourList = [{ id: 0xd8e5e6, value: "White" }];
  public flyoverColourList = [
    { id: 0xd8e5e6, value: "Non Standard Colour" },
    { id: 0x000000, value: "Black" },
    { id: 0xe9dcb8, value: "Cream" },
    { id: 0xe4e2d5, value: "Surfmist" },
    { id: 0xcabfa4, value: "Paperbark" },
    { id: 0x323233, value: "Monument" },
  ];
  public fasciaColourList = [
    { id: 0x000000, value: "Black" },
    { id: 0xe9dcb8, value: "Classic Cream" },
    { id: 0xe4e2d5, value: "Surfmist" },
    { id: 0x323233, value: "Monument" },
  ];
  public existingRoofPitchList = [
    { id: 10, value: "10" },
    { id: 15, value: "15" },
    { id: 20, value: "20" },
    { id: 22.5, value: "22.5" },
    { id: 25, value: "25" },
    { id: 30, value: "30" },
  ];
  public roofPitchList = [
    { id: 2, value: "2" },
    { id: 3, value: "3" },
    { id: 5, value: "5" },
    { id: 7.5, value: "7.5" },
    { id: 10, value: "10" },
  ];
  public gableRoofPitchList = [
    { id: 7.5, value: "7.5" },
    { id: 10, value: "10" },
    { id: 12, value: "12" },
    { id: 15, value: "15" },
    { id: 20, value: "20" },
    { id: 22.5, value: "22.5" },
    { id: 25, value: "25" },
  ];
  public wallList = [{ id: 0, value: "Bricks" }];
  public structureTypeList = [
    { id: 0, value: "Existing Building" },
    { id: 1, value: "Fly Over" },
    { id: 2, value: "Back Fly Over" },
    { id: 3, value: "To Fascia" },
    { id: 5, value: "Fascia Upstand" },
    { id: 6, value: "Back Fascia Upstand" },
    { id: 4, value: "Free Standing" },
  ];
  public structureTypeListGableLR = [
    { id: 1, value: "Fly Over" },
    { id: 5, value: "Fascia Upstand" },
    { id: 4, value: "Free Standing" },
  ];
  public structureTypeListGableFB = [
    { id: 0, value: "Existing Building" },
    { id: 1, value: "Fly Over" },
    { id: 5, value: "Fascia Upstand" },
  ];
  public existingTypeList = [
    { id: 0, value: "None" },
    { id: 1, value: "Left" },
    { id: 2, value: "Right" },
    { id: 3, value: "Both" },
  ];
  public windClassList = [
    { id: 0, value: "N2(W33)" },
    { id: 1, value: "N3(W41)" },
    { id: 2, value: "N4(W50)" },
    { id: 3, value: "C1(W41)" },
    { id: 4, value: "C2(W50)" },
    { id: 5, value: "C3(W60)" },
  ];
  public windClassListGable = [
    { id: 0, value: "N2(W33)" },
    { id: 1, value: "N3(W41)" },
    { id: 2, value: "N4(W50)" },
  ];
  public roofSheetingTypeList = [
    { id: 0, value: "Coolaspan Trimspan" },
    { id: 1, value: "Monopanel" },
    { id: 2, value: "Corropanel" },
  ];
  public flatBottomList = [
    { id: 0xe4e2d5, value: "Surfmist/Off White flat bottom" },
    { id: 0xe9dcb8, value: "Classic Cream bottom" },
  ];
  public downpipeTypeList = [{ id: 0, value: "90mm PVC" }];
  public thicknessList = [
    { id: 0, value: "N/A" },
    { id: 1, value: "50mm" },
    { id: 2, value: "75mm" },
    { id: 3, value: "100mm" },
    { id: 4, value: "125mm" },
    { id: 5, value: "150mm" },
  ];
  public gutterTypeList = [
    { id: 0, value: "Gutter Square Line" },
    { id: 1, value: "Quad 150 Low Front Gutter Unslotted" },
    { id: 2, value: "Quad 150 Hi Front Gutter Slotted" },
  ];
  public beamTypeList = [
    { id: 0, value: "SUPERIOR BEAMS" },
    { id: 1, value: "TIMBER" },
  ];
  public beamSizeList = [
    { id: 0, value: "N/A", beamType: 0 },
    { id: 1, value: "SB110", beamType: 0 },
    { id: 2, value: "SB160", beamType: 0 },
    { id: 3, value: "SB210", beamType: 0 },
    { id: 4, value: "SB260", beamType: 0 },
    { id: 0, value: "N/A", beamType: 1 },
    { id: 1, value: "140x45 F7", beamType: 1 },
    { id: 2, value: "190x45 F7", beamType: 1 },
    { id: 3, value: "150x50 F14", beamType: 1 },
    { id: 4, value: "200x50 F14", beamType: 1 },
    { id: 5, value: "150x75 F14", beamType: 1 },
    { id: 6, value: "200x75 F14", beamType: 1 },
    { id: 7, value: "250x75 F14", beamType: 1 },
  ];

  public gableConditionList = [
    { windClass: [0, 1], beamSize: [1], maxBay: 4000, maxSpan: 4000 },
    { windClass: [0, 1], beamSize: [2], maxBay: 6000, maxSpan: 7000 },
    { windClass: [0, 1], beamSize: [3, 4], maxBay: 8000, maxSpan: 8500 },
    { windClass: [2], beamSize: [2], maxBay: 4000, maxSpan: 4000 },
    { windClass: [2], beamSize: [3, 4], maxBay: 6000, maxSpan: 7000 },
  ];

  public gableBeamConditionList = [
    { windClass: [0, 1], lengthMax: 4000, widthMax: 4000, beamSizeIndex: 1 },
    { windClass: [0, 1], lengthMax: 6000, widthMax: 7000, beamSizeIndex: 2 },
    { windClass: [0, 1], lengthMax: 8000, widthMax: 8500, beamSizeIndex: 3 },
    { windClass: [2], lengthMax: 4000, widthMax: 4000, beamSizeIndex: 2 },
    { windClass: [2], lengthMax: 6000, widthMax: 7000, beamSizeIndex: 3 },
  ];

  public columnTypeList = [
    { id: 0, value: "Superior Post 65mm x 65mm" },
    { id: 1, value: "Superior 90 x 90 Aluminium Post" },
    { id: 2, value: "Superior Post RHS 90mm x 90mm x 3.5mm" },
    { id: 3, value: "Timber" },
  ];
  public columnTypeList2 = [
    {
      id: -1,
      value: "N/A",
      columnHeight: -1,
      baseFixingType: "0, 1", // 'Dynabolt, Post In Ground',
      idx: -1,
    },
    {
      id: 0,
      value: "Superior Post 65mm x 65mm",
      columnHeight: 3000,
      baseFixingType: "0, 1", // 'Dynabolt, Post In Ground',
      idx: 0,
    },
    {
      id: 2,
      value: "Superior Post RHS 90mm x 90mm x 3.5mm",
      columnHeight: 3600,
      baseFixingType: "1", // 'Post In Ground',
      idx: 2,
    },
    {
      id: 1,
      value: "Superior 90 x 90 Aluminium Post",
      columnHeight: 4000,
      baseFixingType: "0, 1", // 'Dynabolt, Post In Ground',
      idx: 1,
    },
    {
      id: 2,
      value: "Superior Post RHS 90mm x 90mm x 3.5mm",
      columnHeight: 4000,
      baseFixingType: "0", // 'Dynabolt',
      idx: 3,
    },
    {
      id: 3,
      value: "Timber",
      columnHeight: 4000,
      baseFixingType: "0, 1", // 'Dynabolt, Post In Ground',
      idx: 4,
    },
  ];
  public baseFixingTypeList = [
    { id: 0, value: "Dynabolt" },
    { id: 1, value: "Post In Ground" },
  ];
  public roles = {
    user: "User",
    customer: "Customer",
    admin: "Admin",
  };
  public cutOutList = [
    { id: 0, value: "No" },
    { id: 1, value: "Yes" },
  ];
  public roofTypeList = [
    { id: 0, value: "Steel Roof" },
    { id: 1, value: "Tiled Roof" },
  ];
  public fasciaBracketList = [
    { id: 0, value: "None" },
    { id: 150, value: "150" },
    { id: 300, value: "300" },
    { id: 450, value: "450" },
    { id: 600, value: "600" },
  ];
}
