import { Color, Vector3, Group, Mesh, MeshBasicMaterial, Object3D, Shape, ShapeBufferGeometry, TextBufferGeometry } from "three";
import { DIMENSION_LABEL_TYPE, GEOMETRY_TYPE } from "src/app/app.config";
import { MaterialManager } from "../core/material.manager";
import { GeometryInfo } from "src/app/core/models";
import SelectionManager from "../core/selection.manager";
import { utils } from "../core/utils";
import { GeometryManager } from "../core/geometry.manager";

export interface AddDimensionOptions {
    container: Object3D,
    start: {x: number, y: number, z: number},
    end: {x: number, y: number, z: number},
    textPos: {x: number, y: number, z: number},
    linePos: {x: number, y: number, z: number},
    lineLength: number;
    views: any,
    allowDrag: boolean,
    textParameter: any,
    labelType: string,
    fitZ: number,
    hasTextClone:boolean,
    axis: string,
    onDragCallback: (obj: Mesh) => void,
    onDragStartCallback: (obj: Mesh) => void,
    subtype?: string,
    textCloneUserData?: {},
    textCloneRotation?: Vector3,
    textCloneVisible?: boolean,
    bayNum?: number,
    textUserData?: {},
    textRotation?: {x: number, y: number, z: number},
    side?: number,
    visible?: boolean
}

export default class Dimension extends Group {
    options: AddDimensionOptions
    mat: MeshBasicMaterial
    lineGeometry: GeometryInfo
    lineCapGeo: GeometryInfo
    meshText: Mesh
    meshBayNo: Mesh
    meshLine: Mesh
    meshCap1: Mesh
    meshCap2: Mesh
    meshTextCover: Mesh
    meshTextClone: Mesh
    meshArrow1: Mesh
    meshArrow2: Mesh

    static create(options: AddDimensionOptions){
        return new Dimension(options)
    }

    constructor(options: AddDimensionOptions){
        super()
        if(!options){
            return this;
        }

        this.lineGeometry = GeometryManager.Instance().getDimensionLine();
        this.lineCapGeo = GeometryManager.Instance().getDimensionCap();
        this.options = options

        this.mat = MaterialManager.Instance().DIMENSION_TEXT.clone();

        let cap1 = new Mesh(this.lineCapGeo.geometry, this.mat);
        cap1.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: this.options.views };
        let cap2 = new Mesh(this.lineCapGeo.geometry, this.mat);
        cap2.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: this.options.views };

        if(this.options.axis === 'x'){
            cap1.position.setX( this.options.linePos.x - this.options.lineLength/2 );
            cap2.position.setX( this.options.linePos.x + this.options.lineLength/2 );
            cap1.position.setY(this.options.linePos.y);
            cap2.position.setY(this.options.linePos.y);
        }
        
        let line = new Mesh(this.lineGeometry.geometry, this.mat);
        line.userData = { type: GEOMETRY_TYPE.DIMENSION_LINE, views: this.options.views };
        line.scale.setX( this.options.lineLength / this.lineGeometry.length);
        line.position.set( this.options.linePos.x, this.options.linePos.y, this.options.linePos.z)

        let textStr = utils.getDimensionValue(this.options.lineLength);
        const textGeo = new TextBufferGeometry(textStr, this.options.textParameter);
        textGeo.center();

        let text = new Mesh(textGeo, this.options.allowDrag ? MaterialManager.Instance().HIGHLIGHT_DIMENSION_TEXT : this.mat);
        text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: this.options.views };
        if(this.options.textUserData){
            text.userData = this.options.textUserData;
        }
        if(this.options.textRotation){
            text.rotateX(this.options.textRotation.x)
            text.rotateY(this.options.textRotation.y)
            text.rotateZ(this.options.textRotation.z)
        }
        if(this.options.textCloneUserData && this.options.textCloneUserData['rotation']){
            text.userData.rotation = this.options.textCloneUserData['rotation']
        }
        text.position.set( this.options.textPos.x, this.options.textPos.y, this.options.textPos.z);
        
        if(this.options.labelType === DIMENSION_LABEL_TYPE.BAY && this.options.bayNum !== undefined){
            let textCover = utils.getTextCover(textGeo, this.options.labelType, new Vector3(this.options.textPos.x, this.options.textPos.y, this.options.textPos.z), this.options.fitZ, this.options.bayNum);
            this.add(textCover);
            this.meshTextCover = textCover;
        } else {
            let textCover = utils.getTextCover(textGeo, this.options.labelType, new Vector3(this.options.textPos.x, this.options.textPos.y, this.options.textPos.z), this.options.fitZ);
            this.add(textCover);
            this.meshTextCover = textCover;
        }

        if(this.options.labelType === DIMENSION_LABEL_TYPE.BAY && this.options.bayNum !== undefined){
            const textGeoBayNo = new TextBufferGeometry(`#${this.options.bayNum + 1}`, this.options.textParameter);
            textGeoBayNo.center();

            let text = new Mesh(textGeoBayNo, this.mat);
            text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: `#${this.options.bayNum + 1}`, views: [] };

            if(this.options.textRotation){
                text.rotateX(this.options.textRotation.x)
                text.rotateY(this.options.textRotation.y)
                text.rotateZ(this.options.textRotation.z)
            }
            if(this.options.textCloneUserData && this.options.textCloneUserData['rotation']){
                text.userData.rotation = this.options.textCloneUserData['rotation']
            }
            text.position.set( this.options.textPos.x, - this.options.textPos.y, this.options.textPos.z);

            this.meshBayNo = text
            this.add(this.meshBayNo);
        }
        if(this.options.subtype){
            cap1.userData['subtype'] = this.options.subtype;
            cap2.userData['subtype'] = this.options.subtype;
            line.userData['subtype'] = this.options.subtype;
            text.userData['subtype'] = this.options.subtype;
        }
        if(this.options.hasTextClone){
            let textClone = text.clone();
            textClone.userData = {...this.options.textCloneUserData, isTextClone: true};
            textClone.rotation.set( this.options.textCloneRotation.x, this.options.textCloneRotation.y, this.options.textCloneRotation.z);
            textClone.visible = this.options.textCloneVisible;
            this.meshTextClone = textClone;
            this.add(this.meshTextClone);
        }
        this.meshText = text;
        this.meshLine = line;
        this.meshCap1 = cap1;
        this.meshCap2 = cap2;

        this.add(this.meshText, this.meshLine, this.meshCap1, this.meshCap2);
        // this.add(text, line, cap1, cap2);
        this.addArrow();
        this.userData.groupType = this.options.labelType
        if(options.side != null && options.side != undefined){
            this.userData.side = options.side
        }
        if(options.bayNum !== undefined){
            this.userData.bayNum = options.bayNum
        }
        this.userData.views = this.options.views || []
        if(!this.options.visible){
            this.visible = this.options.visible
        }
        this.options.container.add(this);
    }
    
    update(options: AddDimensionOptions){
        this.options = options;
        if(this.options.axis === 'x'){
            this.meshCap1.position.setX( this.options.linePos.x - this.options.lineLength/2 );
            this.meshCap2.position.setX( this.options.linePos.x + this.options.lineLength/2 );
            this.meshCap1.position.setY(this.options.linePos.y);
            this.meshCap2.position.setY(this.options.linePos.y);
        }

        this.meshLine.scale.setX( this.options.lineLength / this.lineGeometry.length);
        this.meshLine.position.set( this.options.linePos.x, this.options.linePos.y, this.options.linePos.z)

        let textStr = utils.getDimensionValue(this.options.lineLength);
        const textGeo = new TextBufferGeometry(textStr, this.options.textParameter);
        textGeo.center();

        let text = new Mesh(textGeo, this.options.allowDrag ? MaterialManager.Instance().HIGHLIGHT_DIMENSION_TEXT : this.mat);
        text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: this.options.views };
        if(this.options.textUserData){
            text.userData = this.options.textUserData;
        }
        if(this.options.textRotation){
            text.rotateX(this.options.textRotation.x)
            text.rotateY(this.options.textRotation.y)
            text.rotateZ(this.options.textRotation.z)
        }
        text.position.set( this.options.textPos.x, this.options.textPos.y, this.options.textPos.z);
        
        if(this.options.labelType === DIMENSION_LABEL_TYPE.BAY && this.options.bayNum !== undefined){
            let textCover = utils.getTextCover(textGeo, this.options.labelType, new Vector3(this.options.textPos.x, this.options.textPos.y, this.options.textPos.z), this.options.fitZ, this.options.bayNum);
            this.meshTextCover = textCover;
        } else {
            let textCover = utils.getTextCover(textGeo, this.options.labelType, new Vector3(this.options.textPos.x, this.options.textPos.y, this.options.textPos.z), this.options.fitZ);
            this.meshTextCover = textCover;
        }

        this.meshText = text;
        this.children = this.children.filter(el => el.userData.type != GEOMETRY_TYPE.DIMENSION_TEXT &&  el.userData.type != GEOMETRY_TYPE.TEXT_COVER);
        this.add(this.meshText, this.meshTextCover)

        if(this.options.labelType === DIMENSION_LABEL_TYPE.BAY && this.options.bayNum !== undefined){
            const textGeoBayNo = new TextBufferGeometry(`#${this.options.bayNum + 1}`, this.options.textParameter);
            textGeoBayNo.center();

            let text = new Mesh(textGeoBayNo, this.mat);
            text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: `#${this.options.bayNum + 1}`, views: [] };

            if(this.options.textRotation){
                text.rotateX(this.options.textRotation.x)
                text.rotateY(this.options.textRotation.y)
                text.rotateZ(this.options.textRotation.z)
            }
            if(this.options.textCloneUserData && this.options.textCloneUserData['rotation']){
                text.userData.rotation = this.options.textCloneUserData['rotation']
            }
            text.position.set( this.options.textPos.x, - this.options.textPos.y, this.options.textPos.z);

            this.meshBayNo = text
            this.add(this.meshBayNo);
        }

        if(this.options.hasTextClone){
            let textClone = text.clone();
            textClone.userData = {...this.options.textCloneUserData, isTextClone: true};
            textClone.rotation.set( this.options.textCloneRotation.x, this.options.textCloneRotation.y, this.options.textCloneRotation.z);
            textClone.visible = this.options.textCloneVisible;
            this.meshTextClone = textClone;
            this.children = this.children.filter(el => !el.userData.isTextClone);
            this.add(this.meshTextClone)
        }

        if(this.options.lineLength >= 300){
            this.meshArrow1.visible = true;
            this.meshArrow2.visible = true;
        } else {
            this.meshArrow1.visible = false;
            this.meshArrow2.visible = false;
        }

        if(this.options.axis === 'x'){
            this.meshArrow1.position.set( this.options.linePos.x - this.options.lineLength / 2, 0, 0);
            this.meshArrow2.position.set( this.options.linePos.x + this.options.lineLength / 2, 0, 0);
            this.meshArrow1.position.setY(this.options.linePos.y);
            this.meshArrow2.position.setY(this.options.linePos.y);
        }
    }

    onHover(intersects){
        // if(intersects.find(o => o.object.uuid === this.uuid)){
        //     (this.material as MeshLambertMaterial).opacity = 1
        //     HomeComponent.ins.renderStatic()
        // }
        // else{
        //     // (this.material as MeshLambertMaterial).color = new Color('green')
        //     //this.visible = false
            
        //     (this.material as MeshLambertMaterial).opacity = 0
        //     HomeComponent.ins.renderStatic()
        // }
    }
    onClick(intersects){
        // const obj = intersects.find(o => o.object.uuid === this.uuid)
        // if(obj){
        //     HomeComponent.ins.dialogRakeCut.show(true, obj.object?.userData?.side)
        // }
    }

    addArrow(){
        let arrowLength = 150;
        let angle = 15;

        // if(this.options.lineLength < 300){
        //     return ;
        // }
        
        let y = utils.tan(angle) * arrowLength;
        let shape1 = new Shape();
        shape1.moveTo(0, 0,);
        shape1.lineTo(arrowLength, y);
        shape1.lineTo(arrowLength, -y);
        shape1.closePath();
        const mat = MaterialManager.Instance().DIMENSION_TEXT.clone();
        
        let arrow1 = new Mesh(new ShapeBufferGeometry(shape1), mat);
        arrow1.userData = { views: this.options.views, type: GEOMETRY_TYPE.DIMENSION_CAP, dimType: this.options.labelType, right: true };
        let arrow2 = arrow1.clone();
        arrow2.rotateZ(Math.PI);
        arrow2.userData = { views: this.options.views, type: GEOMETRY_TYPE.DIMENSION_CAP, dimType: this.options.labelType, left: true};

        if( this.options.labelType === DIMENSION_LABEL_TYPE.BAY){
            arrow1.userData['bayNum'] = this.options.bayNum;
            arrow2.userData['bayNum'] = this.options.bayNum;
        }
        if(this.options.axis === 'x'){
            arrow1.position.set( this.options.linePos.x - this.options.lineLength / 2, 0, 0);
            arrow2.position.set( this.options.linePos.x + this.options.lineLength / 2, 0, 0);
            arrow1.position.setY(this.options.linePos.y);
            arrow2.position.setY(this.options.linePos.y);
        }

        if(this.options.allowDrag){
            mat.color = new Color('yellow');
        }
        if(this.options.subtype){
            arrow1.userData['subtype'] = this.options.subtype;
            arrow2.userData['subtype'] = this.options.subtype;
        }

        if(this.options.lineLength < 300){
            arrow1.visible = false;
            arrow2.visible = false;
        }

        this.meshArrow1 = arrow1;
        this.meshArrow2 = arrow2;

        this.add(this.meshArrow1, this.meshArrow2)
        // this.add(arrow1, arrow2)

        if(this.options.allowDrag){
            SelectionManager.ins.addEventListener('dragstart', this.options.onDragStartCallback, this.meshArrow1)
            SelectionManager.ins.addEventListener('dragstart', this.options.onDragStartCallback, this.meshArrow2)

            SelectionManager.ins.addEventListener('drag', this.options.onDragCallback, this.meshArrow1)
            SelectionManager.ins.addEventListener('drag', this.options.onDragCallback, this.meshArrow2)
        }
    }
}