import { Component, OnInit } from "@angular/core";


@Component({
    selector: 'switch-input',
    template: '<nz-switch [(ngModel)]="currentValue" nzCheckedChildren="Right -> Left" nzUnCheckedChildren="Left -> Right" nzTooltipTitle="Only Admin can make this change" nz-tooltip (ngModelChange)="onStateChanged()"></nz-switch>'
})
export class SwitchInputComponent implements OnInit {
    // set _currentValue(value: boolean) {
    //     this.currentValue = value ? 1 : 0;
    // }

    // get _currentValue(): boolean {
    //     return !(this.currentValue == 0);
    // };

    currentValue: boolean = false;
    previousValue: boolean;

    actions: Array<{
        priority: number;
        actions: Array<(previousValue: boolean, currentValue: boolean) => void>;
    }> = [];

    ngOnInit(): void {

    }

    onStateChanged() {
        for (let item of this.actions) {
            item.actions.forEach(action => {
                action(this.previousValue, this.currentValue);
            });
        }

        this.previousValue = this.currentValue;
    }

    public addAction(
        action: (previousValue: boolean, currentValue: boolean) => void,
        priority?: number
    ) {
        priority = priority || 3;
        let actionsByPriority = this.actions.find(a => a.priority === priority);
        if (actionsByPriority) {
            actionsByPriority.actions.push(action);
        } else {
            this.actions.push({ priority: priority, actions: [action] });
        }

        this.actions.sort((a, b) => a.priority - b.priority);
    }
    public removeAction(action: any): void {
        for (let item of this.actions) {
            const idx = item.actions.indexOf(action);

            if (idx !== -1) {
                item.actions.splice(idx, 1);
            }
        }
    }
    public setValue(value: boolean) {
        this.currentValue = value;
        //this.onStateChanged();
    }
}