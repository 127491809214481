import { Mesh, Vector3, Material, Plane, Geometry, BufferGeometry, Group, BoxBufferGeometry, MeshLambertMaterial, BoxHelper, Matrix4, PlaneHelper, Points, PointsMaterial, Color, PlaneBufferGeometry, DoubleSide, Object3D, LineSegments } from 'three';
import { Util } from '../utils';
import { CONFIG as env, GEOMETRY_TYPE, GEOMETRY_CATEGORY } from 'src/app/app.config';
import { BUILDING_SIDE, CUTOUT_ENABLE, EXISTING_BUILDING_CONFIG as CONST, EXISTING_BUILDING_CONFIG, PANEL_DIRECTION, RAKECUT_TYPE, RIBSPAN_PANEL_WIDTH, RIBSPAN_BARGE_FIT, RIBSPAN_RECEIVER_CHANNEL_FIT } from 'src/app/app.constants';
import { HomeComponent as AppComponent } from '../../containers/home/home.component';
import { GeometryManager } from '../geometry.manager';
import { MaterialManager } from '../material.manager';
import { GeometryInfo, Printing2DGeometry, Printing2DLine, Print2DView, ViewType, LineType } from 'src/app/core/models';
import { ExistingBuildingManager } from '.';
import { CSG } from 'three-csg-ts';
import HighLightBox from 'src/app-ribspan/models/HighlightBox';
import { UI } from '../ui';
import SelectionManager from '../selection.manager';

export class RoofManager {
  private scene: Group;
  private APP: AppComponent;
  private MANAGER: ExistingBuildingManager;
  private material: Material;
  private utils: Util;

  private geometryManager: GeometryManager;

  private roofInfo: GeometryInfo;
  private bargeLeftInfo: GeometryInfo;
  private bargeRightInfo: GeometryInfo;
  private receiverCutoutLeftHor: GeometryInfo;
  private receiverCutoutRightHor: GeometryInfo;
  private receiverCutoutRightVer: GeometryInfo;
  private receiverCutoutRightVer2: GeometryInfo;
  private receiverCutoutLeftVer: GeometryInfo;
  private receiverCutoutLeftVer2: GeometryInfo;
  private gutterInfo: GeometryInfo;
  private gutterRakecutLeftInfo: GeometryInfo;
  private gutterRakecutRightInfo: GeometryInfo;
  //private flashingInfo: GeometryInfo;
  private geo_receiverChanel: GeometryInfo;
  private geo_gutterCap: GeometryInfo;
  private roofGroup: Group;

  private eventHandleId: any;

  // Var
  private roofLengthPlus: number;
  private roofWidth: number;
  private roofLength: number;
  private totalBaySize: number;

  private objectSizeChangedHandle: any;

  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;

  private mesh_CutoutLeft: Mesh;
  private csg_cutoutLeft: CSG;
  private mesh_CutoutRight: Mesh;
  private csg_cutoutRight: CSG;
  private geo_cutoutLeft: BoxBufferGeometry;
  private geo_cutoutRight: BoxBufferGeometry;

  private geo_rakecutLeft: BoxBufferGeometry;
  private geo_rakecutRight: BoxBufferGeometry;
  private mesh_rakecutLeft: Mesh;
  private mesh_rakecutRight: Mesh;
  private csg_rakecutLeft: CSG;
  private csg_rakecutRight: CSG;

  private geoCutGutter: BoxBufferGeometry;
  private meshCutGutterLeft1: Mesh;
  private csgCutGutterLeft1: CSG;
  private meshCutGutterLeft2: Mesh;
  private csgCutGutterLeft2: CSG;

  private meshCutGutterRight1: Mesh;
  private csgCutGutterRight1: CSG;
  private meshCutGutterRight2: Mesh;
  private csgCutGutterRight2: CSG;

  private enableCutout: boolean;
  private deferHandle;
  private deferTimeout = EXISTING_BUILDING_CONFIG.CUTOUT_DEFFER_TIME_OUT;


  constructor(app: AppComponent, existingManager: ExistingBuildingManager) {
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.APP = app;
    this.MANAGER = existingManager;
    this.scene = new Group();
    existingManager.patiosGroup.add(this.scene)
    this.material = MaterialManager.Instance().ROOF;
    this.registerEvent();
  }

  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.roofInfo = this.geometryManager.getRoofRibspanPanel();
      this.roofInfo.geometry
        .translate(-this.roofInfo.width / 2, this.roofInfo.height / 2, -this.roofInfo.length / 2);
      this.roofInfo.geometry.rotateY(Math.PI)
      this.utils.changeGeoColor(this.roofInfo.geometry, new Vector3(0, 1, 0), UI.roofColor.toString());

      // Barge capping - left
      this.bargeLeftInfo = this.geometryManager.getRibspanBarge();
      this.bargeLeftInfo.geometry
        .rotateY(Math.PI)
        .translate(this.bargeLeftInfo.width / 2 - 3, this.bargeLeftInfo.height / 2 - RIBSPAN_BARGE_FIT, this.bargeLeftInfo.length / 2);

      // Barge capping - right
      this.bargeRightInfo = this.geometryManager.getRibspanBarge();
      this.bargeRightInfo.geometry
        .translate(-this.bargeLeftInfo.width / 2 + 3, this.bargeLeftInfo.height / 2 - RIBSPAN_BARGE_FIT, this.bargeLeftInfo.length / 2);

      //Barge cutout left
      this.receiverCutoutLeftHor = this.geometryManager.getRibspanReceiverChannel();
      this.receiverCutoutLeftHor.geometry
        .rotateY(Math.PI / 2)
        .translate(-this.receiverCutoutLeftHor.length / 2, this.receiverCutoutLeftHor.height / 2 - RIBSPAN_RECEIVER_CHANNEL_FIT, this.receiverCutoutLeftHor.width / 2);

      //Barge cutout right
      this.receiverCutoutRightHor = this.geometryManager.getRibspanReceiverChannel();
      this.receiverCutoutRightHor.geometry
        .rotateY(Math.PI / 2)
        .translate(this.receiverCutoutRightHor.length / 2, this.receiverCutoutRightHor.height / 2 - RIBSPAN_RECEIVER_CHANNEL_FIT, this.receiverCutoutRightHor.width / 2);

        
      //Vertical Barge cutout left 2
      this.receiverCutoutLeftVer2 = this.geometryManager.getRibspanReceiverChannel();
      this.receiverCutoutLeftVer2.geometry
        .rotateY(Math.PI)
        .translate(this.receiverCutoutLeftVer2.width / 2, this.receiverCutoutLeftVer2.height / 2 - RIBSPAN_RECEIVER_CHANNEL_FIT, this.receiverCutoutLeftVer2.length / 2);

      //Vertical cutout right 2
      this.receiverCutoutRightVer2 = this.geometryManager.getRibspanReceiverChannel();
      this.receiverCutoutRightVer2.geometry
        .translate(-this.receiverCutoutRightVer2.width / 2, this.receiverCutoutRightVer2.height / 2 - RIBSPAN_RECEIVER_CHANNEL_FIT, this.receiverCutoutRightVer2.length / 2);

      //Vertical Barge cutout left
      this.receiverCutoutLeftVer = this.geometryManager.getRibspanReceiverChannel();
      this.receiverCutoutLeftVer.geometry
        .rotateY(Math.PI)
        .translate(this.receiverCutoutLeftVer.width / 2, this.receiverCutoutLeftVer.height / 2 - RIBSPAN_RECEIVER_CHANNEL_FIT, this.receiverCutoutLeftVer.length / 2);

      //Vertical cutout right
      this.receiverCutoutRightVer = this.geometryManager.getRibspanReceiverChannel();
      this.receiverCutoutRightVer.geometry
        .translate(-this.receiverCutoutRightVer.width / 2, this.receiverCutoutRightVer.height / 2 - RIBSPAN_RECEIVER_CHANNEL_FIT, this.receiverCutoutRightVer.length / 2);

      //Receiver chanel - barge caping back
      this.geo_receiverChanel = this.geometryManager.getRibspanReceiverChannel();
      this.geo_receiverChanel.geometry
        .rotateY(Math.PI / 2)
        .translate(this.geo_receiverChanel.length / 2, this.geo_receiverChanel.height / 2 + 2 - RIBSPAN_RECEIVER_CHANNEL_FIT, this.geo_receiverChanel.width / 2);

      // Gutter
      let gtExtraOffsetY = 0;
      if (this.APP.sltGutterType.currentValue == 1 || this.APP.sltGutterType.currentValue == 2) {
        gtExtraOffsetY = 20;
      }

      this.gutterInfo = this.geometryManager.getGutter();
      this.gutterInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(this.gutterInfo.length / 2, -20, this.gutterInfo.width / 2);

      this.gutterRakecutLeftInfo = this.geometryManager.getGutter();
      this.gutterRakecutLeftInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(this.gutterRakecutLeftInfo.length / 2, -20, this.gutterRakecutLeftInfo.width / 2);

      this.gutterRakecutRightInfo = this.geometryManager.getGutter();
      this.gutterRakecutRightInfo.geometry
        .rotateY(-Math.PI / 2)
        .translate(-this.gutterRakecutRightInfo.length / 2, -20, this.gutterRakecutRightInfo.width / 2);

      //Gutter cap
      this.geo_gutterCap = this.geometryManager.getGuterEndCap();
      this.geo_gutterCap.geometry
        .rotateY(-Math.PI / 2)
        .translate(0, -20, this.geo_gutterCap.length / 2);

      this.geo_cutoutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutLeft.translate(-500, 0, -500);

      this.geo_cutoutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutRight.translate(500, 0, -500);

      this.geo_rakecutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutLeft.translate(-500, 500, 500);
      this.geo_rakecutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutRight.translate(500, 500, 500);

      this.geoCutGutter = new BoxBufferGeometry(500, 10000, 500);
      this.geoCutGutter.translate(0, 0, -250);

      resolve();
    });
  }
  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(...this.scene.children.filter(x => x.userData.type === GEOMETRY_TYPE.ROOF_PANEL 
        || x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BASE
        || x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING
        || x.userData.type === GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        || x.userData.type === GEOMETRY_TYPE.GUTTER_PATIOS
        || x.userData.type === GEOMETRY_TYPE.ROOF_PATIOS
        || x.userData.type === GEOMETRY_TYPE.HIGHLIGHT_BOX));

      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.SHEET_OUTLINE));
      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'HELPER'));
      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'RAKECUT'));
      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'CUTGUTTER'));

      this.totalBaySize = this.APP.dialogEditBay.totalBaySize;
      this.roofLength = this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + this.APP.sldFrontOverhang.currentValue;
      const _roofLength = Math.pow(this.roofLength, 2) + Math.pow(this.getHeightByRoofPitch({ isMultiSpan: true }), 2);
      this.roofLengthPlus = Math.sqrt(_roofLength);
      this.roofWidth = this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue;

      this.addRoofGroup();
      this.addCutout();
      if(UI.rakeCutLeftType == 0){
        new HighLightBox(this.roofGroup, {
          min: {
            x: -(UI.totalBayLength / 2 + UI.overhangLeft), 
            y: 0, 
            z: UI.span + UI.multiSpan + UI.overhangFront, 
          },
          max: {
            x: -(UI.totalBayLength / 2 + UI.overhangLeft) + 300, 
            y: 0, 
            z: UI.span + UI.multiSpan + UI.overhangFront - 300,
          },
          userData: { side: BUILDING_SIDE.LEFT }
        })
      }
      if(UI.rakeCutRightType == 0){
        new HighLightBox(this.roofGroup, {
          min: {
            x: UI.totalBayLength / 2 + UI.overhangRight - 300, 
            y: 0, 
            z: UI.span + UI.multiSpan + UI.overhangFront, 
          },
          max: {
            x: UI.totalBayLength / 2 + UI.overhangRight,
            y: 0,
            z: UI.span + UI.multiSpan + UI.overhangFront - 300,
          },
          userData: { side: BUILDING_SIDE.RIGHT }
        })
      }

      //Add angle rakecut
      if(this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE){
        this.addAngleRakeCut(BUILDING_SIDE.LEFT);
      }
      if(this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE){
        this.addAngleRakeCut(BUILDING_SIDE.RIGHT);
      }

      //Add step rakecut
      if(this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP){
        this.addStepRakecut(BUILDING_SIDE.LEFT);
      }
      if(this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP){
        this.addStepRakecut(BUILDING_SIDE.RIGHT);
      }
     

      let offsetXL = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
      let offsetXR = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue + this.MANAGER.patiosGroup.position.x;

      let roofPanelCount = Math.ceil(this.roofWidth / RIBSPAN_PANEL_WIDTH);
      this.enableCutout = this.MANAGER.cutoutCondition;

      //clipping plane
      let offsetLeft = this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue - this.MANAGER.patiosGroup.position.x;
      let clipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
      const clipingPlane = new Plane(new Vector3(-1, 0, 0), offsetXR);
      this.material.clippingPlanes = [clipingPlaneLeft, clipingPlane];
      MaterialManager.Instance().ROOF_BASE.clippingPlanes = [clipingPlaneLeft, clipingPlane];

      //panel direction
      let panelDirectionOfset = 1;
      if(this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.RIGHT_TO_LEFT){
          offsetXL = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue - RIBSPAN_PANEL_WIDTH;
          panelDirectionOfset = -1;
      }
      else if(this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.LEFT_TO_RIGHT){
        offsetXL = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
      }

      if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT && this.enableCutout) {
        let totalPanelLength = 0;
        let cuted = true;
        for (let index = 0; index < roofPanelCount; index++) {
          totalPanelLength += RIBSPAN_PANEL_WIDTH;
          let isOver = totalPanelLength < this.APP.sldExistingLength2.currentValue;
          this.addRoof(offsetXL, [this.csg_cutoutLeft]);
          cuted = isOver;

          offsetXL += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      }
      else if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT && this.enableCutout) {
        let totalPanelLength = 0;
        let cuted = false;
        for (let index = 0; index < roofPanelCount; index++) {
          totalPanelLength += RIBSPAN_PANEL_WIDTH;
          let isOver = totalPanelLength >= this.roofWidth - this.APP.sldExistingLength2.currentValue + RIBSPAN_PANEL_WIDTH;
          cuted = totalPanelLength >= this.roofWidth - this.APP.sldExistingLength2.currentValue && !isOver;
          this.addRoof(offsetXL, [this.csg_cutoutRight] );

          offsetXL += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      }
      else if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH && this.enableCutout) {
        let totalPanelLength = 0;
        for (let index = 0; index < roofPanelCount; index++) {
          totalPanelLength += RIBSPAN_PANEL_WIDTH;
          this.addRoof(offsetXL, [this.csg_cutoutLeft, this.csg_cutoutRight]);
          offsetXL += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      }
      else {
        for (let index = 0; index < roofPanelCount; index++) {
          this.addRoof(offsetXL, []);

          offsetXL += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
        }
      }

      this.addBarge();
      this.addBargeStepRakecut();
      this.addGutter();
      this.addGutterAngelRakecut();
      this.addGutterStepRakecut();
      this.showPanelOutline();

      this.scene.visible = UI.showRoof

      //this.getOutLines();
      resolve();
    });
  }
  private addCutout() {
    if (this.APP.sltCutOut.currentValue == 1) {
      this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'CUTOUT'));

      let offsetX = this.APP.sldExistingLength.currentValue / 2;
      let offsetY = 0;
      let offsetZ = this.APP.sldExistingWidth1.currentValue / 2;

      let scaleX = (this.APP.sldExistingLength2.currentValue + 5000) / 1000;
      if (scaleX == 0) scaleX = 1;
      let scaleY = (this.APP.sldExistingWallHeight.currentValue + 5000) / 1000;
      let scaleZ = (this.APP.sldExistingWidth1.currentValue + 5000) / 1000;
      if (scaleZ == 0) scaleZ = 1;

      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT || this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        this.mesh_CutoutLeft = new Mesh(this.geo_cutoutLeft, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
        this.mesh_CutoutLeft.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: 'CUTOUT' };

        this.mesh_CutoutLeft.position.set(-offsetX, offsetY, offsetZ);
        this.mesh_CutoutLeft.scale.set(scaleX, scaleY, scaleZ);

        this.mesh_CutoutLeft.updateMatrix();
        this.csg_cutoutLeft = CSG.fromMesh(this.mesh_CutoutLeft);
      }
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT || this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        this.mesh_CutoutRight = new Mesh(this.geo_cutoutRight, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
        this.mesh_CutoutRight.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: 'CUTOUT' };

        this.mesh_CutoutRight.position.set(offsetX, offsetY, offsetZ);
        this.mesh_CutoutRight.scale.set(scaleX, scaleY, scaleZ);

        this.mesh_CutoutRight.updateMatrix();
        this.csg_cutoutRight = CSG.fromMesh(this.mesh_CutoutRight);
      }
    }
  }
  
  public addRoofGroup() {
    this.roofGroup = null;
    this.roofGroup = new Group();
    this.roofGroup.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PATIOS };

    //let offsetX = -this.APP.sldLeftOverhang.currentValue;
    let offsetY = this.APP.sldBuildingHeight.currentValue;
    let offsetZ = -this.APP.sldExistingWidth1.currentValue / 2;

    this.roofGroup.position.set(0, offsetY, offsetZ);
    this.roofGroup.rotation.set(this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), 0, 0);

    this.scene.add(this.roofGroup);
  }
  public getOutLines(): Printing2DGeometry {

    let objs = this.roofGroup.children.filter(o =>
      o.userData.type == GEOMETRY_TYPE.ROOF_PANEL
      || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    );

    let gutterGroupObjs = this.roofGroup.children.filter(o => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS);
    let gutterObjs: Object3D[] = [];
    for(let group of gutterGroupObjs){
      gutterObjs.push(...group.children);
    }

    objs = [...objs, ...gutterObjs];

    let lsGeometries: Printing2DLine[] = [];

    let offsetLeft = this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue - this.MANAGER.patiosGroup.position.x;
    let offsetRight = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue + this.MANAGER.patiosGroup.position.x;
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlane = new Plane(new Vector3(-1, 0, 0), offsetRight);

    for (let o of objs) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale((o as Mesh), 60);
      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);
      outlineGeo.translate(0, 5000, 0);

      if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE) {
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlane);
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
      }

      let simplifiedGeo = this.simplifyGeo(outlineGeo);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: simplifiedGeo.vertices,
        views: o.userData.views
      });
    }

    lsGeometries.push({
      objectType: GEOMETRY_TYPE.ROOF_COVER,
      vertices: this.makeRoofPolygon(),
      color: this.utils.convertHexColorToRgb(`#${new Color(UI.panelColor).getHexString()}`),
      views: [
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
      ]
    });

    return { lines: lsGeometries, texts: [] };
  }
  makeRoofPolygon() {
    const polygon = this.utils.getRoofCoverPolygonForLRRoof()
    this.roofGroup.updateWorldMatrix(true, true)
    return polygon.map(el => (el as Vector3).applyMatrix4(this.roofGroup.matrixWorld))
  }
  public showPanelOutline(){
    if(this.APP.sltPanelDirectionShow.currentValue == false)
        return;

    let objs = this.roofGroup.children.filter(o =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    );

    let offsetLeft = this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue - this.MANAGER.patiosGroup.position.x;
    let offsetRight = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue + this.MANAGER.patiosGroup.position.x;
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);
    

    for (let o of objs) {
        let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale((o as Mesh), 60);

        o.updateWorldMatrix(true, true);
        outlineGeo.applyMatrix4(o.matrixWorld);

        if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE) {
            outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
            outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
        }

        var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
        line.userData = {type: GEOMETRY_TYPE.SHEET_OUTLINE};
        this.APP.scene.add( line );
    }
}
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let simplifiedGeo = new Geometry();
    let vertices = geo.getAttribute('position').array;
    for (let i = 0; i < vertices.length; i += 3) {
      simplifiedGeo.vertices.push(new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2]));
    }
    return simplifiedGeo;
  }
  public addRoof(offsetX: number, csgObjs: CSG[]) {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];
    const mesh: Mesh = new Mesh(this.roofInfo.geometry, MaterialManager.Instance().ROOF);

    let offsetSheet = 0;
    if (this.APP.sltRoofSheetingType.currentValue == 0 || this.APP.sltRoofSheetingType.currentValue == 1) {
      offsetSheet = 13;
    }
    else {
      offsetSheet = 5;
    }

    let scaleZ = this.roofLengthPlus / this.roofInfo.length;
    const offsetY = 0;
    let offsetZ = 0;
    mesh.position.set(offsetX, offsetY, offsetZ);
    //mesh.rotateX(this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue));
    mesh.scale.setZ(scaleZ);
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PANEL, views: views };
    
    let cutoutCondition = this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.APP.sltExistingType.currentValue != BUILDING_SIDE.NONE && this.APP.sldExistingLength2.currentValue > 0;
    let rakeCutCondition = this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE || this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE;
    
    if (cutoutCondition || rakeCutCondition) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();
      //cho no cung he toa do => cai nay khong delay
      mesh.position.set(offsetX + this.MANAGER.patiosGroup.position.x, offsetY, offsetZ + this.roofGroup.position.z);
      mesh.updateMatrix();

      let meshResult = mesh.clone();

      if(cutoutCondition){
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
        }
      }
      if(rakeCutCondition){
        if(this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE){
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(this.csg_rakecutLeft), mesh.matrix);
        }
        if(this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE){
          meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(this.csg_rakecutRight), mesh.matrix);
        }
      }
      
      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix)); //=> tra ve goc toa do
      meshResult.applyMatrix4(matrixBefore);

      let buferGeo = new BufferGeometry().fromGeometry((meshResult as Mesh).geometry as Geometry);
      this.utils.changeGeoColor(buferGeo, new Vector3(0, 1, 0), UI.roofColor.toString());
      let newGeo = new Geometry().fromBufferGeometry(buferGeo);
      (meshResult as Mesh).geometry = newGeo;

      this.roofGroup.add(meshResult);
    }
    else {
      this.roofGroup.add(mesh);
    }
  }
  private addAngleRakeCut(cutSide: BUILDING_SIDE) {
    let offsetXL = -this.totalBaySize / 2 - this.APP.sldLeftOverhang.currentValue;
    let offsetXR = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;
    let offsetY = -100;

    let offsetZ = this.MANAGER.patiosLength - this.APP.sldExistingWidth1.currentValue / 2;

    let angle;

    if(cutSide == BUILDING_SIDE.LEFT){
      angle = Math.atan(this.APP.sldLeftCutHorizontal.currentValue / this.APP.sldLeftCutVertical.currentValue);
    }
    else if(cutSide == BUILDING_SIDE.RIGHT){
      angle = Math.atan(this.APP.sldRightCutHorizontal.currentValue / this.APP.sldRightCutVertical.currentValue);
    }
    
    const _offsetZ = (this.APP.sldFrontOverhang.currentValue
      + this.APP.sldMultiSpan.currentValue
      + this.APP.sldSpan.currentValue
      //- this.APP.sldExistingWidth1.currentValue/2
      - CONST.GUTTER_ROOF_OFFSET_Z) / this.utils.cos(this.APP.sltRoofPitch.currentValue);

    let _rotY;
    if(cutSide == BUILDING_SIDE.LEFT){
      _rotY = Math.atan(this.APP.sldLeftCutVertical.currentValue / this.APP.sldLeftCutHorizontal.currentValue);
    }
    else if(cutSide == BUILDING_SIDE.RIGHT){
      _rotY = Math.atan(this.APP.sldRightCutVertical.currentValue / this.APP.sldRightCutHorizontal.currentValue);
    }
    let _angle = (Math.PI - _rotY)/2;

    let hypotenuse = Math.sqrt(Math.pow(this.MANAGER.patiosLength, 2) + Math.pow(this.MANAGER.patiosWidth, 2));
    let scaleLength = hypotenuse/1000;
    let scaleWidth = this.MANAGER.patiosLength * this.MANAGER.patiosWidth / hypotenuse/1000;

    if (cutSide == BUILDING_SIDE.LEFT) {
      this.mesh_rakecutLeft = new Mesh(this.geo_rakecutLeft, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
      this.mesh_rakecutLeft.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: 'RAKECUT' };

      this.mesh_rakecutLeft.position.set(offsetXL + this.MANAGER.patiosGroup.position.x, offsetY, offsetZ - this.APP.sldLeftCutVertical.currentValue);
      this.mesh_rakecutLeft.scale.set(scaleWidth, 5, scaleLength);
      this.mesh_rakecutLeft.rotateY(angle);
      this.mesh_rakecutLeft.updateMatrix();
      this.csg_rakecutLeft = CSG.fromMesh(this.mesh_rakecutLeft);
      //this.APP.scene.add(this.mesh_rakecutLeft);

      //cut gutter csg
      let scaleZ = (this.APP.dialogEditBay.totalBaySize + 200)/500;
      let scaleX = (this.APP.dialogEditBay.totalBaySize + 200)/500;
      this.meshCutGutterLeft1 = new Mesh(this.geoCutGutter, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
      this.meshCutGutterLeft1.userData = {category: GEOMETRY_CATEGORY.PATIOS, type: 'CUTGUTTER'};
      this.meshCutGutterLeft1.position.set(offsetXL + this.APP.sldLeftCutHorizontal.currentValue, 0, _offsetZ);
      this.meshCutGutterLeft1.scale.set(scaleX, 1, scaleZ);
      this.meshCutGutterLeft1.rotation.set(0,_angle,0);
      this.meshCutGutterLeft1.updateMatrix();
      this.csgCutGutterLeft1 = CSG.fromMesh(this.meshCutGutterLeft1);
      //this.APP.scene.add(this.meshCutGutterLeft1);

      this.meshCutGutterLeft2 = new Mesh(this.geoCutGutter, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
      this.meshCutGutterLeft2.userData = {category: GEOMETRY_CATEGORY.PATIOS, type: 'CUTGUTTER'};
      this.meshCutGutterLeft2.position.set(offsetXL + this.APP.sldLeftCutHorizontal.currentValue, 0, _offsetZ);
      this.meshCutGutterLeft2.scale.set(scaleX, 1, scaleZ);
      this.meshCutGutterLeft2.rotation.set(0,_angle + Math.PI,0);
      this.meshCutGutterLeft2.updateMatrix();
      this.csgCutGutterLeft2 = CSG.fromMesh(this.meshCutGutterLeft2);
    }
    if (cutSide == BUILDING_SIDE.RIGHT) {
      this.mesh_rakecutRight = new Mesh(this.geo_rakecutRight, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
      this.mesh_rakecutRight.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: 'RAKECUT' };

      this.mesh_rakecutRight.position.set(offsetXR  + this.MANAGER.patiosGroup.position.x, offsetY, offsetZ - this.APP.sldRightCutVertical.currentValue);
      this.mesh_rakecutRight.scale.set(scaleWidth, 5, scaleLength);
      this.mesh_rakecutRight.rotateY(-angle);

      //this.APP.scene.add(this.mesh_rakecutRight);

      this.mesh_rakecutRight.updateMatrix();
      this.csg_rakecutRight = CSG.fromMesh(this.mesh_rakecutRight);


      //cut gutter
      let scaleZ = (this.APP.dialogEditBay.totalBaySize + 200)/500;
      let scaleX = (this.APP.dialogEditBay.totalBaySize + 200)/500;
      this.meshCutGutterRight1 = new Mesh(this.geoCutGutter, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
      this.meshCutGutterRight1.userData = {category: GEOMETRY_CATEGORY.PATIOS, type: 'CUTGUTTER'};
      this.meshCutGutterRight1.position.set(offsetXR - this.APP.sldRightCutHorizontal.currentValue, 0, _offsetZ);
      this.meshCutGutterRight1.rotation.set(0,-_angle,0);
      this.meshCutGutterRight1.scale.set(scaleX, 1, scaleZ);
      this.meshCutGutterRight1.updateMatrix();
      this.csgCutGutterRight1 = CSG.fromMesh(this.meshCutGutterRight1);

      this.meshCutGutterRight2 = new Mesh(this.geoCutGutter, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
      this.meshCutGutterRight2.userData = {category: GEOMETRY_CATEGORY.PATIOS, type: 'CUTGUTTER'};
      this.meshCutGutterRight2.position.set(offsetXR - this.APP.sldRightCutHorizontal.currentValue, 0, _offsetZ);
      this.meshCutGutterRight2.scale.set(scaleX, 1, scaleZ);
      this.meshCutGutterRight2.rotation.set(0,-(_angle + Math.PI),0);
      this.meshCutGutterRight2.updateMatrix();
      this.csgCutGutterRight2 = CSG.fromMesh(this.meshCutGutterRight2);
      //this.APP.scene.add(this.meshCutGutterRight2);
    }
  }
  private addStepRakecut(cutSide: BUILDING_SIDE) {
    let offsetXL = -this.totalBaySize / 2 - this.APP.sldLeftOverhang.currentValue + this.MANAGER.patiosGroup.position.x;
    let offsetXR = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue + this.MANAGER.patiosGroup.position.x;
    let offsetY = -100;//this.APP.sldExistingWallHeight.currentValue;
    // let offsetY = this.utils.getHeightByAngle(
    //     this.APP.sldExistingWallHeight.currentValue + this.APP.sldFlyOverBracketHeight.currentValue, 
    //     this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue, 
    //     this.APP.sltRoofPitch.currentValue, 
    //     1
    // );

    let offsetZ = this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + this.APP.sldFrontOverhang.currentValue - this.APP.sldExistingWidth1.currentValue / 2;
    

    if (cutSide == BUILDING_SIDE.LEFT) {
      let scaleLength = (this.APP.sldLeftCutVertical.currentValue + 100)/1000;
      let scaleWidth = (this.APP.sldLeftCutHorizontal.currentValue + 100)/1000;

      this.mesh_rakecutLeft = new Mesh(this.geo_rakecutLeft, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
      this.mesh_rakecutLeft.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: 'RAKECUT' };

      this.mesh_rakecutLeft.position.set(offsetXL + this.APP.sldLeftCutHorizontal.currentValue, offsetY, offsetZ - this.APP.sldLeftCutVertical.currentValue);
      this.mesh_rakecutLeft.scale.set(scaleWidth, 5, scaleLength);

      //this.APP.scene.add(this.mesh_rakecutLeft);

      this.mesh_rakecutLeft.updateMatrix();
      this.csg_rakecutLeft = CSG.fromMesh(this.mesh_rakecutLeft);
    }
    if (cutSide == BUILDING_SIDE.RIGHT) {
      let scaleLength = (this.APP.sldRightCutVertical.currentValue + 100)/1000;
      let scaleWidth = (this.APP.sldRightCutHorizontal.currentValue + 100)/1000;

      this.mesh_rakecutRight = new Mesh(this.geo_rakecutRight, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
      this.mesh_rakecutRight.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: 'RAKECUT' };

      this.mesh_rakecutRight.position.set(offsetXR - this.APP.sldRightCutHorizontal.currentValue, offsetY, offsetZ - this.APP.sldRightCutVertical.currentValue);
      this.mesh_rakecutRight.scale.set(scaleWidth, 5, scaleLength);

      //this.APP.scene.add(this.mesh_rakecutRight);

      this.mesh_rakecutRight.updateMatrix();
      this.csg_rakecutRight = CSG.fromMesh(this.mesh_rakecutRight);
    }
  }

  private addBarge() {
    let mesh: Mesh;

    let offsetXB = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
    let offsetXL1 = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue) + this.APP.sldExistingLength2.currentValue;
    let offsetXL2 = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);

    let offsetXR1 = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue - this.APP.sldExistingLength2.currentValue;
    let offsetXR2 = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;

    let offsetZLeft = 0;
    let offsetZRight = 0;

    let scaleXB = this.roofWidth / this.geo_receiverChanel.length;
    let scaleCutoutVer = this.utils.getHypotenuseByCos(this.APP.sldExistingWidth1.currentValue + this.receiverCutoutLeftHor.width, this.APP.sltRoofPitch.currentValue) / this.bargeLeftInfo.length;
    let scaleCutoutHor = this.APP.sldExistingLength2.currentValue / this.receiverCutoutLeftHor.length;
    let scaleZLeft = this.roofLength / this.bargeLeftInfo.length;
    let bargeLeftLength = this.roofLength;
    let scaleZRight = this.roofLength / this.bargeRightInfo.length;
    let bargeRightLength = this.roofLength;

    let offsetY = 0

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];

    //cutout
    if (this.APP.sltCutOut.currentValue == 1
      && this.APP.sldExistingLength2.currentValue > 0
      && this.enableCutout
      && (this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3)) {
      scaleXB = (this.roofWidth - this.APP.sldExistingLength2.currentValue) / this.geo_receiverChanel.length;
      scaleZLeft = this.utils.getHypotenuseByCos(this.roofLength - this.APP.sldExistingWidth1.currentValue, this.APP.sltRoofPitch.currentValue) / this.bargeLeftInfo.length;
      bargeLeftLength = this.utils.getHypotenuseByCos(this.roofLength - this.APP.sldExistingWidth1.currentValue, this.APP.sltRoofPitch.currentValue);
      
      offsetXB += this.APP.sldExistingLength2.currentValue;
      offsetZLeft += this.utils.getHypotenuseByCos(this.APP.sldExistingWidth1.currentValue, this.APP.sltRoofPitch.currentValue);

      //cutout Hor
      let offsetZ = this.utils.getHypotenuseByCos(this.APP.sldExistingWidth1.currentValue, this.APP.sltRoofPitch.currentValue);
      mesh = new Mesh(this.receiverCutoutLeftHor.geometry, MaterialManager.Instance().RECEIVER_CHANEL);
      mesh.position.set(offsetXL1, offsetY, offsetZ);
      mesh.scale.setX(scaleCutoutHor);
      mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RECEIVER_CHANEL, views: views };
      this.roofGroup.add(mesh);

      //cutout Ver
      mesh = new Mesh(this.receiverCutoutLeftVer.geometry, MaterialManager.Instance().RECEIVER_CHANEL);
      mesh.position.set(offsetXL1, offsetY, 0);
      mesh.scale.setZ(scaleCutoutVer);
      mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RECEIVER_CHANEL, views: views };
      this.roofGroup.add(mesh);
    }
    if (this.APP.sltCutOut.currentValue == 1
      && this.APP.sldExistingLength2.currentValue > 0
      && this.enableCutout
      && (this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3)) {
      scaleXB = (this.roofWidth - this.APP.sldExistingLength2.currentValue) / this.geo_receiverChanel.length;
      scaleZRight = this.utils.getHypotenuseByCos(this.roofLength - this.APP.sldExistingWidth1.currentValue, this.APP.sltRoofPitch.currentValue) / this.bargeRightInfo.length;
      bargeRightLength = this.utils.getHypotenuseByCos(this.roofLength - this.APP.sldExistingWidth1.currentValue, this.APP.sltRoofPitch.currentValue);
      offsetZRight += this.utils.getHypotenuseByCos(this.APP.sldExistingWidth1.currentValue, this.APP.sltRoofPitch.currentValue);
      //cutout hor
      let offsetZ = this.utils.getHypotenuseByCos(this.APP.sldExistingWidth1.currentValue, this.APP.sltRoofPitch.currentValue);
      mesh = new Mesh(this.receiverCutoutRightHor.geometry, MaterialManager.Instance().RECEIVER_CHANEL);
      mesh.position.set(offsetXR1, offsetY, offsetZ);
      mesh.scale.setX(scaleCutoutHor);
      mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RECEIVER_CHANEL, views: views };
      this.roofGroup.add(mesh);

      //cutout ver
      mesh = new Mesh(this.receiverCutoutRightVer.geometry, MaterialManager.Instance().RECEIVER_CHANEL);
      mesh.position.set(offsetXR1, offsetY, 0);
      mesh.scale.setZ(scaleCutoutVer);
      mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RECEIVER_CHANEL, views: views };
      this.roofGroup.add(mesh);
    }
    if (this.APP.sltCutOut.currentValue == 1
      && this.APP.sldExistingLength2.currentValue > 0
      && this.enableCutout
      && (this.APP.sltExistingType.currentValue == 3)) {
      scaleXB = (this.roofWidth - this.APP.sldExistingLength2.currentValue * 2) / this.geo_receiverChanel.length;
    }

    //Rake cut
    if(this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE){
      bargeLeftLength -= this.APP.sldLeftCutVertical.currentValue
    }

    if(this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE){
      bargeRightLength -= this.APP.sldRightCutVertical.currentValue
    }

    scaleZLeft = bargeLeftLength/this.bargeLeftInfo.length;
    scaleZRight = bargeRightLength/this.bargeRightInfo.length;

    //left
    if ((this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3)
      && ((this.APP.sldExistingWidth2.currentValue == 0 && this.APP.sldExistingWidth1.currentValue > scaleZLeft * this.receiverCutoutLeftVer2.length 
        || this.APP.sldExistingWidth2.currentValue > 0 && this.APP.sldExistingWidth2.currentValue > scaleZLeft * this.receiverCutoutLeftVer2.length))) {
      mesh = new Mesh(this.receiverCutoutLeftVer2.geometry, MaterialManager.Instance().RECEIVER_CHANEL);
      mesh.position.set(offsetXL2, offsetY, offsetZLeft);
      mesh.scale.setZ(scaleZLeft);
      mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RECEIVER_CHANEL, views: views };
      this.roofGroup.add(mesh);
    } else {
      mesh = new Mesh(this.bargeLeftInfo.geometry, MaterialManager.Instance().BARGE);
      mesh.position.set(offsetXL2, offsetY, offsetZLeft);
      mesh.scale.setZ(scaleZLeft);
      mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
      this.roofGroup.add(mesh);
    }
    

    //right
    if ((this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3)
      && ((this.APP.sldExistingWidth2.currentValue == 0 && this.APP.sldExistingWidth1.currentValue > scaleZRight * this.receiverCutoutRightVer2.length 
      || this.APP.sldExistingWidth2.currentValue > 0 && this.APP.sldExistingWidth2.currentValue > scaleZRight * this.receiverCutoutRightVer2.length))) {
      mesh = new Mesh(this.receiverCutoutRightVer2.geometry, MaterialManager.Instance().RECEIVER_CHANEL);
      mesh.position.set(offsetXR2, offsetY, offsetZRight);
      mesh.scale.setZ(scaleZRight);
      mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RECEIVER_CHANEL, views: views };
      this.roofGroup.add(mesh);
    } else {
      mesh = new Mesh(this.bargeRightInfo.geometry, MaterialManager.Instance().BARGE);
      mesh.position.set(offsetXR2, offsetY, offsetZRight);
      mesh.scale.setZ(scaleZRight);
      mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
      this.roofGroup.add(mesh);
    }


    // back
    mesh = new Mesh(this.geo_receiverChanel.geometry, MaterialManager.Instance().RECEIVER_CHANEL);
    mesh.position.set(offsetXB, offsetY, 0);
    mesh.scale.setX(scaleXB);
    //FIXED: BARGE -> RECEIVER_CHANCEL
    mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RECEIVER_CHANEL, views: views };
    this.roofGroup.add(mesh);
  }
  private addBargeStepRakecut() {
    if(!(this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP || this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP)){
      return;
    }

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];

    let offsetXL = this.APP.dialogEditBay.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue;
    let offsetXR = this.APP.dialogEditBay.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;
    let offsetZL = this.utils.getHypotenuseByCos(this.APP.sldSpan.currentValue
      + this.APP.sldMultiSpan.currentValue
      + this.APP.sldFrontOverhang.currentValue
      - this.APP.sldLeftCutVertical.currentValue, this.APP.sltRoofPitch.currentValue);
    let offsetZR = this.utils.getHypotenuseByCos(this.APP.sldSpan.currentValue
      + this.APP.sldMultiSpan.currentValue
      + this.APP.sldFrontOverhang.currentValue
      - this.APP.sldRightCutVertical.currentValue, this.APP.sltRoofPitch.currentValue);
    let scaleZL = (this.APP.sldLeftCutVertical.currentValue) / this.bargeLeftInfo.length;
    let scaleZR = (this.APP.sldRightCutVertical.currentValue) / this.bargeRightInfo.length;
    //let scaleX = (this.APP.sldRakeCutHorizontal.currentValue) / this.bargeLeftInfo.length;

    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
      let bargeVer = new Mesh(this.bargeLeftInfo.geometry, MaterialManager.Instance().BARGE);
      bargeVer.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
      bargeVer.position.set(-offsetXL + this.APP.sldLeftCutHorizontal.currentValue, 0, offsetZL);
      bargeVer.scale.set(1, 1, scaleZL);

      // let bargeHor = new Mesh(this.bargeLeftInfo.geometry, MaterialManager.Instance().BARGE);
      // bargeHor.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
      // bargeHor.position.set(-offsetXL, 0, offsetZ);
      // bargeHor.scale.set(1, 1, scaleX);
      // bargeHor.rotateY(Math.PI / 2);

      this.roofGroup.add(bargeVer);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      let bargeVer = new Mesh(this.bargeRightInfo.geometry, MaterialManager.Instance().BARGE);
      bargeVer.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
      bargeVer.position.set(offsetXR - this.APP.sldRightCutHorizontal.currentValue, 0, offsetZR);
      bargeVer.scale.set(1, 1, scaleZR);

      // let bargeHor = new Mesh(this.bargeRightInfo.geometry, MaterialManager.Instance().BARGE);
      // bargeHor.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
      // bargeHor.position.set(offsetXR, 0, offsetZ);
      // bargeHor.scale.set(1, 1, scaleX);
      // bargeHor.rotateY(-Math.PI / 2);

      this.roofGroup.add(bargeVer);
    }
  }
  public addGutter() {
    let offsetXL = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
    const offsetZ = (this.APP.sldFrontOverhang.currentValue
      + this.APP.sldMultiSpan.currentValue
      + this.APP.sldSpan.currentValue
      - CONST.GUTTER_ROOF_OFFSET_Z) / this.utils.cos(this.APP.sltRoofPitch.currentValue);
    const offsetY = 0;

    let gutterLength = this.roofWidth;
    let exLength = 300//Math.tan(extraAngle) * this.gutterInfo.width;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];

    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      offsetXL += (this.APP.sldLeftCutHorizontal.currentValue - exLength);
      gutterLength -= (this.APP.sldLeftCutHorizontal.currentValue - exLength);
    }
    else if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
      offsetXL += this.APP.sldLeftCutHorizontal.currentValue;
      gutterLength -= this.APP.sldLeftCutHorizontal.currentValue;
    }

    if(this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE){
      gutterLength -= (this.APP.sldRightCutHorizontal.currentValue - exLength);
    }
    else if(this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP){
      gutterLength -= this.APP.sldRightCutHorizontal.currentValue;
    }
    
    if(this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE && this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE){
      let gutterMesh = new Mesh(this.gutterInfo.geometry, MaterialManager.Instance().GUTTER);
      gutterMesh.scale.setX(gutterLength/this.gutterInfo.length);
      gutterMesh.updateMatrix();
      let matrixBefore = gutterMesh.matrix.clone();

      gutterMesh.position.set(offsetXL, offsetY, offsetZ);
      gutterMesh.rotation.set(-this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), 0, 0);
      gutterMesh.updateMatrix();

      let meshClipped = CSG.toMesh(CSG.fromMesh(gutterMesh).subtract(this.csgCutGutterLeft1), gutterMesh.matrix);
      meshClipped = CSG.toMesh(CSG.fromMesh(meshClipped).subtract(this.csgCutGutterRight1), gutterMesh.matrix);
      meshClipped.applyMatrix4(new Matrix4().getInverse(gutterMesh.matrix));
      meshClipped.applyMatrix4(matrixBefore);
      meshClipped.material = gutterMesh.material;
      meshClipped.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.GUTTER_PATIOS, views: views };

      let gutterGroup = this.utils.createGutterGroupWithMesh(meshClipped, this.geo_gutterCap,
        gutterLength, new Vector3(offsetXL, offsetY, offsetZ), new Vector3(-this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), 0, 0),
        views, 1, false, false);

      this.roofGroup.add(gutterGroup);
    }
    else if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE){
      {
        let gutterGroup = this.utils.createGutterGroup(this.gutterInfo, this.geo_gutterCap,
          gutterLength, new Vector3(offsetXL, offsetY, offsetZ), new Vector3(-this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), 0, 0),
          views, 1, false, true, this.csgCutGutterLeft1, this.roofGroup);

        this.roofGroup.add(gutterGroup);
      }
       
    }
    else if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE){
      {
        let gutterGroup = this.utils.createGutterGroup(this.gutterInfo, this.geo_gutterCap,
          gutterLength, new Vector3(offsetXL, offsetY, offsetZ), new Vector3(-this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), 0, 0),
          views, 1, true, false, this.csgCutGutterRight1, this.roofGroup);

        this.roofGroup.add(gutterGroup);
      }
    }
    else{
      let gutterGroup = this.utils.createGutterGroup(this.gutterInfo, this.geo_gutterCap,
      gutterLength, new Vector3(offsetXL, offsetY, offsetZ), new Vector3(-this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), 0, 0),
      views, 1, true, true);
      this.roofGroup.add(gutterGroup);
    }
  }
  
  public addGutterAngelRakecut(){
    if(this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.ANGLE && this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.ANGLE){
      return;
    }

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];

    let exLength = 300
    let rc_offsetXL = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
    let rc_offsetXR = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;
    const rc_offsetZL = (this.APP.sldFrontOverhang.currentValue
      + this.APP.sldMultiSpan.currentValue
      + this.APP.sldSpan.currentValue
      - this.APP.sldLeftCutVertical.currentValue
      - CONST.GUTTER_ROOF_OFFSET_Z) / this.utils.cos(this.APP.sltRoofPitch.currentValue);
      const rc_offsetZR = (this.APP.sldFrontOverhang.currentValue
        + this.APP.sldMultiSpan.currentValue
        + this.APP.sldSpan.currentValue
        - this.APP.sldRightCutVertical.currentValue
        - CONST.GUTTER_ROOF_OFFSET_Z) / this.utils.cos(this.APP.sltRoofPitch.currentValue);
    const rc_offsetY = 0;


    let rc_rotYL = Math.atan(this.APP.sldLeftCutVertical.currentValue / this.APP.sldLeftCutHorizontal.currentValue);
    let rc_lengthL = exLength + Math.sqrt(Math.pow(this.APP.sldLeftCutVertical.currentValue, 2) + Math.pow(this.APP.sldLeftCutHorizontal.currentValue, 2));
    let rc_rotYR = Math.atan(this.APP.sldRightCutVertical.currentValue / this.APP.sldRightCutHorizontal.currentValue);
    let rc_lengthR = exLength + Math.sqrt(Math.pow(this.APP.sldRightCutVertical.currentValue, 2) + Math.pow(this.APP.sldRightCutHorizontal.currentValue, 2));

    if(this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE && this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE){
      let gutterRakecutGroupLeft = this.utils.createGutterGroup(this.gutterRakecutLeftInfo, this.geo_gutterCap,
        rc_lengthL, new Vector3(rc_offsetXL, rc_offsetY, rc_offsetZL), new Vector3(0, -rc_rotYL, 0), views, 1, true, false,
        this.csgCutGutterLeft2, this.roofGroup);
      let gutterRakecutGroupRight = this.utils.createGutterGroup(this.gutterRakecutRightInfo, this.geo_gutterCap,
        rc_lengthR, new Vector3(rc_offsetXR, rc_offsetY, rc_offsetZR), new Vector3(0, rc_rotYR, 0), views, 1, true, false,
        this.csgCutGutterRight2, this.roofGroup);

      this.roofGroup.add(gutterRakecutGroupLeft, gutterRakecutGroupRight);
    }
    else{
      if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        let gutterRakecutGroup = this.utils.createGutterGroup(this.gutterRakecutLeftInfo, this.geo_gutterCap,
          rc_lengthL, new Vector3(rc_offsetXL, rc_offsetY, rc_offsetZL), new Vector3(0, -rc_rotYL, 0), views, 1, true, false,
          this.csgCutGutterLeft2, this.roofGroup);

        this.roofGroup.add(gutterRakecutGroup);
      }
      if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        let gutterRakecutGroup = this.utils.createGutterGroup(this.gutterRakecutRightInfo, this.geo_gutterCap,
          rc_lengthR, new Vector3(rc_offsetXR, rc_offsetY, rc_offsetZR), new Vector3(0, rc_rotYR, 0), views, 1, true, false,
          this.csgCutGutterRight2, this.roofGroup);

        this.roofGroup.add(gutterRakecutGroup);
      }
    }
  }
  public addGutterStepRakecut() {
    if(this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.STEP && this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.STEP){
      return;
    }

    let offsetXL = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
    let offsetXR = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;
    const offsetZL = (this.APP.sldFrontOverhang.currentValue
      + this.APP.sldMultiSpan.currentValue
      + this.APP.sldSpan.currentValue
      - this.APP.sldLeftCutVertical.currentValue
      - CONST.GUTTER_ROOF_OFFSET_Z) / this.utils.cos(this.APP.sltRoofPitch.currentValue);

    const offsetZR = (this.APP.sldFrontOverhang.currentValue
      + this.APP.sldMultiSpan.currentValue
      + this.APP.sldSpan.currentValue
      - this.APP.sldRightCutVertical.currentValue
      - CONST.GUTTER_ROOF_OFFSET_Z) / this.utils.cos(this.APP.sltRoofPitch.currentValue);

    const offsetY = 0;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
    ];

    //Step rakecut
    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
      let gutterLength = this.APP.sldLeftCutHorizontal.currentValue;
      let gutterHor = this.utils.createGutterGroup(this.gutterInfo, this.geo_gutterCap, gutterLength, 
        new Vector3(offsetXL, offsetY, offsetZL),
        new Vector3(0, 0, 0),
        views, 1, true, true);
      
      this.roofGroup.add(gutterHor);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      let gutterLength = this.APP.sldRightCutHorizontal.currentValue;
      let gutterHor = this.utils.createGutterGroup(this.gutterInfo, this.geo_gutterCap, gutterLength, 
        new Vector3(offsetXR - this.APP.sldRightCutHorizontal.currentValue, offsetY, offsetZR),
        new Vector3(0, 0, 0),
        views, 1, true, true);
      
      this.roofGroup.add(gutterHor);
    }
  }

  private getHeightByRoofPitch(userDataPos: any): number {
    if (userDataPos.isSpan) {
      return this.utils.tan(this.APP.sltRoofPitch.currentValue) * this.APP.sldSpan.currentValue;
    } else if (userDataPos.isMultiSpan) {
      return this.utils.tan(this.APP.sltRoofPitch.currentValue) * (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue);
    } else {
      return this.utils.tan(this.APP.sltRoofPitch.currentValue) * this.roofLength;
    }
  }
  public destroy(): void {
    this.unregisterEvent();
  }
  uiCHangedDefer(previousValue: number, currentValue: number) {
    if (this.APP.sltCutOut.currentValue == 1) {
      if (this.deferHandle) {
        clearTimeout(this.deferHandle);
      }
      this.deferHandle = setTimeout(() => { this.load() }, this.deferTimeout);
    }
    else {
      this.load();
    }
  }
  public uiChanged(preVal: number, curVal: number): void {
    this.load();
  }
  public onColorChanged() {
    if(this.utils && this.roofInfo.geometry){
      this.utils.changeGeoColor(this.roofInfo.geometry, new Vector3(0, 1, 0), UI.roofColor.toString());
    }
  }
  private registerEvent(): void {
    this.eventHandleId = this.onColorChanged.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sltColourRoof
    ];
    this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));
    this.controlsToRegisterEvent2 = [
      this.APP.sltRoofSheetingType,
      this.APP.sltRoofThickness,
      this.APP.sltBargeType,
      this.APP.sltDripBarge,
      this.APP.sltGutterType,
      this.APP.sltReceiverType
    ];
    this.controlsToRegisterEvent2.forEach(c => c.addAction(this.objectSizeChangedHandle));
  }
  private unregisterEvent(): void {
    this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    this.controlsToRegisterEvent = undefined;
    this.controlsToRegisterEvent2.forEach(c => c.removeAction(this.objectSizeChangedHandle));
    this.controlsToRegisterEvent2 = undefined;
  }
  private objectSizeChanged(preVal: number, curVal: number) {
    this.optimize().then(() => { this.load() });
  }
}

