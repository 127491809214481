import { HomeComponent } from 'src/app/containers/home';
import { HomeComponent as HomeComponentRibSpan } from 'src/app-ribspan/containers/home';
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'slider-input',
    templateUrl: './slider-input.component.html',
    styleUrls: ['./slider-input.component.css']
})
export class SliderInputComponent implements OnInit, AfterViewInit {
    _isDisable: boolean;
    @ViewChild('txtInput') txtInput: ElementRef<HTMLInputElement>;
    @Input('min') minValue: number;
    @Input('max') maxValue: number;
    @Input('step') step: number;
    @Input('default') public defaultValue: number;
    @Input('extraValue') public extraValue: number;
    @Input('disabled') public set isDisabled(val: boolean) { this._isDisable = val };
    @Output('change') onChange = new EventEmitter();

    _currentValue: number;
    _previousValue: number;

    public get previousValue(): number {
        if (this.extraValue && this.extraValue > 0) {
            return this._previousValue + this.extraValue;
        }
        else {
            return this._previousValue
        }
    };
    public get currentValue(): number {
        if (this.extraValue && this.extraValue > 0) {
            return this._currentValue + this.extraValue;
        }
        else {
            return this._currentValue
        }
    };
    public set currentValue(val: number){
        if(val >= this.minValue && val <= this.maxValue){
            this._currentValue = val;
            this.onValueChanged(val);
        }
    }

    actions: Array<{ priority: number, actions: Array<(previousValue: number, currentValue: number) => void> }> = [];

    constructor(private route: Router) { }

    ngOnInit() {
        this._currentValue = this.defaultValue;
    }
    ngAfterViewInit(){
        // fromEvent(this.txtInput.nativeElement, 'input').pipe(
        // // get value
        // map((event: any) => {
        //     return event.target.value;
        // })
        // // // if character length greater then 2
        // // ,filter(res => res >= this.minValue && res <= this.maxValue)
        // // Time in milliseconds between key events
        // ,debounceTime(1000)        
        // // If previous query is diffent from current   
        // ,distinctUntilChanged()
        // // subscription for response
        // ).subscribe((_value: string) => {
        //     let value = parseInt(_value);
        //     if(value < this.minValue){
        //         this._currentValue = this.minValue;
        //     }
        //     else if(value > this.maxValue){
        //         this._currentValue = this.maxValue;
        //     }
        //     else{
        //         this._currentValue = value;
        //     }
            
        //     this.onValueChanged({target: { value: this._currentValue }});
        // });
    }

    public addAction(action: (previousValue: number, currentValue: number) => void, priority?: number) {
        priority = priority || 3;
        let actionsByPriority = this.actions.find(a => a.priority === priority);
        if (actionsByPriority) {
            actionsByPriority.actions.push(action);
        }
        else {
            this.actions.push({ priority: priority, actions: [action] });
        }

        this.actions.sort((a, b) => a.priority - b.priority);
    }
    public removeAction(action: any): void {
        for (let item of this.actions) {
            const idx = item.actions.indexOf(action);

            if (idx !== -1) {
                item.actions.splice(idx, 1);
            }
        }
    }

    public onValueChanged(e, shouldEmit = false) {
        const val = e;
        this._currentValue = e;
    
        if ((val < this.minValue || val > this.maxValue) && !shouldEmit) {
            return;
        }

        this.onChange.emit(this);
        // for (let item of this.actions) {
        //     item.actions.forEach(action => {
        //         action(+this.previousValue, +this.currentValue);
        //     });
        // }
        Promise.all([
            ...this.actions.map(item => item.actions.map(action => action(this.previousValue, this.currentValue)))
        ]).then(() => {
            if(this.route.url == "/ribspan"){
                HomeComponentRibSpan.ins.renderStatic();
            } else {
                HomeComponent.ins.renderStatic();
            }
        })
        this._previousValue = val;
    }

    public setValueAndHandleEvent (value: number, shouldEmit = false) {
        this.onValueChanged(value, shouldEmit)
    }

    public setValue(value: number) {
        this.currentValue = value;
    }
    public setMin(value: number){
        this.minValue = value;
        if(this.currentValue < this.minValue) {
            this.setValueAndHandleEvent(this.minValue, true)
        }
    }
    public setMax(value: number){
        this.maxValue = value;
        if(this.currentValue && this.currentValue > value){
            this.onValueChanged(value)
        }
    }

    public setDisable(value: boolean) {
        this.isDisabled = value;
    }

}
