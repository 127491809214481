
import { Mesh, Vector3, Material, Plane, Group, BufferGeometry, Geometry, BoxBufferGeometry, MeshLambertMaterial, Matrix4, Object3D, LineSegments, Color } from "three";
import { Util } from "../utils";
import { GEOMETRY_TYPE, GEOMETRY_CATEGORY } from 'src/app/app.config';
// import { AppComponent } from "../../app.component";
import { HomeComponent as AppComponent } from '../../containers/home/home.component';
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import { GeometryInfo, Print2DView, ViewType, LineType, Printing2DLine, Printing2DGeometry } from 'src/app/core/models';
import { PatiosGableLRFlyOverManager } from '.';
import { CSG } from 'three-csg-ts';
import { EXISTING_BUILDING_CONFIG, EXISTING_BUILDING_CONFIG as CONST, BUILDING_SIDE, CUTOUT_ENABLE, PANEL_DIRECTION, RIBSPAN_PANEL_WIDTH, RIBSPAN_BARGE_FIT, FIT_FLYOVER_BRAKET_ON_ROOF } from 'src/app/app.constants';
import { UI } from "../ui";

export class RoofManager {
    private scene: Group;
    private APP: AppComponent;
    private MANAGER: PatiosGableLRFlyOverManager;
    private material: Material;
    private utils: Util;

    private geometryManager: GeometryManager;

    private geo_RoofPanel: GeometryInfo;
    private gutterRakecutLeftInfo: GeometryInfo;
    private gutterRakecutRightInfo: GeometryInfo;
    private geo_roofPanelBargeCappingLeft: GeometryInfo;
    private geo_roofPanelBargeCappingRight: GeometryInfo;
    private geo_roofPanelBargeCappingBack: GeometryInfo;
    private geo_roofPanelBargeCappingFront: GeometryInfo;
    private geo_bargeCutoutLeftHor: GeometryInfo;
    private geo_bargeCutoutRightHor: GeometryInfo;
    private geo_gutterBack: GeometryInfo;
    private geo_gutterCap: GeometryInfo;
    public ridgeGeo: GeometryInfo;

    private geo_rakecutLeft: BoxBufferGeometry;
    private geo_rakecutRight: BoxBufferGeometry;
    private mesh_rakecutLeft: Mesh;
    private mesh_rakecutRight: Mesh;
    private csg_rakecutLeft: CSG;
    private csg_rakecutRight: CSG;

    private roofGroupFront: Group;
    private roofGroupBack: Group;

    private objectSizeChangedHandle: any;

    private totalBaySize: number;
    private extraOffsetZ: number;
    
    private eventHandleId: any;

    private controlsToRegisterEvent: Array<any>;
    private controlsToRegisterEvent2: Array<any>;

    private mesh_CutoutLeft: Mesh;
    private csg_cutoutLeft: CSG;
    private mesh_CutoutRight: Mesh;
    private csg_cutoutRight: CSG;
    private geo_cutoutLeft: BoxBufferGeometry;
    private geo_cutoutRight: BoxBufferGeometry;

    private geoCutGutter: BoxBufferGeometry;
    private meshCutGutterLeft1: Mesh;
    private csgCutGutterLeft1: CSG;
    private meshCutGutterLeft2: Mesh;
    private csgCutGutterLeft2: CSG;

    private meshCutGutterRight1: Mesh;
    private csgCutGutterRight1: CSG;
    private meshCutGutterRight2: Mesh;
    private csgCutGutterRight2: CSG
    
    private roofWidth: number;
    private roofLength: number;

    private deferHandle;
    private deferTimeout = EXISTING_BUILDING_CONFIG.CUTOUT_DEFFER_TIME_OUT;

    constructor(app: AppComponent, flyOverManager: PatiosGableLRFlyOverManager) {
        this.utils = new Util();
        this.geometryManager = GeometryManager.Instance();
        this.APP = app;
        this.MANAGER = flyOverManager;
        this.scene = new Group();
        flyOverManager.patiosGroup.add(this.scene);
        this.material = MaterialManager.Instance().ROOF;

        this.registerEvent();
    }


    public optimize(): Promise<void> {
        return new Promise((resolve) => {

            this.geo_RoofPanel = this.geometryManager.getRoofRibspanPanel();
            this.geo_RoofPanel.geometry
                .translate(-this.geo_RoofPanel.width / 2, this.geo_RoofPanel.height / 2, -this.geo_RoofPanel.length / 2);
            this.geo_RoofPanel.geometry.rotateY(Math.PI)
            this.utils.changeGeoColor(this.geo_RoofPanel.geometry, new Vector3(0, 1, 0), UI.roofColor.toString());

            //Barge capping
            this.geo_roofPanelBargeCappingLeft = this.geometryManager.getRibspanBarge();
            this.geo_roofPanelBargeCappingLeft.geometry
                .rotateY(Math.PI)
                .translate(this.geo_roofPanelBargeCappingLeft.width / 2 - 3, this.geo_roofPanelBargeCappingLeft.height / 2 - RIBSPAN_BARGE_FIT, this.geo_roofPanelBargeCappingLeft.length / 2);

            this.geo_roofPanelBargeCappingRight = this.geometryManager.getRibspanBarge();
            this.geo_roofPanelBargeCappingRight.geometry
                .translate(-this.geo_roofPanelBargeCappingRight.width / 2 + 3, this.geo_roofPanelBargeCappingRight.height / 2 - RIBSPAN_BARGE_FIT, this.geo_roofPanelBargeCappingRight.length / 2);

            this.geo_roofPanelBargeCappingBack = this.geometryManager.getRibspanBarge();
            this.geo_roofPanelBargeCappingBack.geometry
                .rotateY(Math.PI / 2)
                .translate(this.geo_roofPanelBargeCappingBack.length / 2, this.geo_roofPanelBargeCappingBack.height / 2 - RIBSPAN_BARGE_FIT, this.geo_roofPanelBargeCappingBack.width / 2 - 3);

            this.geo_roofPanelBargeCappingFront = this.geometryManager.getRibspanBarge();
            this.geo_roofPanelBargeCappingFront.geometry
                .rotateY(-Math.PI / 2)
                .translate(this.geo_roofPanelBargeCappingFront.length / 2, this.geo_roofPanelBargeCappingFront.height / 2 - RIBSPAN_BARGE_FIT, -this.geo_roofPanelBargeCappingFront.width / 2);
            //Gutter
            this.geo_gutterBack = this.geometryManager.getGutter();
            this.geo_gutterBack.geometry
                .rotateY(-Math.PI / 2)
                .translate(this.geo_gutterBack.length / 2, -20, this.geo_gutterBack.width / 2);

            this.gutterRakecutLeftInfo = this.geometryManager.getGutter();
            this.gutterRakecutLeftInfo.geometry
                .rotateY(-Math.PI / 2)
                .translate(this.gutterRakecutLeftInfo.length / 2, -20, this.gutterRakecutLeftInfo.width / 2);
        
            this.gutterRakecutRightInfo = this.geometryManager.getGutter();
            this.gutterRakecutRightInfo.geometry
                .rotateY(-Math.PI / 2)
                .translate(-this.gutterRakecutRightInfo.length / 2, -20, this.gutterRakecutRightInfo.width / 2);

            //Gutter cap
            this.geo_gutterCap = this.geometryManager.getGuterEndCap();
            this.geo_gutterCap.geometry
                .rotateY(-Math.PI / 2)
                .translate(0, -20, this.geo_gutterCap.length / 2);

            this.geo_cutoutLeft = new BoxBufferGeometry(1000, 1000, 1000);
            this.geo_cutoutLeft.translate(-500, 500, -500);
            this.geo_cutoutRight = new BoxBufferGeometry(1000, 1000, 1000);
            this.geo_cutoutRight.translate(500, 500, -500);

            this.geo_rakecutLeft = new BoxBufferGeometry(1000, 1000, 1000);
            this.geo_rakecutLeft.translate(-500, 500, 500);
            this.geo_rakecutRight = new BoxBufferGeometry(1000, 1000, 1000);
            this.geo_rakecutRight.translate(500, 500, 500);

            this.geoCutGutter = new BoxBufferGeometry(500, 10000, 500);
            this.geoCutGutter.translate(0, 0, -250);
            //Barge cutout left
            this.geo_bargeCutoutLeftHor = this.geometryManager.getRibspanBarge();
            this.geo_bargeCutoutLeftHor.geometry
                .rotateY(Math.PI / 2)
                .translate(-this.geo_bargeCutoutLeftHor.length / 2, this.geo_bargeCutoutLeftHor.height / 2 - RIBSPAN_BARGE_FIT, this.geo_bargeCutoutLeftHor.width / 2);

            //Barge cutout right
            this.geo_bargeCutoutRightHor = this.geometryManager.getRibspanBarge();
            this.geo_bargeCutoutRightHor.geometry
                .rotateY(Math.PI / 2)
                .translate(this.geo_bargeCutoutRightHor.length / 2, this.geo_bargeCutoutRightHor.height / 2 - RIBSPAN_BARGE_FIT, this.geo_bargeCutoutRightHor.width / 2);
            
            this.ridgeGeo = this.geometryManager.getRibspanRidgeCapping();
            this.ridgeGeo.geometry.translate(- this.ridgeGeo.width / 2 + 1, -this.ridgeGeo.height / 2, 0);

            resolve();
        });
    }
    public load(): Promise<void> {
        return new Promise((resolve) => {
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PATIOS));
            this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.SHEET_OUTLINE));

            //test
            //this.scene.remove(...this.scene.children.filter(x => x.userData.type == 'HELPER'));
            this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'RAKECUT'));
            this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'CUTGUTTER'));
            this.extraOffsetZ = this.APP.existingWallManager.geo_existingWallL1.width + this.APP.eaveManager.backOverhang;
            this.totalBaySize = this.APP.dialogEditBay.totalBaySize;

            this.addRoofFront();
            this.addRoofBack();
            this.addCutout();

            let offsetLeft = this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue - this.MANAGER.patiosGroup.position.x;
            let offsetRight = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue + this.MANAGER.patiosGroup.position.x;
            if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT){
                let offsetX = this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + 100 - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
                offsetRight -= offsetX
                offsetLeft += offsetX
            }
            if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT){
                let offsetX = this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + 100 - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
                offsetLeft += offsetX
                offsetRight += offsetX
            }

            let clipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
            let clipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);
            

            this.material.clippingPlanes = [clipingPlaneLeft, clipingPlaneRight];
            MaterialManager.Instance().ROOF_BASE.clippingPlanes = [clipingPlaneLeft, clipingPlaneRight];
            let width = this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue;
            this.roofWidth = width;
            this.roofLength = this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + this.APP.sldBackOverhang.currentValue + this.APP.sldFrontOverhang.currentValue;
            let roofSheetingCount = Math.ceil(width / RIBSPAN_PANEL_WIDTH);
            let offsetX = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);

            let panelDirectionOfset = 1;
            if(this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.RIGHT_TO_LEFT){
                offsetX = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue - RIBSPAN_PANEL_WIDTH;
                panelDirectionOfset = -1;
            }
            else if(this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.LEFT_TO_RIGHT){
                offsetX = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
            }

            if (this.APP.sltCutOut.currentValue == 1 && this.APP.sltExistingType.currentValue == 1 && this.MANAGER.cutoutCondition) {
                for (let index = 0; index < roofSheetingCount; index++) {
                    let last = index == roofSheetingCount - 1;

                    this.addRoofSheetingFront(offsetX, []);
                    this.addRoofSheetingBack(offsetX, [this.csg_cutoutLeft]);

                    offsetX += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
                }
            }
            else if (this.APP.sltCutOut.currentValue == 1 && this.APP.sltExistingType.currentValue == 2 && this.MANAGER.cutoutCondition) {
                for (let index = 0; index < roofSheetingCount; index++) {
                    let last = index == roofSheetingCount - 1;

                    this.addRoofSheetingFront(offsetX, []);
                    this.addRoofSheetingBack(offsetX, [this.csg_cutoutRight]);

                    offsetX += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
                }
            }
            else if (this.APP.sltCutOut.currentValue == 1 && this.APP.sltExistingType.currentValue == 3 && this.MANAGER.cutoutCondition) {
                for (let index = 0; index < roofSheetingCount; index++) {
                    let last = index == roofSheetingCount - 1;

                    this.addRoofSheetingFront(offsetX, []);
                    this.addRoofSheetingBack(offsetX, [this.csg_cutoutLeft, this.csg_cutoutRight]);

                    offsetX += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
                }
            }
            else {
                for (let index = 0; index < roofSheetingCount; index++) {
                    let last = index == roofSheetingCount - 1;

                    this.addRoofSheetingFront(offsetX, null);
                    this.addRoofSheetingBack(offsetX, null);

                    offsetX += RIBSPAN_PANEL_WIDTH * panelDirectionOfset;
                }
            }

            this.addBargeCappingFront();
            this.addBargeCappingBack();

            this.addGutterFront();
            this.addGutterBack();

            this.addRidgeCapping();

            this.showPanelOutline();

            this.scene.visible = UI.showRoof

            resolve();
        });
    }
    private totalHeightFromEaveHeightToTopOfExistingRoof(){
        const height = 
          this.geometryManager.EAVE.EAVE.height 
          + UI.fasciaDepth 
          + UI.eaveWidth * this.utils.tan(UI.existingRoofPitch) 
          + this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.height / this.utils.cos(UI.existingRoofPitch)
    
        return height
    }
    private totalHeightFromEaveHeightToTopOfFlyoverBraket(){
        const height = this.totalHeightFromEaveHeightToTopOfExistingRoof() + UI.braketHeight - FIT_FLYOVER_BRAKET_ON_ROOF;

        return height
    }
    private addCutout() {
        if (this.APP.sltCutOut.currentValue == 1) {
            this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == 'CUTOUT'));

            let offsetX = this.APP.sldExistingLength.currentValue / 2 + this.APP.existingWallManager.geo_existingWallL2.width + 100;
            let offsetY = 0;
            let offsetZ = this.APP.sldExistingWidth1.currentValue / 2 - this.APP.existingWallManager.geo_existingWallL2.width - this.APP.sldBackOverhang.currentValue;

            let scaleX = (this.APP.sldExistingLength2.currentValue + 5000) / 1000;
            if (scaleX == 0) scaleX = 1;
            let scaleY = (this.APP.sldExistingWallHeight.currentValue + 5000) / 1000;
            let scaleZ = (this.APP.sldExistingWidth1.currentValue + this.APP.sldBackOverhang.currentValue + 5000) / 1000;
            if (scaleZ == 0) scaleZ = 1;

            if (this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3) {
                this.mesh_CutoutLeft = new Mesh(this.geo_cutoutLeft, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
                this.mesh_CutoutLeft.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: 'CUTOUT' };

                this.mesh_CutoutLeft.position.set(-offsetX, offsetY, offsetZ);
                this.mesh_CutoutLeft.scale.set(scaleX, scaleY, scaleZ);

                // this.APP.scene.add(this.mesh_CutoutLeft);

                this.mesh_CutoutLeft.updateMatrix();
                this.csg_cutoutLeft = CSG.fromMesh(this.mesh_CutoutLeft);
            }
            if (this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3) {
                this.mesh_CutoutRight = new Mesh(this.geo_cutoutRight, new MeshLambertMaterial({ color: 'red', transparent: true, opacity: 0.5 }));
                this.mesh_CutoutRight.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: 'CUTOUT' };

                this.mesh_CutoutRight.position.set(offsetX, offsetY, offsetZ);
                this.mesh_CutoutRight.scale.set(scaleX, scaleY, scaleZ);

                // this.APP.scene.add(this.mesh_CutoutRight);

                this.mesh_CutoutRight.updateMatrix();
                this.csg_cutoutRight = CSG.fromMesh(this.mesh_CutoutRight);
            }
        }
    }

    public getOutLines(): Printing2DGeometry {

        let objs = this.roofGroupFront.children.filter(o =>
            o.userData.type == GEOMETRY_TYPE.ROOF_PANEL
            || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
        ).concat(
            ...this.roofGroupBack.children.filter(o =>
                o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
                o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
            )
        );

        let gutterGroupObjs = this.roofGroupFront.children.filter(o => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS)
        .concat(...this.roofGroupBack.children.filter(o => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS));
        let gutterObjs: Object3D[] = [];
        for(let group of gutterGroupObjs){
        gutterObjs.push(...group.children);
        }
        objs = [...objs, ...gutterObjs];

        let lsGeometries: Printing2DLine[] = [];

        let offsetLeft = this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue - this.MANAGER.patiosGroup.position.x;
        let offsetRight = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue + this.MANAGER.patiosGroup.position.x;
        if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
            let offsetX =
              this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
              100 -
              (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
            offsetRight -= offsetX;
            offsetLeft += offsetX;
        }
        if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
            let offsetX =
                this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
                100 -
                (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
            offsetLeft += offsetX;
            offsetRight += offsetX;
        }
        let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
        let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);

        for (let o of objs) {
            let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale((o as Mesh), 60);

            o.updateWorldMatrix(true, true);
            outlineGeo.applyMatrix4(o.matrixWorld);
            outlineGeo.translate(0, 5000, 0);

            if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL) {
                outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
                outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
            }
            if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE) {
                outlineGeo = this.utils.clipOutlineBasePanel(outlineGeo, roofClipingPlaneLeft);
                outlineGeo = this.utils.clipOutlineBasePanel(outlineGeo, roofClipingPlaneRight);
            }

            let simplifiedGeo = this.simplifyGeo(outlineGeo);
            lsGeometries.push({
                objectType: o.userData.type,
                vertices: simplifiedGeo.vertices,
                views: o.userData.views
            });
        }

        let ridgeCapping = this.scene.children.find(el => el.userData.type === GEOMETRY_TYPE.RIDGE_CAPPING)
        if(ridgeCapping){
            ridgeCapping.children.forEach(el => {
                this.utils.createOutline(el as Mesh, lsGeometries);
            })
        }

        lsGeometries.push({
            objectType: GEOMETRY_TYPE.ROOF_COVER,
            vertices: this.makeRoofPolygon(),
            color: this.utils.convertHexColorToRgb(`#${new Color(UI.panelColor).getHexString()}`),
            views: [
                { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
            ]
        });

        return { lines: lsGeometries, texts: [] };
    }
    makeRoofPolygon() {
        const polygon = this.utils.getRoofCoverPolygonForLRRoof()
        this.MANAGER.patiosGroup.updateWorldMatrix(true, true)
        return polygon.map(el => (el as Vector3).applyMatrix4(this.MANAGER.patiosGroup.matrixWorld).add(new Vector3(this.roofGroupFront.position.x, 0, - UI.existingWidth1 / 2 - this.extraOffsetZ - UI.overhangBack)))
    }
    public showPanelOutline(){
        if(this.APP.sltPanelDirectionShow.currentValue == false)
            return;

        let objs = this.roofGroupFront.children.filter(o =>
            o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
            o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
        ).concat(
            ...this.roofGroupBack.children.filter(o =>
                o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
                o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
            )
        );

        let offsetLeft = this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue - this.MANAGER.patiosGroup.position.x;
        let offsetRight = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue + this.MANAGER.patiosGroup.position.x;
        if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
            let offsetX =
              this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
              100 -
              (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
            offsetRight -= offsetX;
            offsetLeft += offsetX;
        }
        if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
            let offsetX =
                this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
                100 -
                (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
            offsetLeft += offsetX;
            offsetRight += offsetX;
        }
        let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
        let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);
        

        for (let o of objs) {
            let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale((o as Mesh), 60);

            //let outlineBox = new Box3().setFromBufferAttribute(outlineGeo.getAttribute('position') as Buffer);

            o.updateWorldMatrix(true, true);
            outlineGeo.applyMatrix4(o.matrixWorld);
            //outlineGeo.translate(0, 1000, 0);

            if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE) {
                outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
                outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
            }

            var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
            line.userData = {type: GEOMETRY_TYPE.SHEET_OUTLINE};
            this.APP.scene.add( line );
        }
    }
    public simplifyGeo(geo: BufferGeometry): Geometry {
        let simplifiedGeo = new Geometry();
        let vertices = geo.getAttribute('position').array;
        for (let i = 0; i < vertices.length; i += 3) {
            simplifiedGeo.vertices.push(new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2]));
        }
        return simplifiedGeo;
    }

    public addRoofFront() {
        this.roofGroupFront = null;
        this.roofGroupFront = new Group();
        this.roofGroupFront.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PATIOS };

        let offsetY = this.getRoofOffsetY();
        let offsetZ = -(this.APP.sldExistingWidth1.currentValue / 2 + this.extraOffsetZ) + (UI.span + UI.multiSpan) / 2;

        this.roofGroupFront.position.setY(offsetY);
        this.roofGroupFront.position.setZ(offsetZ);
        this.roofGroupFront.rotateX(this.utils.degreesToRadians(UI.patiosPitch));

        if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT){
            let offsetX = this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + 100 - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
            this.roofGroupFront.position.setX(- offsetX)
        }

        if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT){
            let offsetX = this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + 100 - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
            this.roofGroupFront.position.setX(offsetX)
        }

        this.scene.add(this.roofGroupFront);
    }

    public addRoofBack() {
        this.roofGroupBack = null;
        this.roofGroupBack = new Group();
        this.roofGroupBack.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PATIOS };

        let offsetY = this.getRoofOffsetY();
        let offsetZ = -(this.APP.sldExistingWidth1.currentValue / 2 + this.extraOffsetZ) + (UI.span + UI.multiSpan) / 2;

        this.roofGroupBack.position.setY(offsetY);
        this.roofGroupBack.position.setZ(offsetZ);
        this.roofGroupBack.rotateX(-this.utils.degreesToRadians(UI.patiosPitch));

        if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT){
            let offsetX = this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + 100 - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
            this.roofGroupBack.position.setX(- offsetX)
        }

        if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT){
            let offsetX = this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + 100 - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
            this.roofGroupBack.position.setX(offsetX)
        }

        this.scene.add(this.roofGroupBack);
    }

    public addRoofSheetingFront(offsetX: number, csgObjs: CSG[]) {
        let mesh = new Mesh(this.geo_RoofPanel.geometry, this.material);

        let offsetSheet = 0;
        if (this.APP.sltRoofSheetingType.currentValue == 0 || this.APP.sltRoofSheetingType.currentValue == 1) {
            offsetSheet = 30;
        }
        else {
            offsetSheet = 12;
        }

        let scaleZ = ((UI.span + UI.multiSpan) / 2
            + this.APP.sldFrontOverhang.currentValue) / this.utils.cos(this.APP.sltRoofPitch.currentValue) / this.geo_RoofPanel.length;

        let offsetY = 0;
        let offsetZ = 0;

        let views: Print2DView[] = [
            { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        mesh.position.set(offsetX, offsetY, offsetZ);
        mesh.scale.setZ(scaleZ);
        mesh.userData = { 
            category: GEOMETRY_CATEGORY.PATIOS, 
            type: GEOMETRY_TYPE.ROOF_PANEL, 
            views: views,
            dir: UI.panelDirection === PANEL_DIRECTION.LEFT_TO_RIGHT ? "L-R" : "R-L"
        };

        let cutoutCondition = this.APP.sltCutOut.currentValue == 1 && this.APP.sltExistingType.currentValue != 0 && this.APP.sldExistingLength2.currentValue > 0;
        if (cutoutCondition) {
            mesh.updateMatrix();
            let matrixBefore = mesh.matrix.clone();

            mesh.position.set(offsetX + this.MANAGER.patiosGroup.position.x, offsetY, offsetZ + this.roofGroupFront.position.z);
            mesh.updateMatrix();
            let meshResult = mesh.clone();

            if (cutoutCondition) {
                for (let csgObj of csgObjs) {
                    meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
                }
            }
            meshResult.userData = mesh.userData;
            meshResult.material = mesh.material;
            meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix));
            meshResult.applyMatrix4(matrixBefore);

            this.roofGroupFront.add(meshResult);
        }
        else {
            this.roofGroupFront.add(mesh);
        }
    }
    public addRoofSheetingBack(offsetX: number, csgObjs: CSG[]) {
        let mesh = new Mesh(this.geo_RoofPanel.geometry, this.material);

        let offsetSheet = 0;
        if (this.APP.sltRoofSheetingType.currentValue == 0 || this.APP.sltRoofSheetingType.currentValue == 1) {
            offsetSheet = 30;
        }
        else {
            offsetSheet = 12;
        }

        let scaleZ = ((UI.span + UI.multiSpan) / 2
            + UI.overhangBack) / this.utils.cos(this.APP.sltRoofPitch.currentValue) / this.geo_RoofPanel.length;

        let offsetY = 0;
        let offsetZ = - scaleZ * this.geo_RoofPanel.length;;

        let views: Print2DView[] = [
            { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        mesh.position.set(offsetX, offsetY, offsetZ);
        mesh.scale.setZ(scaleZ);
        mesh.userData = { 
            category: GEOMETRY_CATEGORY.PATIOS, 
            type: GEOMETRY_TYPE.ROOF_PANEL, 
            views: views,
            dir: UI.panelDirection === PANEL_DIRECTION.RIGHT_TO_LEFT ? "L-R" : "R-L"
        };

        let cutoutCondition = this.APP.sltCutOut.currentValue == 1 && this.APP.sltExistingType.currentValue != 0 && this.APP.sldExistingLength2.currentValue > 0;
        if (cutoutCondition) {
            mesh.updateMatrix();
            let matrixBefore = mesh.matrix.clone();

            mesh.position.set(offsetX + this.MANAGER.patiosGroup.position.x, offsetY, offsetZ + this.roofGroupBack.position.z);
            mesh.updateMatrix();
            let meshResult = mesh.clone();

            if (cutoutCondition) {
                for (let csgObj of csgObjs) {
                    meshResult = CSG.toMesh(CSG.fromMesh(meshResult).subtract(csgObj), mesh.matrix);
                }
            }
            meshResult.userData = mesh.userData;
            meshResult.material = mesh.material;
            meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix));
            meshResult.applyMatrix4(matrixBefore);

            this.roofGroupBack.add(meshResult);
        }
        else {
            this.roofGroupBack.add(mesh);
        }
    }

    private addBargeCappingBack() {
        let mesh: Mesh;

        let extraWallWidth = this.APP.existingWallManager.geo_existingWallW1.width + EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
        let offsetXL1 = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue) + this.APP.sldExistingLength2.currentValue - extraWallWidth;
        let offsetXL2 = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);

        let offsetXR1 = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue - this.APP.sldExistingLength2.currentValue + this.APP.existingWallManager.geo_existingWallW1.width + 100;
        let offsetXR2 = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;

        let offsetZL = 0;
        let offsetZR = 0;

        let totalLengthCutOut = UI.existingWidth1 < (UI.span + UI.multiSpan) / 2 ? UI.existingWidth1 : (UI.span + UI.multiSpan) / 2;

        let scaleCutoutVer = this.utils.getHypotenuseByCos(totalLengthCutOut + this.geo_roofPanelBargeCappingLeft.width, UI.patiosPitch) / this.geo_roofPanelBargeCappingLeft.length;

        let roofBackLength = (UI.span + UI.multiSpan) / 2 + UI.overhangBack;
        let bargeLeftLength = roofBackLength;
        let bargeRightLength = roofBackLength;

        let angleLength = roofBackLength / this.utils.cos(UI.patiosPitch);

        let offsetY = 0;

        let views: Print2DView[] = [
            { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        let isShowLeftBarge = true;
        let isShowRightBarge = true;

        let scaleXF = roofBackLength / this.geo_roofPanelBargeCappingFront.length;
        let scaleZLeft = this.utils.getHypotenuseByCos(bargeLeftLength, this.APP.sltRoofPitch.currentValue) / this.geo_roofPanelBargeCappingLeft.length;
        let scaleZRight = this.utils.getHypotenuseByCos(bargeRightLength, this.APP.sltRoofPitch.currentValue) / this.geo_roofPanelBargeCappingRight.length;

        let fitScale = this.geo_roofPanelBargeCappingLeft.height * this.utils.tan(UI.patiosPitch) / this.geo_roofPanelBargeCappingLeft.length;

        //left
        if(isShowLeftBarge){
            mesh = new Mesh(this.geo_roofPanelBargeCappingLeft.geometry, MaterialManager.Instance().BARGE);
            mesh.position.set(offsetXL2, offsetY, offsetZL - this.geo_roofPanelBargeCappingLeft.length * scaleZLeft);
            mesh.scale.setZ(scaleZLeft + fitScale);
            mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
            this.roofGroupBack.add(mesh);
        }

        //right
        if(isShowRightBarge){
            mesh = new Mesh(this.geo_roofPanelBargeCappingRight.geometry, MaterialManager.Instance().BARGE);
            mesh.position.set(offsetXR2, offsetY, offsetZR - this.geo_roofPanelBargeCappingRight.length * scaleZRight);
            mesh.scale.setZ(scaleZRight + fitScale);
            mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
            this.roofGroupBack.add(mesh);
        }
    }

    private addBargeCappingFront() {
        let mesh: Mesh;
        let extraWallWidth = this.APP.existingWallManager.geo_existingWallW1.width + EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;

        let offsetXL2 = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
        let offsetXR2 = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;

        let offsetZL = 0;
        let offsetZR = 0;

        let roofFrontLength = (UI.span + UI.multiSpan) / 2 + UI.overhangFront;
        let offsetY = 0;
        let bargeLeftLength = roofFrontLength;
        let bargeRightLength = roofFrontLength;

        let views: Print2DView[] = [
            { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        let scaleZLeft = this.utils.getHypotenuseByCos(bargeLeftLength, this.APP.sltRoofPitch.currentValue) / this.geo_roofPanelBargeCappingLeft.length;
        let scaleZRight = this.utils.getHypotenuseByCos(bargeRightLength, this.APP.sltRoofPitch.currentValue) / this.geo_roofPanelBargeCappingLeft.length;


        let fitScale = this.geo_roofPanelBargeCappingLeft.height * this.utils.tan(UI.patiosPitch) / this.geo_roofPanelBargeCappingLeft.length;
        //left
        mesh = new Mesh(this.geo_roofPanelBargeCappingLeft.geometry, MaterialManager.Instance().BARGE);
        mesh.position.set(offsetXL2, offsetY, offsetZL - this.geo_roofPanelBargeCappingLeft.height * this.utils.tan(UI.patiosPitch));
        mesh.scale.setZ(scaleZLeft + fitScale);
        mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
        this.roofGroupFront.add(mesh);

        //right
        mesh = new Mesh(this.geo_roofPanelBargeCappingRight.geometry, MaterialManager.Instance().BARGE);
        mesh.position.set(offsetXR2, offsetY, offsetZR - this.geo_roofPanelBargeCappingLeft.height * this.utils.tan(UI.patiosPitch));
        mesh.scale.setZ(scaleZRight + fitScale);
        mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
        this.roofGroupFront.add(mesh);

        // //back
        // mesh = new Mesh(this.geo_roofPanelBargeCappingBack.geometry, MaterialManager.Instance().BARGE);
        // mesh.position.setX(offsetXB);
        // mesh.scale.setX(bargeBackLength/this.geo_roofPanelBargeCappingBack.length);
        // mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
        // this.roofGroupFront.add(mesh);
    }
    public addGutterFront() {

        let offsetXL = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
        let offsetY = 0;
        let offsetZ = ((UI.span + UI.multiSpan) / 2 + UI.overhangFront
            - CONST.GUTTER_ROOF_OFFSET_Z) / this.utils.cos(this.APP.sltRoofPitch.currentValue);

        let gutterLength = this.roofWidth;

        let views: Print2DView[] = [
            { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        let gutterGroup = this.utils.createGutterGroup(this.geo_gutterBack, this.geo_gutterCap,
            gutterLength, new Vector3(offsetXL, offsetY, offsetZ), new Vector3(-this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), 0, 0),
            views, 1, true, true);
            this.roofGroupFront.add(gutterGroup); 
    }

    public addGutterBack() {
        let offsetX = (this.totalBaySize / 2 + UI.overhangRight);
        let offsetY = 0;
        let gutterLength = (this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue);
        let extraWallWidth = this.APP.existingWallManager.geo_existingWallW1.width + EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;

        //cutout
        if (this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES && this.MANAGER.cutoutCondition) {
            if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
                if(this.APP.sldExistingWidth2.currentValue > 0){
                    gutterLength -= this.APP.sldExistingLength2.currentValue;
                }
                else{
                    gutterLength -= (this.APP.sldExistingLength2.currentValue - extraWallWidth);
                }
                gutterLength -= this.APP.sldLeftOverhang.currentValue;
            }
            else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
                if(this.APP.sldExistingWidth2.currentValue > 0){
                    offsetX -= this.APP.sldExistingLength2.currentValue;
                    gutterLength -= this.APP.sldExistingLength2.currentValue;
                }
                else{
                    offsetX -= (this.APP.sldExistingLength2.currentValue - extraWallWidth);
                    gutterLength -= (this.APP.sldExistingLength2.currentValue - extraWallWidth);
                }
                offsetX -= this.APP.sldRightOverhang.currentValue;
                gutterLength -= this.APP.sldRightOverhang.currentValue;
            }
            else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
                offsetX -= ((this.totalBaySize - (this.APP.sldExistingLength.currentValue + this.APP.existingWallManager.geo_existingWallW1.width * 2)) / 2 - EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK);
                gutterLength = this.APP.sldExistingLength.currentValue + extraWallWidth *2;
            }
        }


        let views: Print2DView[] = [
            { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        let offsetZ = -((UI.span + UI.multiSpan) / 2 + UI.overhangBack
        - CONST.GUTTER_ROOF_OFFSET_Z) / this.utils.cos(this.APP.sltRoofPitch.currentValue);

        //back
        let gutterGroup = this.utils.createGutterGroup(this.geo_gutterBack, this.geo_gutterCap, gutterLength,
            new Vector3(offsetX, offsetY, offsetZ), new Vector3(this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), Math.PI, 0), views, 1, true, true);

        this.roofGroupBack.add(gutterGroup);
    }

    public addRidgeCapping(){
        this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.RIDGE_CAPPING));

        let ridgeFront = this.createRidgeCapping();
        ridgeFront.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RIDGE_CAPPING};
        const height = this.getRoofOffsetY() + this.geo_RoofPanel.height / this.utils.cos(this.APP.sltRoofPitch.currentValue);
        
        let offsetX = (UI.overhangRight - UI.overhangLeft) / 2
        if(UI.existingType == BUILDING_SIDE.RIGHT){
            offsetX += - this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width - 100 + ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        }
        if(UI.existingType == BUILDING_SIDE.LEFT){
            offsetX += this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + 100 - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        }
        
        const ridgeLength = this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue;
        ridgeFront.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RIDGE_CAPPING, length: ridgeLength, views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },{ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }]};
        ridgeFront.scale.setZ(ridgeLength / this.ridgeGeo.length);
        ridgeFront.rotation.set(0, Math.PI / 2, 0)
        ridgeFront.position.set( offsetX, height, ( UI.span + UI.multiSpan ) / 2 - UI.existingWidth1 / 2 - this.extraOffsetZ);
        this.scene.add(ridgeFront)
    }
    public createRidgeCapping(){
        let leftCap = new Mesh(this.ridgeGeo.geometry, MaterialManager.Instance().RIDGE_CAPPING);
        let rightCap = new Mesh(this.ridgeGeo.geometry, MaterialManager.Instance().RIDGE_CAPPING);
    
        rightCap.rotateY(Math.PI)
    
        leftCap.rotateZ(this.utils.degreesToRadians(UI.patiosPitch))
        rightCap.rotateZ(this.utils.degreesToRadians(UI.patiosPitch))
    
        leftCap.userData = {views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },{ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }]}
        rightCap.userData = {views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },{ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }]}
    
        return new Group().add(leftCap, rightCap)
    }

    public getRoofOffsetY() {
        return UI.eaveHeight
            + this.totalHeightFromEaveHeightToTopOfFlyoverBraket()
            + this.geometryManager.getBeam().height
            + this.utils.tan(UI.patiosPitch) * ((UI.span + UI.multiSpan) / 2);
    }
    public destroy(): void {
        this.unregisterEvent();
    }
    public onColorChanged() {
        if(this.utils && this.geo_RoofPanel.geometry){
            this.utils.changeGeoColor(this.geo_RoofPanel.geometry, new Vector3(0, 1, 0), UI.roofColor.toString());
        }
    }
    private registerEvent(): void {
        this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

        this.eventHandleId = this.onColorChanged.bind(this);
        this.controlsToRegisterEvent = [
            this.APP.sltColourRoof
        ];
        this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));

        this.controlsToRegisterEvent2 = [
            this.APP.sltRoofSheetingType,
            this.APP.sltRoofThickness,
            this.APP.sltBargeType,
            this.APP.sltDripBarge,
            this.APP.sltGutterType,
            this.APP.sltRoofPitch
        ];
        this.controlsToRegisterEvent2.forEach(c => c.addAction(this.objectSizeChangedHandle));
    }
    private unregisterEvent(): void {
        this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
        this.controlsToRegisterEvent = undefined;

        this.controlsToRegisterEvent2.forEach(c => c.removeAction(this.objectSizeChangedHandle));
        this.controlsToRegisterEvent2 = undefined;
    }
    private objectSizeChanged() {
        this.optimize().then(() => { this.load() });
    }
    public uiChanged(): void {
        this.load();
    }
    uiCHangedDefer() {
        if (this.APP.sltCutOut.currentValue == 1) {
            if (this.deferHandle) {
                clearTimeout(this.deferHandle);
            }
            this.deferHandle = setTimeout(() => { this.load() }, this.deferTimeout);
        }
        else {
            this.load();
        }
    }
}
