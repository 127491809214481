import { Injectable } from "@angular/core";
import HighLightBox from "src/app-ribspan/models/HighlightBox";
import SelectionManager from "../selection.manager";
import { UI } from "../ui";

@Injectable({
    providedIn: 'root'
})
export default class UIRakecutManager {
    box1: HighLightBox
    updateFnc = this.update.bind(this)
    constructor(private selMgr: SelectionManager){

    }
    public init(){
        // HomeComponent.ins.sldBuildingHeight.addAction(this.updateFnc)
        // HomeComponent.ins.sltRoofPitch.addAction(this.updateFnc)
        
        // this.box1 = new HighLightBox(HomeComponent.ins.scene, {
        //     x: -(UI.totalBayLength / 2 + UI.overhangLeft), 
        //     y: UI.height - UI.frontHeight, 
        //     z: UI.span + UI.multiSpan, 
        //     selectionManager: this.selMgr
        // })
    }

    update(){
        this.box1.position.set(-(UI.totalBayLength / 2 + UI.overhangLeft), UI.height, UI.span + UI.multiSpan)
    }
}