import { Box3, BoxBufferGeometry, FontLoader, Geometry, Group, Line, Line3, Material, Matrix4, Mesh, Object3D, Scene, TextBufferGeometry, TextGeometryParameters, Vector3 } from "three";
import { environment as env } from '../../environments/environment';
import { DIMENSION_LABEL_TYPE, GEOMETRY_TYPE, PATIOS_ROOF_TYPE } from '../app.config';
import { BUILDING_SIDE, CONNECTION_TYPE, EXISTING_BUILDING_CONFIG } from '../app.constants';
import { HomeComponent as AppComponent, HomeComponent } from '../containers/home/home.component';
import Dimension, { AddDimensionOptions } from '../models/Dimension';
import { GeometryManager } from './geometry.manager';
import { MaterialManager } from './material.manager';
import { GeometryInfo, LineType, Print2DText, Printing2DGeometry, Printing2DLine, ViewType } from './models';
import { UI } from "./ui";
import { Util } from "./utils";

// declare var KIEU_GABLE = {
//     NONE: -1,
//     FRONT: 0,
//     BACK: 1,
//     BOTH: 2
// }

export class DimensionManager {
    private scene: Scene;
    private material: Material;
    private APP: AppComponent;
    public listText: THREE.Object3D[] = [];
    private utils: Util;

    private materialManager: MaterialManager;

    private dimensionPlanLeft: Object3D;
    private dimensionPlanTop: Object3D;
    private dimensionFrontLeft: Object3D;
    private dimensionFrontTop: Object3D;
    private dimensionLeftLeft: Object3D;
    private dimensionLeftTop: Object3D;

    private dimensionEaveHeight: Object3D;
    
    private dimensionRightTop: Object3D;
    private dimensionRightRight: Object3D;

    private lineCapGeo: GeometryInfo;
    private lineGeo: GeometryInfo;
    private textGeo: TextBufferGeometry;

    private dimensionDistance = 1500;
    private dimensionDistance2 = 500;

    private textDistance = 5000;

    private box: Box3;
    private startPos: Vector3;

    private font: any;
    private isReady = false;
    private textParameter: TextGeometryParameters;
    private geometryManager: GeometryManager;

    private options : AddDimensionOptions;

    private static instance: DimensionManager;
    static Instance(): DimensionManager{
        if (!DimensionManager.instance) {
            DimensionManager.instance = new DimensionManager();
        }
        return DimensionManager.instance;
    }

    private constructor() {
        this.utils = new Util();
        this.materialManager = MaterialManager.Instance();
        this.geometryManager = GeometryManager.Instance();
    }

    public init(app: AppComponent): Promise<void>{
        this.APP = app;
        this.scene = this.APP.scene;
        this.material = MaterialManager.Instance().DIMENSION_TEXT;

        this.dimensionPlanTop = new Group();
        this.dimensionPlanTop.rotation.set(Math.PI/2,Math.PI,0);
        this.dimensionPlanTop.userData = { type: GEOMETRY_TYPE.DIMENSION };
        this.scene.add(this.dimensionPlanTop);

        this.dimensionPlanLeft = new Group();
        this.dimensionPlanLeft.rotation.set(-Math.PI/2, 0, -Math.PI/2);
        this.dimensionPlanLeft.userData = { type: GEOMETRY_TYPE.DIMENSION, position: { planLeft: true } };
        this.scene.add(this.dimensionPlanLeft);

        this.dimensionEaveHeight = new Group();
        this.dimensionEaveHeight.rotation.set(0, -Math.PI/2, Math.PI/2);
        this.dimensionEaveHeight.userData = { type: GEOMETRY_TYPE.DIMENSION };
        this.scene.add(this.dimensionEaveHeight);

        this.dimensionRightTop = new Group();            
        this.dimensionRightTop.rotation.set(0,-Math.PI/2,0);
        this.dimensionRightTop.userData = { type: GEOMETRY_TYPE.DIMENSION };
        this.dimensionRightTop.visible = false;
        this.scene.add(this.dimensionRightTop);

        this.dimensionRightRight = new Group();
        this.dimensionRightRight.rotation.set(0,-Math.PI/2,-Math.PI/2);
        this.dimensionRightRight.userData = { type: GEOMETRY_TYPE.DIMENSION };
        this.dimensionRightRight.visible = false;
        this.scene.add(this.dimensionRightRight);

        this.dimensionLeftTop = new Group();            
        this.dimensionLeftTop.rotation.set(0,-Math.PI/2,0);
        this.dimensionLeftTop.userData = { type: GEOMETRY_TYPE.DIMENSION };
        this.dimensionLeftTop.visible = false;
        this.scene.add(this.dimensionLeftTop);

        this.dimensionLeftLeft = new Group();
        this.dimensionLeftLeft.rotation.set(0,-Math.PI/2,-Math.PI/2);
        this.dimensionLeftLeft.userData = { type: GEOMETRY_TYPE.DIMENSION };
        this.dimensionLeftLeft.visible = false;
        this.scene.add(this.dimensionLeftLeft);

        this.dimensionFrontTop = new Group();
        this.dimensionFrontTop.rotation.set(0,Math.PI,0);
        this.dimensionFrontTop.userData = { type: GEOMETRY_TYPE.DIMENSION };
        this.dimensionFrontTop.visible = false;
        this.scene.add(this.dimensionFrontTop);

        this.dimensionFrontLeft = new Group();
        this.dimensionFrontLeft.rotation.set(0,0,Math.PI/2);
        this.dimensionFrontLeft.userData = { type: GEOMETRY_TYPE.DIMENSION };
        this.dimensionFrontLeft.visible = false;
        this.scene.add(this.dimensionFrontLeft);

        //test
        // [
        //     this.dimensionPlanTop,
        //     this.dimensionPlanLeft,
        //     this.dimensionRightTop,
        //     this.dimensionRightRight,
        //     this.dimensionLeftTop,
        //     this.dimensionLeftLeft,
        //     this.dimensionFrontTop,
        //     this.dimensionFrontLeft
        // ].forEach(d => {d.visible = true});

        // app.sldSpan.addAction(this.onUIChanged.bind(this));
        // app.sldMultiSpan.addAction(this.onUIChanged.bind(this));
        // app.sldFrontOverhang.addAction(this.onUIChanged.bind(this));
        // app.sldExistingWidth1.addAction(this.onUIChanged.bind(this));
        // app.sldExistingWidth2.addAction(this.onUIChanged.bind(this));
        // app.sldExistingLength.addAction(this.onUIChanged.bind(this));
        // app.sldExistingLength2.addAction(this.onUIChanged.bind(this));
        // app.sltExistingType.addAction(this.onUIChanged.bind(this));
        // app.sldExistingWallHeight.addAction(this.onUIChanged.bind(this));
        // app.dialogEditBay.addAction(this.onUIChanged.bind(this));
        // app.sltStructureType.addAction(this.onUIChanged.bind(this));
        // app.sldLeftOverhang.addAction(this.onUIChanged.bind(this));
        // app.sldRightOverhang.addAction(this.onUIChanged.bind(this));

        return new Promise((resolve, reject) => {
            resolve();
        });
    }
    public optimize() : Promise<void>{
        return new Promise((resolve, reject) => {
            this.lineGeo = this.geometryManager.getDimensionLine();
            this.lineCapGeo = this.geometryManager.getDimensionCap();

            resolve();
        });
    }
    public load(): Promise<void> {    
        return new Promise((resolve, reject) => {
            var loader = new FontLoader();
            loader.load( env.modelBaseUrl + '/assets/fonts/helvetiker_regular.typeface.json', (font) => {
                this.font  = font;

                this.textParameter = {
                    font: this.font,
                    size: 200,
                    height: 5,
                    curveSegments: 10
                };
                this.options = {
                    container: HomeComponent.ins.scene,
                    start: new Vector3(),
                    end: new Vector3(),
                    linePos: new Vector3(),
                    views: [],
                    allowDrag: true,
                    textParameter: this.textParameter,
                    textPos: new Vector3(),
                    labelType: null,
                    fitZ: 10,
                    hasTextClone: false,
                    axis: 'x',
                    lineLength: 0,
                    onDragCallback: this.onDimDrag.bind(this),
                    onDragStartCallback: this.onDimDragStart.bind(this)
                }

                this.isReady = true;
                this.draw();                

                resolve();
            })
        });
    }

    public unload(){
        this.scene.remove(this.dimensionPlanTop);
        this.scene.remove(this.dimensionPlanLeft);
        this.scene.remove(this.dimensionRightTop);
        this.scene.remove(this.dimensionRightRight);
        this.scene.remove(this.dimensionLeftTop);
        this.scene.remove(this.dimensionLeftLeft);
        this.scene.remove(this.dimensionFrontTop);
        this.scene.remove(this.dimensionFrontLeft);
        this.scene.remove(this.dimensionEaveHeight);
    }
    
    public draw(){
        if (!this.isReady) {
            return;
        }

        // this.dimensionPlanTop.children = [];
        // this.dimensionPlanLeft.children = [];
        this.dimensionPlanTop.children = this.dimensionPlanTop.children.filter( el =>
            el.userData?.groupType == DIMENSION_LABEL_TYPE.LENGTH_1
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.LENGTH_2
        );
        this.dimensionPlanLeft.children = this.dimensionPlanLeft.children.filter( el =>
            el.userData?.groupType == DIMENSION_LABEL_TYPE.WIDTH_1
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.WIDTH_2
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.EAVE_WIDTH
        );
        this.dimensionEaveHeight.children = this.dimensionEaveHeight.children.filter( el =>
            el.userData?.groupType == DIMENSION_LABEL_TYPE.EAVE_HEIGHT_DIM
        );
        this.dimensionFrontTop.children = [];
        this.dimensionFrontLeft.children = [];
        this.dimensionLeftTop.children = [];
        this.dimensionLeftLeft.children = [];
        this.dimensionRightTop.children = [];
        this.dimensionRightRight.children = [];

        this.scene.remove(...this.scene.children.filter(c => c.userData.type == GEOMETRY_TYPE.DIRECTION_TEXT));

        this.box = this.utils.getSceneBox();
        
        this.addText("LEFT", {position: {trai: true}});
        this.addText("RIGHT", {position: {phai: true}});
        this.addText("FRONT", {position: {truoc: true}});
        this.addText("BACK", {position: {sau: true}});

        if(UI.structureType == CONNECTION_TYPE.FREE_STANDING){
            this.dimensionPlanTop.children = [];
            this.dimensionPlanLeft.children = [];
            this.dimensionEaveHeight.children = [];
            return;
        }

        this.drawDimensionPlanTop({length1: true});        
        if(this.APP.sldExistingLength2.currentValue > 0){
            this.drawDimensionPlanTop({length2: true});
        } else {
            this.dimensionPlanTop.children = this.dimensionPlanTop.children.filter(el => el.userData?.groupType !== DIMENSION_LABEL_TYPE.LENGTH_2)
        }
        
        this.drawDimensionPlanLeft({eaveWidth: true});
        //WIDTH 1 AND WIDTH 2 IN LEFT
        if(this.APP.sltExistingType.currentValue != 0 && this.APP.sltExistingType.currentValue != 4){
            if(this.APP.sldExistingWidth1.currentValue > 0){
                this.drawDimensionPlanLeft({width1: true});
            } else {
                this.dimensionPlanLeft.children = this.dimensionPlanLeft.children.filter(el => 
                    el.userData?.groupType !== DIMENSION_LABEL_TYPE.WIDTH_1
                )
            }
            if(this.APP.sldExistingWidth2.currentValue > 0){
                this.drawDimensionPlanLeft({width2: true});
            } else {
                this.dimensionPlanLeft.children = this.dimensionPlanLeft.children.filter(el => 
                    el.userData?.groupType !== DIMENSION_LABEL_TYPE.WIDTH_2
                )
            }
        } else {
            this.dimensionPlanLeft.children = this.dimensionPlanLeft.children.filter(el => 
                el.userData?.groupType !== DIMENSION_LABEL_TYPE.WIDTH_1
                && el.userData?.groupType !== DIMENSION_LABEL_TYPE.WIDTH_2
            )
        }

        this.drawDimensionEaveHeight();
        
        this.drawDimensionFrontTop();
        this.drawDimensionFrontLeft();

        this.drawDimensionLeftTop();
        this.drawDimensionLeftLeft();
        
        this.drawDimensionRightTop();
        this.drawDimensionRightRight();

        let offset = this.calulateBoundingBox();
        this.dimensionPlanLeft.position.setX(offset.left);

        //this.getOutlines();
    }

    private drawDimensionPlanTop(userDataPos){
        let offsetZ = this.APP.sldExistingWidth1.currentValue/2 + this.dimensionDistance;
        this.dimensionPlanTop.position.setZ(-offsetZ);

        let views = [ { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS } ];

        if(userDataPos.length1){
            let offsetX = this.APP.sldExistingLength.currentValue/2;
            let scaleX = this.APP.sldExistingLength.currentValue/this.lineGeo.length;

            // NO delete
            //if(this.APP.sltExistingType.currentValue == 1 && this.APP.sldExistingWidth1.currentValue > 0){
            //    cap1.position.setX(-offsetX);
                
            //    offsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.existingWallManager.geo_existingWallW1.width;
            //    cap2.position.setX(offsetX);
                
            //    scaleX = (this.APP.sldExistingLength.currentValue + this.APP.existingWallManager.geo_existingWallW1.width)/this.lineGeo.length;
            //    line.scale.setX(scaleX);
            //    line.position.setX(this.APP.existingWallManager.geo_existingWallW1.width/2);
            //}
            //else if(this.APP.sltExistingType.currentValue == 2 && this.APP.sldExistingWidth1.currentValue > 0){
            //    cap1.position.setX(offsetX);
                
            //    offsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.existingWallManager.geo_existingWallW1.width;
            //    cap2.position.setX(-offsetX);
                
            //    scaleX = (this.APP.sldExistingLength.currentValue + this.APP.existingWallManager.geo_existingWallW1.width)/this.lineGeo.length;
            //    line.scale.setX(scaleX);
            //    line.position.setX(-this.APP.existingWallManager.geo_existingWallW1.width/2);
            //}
            //else if(this.APP.sltExistingType.currentValue == 3 && this.APP.sldExistingWidth1.currentValue > 0){
            //    offsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.existingWallManager.geo_existingWallW1.width;
            //    cap1.position.setX(offsetX);
            //    cap2.position.setX(-offsetX);
                
            //    scaleX = (this.APP.sldExistingLength.currentValue + this.APP.existingWallManager.geo_existingWallW1.width*2)/this.lineGeo.length;
            //    line.scale.setX(scaleX);
            //}
            
            let textStr = this.utils.getDimensionValue(this.APP.sldExistingLength.currentValue);
            let options : AddDimensionOptions = {... this.options,
                lineLength: this.APP.sldExistingLength.currentValue,
                container: this.dimensionPlanTop,
                views: views,
                textPos: new Vector3(0, -200, 0),
                labelType: DIMENSION_LABEL_TYPE.LENGTH_1,
                hasTextClone: true,
                textCloneUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0,0,0) },
                textCloneRotation: new Vector3(Math.PI,Math.PI,0),
                textCloneVisible: false
            }
            let dim = this.dimensionPlanTop.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.LENGTH_1) as Dimension;
            if(!dim){
                new Dimension(options);
            } else {
                dim.update(options);
            }
        }
        else if(userDataPos.length2){
            let offsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.sldExistingLength2.currentValue;
            let textOffsetX = this.APP.sldExistingLength2.currentValue/2 + this.APP.sldExistingLength.currentValue/2;
            let scaleX = this.APP.sldExistingLength2.currentValue/this.lineGeo.length;

            let textStr = this.utils.getDimensionValue(this.APP.sldExistingLength2.currentValue);
            this.textGeo = new TextBufferGeometry(textStr, this.textParameter);
            this.textGeo.center();

            if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                let options : AddDimensionOptions = {... this.options,
                    container: this.dimensionPlanTop,
                    views: views,
                    textPos: new Vector3( textOffsetX, -200, 0),
                    linePos: new Vector3( textOffsetX, 0, 0),
                    lineLength: this.APP.sldExistingLength2.currentValue,
                    labelType: DIMENSION_LABEL_TYPE.LENGTH_2,
                    hasTextClone: true,
                    textCloneUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0,0,0) },
                    textCloneRotation: new Vector3(Math.PI,Math.PI,0),
                    textCloneVisible: false,
                    side: BUILDING_SIDE.LEFT
                }
                let dim = this.dimensionPlanTop.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.LENGTH_2 && el.userData?.side == BUILDING_SIDE.LEFT) as Dimension;
                if(!dim){
                    new Dimension(options);
                } else {
                    dim.update(options);
                }
            } else {
                this.dimensionPlanTop.children = this.dimensionPlanTop.children.filter(el => el.userData?.groupType !== DIMENSION_LABEL_TYPE.LENGTH_2 || (el.userData?.groupType !== DIMENSION_LABEL_TYPE.LENGTH_2 && el.userData?.side !== BUILDING_SIDE.LEFT))
            }
            if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                let options : AddDimensionOptions = {... this.options,
                    container: this.dimensionPlanTop,
                    views: views,
                    textPos: new Vector3( -textOffsetX, -200, 0),
                    linePos: new Vector3( -textOffsetX, 0, 0),
                    lineLength: this.APP.sldExistingLength2.currentValue,
                    labelType: DIMENSION_LABEL_TYPE.LENGTH_2,
                    hasTextClone: true,
                    textCloneUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0,0,0) },
                    textCloneRotation: new Vector3(Math.PI,Math.PI,0),
                    textCloneVisible: false,
                    side: BUILDING_SIDE.RIGHT
                }
                let dim = this.dimensionPlanTop.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.LENGTH_2 && el.userData?.side == BUILDING_SIDE.RIGHT) as Dimension;
                if(!dim){
                    new Dimension(options);
                } else {
                    dim.update(options);
                }
            } else {
                this.dimensionPlanTop.children = this.dimensionPlanTop.children.filter(el => el.userData?.groupType !== DIMENSION_LABEL_TYPE.LENGTH_2 || (el.userData?.groupType == DIMENSION_LABEL_TYPE.LENGTH_2 && el.userData?.side !== BUILDING_SIDE.RIGHT))
            }
        }
    }
    onDimDrag(event){
        if(event.object.userData?.dimType == DIMENSION_LABEL_TYPE.LENGTH_1){
            const obj = event.object as Mesh
            obj.position.z = 0
            obj.updateMatrixWorld()
            const pos = obj.position.clone();
            event.object.position.z = 0;
            event.object.position.y = 0;
            if (event.object.position.x < - HomeComponent.ins.sldExistingLength.maxValue / 2){
                event.object.position.x = - HomeComponent.ins.sldExistingLength.maxValue / 2
            } else if (event.object.position.x > HomeComponent.ins.sldExistingLength.maxValue / 2){
                event.object.position.x = HomeComponent.ins.sldExistingLength.maxValue / 2
            }
            if(event.object.userData?.left){
                if(event.object.position.x < HomeComponent.ins.sldExistingLength.minValue / 2){
                    event.object.position.x = HomeComponent.ins.sldExistingLength.minValue / 2
                }
            }
            if(event.object.userData?.right){
                if(event.object.position.x > - HomeComponent.ins.sldExistingLength.minValue / 2){
                    event.object.position.x = - HomeComponent.ins.sldExistingLength.minValue / 2
                }
            }

            let distance = HomeComponent.ins.sldExistingLength.currentValue;
            if(obj.userData.left){
                if(pos.x > this.startPos.x){
                    distance = HomeComponent.ins.sldExistingLength.currentValue + 2 * (pos.x - this.startPos.x);
                } else if (pos.x < this.startPos.x){
                    distance = HomeComponent.ins.sldExistingLength.currentValue - 2 * (- pos.x + this.startPos.x);
                }
            } else {
                if(pos.x > this.startPos.x){
                    distance = HomeComponent.ins.sldExistingLength.currentValue - 2 * (pos.x - this.startPos.x);
                } else if (pos.x < this.startPos.x){
                    distance = HomeComponent.ins.sldExistingLength.currentValue + 2 * (- pos.x + this.startPos.x);
                }
            }
            distance = Math.round(distance / 10) * 10;
            if(distance < HomeComponent.ins.sldExistingLength.minValue){
                distance = HomeComponent.ins.sldExistingLength.minValue;
            }
            if(distance > HomeComponent.ins.sldExistingLength.maxValue){
                distance = HomeComponent.ins.sldExistingLength.maxValue
            }
            if(HomeComponent.ins.sldExistingLength._isDisable){
                HomeComponent.ins.sldExistingLength.setValue(UI.existingLength1);
            } else {
                HomeComponent.ins.sldExistingLength.setValue(distance);
            }
        } else if ( event.object.userData?.dimType == DIMENSION_LABEL_TYPE.LENGTH_2 ){
            const obj = event.object as Mesh
            obj.position.z = 0
            obj.updateMatrixWorld()
            const pos = obj.position.clone();
            event.object.position.z = 0;
            event.object.position.y = 0;
            if(this.startPos.x < 0){
                if (event.object.position.x < - UI.existingLength1 / 2 - HomeComponent.ins.sldExistingLength2.maxValue){
                    event.object.position.x = - UI.existingLength1 / 2 - HomeComponent.ins.sldExistingLength2.maxValue
                } 
                if (event.object.position.x > - UI.existingLength1 / 2){
                    event.object.position.x = - UI.existingLength1 / 2
                }
                if(event.object.userData?.right){
                    if(event.object.position.x > - UI.existingLength1 / 2 - HomeComponent.ins.sldExistingLength2.minValue){
                        event.object.position.x = - UI.existingLength1 / 2 - HomeComponent.ins.sldExistingLength2.minValue
                    }
                }
                if(event.object.userData?.left){
                    if(event.object.position.x < -  UI.existingLength1 / 2){
                        event.object.position.x = -  UI.existingLength1 / 2
                    }
                }
            }
            if(this.startPos.x > 0){
                if (event.object.position.x > UI.existingLength1 / 2 + HomeComponent.ins.sldExistingLength2.maxValue){
                    event.object.position.x = UI.existingLength1 / 2 + HomeComponent.ins.sldExistingLength2.maxValue
                } 
                if (event.object.position.x < UI.existingLength1 / 2){
                    event.object.position.x = UI.existingLength1 / 2
                }
                if(event.object.userData?.left){
                    if(event.object.position.x < UI.existingLength1 / 2 + HomeComponent.ins.sldExistingLength2.minValue){
                        event.object.position.x = UI.existingLength1 / 2 + HomeComponent.ins.sldExistingLength2.minValue
                    }
                }
                if(event.object.userData?.right){
                    if(event.object.position.x > UI.existingLength1 / 2){
                        event.object.position.x = UI.existingLength1 / 2
                    }
                }
            }
            let distance = HomeComponent.ins.sldExistingLength2.currentValue;
            if(this.startPos.x < 0){
                // RIGHT
                if(obj.userData.left){
                    if(pos.x > this.startPos.x){
                        distance = HomeComponent.ins.sldExistingLength2.currentValue + (pos.x - this.startPos.x);
                    } else if (obj.position.x < this.startPos.x){
                        distance = HomeComponent.ins.sldExistingLength2.currentValue - (- pos.x + this.startPos.x);
                    }
                } else {
                    if(pos.x > this.startPos.x){
                        distance = HomeComponent.ins.sldExistingLength2.currentValue - (pos.x - this.startPos.x);
                    } else if (pos.x < this.startPos.x){
                        distance = HomeComponent.ins.sldExistingLength2.currentValue + (- pos.x + this.startPos.x);
                    }
                }
            } else if(this.startPos.x > 0){
                // LEFT
                if(obj.userData.left){
                    if(pos.x > this.startPos.x){
                        distance = HomeComponent.ins.sldExistingLength2.currentValue + (pos.x - this.startPos.x);
                    } else if (pos.x < this.startPos.x){
                        distance = HomeComponent.ins.sldExistingLength2.currentValue - (- pos.x + this.startPos.x);
                    }
                } else {
                    if(pos.x > this.startPos.x){
                        distance = HomeComponent.ins.sldExistingLength2.currentValue - (pos.x - this.startPos.x);
                    } else if (pos.x < this.startPos.x){
                        distance = HomeComponent.ins.sldExistingLength2.currentValue + (- pos.x + this.startPos.x);
                    }
                }
            }
            distance = Math.round(distance / 10) * 10;
            if(distance < HomeComponent.ins.sldExistingLength2.minValue){
                distance = HomeComponent.ins.sldExistingLength2.minValue;
            }
            if(distance > HomeComponent.ins.sldExistingLength2.maxValue){
                distance = HomeComponent.ins.sldExistingLength2.maxValue
            }
            HomeComponent.ins.sldExistingLength2.setValue(distance);
        } else if ( event.object.userData?.dimType == DIMENSION_LABEL_TYPE.WIDTH_1 ){
            const obj = event.object as Mesh
            obj.position.z = 0
            obj.updateMatrixWorld()
            const pos = obj.position.clone();
            event.object.position.z = 0;
            event.object.position.y = 0;
            if (event.object.position.x < - HomeComponent.ins.sldExistingWidth1.maxValue / 2){
                event.object.position.x = - HomeComponent.ins.sldExistingWidth1.maxValue / 2
            } else if (event.object.position.x > HomeComponent.ins.sldExistingWidth1.maxValue / 2){
                event.object.position.x = HomeComponent.ins.sldExistingWidth1.maxValue / 2
            }
            if(event.object.userData?.left){
                if(event.object.position.x < HomeComponent.ins.sldExistingWidth1.minValue / 2){
                    event.object.position.x = HomeComponent.ins.sldExistingWidth1.minValue / 2
                }
            }
            if(event.object.userData?.right){
                if(event.object.position.x > - HomeComponent.ins.sldExistingWidth1.minValue / 2){
                    event.object.position.x = - HomeComponent.ins.sldExistingWidth1.minValue / 2
                }
            }
            let distance = HomeComponent.ins.sldExistingWidth1.currentValue;
            if(obj.userData.left){
                if(pos.x > this.startPos.x){
                    distance = HomeComponent.ins.sldExistingWidth1.currentValue + 2 * (pos.x - this.startPos.x);
                } else if (pos.x < this.startPos.x){
                    distance = HomeComponent.ins.sldExistingWidth1.currentValue - 2 * (- pos.x + this.startPos.x);
                }
            } else {
                if(pos.x > this.startPos.x){
                    distance = HomeComponent.ins.sldExistingWidth1.currentValue - 2 * (pos.x - this.startPos.x);
                } else if (pos.x < this.startPos.x){
                    distance = HomeComponent.ins.sldExistingWidth1.currentValue + 2 * (- pos.x + this.startPos.x);
                }
            }
            distance = Math.round(distance / 10) * 10;
            if(distance < HomeComponent.ins.sldExistingWidth1.minValue){
                distance = HomeComponent.ins.sldExistingWidth1.minValue;
            }
            if(distance > HomeComponent.ins.sldExistingWidth1.maxValue){
                distance = HomeComponent.ins.sldExistingWidth1.maxValue
            }
            HomeComponent.ins.sldExistingWidth1.setValue(distance);
        } else if ( event.object.userData?.dimType == DIMENSION_LABEL_TYPE.WIDTH_2 ){
            const obj = event.object as Mesh
            obj.position.z = 0
            obj.updateMatrixWorld()
            const pos = obj.position.clone();
            event.object.position.z = 0;
            event.object.position.y = 0;
            if (event.object.position.x < UI.existingWidth1 / 2){
                event.object.position.x = UI.existingWidth1 / 2
            } else if (event.object.position.x > UI.existingWidth1 / 2 + HomeComponent.ins.sldExistingWidth2.maxValue){
                event.object.position.x = UI.existingWidth1 / 2 + HomeComponent.ins.sldExistingWidth2.maxValue
            }
            if(event.object.userData?.left){
                if(event.object.position.x < UI.existingWidth1 / 2 + HomeComponent.ins.sldExistingWidth2.minValue / 2){
                    event.object.position.x = UI.existingWidth1 / 2 + HomeComponent.ins.sldExistingWidth2.minValue / 2
                }
            }
            if(event.object.userData?.right){
                if(event.object.position.x > UI.existingWidth1 / 2){
                    event.object.position.x = UI.existingWidth1 / 2
                }
            }
            let distance = HomeComponent.ins.sldExistingWidth2.currentValue;
            if(obj.userData?.left){
                if(pos.x > this.startPos.x){
                    distance = HomeComponent.ins.sldExistingWidth2.currentValue + (pos.x - this.startPos.x);
                } else if (obj.position.x < this.startPos.x){
                    distance = HomeComponent.ins.sldExistingWidth2.currentValue - (- pos.x + this.startPos.x);
                }
            } else {
                if(pos.x > this.startPos.x){
                    distance = HomeComponent.ins.sldExistingWidth2.currentValue - (pos.x - this.startPos.x);
                } else if (pos.x < this.startPos.x){
                    distance = HomeComponent.ins.sldExistingWidth2.currentValue + (- pos.x + this.startPos.x);
                }
            }
            distance = Math.round(distance / 10) * 10;
            if(distance < HomeComponent.ins.sldExistingWidth2.minValue){
                distance = HomeComponent.ins.sldExistingWidth2.minValue;
            }
            if(distance > HomeComponent.ins.sldExistingWidth2.maxValue){
                distance = HomeComponent.ins.sldExistingWidth2.maxValue
            }
            HomeComponent.ins.sldExistingWidth2.setValue(distance);
        } else if ( event.object.userData?.dimType == DIMENSION_LABEL_TYPE.EAVE_HEIGHT_DIM ){
            const obj = event.object as Mesh
            obj.position.z = 0
            obj.updateMatrixWorld()
            const pos = obj.position.clone();
            event.object.position.z = 0;
            event.object.position.y = 0;
            if (event.object.position.x < - HomeComponent.ins.sldExistingWallHeight.maxValue / 2){
                event.object.position.x = - HomeComponent.ins.sldExistingWallHeight.maxValue / 2
            } else if (event.object.position.x > HomeComponent.ins.sldExistingWallHeight.maxValue / 2){
                event.object.position.x = HomeComponent.ins.sldExistingWallHeight.maxValue / 2
            }
            if(event.object.userData?.left){
                if(event.object.position.x < HomeComponent.ins.sldExistingWallHeight.minValue / 2){
                    event.object.position.x = HomeComponent.ins.sldExistingWallHeight.minValue / 2
                }
            }
            if(event.object.userData?.right){
                if(event.object.position.x > - HomeComponent.ins.sldExistingWallHeight.minValue / 2){
                    event.object.position.x = - HomeComponent.ins.sldExistingWallHeight.minValue / 2
                }
            }
            let distance = HomeComponent.ins.sldExistingWallHeight.currentValue;
            if(obj.userData.left){
                if(pos.x > this.startPos.x){
                    distance = HomeComponent.ins.sldExistingWallHeight.currentValue + (pos.x - this.startPos.x);
                } else if (pos.x < this.startPos.x){
                    distance = HomeComponent.ins.sldExistingWallHeight.currentValue - (- pos.x + this.startPos.x);
                }
            } else {
                if(pos.x > this.startPos.x){
                    distance = HomeComponent.ins.sldExistingWallHeight.currentValue - (pos.x - this.startPos.x);
                } else if (pos.x < this.startPos.x){
                    distance = HomeComponent.ins.sldExistingWallHeight.currentValue + (- pos.x + this.startPos.x);
                }
            }
            distance = Math.round(distance / 10) * 10;
            if(distance < HomeComponent.ins.sldExistingWallHeight.minValue){
                distance = HomeComponent.ins.sldExistingWallHeight.minValue;
            }
            if(distance > HomeComponent.ins.sldExistingWallHeight.maxValue){
                distance = HomeComponent.ins.sldExistingWallHeight.maxValue
            }
            HomeComponent.ins.sldExistingWallHeight.setValue(distance);
        }
        this.startPos = event.object.position.clone()
    }
    private drawDimensionEaveHeight() {
        let _dimensionDistane = this.dimensionDistance2;
        if(UI.existingType == BUILDING_SIDE.LEFT || UI.existingType == BUILDING_SIDE.RIGHT){
            _dimensionDistane = this.dimensionDistance;
        }

        let dimensionOffsetX = this.box.min.x - this.dimensionDistance2;
        if (UI.structureType == CONNECTION_TYPE.FASCIA || UI.structureType == CONNECTION_TYPE.FASCIA_UPSTAND) {
            if (UI.existingType == 2) {
                dimensionOffsetX -= UI.eaveWidth;
            }
        }
        this.dimensionEaveHeight.position.set(dimensionOffsetX, UI.eaveHeight / 2, -UI.existingWidth1 / 2);

        let textStr = this.utils.getDimensionValue(UI.eaveHeight);
        let options : AddDimensionOptions = {
            ... this.options,
            lineLength: UI.eaveHeight,
            container: this.dimensionEaveHeight,
            views: [],
            linePos: new Vector3(0, 0, 0),
            textPos: new Vector3(0, 200, 0),
            labelType: DIMENSION_LABEL_TYPE.EAVE_HEIGHT_DIM,
            textUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: [] },
            allowDrag: true
        }
        let dimBay = this.dimensionEaveHeight.children.find(el => 
            el.userData?.groupType == DIMENSION_LABEL_TYPE.EAVE_HEIGHT_DIM
        ) as Dimension;
        if(!dimBay){
            new Dimension(options);
        } else {
            dimBay.update(options);
        }
    }
    onDimDragStart(event){
        this.startPos = event.object.position.clone();
    }
    private drawDimensionPlanLeft(userDataPos){
        let _dimensionDistane = this.dimensionDistance2;
        if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
            _dimensionDistane = this.dimensionDistance;
        }

        //let dimensionOffsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.sldExistingLength2.currentValue + _dimensionDistane;
        let dimensionOffsetX = this.box.min.x - this.dimensionDistance2;

        if (this.APP.sltStructureType.currentValue == 3 || this.APP.sltStructureType.currentValue == 5) {
            if (this.APP.sltExistingType.currentValue == 2) {
                dimensionOffsetX -= this.APP.sldEaveWidth.currentValue;
            }
        }

        //if(this.APP.sltExistingType.currentValue == 2){
        //    dimensionOffsetX -= this.APP.sldExistingLength2.currentValue;
        //}

        this.dimensionPlanLeft.position.setX(dimensionOffsetX);

        let views = [ { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS } ];

        if(userDataPos.eaveWidth){

            let textStr = this.utils.getDimensionValue(this.APP.sldEaveWidth.currentValue);
            let options : AddDimensionOptions = {... this.options,
                lineLength: this.APP.sldEaveWidth.currentValue,
                container: this.dimensionPlanLeft,
                views: views,
                textPos: this.APP.sltExistingType.currentValue == 0 ? new Vector3(this.APP.sldEaveWidth.currentValue/2, - 200, 0) : new Vector3( - this.APP.sldExistingWidth1.currentValue/2 + this.APP.sldEaveWidth.currentValue/2, 200, 0),
                linePos: this.APP.sltExistingType.currentValue == 0 ? new Vector3(this.APP.sldEaveWidth.currentValue/2, 0, 0) : new Vector3( - this.APP.sldExistingWidth1.currentValue/2 + this.APP.sldEaveWidth.currentValue/2, 400, 0),
                labelType: DIMENSION_LABEL_TYPE.EAVE_WIDTH,
                hasTextClone: false,
                textCloneUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0,Math.PI/2,0) },
                textCloneRotation: new Vector3(Math.PI,Math.PI,0),
                textCloneVisible: false,
                textUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: this.APP.sldEaveWidth.currentValue.toString(), views: views, rotation: new Vector3(0,Math.PI/2,0) },
                allowDrag: false,
                visible: false
            }
            let dim = this.dimensionPlanLeft.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.EAVE_WIDTH) as Dimension;
            if(!dim){
                new Dimension(options);
            } else {
                dim.update(options);
            }
        }

        if(userDataPos.width1){
            let offsetZ = this.APP.sldExistingWidth1.currentValue/2;
            let scaleZ = this.APP.sldExistingWidth1.currentValue/this.lineGeo.length;

            let textStr = this.utils.getDimensionValue(this.APP.sldExistingWidth1.currentValue);
            let options : AddDimensionOptions = {... this.options,
                lineLength: this.APP.sldExistingWidth1.currentValue,
                container: this.dimensionPlanLeft,
                views: views,
                textPos: new Vector3(0, -200, 0),
                labelType: DIMENSION_LABEL_TYPE.WIDTH_1,
                hasTextClone: false,
                textCloneUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0,Math.PI/2,0) },
                textCloneRotation: new Vector3(Math.PI,Math.PI,0),
                textCloneVisible: false,
                textUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: this.APP.sldExistingWidth1.currentValue.toString(), views: views, rotation: new Vector3(0,Math.PI/2,0) }
            }
            let dim = this.dimensionPlanLeft.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.WIDTH_1) as Dimension;
            if(!dim){
                new Dimension(options);
            } else {
                dim.update(options);
            }
        }
        if(userDataPos.width2){
            let textStr = this.utils.getDimensionValue(this.APP.sldExistingWidth2.currentValue);
            let options : AddDimensionOptions = {... this.options,
                container: this.dimensionPlanLeft,
                views: views,
                textPos: new Vector3( this.APP.sldExistingWidth1.currentValue/2 + this.APP.sldExistingWidth2.currentValue/2, -200, 0),
                linePos: new Vector3( this.APP.sldExistingWidth1.currentValue/2 + this.APP.sldExistingWidth2.currentValue/2, 0, 0),
                lineLength: this.APP.sldExistingWidth2.currentValue,
                labelType: DIMENSION_LABEL_TYPE.WIDTH_2,
                hasTextClone: false,
                textUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0,Math.PI/2,0) },
                textCloneUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0,Math.PI/2,0) },
                textCloneRotation: new Vector3(Math.PI,Math.PI,0),
                textCloneVisible: false
            }
            let dim = this.dimensionPlanLeft.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.WIDTH_2) as Dimension;
            if(!dim){
                new Dimension(options);
            } else {
                dim.update(options);
            }
        }
    }
    private drawDimensionFrontLeft(){
        // if(this.APP.sltExistingType.currentValue == 0){
        //     return;
        // }

        let dimensionOffsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.sldExistingLength2.currentValue + this.dimensionDistance2;

        if(this.APP.sltExistingType.currentValue == 2){
            dimensionOffsetX -= this.APP.sldExistingLength2.currentValue;
        }
        
        this.dimensionFrontLeft.position.set(-dimensionOffsetX,this.APP.sldExistingWallHeight.currentValue/2,0);

        let offsetY = this.APP.sldExistingWallHeight.currentValue/2;
        let scaleY = this.APP.sldExistingWallHeight.currentValue/this.lineGeo.length;

        let views = [ { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS } ];

        if(this.APP.sltExistingType.currentValue == 0)
           views = null;
        
        let cap1 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap1.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap1.position.setX(-offsetY);

        let cap2 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap2.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap2.position.setX(offsetY);

        let line = new Mesh(this.lineGeo.geometry, this.materialManager.DIMENSION_TEXT);
        line.userData = { type: GEOMETRY_TYPE.DIMENSION_LINE, views: views };
        line.scale.setX(scaleY);

        let textStr = this.utils.getDimensionValue(this.APP.sldExistingWallHeight.currentValue);
        this.textGeo = new TextBufferGeometry(textStr, this.textParameter);
        this.textGeo.center();

        let text = new Mesh(this.textGeo, MaterialManager.Instance().DIMENSION_TEXT);
        text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, rotation: new Vector3(0,0,Math.PI/2), views: views };
        text.position.setY(200);
    
        this.dimensionFrontLeft.add(line, cap1, cap2, text, ...this.utils.addArrowForLine(line));
    }
    private drawDimensionFrontTop(){  
        if(this.APP.sltExistingType.currentValue == 0){
            return;
        }
         
        let offsetY = this.APP.sldExistingWallHeight.currentValue + this.APP.sldFlyOverBracketHeight.currentValue + EXISTING_BUILDING_CONFIG.EXTRA_BRACKET_HEIGHT + this.dimensionDistance2;
        
        if (this.APP.sltStructureType.currentValue == 0 || this.APP.sltStructureType.currentValue == 3 || this.APP.sltStructureType.currentValue == 5) {
            if (this.APP.sldExistingWidth1.currentValue == 0) {
                offsetY = this.APP.sldBuildingHeight.currentValue + this.dimensionDistance2;
            }
        }

        if(this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF && this.APP.isFBRoof){
            if(UI.structureType === CONNECTION_TYPE.FLY_OVER){
                offsetY = UI.eaveHeight
                    + UI.braketHeight
                    + this.geometryManager.getBeam().height
                    + this.utils.tan(UI.patiosPitch) * (UI.span / 2)
                    + this.dimensionDistance2
            }
            if(UI.structureType === CONNECTION_TYPE.FASCIA_UPSTAND){
                offsetY = UI.existingWallHeight
                + +UI.upstandBraketType
                + this.geometryManager.getBeam().height
                + this.utils.tan(UI.patiosPitch) * (UI.span / 2)
                + this.dimensionDistance2
            }
            if(UI.structureType === CONNECTION_TYPE.EXISTING){
                offsetY = UI.existingWallHeight + this.dimensionDistance2
            }
        }
        if(this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF && !this.APP.isFBRoof){
            if(UI.structureType === CONNECTION_TYPE.FLY_OVER){
                offsetY = UI.eaveHeight
                    + UI.braketHeight
                    + this.geometryManager.getBeam().height
                    + this.utils.tan(UI.patiosPitch) * ((UI.span + UI.multiSpan) / 2)
                    + this.dimensionDistance2
            }
            if(UI.structureType === CONNECTION_TYPE.FASCIA_UPSTAND){
                offsetY = UI.existingWallHeight
                    + +UI.upstandBraketType
                    + this.geometryManager.getBeam().height
                    + this.utils.tan(UI.patiosPitch) * ((UI.span + UI.multiSpan) / 2)
                    + this.dimensionDistance2
            }
            if(UI.structureType === CONNECTION_TYPE.FREE_STANDING){
                offsetY = this.APP.sldBuildingHeight.currentValue + this.utils.tan(this.APP.sltRoofPitch.currentValue) * (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2 + this.dimensionDistance2
            }
        }

        this.dimensionFrontTop.position.setY(offsetY);

        let views = [ { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS } ];

        this.dimensionPlanTop.children.forEach( dimGroup => {
            dimGroup.children.forEach(c => {
                let clone = c.clone();
                clone.visible = false;
                clone.userData['views'] = views;
    
                if(c.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && c.visible){
                    clone.position.setY(-c.position.y);
                    clone.rotation.set(0, Math.PI, 0);
                } else if(c.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && !c.visible){
                    return;
                }
                this.dimensionFrontTop.add(clone);
            })
        })
    }
    private drawDimensionLeftTop() {
        let offsetY = this.APP.sldExistingWallHeight.currentValue + this.APP.sldFlyOverBracketHeight.currentValue + EXISTING_BUILDING_CONFIG.EXTRA_BRACKET_HEIGHT + this.dimensionDistance2
        if(this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF && this.APP.isFBRoof){
            if(UI.structureType === CONNECTION_TYPE.FLY_OVER){
                offsetY = UI.eaveHeight
                    + UI.braketHeight
                    + this.geometryManager.getBeam().height
                    + this.utils.tan(UI.patiosPitch) * (UI.span / 2)
                    + this.dimensionDistance2
            }
            if(UI.structureType === CONNECTION_TYPE.FASCIA_UPSTAND){
                offsetY = UI.existingWallHeight
                + +UI.upstandBraketType
                + this.geometryManager.getBeam().height
                + this.utils.tan(UI.patiosPitch) * (UI.span / 2)
                + this.dimensionDistance2
            }
            if(UI.structureType === CONNECTION_TYPE.EXISTING){
                offsetY = UI.existingWallHeight + this.dimensionDistance2
            }
        }
        if(this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF && !this.APP.isFBRoof){
            if(UI.structureType === CONNECTION_TYPE.FLY_OVER){
                offsetY = UI.eaveHeight
                    + UI.braketHeight
                    + this.geometryManager.getBeam().height
                    + this.utils.tan(UI.patiosPitch) * ((UI.span + UI.multiSpan) / 2)
                    + this.dimensionDistance2
            }
            if(UI.structureType === CONNECTION_TYPE.FASCIA_UPSTAND){
                offsetY = UI.existingWallHeight
                    + +UI.upstandBraketType
                    + this.geometryManager.getBeam().height
                    + this.utils.tan(UI.patiosPitch) * ((UI.span + UI.multiSpan) / 2)
                    + this.dimensionDistance2
            }
            if(UI.structureType === CONNECTION_TYPE.FREE_STANDING){
                offsetY = this.APP.sldBuildingHeight.currentValue + this.utils.tan(this.APP.sltRoofPitch.currentValue) * (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2 + this.dimensionDistance2
            }
        }
        this.dimensionLeftTop.position.setY(offsetY);
        //this.dimensionTopLeft.position.setY(-1000);

        let views = [];

        
        this.dimensionPlanLeft.children.forEach( dimGroup => {
            if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                views = [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS }];
            } else if(dimGroup.userData.groupType == DIMENSION_LABEL_TYPE.EAVE_WIDTH){
                views = [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS }];
            } else {
                views = [];
            }
            dimGroup.children.forEach(c => {
                let clone = c.clone();
                clone.userData['views'] = views;
    
                if(views.length != 0 && c.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && c.visible && dimGroup.userData.groupType == DIMENSION_LABEL_TYPE.EAVE_WIDTH){
                    clone.position.setY(c.position.y + 400);
                } else if(views.length != 0 && c.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && c.visible){
                    clone.position.setY(-c.position.y);
                } else if(c.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && !c.visible){
                    return;
                }
                this.dimensionLeftTop.add(clone);
            })
        })
    }
    private drawDimensionRightTop(){
        let offsetY = this.APP.sldExistingWallHeight.currentValue + this.APP.sldFlyOverBracketHeight.currentValue + EXISTING_BUILDING_CONFIG.EXTRA_BRACKET_HEIGHT + this.dimensionDistance2
        if(this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF && this.APP.isFBRoof){
            if(UI.structureType === CONNECTION_TYPE.FLY_OVER){
                offsetY = UI.eaveHeight
                    + UI.braketHeight
                    + this.geometryManager.getBeam().height
                    + this.utils.tan(UI.patiosPitch) * (UI.span / 2)
                    + this.dimensionDistance2
            }
            if(UI.structureType === CONNECTION_TYPE.FASCIA_UPSTAND){
                offsetY = UI.existingWallHeight
                + +UI.upstandBraketType
                + this.geometryManager.getBeam().height
                + this.utils.tan(UI.patiosPitch) * (UI.span / 2)
                + this.dimensionDistance2
            }
            if(UI.structureType === CONNECTION_TYPE.EXISTING){
                offsetY = UI.existingWallHeight + this.dimensionDistance2
            }
        }
        if(this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF && !this.APP.isFBRoof){
            if(UI.structureType === CONNECTION_TYPE.FLY_OVER){
                offsetY = UI.eaveHeight
                    + UI.braketHeight
                    + this.geometryManager.getBeam().height
                    + this.utils.tan(UI.patiosPitch) * ((UI.span + UI.multiSpan) / 2)
                    + this.dimensionDistance2
            }
            if(UI.structureType === CONNECTION_TYPE.FASCIA_UPSTAND){
                offsetY = UI.existingWallHeight
                    + +UI.upstandBraketType
                    + this.geometryManager.getBeam().height
                    + this.utils.tan(UI.patiosPitch) * ((UI.span + UI.multiSpan) / 2)
                    + this.dimensionDistance2
            }
            if(UI.structureType === CONNECTION_TYPE.FREE_STANDING){
                offsetY = this.APP.sldBuildingHeight.currentValue + this.utils.tan(this.APP.sltRoofPitch.currentValue) * (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2 + this.dimensionDistance2
            }
        }
        this.dimensionRightTop.position.setY(offsetY);
        //this.dimensionTopRight.position.setY(-1000);
        let views = [];

        this.dimensionPlanLeft.children.forEach( dimGroup => {
            if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                views.push({ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS });
            } else if(dimGroup.userData.groupType == DIMENSION_LABEL_TYPE.EAVE_WIDTH){
                views.push({ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS });
            } else {
                views = [];
            }
            dimGroup.children.forEach(c => {
                let clone = c.clone();
                clone.userData['views'] = views;
                if(views.length != 0 && c.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && c.visible && dimGroup.userData.groupType == DIMENSION_LABEL_TYPE.EAVE_WIDTH){
                    clone.position.setY(c.position.y + 400);
                    clone.rotation.set(0, Math.PI, 0);
                    clone.visible = false;
                } else if(views.length != 0 && c.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && c.visible){
                    clone.position.setY(-c.position.y);
                    clone.rotation.set(0, Math.PI, 0);
                    clone.visible = false;
                } else if(c.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && !c.visible){
                    return;
                }
                this.dimensionRightTop.add(clone);
            })
        })
    }
    private drawDimensionLeftLeft(){
        let dimenstionOffsetY = this.APP.sldExistingWallHeight.currentValue/2;
        let dimenstionOffsetZ = this.APP.sldExistingWidth1.currentValue/2 + this.dimensionDistance2 + 500;

        this.dimensionLeftLeft.position.set(0,dimenstionOffsetY,-dimenstionOffsetZ);

        let views = [ 
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            //{ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
        ];

        this.dimensionFrontLeft.children.forEach(c => {
            let clone = c.clone();
            //clone.visible = false;
            clone.userData['views'] = views;

            if(c.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && c.visible){
                clone.position.setY(-c.position.y);
                clone.rotation.set(0, 0,-Math.PI);
                clone.userData['views'] = [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS }];
                clone.userData['rotation'] = new Vector3(Math.PI/2,0,0)
            } else if(c.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && !c.visible){
                return;
            }

            this.dimensionLeftLeft.add(clone);
        })
    }
    private drawDimensionRightRight(){
        let dimenstionOffsetY = this.APP.sldExistingWallHeight.currentValue/2;
        let dimenstionOffsetZ = this.APP.sldExistingWidth1.currentValue/2 + this.dimensionDistance2 + 500;

        this.dimensionRightRight.position.set(0,dimenstionOffsetY,-dimenstionOffsetZ);

        let views = [ { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS } ];

        this.dimensionFrontLeft.children.forEach(c => {
            let clone = c.clone();
            clone.userData['views'] = views;
            if(c.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && c.visible){
                
                clone.userData['rotation'] = new Vector3(-Math.PI/2,0,0)
                clone.position.setY(-c.position.y);
                clone.rotation.set(0, Math.PI,-Math.PI);
                //clone.visible = false;
                
            } else if(c.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && !c.visible){
                return;
            }
            this.dimensionRightRight.add(clone);
        })
    }

    private calulateBoundingBox(){
        this.APP.scene.remove(...this.APP.scene.children.filter(c => c.userData.type == 'BOX_HELPER__'));
        let existingHouseObjs = this.scene.children.filter(c =>
            (c.userData.type == GEOMETRY_TYPE.EXISTING_ROOF && c.userData.position?.width1 || c.userData.position?.width2 || c.userData.position?.length2)
            || (c.userData.type == GEOMETRY_TYPE.EXISTING_WALL)
            );
        let existingHouseBox = this.APP.utils.getObjectsBoundingBox(existingHouseObjs);
       
        let planLeftBox = this.APP.utils.getObjectBoundingBox(this.dimensionPlanLeft);
        planLeftBox.min.x = existingHouseBox.min.x - 500;
        planLeftBox.max.x = existingHouseBox.min.x;
        planLeftBox.max.y = 10000;

        // let boxHelper = new Box3Helper(planRightBox);
        // boxHelper.userData = {type: 'BOX_HELPER_'};
        // this.APP.scene.add(boxHelper);

        // let boxHelper2 = new Box3Helper(planLeftBox);
        // boxHelper2.userData = {type: 'BOX_HELPER__'};
        // this.scene.add(boxHelper2);

        // let boxHelper3 = new Box3Helper(planFrontBox);
        // boxHelper3.userData = {type: 'BOX_HELPER_'};
        // this.APP.scene.add(boxHelper3);

        let patiosBox = this.APP.patiosManager.boundingBox;
        if(patiosBox && planLeftBox.max.x > patiosBox.min.x){
            planLeftBox.translate(new Vector3(patiosBox.min.x - planLeftBox.max.x - 100, 0, 0));
        }

        let dimPatiosLeft = this.scene.children.find(c => c.userData.type == GEOMETRY_TYPE.DIMENSION && c.userData.position?.left);
        if(dimPatiosLeft && planLeftBox.max.x > dimPatiosLeft.position.x){
            planLeftBox.translate(new Vector3(dimPatiosLeft.position.x - planLeftBox.max.x, 0, 0));
        }
        
        return { left: planLeftBox.min.x };
    }

    private addText(text: string, userData: any){
        let textMesh: Mesh;

        let parameter = {
            font: this.font,
            size: 500,
            height: 5,
            curveSegments: 12
        };

        if(userData.position.trai){
            var geometry = new TextBufferGeometry( text,  parameter).center().rotateY(-Math.PI/2).rotateZ(-Math.PI/2);
            textMesh = new Mesh(geometry, MaterialManager.Instance().DIMENSION_TEXT);
            textMesh.position.setX(this.box.min.x - this.textDistance);
            textMesh.userData = {...userData, type: GEOMETRY_TYPE.DIRECTION_TEXT};
        }
        else if(userData.position.phai){
            var geometry = new TextBufferGeometry( text,  parameter).center().rotateY(Math.PI/2).rotateZ(Math.PI/2);
            textMesh = new Mesh(geometry, MaterialManager.Instance().DIMENSION_TEXT);
            textMesh.position.setX(this.box.max.x + this.textDistance);
            textMesh.userData = {...userData, type: GEOMETRY_TYPE.DIRECTION_TEXT};
        }
        else if(userData.position.truoc){
            let offsetZ = this.APP.sldExistingWidth1.currentValue/2 + this.textDistance;
            let totalSpanSize = this.APP.sldSpan.currentValue 
            + this.APP.sldMultiSpan.currentValue 
            + this.APP.sldBackOverhang.currentValue 
            + this.APP.sldFrontOverhang.currentValue
            + this.textDistance;

            if(this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF && this.APP.isFBRoof){
                totalSpanSize = UI.totalBayLength + this.textDistance;
            }
            
            if(this.APP.sldExistingWidth1.currentValue/2 < totalSpanSize){
                offsetZ = totalSpanSize;
            }

            var geometry = new TextBufferGeometry( text,  parameter).center().rotateX(-Math.PI/2);
            textMesh = new Mesh(geometry, MaterialManager.Instance().DIMENSION_TEXT);
            textMesh.position.setZ(offsetZ);
            textMesh.userData = {...userData, type: GEOMETRY_TYPE.DIRECTION_TEXT};
        }
        else if(userData.position.sau){
            let offsetZ = this.APP.sldExistingWidth1.currentValue/2 + this.textDistance;

            if(this.APP.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF){
                offsetZ = (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue ) / 2 + this.APP.sldFrontOverhang.currentValue + this.textDistance;
            }

            var geometry = new TextBufferGeometry( text,  parameter).center().rotateY(Math.PI).rotateX(Math.PI/2);
            textMesh = new Mesh(geometry, MaterialManager.Instance().DIMENSION_TEXT);
            textMesh.position.setZ(-offsetZ);
            textMesh.userData = {...userData, type: GEOMETRY_TYPE.DIRECTION_TEXT};
        }
        
        this.listText.push(textMesh);
        this.scene.add(textMesh);
    }
    public getOutlines(): Printing2DGeometry{
        let lsGeometries: Printing2DLine[] = [];
        let lsText: Print2DText[] = [];
        
        for(let dim of [
            ...this.dimensionPlanTop.children, 
            ...this.dimensionPlanLeft.children, 
            ...this.dimensionFrontTop.children,
            ...this.dimensionFrontLeft.children,
            ...this.dimensionLeftLeft.children,
            ...this.dimensionLeftTop.children,
            ...this.dimensionRightTop.children,
            ...this.dimensionRightRight.children
        ]){
            if(!dim.userData.views){
                console.log("TEXT NO VIEW", dim);
                continue;
            }

            if(dim.type == 'Group'){
                dim.children.forEach(el => {
                    el.updateMatrix();
                    let _clone = el.clone();
                    _clone.applyMatrix4(new Matrix4().getInverse(el.matrix));
                    let _box = new Box3().setFromObject(_clone);

                    let _points = this.utils.getStartEndPoint(_box);
                    let _line = new Line(new Geometry().setFromPoints(_points));
                    
                    el.updateWorldMatrix(true, true);
                    _line.geometry.applyMatrix4(el.matrixWorld);

                    let points = (_line.geometry as Geometry).vertices;
                    let pos = new Vector3().applyMatrix4(el.matrixWorld);

                    let textRotation = el.userData.rotation || new Vector3();
                    this.getDimInfo(lsGeometries, lsText, el, pos, textRotation, points)
                })
            } else{
                dim.updateMatrix();
                let _clone = dim.clone();
                _clone.applyMatrix4(new Matrix4().getInverse(dim.matrix));
                let _box = new Box3().setFromObject(_clone);

                let _points = this.utils.getStartEndPoint(_box);
                let _line = new Line(new Geometry().setFromPoints(_points));
                
                dim.updateWorldMatrix(true, true);
                _line.geometry.applyMatrix4(dim.matrixWorld);

                let points = (_line.geometry as Geometry).vertices;
                let pos = new Vector3().applyMatrix4(dim.matrixWorld);

                let textRotation = dim.userData.rotation || new Vector3();
                this.getDimInfo(lsGeometries, lsText, dim, pos, textRotation, points)
            }
        }
        return { lines: lsGeometries, texts: lsText };
    }
    private getDimInfo(lsGeometries: Printing2DLine[], lsText: Print2DText[], dim: Object3D, pos: Vector3, textRotation: Vector3, points: Vector3[]){
        if (dim.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && dim.userData.views && dim.userData.value) {
            let box = new Box3().setFromObject(dim.parent);
            let startEnd = this.utils.getStartEndPoint(box);
            let line = new Line3(startEnd[0], startEnd[1]);

            let projectPoint = new Vector3();
            line.closestPointToPoint(pos, true, projectPoint);
            let _pos = new Vector3().addVectors(projectPoint, new Vector3().subVectors(pos, projectPoint).normalize().multiplyScalar(10));

            if(dim.userData.position?.bottom){
                if(dim.userData.position?.overhang){
                    _pos = new Vector3().addVectors(projectPoint, new Vector3().subVectors(pos, projectPoint).normalize().multiplyScalar(180));
                }
                else{
                    _pos = new Vector3().addVectors(projectPoint, new Vector3().subVectors(pos, projectPoint).normalize().multiplyScalar(120));
                }
            }
            else{
                if(dim.userData.position?.overhang){
                    _pos = new Vector3().addVectors(projectPoint, new Vector3().subVectors(pos, projectPoint).normalize().multiplyScalar(100));
                }
            }

            let p = new Vector3().applyMatrix4(dim.matrixWorld);

            lsText.push({
                value: dim.userData.value,
                position: p,
                rotation: textRotation,
                views: dim.userData.views
            });

            
        }
        else if (dim.userData.type == GEOMETRY_TYPE.DIMENSION_LINE) {
            // this.scene.add(new LineSegments(new Geometry().setFromPoints(points), new LineBasicMaterial({color: new Color('red')})));

            lsGeometries.push({
                vertices: points,
                objectType: dim.userData.type,
                views: dim.userData.views
            });
        }
        else if (dim.userData.type == GEOMETRY_TYPE.DIMENSION_CAP) {
            lsGeometries.push({
                vertices: points,
                objectType: dim.userData.type,
                views: dim.userData.views
            });
        }
        else if (dim.userData.type == "Arrow") {
            let geo = ((dim as Line).geometry as Geometry).clone();
            geo.applyMatrix4(dim.matrixWorld);
            lsGeometries.push({
                vertices: geo.vertices,
                objectType: dim.userData.type,
                views: dim.userData.views
            });
        }
    }
    private getTextCover(textGeo: TextBufferGeometry, labelType: string, pos: Vector3){
        let textSize = new Vector3();
        textGeo.boundingBox.getSize(textSize)

        let textCover = new Mesh(new BoxBufferGeometry(textSize.x, textSize.y, textSize.z))
        textCover.position.set(pos.x , pos.y, pos.z + 10);
        textCover.userData = { type: GEOMETRY_TYPE.TEXT_COVER, labelType };
        textCover.material['opacity'] = 0;
        textCover.material['transparent'] = true;

        return textCover
    }
    private onUIChanged(preVal: number, curVal: number): void{
        this.draw();
    }
}
