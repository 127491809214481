import { Sprite } from "three"
import BaseUIControlEntity from "./base.model"

export class UIControl extends BaseUIControlEntity {
    draw(){
        this.mesh = new Sprite(this.material)
        this.mesh.userData = this.options.userData
        let scaleUniform = 1
        if(this.material.sizeAttenuation) {
            scaleUniform = 5000
        }

        this.mesh.scale.set(this.size[0] * scaleUniform, this.size[1] * scaleUniform, this.size[2] * scaleUniform)
        this.mesh.position.set(this.position[0], this.position[1], this.position[2])
        this.mesh.renderOrder = 0
        this.mesh.visible = this.visible
        this.container.add(this.mesh)
    }
}