import { Injectable } from "@angular/core";
import { MOUSE_EVENT, OBJECT_TYPE } from "src/app/app.constants";
import { UIControl } from "src/app/models/uicontrol.model";
import { Object3D, Sprite, Vector2, Vector3 } from "three";
import { BaseManager } from "src/app/core/base.manager";
import SelectionManager from "../selection.manager";
import { UI } from "src/app/core/ui";
import {DragControls} from 'three/examples/jsm/controls/DragControls'
import { HomeComponent } from "src/app/containers/home";
import { MaterialManager } from "../material.manager";
import ArrowControl from "src/app/models/ArrowControl.model";

@Injectable({ providedIn: 'root' })
export class EditLength2Manager extends BaseManager {
    controls = [
        UI.Controls.LENGTH1,
        UI.Controls.LENGTH2,
        UI.Controls.WIDTH1,
        UI.Controls.WIDTH2,
        UI.Controls.EXISTING_TYPE
    ]
    
    userData = undefined;
    offset = 400;
    startX = 0;

    constructor(private selMgr: SelectionManager){
        super()
    }

    init(): Promise<any> {
        return super.init().then(() => {
            this.userData = { type: OBJECT_TYPE.UI_CONTROL_LENGTH2 }
            this.selMgr.addEventListener( 'dragstart', ( event ) => {
                this.startX = event.object.position.x;
            });
            this.selMgr.addEventListener ( 'drag', this.onDrag.bind(this), this.container)
        })
    }

    

    load(): Promise<void>{
        return new Promise(resolve => {
            this.addControl();
            HomeComponent.ins.renderStatic();

            resolve()
        })
    }
    
    addControl(){
        this.container.children = [];
        if(UI.existingLength2){
            return
        }
        if( (UI.existingType == 1 || UI.existingType == 3) && UI.existingWidth1 > 620){
            new ArrowControl(
                this.container, 
                { 
                    pos: new Vector3(- UI.existingLength1 / 2 - this.offset, 0, UI.existingWidth1 / 2),
                    userData: {...this.userData, left: true},
                    rotation: new Vector3(Math.PI / 2,  0, 0)
                }
            );
        }
        if( (UI.existingType == 2 || UI.existingType == 3) && UI.existingWidth1 > 620){
            new ArrowControl(
                this.container, 
                { 
                    pos: new Vector3( UI.existingLength1 / 2  + this.offset, 0, UI.existingWidth1 / 2),
                    userData: {...this.userData, right: true},
                    rotation: new Vector3(Math.PI / 2,  0, Math.PI)
                }
            );
        }
    }

    onDrag(event){
        if(event.object?.userData?.type === OBJECT_TYPE.UI_CONTROL_LENGTH2){
            event.object.position.y = 0;
            if(event.object?.userData?.left){
                event.object.position.z = UI.existingWidth1 / 2;
                if(event.object.position.x > - UI.existingLength1 / 2 - this.offset){
                    event.object.position.x = - UI.existingLength1 / 2 - this.offset;
                    return
                }
            }
            if(event.object?.userData?.right){
                event.object.position.z = UI.existingWidth1 / 2;
                if(event.object.position.x < UI.existingLength1 / 2 + this.offset){
                    event.object.position.x = UI.existingLength1 / 2 + this.offset;
                    return
                }
            }
            let distance = Math.floor(event.object.position.x - UI.existingLength1 / 2  - this.offset);
            if(event.object?.userData?.left){
                distance = Math.floor(- UI.existingLength1 / 2  - this.offset - event.object.position.x);
            }
            
            if (distance > HomeComponent.ins.sldExistingLength2.maxValue){
                distance = HomeComponent.ins.sldExistingLength2.maxValue
            } else if (distance < HomeComponent.ins.sldExistingLength2.minValue){
                distance = HomeComponent.ins.sldExistingLength2.minValue
            }
            HomeComponent.ins.sldExistingLength2.setValue(distance);
        }
    }
}
