import { MeshLambertMaterial, TextureLoader, MeshBasicMaterial, RepeatWrapping, DoubleSide, MeshPhongMaterial, LineBasicMaterial, Color, Texture, SpriteMaterial } from "three";
import { environment as env } from 'src/environments/environment';
import { HomeComponent } from '../containers/home/home.component';
import { UI } from "./ui";

export class MaterialManager {
    private textureLoader: TextureLoader;
    private APP: HomeComponent;

    private defaultColor = new Color(0x6D6C6E); //Basalt

    public DEFAULT: MeshPhongMaterial;
    public WALL: MeshLambertMaterial;
    public EAVE: MeshPhongMaterial;
    public FASCIA: MeshPhongMaterial;
    public EXISTING_ROOF: MeshPhongMaterial;
    public GROUND: MeshLambertMaterial;
    public BASE: MeshPhongMaterial;

    public BEAM: MeshPhongMaterial;
    public BEAM_TRANSPARENT: MeshPhongMaterial;
    public POST: MeshPhongMaterial;
    public GUTTER: MeshPhongMaterial;
    public BARGE: MeshPhongMaterial;
    public RECEIVER_CHANEL: MeshPhongMaterial;
    public DOWNPIPE: MeshPhongMaterial;
    public BRACKET: MeshPhongMaterial;
    public BRACKET_WARNING: MeshPhongMaterial;
    public FASCIA_BRACKET: MeshPhongMaterial;
    public ROOF: MeshPhongMaterial;
    public RIDGE_CAPPING: MeshPhongMaterial;
    public ROOF_BASE: MeshPhongMaterial;
    public ZFLASHING: MeshPhongMaterial;

    public EXISTING_WALL_L1_TEXTURE: Texture;
    public EXISTING_WALL_W1_TEXTURE: Texture;
    public EXISTING_WALL_L2_TEXTURE: Texture;
    public EXISTING_WALL_W2_TEXTURE: Texture;

    public EXISTING_ROOF_TEXTURE: {
        L1: Texture,
        L2: Texture,
        W1: Texture,
        W2: Texture
    } = {
        L1: null,
        L2: null,
        W1: null,
        W2: null
    }

    public DIMENSION_TEXT: MeshBasicMaterial;
    public HIGHLIGHT_DIMENSION_TEXT: MeshBasicMaterial;

    public MESH_OUTLINE: LineBasicMaterial;
    public BEAM_OUTLINE: LineBasicMaterial;

    public TEXTURE_UI_CONTROL: Texture;
    public TEXTURE_TIMBER: Texture;
    public UI_CONTROL: SpriteMaterial

    public TEXTURE_UI_CONTROL_DELETE: Texture;
    public UI_CONTROL_DELETE: SpriteMaterial

    public HIDDEN_UI_CONTROL: MeshBasicMaterial

    public TEXTURE_UI_CONTROL_CUTBEAM: Texture;
    public TEXTURE_UI_CONTROL_EXIT_CUTBEAM: Texture;
    public UI_CONTROL_CUTBEAM: SpriteMaterial
    public UI_CONTROL_EXIT_CUTBEAM: SpriteMaterial

    private static instance: MaterialManager;
    static Instance(): MaterialManager{
        if (!MaterialManager.instance) {
            MaterialManager.instance = new MaterialManager();
        }
        return MaterialManager.instance;
    }

    private constructor(){
        this.textureLoader = new TextureLoader();

        //default
        this.DEFAULT = new MeshPhongMaterial({
            color: 0x6D6C6E,
            side: DoubleSide
        });

        //Eave
        this.EAVE = new MeshPhongMaterial({
            color: new Color('white'),
            side: DoubleSide
        });

        //Eave
        this.FASCIA = new MeshPhongMaterial({
            color: new Color('white'),
            side: DoubleSide
        });

        //Ground
        var groundTexture = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/grass-dark.jpg" );
        groundTexture.wrapS = groundTexture.wrapT = RepeatWrapping;
        groundTexture.repeat.set( 25, 25 );
        groundTexture.anisotropy = 16;
        this.GROUND = new MeshLambertMaterial({
            side: DoubleSide, 
            map: groundTexture
        });

        //Base
        this.BASE = new MeshPhongMaterial({ color: new Color('white') });

        //Existing roof
        // var existingRoofTexture = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/131_Concrete roof tile texture-seamless.jpg" );
        // existingRoofTexture.wrapS = existingRoofTexture.wrapT = RepeatWrapping;
        // existingRoofTexture.repeat.set( 2, 2 );
        // existingRoofTexture.anisotropy = 16;
        this.EXISTING_ROOF = new MeshPhongMaterial({
            side: DoubleSide
        });

        //wall texture
        this.EXISTING_WALL_L1_TEXTURE = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/brick4.png" );
        this.EXISTING_WALL_L1_TEXTURE.wrapS = this.EXISTING_WALL_L1_TEXTURE.wrapT = RepeatWrapping;
        this.EXISTING_WALL_L1_TEXTURE.anisotropy = 1;

        this.EXISTING_WALL_L2_TEXTURE = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/brick4.png" );
        this.EXISTING_WALL_L2_TEXTURE.wrapS = this.EXISTING_WALL_L2_TEXTURE.wrapT = RepeatWrapping;
        this.EXISTING_WALL_L2_TEXTURE.anisotropy = 1;

        this.EXISTING_WALL_W1_TEXTURE = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/brick4.png" );
        this.EXISTING_WALL_W1_TEXTURE.wrapS = this.EXISTING_WALL_W1_TEXTURE.wrapT = RepeatWrapping;
        this.EXISTING_WALL_W1_TEXTURE.anisotropy = 1;

        this.EXISTING_WALL_W2_TEXTURE = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/brick4.png" );
        this.EXISTING_WALL_W2_TEXTURE.wrapS = this.EXISTING_WALL_W2_TEXTURE.wrapT = RepeatWrapping;
        this.EXISTING_WALL_W2_TEXTURE.anisotropy = 1;

        

        //Existing roof texture
        this.EXISTING_ROOF_TEXTURE.L1 = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/131_Concrete roof tile texture-seamless.jpg" );
        this.EXISTING_ROOF_TEXTURE.L1.wrapS = this.EXISTING_ROOF_TEXTURE.L1.wrapT = RepeatWrapping;
        this.EXISTING_ROOF_TEXTURE.L1.anisotropy = 1;

        this.EXISTING_ROOF_TEXTURE.L2 = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/131_Concrete roof tile texture-seamless.jpg" );
        this.EXISTING_ROOF_TEXTURE.L2.wrapS = this.EXISTING_ROOF_TEXTURE.L2.wrapT = RepeatWrapping;
        this.EXISTING_ROOF_TEXTURE.L2.anisotropy = 1;

        this.EXISTING_ROOF_TEXTURE.W1 = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/131_Concrete roof tile texture-seamless.jpg" );
        this.EXISTING_ROOF_TEXTURE.W1.wrapS = this.EXISTING_ROOF_TEXTURE.W1.wrapT = RepeatWrapping;
        this.EXISTING_ROOF_TEXTURE.W1.anisotropy = 1;

        this.EXISTING_ROOF_TEXTURE.W2 = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/131_Concrete roof tile texture-seamless.jpg" );
        this.EXISTING_ROOF_TEXTURE.W2.wrapS = this.EXISTING_ROOF_TEXTURE.W2.wrapT = RepeatWrapping;
        this.EXISTING_ROOF_TEXTURE.W2.anisotropy = 1;

        //Wall
        this.WALL = new MeshLambertMaterial({
            side: DoubleSide, 
            //map: this.BRICK_TEXTURE
        });

        //text
        this.DIMENSION_TEXT = new MeshBasicMaterial({ color: 0xffffff, side: DoubleSide });
        this.HIDDEN_UI_CONTROL = new MeshLambertMaterial({
            side: DoubleSide,
            color: 'white',
            transparent: true,
            opacity: 0
        })
        this.HIGHLIGHT_DIMENSION_TEXT = new MeshBasicMaterial({ color: 0xffff00, side: DoubleSide });

        this.MESH_OUTLINE = new LineBasicMaterial( { color: 'red' } );
        this.BEAM_OUTLINE = new LineBasicMaterial( { color: 'yellow' } );

        this.BARGE = new MeshPhongMaterial({ color: new Color(0xE4E2D5) }); //surfmist
        this.RECEIVER_CHANEL = new MeshPhongMaterial({ color: new Color(0xE4E2D5) }); //surfmist
        this.GUTTER = new MeshPhongMaterial({ color: new Color(0xE4E2D5) }); //basalt
        this.DOWNPIPE = new MeshPhongMaterial({ color: new Color(0xffffff) }); //white
        this.BEAM = new MeshPhongMaterial({ color: new Color(0xE4E2D5), side: DoubleSide }); //surfmist
        this.BEAM_TRANSPARENT = new MeshPhongMaterial({ color: new Color(0xE4E2D5), side: DoubleSide, transparent: true, opacity: 0.5 }); //surfmist
        this.POST = new MeshPhongMaterial({ color: new Color(0xE4E2D5) }); //surfmist
        this.BRACKET = new MeshPhongMaterial({ color: new Color(0xE4E2D5) }); //surfmist
        this.BRACKET_WARNING = new MeshPhongMaterial({ color: new Color(0xFF0000) }); //red: always red
        this.FASCIA_BRACKET = new MeshPhongMaterial({ color: new Color(0xE4E2D5) }); //surfmist
        this.ROOF = new MeshPhongMaterial({ color: new Color(0xE4E2D5) }); //surfmist
        this.RIDGE_CAPPING = new MeshPhongMaterial({ color: new Color(0xE4E2D5) });
        this.ROOF_BASE = new MeshPhongMaterial({ color: new Color(0xE4E2D5) }); //surfmist
        this.ZFLASHING = new MeshPhongMaterial({ color: new Color(0xE4E2D5) }); //surfmist
    }

    public init(app: HomeComponent){
        this.APP = app;
        this.registerEvent();

        return Promise.all([
            this.loadTexture('cut-beam.png').then(texture => {
                this.TEXTURE_UI_CONTROL_CUTBEAM = texture
                this.UI_CONTROL_CUTBEAM = new SpriteMaterial({ map: this.TEXTURE_UI_CONTROL_CUTBEAM, sizeAttenuation: true })
            }),
            this.loadTexture('join-beam.png').then(texture => {
                this.TEXTURE_UI_CONTROL_EXIT_CUTBEAM = texture
                this.UI_CONTROL_EXIT_CUTBEAM = new SpriteMaterial({ map: this.TEXTURE_UI_CONTROL_EXIT_CUTBEAM, sizeAttenuation: true })
            }),
            this.loadTexture('resize-icon.png').then(texture => {
                this.TEXTURE_UI_CONTROL = texture
                this.UI_CONTROL = new SpriteMaterial({ map: this.TEXTURE_UI_CONTROL, sizeAttenuation: false })
            }),

            this.loadTexture('delete-icon.png').then(texture => {
                this.TEXTURE_UI_CONTROL_DELETE = texture
                this.UI_CONTROL_DELETE = new SpriteMaterial({ map: this.TEXTURE_UI_CONTROL_DELETE, sizeAttenuation: false })
            }),

            this.loadTexture('timber-texture.jpg').then(texture => {
                this.TEXTURE_TIMBER = texture
                this.TEXTURE_TIMBER.repeat.set(1, 0.3)
                this.TEXTURE_TIMBER.wrapS = RepeatWrapping
                this.TEXTURE_TIMBER.wrapT = RepeatWrapping
            }),
            this.existingRoofTypeChanged(null,null)
        ])
    }
    private loadTexture(name: string) {
        return new Promise<Texture>((resolve, reject) => {
            this.textureLoader.load(env.modelBaseUrl + "/assets/images/textures/" + name, (texture) => {
                resolve(texture)
            },
                () => { },
                (err) => { reject(err) });
        })
    }
    private controlsToRegisterEvent: Array<any>;
    private registerEvent(){
        this.APP.sltColourBeam.addAction(this.beamColorChanged.bind(this), 1);
        this.APP.sltColourPost.addAction(this.postColorChanged.bind(this), 1);
        this.APP.sltColourBarge.addAction(this.bargeColorChanged.bind(this),1);
        this.APP.sltColourGutter.addAction(this.gutterColorChanged.bind(this), 1);
        this.APP.sltColourDownpipe.addAction(this.downpipeColorChanged.bind(this), 1);
        this.APP.sltColourBracket.addAction(this.bracketColorChanged.bind(this), 1);
        this.APP.sltColourFasciaBracket.addAction(this.fasciaBracketColorChanged.bind(this), 1);
        this.APP.sltColourRoof.addAction(this.roofColorChanged.bind(this), 1);
        this.APP.sltFlatBottom.addAction(this.roofBaseColorChanged.bind(this), 1);
        this.APP.sltColourZFlashing.addAction(this.zFlashingColorChanged.bind(this), 1);
        this.APP.sltExistingRoofType.addAction(this.existingRoofTypeChanged.bind(this), 1);
    }
    private postColorChanged(pre: number, cur: string){
        if(UI.postType == 3) {
            this.POST.color = new Color(UI.postColor);
            if(this.TEXTURE_TIMBER) {
                this.POST.color = new Color(0xFFFFFF);
                this.POST.map = this.TEXTURE_TIMBER
                this.POST.map.needsUpdate = true
                this.POST.needsUpdate = true
            }
        } else {
            this.POST.color = new Color(UI.postColor);
            this.POST.map = null
            this.POST.needsUpdate = true
        }
    }
    private bargeColorChanged(pre: number, cur: string){
        this.BARGE.color = new Color(parseInt(cur));
    }
    private gutterColorChanged(pre: number, cur: string){
        this.GUTTER.color = new Color(parseInt(cur));
    }
    private downpipeColorChanged(pre: number, cur: string){
        this.DOWNPIPE.color = new Color(parseInt(cur));
    }
    private beamColorChanged(pre: number, cur: string){
        if(UI.beamType == 1) {
            this.BEAM.color = new Color(UI.beamColor);
            this.BEAM_TRANSPARENT.color = new Color(UI.beamColor);
            if(this.TEXTURE_TIMBER) {
                this.BEAM.color = new Color(0xFFFFFF);
                this.BEAM.map = this.TEXTURE_TIMBER
                this.BEAM.map.needsUpdate = true
                this.BEAM.needsUpdate = true

                this.BEAM_TRANSPARENT.color = new Color(0xFFFFFF);
                this.BEAM_TRANSPARENT.map = this.TEXTURE_TIMBER
                this.BEAM_TRANSPARENT.map.needsUpdate = true
                this.BEAM_TRANSPARENT.needsUpdate = true
            }
        } else {
            this.BEAM.color = new Color(UI.beamColor);
            this.BEAM.map = null
            this.BEAM.needsUpdate = true

            this.BEAM_TRANSPARENT.color = new Color(UI.beamColor);
            this.BEAM_TRANSPARENT.map = null
            this.BEAM_TRANSPARENT.needsUpdate = true
        }
    }
    private bracketColorChanged(pre: number, cur: string){
        this.BRACKET.color = new Color(parseInt(cur));
    }
    private fasciaBracketColorChanged(pre: number, cur: string){
        this.FASCIA_BRACKET.color = new Color(parseInt(cur));
    }
    private roofColorChanged(pre: number, cur: string){
        this.ROOF.color = new Color(parseInt(cur));
        this.RIDGE_CAPPING.color = new Color(parseInt(cur));
    }
    private roofBaseColorChanged(pre: number, cur: string){
        this.ROOF_BASE.color = new Color(parseInt(cur));
        this.RECEIVER_CHANEL.color = new Color(parseInt(cur));
    }
    private zFlashingColorChanged(pre: number, cur: string) {
        this.ZFLASHING.color = new Color(parseInt(cur));
    }
    private existingRoofTypeChanged(pre: number, cur: number) {
        if(UI.existingRoofType == 0){
            this.EXISTING_ROOF_TEXTURE.L1 = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/custom_orb_accent_21_windspray.bmp" );
            this.EXISTING_ROOF_TEXTURE.L1.wrapS = this.EXISTING_ROOF_TEXTURE.L1.wrapT = RepeatWrapping;
            this.EXISTING_ROOF_TEXTURE.L1.anisotropy = 1;

            this.EXISTING_ROOF_TEXTURE.L2 = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/custom_orb_accent_21_windspray.bmp" );
            this.EXISTING_ROOF_TEXTURE.L2.wrapS = this.EXISTING_ROOF_TEXTURE.L2.wrapT = RepeatWrapping;
            this.EXISTING_ROOF_TEXTURE.L2.anisotropy = 1;

            this.EXISTING_ROOF_TEXTURE.W1 = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/custom_orb_accent_21_windspray.bmp" );
            this.EXISTING_ROOF_TEXTURE.W1.wrapS = this.EXISTING_ROOF_TEXTURE.W1.wrapT = RepeatWrapping;
            this.EXISTING_ROOF_TEXTURE.W1.anisotropy = 1;

            this.EXISTING_ROOF_TEXTURE.W2 = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/custom_orb_accent_21_windspray.bmp" );
            this.EXISTING_ROOF_TEXTURE.W2.wrapS = this.EXISTING_ROOF_TEXTURE.W2.wrapT = RepeatWrapping;
            this.EXISTING_ROOF_TEXTURE.W2.anisotropy = 1;
        } else if (UI.existingRoofType == 1){
            this.EXISTING_ROOF_TEXTURE.L1 = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/131_Concrete roof tile texture-seamless.jpg" );
            this.EXISTING_ROOF_TEXTURE.L1.wrapS = this.EXISTING_ROOF_TEXTURE.L1.wrapT = RepeatWrapping;
            this.EXISTING_ROOF_TEXTURE.L1.anisotropy = 1;

            this.EXISTING_ROOF_TEXTURE.L2 = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/131_Concrete roof tile texture-seamless.jpg" );
            this.EXISTING_ROOF_TEXTURE.L2.wrapS = this.EXISTING_ROOF_TEXTURE.L2.wrapT = RepeatWrapping;
            this.EXISTING_ROOF_TEXTURE.L2.anisotropy = 1;

            this.EXISTING_ROOF_TEXTURE.W1 = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/131_Concrete roof tile texture-seamless.jpg" );
            this.EXISTING_ROOF_TEXTURE.W1.wrapS = this.EXISTING_ROOF_TEXTURE.W1.wrapT = RepeatWrapping;
            this.EXISTING_ROOF_TEXTURE.W1.anisotropy = 1;

            this.EXISTING_ROOF_TEXTURE.W2 = this.textureLoader.load( env.modelBaseUrl + "/assets/images/textures/131_Concrete roof tile texture-seamless.jpg" );
            this.EXISTING_ROOF_TEXTURE.W2.wrapS = this.EXISTING_ROOF_TEXTURE.W2.wrapT = RepeatWrapping;
            this.EXISTING_ROOF_TEXTURE.W2.anisotropy = 1;
        } 
    }
}
