import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { SelectInputItemModel } from 'src/app/core/models';
import _ from "lodash";
declare var $: any;


@Component({
  selector: "dropdown-input",
  templateUrl: "./dropdown-input.component.html"
  // styleUrls: ['./dropdown-input.component.css']
})
export class DropDownInputComponent implements OnInit {
  _isDisable: boolean;
  @Input("disabled") public set isDisabled(val: boolean) {
    this._isDisable = val;
  }
  @Input("items") items: Array<SelectInputItemModel>;
  @Input("selected") public selectedValue: number;
  @Output("change") onChange = new EventEmitter();
  @ViewChild("selectInput", { static: true }) selectElement;

  previousValue: number;
  currentValue = 10;
  disableList = [];
  //actions: Array<(previousValue: number, currentValue: number) => void> = [];
  actions: Array<{
    priority: number;
    actions: Array<(previousValue: number, currentValue: number) => void>;
  }> = [];
  public get currentText(): string {
    let selectedItem = this.items.find(i => i.id == this.currentValue);
    if (selectedItem)
        return selectedItem.value;

    if (this.items.length > 0)
        return this.items[0].value;

  }

  public get currentBeamEndCapColorCode(): string {
    let selectedItem = this.items.find((i) => i.id == this.currentValue);
    if (selectedItem) return _.get(selectedItem, ['beamEndCapColorCode'], '');

    if (this.items.length > 0) return _.get(this.items, [0, 'beamEndCapColorCode'], '');

    return '';
  }
  ngOnInit(): void {
    this.selectedValue = this.selectedValue || 0;
    this.currentValue = this.selectedValue;
  }
  ngAfterContentInit() {
    this.setSelected(this.selectedValue);
  }

  public addAction(
    action: (previousValue: number, currentValue: number) => void,
    priority?: number
  ) {
    priority = priority || 3;
    let actionsByPriority = this.actions.find(a => a.priority === priority);
    if (actionsByPriority) {
      actionsByPriority.actions.push(action);
    } else {
      this.actions.push({ priority: priority, actions: [action] });
    }

    this.actions.sort((a, b) => a.priority - b.priority);
  }
  public removeAction(action: any): void {
    for (let item of this.actions) {
      const idx = item.actions.indexOf(action);

      if (idx !== -1) {
        item.actions.splice(idx, 1);
      }
    }
  }

  public onValueChanged(e) {
    const val = e.target.value;
    this.currentValue = val;
    // this.currentText = e.target.options[e.target.options.selectedIndex].text;
    for (let item of this.actions) {
      item.actions.forEach(action => {
        action(+this.previousValue, +this.currentValue);
      });
    }
    // this.onChange.emit();
    this.previousValue = val;
  }
  public setSelected(value: any) {
    this.currentValue = value;
    this.selectedValue = value;
    setTimeout(() => {
      // let selectedItem = this.items.find(i => i.id == value);
      $(this.selectElement.nativeElement).dropdown("set selected", value.toString());
    }, 200);
  }

  public setSelectedAndHandleEvent(value: any) {
    setTimeout(() => {
      // let selectedItem = this.items.find(i => i.id == value);
      $(this.selectElement.nativeElement).dropdown("set selected", value.toString());
    }, 200);

    this.onValueChanged({target: {value: value}})
  }

  public setDisable(value: boolean) {
    if (value) {
      $(this.selectElement.nativeElement).parent().addClass('disabled');
    }
    else {
      $(this.selectElement.nativeElement).parent().removeClass('disabled');
    }
  }
  disabledItem(value: any) {
    if (this.disableList.length === 0) {
      return false;
    }
    return this.disableList.indexOf(value) > -1;
  }
}
