import { Vector3 } from "three";

type Edge = [Vector3, Vector3]
type Polygon = Array<Vector3>;

export const PolygonUtils = {
    getVectorLength(vector): number {
        return Math.sqrt(vector.x * vector.x + vector.y * vector.y + vector.z * vector.z);
    },
    dotProduct(v0, v1): number {
        return v0.x * v1.x + v0.y * v1.y + v0.z * v1.z;
    },
      
    /**
     * Get projected point P' of P on a segment
     * @return projected point p.
     */
    getProjectedPointOnSegment(segment: Edge, point: any): any {
        // get dot product of e1, e2
        const e1 = this.toVector(...segment);
        const e2 = this.toVector(segment[0], point);
        const valDp = this.dotProduct(e1, e2);
        // get length of vectors
        const lenLineE1 = this.getVectorLength(e1);
        const lenLineE2 = this.getVectorLength(e2);
        const cos = valDp / (lenLineE1 * lenLineE2);
        // length of v1P'
        const projLenOfLine = cos * lenLineE2;
        return new Vector3(segment[0].x + (projLenOfLine * e1.x) / lenLineE1, segment[0].y + (projLenOfLine * e1.y) / lenLineE1, segment[0].z + (projLenOfLine * e1.z) / lenLineE1)
    },
    isProjectionOnSegment(point, segment: Edge): boolean {
        const e1 = this.toVector(...segment);
        const dp = this.dotProduct(this.toVector(point, segment[1]), e1);
        const max = e1.x * e1.x + e1.y * e1.y + e1.z * e1.z;
        return dp >= 0 && dp <= max;
    },
      
    /**
     * Convert a polygon a list of edges
     * Except last point to start point
     * @param polygon
     */
    toEdgeList(polygon: Polygon): Array<Edge> {
        const result: Array<Edge> = [];
        for (let i = 0; i < polygon.length - 1; i++) {
          const pointA = polygon[i];
          const pointB = polygon[i === polygon.length - 1 ? 0 : i + 1];
          result.push([pointA, pointB]);
        }
        return result;
    },
    toVector(A: Vector3, B: Vector3): Vector3 {
        return new Vector3( B.x - A.x, B.y - A.y, B.z - A.z)
    },
      
    /**
     * Return the cumulated rotation around a point of another point
     * which is translating on a polygon edge.
     * The returned value is floored to deal with javscript float number imprecision.
     * So if the absolute value winding number is lower than 1, it
     * means that the rotation is null.
     * @param polygon
     * @param point
     */
    getWindingNumber(polygon: Polygon, point: any): number {
        const angles = [];
        let result: number = 0;
        for (let index = 0; index < polygon.length; index++) {
          const vertex = polygon[index];
          const nextVertex =
            index === polygon.length - 1 ? polygon[0] : polygon[index + 1];
          // Get the angle between the previous vertex, the point and the current vertex
          const angle = this.getAngle(vertex, point, nextVertex);
          angles.push(angle);
          result += angle;
        }
        return Math.floor(result);
    },
      
    /**
     * Calculates the signed (counter/clockwize) angle ABC (in radian).
     * @param A
     * @param B
     * @param C
     */
    getAngle(A: any, B: any, C: any): number {
        const v0 = { x: A.x - B.x, y: A.y - B.y, z: A.z - B.z };
        const v1 = { x: C.x - B.x, y: C.y - B.y, z: C.z - B.z };
        const angle = new Vector3(v0.x, v0.y, v0.z).angleTo(new Vector3(v1.x, v1.y, v1.z));
        // Special case, the angle cross over the complete circle
        return this.round(
          Math.abs(angle) > Math.PI
            ? angle < 0
              ? angle + 2 * Math.PI
              : angle - 2 * Math.PI
            : angle
        );
    },
      
    /**
     * Return true if a point lies on a segment
     * @param segment
     * @param point
     */
    isPointOnSegment(segment: Edge, point: any): boolean {
        const distAP = segment[0].distanceTo(point);
        const distBP = segment[1].distanceTo(point);
        const distAB = segment[1].distanceTo(segment[0]);
        return distAB.toFixed(5) === (distAP + distBP).toFixed(5);
    },
    getDistance(A: any, B: any): number {
        return Math.sqrt((A.x - B.x) * (A.x - B.x) + (A.y - B.y) * (A.y - B.y));
    },
      
    /**
     * Workaround javascript imprecision
     */
    round(number: number) {
        return Math.round((number + Number.EPSILON) * 100) / 100;
    },

    getClosestPointInsidePolygon(poly: Polygon, pos: any): any {
        // Except case: Inside the polygon. Never happen.
        // // Case I
        // const wn = this.getWindingNumber(poly, pos);
        // if (Math.abs(wn) > 1) {
        //   return pos;
        // }

        // Case II
        if (this.toEdgeList(poly).some(edge => this.isPointOnSegment(edge, pos))) {
          return pos;
        }

        // Case III - A
        // Get edges which faces the points.
      
        const closestPointOnEdge = this.toEdgeList(poly)
          .filter(edge => this.isProjectionOnSegment(pos, edge))
          .reduce(
            ([_pp, minDistance], edge) => {
              // Get the projected point on this edge
              const pp = this.getProjectedPointOnSegment(edge, pos);
              // Get the distance from the edge
              const distance = this.getDistance(pos, pp);
              // Return this projection if the distance is smaller
              return distance < minDistance ? [pp, distance] : [_pp, minDistance];
            },
            [undefined, Infinity] as [any | undefined, number]
          )[0];
      
        // Case III - B
        const closestVertex = poly.reduce(
          ([closestVertex, minDistance], vertex) => {
            // Get distance from vertex to target
            const distance = this.getDistance(vertex, pos);
            // Return this vertex if the distance is smaller
            return distance < minDistance
              ? [vertex, distance]
              : [closestVertex, minDistance];
          },
          [undefined, Infinity] as [any | undefined, number]
        )[0];
        if (!closestPointOnEdge) {
          return closestVertex;
        }
        // At this point we can get both an edge and a vertex.
        // Get the closest between the edge and the vertex
        return this.getDistance(pos, closestPointOnEdge) < this.getDistance(pos, closestVertex)
          ? closestPointOnEdge
          : closestVertex;
    }
}