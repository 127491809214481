import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "checkbox-input",
    template: '<label nz-checkbox [(ngModel)]="currentValue" (ngModelChange)="onStateChanged()" [disabled]="_isDisable"></label>'
})
export class CheckboxInputComponent {
    currentValue = false;
    private previousValue: boolean;
    _isDisable: boolean;
    @Input("disabled") public set isDisabled(val: boolean) {
        this._isDisable = val;
    }
    @Output('onChange') onClick = new EventEmitter();

    private actions: Array<{
        priority: number;
        actions: Array<(previousValue: boolean, currentValue: boolean) => void>;
    }> = [];

    ngOnInit(): void {

    }

    public onStateChanged() {
        for (let item of this.actions) {
            item.actions.forEach(action => {
                action(this.previousValue, this.currentValue);
            });
        }
        this.onClick.emit(this);
        this.previousValue = this.currentValue;
    }

    public addAction(
        action: (previousValue: boolean, currentValue: boolean) => void,
        priority?: number
    ) {
        priority = priority || 3;
        let actionsByPriority = this.actions.find(a => a.priority === priority);
        if (actionsByPriority) {
            actionsByPriority.actions.push(action);
        } else {
            this.actions.push({ priority: priority, actions: [action] });
        }

        this.actions.sort((a, b) => a.priority - b.priority);
    }
    public removeAction(action: any): void {
        for (let item of this.actions) {
            const idx = item.actions.indexOf(action);

            if (idx !== -1) {
                item.actions.splice(idx, 1);
            }
        }
    }
    public setValue(value: boolean){
        this.currentValue = value;
        this.onStateChanged();
    }
    public setDisable(value: boolean) {
        if (value) {
          this._isDisable = true;
        }
        else {
          this.isDisabled = false;
        }
      }
}