import { Scene, Mesh, Vector3, Material, Plane, Group, PlaneHelper, BufferGeometry, Geometry, LineSegments, Color } from "three";
import { Util } from "../utils";
import { CONFIG as env, GEOMETRY_TYPE, GEOMETRY_CATEGORY } from '../../app.config';
import { HomeComponent as AppComponent } from '../../containers/home/home.component';
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import { GeometryInfo, Printing2DGeometry, Printing2DLine, Printing2DGeometryType, Print2DView, ViewType, LineType } from '../models';
import { PatiosGableFreeStandingManager } from '.';
import { PANEL_DIRECTION } from 'src/app/app.constants';
import { UI } from "../ui";

export class RoofManager {
    private scene: Group;
    private APP: AppComponent;
    private MANAGER: PatiosGableFreeStandingManager;
    private material: Material;
    private utils: Util;

    private geometryManager: GeometryManager;

    private geo_RoofPanel: GeometryInfo;
    private geo_roofPanelBase: GeometryInfo;
    private geo_roofPanelBargeCappingLeft: GeometryInfo;
    private geo_roofPanelBargeCappingRight: GeometryInfo;
    private geo_roofPanelBargeCappingBack: GeometryInfo;
    private geo_gutterBack: GeometryInfo;
    private zFlashingGeo: GeometryInfo;
    private geo_gutterCap: GeometryInfo;
    public ridgeGeo: GeometryInfo;

    private roofGroupFront: Group;
    private roofGroupBack: Group;

    private eventHandleId: any;

    private totalBaySize: number;

    private objectSizeChangedHandle: any;
    private controlsToRegisterEvent: Array<any>;
    private controlsToRegisterEvent2: Array<any>;
    private FIT_SHEETING: number = 0;
    private FIT_SHEETING_BASE: number = -60;


    constructor(app: AppComponent, freeStandingManager: PatiosGableFreeStandingManager) {
        this.utils = new Util();
        this.geometryManager = GeometryManager.Instance();
        this.APP = app;
        this.MANAGER = freeStandingManager;
        this.scene = new Group();
        freeStandingManager.patiosGroup.add(this.scene)
        this.material = MaterialManager.Instance().ROOF;

        this.registerEvent();
    }
    
    
    public optimize() : Promise<void>{
        return new Promise((resolve, reject) => {
            this.geo_RoofPanel = this.geometryManager.getRoofSheetPanel();
            this.geo_RoofPanel.geometry
            .translate(this.geo_RoofPanel.width/2+2,this.geo_RoofPanel.height/2 -1,this.geo_RoofPanel.length/2);
            
            this.geo_roofPanelBase = this.geometryManager.getRoofBase();
            this.geo_roofPanelBase.geometry
            .translate(this.geo_roofPanelBase.width/2,
                this.geo_roofPanelBase.height/2,
                this.geo_roofPanelBase.length / 2);

            this.zFlashingGeo = this.geometryManager.getZFlashing();
            this.zFlashingGeo.geometry.translate(0, -3, 0);
            
            //Barge capping
            this.geo_roofPanelBargeCappingLeft = this.geometryManager.getBarge();
            this.geo_roofPanelBargeCappingLeft.geometry
            .rotateY(Math.PI)
            .translate(this.geo_roofPanelBargeCappingLeft.width/2 - 3,this.geo_roofPanelBargeCappingLeft.height/2 - (this.APP.sltDripBarge.currentValue ? 9 : 1),this.geo_roofPanelBargeCappingLeft.length/2);

            this.geo_roofPanelBargeCappingRight = this.geometryManager.getBarge();
            this.geo_roofPanelBargeCappingRight.geometry
            .translate(-this.geo_roofPanelBargeCappingRight.width/2 + 3,this.geo_roofPanelBargeCappingRight.height/2 - (this.APP.sltDripBarge.currentValue ? 9 : 1),this.geo_roofPanelBargeCappingRight.length/2);

            this.geo_roofPanelBargeCappingBack = this.geometryManager.getBarge();
            this.geo_roofPanelBargeCappingBack.geometry
            .rotateY(Math.PI/2)
            .translate(this.geo_roofPanelBargeCappingBack.length/2,this.geo_roofPanelBargeCappingBack.height/2 - (this.APP.sltDripBarge.currentValue ? 9 : 1),this.geo_roofPanelBargeCappingBack.width/2 - 3);

            //Gutter
            this.geo_gutterBack = this.geometryManager.getGutter();
            this.geo_gutterBack.geometry
            .rotateY(-Math.PI/2)
                .translate(this.geo_gutterBack.length / 2, -20, this.geo_gutterBack.width / 2);

            //Gutter cap
            this.geo_gutterCap = this.geometryManager.getGuterEndCap();
            this.geo_gutterCap.geometry
                .rotateY(-Math.PI / 2)
                .translate(0, -20, this.geo_gutterCap.length / 2);

            this.ridgeGeo = this.geometryManager.getRidge();
            this.ridgeGeo.geometry.translate(- this.ridgeGeo.width / 2, -this.ridgeGeo.height / 2, 0);
            // this.ridgeGeo.geometry.rotateY(Math.PI / 2)

            resolve();
        });
    }
    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.ROOF_PATIOS));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.RIDGE_CAPPING));
            this.APP.scene.remove(...this.APP.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.SHEET_OUTLINE));

            //test
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == 'HELPER'));
            
            this.totalBaySize = 0;
            for(let b of this.APP.dialogEditBay.listBay){
                this.totalBaySize += b.value;
            }
            
            this.addRoofFront();
            this.addRoofBack();

            let offsetLeft = this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue - this.MANAGER.patiosGroup.position.x;
            let offsetRight = this.totalBaySize/2 + this.APP.sldRightOverhang.currentValue + this.MANAGER.patiosGroup.position.x;
            let clipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
            let clipingPlaneRight = new Plane( new Vector3( -1, 0, 0 ), offsetRight );
            
            this.material.clippingPlanes = [clipingPlaneLeft, clipingPlaneRight];
            MaterialManager.Instance().ROOF_BASE.clippingPlanes = [clipingPlaneLeft, clipingPlaneRight];

            // this.material.clippingPlanes = [];
            // MaterialManager.Instance().ROOF_BASE.clippingPlanes = [];

            let width = this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue;
            let roofSheetingCount = Math.ceil(width/this.geo_RoofPanel.width);
            let offsetX = -(this.totalBaySize/2 + this.APP.sldLeftOverhang.currentValue);

            //panel direction
            let panelDirectionOfset = 1;
            if(this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.RIGHT_TO_LEFT){
                offsetX = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue - this.geo_RoofPanel.width;
                panelDirectionOfset = -1;
            }
            else if(this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.LEFT_TO_RIGHT){
                offsetX = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
            }

            // let helper = new PlaneHelper(clipingPlaneRight, 10000, 0xffff00);
            // helper.userData = { type: 'HELPER' };
            // this.APP.scene.add(helper);

            const OFFSET_TOLE_AND_BASE = 10

            for (let index = 0; index < roofSheetingCount; index++) {
                let last = index == roofSheetingCount - 1;
                
                this.addRoofSheetingFront(offsetX + OFFSET_TOLE_AND_BASE);
                this.addRoofSheetingBack(offsetX + OFFSET_TOLE_AND_BASE);

                this.addRoofSheetingBaseFront(offsetX, last);
                this.addRoofSheetingBaseBack(offsetX, last);

                offsetX += this.geo_RoofPanel.width * panelDirectionOfset;
            }

            this.addZFlashingFront();
            this.addZFlashingBack();

            this.addBargeCappingFront();
            this.addBargeCappingBack();
            
            this.addGutterFront();
            this.addGutterBack();

            this.addRidgeCapping();

            this.showPanelOutline();

            this.scene.visible = UI.showRoof

            resolve();
        });
    }
    public getOutLines(): Printing2DGeometry{
        
        let objs = this.roofGroupFront.children.filter(o => 
            o.userData.type == GEOMETRY_TYPE.ROOF_PANEL 
            || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE          
        ).concat(...this.roofGroupBack.children.filter(o => 
            o.userData.type == GEOMETRY_TYPE.ROOF_PANEL 
            || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE          
        ));

        let gutterObjs = this.roofGroupFront.children.find(o => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS).children.concat(...this.roofGroupBack.children.find(o => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS).children);
        objs = [...objs, ...gutterObjs];

        let lsGeometries: Printing2DLine[] = [];

        let offsetLeft = this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue - this.MANAGER.patiosGroup.position.x;
        let offsetRight = this.totalBaySize/2 + this.APP.sldRightOverhang.currentValue + this.MANAGER.patiosGroup.position.x;
        let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
        let roofClipingPlane = new Plane( new Vector3( -1, 0, 0 ), offsetRight );

        for(let o of objs){            
            let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale((o as Mesh), 60);
            o.updateWorldMatrix(true, true);
            outlineGeo.applyMatrix4(o.matrixWorld);
            outlineGeo.translate(0,5000,0);            

            if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL) {
                outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
                outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlane);
            }
            if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE) {
                outlineGeo = this.utils.clipOutlineBasePanel(outlineGeo, roofClipingPlaneLeft);
                outlineGeo = this.utils.clipOutlineBasePanel(outlineGeo, roofClipingPlane);
            }
            
            // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
            // line.userData = {type: "SHEETING_OUTLINE"};
            // this.APP.scene.add( line );

            let simplifiedGeo = this.simplifyGeo(outlineGeo);
            lsGeometries.push({ 
                objectType: o.userData.type,
                vertices: simplifiedGeo.vertices, 
                views: o.userData.views
            });

            // var line = new LineSegments( simplifiedGeo, MaterialManager.Instance().MESH_OUTLINE );
            // line.userData = {type: "SHEETING_OUTLINE"};
            // this.APP.scene.add( line );
        }

        let ridgeCapping = this.scene.children.find(el => el.userData.type === GEOMETRY_TYPE.RIDGE_CAPPING)
        if(ridgeCapping){
            ridgeCapping.children.forEach(el => {
                this.utils.createOutline(el as Mesh, lsGeometries);
            })
        }

        lsGeometries.push({
            objectType: GEOMETRY_TYPE.ROOF_COVER,
            vertices: this.makeRoofPolygon(),
            color: this.utils.convertHexColorToRgb(`#${new Color(UI.panelColor).getHexString()}`),
            views: [
                { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
            ]
        });

        return { lines: lsGeometries, texts: [] };
    }
    makeRoofPolygon() {
        const polygon = this.utils.getRoofCoverPolygonForLRRoof()
        this.MANAGER.patiosGroup.updateWorldMatrix(true, true)
        return polygon.map(el => (el as Vector3).applyMatrix4(this.MANAGER.patiosGroup.matrixWorld).add(new Vector3(0, 0, - UI.overhangBack)))
    }
    public showPanelOutline(){
        if(this.APP.sltPanelDirectionShow.currentValue == false)
            return;
    
        let objs = this.roofGroupFront.children.filter(o =>
            o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
            o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
        ).concat(...this.roofGroupBack.children.filter(o =>
            o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
            o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
        ));
    
        let offsetLeft = this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue - this.MANAGER.patiosGroup.position.x;
        let offsetRight = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue + this.MANAGER.patiosGroup.position.x;
        let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
        let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);
        
    
        for (let o of objs) {
            let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale((o as Mesh), 60);
    
            o.updateWorldMatrix(true, true);
            outlineGeo.applyMatrix4(o.matrixWorld);
            //outlineGeo.translate(0, 5000, 0);
    
            if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE) {
                outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
                outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
            }
    
            var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
            line.userData = {type: GEOMETRY_TYPE.SHEET_OUTLINE};
            this.APP.scene.add( line );
        }
    }
    public simplifyGeo(geo: BufferGeometry): Geometry{
        let simplifiedGeo = new Geometry();
        let vertices = geo.getAttribute('position').array;
        for(let i = 0; i < vertices.length; i+=3){
            simplifiedGeo.vertices.push(new Vector3(vertices[i], vertices[i+1] - 5000, vertices[i+2]));
        }
        return simplifiedGeo;
    }    
    public addRoofFront(){
        this.roofGroupFront = null;
        this.roofGroupFront = new Group();
        this.roofGroupFront.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PATIOS };

        let offsetY = this.getRoofOffsetY();
        let offsetZ = (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2;

        this.roofGroupFront.position.setY(offsetY);
        this.roofGroupFront.position.setZ(offsetZ);
        this.roofGroupFront.rotateX(this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue));

        this.scene.add(this.roofGroupFront);
    }
    public addRoofBack(){
        this.roofGroupBack = null;
        this.roofGroupBack = new Group();
        this.roofGroupBack.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PATIOS };

        let offsetY = this.getRoofOffsetY();
        let offsetZ = (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2;

        this.roofGroupBack.position.setY(offsetY);
        this.roofGroupBack.position.setZ(offsetZ);
        this.roofGroupBack.rotateX(-this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue));

        this.scene.add(this.roofGroupBack);
    }
    public addRoofSheetingFront(offsetX: number){
        let mesh = new Mesh(this.geo_RoofPanel.geometry, this.material);

        let offsetSheet = 0;
        if(this.APP.sltRoofSheetingType.currentValue == 0 || this.APP.sltRoofSheetingType.currentValue == 1){
            offsetSheet = 30;
        }
        else{
            offsetSheet = 12;
        }
        
        const offsetPitch = this.geo_roofPanelBase.height * this.utils.tan(UI.patiosPitch)
        let scaleZ =( ((this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2
            + this.APP.sldFrontOverhang.currentValue)/this.utils.cos(this.APP.sltRoofPitch.currentValue) - offsetPitch)/this.geo_RoofPanel.length;
        
        let offsetY = this.geo_roofPanelBase.height - offsetSheet;
        let offsetZ = 0;

        let views: Print2DView[] = [
            { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        mesh.position.set(offsetX, offsetY, offsetZ);
        mesh.scale.setZ(scaleZ);
        mesh.userData = { 
            category: GEOMETRY_CATEGORY.PATIOS, 
            type: GEOMETRY_TYPE.ROOF_PANEL, 
            views: views,
            dir: UI.panelDirection === PANEL_DIRECTION.LEFT_TO_RIGHT ? "L-R" : "R-L"
        };
        
        this.roofGroupFront.add(mesh);
    }
    public addRoofSheetingBack(offsetX: number){
        let mesh = new Mesh(this.geo_RoofPanel.geometry, this.material);

        let offsetSheet = 0;
        if(this.APP.sltRoofSheetingType.currentValue == 0 || this.APP.sltRoofSheetingType.currentValue == 1){
            offsetSheet = 30;
        }
        else{
            offsetSheet = 12;
        }
        
        const offsetPitch = this.geo_roofPanelBase.height * this.utils.tan(UI.patiosPitch)
        let scaleZ = (((this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2
            + this.APP.sldBackOverhang.currentValue)/this.utils.cos(this.APP.sltRoofPitch.currentValue) - offsetPitch)/this.geo_RoofPanel.length;
        
        let offsetY = this.geo_roofPanelBase.height - offsetSheet;
        let offsetZ = - scaleZ * this.geo_RoofPanel.length;

        let views: Print2DView[] = [
            // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        mesh.position.set(offsetX, offsetY, offsetZ);
        mesh.scale.setZ(scaleZ);
        mesh.userData = { 
            category: GEOMETRY_CATEGORY.PATIOS, 
            type: GEOMETRY_TYPE.ROOF_PANEL, 
            views: views,
            dir: UI.panelDirection === PANEL_DIRECTION.RIGHT_TO_LEFT ? "L-R" : "R-L"
        };
        
        this.roofGroupBack.add(mesh);
    }
    public addRoofSheetingBaseFront(offsetX: number, isLast: boolean){
        let mesh: Mesh = new Mesh(this.geo_roofPanelBase.geometry, MaterialManager.Instance().ROOF_BASE);
        const offsetPitch = this.geo_roofPanelBase.height * this.utils.tan(UI.patiosPitch)
        let scaleZ = (((this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2
            + this.APP.sldFrontOverhang.currentValue
            )/this.utils.cos(this.APP.sltRoofPitch.currentValue) - offsetPitch + this.FIT_SHEETING_BASE)/this.geo_roofPanelBase.length;

        let views: Print2DView[] = [
            { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        mesh.position.set(offsetX + 10, 0, 0);
        mesh.scale.setZ(scaleZ);
        mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PANEL_BASE, views: views };
        if(isLast){
            mesh.userData.last = true;
        }
        this.roofGroupFront.add(mesh);
    }
    public addRoofSheetingBaseBack(offsetX: number, isLast: boolean){
        let mesh: Mesh = new Mesh(this.geo_roofPanelBase.geometry, MaterialManager.Instance().ROOF_BASE);
        const offsetPitch = this.geo_roofPanelBase.height * this.utils.tan(UI.patiosPitch)
        let scaleZ = (((this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2
            + this.APP.sldBackOverhang.currentValue
            )/this.utils.cos(this.APP.sltRoofPitch.currentValue) - offsetPitch + this.FIT_SHEETING_BASE)/this.geo_roofPanelBase.length;

        let views: Print2DView[] = [
            // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        mesh.position.set(offsetX + 10, 0, - scaleZ * this.geo_roofPanelBase.length);
        mesh.scale.setZ(scaleZ);
        mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ROOF_PANEL_BASE, views: views };
        if(isLast){
            mesh.userData.last = true;
        }
        this.roofGroupBack.add(mesh);
    }
    private addBargeCappingFront(){
        let mesh: Mesh;

        let offsetLeft = this.totalBaySize/2 + this.APP.sldLeftOverhang.currentValue;
        let offsetRight = this.totalBaySize/2 + this.APP.sldRightOverhang.currentValue;

        let scaleX = (this.APP.sldLeftOverhang.currentValue 
            + this.totalBaySize 
            + this.APP.sldRightOverhang.currentValue)/this.geo_roofPanelBargeCappingBack.length;
        
        let fitZ = this.geo_roofPanelBargeCappingLeft.height * this.utils.tan(this.APP.sltRoofPitch.currentValue)

        const offsetPitch = this.geo_roofPanelBase.height * this.utils.tan(UI.patiosPitch)
        let scaleZ = (((this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2
            + this.APP.sldFrontOverhang.currentValue + fitZ)/this.utils.cos(this.APP.sltRoofPitch.currentValue) - offsetPitch)/this.geo_roofPanelBargeCappingLeft.length;

        let views: Print2DView[] = [
            { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        //left
        mesh = new Mesh(this.geo_roofPanelBargeCappingLeft.geometry, MaterialManager.Instance().BARGE);
        mesh.position.setX(-offsetLeft);
        mesh.position.setZ(-fitZ)
        mesh.scale.setZ(scaleZ);
        mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
        this.roofGroupFront.add(mesh);

        //right
        mesh = new Mesh(this.geo_roofPanelBargeCappingRight.geometry, MaterialManager.Instance().BARGE);
        mesh.position.setX(offsetRight);
        mesh.position.setZ(-fitZ)
        mesh.scale.setZ(scaleZ);
        mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
        this.roofGroupFront.add(mesh);
    }

    private addBargeCappingBack(){
        let mesh: Mesh;

        let offsetLeft = this.totalBaySize/2 + this.APP.sldLeftOverhang.currentValue;
        let offsetRight = this.totalBaySize/2 + this.APP.sldRightOverhang.currentValue;

        let scaleX = (this.APP.sldLeftOverhang.currentValue 
            + this.totalBaySize 
            + this.APP.sldRightOverhang.currentValue)/this.geo_roofPanelBargeCappingBack.length;

        let fitZ = this.geo_roofPanelBargeCappingLeft.height * this.utils.tan(this.APP.sltRoofPitch.currentValue);

        const offsetPitch = this.geo_roofPanelBase.height * this.utils.tan(UI.patiosPitch)
        let scaleZ = (((this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2
            + this.APP.sldBackOverhang.currentValue + fitZ)/this.utils.cos(this.APP.sltRoofPitch.currentValue) - offsetPitch)/this.geo_roofPanelBargeCappingLeft.length;

        let views: Print2DView[] = [
            // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        //left
        mesh = new Mesh(this.geo_roofPanelBargeCappingLeft.geometry, MaterialManager.Instance().BARGE);
        mesh.position.setX(-offsetLeft);
        mesh.position.setZ(- scaleZ * this.geo_roofPanelBargeCappingLeft.length + fitZ);
        mesh.scale.setZ(scaleZ);
        mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
        this.roofGroupBack.add(mesh);

        //right
        mesh = new Mesh(this.geo_roofPanelBargeCappingRight.geometry, MaterialManager.Instance().BARGE);
        mesh.position.setX(offsetRight);
        mesh.position.setZ(- scaleZ * this.geo_roofPanelBargeCappingLeft.length + fitZ);
        mesh.scale.setZ(scaleZ);
        mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: this.APP.sltDripBarge.currentValue ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING, views: views };
        this.roofGroupBack.add(mesh);
    }

    public addGutterFront(){
        let mesh: Mesh;
        
        let offsetXL = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
        let offsetXR = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;
        let offsetY = this.geo_roofPanelBase.height - 25;
        let offsetZ = (this.APP.sldFrontOverhang.currentValue 
            + (this.APP.sldMultiSpan.currentValue + this.APP.sldSpan.currentValue) / 2
            + this.FIT_SHEETING - 50)/this.utils.cos(this.APP.sltRoofPitch.currentValue);

        let scaleX = (this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue)/this.geo_gutterBack.length;
        
        let views: Print2DView[] = [
            { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        mesh = new Mesh(this.geo_gutterBack.geometry, MaterialManager.Instance().GUTTER);
        mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.GUTTER_PATIOS, views: views };
        mesh.position.set(offsetXL, 0, 0);
        mesh.scale.setX(scaleX);
        
        let endCapMesh = new Mesh(this.geo_gutterCap.geometry, MaterialManager.Instance().GUTTER);
        endCapMesh.position.set(offsetXL, 0, 0);

        let endCapMesh2 = new Mesh(this.geo_gutterCap.geometry, MaterialManager.Instance().GUTTER);
        endCapMesh2.position.set(offsetXR, 0, 0);

        let gutterGroup = new Group();
        gutterGroup.add(mesh, endCapMesh, endCapMesh2);
        gutterGroup.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.GUTTER_PATIOS, views: views};
        gutterGroup.position.set(0, offsetY, offsetZ);
        gutterGroup.rotation.set(-this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), 0, 0);
        this.roofGroupFront.add(gutterGroup);
    }

    public addRidgeCapping(){
        let ridgeFront = this.createRidgeCapping();
        const height = this.getRoofOffsetY() + 
            this.geo_roofPanelBase.height / this.utils.cos(this.APP.sltRoofPitch.currentValue) + 
            this.geo_RoofPanel.height / this.utils.cos(this.APP.sltRoofPitch.currentValue) - 
            (this.APP.sltRoofSheetingType.currentValue == 0 || this.APP.sltRoofSheetingType.currentValue == 1 ? 20 : 10);
        const ridgeLength = this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue;
        ridgeFront.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.RIDGE_CAPPING, length: ridgeLength, views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },{ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }]};
        ridgeFront.scale.setZ(ridgeLength / this.ridgeGeo.length);
        ridgeFront.rotation.set(0, Math.PI / 2, 0)
        ridgeFront.position.set((this.APP.sldRightOverhang.currentValue - this.APP.sldLeftOverhang.currentValue) / 2, height, (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2);
        this.scene.add(ridgeFront)
    }

    public createRidgeCapping(){
        let leftCap = new Mesh(this.ridgeGeo.geometry, MaterialManager.Instance().RIDGE_CAPPING);
        let rightCap = new Mesh(this.ridgeGeo.geometry, MaterialManager.Instance().RIDGE_CAPPING);
    
        rightCap.rotateY(Math.PI)
    
        leftCap.rotateZ(this.utils.degreesToRadians(UI.patiosPitch))
        rightCap.rotateZ(this.utils.degreesToRadians(UI.patiosPitch))
    
        leftCap.userData = {views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },{ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }]}
        rightCap.userData = {views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },{ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }]}
    
        return new Group().add(leftCap, rightCap)
    }

    public addGutterBack(){
        let mesh: Mesh;
        
        let offsetXL = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
        let offsetXR = this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;
        let offsetY = this.geo_roofPanelBase.height - 25;
        let offsetZ = -(this.APP.sldBackOverhang.currentValue 
            + (this.APP.sldMultiSpan.currentValue + this.APP.sldSpan.currentValue) / 2
            + this.FIT_SHEETING - 50)/this.utils.cos(this.APP.sltRoofPitch.currentValue);

        let scaleX = (this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue)/this.geo_gutterBack.length;
        
        let views: Print2DView[] = [
            // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
        ];

        mesh = new Mesh(this.geo_gutterBack.geometry, MaterialManager.Instance().GUTTER);
        mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.GUTTER_PATIOS, views: views };
        mesh.position.set(offsetXL, 0, 0);
        mesh.scale.setX(scaleX);
        
        let endCapMesh = new Mesh(this.geo_gutterCap.geometry, MaterialManager.Instance().GUTTER);
        endCapMesh.position.set(offsetXL, 0, 0);

        let endCapMesh2 = new Mesh(this.geo_gutterCap.geometry, MaterialManager.Instance().GUTTER);
        endCapMesh2.position.set(offsetXR, 0, 0);

        let gutterGroup = new Group();
        gutterGroup.add(mesh, endCapMesh, endCapMesh2);
        gutterGroup.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.GUTTER_PATIOS, views: views};
        gutterGroup.position.set(-(this.APP.sldLeftOverhang.currentValue - this.APP.sldRightOverhang.currentValue), offsetY, offsetZ);
        gutterGroup.rotation.set(this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue), Math.PI, 0);
        this.roofGroupBack.add(gutterGroup);
    }

    public addZFlashingFront() {
        let offsetX = -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue);
        let offsetY = this.geo_roofPanelBase.height - 25;
        const offsetZ = (this.APP.sldFrontOverhang.currentValue
            + (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2
        ) / this.utils.cos(this.APP.sltRoofPitch.currentValue);

        const scaleX = (this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue) / this.zFlashingGeo.length;

        let mesh = new Mesh(this.zFlashingGeo.geometry, MaterialManager.Instance().ZFLASHING);
        mesh.position.set(offsetX, offsetY, offsetZ);
        mesh.scale.set(scaleX, 1, 1);

        mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ZFLASHING, scale: mesh.scale };
        this.roofGroupFront.add(mesh);
    }
    public addZFlashingBack() {
        let offsetX = (this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue);
        let offsetY = this.geo_roofPanelBase.height - 25;
        const offsetZ = (this.APP.sldBackOverhang.currentValue
            + (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2
        ) / this.utils.cos(this.APP.sltRoofPitch.currentValue);

        const scaleX = (this.totalBaySize + this.APP.sldLeftOverhang.currentValue + this.APP.sldRightOverhang.currentValue) / this.zFlashingGeo.length;

        let mesh = new Mesh(this.zFlashingGeo.geometry, MaterialManager.Instance().ZFLASHING);
        mesh.position.set(offsetX, offsetY, - offsetZ);
        mesh.scale.set(scaleX, 1, 1);
        mesh.rotateY(Math.PI)

        mesh.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.ZFLASHING, scale: mesh.scale };
        this.roofGroupBack.add(mesh);
    }
    public getRoofOffsetY() {
        return this.APP.sldBuildingHeight.currentValue + this.utils.tan(this.APP.sltRoofPitch.currentValue) * (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2
    }
    public destroy(): void {
        this.unregisterEvent();
    }
    private registerEvent(): void {
        this.eventHandleId = this.uiChanged.bind(this);
        this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

        this.controlsToRegisterEvent = [
            this.APP.sldSpan,
            this.APP.sldFrontOverhang,
            this.APP.sldBackOverhang,
            this.APP.sldLeftOverhang,
            this.APP.sldRightOverhang,
            this.APP.sltRoofPitch,
            this.APP.sldMultiSpan,
            this.APP.sldBuildingHeight,
            this.APP.dialogEditBay,
            this.APP.sldFrontOverhang,
            this.APP.sldBackOverhang
        ];
        //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));

        this.controlsToRegisterEvent2 = [
            this.APP.sltRoofSheetingType,
            this.APP.sltRoofThickness,
            this.APP.sltBargeType,
            this.APP.sltDripBarge,
            this.APP.sltGutterType,
            this.APP.sltRoofPitch
        ];
        this.controlsToRegisterEvent2.forEach(c => c.addAction(this.objectSizeChangedHandle));
    }
    private unregisterEvent(): void{
        // this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
        // this.controlsToRegisterEvent = undefined;

        this.controlsToRegisterEvent2.forEach(c => c.removeAction(this.objectSizeChangedHandle));
        this.controlsToRegisterEvent2 = undefined;
    }
    public uiChanged(preVal: number, curVal: number): void {
        this.load();
    }
    private objectSizeChanged(pre: number, cur: number){
        this.optimize().then(() => { this.load() });
    }
}
