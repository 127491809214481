<div class="popup-window" [ngClass]="{ 'p-show': visible }">
    <div class="p-header">{{ title }}</div>
    <div class="p-body">
      <div
        class="ctr-input"
        [ngClass]="{ 'ctrl-visible': valueType === 'number' }"
      >
        <!-- <input type="number" [(ngModel)]="dimValue" /> -->
        <nz-input-number
          style="width: 100%"
          [nzMin]="minValue"
          [nzMax]="maxValue"
          [nzStep]="step"
          [(ngModel)]="dimValue"
        ></nz-input-number>
      </div>
    </div>
    <div class="p-footer">
      <button class="ui button mini" (click)="onOK()">OK</button>
      <button class="ui button mini" (click)="onCancel()">Cancel</button>
    </div>
  </div>