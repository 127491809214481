<div
  class="ui fluid dropdown selection"
  [ngClass]="{ 'active visible': isOpen }"
  tabindex="0"
  (click)="onClick()"
>
  <i class="dropdown icon"></i>
  <div class="text">
    <div
      class="text-color-icon"
      [style.background-color]="toHexColor(currentValue)"
    ></div>
    <div
      class="text-label"
      [ngClass]="{ 'item-disableDiv': currentItem?.disabled }"
    >
      {{ currentText }}
    </div>
  </div>
  <div
    class="menu transition"
    [ngClass]="{ hidden: !isOpen, visible: isOpen }"
    tabindex="-1"
    [style.display]="isOpen ? 'display: block !important;' : ''"
  >
    <div
      *ngFor="let item of items"
      class="item color"
      [ngClass]="{
        'active selected': item.selected,
        'item-disableDiv': item?.disabled
      }"
      [attr.data-value]="item.id"
      (click)="onItemSelected(item)"
    >
      <div
        class="color-icon"
        [style.background-color]="toHexColor(item.id)"
      ></div>
      {{ item.value }}
    </div>
  </div>
</div>
