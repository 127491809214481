import {
  BufferGeometry,
  Vector3,
  PlaneBufferGeometry,
  BoxBufferGeometry,
} from "three";
import { environment as env } from "src/environments/environment";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { Util } from "../core/utils";
import { GeometryInfo } from "./models";
import { HomeComponent } from "../containers/home/home.component";
import { EXISTING_BUILDING_CONFIG } from "../app.constants";
import { UI } from "./ui";
import _ from "lodash"

export class GeometryManager {
  APP: HomeComponent;
  private constructor() {
    this.utils = new Util();
    this.loader = new STLLoader();
  }
  public init(app: HomeComponent) {
    this.APP = app;
  }
  private static instance: GeometryManager;
  private loader: STLLoader;

  private utils: Util;

  public EXISTING_ROOF: {
    EXISTING_ROOF: GeometryInfo;
  } = {
    EXISTING_ROOF: null,
  };

  public EXISTING_WALL: {
    EXISTING_WALL: GeometryInfo;
  } = {
    EXISTING_WALL: null,
  };

  public FASCIA: {
    FASCIA: GeometryInfo;
  } = {
    FASCIA: null,
  };
  public EAVE: {
    EAVE: GeometryInfo;
  } = {
    EAVE: null,
  };

  public SUPERIOR_BEAM: {
    S65x110: GeometryInfo;
    S65x110_JOINT: GeometryInfo;
    S65x110_ENDCAP: GeometryInfo;
    S65x160: GeometryInfo;
    S65x160_JOINT: GeometryInfo;
    S65x160_ENDCAP: GeometryInfo;
    S65x210: GeometryInfo;
    S65x210_JOINT: GeometryInfo;
    S65x210_ENDCAP: GeometryInfo;
    S65x260: GeometryInfo;
    S65x260_JOINT: GeometryInfo;
    S65x260_ENDCAP: GeometryInfo;
  } = {
    S65x110: null,
    S65x110_JOINT: null,
    S65x110_ENDCAP: null,
    S65x160: null,
    S65x160_JOINT: null,
    S65x160_ENDCAP: null,
    S65x210: null,
    S65x210_JOINT: null,
    S65x210_ENDCAP: null,
    S65x260: null,
    S65x260_JOINT: null,
    S65x260_ENDCAP: null,
  };
  public TIMBER_BEAM: {
    S140x45: GeometryInfo;
    S190x45: GeometryInfo;
    S150x50: GeometryInfo;
    S200x50: GeometryInfo;
    S150x75: GeometryInfo;
    S200x75: GeometryInfo;
    S250x75: GeometryInfo;
    S150x50_ENDCAP: GeometryInfo;
  } = {
    S140x45: null,
    S190x45: null,
    S150x50: null,
    S200x50: null,
    S150x75: null,
    S200x75: null,
    S250x75: null,
    S150x50_ENDCAP: null,
  };

  public RidgeCapping: {
    // S_Ridge_100: GeometryInfo;
    // S_Ridge_125: GeometryInfo;
    // S_Ridge_150: GeometryInfo;
    // S_Ridge_200: GeometryInfo;
    // S_Ridge_225: GeometryInfo;
    // S_Ridge_250: GeometryInfo;
    S_Ridge_Custom: GeometryInfo;
  } = {
    S_Ridge_Custom: null
  };

  public SUPERIOR_POST: {
    S65x65: GeometryInfo;
    S90x90: GeometryInfo;
    TIMBER: GeometryInfo; // 150
    CENTER_POST_TIMBER: GeometryInfo;
  } = {
    S65x65: null,
    S90x90: null,
    TIMBER: null,
    CENTER_POST_TIMBER: null,
  };
  public FLY_OVER_BRACKET: {
    S65x3: GeometryInfo;
  } = {
    S65x3: null,
  };
  public MONO_PANEL: {
    S50MM: GeometryInfo;
    S50MM_BASE: GeometryInfo;
    S75MM: GeometryInfo;
    S75MM_BASE: GeometryInfo;
    S100MM: GeometryInfo;
    S100MM_BASE: GeometryInfo;
    S125MM: GeometryInfo;
    S125MM_BASE: GeometryInfo;
    S150MM: GeometryInfo;
    S150MM_BASE: GeometryInfo;
  } = {
    S50MM: null,
    S50MM_BASE: null,
    S75MM: null,
    S75MM_BASE: null,
    S100MM: null,
    S100MM_BASE: null,
    S125MM: null,
    S125MM_BASE: null,
    S150MM: null,
    S150MM_BASE: null,
  };

  public CORRO_PANEL: {
    S50MM: GeometryInfo;
    S50MM_BASE: GeometryInfo;
    S75MM: GeometryInfo;
    S75MM_BASE: GeometryInfo;
    S100MM: GeometryInfo;
    S100MM_BASE: GeometryInfo;
    S125MM: GeometryInfo;
    S125MM_BASE: GeometryInfo;
    S150MM: GeometryInfo;
    S150MM_BASE: GeometryInfo;
  } = {
    S50MM: null,
    S50MM_BASE: null,
    S75MM: null,
    S75MM_BASE: null,
    S100MM: null,
    S100MM_BASE: null,
    S125MM: null,
    S125MM_BASE: null,
    S150MM: null,
    S150MM_BASE: null,
  };

  public UPSTAND_BRAKET = {
    BRAKET_1: new GeometryInfo(),
    BRAKET_2: new GeometryInfo(),
  };

  public MONO_PANEL_BARGE_CAPPING: {
    S50MM: GeometryInfo;
    S75MM: GeometryInfo;
    S100MM: GeometryInfo;
    S125MM: GeometryInfo;
    S150MM: GeometryInfo;
  } = {
    S50MM: null,
    S75MM: null,
    S100MM: null,
    S125MM: null,
    S150MM: null,
  };

  public CORRO_PANEL_BARGE_CAPPING: {
    S50MM: GeometryInfo;
    S75MM: GeometryInfo;
    S100MM: GeometryInfo;
    S125MM: GeometryInfo;
    S150MM: GeometryInfo;
  } = {
    S50MM: null,
    S75MM: null,
    S100MM: null,
    S125MM: null,
    S150MM: null,
  };

  public MONO_PANEL_DRIP_BARGE_CAPPING: {
    S50MM: GeometryInfo;
    S75MM: GeometryInfo;
    S100MM: GeometryInfo;
    S125MM: GeometryInfo;
    S150MM: GeometryInfo;
  } = {
    S50MM: null,
    S75MM: null,
    S100MM: null,
    S125MM: null,
    S150MM: null,
  };

  public CORRO_PANEL_DRIP_BARGE_CAPPING: {
    S50MM: GeometryInfo;
    S75MM: GeometryInfo;
    S100MM: GeometryInfo;
    S125MM: GeometryInfo;
    S150MM: GeometryInfo;
  } = {
    S50MM: null,
    S75MM: null,
    S100MM: null,
    S125MM: null,
    S150MM: null,
  };

  public MONO_PANEL_RECIEVER_CHANEL: {
    S50MM: GeometryInfo;
    S75MM: GeometryInfo;
    S100MM: GeometryInfo;
    S125MM: GeometryInfo;
    S150MM: GeometryInfo;
  } = {
    S50MM: null,
    S75MM: null,
    S100MM: null,
    S125MM: null,
    S150MM: null,
  };
  public CORRO_PANEL_RECIEVER_CHANEL: {
    S50MM: GeometryInfo;
    S75MM: GeometryInfo;
    S100MM: GeometryInfo;
    S125MM: GeometryInfo;
    S150MM: GeometryInfo;
  } = {
    S50MM: null,
    S75MM: null,
    S100MM: null,
    S125MM: null,
    S150MM: null,
  };

  public GUTTER: {
    SQUARE_LINE: GeometryInfo;
    SQUARE_LINE_END_CAP: GeometryInfo;
    QUAD_150: GeometryInfo;
    QUAD_150_END_CAP: GeometryInfo;
  } = {
    SQUARE_LINE: null,
    SQUARE_LINE_END_CAP: null,
    QUAD_150: null,
    QUAD_150_END_CAP: null,
  };
  public DOWNPIPE: {
    PIPE_S90MM: GeometryInfo;
    L_S90MM: GeometryInfo;
  } = {
    PIPE_S90MM: undefined,
    L_S90MM: undefined,
  };

  public Z_FlASHING: {
    S50: GeometryInfo;
    S75: GeometryInfo;
    S100: GeometryInfo;
    S125: GeometryInfo;
    S150: GeometryInfo;
  } = {
    S50: null,
    S75: null,
    S100: null,
    S125: null,
    S150: null,
  };

  public DIMENSION = {
    LINE: new GeometryInfo(new PlaneBufferGeometry(1000, 15), 15, 1, 1000),
    CAP: new GeometryInfo(new PlaneBufferGeometry(15, 200), 200, 1, 15),
  };

  public UI_CONTROL = {
    LINE: new GeometryInfo(new PlaneBufferGeometry(1000, 30), 15, 1, 1000),
  };

  static Instance(): GeometryManager {
    if (!GeometryManager.instance) {
      GeometryManager.instance = new GeometryManager();
    }
    return GeometryManager.instance;
  }

  public loadAll(): Promise<any> {
    return Promise.all([
      this.loadExistingRoof(),
      this.loadExistingWall(),
      this.loadFascia(),
      this.loadEave(),
      this.loadSuperiorBeam(),
      this.loadBeamJoint(),
      this.loadRidgeCapping(),
      this.loadSuperiorPost(),
      this.loadFlyOverBracket(),
      this.loadMonoPanel(),
      this.loadCorroPanel(),
      this.loadMonoPanelBargeCapping(),
      this.loadCorroPanelBargeCapping(),
      this.loadMonoPanelDripBargeCapping(),
      this.loadCorroPanelDripBargeCapping(),
      this.loadGutter(),
      this.loadMonoRecieverChanel(),
      this.loadCorroRecieverChanel(),
      this.loadDownPipe(),
      this.loadZFlashing(),
      this.loadBraket(),
    ]);
  }

  private downloadFile(fileName: string): Promise<BufferGeometry> {
    return new Promise((resolve, reject) => {
      this.loader.load(
        env.modelBaseUrl + "/assets/models/" + fileName,
        (geometry) => {
          geometry.center();
          resolve(geometry);
        }
      );
    });
  }
  private downloadFileEx(
    fileName: string,
    vWidth: Vector3,
    vHeight: Vector3,
    vLength: Vector3,
    name: string = ""
  ): Promise<GeometryInfo> {
    return new Promise((resolve, reject) => {
      this.loader.load(
        env.modelBaseUrl + "/assets/models/" + fileName,
        (geometry) => {
          geometry.center();
          const width = this.utils.getGeometryLength(geometry, vWidth);
          const height = this.utils.getGeometryLength(geometry, vHeight);
          const length = this.utils.getGeometryLength(geometry, vLength);
          let object = new GeometryInfo(geometry, width, height, length, name);
          resolve(object);
        }
      );
    });
  }
  public loadExistingRoof(): Promise<any> {
    // return this.downloadFile('existing_roof_left.stl')
    // .then((geometry) => {
    //     geometry.center();
    //     let width = this.utils.getGeometryLength(geometry, new Vector3(1,0,0));
    //     let height = this.utils.getGeometryLength(geometry, new Vector3(0,1,0));
    //     let length = this.utils.getGeometryLength(geometry, new Vector3(0,0,1));
    //     this.EXISTING_ROOF.LEFT = new GeometryInfo(geometry, width, height, length);
    //     return this.downloadFile('existing_roof_right.stl');
    // })
    // .then((geometry) => {
    //     geometry.center();
    //     let width = this.utils.getGeometryLength(geometry, new Vector3(0,0,1));
    //     let height = this.utils.getGeometryLength(geometry, new Vector3(0,1,0));
    //     let length = this.utils.getGeometryLength(geometry, new Vector3(1,0,0));
    //     this.EXISTING_ROOF.RIGHT = new GeometryInfo(geometry, width, height, length);
    // })

    // return this.downloadFile('existing_roof.stl')
    // .then((geometry) => {
    //     geometry.center();
    //     let width = this.utils.getGeometryLength(geometry, new Vector3(1,0,0));
    //     let height = this.utils.getGeometryLength(geometry, new Vector3(0,1,0));
    //     let length = this.utils.getGeometryLength(geometry, new Vector3(0,0,1));
    //     this.EXISTING_ROOF.LEFT = new GeometryInfo(geometry, width, height, length);
    //     this.EXISTING_ROOF.RIGHT = new GeometryInfo(geometry.clone().rotateY(-Math.PI/2), width, height, length);
    //     //return this.downloadFile('existing_roof_right.stl');
    // })

    return new Promise((resolve, reject) => {
      const geometry = new BoxBufferGeometry(3000, 20, 1800);

      this.EXISTING_ROOF.EXISTING_ROOF = new GeometryInfo(
        geometry,
        1800,
        20,
        3000
      );
      // this.EXISTING_ROOF.RIGHT = new GeometryInfo(geometry, 1800, 20, 3000);
      resolve(geometry);
    });
  }
  public loadExistingWall(): Promise<any> {
    // return this.downloadFile('eave_wall.stl')
    // .then((geometry) => {
    //     geometry.center();

    //     let width = this.utils.getGeometryLength(geometry, new Vector3(1,0,0));
    //     let height = this.utils.getGeometryLength(geometry, new Vector3(0,1,0));
    //     let length = this.utils.getGeometryLength(geometry, new Vector3(0,0,1));
    //     this.EXISTING_WALL.EXISTING_WALL = new GeometryInfo(geometry, width, height, length);
    // })
    return new Promise((resolve, reject) => {
      const geometry = new BoxBufferGeometry(1000, 1000, 190);
      this.EXISTING_WALL.EXISTING_WALL = new GeometryInfo(
        geometry,
        190,
        1000,
        1000
      );
      resolve(geometry);
    });
  }
  public loadEave(): Promise<any> {
    return this.downloadFile("fascia_width.stl").then((geometry) => {
      geometry.center();
      const width = this.utils.getGeometryLength(
        geometry,
        new Vector3(1, 0, 0)
      );
      const height = this.utils.getGeometryLength(
        geometry,
        new Vector3(0, 1, 0)
      );
      const length = this.utils.getGeometryLength(
        geometry,
        new Vector3(0, 0, 1)
      );
      this.EAVE.EAVE = new GeometryInfo(geometry, width, height, length);
    });
  }
  public loadFascia(): Promise<any> {
    return this.downloadFile("fascia_depth.stl").then((geometry) => {
      geometry.center();
      const width = this.utils.getGeometryLength(
        geometry,
        new Vector3(1, 0, 0)
      );
      const height = this.utils.getGeometryLength(
        geometry,
        new Vector3(0, 1, 0)
      );
      const length = this.utils.getGeometryLength(
        geometry,
        new Vector3(0, 0, 1)
      );
      this.FASCIA.FASCIA = new GeometryInfo(geometry, width, height, length);
    });
  }
  public loadRidgeCapping(): Promise<any> {
    let vW = new Vector3(1, 0, 0);
    let vH = new Vector3(0, 1, 0);
    let vL = new Vector3(0, 0, 1);
    return Promise.all([
      // this.downloadFileEx("Ridge Capping 10 Deg (3D).stl", vW, vH, vL).then(
      //   (g) => {
      //     this.RidgeCapping.S_Ridge_100 = g;
      //   }
      // ),
      // this.downloadFileEx("Ridge Capping 12.5 Deg (3D).stl", vW, vH, vL).then(
      //   (g) => {
      //     this.RidgeCapping.S_Ridge_125 = g;
      //   }
      // ),
      // this.downloadFileEx("Ridge Capping 15 Deg (3D).stl", vW, vH, vL).then(
      //   (g) => {
      //     this.RidgeCapping.S_Ridge_150 = g;
      //   }
      // ),
      // this.downloadFileEx("Ridge Capping 20 Deg (3D).stl", vW, vH, vL).then(
      //   (g) => {
      //     this.RidgeCapping.S_Ridge_200 = g;
      //   }
      // ),
      // this.downloadFileEx("Ridge Capping 22.5 Deg (3D).stl", vW, vH, vL).then(
      //   (g) => {
      //     this.RidgeCapping.S_Ridge_225 = g;
      //   }
      // ),
      // this.downloadFileEx("Ridge Capping 25 Deg (3D).stl", vW, vH, vL).then(
      //   (g) => {
      //     this.RidgeCapping.S_Ridge_250 = g;
      //   }
      // ),
      this.downloadFileEx("Ridge Capping (Customs).stl", vW, vH, vL).then(
        (g) => {
          this.RidgeCapping.S_Ridge_Custom = g;
        }
      ),
    ]);
  }
  public loadBeamJoint(): Promise<any> {
    let geo = new BoxBufferGeometry(60, 105, 500);
    this.SUPERIOR_BEAM.S65x110_JOINT = new GeometryInfo(geo, 60, 105, 500, "BSIJ110");
    let geo1 = new BoxBufferGeometry(60, 155, 500);
    this.SUPERIOR_BEAM.S65x160_JOINT = new GeometryInfo(geo1, 60, 155, 500, "BSIJ160");
    let geo2 = new BoxBufferGeometry(60, 205, 500);
    this.SUPERIOR_BEAM.S65x210_JOINT = new GeometryInfo(geo2, 60, 205, 500, "BSIJ210");
    let geo3 = new BoxBufferGeometry(60, 255, 500);
    this.SUPERIOR_BEAM.S65x260_JOINT = new GeometryInfo(geo3, 60, 255, 500, "BSIJ260");

    return Promise.resolve()
  }
  public loadSuperiorBeam(): Promise<any> {
    let geo = new BoxBufferGeometry(45, 140, 1000);
    this.TIMBER_BEAM.S140x45 = new GeometryInfo(geo, 45, 140, 1000, "S45x140");
    let geo1 = new BoxBufferGeometry(90, 140, 1000);
    this.TIMBER_BEAM.S190x45 = new GeometryInfo(geo1, 90, 140, 1000, "S45x190");
    let geo2 = new BoxBufferGeometry(50, 150, 1000);
    this.TIMBER_BEAM.S150x50 = new GeometryInfo(geo2, 50, 150, 1000, "S50x150");
    let geo3 = new BoxBufferGeometry(50, 200, 1000);
    this.TIMBER_BEAM.S200x50 = new GeometryInfo(geo3, 50, 200, 1000, "S50x200");
    let geo4 = new BoxBufferGeometry(75, 150, 1000);
    this.TIMBER_BEAM.S150x75 = new GeometryInfo(geo4, 75, 150, 1000, "S75x150");
    let geo5 = new BoxBufferGeometry(75, 200, 1000);
    this.TIMBER_BEAM.S200x75 = new GeometryInfo(geo5, 75, 200, 1000, "S75x200");
    let geo6 = new BoxBufferGeometry(75, 250, 1000);
    this.TIMBER_BEAM.S250x75 = new GeometryInfo(geo6, 75, 250, 1000, "S75x250");
    let geoEndCap = new BoxBufferGeometry(50, 150, 50);
    this.TIMBER_BEAM.S150x50_ENDCAP = new GeometryInfo(geoEndCap, 50, 150, 50, "S150x50_ENDCAP");

    let vW = new Vector3(1, 0, 0);
    let vH = new Vector3(0, 1, 0);
    let vL = new Vector3(0, 0, 1);
    return Promise.all([
      this.downloadFileEx("Superior Beam 65x110 (3D).stl", vW, vH, vL, "S65x110").then(
        (g) => {
          this.SUPERIOR_BEAM.S65x110 = g;
        }
      ),
      this.downloadFileEx("Beam End Cap 65x110 (3D).stl", vW, vH, vL, "BSEC110").then(
        (g) => {
          this.SUPERIOR_BEAM.S65x110_ENDCAP = g;
        }
      ),
      this.downloadFileEx("Superior Beam 65x160 (3D).stl", vW, vH, vL, "S65x160").then(
        (g) => {
          this.SUPERIOR_BEAM.S65x160 = g;
        }
      ),
      this.downloadFileEx("Beam End Cap 65x160 (3D).stl", vW, vH, vL, "BSEC160").then(
        (g) => {
          this.SUPERIOR_BEAM.S65x160_ENDCAP = g;
        }
      ),
      this.downloadFileEx("Superior Beam 65x210 (3D).stl", vW, vH, vL, "S65x210").then(
        (g) => {
          this.SUPERIOR_BEAM.S65x210 = g;
        }
      ),
      this.downloadFileEx("Beam End Cap 65x210 (3D).stl", vW, vH, vL, "BSEC210").then(
        (g) => {
          this.SUPERIOR_BEAM.S65x210_ENDCAP = g;
        }
      ),
      this.downloadFileEx("Superior Beam 65x260 (3D).stl", vW, vH, vL, "S65x260").then(
        (g) => {
          this.SUPERIOR_BEAM.S65x260 = g;
        }
      ),
      this.downloadFileEx("Beam End Cap 65x260 (3D).stl", vW, vH, vL, "BSEC260").then(
        (g) => {
          this.SUPERIOR_BEAM.S65x260_ENDCAP = g;
        }
      ),
    ]);
  }
  public loadSuperiorPost(): Promise<any> {
    let geo = new BoxBufferGeometry(100, 100, 1000);
    this.SUPERIOR_POST.TIMBER = new GeometryInfo(geo, 100, 1000, 100);
    let geoTimber = new BoxBufferGeometry(65, 65, 1000);
    this.SUPERIOR_POST.CENTER_POST_TIMBER = new GeometryInfo(geoTimber, 65, 1000, 65);

    return this.downloadFile("Superior Post 65x65 (3D).stl")
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        this.SUPERIOR_POST.S65x65 = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("Post SHS 90x3 (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        this.SUPERIOR_POST.S90x90 = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
      });
  }
  public loadFlyOverBracket(): Promise<any> {
    return this.downloadFile("Fly Over Bracket 65x3 (3D).stl").then(
      (geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        this.FLY_OVER_BRACKET.S65x3 = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
      }
    );
  }
  public loadMonoPanel(): Promise<any> {
    return this.downloadFile("50mm Monopanel-1 (3D).stl")
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        geometry.translate(0, 2, 0);
        this.MONO_PANEL.S50MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("75mm Monopanel-1 (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        geometry.translate(0, 2, 0);
        this.MONO_PANEL.S75MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("100mm Monopanel-1 (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        geometry.translate(0, 2, 0);
        this.MONO_PANEL.S100MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("125mm Monopanel-1 (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        geometry.translate(0, 2, 0);
        this.MONO_PANEL.S125MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("150mm Monopanel-1 (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        geometry.translate(0, 2, 0);
        this.MONO_PANEL.S150MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("50mm Monopanel-1 (3D)-base.stl");
      })

      .then((geometry) => {
        geometry.center();
        geometry.translate(
          EXISTING_BUILDING_CONFIG.ROOF_AND_BASE_OFFSET_X,
          0,
          0
        );
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL.S50MM_BASE = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("75mm Monopanel-1 (3D)-base.stl");
      })

      .then((geometry) => {
        geometry.center();
        geometry.translate(
          EXISTING_BUILDING_CONFIG.ROOF_AND_BASE_OFFSET_X,
          0,
          0
        );
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL.S75MM_BASE = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("100mm Monopanel-1 (3D)-base.stl");
      })
      .then((geometry) => {
        geometry.center();
        geometry.translate(
          EXISTING_BUILDING_CONFIG.ROOF_AND_BASE_OFFSET_X,
          0,
          0
        );
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL.S100MM_BASE = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("125mm Monopanel-1 (3D)-base.stl");
      })
      .then((geometry) => {
        geometry.center();
        geometry.translate(
          EXISTING_BUILDING_CONFIG.ROOF_AND_BASE_OFFSET_X,
          0,
          0
        );
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL.S125MM_BASE = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("150mm Monopanel-1 (3D)-base.stl");
      })
      .then((geometry) => {
        geometry.center();
        geometry.translate(
          EXISTING_BUILDING_CONFIG.ROOF_AND_BASE_OFFSET_X,
          0,
          0
        );
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL.S150MM_BASE = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
      });
  }
  public loadCorroPanel(): Promise<any> {
    return this.downloadFile("50mm Coropanel-1 (3D).stl")
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL.S50MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("75mm Coropanel-1 (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL.S75MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("100mm Coropanel-1 (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL.S100MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("125mm Coropanel-1 (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL.S125MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("150mm Coropanel-1 (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL.S150MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("50mm Coropanel-1 (3D)-base.stl");
      })

      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL.S50MM_BASE = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("75mm Coropanel-1 (3D)-base.stl");
      })

      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL.S75MM_BASE = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("100mm Coropanel-1 (3D)-base.stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL.S100MM_BASE = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("125mm Coropanel-1 (3D)-base.stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL.S125MM_BASE = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("150mm Coropanel-1 (3D)-base.stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL.S150MM_BASE = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
      });
  }
  public loadMonoPanelBargeCapping(): Promise<any> {
    return this.downloadFile("50mm Monopanel Barge Capping (3D).stl")
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_BARGE_CAPPING.S50MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("75mm Monopanel Barge Capping (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_BARGE_CAPPING.S75MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("100mm Monopanel Barge Capping (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_BARGE_CAPPING.S100MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("125mm Monopanel Barge Capping (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_BARGE_CAPPING.S125MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("150mm Monopanel Barge Capping (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_BARGE_CAPPING.S150MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
      });
  }
  public loadMonoPanelDripBargeCapping(): Promise<any> {
    return this.downloadFile("50MM TRIMSPAN DRIP BARGE.stl")
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_DRIP_BARGE_CAPPING.S50MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("75MM TRIMSPAN DRIP BARGE.stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_DRIP_BARGE_CAPPING.S75MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("100MM TRIMSPAN DRIP BARGE .stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_DRIP_BARGE_CAPPING.S100MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("125MM TRIMSPAN DRIP BARGE .stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_DRIP_BARGE_CAPPING.S125MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("150MM TRIMSPAN DRIP BARGE.stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_DRIP_BARGE_CAPPING.S150MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
      });
  }
  public loadCorroPanelBargeCapping(): Promise<any> {
    return this.downloadFile("50mm Coropanel Barge Capping (3D).stl")
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_BARGE_CAPPING.S50MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("75mm Coropanel Barge Capping (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_BARGE_CAPPING.S75MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("100mm Coropanel Barge Capping (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_BARGE_CAPPING.S100MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("125mm Coropanel Barge Capping (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_BARGE_CAPPING.S125MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("150mm Coropanel Barge Capping (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_BARGE_CAPPING.S150MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
      });
  }
  public loadCorroPanelDripBargeCapping(): Promise<any> {
    return this.downloadFile("50MM CORROPANEL DRIP BARGE .stl")
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_DRIP_BARGE_CAPPING.S50MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("75MM  CORROPANEL DRIP BARGE.stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_DRIP_BARGE_CAPPING.S75MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("100MM CORROPANEL DRIP BARGE.stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_DRIP_BARGE_CAPPING.S100MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("125MM CORROPANEL DRIP BARGE .stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_DRIP_BARGE_CAPPING.S125MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("150MM CORROPANEL DRIP BARGE .stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_DRIP_BARGE_CAPPING.S150MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
      });
  }
  public loadMonoRecieverChanel(): Promise<any> {
    return this.downloadFile("50mm Monopanel Reveiver Channel (3D).stl")
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_RECIEVER_CHANEL.S50MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("75mm Monopanel Reveiver Channel (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_RECIEVER_CHANEL.S75MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("100mm Monopanel Reveiver Channel (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_RECIEVER_CHANEL.S100MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("125mm Monopanel Reveiver Channel (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_RECIEVER_CHANEL.S125MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("150mm Monopanel Reveiver Channel (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.MONO_PANEL_RECIEVER_CHANEL.S150MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
      });
  }
  public loadCorroRecieverChanel(): Promise<any> {
    return this.downloadFile("50mm Coropanel Reveiver Channel (3D).stl")
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        //geometry.rotateY(Math.PI/2)
        this.CORRO_PANEL_RECIEVER_CHANEL.S50MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("75mm Coropanel Reveiver Channel (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_RECIEVER_CHANEL.S75MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("100mm Coropanel Reveiver Channel (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_RECIEVER_CHANEL.S100MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("125mm Coropanel Reveiver Channel (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_RECIEVER_CHANEL.S125MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("150mm Coropanel Reveiver Channel (3D).stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        this.CORRO_PANEL_RECIEVER_CHANEL.S150MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
      });
  }
  public loadGutter(): Promise<any> {
    let vW = new Vector3(1, 0, 0);
    let vH = new Vector3(0, 1, 0);
    let vL = new Vector3(0, 0, 1);
    return Promise.all([
      this.downloadFileEx("Quad Gutter (3D).stl", vW, vH, vL).then((g) => {
        this.GUTTER.QUAD_150 = g;
      }),
      this.downloadFileEx(
        "End Cap Quad Gutter (3D).stl",
        vW,
        vH,
        new Vector3(1, 0, 0)
      ).then((g) => {
        this.GUTTER.QUAD_150_END_CAP = g;
      }),
      this.downloadFileEx("Square Line Gutter (3D).stl", vW, vH, vL).then(
        (g) => {
          this.GUTTER.SQUARE_LINE = g;
        }
      ),
      this.downloadFileEx(
        "End Cap Square Line Gutter (3D).stl",
        vW,
        vH,
        new Vector3(1, 0, 0)
      ).then((g) => {
        this.GUTTER.SQUARE_LINE_END_CAP = g;
      }),
    ]);
  }
  public loadBraket(): Promise<any> {
    let vW = new Vector3(1, 0, 0);
    let vH = new Vector3(0, 1, 0);
    let vL = new Vector3(0, 0, 1);
    return Promise.all([
      this.downloadFileEx("FUB Bracket 65x3_1.stl", vW, vH, vL).then((g) => {
        this.UPSTAND_BRAKET.BRAKET_1 = g;
      }),
      this.downloadFileEx("FUB Bracket 65x3_2.stl", vW, vH, vL).then((g) => {
        this.UPSTAND_BRAKET.BRAKET_2 = g;
      }),
    ]);
  }
  private loadDownPipe(): Promise<any> {
    return this.downloadFile("90mm PVC (3D)-pipe.stl")
      .then((geometry) => {
        geometry.center();

        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        geometry.translate(0, height / 2, 0);
        this.DOWNPIPE.PIPE_S90MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
        return this.downloadFile("90mm PVC (3D)-L.stl");
      })
      .then((geometry) => {
        geometry.center();
        const width = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 0, 1)
        );
        const height = this.utils.getGeometryLength(
          geometry,
          new Vector3(0, 1, 0)
        );
        const length = this.utils.getGeometryLength(
          geometry,
          new Vector3(1, 0, 0)
        );
        geometry.translate(0, height / 2, 0);
        this.DOWNPIPE.L_S90MM = new GeometryInfo(
          geometry,
          width,
          height,
          length
        );
      });
  }
  private loadZFlashing() {
    let vW = new Vector3(1, 0, 0);
    let vH = new Vector3(0, 1, 0);
    let vL = new Vector3(0, 0, 1);
    return Promise.all([
      this.downloadFileEx("50mm Z Flashing (3D).stl", vW, vH, vL).then((g) => {
        g.geometry
          .rotateY(Math.PI / 2)
          .translate(g.length / 2, -g.height / 2 - 2, -80);
        this.Z_FlASHING.S50 = g;
      }),
      this.downloadFileEx("75mm Z Flashing (3D).stl", vW, vH, vL).then((g) => {
        g.geometry
          .rotateY(Math.PI / 2)
          .translate(g.length / 2, -g.height / 2 - 2, -80);
        this.Z_FlASHING.S75 = g;
      }),
      this.downloadFileEx("100mm Z Flashing (3D).stl", vW, vH, vL).then((g) => {
        g.geometry
          .rotateY(Math.PI / 2)
          .translate(g.length / 2, -g.height / 2 - 2, -80);
        this.Z_FlASHING.S100 = g;
      }),
      this.downloadFileEx("125mm Z Flashing (3D).stl", vW, vH, vL).then((g) => {
        g.geometry
          .rotateY(Math.PI / 2)
          .translate(g.length / 2, -g.height / 2 - 2, -80);
        this.Z_FlASHING.S125 = g;
      }),
      this.downloadFileEx("150mm Z Flashing (3D).stl", vW, vH, vL).then((g) => {
        g.geometry
          .rotateY(Math.PI / 2)
          .translate(g.length / 2, -g.height / 2 - 2, -80);
        this.Z_FlASHING.S150 = g;
      }),
    ]);
  }
  public getBeam(): GeometryInfo {
    //_SUPERIOR BEAM
    if (this.APP.sltBeamType.currentValue == 0) {
      if (
        this.APP.sltBeamSize.currentValue == 0 ||
        this.APP.sltBeamSize.currentValue == 1
      ) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x110);
      }
      if (this.APP.sltBeamSize.currentValue == 2) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x160);
      }
      if (this.APP.sltBeamSize.currentValue == 3) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x210);
      }
      if (this.APP.sltBeamSize.currentValue == 4) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x260);
      }
      //FIXME: Bang - 2021.05.20: Xu ly loi khi tu TIMER -> SPC BEAM
      if (this.APP.sltBeamSize.currentValue > 4) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x110);
      }
    }
    //_TIMBER BEAM
    else if (this.APP.sltBeamType.currentValue == 1) {
      if (
        this.APP.sltBeamSize.currentValue == 0 ||
        this.APP.sltBeamSize.currentValue == 1
      ) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S140x45);
      }
      if (this.APP.sltBeamSize.currentValue == 2) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S190x45);
      }
      if (this.APP.sltBeamSize.currentValue == 3) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S150x50);
      }
      if (this.APP.sltBeamSize.currentValue == 4) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S200x50);
      }
      if (this.APP.sltBeamSize.currentValue == 5) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S150x75);
      }
      if (this.APP.sltBeamSize.currentValue == 6) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S200x75);
      }
      if (this.APP.sltBeamSize.currentValue == 7) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S250x75);
      }
    }
  }

  public getBeamJoint(): GeometryInfo {
    if (UI.beamType == 0) {
      if (
        UI.beamSize == 0 || UI.beamSize == 1
      ) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x110_JOINT);
      } else if (UI.beamSize == 2) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x160_JOINT);
      } else if (UI.beamSize == 3) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x210_JOINT);
      } else if (UI.beamSize == 4) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x260_JOINT);
      } else if (UI.beamSize > 4) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x110_JOINT);
      }
    }

    return null
  }

  public getHouseBeamJoint(): GeometryInfo {
    if (UI.beamType == 0) {
      if (
        UI.houseBeamSize == 0 ||
        UI.houseBeamSize == 1
      ) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x110_JOINT);
      }
      if (UI.houseBeamSize == 2) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x160_JOINT);
      }
      if (UI.houseBeamSize == 3) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x210_JOINT);
      }
      if (UI.houseBeamSize == 4) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x260_JOINT);
      }
      if (UI.houseBeamSize > 4) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x110_JOINT);
      }
    }

    return null
  }

  public getHouseBeam(): GeometryInfo {
    //_SUPERIOR BEAM
    if (this.APP.sltBeamType.currentValue == 0) {
      if (
        this.APP.sltHouseBeamSize.currentValue == 0 ||
        this.APP.sltHouseBeamSize.currentValue == 1
      ) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x110);
      }
      if (this.APP.sltHouseBeamSize.currentValue == 2) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x160);
      }
      if (this.APP.sltHouseBeamSize.currentValue == 3) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x210);
      }
      if (this.APP.sltHouseBeamSize.currentValue == 4) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x260);
      }
      //FIXME: Bang - 2021.05.20: Xu ly loi khi tu TIMER -> SPC BEAM
      if (this.APP.sltHouseBeamSize.currentValue > 4) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x110);
      }
    }
    //_TIMBER BEAM
    else if (this.APP.sltBeamType.currentValue == 1) {
      if (
        this.APP.sltHouseBeamSize.currentValue == 0 ||
        this.APP.sltHouseBeamSize.currentValue == 1
      ) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S140x45);
      }
      if (this.APP.sltHouseBeamSize.currentValue == 2) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S190x45);
      }
      if (this.APP.sltHouseBeamSize.currentValue == 3) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S150x50);
      }
      if (this.APP.sltHouseBeamSize.currentValue == 4) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S200x50);
      }
      if (this.APP.sltHouseBeamSize.currentValue == 5) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S150x75);
      }
      if (this.APP.sltHouseBeamSize.currentValue == 6) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S200x75);
      }
      if (this.APP.sltHouseBeamSize.currentValue == 7) {
        return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S250x75);
      }
    }
  }
  public getRafterBeam(): GeometryInfo {
    //_SUPERIOR BEAM
    if (this.APP.sltBeamType.currentValue == 0) {
      return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x160);
    }
    //_TIMBER BEAM
    else if (this.APP.sltBeamType.currentValue == 1) {
      return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S150x50);
    }
  }
  public getRafterBeamEndCap(): GeometryInfo {
    //_SUPERIOR BEAM
    if (this.APP.sltBeamType.currentValue == 0) {
      return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x160_ENDCAP);
    }
    //_TIMBER BEAM
    else if (this.APP.sltBeamType.currentValue == 1) {
      return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S150x50_ENDCAP);
    }
  }
  public getBeamEndCap(): GeometryInfo {
    //_SUPERIOR BEAM
    if (this.APP.sltBeamType.currentValue == 0) {
      if (
        this.APP.sltBeamSize.currentValue == 0 ||
        this.APP.sltBeamSize.currentValue == 1
      ) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x110_ENDCAP);
      }
      if (this.APP.sltBeamSize.currentValue == 2) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x160_ENDCAP);
      }
      if (this.APP.sltBeamSize.currentValue == 3) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x210_ENDCAP);
      }
      if (this.APP.sltBeamSize.currentValue == 4) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x260_ENDCAP);
      }
      //FIXME: Bang - 2021.05.20: Xu ly loi khi tu TIMER -> SPC BEAM
      if (this.APP.sltBeamSize.currentValue > 4) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x110);
      }
    }
    //_TIMBER BEAM
    else if (this.APP.sltBeamType.currentValue == 1) {
      return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S150x50_ENDCAP);
    }
  }

  public getHouseBeamEndCap(): GeometryInfo {
    //_SUPERIOR BEAM
    if (this.APP.sltBeamType.currentValue == 0) {
      if (
        this.APP.sltHouseBeamSize.currentValue == 0 ||
        this.APP.sltHouseBeamSize.currentValue == 1
      ) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x110_ENDCAP);
      }
      if (this.APP.sltHouseBeamSize.currentValue == 2) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x160_ENDCAP);
      }
      if (this.APP.sltHouseBeamSize.currentValue == 3) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x210_ENDCAP);
      }
      if (this.APP.sltHouseBeamSize.currentValue == 4) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x260_ENDCAP);
      }
      //FIXME: Bang - 2021.05.20: Xu ly loi khi tu TIMER -> SPC BEAM
      if (this.APP.sltHouseBeamSize.currentValue > 4) {
        return this.utils.cloneGeometryInfo(this.SUPERIOR_BEAM.S65x110);
      }
    }
    //_TIMBER BEAM
    else if (this.APP.sltBeamType.currentValue == 1) {
      return this.utils.cloneGeometryInfo(this.TIMBER_BEAM.S150x50_ENDCAP);
    }
  }

  public getPost() {
    if (this.APP.sltColumnType.currentValue == 0) {
      return this.utils.cloneGeometryInfo(this.SUPERIOR_POST.S65x65);
    } else if (
      this.APP.sltColumnType.currentValue == 1 ||
      this.APP.sltColumnType.currentValue == 2
    ) {
      return this.utils.cloneGeometryInfo(this.SUPERIOR_POST.S90x90);
    } else {
      return this.utils.cloneGeometryInfo(this.SUPERIOR_POST.TIMBER);
    }
  }
  public getCenterPost() {
    if(UI.beamType == 1) {
      return this.utils.cloneGeometryInfo(this.SUPERIOR_POST.CENTER_POST_TIMBER)
    } else {
      return this.utils.cloneGeometryInfo(this.SUPERIOR_POST.S65x65)
    }
  }
  public getRoofSheetPanel(): GeometryInfo {
    if (
      this.APP.sltRoofSheetingType.currentValue == 0 ||
      this.APP.sltRoofSheetingType.currentValue == 1
    ) {
      if (
        this.APP.sltRoofThickness.currentValue == 0 ||
        this.APP.sltRoofThickness.currentValue == 1
      ) {
        return this.utils.cloneGeometryInfo(this.MONO_PANEL.S50MM);
      }
      if (this.APP.sltRoofThickness.currentValue == 2) {
        return this.utils.cloneGeometryInfo(this.MONO_PANEL.S75MM);
      }
      if (this.APP.sltRoofThickness.currentValue == 3) {
        return this.utils.cloneGeometryInfo(this.MONO_PANEL.S100MM);
      }
      if (this.APP.sltRoofThickness.currentValue == 4) {
        return this.utils.cloneGeometryInfo(this.MONO_PANEL.S125MM);
      }
      if (this.APP.sltRoofThickness.currentValue == 5) {
        return this.utils.cloneGeometryInfo(this.MONO_PANEL.S150MM);
      }
    } else if (this.APP.sltRoofSheetingType.currentValue == 2) {
      if (
        this.APP.sltRoofThickness.currentValue == 0 ||
        this.APP.sltRoofThickness.currentValue == 1
      ) {
        return this.utils.cloneGeometryInfo(this.CORRO_PANEL.S50MM);
      }
      if (this.APP.sltRoofThickness.currentValue == 2) {
        return this.utils.cloneGeometryInfo(this.CORRO_PANEL.S75MM);
      }
      if (this.APP.sltRoofThickness.currentValue == 3) {
        return this.utils.cloneGeometryInfo(this.CORRO_PANEL.S100MM);
      }
      if (this.APP.sltRoofThickness.currentValue == 4) {
        return this.utils.cloneGeometryInfo(this.CORRO_PANEL.S125MM);
      }
      if (this.APP.sltRoofThickness.currentValue == 5) {
        return this.utils.cloneGeometryInfo(this.CORRO_PANEL.S150MM);
      }
    }
    return null;
  }
  public getRoofBase(): GeometryInfo {
    if (
      this.APP.sltRoofSheetingType.currentValue == 0 ||
      this.APP.sltRoofSheetingType.currentValue == 1
    ) {
      if (
        this.APP.sltRoofThickness.currentValue == 0 ||
        this.APP.sltRoofThickness.currentValue == 1
      ) {
        return this.utils.cloneGeometryInfo(this.MONO_PANEL.S50MM_BASE);
      }
      if (this.APP.sltRoofThickness.currentValue == 2) {
        return this.utils.cloneGeometryInfo(this.MONO_PANEL.S75MM_BASE);
      }
      if (this.APP.sltRoofThickness.currentValue == 3) {
        return this.utils.cloneGeometryInfo(this.MONO_PANEL.S100MM_BASE);
      }
      if (this.APP.sltRoofThickness.currentValue == 4) {
        return this.utils.cloneGeometryInfo(this.MONO_PANEL.S125MM_BASE);
      }
      if (this.APP.sltRoofThickness.currentValue == 5) {
        return this.utils.cloneGeometryInfo(this.MONO_PANEL.S150MM_BASE);
      }
    } else if (this.APP.sltRoofSheetingType.currentValue == 2) {
      if (
        this.APP.sltRoofThickness.currentValue == 0 ||
        this.APP.sltRoofThickness.currentValue == 1
      ) {
        return this.utils.cloneGeometryInfo(this.CORRO_PANEL.S50MM_BASE);
      }
      if (this.APP.sltRoofThickness.currentValue == 2) {
        return this.utils.cloneGeometryInfo(this.CORRO_PANEL.S75MM_BASE);
      }
      if (this.APP.sltRoofThickness.currentValue == 3) {
        return this.utils.cloneGeometryInfo(this.CORRO_PANEL.S100MM_BASE);
      }
      if (this.APP.sltRoofThickness.currentValue == 4) {
        return this.utils.cloneGeometryInfo(this.CORRO_PANEL.S125MM_BASE);
      }
      if (this.APP.sltRoofThickness.currentValue == 5) {
        return this.utils.cloneGeometryInfo(this.CORRO_PANEL.S150MM_BASE);
      }
    }
    return null;
  }
  public getRoofBaseHeight(): number {
    const roofBase = this.getRoofBase()
    return _.get(roofBase, ['height'], 0)
  }
  public getBarge(): GeometryInfo {
    if (this.APP.sltDripBarge.currentValue) {
      if (
        this.APP.sltBargeType.currentValue == 0 ||
        this.APP.sltBargeType.currentValue == 1
      ) {
        if (
          this.APP.sltRoofThickness.currentValue == 0 ||
          this.APP.sltRoofThickness.currentValue == 1
        ) {
          return this.utils.cloneGeometryInfo(
            this.MONO_PANEL_DRIP_BARGE_CAPPING.S50MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 2) {
          return this.utils.cloneGeometryInfo(
            this.MONO_PANEL_DRIP_BARGE_CAPPING.S75MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 3) {
          return this.utils.cloneGeometryInfo(
            this.MONO_PANEL_DRIP_BARGE_CAPPING.S100MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 4) {
          return this.utils.cloneGeometryInfo(
            this.MONO_PANEL_DRIP_BARGE_CAPPING.S125MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 5) {
          return this.utils.cloneGeometryInfo(
            this.MONO_PANEL_DRIP_BARGE_CAPPING.S150MM
          );
        }
      } else if (this.APP.sltBargeType.currentValue == 2) {
        if (
          this.APP.sltRoofThickness.currentValue == 0 ||
          this.APP.sltRoofThickness.currentValue == 1
        ) {
          return this.utils.cloneGeometryInfo(
            this.CORRO_PANEL_DRIP_BARGE_CAPPING.S50MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 2) {
          return this.utils.cloneGeometryInfo(
            this.CORRO_PANEL_DRIP_BARGE_CAPPING.S75MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 3) {
          return this.utils.cloneGeometryInfo(
            this.CORRO_PANEL_DRIP_BARGE_CAPPING.S100MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 4) {
          return this.utils.cloneGeometryInfo(
            this.CORRO_PANEL_DRIP_BARGE_CAPPING.S125MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 5) {
          return this.utils.cloneGeometryInfo(
            this.CORRO_PANEL_DRIP_BARGE_CAPPING.S150MM
          );
        }
      }
    } else {
      if (
        this.APP.sltBargeType.currentValue == 0 ||
        this.APP.sltBargeType.currentValue == 1
      ) {
        if (
          this.APP.sltRoofThickness.currentValue == 0 ||
          this.APP.sltRoofThickness.currentValue == 1
        ) {
          return this.utils.cloneGeometryInfo(
            this.MONO_PANEL_BARGE_CAPPING.S50MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 2) {
          return this.utils.cloneGeometryInfo(
            this.MONO_PANEL_BARGE_CAPPING.S75MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 3) {
          return this.utils.cloneGeometryInfo(
            this.MONO_PANEL_BARGE_CAPPING.S100MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 4) {
          return this.utils.cloneGeometryInfo(
            this.MONO_PANEL_BARGE_CAPPING.S125MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 5) {
          return this.utils.cloneGeometryInfo(
            this.MONO_PANEL_BARGE_CAPPING.S150MM
          );
        }
      } else if (this.APP.sltBargeType.currentValue == 2) {
        if (
          this.APP.sltRoofThickness.currentValue == 0 ||
          this.APP.sltRoofThickness.currentValue == 1
        ) {
          return this.utils.cloneGeometryInfo(
            this.CORRO_PANEL_BARGE_CAPPING.S50MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 2) {
          return this.utils.cloneGeometryInfo(
            this.CORRO_PANEL_BARGE_CAPPING.S75MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 3) {
          return this.utils.cloneGeometryInfo(
            this.CORRO_PANEL_BARGE_CAPPING.S100MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 4) {
          return this.utils.cloneGeometryInfo(
            this.CORRO_PANEL_BARGE_CAPPING.S125MM
          );
        }
        if (this.APP.sltRoofThickness.currentValue == 5) {
          return this.utils.cloneGeometryInfo(
            this.CORRO_PANEL_BARGE_CAPPING.S150MM
          );
        }
      }
    }

    return null;
  }
  public getReceiverChannel(): GeometryInfo {
    if (
      this.APP.sltReceiverType.currentValue == 0 ||
      this.APP.sltReceiverType.currentValue == 1
    ) {
      if (
        this.APP.sltRoofThickness.currentValue == 0 ||
        this.APP.sltRoofThickness.currentValue == 1
      ) {
        return this.utils.cloneGeometryInfo(
          this.MONO_PANEL_RECIEVER_CHANEL.S50MM
        );
      }
      if (this.APP.sltRoofThickness.currentValue == 2) {
        return this.utils.cloneGeometryInfo(
          this.MONO_PANEL_RECIEVER_CHANEL.S75MM
        );
      }
      if (this.APP.sltRoofThickness.currentValue == 3) {
        return this.utils.cloneGeometryInfo(
          this.MONO_PANEL_RECIEVER_CHANEL.S100MM
        );
      }
      if (this.APP.sltRoofThickness.currentValue == 4) {
        return this.utils.cloneGeometryInfo(
          this.MONO_PANEL_RECIEVER_CHANEL.S125MM
        );
      }
      if (this.APP.sltRoofThickness.currentValue == 5) {
        return this.utils.cloneGeometryInfo(
          this.MONO_PANEL_RECIEVER_CHANEL.S150MM
        );
      }
    } else if (this.APP.sltReceiverType.currentValue == 2) {
      if (
        this.APP.sltRoofThickness.currentValue == 0 ||
        this.APP.sltRoofThickness.currentValue == 1
      ) {
        return this.utils.cloneGeometryInfo(
          this.CORRO_PANEL_RECIEVER_CHANEL.S50MM
        );
      }
      if (this.APP.sltRoofThickness.currentValue == 2) {
        return this.utils.cloneGeometryInfo(
          this.CORRO_PANEL_RECIEVER_CHANEL.S75MM
        );
      }
      if (this.APP.sltRoofThickness.currentValue == 3) {
        return this.utils.cloneGeometryInfo(
          this.CORRO_PANEL_RECIEVER_CHANEL.S100MM
        );
      }
      if (this.APP.sltRoofThickness.currentValue == 4) {
        return this.utils.cloneGeometryInfo(
          this.CORRO_PANEL_RECIEVER_CHANEL.S125MM
        );
      }
      if (this.APP.sltRoofThickness.currentValue == 5) {
        return this.utils.cloneGeometryInfo(
          this.CORRO_PANEL_RECIEVER_CHANEL.S150MM
        );
      }
    }
    return null;
  }
  public getGutter() {
    if (this.APP.sltGutterType.currentValue == 0) {
      return this.utils.cloneGeometryInfo(this.GUTTER.SQUARE_LINE);
    } else if (
      this.APP.sltGutterType.currentValue == 1 ||
      this.APP.sltGutterType.currentValue == 2
    ) {
      return this.utils.cloneGeometryInfo(this.GUTTER.QUAD_150);
    }
  }
  public getGuterEndCap(): GeometryInfo {
    if (this.APP.sltGutterType.currentValue == 0) {
      return this.utils.cloneGeometryInfo(this.GUTTER.SQUARE_LINE_END_CAP);
    } else if (
      this.APP.sltGutterType.currentValue == 1 ||
      this.APP.sltGutterType.currentValue == 2
    ) {
      return this.utils.cloneGeometryInfo(this.GUTTER.QUAD_150_END_CAP);
    }
  }
  public getRidge(): GeometryInfo {
    return this.utils.cloneGeometryInfo(this.RidgeCapping.S_Ridge_Custom);
    // if (
    //   this.APP.sltRoofPitch.currentValue == 2 ||
    //   this.APP.sltRoofPitch.currentValue == 3 ||
    //   this.APP.sltRoofPitch.currentValue == 5 ||
    //   this.APP.sltRoofPitch.currentValue == 7.5 ||
    //   this.APP.sltRoofPitch.currentValue == 10
    // ) {
    //   return this.utils.cloneGeometryInfo(this.RidgeCapping.S_Ridge_100);
    // } else if (this.APP.sltRoofPitch.currentValue == 12) {
    //   return this.utils.cloneGeometryInfo(this.RidgeCapping.S_Ridge_125);
    // } else if (this.APP.sltRoofPitch.currentValue == 15) {
    //   return this.utils.cloneGeometryInfo(this.RidgeCapping.S_Ridge_150);
    // } else if (this.APP.sltRoofPitch.currentValue == 20) {
    //   return this.utils.cloneGeometryInfo(this.RidgeCapping.S_Ridge_200);
    // } else if (this.APP.sltRoofPitch.currentValue == 22.5) {
    //   return this.utils.cloneGeometryInfo(this.RidgeCapping.S_Ridge_225);
    // } else if (this.APP.sltRoofPitch.currentValue == 25) {
    //   return this.utils.cloneGeometryInfo(this.RidgeCapping.S_Ridge_250);
    // }
  }
  public getDownPipe(): GeometryInfo {
    if (this.APP.sltDownpipeType.currentValue == 0) {
      return this.DOWNPIPE.PIPE_S90MM;
    }
    return null;
  }
  public getDownPipeL(): GeometryInfo {
    if (this.APP.sltDownpipeType.currentValue == 0) {
      return this.DOWNPIPE.L_S90MM;
    }
    return null;
  }
  public getZFlashing(): GeometryInfo {
    if (
      this.APP.sltRoofThickness.currentValue == 0 ||
      this.APP.sltRoofThickness.currentValue == 1
    ) {
      return this.utils.cloneGeometryInfo(this.Z_FlASHING.S50);
    }
    if (this.APP.sltRoofThickness.currentValue == 2) {
      return this.utils.cloneGeometryInfo(this.Z_FlASHING.S75);
    }
    if (this.APP.sltRoofThickness.currentValue == 3) {
      return this.utils.cloneGeometryInfo(this.Z_FlASHING.S100);
    }
    if (this.APP.sltRoofThickness.currentValue == 4) {
      return this.utils.cloneGeometryInfo(this.Z_FlASHING.S125);
    }
    if (this.APP.sltRoofThickness.currentValue == 5) {
      return this.utils.cloneGeometryInfo(this.Z_FlASHING.S150);
    }

    return null;
  }
  public getDimensionLine() {
    return this.utils.cloneGeometryInfo(this.DIMENSION.LINE);
  }
  public getDimensionCap() {
    return this.utils.cloneGeometryInfo(this.DIMENSION.CAP);
  }
  public getUIControlLine() {
    return this.utils.cloneGeometryInfo(this.UI_CONTROL.LINE);
  }
  public getUpstandBraket(part: number) {
    if (part == 1) {
      return this.utils.cloneGeometryInfo(this.UPSTAND_BRAKET.BRAKET_1);
    } else if (part == 2) {
      return this.utils.cloneGeometryInfo(this.UPSTAND_BRAKET.BRAKET_2);
    }
  }
}
