import { Scene, Mesh, BufferGeometry, Vector3, Object3D, Color, Material, MeshPhongMaterial, Plane, Geometry, LineSegments } from "three";
import { Util } from "./utils";
import { CONFIG, GEOMETRY_TYPE } from "src/app/app.config";
import { HomeComponent as AppComponent } from '../containers/home/home.component';
import { GeometryManager } from "./geometry.manager";
import { MaterialManager } from "./material.manager";
import { GeometryInfo, Printing2DLine, ViewType, LineType, Printing2DGeometry, Printing2DGeometryType } from './models';

export class EaveManager {
    private scene: Scene;
    private APP: AppComponent;
    private material: Material;
    private utils: Util;

    private geometryManager: GeometryManager;

    private geo_eaveWidth1: BufferGeometry;
    private geo_eaveWidth2: BufferGeometry;
    private geo_eaveLength1: GeometryInfo;

    public backOverhang = 100;

    //private matW1: Material;
    //private clippingPlaneW1: Plane;

    public listEave: Object3D[];

    constructor() {
        this.utils = new Util();
        this.geometryManager = GeometryManager.Instance();
        this.listEave = [];
    }

    public init(app: AppComponent): Promise<void>{
        return new Promise((resolve, reject) => {
            this.APP = app;
            this.scene = app.scene;
            this.material = MaterialManager.Instance().EAVE.clone();
            //this.matW1 = MaterialManager.Instance().EAVE.clone();

            app.sldBaySize.addAction(this.uiChanged.bind(this));
            app.sldSpan.addAction(this.uiChanged.bind(this));
            app.sldExistingWallHeight.addAction(this.uiChanged.bind(this));
            app.sldEaveWidth.addAction(this.uiChanged.bind(this));
            app.sldExistingLength.addAction(this.uiChanged.bind(this));
            app.sldExistingLength2.addAction(this.uiChanged.bind(this));
            app.sldExistingWidth1.addAction(this.uiChanged.bind(this));
            app.sldExistingWidth2.addAction(this.uiChanged.bind(this));
            app.sltExistingType.addAction(this.uiChanged.bind(this));
            app.sltStructureType.addAction(this.uiChanged.bind(this));

            resolve();
        });
    }
    public optimize() : Promise<void>{
        return new Promise((resolve, reject) => {
            
            let scaleX = CONFIG.eaveWidth.default/this.geometryManager.EAVE.EAVE.width;
            let scaleZ = CONFIG.span.default/this.geometryManager.EAVE.EAVE.length;
            
            let translateX = this.geometryManager.EAVE.EAVE.width/2;
            let translateY = this.geometryManager.EAVE.EAVE.height/2;
            let translateZ = this.geometryManager.EAVE.EAVE.length/2;

            
            this.geo_eaveWidth1 = this.geometryManager.EAVE.EAVE.geometry
            .clone()
            .translate(translateX,translateY,translateZ)

            this.geo_eaveWidth2 = this.geometryManager.EAVE.EAVE.geometry
            .clone()
            .translate(translateX,translateY,translateZ)
            

            let _scaleX = CONFIG.baySize.default/this.geometryManager.EAVE.EAVE.length;
            let _scaleZ = CONFIG.eaveWidth.default/this.geometryManager.EAVE.EAVE.width;

            this.geo_eaveLength1 = new GeometryInfo();
            this.geo_eaveLength1.geometry = this.geometryManager.EAVE.EAVE.geometry
            .clone()
            .rotateY(Math.PI/2)
            .translate(0,translateY,translateX)
            
            // this.geo_eaveLength1.length = env.baySize.default;
            // this.geo_eaveLength1.width = env.eaveWidth.default;

            resolve();
        });
    }
    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.EAVE));
            this.scene.remove(...this.scene.children.filter(x => x.userData.type == 'EAVE_OUTLINE'));
            this.listEave = [];

            // if(this.APP.sldExistingLength2.currentValue == 0){
            //     this.clippingPlaneW1 = new Plane(new Vector3(0,0,-1), this.APP.sldExistingWidth1.currentValue/2);
            //     this.matW1.clippingPlanes = [this.clippingPlaneW1];
            // }
            // else{
            //     this.matW1.clippingPlanes = [];
            // }

            if(this.APP.sltStructureType.currentValue == 4){
                resolve();
                return;
            }

            this.addEave({length1: true});
            if(this.APP.sldExistingLength2.currentValue > 0){
                this.addEave({length2: true});
            }
            if(this.APP.sldExistingWidth1.currentValue > 0){
                this.addEave({width1: true});
            }
            if(this.APP.sldExistingWidth2.currentValue > 0){
                this.addEave({width2: true});
            }

            //this.getOutLines();

            resolve();
        });
    }

    public getOutLines(): Printing2DGeometry{
        let objs = this.scene.children.filter(o => o.userData.type == GEOMETRY_TYPE.EAVE );
        let lsGeometries: Printing2DLine[] = [];

        for(let o of objs){            
            let outlineGeo = this.utils.getOutlineGeometryFromMesh((o as Mesh), 10);
            outlineGeo.translate(o.position.x, o.position.y + 5000, o.position.z);

            // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
            // line.userData = {type: "EAVE_OUTLINE"};
            // this.scene.add( line );

            let simplifiedGeo = this.simplifyGeo(outlineGeo);
            lsGeometries.push({ 
                objectType: o.userData.type,
                vertices: simplifiedGeo.vertices, 
                views: o.userData.views
            });
        }

        return { lines: lsGeometries, texts: [] };
    }
    public simplifyGeo(geo: BufferGeometry): Geometry{
        let simplifiedGeo = new Geometry();
        let vertices = geo.getAttribute('position').array;
        for(let i = 0; i < vertices.length; i+=3){
            simplifiedGeo.vertices.push(new Vector3(vertices[i], vertices[i+1] - 5000, vertices[i+2]));
        }
        return simplifiedGeo;
    }
  
    public addEave(userDataPos: any){
        let mesh: Mesh;
        let offsetY = this.APP.sldExistingWallHeight.currentValue;
        
        let backOverhangL1 = this.APP.existingWallManager.geo_existingWallL1.width + this.backOverhang;
        let backOverhangL2 = this.APP.existingWallManager.geo_existingWallL2.width + this.backOverhang;
        let backOverhangW1 = this.APP.existingWallManager.geo_existingWallW1.width + this.backOverhang;
        let backOverhangW2 = this.APP.existingWallManager.geo_existingWallW2.width + this.backOverhang;
        
        if(userDataPos.length1){
            //let mat = new MeshPhongMaterial({ transparent: true, opacity: 0.8});
            let offsetZ = this.APP.sldExistingWidth1.currentValue/2 + backOverhangL1;
            let scaleX = this.APP.sldExistingLength.currentValue/this.geometryManager.EAVE.EAVE.length;
            let scaleZ = (this.APP.sldEaveWidth.currentValue + backOverhangL1)/this.geometryManager.EAVE.EAVE.width;
            let views = [
                { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
            ];
            mesh = new Mesh(this.geo_eaveLength1.geometry, this.material);
            mesh.userData = { type: GEOMETRY_TYPE.EAVE, position: userDataPos, views: views };
            mesh.position.setY(offsetY);
            mesh.position.setZ(-offsetZ);
            mesh.scale.set(scaleX,1,scaleZ);
            this.scene.add(mesh);
            this.listEave.push(mesh);
        }
        else if(userDataPos.length2){
            let views = [
                // { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
                // { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
            ];

            if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                views.push({ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS });
                let offsetX = this.APP.sldExistingLength.currentValue/2;
                let offsetZ = this.APP.sldExistingWidth1.currentValue/2 - backOverhangL2;
                let scaleX = this.APP.sldExistingLength2.currentValue/this.geometryManager.EAVE.EAVE.length;
                let scaleZ = (this.APP.sldEaveWidth.currentValue + backOverhangL2)/this.geometryManager.EAVE.EAVE.width;
                
                mesh = new Mesh(this.geo_eaveLength1.geometry, this.material);
                mesh.userData = { type: GEOMETRY_TYPE.EAVE, position: userDataPos, views: views };
                mesh.position.setX(-offsetX - this.APP.sldExistingLength2.currentValue/2);
                mesh.position.setY(offsetY);
                mesh.position.setZ(offsetZ);
                mesh.scale.set(scaleX,1,scaleZ);
                this.scene.add(mesh);
                this.listEave.push(mesh);
            }
            if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                views.push({ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS });
                let offsetX = this.APP.sldExistingLength.currentValue/2;
                let offsetZ = this.APP.sldExistingWidth1.currentValue/2 - backOverhangL2;
                let scaleX = this.APP.sldExistingLength2.currentValue/this.geometryManager.EAVE.EAVE.length;
                let scaleZ = (this.APP.sldEaveWidth.currentValue + backOverhangL2)/this.geometryManager.EAVE.EAVE.width;
                
                mesh = new Mesh(this.geo_eaveLength1.geometry, this.material);
                mesh.userData = { type: GEOMETRY_TYPE.EAVE, position: userDataPos, views: views };
                mesh.position.setX(offsetX + this.APP.sldExistingLength2.currentValue/2);
                mesh.position.setY(offsetY);
                mesh.position.setZ(offsetZ);
                mesh.scale.set(scaleX,1,scaleZ);
                this.scene.add(mesh);
                this.listEave.push(mesh);
            }
        }
        else if(userDataPos.width1){
            //let mat = new MeshPhongMaterial({color: new Color('blue'), transparent: true, opacity: 0.8});
            let offsetX = this.APP.sldExistingLength.currentValue/2 + backOverhangW1;
            let offsetZ = this.APP.sldExistingWidth1.currentValue/2 + backOverhangL1;
            let scaleX = (this.APP.sldEaveWidth.currentValue + backOverhangW1)/this.geometryManager.EAVE.EAVE.width;
            let scaleZ = (this.APP.sldExistingWidth1.currentValue + this.APP.sldEaveWidth.currentValue + backOverhangL1)/this.geometryManager.EAVE.EAVE.length;

            if(this.APP.sldExistingLength2.currentValue <= 0){
                scaleZ = (this.APP.sldExistingWidth1.currentValue + backOverhangL1)/this.geometryManager.EAVE.EAVE.length;
                if(scaleZ <= 0)
                    scaleZ = 0.01;
            }

            let views = [
                { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS }
            ];

            if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_eaveWidth1, this.material);
                mesh.userData = { type: GEOMETRY_TYPE.EAVE, position: userDataPos, views: views };
                mesh.position.setX(-offsetX);
                mesh.position.setY(offsetY);
                mesh.position.setZ(-offsetZ);
                mesh.scale.set(scaleX,1,scaleZ);  
                this.scene.add(mesh);
                this.listEave.push(mesh);
            }
            if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_eaveWidth1, this.material);
                mesh.userData = { type: GEOMETRY_TYPE.EAVE, position: userDataPos, views: views };
                mesh.position.setX(offsetX);
                mesh.position.setY(offsetY);
                mesh.position.setZ(-offsetZ);
                mesh.scale.set(-scaleX,1,scaleZ);  
                this.scene.add(mesh);
                this.listEave.push(mesh);
            }
        }
        else if(userDataPos.width2){
            let offsetX = this.APP.sldExistingLength.currentValue/2 + this.APP.sldExistingLength2.currentValue + backOverhangW2;
            let offsetZ = this.APP.sldExistingWidth1.currentValue/2 - backOverhangL2;
            let scaleX = (this.APP.sldEaveWidth.currentValue + backOverhangW2)/this.geometryManager.EAVE.EAVE.width;
            let scaleZ = (this.APP.sldExistingWidth2.currentValue + backOverhangL2)/this.geometryManager.EAVE.EAVE.length;

            let views = [
                { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS }
            ];

            if(this.APP.sltExistingType.currentValue == 1 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_eaveWidth2, this.material);
                mesh.userData = { type: GEOMETRY_TYPE.EAVE, position: userDataPos, views: views };
                mesh.position.set(-offsetX, offsetY, offsetZ);
                mesh.scale.set(scaleX,1,scaleZ);  
                this.scene.add(mesh);
                this.listEave.push(mesh);
            }
            if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
                mesh = new Mesh(this.geo_eaveWidth2, this.material);
                mesh.userData = { type: GEOMETRY_TYPE.EAVE, position: userDataPos, views: views };
                mesh.position.set(offsetX, offsetY, offsetZ);
                mesh.scale.set(-scaleX,1,scaleZ);  
                this.scene.add(mesh);
                this.listEave.push(mesh);
            }
        }
    }

    public uiChanged(preVal: number, curVal): void {
        this.load();
    }
}
