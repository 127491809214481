import _ from "lodash";
import * as THREE from "three";
import {
  Box3,
  BoxBufferGeometry,
  BoxHelper,
  BufferGeometry,
  Color,
  EdgesGeometry,
  Float32BufferAttribute,
  Geometry,
  Group,
  Line3,
  LineBasicMaterial,
  LineSegments,
  Material,
  Matrix4,
  Mesh,
  Object3D,
  Plane,
  PlaneBufferGeometry,
  Shape,
  ShapeBufferGeometry,
  TextBufferGeometry,
  Vector2,
  Vector3,
} from "three";
import { CSG } from "three-csg-ts";
import {
  DIMENSION_LABEL_TYPE,
  GEOMETRY_CATEGORY,
  GEOMETRY_TYPE,
  PATIOS_ROOF_TYPE,
} from "../app.config";
import {
  BUILDING_SIDE,
  CONNECTION_TYPE,
  CUTOUT_ENABLE,
  EXISTING_BUILDING_CONFIG,
  OBJECT_TYPE,
  RAKECUT_TYPE,
} from "../app.constants";
import { HomeComponent } from "../containers/home";
import { GeometryManager } from "./geometry.manager";
import { MaterialManager } from "./material.manager";
import {
  ColorShape,
  GeometryInfo,
  Printing2DLine,
  Printing3DGeometry,
  ViewType,
} from "./models";
import SelectionManager from "./selection.manager";
import { UI } from "./ui";
import { Print2DView } from "./models";
import { PolygonUtils } from "./polygonUtils";
import * as mathjs from "mathjs";
import { BeamCapAndJointInfo } from "./patios.manager";
export class Util {
  private lineArrowGeo: GeometryInfo;
  private geo_gutterBack: GeometryInfo;
  constructor() {
    this.lineArrowGeo = new GeometryInfo();
    this.geo_gutterBack = new GeometryInfo();
    this.lineArrowGeo.geometry = new PlaneBufferGeometry(100, 30);
    this.lineArrowGeo.geometry.translate(50, 0, 0);
    this.lineArrowGeo.width = 30;
    this.lineArrowGeo.length = 100;
  }
  public getGeometryLength(
    geometry: THREE.BufferGeometry,
    axis: THREE.Vector3
  ): number {
    geometry.computeBoundingBox();
    let box = geometry.boundingBox;
    if (axis.x > 0) {
      return box.max.x - box.min.x;
    } else if (axis.y > 0) {
      return box.max.y - box.min.y;
    } else {
      return box.max.z - box.min.z;
    }
  }
  public getMeshLength(mesh: THREE.Mesh, axis: THREE.Vector3): number {
    let box = new Box3().setFromObject(mesh);
    if (axis.x > 0) {
      return box.max.x - box.min.x;
    } else if (axis.y > 0) {
      return box.max.y - box.min.y;
    } else {
      return box.max.z - box.min.z;
    }
  }
  public convertHexColorToRgb(hex): ColorShape {
    const color: ColorShape = {
      r: 0,
      g: 0,
      b: 0,
    };

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      color.r = parseInt(result[1], 16) / 255;
      color.g = parseInt(result[2], 16) / 255;
      color.b = parseInt(result[3], 16) / 255;
    }

    return color;
  }
  public getRoofCoverPolygonForFBRoof() {
    const totalRoofLength =
      UI.span + UI.multiSpan + UI.overhangLeft + UI.overhangRight;
    const totalRoofWidth =
      UI.totalBayLength + UI.overhangFront + UI.overhangBack;

    const minX = -(UI.span + UI.multiSpan) / 2 - UI.overhangLeft;
    const minZ = 0;

    const frontLeft = new Vector3(minX, 0, minZ + totalRoofWidth);
    const frontRight = new Vector3(
      minX + totalRoofLength,
      0,
      minZ + totalRoofWidth
    );
    const backLeft = new Vector3(minX, 0, minZ);
    const backRight = new Vector3(minX + totalRoofLength, 0, minZ);

    let lineFrontLeft = [frontLeft];
    if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
      lineFrontLeft = [
        new Vector3(minX, 0, minZ + totalRoofWidth - UI.rakeCutLeftVer),
        new Vector3(minX + UI.rakeCutLeftHor, 0, minZ + totalRoofWidth),
      ];
    } else if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      lineFrontLeft = [
        new Vector3(minX, 0, minZ + totalRoofWidth - UI.rakeCutLeftVer),
        new Vector3(
          minX + UI.rakeCutLeftHor,
          0,
          minZ + totalRoofWidth - UI.rakeCutLeftVer
        ),
        new Vector3(minX + UI.rakeCutLeftHor, 0, minZ + totalRoofWidth),
      ];
    }

    let lineFrontRight = [frontRight];
    if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
      lineFrontRight = [
        new Vector3(
          minX + totalRoofLength - UI.rakeCutRightHor,
          0,
          minZ + totalRoofWidth
        ),
        new Vector3(
          minX + totalRoofLength,
          0,
          minZ + totalRoofWidth - UI.rakeCutRightVer
        ),
      ];
    } else if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      lineFrontRight = [
        new Vector3(
          minX + totalRoofLength - UI.rakeCutRightHor,
          0,
          minZ + totalRoofWidth
        ),
        new Vector3(
          minX + totalRoofLength - UI.rakeCutRightHor,
          0,
          minZ + totalRoofWidth - UI.rakeCutRightVer
        ),
        new Vector3(
          minX + totalRoofLength,
          0,
          minZ + totalRoofWidth - UI.rakeCutRightVer
        ),
      ];
    }
    let lineFront = [...lineFrontLeft, ...lineFrontRight];

    let lineBackLeft = [backLeft];
    let lineBackRight = [backRight];
    let cutoutLength = UI.existingLength2;
    if (
      UI.structureType == CONNECTION_TYPE.FLY_OVER ||
      UI.structureType == CONNECTION_TYPE.BLACK_FLY_OVER
    ) {
      if (UI.existingType == BUILDING_SIDE.BOTH) {
        if (UI.existingWidth2 > 0) {
          cutoutLength =
            UI.existingLength2 -
            HomeComponent.ins.existingWallManager.geo_existingWallL2.width;
        } else {
          cutoutLength =
            UI.existingLength2 -
            HomeComponent.ins.existingWallManager.geo_existingWallL2.width * 2 -
            EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
        }
      }
    }
    if (UI.cutOutType == CUTOUT_ENABLE.YES) {
      if (
        UI.existingType == BUILDING_SIDE.LEFT ||
        UI.existingType == BUILDING_SIDE.BOTH
      ) {
        lineBackLeft = [
          new Vector3(minX + cutoutLength, 0, minZ),
          new Vector3(minX + cutoutLength, 0, minZ + UI.existingWidth1),
          new Vector3(minX, 0, minZ + UI.existingWidth1),
        ];
      }
      if (
        UI.existingType == BUILDING_SIDE.RIGHT ||
        UI.existingType == BUILDING_SIDE.BOTH
      ) {
        lineBackRight = [
          new Vector3(minX + totalRoofWidth, 0, minZ + UI.existingWidth1),
          new Vector3(
            minX + totalRoofWidth - cutoutLength,
            0,
            minZ + UI.existingWidth1
          ),
          new Vector3(minX + totalRoofWidth - cutoutLength, 0, minZ),
        ];
      }
    }

    let lineBack = [...lineBackRight, ...lineBackLeft];

    return [...lineFront, ...lineBack];
  }
  public getRoofCoverPolygonForLRRoof() {
    const totalRoofLength =
      UI.span + UI.multiSpan + UI.overhangFront + UI.overhangBack;
    const totalRoofWidth =
      UI.totalBayLength + UI.overhangLeft + UI.overhangRight;

    const minX = -UI.totalBayLength / 2 - UI.overhangLeft;
    const minZ = 0;

    const frontLeft = new Vector3(minX, 0, minZ + totalRoofLength);
    const frontRight = new Vector3(
      minX + totalRoofWidth,
      0,
      minZ + totalRoofLength
    );
    const backLeft = new Vector3(minX, 0, minZ);
    const backRight = new Vector3(minX + totalRoofWidth, 0, minZ);

    let lineFrontLeft = [frontLeft];
    if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
      lineFrontLeft = [
        new Vector3(minX, 0, minZ + totalRoofLength - UI.rakeCutLeftVer),
        new Vector3(minX + UI.rakeCutLeftHor, 0, minZ + totalRoofLength),
      ];
    } else if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      lineFrontLeft = [
        new Vector3(minX, 0, minZ + totalRoofLength - UI.rakeCutLeftVer),
        new Vector3(
          minX + UI.rakeCutLeftHor,
          0,
          minZ + totalRoofLength - UI.rakeCutLeftVer
        ),
        new Vector3(minX + UI.rakeCutLeftHor, 0, minZ + totalRoofLength),
      ];
    }

    let lineFrontRight = [frontRight];
    if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
      lineFrontRight = [
        new Vector3(
          minX + totalRoofWidth - UI.rakeCutRightHor,
          0,
          minZ + totalRoofLength
        ),
        new Vector3(
          minX + totalRoofWidth,
          0,
          minZ + totalRoofLength - UI.rakeCutRightVer
        ),
      ];
    } else if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      lineFrontRight = [
        new Vector3(
          minX + totalRoofWidth - UI.rakeCutRightHor,
          0,
          minZ + totalRoofLength
        ),
        new Vector3(
          minX + totalRoofWidth - UI.rakeCutRightHor,
          0,
          minZ + totalRoofLength - UI.rakeCutRightVer
        ),
        new Vector3(
          minX + totalRoofWidth,
          0,
          minZ + totalRoofLength - UI.rakeCutRightVer
        ),
      ];
    }
    let lineFront = [...lineFrontLeft, ...lineFrontRight];

    let lineBackLeft = [backLeft];
    let lineBackRight = [backRight];
    let cutoutLength = UI.existingLength2;
    let cutOutWidth = UI.existingWidth1;
    if (
      UI.structureType == CONNECTION_TYPE.FLY_OVER ||
      UI.structureType == CONNECTION_TYPE.BLACK_FLY_OVER
    ) {
      if (UI.existingType == BUILDING_SIDE.BOTH) {
        if (UI.existingWidth2 > 0) {
          cutoutLength =
            UI.existingLength2 -
            HomeComponent.ins.existingWallManager.geo_existingWallL2.width;
        } else {
          cutoutLength =
            UI.existingLength2 -
            HomeComponent.ins.existingWallManager.geo_existingWallL2.width * 2 -
            EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
        }
      }
    } else if (UI.structureType == CONNECTION_TYPE.FASCIA) {
      const shouldSnap =
        HomeComponent.ins.uiManager.calcShouldSnapRoofToExistingWhenCutout();
      if (shouldSnap) {
        cutoutLength = UI.existingLength2 + UI.eaveWidth;
        cutOutWidth = UI.existingWidth1 - UI.eaveWidth;
      }
    }
    if (UI.cutOutType == CUTOUT_ENABLE.YES) {
      if (
        UI.existingType == BUILDING_SIDE.LEFT ||
        UI.existingType == BUILDING_SIDE.BOTH
      ) {
        lineBackLeft = [
          new Vector3(minX + cutoutLength, 0, minZ),
          new Vector3(minX + cutoutLength, 0, minZ + cutOutWidth),
          new Vector3(minX, 0, minZ + cutOutWidth),
        ];
      }
      if (
        UI.existingType == BUILDING_SIDE.RIGHT ||
        UI.existingType == BUILDING_SIDE.BOTH
      ) {
        lineBackRight = [
          new Vector3(minX + totalRoofWidth, 0, minZ + cutOutWidth),
          new Vector3(
            minX + totalRoofWidth - cutoutLength,
            0,
            minZ + cutOutWidth
          ),
          new Vector3(minX + totalRoofWidth - cutoutLength, 0, minZ),
        ];
      }
    }

    let lineBack = [...lineBackRight, ...lineBackLeft];

    return [...lineFront, ...lineBack];
  }
  private drawBoundingBox(object: THREE.Object3D): void {
    // geometry.computeBoundingBox();
    //   var box = geometry.boundingBox;
    // let cubeGeo = new THREE.BoxGeometry(10, 10, 10);
    // let cube = new THREE.Mesh(cubeGeo, new THREE.MeshBasicMaterial({ color: 0x74d00b }));
    // cube.position.set(box.min.x, box.min.y, box.min.z);
    // this.scene.add(cube);
    // cube = new THREE.Mesh(cubeGeo, new THREE.MeshBasicMaterial({ color: 0x74d00b }));
    // cube.position.set(box.max.x, box.max.y, box.max.z);
    // this.scene.add(cube);
  }
  private fitCameraToSelection(camera, controls, selection, fitOffset = 1.2) {
    const box = new Box3();

    for (const object of selection) box.expandByObject(object);

    const size = box.getSize(new Vector3());
    const center = box.getCenter(new Vector3());

    const maxSize = Math.max(size.x, size.y, size.z);
    const fitHeightDistance =
      maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
    const fitWidthDistance = fitHeightDistance / camera.aspect;
    const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

    const direction = controls.target
      .clone()
      .sub(camera.position)
      .normalize()
      .multiplyScalar(distance);

    controls.maxDistance = distance * 10;
    controls.target.copy(center);

    camera.near = distance / 100;
    camera.far = distance * 100;
    camera.updateProjectionMatrix();

    camera.position.copy(controls.target).sub(direction);

    controls.update();
  }
  fitCameraToObject(camera, object, offset, controls) {
    offset = offset || 1.25;

    const boundingBox = new THREE.Box3();

    // get bounding box of object - this will be used to setup controls and camera
    boundingBox.setFromObject(object);

    let center: THREE.Vector3;
    boundingBox.getCenter(center);
    let size: THREE.Vector3;
    boundingBox.getSize(size);

    // get the max side of the bounding box (fits to width OR height as needed )
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = camera.fov * (Math.PI / 180);
    let cameraZ = Math.abs((maxDim / 4) * Math.tan(fov * 2));

    cameraZ *= offset; // zoom out a little so that objects don't fill the screen

    camera.position.z = cameraZ;

    const minZ = boundingBox.min.z;
    const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

    camera.far = cameraToFarEdge * 3;
    camera.updateProjectionMatrix();

    if (controls) {
      // set camera to rotate around center of loaded object
      controls.target = center;

      // prevent camera from zooming out far enough to create far plane cutoff
      controls.maxDistance = cameraToFarEdge * 2;

      controls.saveState();
    } else {
      camera.lookAt(center);
    }
  }
  degreesToRadians(degrees: number) {
    var pi = Math.PI;
    return this.round(degrees * (pi / 180));
  }
  round(num: number): number {
    return parseFloat(num.toFixed(3));
  }
  sin(degrees: number): number {
    return this.round(Math.sin(this.degreesToRadians(degrees)));
  }
  cos(degrees: number): number {
    return this.round(Math.cos(this.degreesToRadians(degrees)));
  }
  tan(degrees: number): number {
    return this.round(Math.tan(this.degreesToRadians(degrees)));
  }
  public createArrowLine(views: Print2DView[]): THREE.Line {
    let origin = new Vector3(0, 0, 0);
    let head = new Vector3(0, 0, 1000);
    let hand1 = new Vector3(-50, 0, 950);
    let hand2 = new Vector3(50, 0, 950);

    let geoLine = new Geometry();
    geoLine.vertices.push(origin);
    geoLine.vertices.push(head);

    geoLine.vertices.push(head);
    geoLine.vertices.push(hand1);

    geoLine.vertices.push(hand1);
    geoLine.vertices.push(hand2);

    geoLine.vertices.push(hand2);
    geoLine.vertices.push(head);

    let dim = new THREE.Line(geoLine);
    dim.userData = { type: "Arrow", views: views };

    return dim;
  }
  public createText(
    textStr: string,
    views: Print2DView[],
    textParameter,
    position: Vector3
  ): Mesh {
    let textGeo = new TextBufferGeometry(textStr, textParameter);
    textGeo.center();

    let text = new Mesh(textGeo, MaterialManager.Instance().DIMENSION_TEXT);
    text.userData = {
      type: GEOMETRY_TYPE.DIMENSION_TEXT,
      value: textStr,
      views: views,
      rotation: new Vector3(0, 0, 0),
    };
    text.position.setZ(position.z);
    text.position.setX(position.x);

    return text;
  }
  getOutlineGeometry(
    geo: Geometry | BufferGeometry,
    threshold: number
  ): EdgesGeometry {
    return new EdgesGeometry(geo, threshold);
  }
  getOutlineGeometryFromMesh(mesh: Mesh, threshold: number) {
    let geo = mesh.geometry.clone();
    geo.scale(mesh.scale.x, mesh.scale.y, mesh.scale.z);
    return new EdgesGeometry(geo, threshold);
  }
  getOutlineGeometryFromMeshNoScale(mesh: Mesh, threshold: number) {
    let geo = mesh.geometry.clone();
    //geo.scale(mesh.scale.x, mesh.scale.y, mesh.scale.z);
    return new EdgesGeometry(geo, threshold);
  }
  clipOutline(geo: EdgesGeometry, plane: Plane): BufferGeometry {
    let vertices = geo.getAttribute("position").array;
    let newVertices = [];

    let intersectPoints: Vector3[] = [];

    for (let i = 0; i < vertices.length; i += 6) {
      let v1 = new Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
      let v2 = new Vector3(vertices[i + 3], vertices[i + 4], vertices[i + 5]);

      let line = new Line3(v1, v2);
      let intersectPoint = new Vector3();
      let rs = plane.intersectLine(line, intersectPoint);

      let dis1 = plane.distanceToPoint(v1);
      let dis2 = plane.distanceToPoint(v2);

      if (dis1 < 0 && dis2 < 0) continue;

      if (rs) {
        if (dis1 > 0) {
          newVertices.push(v1);
        } else {
          newVertices.push(v2);
        }

        newVertices.push(intersectPoint);
        intersectPoints.push(intersectPoint);
      } else {
        newVertices.push(v1, v2);
      }
    }

    for (let i = 0; i < intersectPoints.length - 1; i++) {
      newVertices.push(intersectPoints[i]);
      newVertices.push(intersectPoints[i + 1]);
    }

    return new BufferGeometry().setFromPoints(newVertices);
  }
  clipOutlineBasePanel(geo: EdgesGeometry, plane: Plane): BufferGeometry {
    let vertices = geo.getAttribute("position").array;
    let newVertices = [];

    let intersectPoints: Vector3[] = [];

    for (let i = 0; i < vertices.length; i += 6) {
      let v1 = new Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
      let v2 = new Vector3(vertices[i + 3], vertices[i + 4], vertices[i + 5]);

      let line = new Line3(v1, v2);
      let intersectPoint = new Vector3();
      let rs = plane.intersectLine(line, intersectPoint);

      let dis1 = plane.distanceToPoint(v1);
      let dis2 = plane.distanceToPoint(v2);

      if (dis1 < 0 && dis2 < 0) continue;

      if (rs) {
        if (dis1 > 0) {
          newVertices.push(v1);
        } else {
          newVertices.push(v2);
        }

        newVertices.push(intersectPoint);
        intersectPoints.push(intersectPoint);
      } else {
        newVertices.push(v1, v2);
      }
    }

    if (HomeComponent.ins.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF) {
      if (intersectPoints.length === 4) {
        intersectPoints = this.createPolygon(intersectPoints);
      }
    }
    for (let i = 0; i < intersectPoints.length - 1; i++) {
      newVertices.push(intersectPoints[i]);
      newVertices.push(intersectPoints[i + 1]);
    }

    return new BufferGeometry().setFromPoints(newVertices);
  }
  public createPolygon(initPoints) {
    if (initPoints.length > 3) {
      let points = this.sortPoints(
        initPoints,
        initPoints[0].x !== initPoints[1].x ? "x" : "z"
      );

      let polygon = [...points.slice(0, 3)];
      points.splice(0, 3);
      this.findNextPoinst(polygon, points, 2);
      return polygon;
    } else {
      return initPoints;
    }
  }
  public findNextPoinst(polygon, restOfPoints, indexOfMaxPoint) {
    if (restOfPoints.length === 0) {
      return;
    }
    let r1 = [
      polygon[indexOfMaxPoint],
      polygon[indexOfMaxPoint === polygon.length - 1 ? 0 : indexOfMaxPoint + 1],
    ];
    let r2 = [polygon[indexOfMaxPoint - 1], polygon[indexOfMaxPoint]];

    let simpleMaxPolygon = [
      polygon[indexOfMaxPoint - 1],
      polygon[indexOfMaxPoint],
      polygon[indexOfMaxPoint === polygon.length - 1 ? 0 : indexOfMaxPoint + 1],
    ];

    let nextPoint = restOfPoints.splice(0, 1)[0];
    let closestPoint = PolygonUtils.getClosestPointInsidePolygon(
      simpleMaxPolygon,
      nextPoint
    );

    let isPointOnSegmentR1 = PolygonUtils.isPointOnSegment(
      r1 as [Vector3, Vector3],
      closestPoint
    );
    let isPointOnSegmentR2 = PolygonUtils.isPointOnSegment(
      r2 as [Vector3, Vector3],
      closestPoint
    );

    if (!(isPointOnSegmentR1 && isPointOnSegmentR2)) {
      if (isPointOnSegmentR1) {
        polygon.splice(indexOfMaxPoint + 1, 0, nextPoint);
        this.findNextPoinst(polygon, restOfPoints, indexOfMaxPoint + 1);
      }
      if (isPointOnSegmentR2) {
        polygon.splice(indexOfMaxPoint, 0, nextPoint);
        this.findNextPoinst(polygon, restOfPoints, indexOfMaxPoint);
      }
    } else {
      let r1_1 = [r1[0], nextPoint];
      let r1_2 = [r1[1], nextPoint];

      let inter1 = mathjs.intersect(
        this.vector3ToArray(r1_1[0]),
        this.vector3ToArray(r1_1[1]),
        this.vector3ToArray(r2[0]),
        this.vector3ToArray(r2[1])
      );
      let inter2 = mathjs.intersect(
        this.vector3ToArray(r1_2[0]),
        this.vector3ToArray(r1_2[1]),
        this.vector3ToArray(r2[0]),
        this.vector3ToArray(r2[1])
      );

      if (
        inter1 &&
        inter2 &&
        PolygonUtils.isPointOnSegment(
          r2 as [Vector3, Vector3],
          this.arrayToVector3(inter1)
        ) &&
        PolygonUtils.isPointOnSegment(
          r2 as [Vector3, Vector3],
          this.arrayToVector3(inter2)
        )
      ) {
        // Add point in r2
        polygon.splice(indexOfMaxPoint, 0, nextPoint);
        this.findNextPoinst(polygon, restOfPoints, indexOfMaxPoint);
      } else {
        // Add point in r1
        polygon.splice(indexOfMaxPoint + 1, 0, nextPoint);
        this.findNextPoinst(polygon, restOfPoints, indexOfMaxPoint + 1);
      }
    }
  }
  public vector3ToArray(vector) {
    return [vector.x, vector.y, vector.z];
  }
  public arrayToVector3(array) {
    return new Vector3(array[0], array[1], array[2]);
  }
  public sortPoints(points, direction) {
    let clonePoints = [...points];
    clonePoints.sort((a, b) => {
      if (a[direction] !== b[direction]) {
        return a[direction] - b[direction];
      } else {
        return a.y - b.y;
      }
    });

    return clonePoints;
  }
  public getOutlineRafterBeam(mesh: Mesh, lines: Printing2DLine[]) {
    let views = mesh.userData.views;

    mesh.updateWorldMatrix(true, true);
    let meshClone = new Mesh((mesh as Mesh).geometry);

    let box = new BoxHelper(meshClone);
    box.applyMatrix4(mesh.matrixWorld);

    box.updateWorldMatrix(true, true);
    let _geo = box["geometry"].clone() as BufferGeometry;
    _geo.applyMatrix4(box.matrixWorld);

    let positions: any = _geo.attributes["position"].array;
    let boxGeo = new BufferGeometry().setAttribute(
      "position",
      new Float32BufferAttribute(positions.slice(0, positions.length / 2), 3)
    );

    this.getOutlineByViews(mesh, boxGeo, lines, views);
  }
  public getOutlineByViews(
    group: THREE.Object3D,
    boxGeo: BufferGeometry,
    lines: Printing2DLine[],
    views
  ) {
    let mat = (group as Mesh).material as Material;
    let outLineClip = boxGeo;
    if (mat.clippingPlanes && mat.clippingPlanes.length > 0) {
      for (let i = 0; i < mat.clippingPlanes.length; i++) {
        let clipPlane = mat.clippingPlanes[i].clone() as Plane;

        outLineClip = this.clipOutline(outLineClip, clipPlane);
      }
    }

    lines.push({
      vertices: this.getVerticeFromBufferGeo(outLineClip),
      views: views,
      objectType: group.userData?.type,
    });
  }
  public createOutline(
    mesh: Mesh,
    lines: Printing2DLine[],
    viewMesh?: Print2DView[]
  ) {
    mesh.updateWorldMatrix(true, true);
    let views = mesh.userData.views;
    if (viewMesh) {
      views = viewMesh;
    }
    //Side view
    let data = this.getEngineeringObjetOutline(mesh, views);

    lines.push(data.line as Printing2DLine);
  }
  public getEngineeringObjetOutline(
    obj: Object3D,
    views: Print2DView[],
    isLineSegment?: boolean
  ) {
    obj.updateWorldMatrix(true, true);

    let _geo = obj["geometry"].clone() as BufferGeometry;
    _geo.applyMatrix4(obj.matrixWorld);

    let outline = this.createOutLineGeo(_geo, null, null);

    let objectType = "";
    if (obj.userData && obj.userData.type) {
      objectType = obj.userData.type;
    }

    let mat = new LineBasicMaterial({ color: 0x000000 });
    let outLineMesh = new LineSegments(outline, mat);
    outLineMesh.userData = { type: objectType };

    return {
      line: {
        vertices: this.getVerticeFromBufferGeo(outline),
        views,
        objectType: obj.userData?.type,
      },
      mesh: outLineMesh,
    };
  }
  public createOutLineGeo(geo, plane, thresholdAngle?: number) {
    let outLine: EdgesGeometry = new EdgesGeometry(geo);

    if (thresholdAngle) {
      outLine = new EdgesGeometry(geo, thresholdAngle);
    }
    return outLine;
  }
  public getVerticeFromBufferGeo(geo: BufferGeometry) {
    let positions = geo.attributes["position"].array;
    let vertices: Vector3[] = [];
    for (let i = 0; i < positions.length; i += 3) {
      const vertex = new Vector3(
        positions[i],
        positions[i + 1],
        positions[i + 2]
      );
      vertices.push(vertex);
    }

    return vertices;
  }
  public getStartEndPoint(box: Box3): Vector3[] {
    let maxHeight = 0;
    let p1: Vector3;
    let p2: Vector3;

    if (box.max.x - box.min.x > maxHeight) {
      maxHeight = box.max.x - box.min.x;

      p1 = new Vector3(
        box.min.x,
        (box.max.y + box.min.y) / 2,
        (box.max.z + box.min.z) / 2
      );
      p2 = new Vector3(
        box.max.x,
        (box.max.y + box.min.y) / 2,
        (box.max.z + box.min.z) / 2
      );
    }
    if (box.max.y - box.min.y > maxHeight) {
      maxHeight = box.max.y - box.min.y;

      p1 = new Vector3(
        (box.max.x + box.min.x) / 2,
        box.min.y,
        (box.max.z + box.min.z) / 2
      );
      p2 = new Vector3(
        (box.max.x + box.min.x) / 2,
        box.max.y,
        (box.max.z + box.min.z) / 2
      );
    }
    if (box.max.z - box.min.z > maxHeight) {
      p1 = new Vector3(
        (box.max.x + box.min.x) / 2,
        (box.max.y + box.min.y) / 2,
        box.min.z
      );
      p2 = new Vector3(
        (box.max.x + box.min.x) / 2,
        (box.max.y + box.min.y) / 2,
        box.max.z
      );
    }

    return [p1, p2];
  }
  public getDimensionValue(value: number) {
    //return parseFloat(`${value/1000}`).toFixed(2) + 'm';
    return Math.round(value).toString(); //parseFloat(`${value/1000}`).toFixed(3);
  }

  private getAllMesh(parent: Object3D): Printing3DGeometry[] {
    let lsMesh: Printing3DGeometry[] = [];
    for (let _mesh of parent.children) {
      if (_mesh.type !== "Mesh" && _mesh.type !== "Group") {
        continue;
      }

      if (_mesh.type === "Mesh") {
        let mesh = _mesh as Mesh;
        mesh.updateWorldMatrix(true, true);

        if (mesh.geometry.type === "BufferGeometry") {
          let geo = new Geometry().fromBufferGeometry(
            mesh.geometry as BufferGeometry
          );
          geo.applyMatrix4(mesh.matrixWorld);
          lsMesh.push({
            vertices: geo.vertices,
            faces: geo.faces.map((f) => ({ a: f.a, b: f.b, c: f.c })),
          });
        } else if (mesh.geometry.type === "Geometry") {
          let geo = mesh.geometry as Geometry;
          geo.applyMatrix4(mesh.matrixWorld);
          lsMesh.push({
            vertices: geo.vertices,
            faces: geo.faces.map((f) => ({ a: f.a, b: f.b, c: f.c })),
          });
        }
      }

      if (_mesh.children && _mesh.children.length > 0) {
        lsMesh.push(...this.getAllMesh(_mesh));
      }
    }

    return lsMesh;
  }
  public cloneGeometryInfo(original): GeometryInfo {
    let newGeoInfo = new GeometryInfo();
    newGeoInfo.geometry = original.geometry.clone();
    newGeoInfo.width = original.width;
    newGeoInfo.length = original.length;
    newGeoInfo.height = original.height;
    newGeoInfo.name = original.name;
    return newGeoInfo;
  }
  public getHeightByAngle(
    height: number,
    length: number,
    angle: number,
    cons: number
  ) {
    let allow = cons;
    if (UI.structureType == 5 && UI.isUpFasciaUpstandardBracket) {
      allow = -cons;
    }
    return height + this.tan(angle) * length * allow;
  }
  public getHeightByAngle2(
    height: number,
    length: number,
    angle: number,
    cons: number
  ) {
    return height + (length / this.tan(angle)) * cons;
  }
  public getHypotenuseByCos(length, angle) {
    return length / this.cos(angle);
  }
  public getSceneBox() {
    let left1 = UI.existingLength1 / 2;
    let right1 = UI.existingLength1 / 2;
    let back1 = UI.existingWidth1 / 2 + 1000;
    let front1 = UI.existingWidth1 / 2;

    let leftCondition =
      UI.existingType == BUILDING_SIDE.LEFT ||
      UI.existingType == BUILDING_SIDE.BOTH;
    let rightCondition =
      UI.existingType == BUILDING_SIDE.RIGHT ||
      UI.existingType == BUILDING_SIDE.BOTH;
    let eave = 1500 - UI.eaveWidth;

    if (UI.existingWidth1 > 0) {
      if (leftCondition) {
        left1 += eave;
      }
      if (rightCondition) {
        right1 += eave;
      }
    }
    if (UI.existingLength2 > eave) {
      if (leftCondition) {
        left1 += UI.existingLength2 - eave;
      }
      if (rightCondition) {
        right1 += UI.existingLength2 - eave;
      }
    }
    if (UI.existingWidth2 > 0) {
      if (leftCondition) {
        left1 += eave;
      }
      if (rightCondition) {
        right1 += eave;
      }
    }

    //Front
    if (front1 < UI.eaveWidth) {
      front1 = UI.eaveWidth - UI.existingWidth1;
    }
    if (UI.existingType != 0) {
      if (UI.existingLength2 > 0) {
        front1 += UI.eaveWidth;
      }
      if (UI.existingWidth2 > 0) {
        front1 += UI.existingWidth2;
        front1 -= UI.eaveWidth;
      }
    }

    let point1 = new Vector3(-left1, 0, front1);
    let point2 = new Vector3(right1, 5000, -back1);

    let box1 = new Box3().setFromPoints([point1, point2]);

    let existingHousetLeft = UI.totalBayLength / 2 + UI.overhangLeft;
    let existingHouseRight = UI.totalBayLength / 2 + UI.overhangRight;
    let existingHouseBack = UI.existingWidth1 / 2 + 1000;
    let existingHouseFront =
      UI.span + UI.multiSpan + UI.overhangFront - UI.existingWidth1 / 2;

    let diff = UI.existingLength1 - UI.totalBayLength;
    let translate = 0;
    if (UI.existingType == BUILDING_SIDE.LEFT) {
      translate = -diff / 2 + UI.overhangLeft;
    } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
      translate = diff / 2 - UI.overhangRight;
    } else {
      translate = 0;
    }

    let point3 = new Vector3(-existingHousetLeft, 0, existingHouseFront);
    let point4 = new Vector3(existingHouseRight, 5000, -existingHouseBack);

    let box2 = new Box3().setFromPoints([point3, point4]);
    box2.translate(new Vector3(translate, 0, 0));

    return box1.union(box2);
  }
  public getAllMeshDescendant(parent: Object3D): Object3D[] {
    let meshes: Object3D[] = [];
    if (!parent.children) return [];

    for (let child of parent.children) {
      if (child.children) {
        meshes = meshes.concat(this.getAllMeshDescendant(child));
      } else {
        meshes.push(child);
      }
    }

    return meshes;
  }
  public getObjectsBoundingBox(objects: Object3D[]) {
    //this function has problem that is, updateMatrixWorld() has some delay

    let objectCloned: Object3D[] = [];
    let box = new Box3();

    for (let obj of objects) {
      if (obj.children.length > 0) {
        // var objChildren = this.getChildDescendent(obj);
        objectCloned.push(...obj.children);
      } else {
        objectCloned.push(obj);
      }
    }

    for (let obj of objectCloned) {
      if (obj["geometry"]) {
        var geo = (obj as Mesh).geometry;
        geo.computeBoundingBox();
        // var min = geo.boundingBox.min;
        // var max = geo.boundingBox.max;

        var objBox = geo.boundingBox.clone();

        obj.updateMatrixWorld();
        objBox.applyMatrix4(obj.matrixWorld);

        // min.x += obj.position.x;
        // min.y += obj.position.y;
        // min.z += obj.position.z;

        // max.x += obj.position.x;
        // max.y += obj.position.y;
        // max.z += obj.position.z;

        box = box.expandByPoint(objBox.min);
        box = box.expandByPoint(objBox.max);
      }
    }

    //objectCloned = objectCloned.filter(o => o.constructor.name == 'Mesh');

    // for(let obj of objectCloned){
    //   //if(obj.constructor.name != 'Mesh') continue;
    //   box = box.expandByObject(obj);
    // }

    return box;
  }
  public getObjectBoundingBox(obj: Object3D): Box3 {
    let box = new Box3();
    return box.expandByObject(obj);
  }
  public getChildDescendent(group: Object3D): Object3D[] {
    if (!group.children || group.children.length == 0) return [];

    let children: Object3D[] = [];

    for (let child of group.children) {
      children.push(child);
      if (child.children && child.children.length > 0) {
        children.push(...this.getChildDescendent(child));
      }
    }

    return children;
  }
  public addArrowForLine(
    line: Mesh,
    userData: any = null,
    onDragCallback = null
  ): Object3D[] {
    let box = new Box3().setFromObject(line);
    let lineLength = box.max.x - box.min.x;
    let arrowLength = 150;
    let angle = 15;

    if (lineLength < 300) {
      return [];
    }

    let y = this.tan(angle) * arrowLength;
    let shape1 = new Shape();
    shape1.moveTo(0, 0);
    shape1.lineTo(arrowLength, y);
    shape1.lineTo(arrowLength, -y);
    shape1.closePath();
    const mat = MaterialManager.Instance().DIMENSION_TEXT.clone();
    let arrow1 = new Mesh(new ShapeBufferGeometry(shape1), mat);
    arrow1.userData = {
      type: GEOMETRY_TYPE.DIMENSION_CAP,
      views: line.userData.views,
      ...userData,
    };
    arrow1.position.set(
      line.position.x - lineLength / 2,
      line.position.y,
      line.position.z
    );
    let arrow2 = arrow1.clone();
    arrow2.rotateZ(Math.PI);
    arrow2.userData = {
      type: GEOMETRY_TYPE.DIMENSION_CAP,
      views: line.userData.views,
      ...userData,
    };
    arrow2.position.set(
      line.position.x + lineLength / 2,
      line.position.y,
      line.position.z
    );

    if (userData?.arrowColor) {
      mat.color = new Color(userData.arrowColor);
    }

    if (onDragCallback) {
      SelectionManager.ins.addEventListener("drag", onDragCallback, arrow1);
      SelectionManager.ins.addEventListener("drag", onDragCallback, arrow2);
    }

    return [arrow1, arrow2];
  }
  public createBeamGroup(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    isHighLightBeam = false,
    beamEndCapAndJointInfo: BeamCapAndJointInfo = {
      hasStartCap: false,
      hasEndCap: false,
      hasStartJoint: false,
      hasEndJoint: false,
      jointGeo: null,
    }
  ) {
    let scale = length / beamGeo.length;

    let mesh = new Mesh(
      beamGeo.geometry,
      isHighLightBeam
        ? MaterialManager.Instance().BEAM_TRANSPARENT
        : MaterialManager.Instance().BEAM
    );
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_BEAM,
      position: userDataPos,
      views: views,
    };
    mesh.scale.setX(scale);

    let startCap = new Mesh(
      beamCapGeo.geometry,
      isHighLightBeam
        ? MaterialManager.Instance().BRACKET_WARNING
        : MaterialManager.Instance().BEAM
    );
    startCap.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: getBeamEndCapCode(
        beamCapGeo.name,
        HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
      ),
    };
    let endCap = new Mesh(
      beamCapGeo.geometry,
      isHighLightBeam
        ? MaterialManager.Instance().BRACKET_WARNING
        : MaterialManager.Instance().BEAM
    );
    endCap.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: getBeamEndCapCode(
        beamCapGeo.name,
        HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
      ),
    };
    if (directionOffset < 0) {
      startCap.position.set(
        -length + beamCapGeo.length / 2 - 2,
        0,
        beamCapGeo.width / 2
      );
      endCap.position.set(-beamCapGeo.length / 2 + 2, 0, beamCapGeo.width / 2);
    } else if (directionOffset > 0) {
      startCap.position.set(
        0 + beamCapGeo.length / 2 - 2,
        0,
        beamCapGeo.width / 2
      );
      endCap.position.set(
        length - beamCapGeo.length / 2 + 2,
        0,
        beamCapGeo.width / 2
      );
    } else {
      startCap.position.set(
        -length / 2 + beamCapGeo.length / 2 - 2,
        0,
        beamCapGeo.width / 2
      );
      endCap.position.set(
        length / 2 - beamCapGeo.length / 2 + 2,
        0,
        beamCapGeo.width / 2
      );
    }

    startCap.rotateY(Math.PI / 2);
    endCap.rotateY(-Math.PI / 2);

    let beamGroup = new Group();
    beamGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_BEAM,
      position: userDataPos,
      views: views,
    };
    beamGroup.position.set(pos.x, pos.y, pos.z);
    if (rot) {
      beamGroup.rotation.set(rot.x, rot.y, rot.z);
    }

    beamGroup.add(mesh);

    if (beamEndCapAndJointInfo.hasStartCap) {
      beamGroup.add(startCap);
    }
    if (beamEndCapAndJointInfo.hasEndCap) {
      beamGroup.add(endCap);
    }

    if (beamEndCapAndJointInfo.jointGeo) {
      const startJoint = new Mesh(
        beamEndCapAndJointInfo.jointGeo.geometry,
        isHighLightBeam
          ? MaterialManager.Instance().BRACKET_WARNING
          : MaterialManager.Instance().BEAM
      );
      startJoint.userData = {
        type: GEOMETRY_TYPE.INTERNAL_JOINT,
        name: beamEndCapAndJointInfo.jointGeo.name,
      };
      const endJoint = new Mesh(
        beamEndCapAndJointInfo.jointGeo.geometry,
        isHighLightBeam
          ? MaterialManager.Instance().BRACKET_WARNING
          : MaterialManager.Instance().BEAM
      );
      endJoint.userData = {
        type: GEOMETRY_TYPE.INTERNAL_JOINT,
        name: beamEndCapAndJointInfo.jointGeo.name,
      };
      if (directionOffset < 0) {
        startJoint.position.set(
          -length + beamCapGeo.length / 2 - 2,
          beamEndCapAndJointInfo.jointGeo.height / 2 +
            EXISTING_BUILDING_CONFIG.FIT_JOINT,
          beamCapGeo.width / 2
        );
        endJoint.position.set(
          -beamCapGeo.length / 2 + 2,
          0,
          beamCapGeo.width / 2
        );
      } else if (directionOffset > 0) {
        startJoint.position.set(
          0 + beamCapGeo.length / 2 - 2,
          beamEndCapAndJointInfo.jointGeo.height / 2 +
            EXISTING_BUILDING_CONFIG.FIT_JOINT,
          beamCapGeo.width / 2
        );
        endJoint.position.set(
          length - beamCapGeo.length / 2 + 2,
          beamEndCapAndJointInfo.jointGeo.height / 2 +
            EXISTING_BUILDING_CONFIG.FIT_JOINT,
          beamCapGeo.width / 2
        );
      } else {
        startJoint.position.set(
          -length / 2 + beamCapGeo.length / 2 - 2,
          beamEndCapAndJointInfo.jointGeo.height / 2 +
            EXISTING_BUILDING_CONFIG.FIT_JOINT,
          beamCapGeo.width / 2
        );
        endJoint.position.set(
          length / 2 - beamCapGeo.length / 2 + 2,
          beamEndCapAndJointInfo.jointGeo.height / 2 +
            EXISTING_BUILDING_CONFIG.FIT_JOINT,
          beamCapGeo.width / 2
        );
      }

      startJoint.rotateY(Math.PI / 2);
      endJoint.rotateY(-Math.PI / 2);

      if (beamEndCapAndJointInfo.hasStartJoint) {
        beamGroup.add(startJoint);
      }
      if (beamEndCapAndJointInfo.hasEndJoint) {
        beamGroup.add(endJoint);
      }
    }

    return beamGroup;
  }

  public getListSeperateBeamsByBaysForFbGable(
    startEndOfBayZ,
    startBeamStartZ,
    startBeamEndZ,
    startEndCutBeamZ,
    startStartCutBeamZ
  ) {
    let endOfBayZ = startEndOfBayZ;
    let beamStartZ = startBeamStartZ;
    let endCutBeamZ = startEndCutBeamZ;
    let beamEndZ = startBeamEndZ;
    let startCutBeamZ = startStartCutBeamZ;

    const beams = [];
    for (let i = 0; i < UI.listBay.length; i++) {
      endOfBayZ += UI.listBay[i].value;
      if (beamStartZ > endOfBayZ) {
        continue;
      }

      let shouldAddBeam = false;
      if (UI.listBay[i].isCut) {
        endCutBeamZ = endOfBayZ;
        shouldAddBeam = true;
      }
      if (i == UI.listBay.length - 1 || beamEndZ < endOfBayZ) {
        endCutBeamZ = beamEndZ;
        shouldAddBeam = true;
      }

      if (shouldAddBeam) {
        beams.push([startCutBeamZ, endCutBeamZ]);
        startCutBeamZ = endCutBeamZ;
      }

      if (beamEndZ < endOfBayZ) {
        break;
      }
    }

    return beams;
  }

  public getListSeperateBeamsByBays(
    startEndOfBayX,
    startBeamStartX,
    startBeamEndX,
    startEndCutBeamX,
    startStartCutBeamX
  ) {
    const beams = [];
    let endOfBayX = startEndOfBayX;
    let beamStartX = startBeamStartX;
    let endCutBeamX = startEndCutBeamX;
    let beamEndX = startBeamEndX;
    let startCutBeamX = startStartCutBeamX;

    for (let i = 0; i < UI.listBay.length; i++) {
      endOfBayX += UI.listBay[i].value;
      if (beamStartX > endOfBayX) {
        continue;
      }

      let shouldAddBeam = false;
      if (UI.listBay[i].isCut) {
        endCutBeamX = endOfBayX;
        shouldAddBeam = true;
      }
      if (i == UI.listBay.length - 1 || beamEndX < endOfBayX) {
        endCutBeamX = beamEndX;
        shouldAddBeam = true;
      }

      if (shouldAddBeam) {
        beams.push([startCutBeamX, endCutBeamX]);
        startCutBeamX = endCutBeamX;
      }

      if (beamEndX < endOfBayX) {
        break;
      }
    }

    return beams;
  }

  public createBeamGroup2(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    beamGeoTranslationZ: number,
    beamDirection: string,
    isHighLightBeam = false,
    beamEndCapAndJointInfo: BeamCapAndJointInfo = {
      hasStartCap: false,
      hasEndCap: false,
      hasStartJoint: false,
      hasEndJoint: false,
      jointGeo: null,
    }
  ) {
    let scale = length / beamGeo.length;

    let mesh = new Mesh(
      beamGeo.geometry,
      isHighLightBeam
        ? MaterialManager.Instance().BEAM_TRANSPARENT
        : MaterialManager.Instance().BEAM
    );
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_BEAM,
      position: userDataPos,
      views: views,
    };
    mesh.scale.setX(scale);

    let startCap = new Mesh(
      beamCapGeo.geometry,
      isHighLightBeam
        ? MaterialManager.Instance().BRACKET_WARNING
        : MaterialManager.Instance().BEAM
    );
    startCap.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: getBeamEndCapCode(
        beamCapGeo.name,
        HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
      ),
    };
    let endCap = new Mesh(
      beamCapGeo.geometry,
      isHighLightBeam
        ? MaterialManager.Instance().BRACKET_WARNING
        : MaterialManager.Instance().BEAM
    );
    endCap.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: getBeamEndCapCode(
        beamCapGeo.name,
        HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
      ),
    };
    if (beamDirection == "x") {
      if (directionOffset < 0) {
        startCap.position.set(
          -length + beamCapGeo.length / 2 - 2,
          0,
          beamGeoTranslationZ
        );
        endCap.position.set(-beamCapGeo.length / 2 + 2, 0, beamGeoTranslationZ);
      } else if (directionOffset > 0) {
        startCap.position.set(
          0 + beamCapGeo.length / 2 - 2,
          0,
          beamGeoTranslationZ
        );
        endCap.position.set(
          length - beamCapGeo.length / 2 + 2,
          0,
          beamGeoTranslationZ
        );
      } else {
        startCap.position.set(
          -length / 2 + beamCapGeo.length / 2 - 2,
          0,
          beamGeoTranslationZ
        );
        endCap.position.set(
          length / 2 - beamCapGeo.length / 2 + 2,
          0,
          beamGeoTranslationZ
        );
      }

      startCap.rotateY(Math.PI / 2);
      endCap.rotateY(-Math.PI / 2);
    } else if (beamDirection == "z") {
      if (directionOffset < 0) {
        startCap.position.set(
          beamGeoTranslationZ,
          0,
          -length + beamCapGeo.length / 2 - 2
        );
        endCap.position.set(beamGeoTranslationZ, 0, -beamCapGeo.length / 2 + 2);
      } else if (directionOffset > 0) {
        startCap.position.set(
          beamGeoTranslationZ,
          0,
          beamCapGeo.length / 2 - 2
        );
        endCap.position.set(
          beamGeoTranslationZ,
          0,
          length - beamCapGeo.length / 2 + 2
        );
      } else {
        startCap.position.set(
          beamGeoTranslationZ,
          0,
          -length / 2 + beamCapGeo.length / 2 - 2
        );
        endCap.position.set(
          beamGeoTranslationZ,
          0,
          length / 2 - beamCapGeo.length / 2 + 2
        );
      }

      // startCap.rotateY(Math.PI / 2);
      // endCap.rotateY(-Math.PI / 2);
    }

    let beamGroup = new Group();
    beamGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_BEAM,
      position: userDataPos,
      views: views,
    };
    beamGroup.position.set(pos.x, pos.y, pos.z);
    if (rot) {
      beamGroup.rotation.set(rot.x, rot.y, rot.z);
    }

    beamGroup.add(mesh);

    if (beamEndCapAndJointInfo.hasStartCap) {
      beamGroup.add(startCap);
    }
    if (beamEndCapAndJointInfo.hasEndCap) {
      beamGroup.add(endCap);
    }

    if (beamEndCapAndJointInfo.jointGeo) {
      const startJoint = new Mesh(
        beamEndCapAndJointInfo.jointGeo.geometry,
        isHighLightBeam
          ? MaterialManager.Instance().BRACKET_WARNING
          : MaterialManager.Instance().BEAM
      );
      startJoint.userData = {
        type: GEOMETRY_TYPE.INTERNAL_JOINT,
        name: beamEndCapAndJointInfo.jointGeo.name,
      };
      const endJoint = new Mesh(
        beamEndCapAndJointInfo.jointGeo.geometry,
        isHighLightBeam
          ? MaterialManager.Instance().BRACKET_WARNING
          : MaterialManager.Instance().BEAM
      );
      endJoint.userData = {
        type: GEOMETRY_TYPE.INTERNAL_JOINT,
        name: beamEndCapAndJointInfo.jointGeo.name,
      };
      if (beamDirection == "x") {
        if (directionOffset < 0) {
          startJoint.position.set(
            -length,
            -beamEndCapAndJointInfo.jointGeo.height / 2 -
              EXISTING_BUILDING_CONFIG.FIT_JOINT,
            beamGeoTranslationZ
          );
          endJoint.position.set(
            -beamCapGeo.length / 2 + 2,
            0,
            beamGeoTranslationZ
          );
        } else if (directionOffset > 0) {
          startJoint.position.set(
            0,
            -beamEndCapAndJointInfo.jointGeo.height / 2 -
              EXISTING_BUILDING_CONFIG.FIT_JOINT,
            beamGeoTranslationZ
          );
          endJoint.position.set(
            length,
            -beamEndCapAndJointInfo.jointGeo.height / 2 -
              EXISTING_BUILDING_CONFIG.FIT_JOINT,
            beamGeoTranslationZ
          );
        } else {
          startJoint.position.set(
            -length / 2,
            -beamEndCapAndJointInfo.jointGeo.height / 2 -
              EXISTING_BUILDING_CONFIG.FIT_JOINT,
            beamGeoTranslationZ
          );
          endJoint.position.set(
            length / 2,
            -beamEndCapAndJointInfo.jointGeo.height / 2 -
              EXISTING_BUILDING_CONFIG.FIT_JOINT,
            beamGeoTranslationZ
          );
        }

        startJoint.rotateY(Math.PI / 2);
        endJoint.rotateY(-Math.PI / 2);
      } else if (beamDirection == "z") {
        if (directionOffset < 0) {
          startJoint.position.set(
            beamGeoTranslationZ,
            -beamEndCapAndJointInfo.jointGeo.height / 2 -
              EXISTING_BUILDING_CONFIG.FIT_JOINT,
            -length
          );
          endJoint.position.set(
            beamGeoTranslationZ,
            0,
            -beamCapGeo.length / 2 + 2
          );
        } else if (directionOffset > 0) {
          startJoint.position.set(
            beamGeoTranslationZ,
            -beamEndCapAndJointInfo.jointGeo.height / 2 -
              EXISTING_BUILDING_CONFIG.FIT_JOINT,
            0
          );
          endJoint.position.set(
            beamGeoTranslationZ,
            -beamEndCapAndJointInfo.jointGeo.height / 2 -
              EXISTING_BUILDING_CONFIG.FIT_JOINT,
            length
          );
        } else {
          startJoint.position.set(
            beamGeoTranslationZ,
            -beamEndCapAndJointInfo.jointGeo.height / 2 -
              EXISTING_BUILDING_CONFIG.FIT_JOINT,
            -length / 2
          );
          endJoint.position.set(
            beamGeoTranslationZ,
            -beamEndCapAndJointInfo.jointGeo.height / 2 -
              EXISTING_BUILDING_CONFIG.FIT_JOINT,
            length / 2
          );
        }
      }
      if (beamEndCapAndJointInfo.hasStartJoint) {
        beamGroup.add(startJoint);
      }
      if (beamEndCapAndJointInfo.hasEndJoint) {
        beamGroup.add(endJoint);
      }
    }

    return beamGroup;
  }
  public createGutterGroup(
    gutterGeo: GeometryInfo,
    gutterCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    hasStartCap?: boolean,
    hasEndCap?: boolean,
    clippingCsg?: CSG,
    parent?: Object3D
  ) {
    let scale = length / gutterGeo.length;

    let mesh = new Mesh(gutterGeo.geometry, MaterialManager.Instance().GUTTER);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.GUTTER_PATIOS,
      views: views,
    };
    mesh.scale.setX(scale);

    let startCap = new Mesh(
      gutterCapGeo.geometry,
      MaterialManager.Instance().GUTTER
    );
    let endCap = new Mesh(
      gutterCapGeo.geometry,
      MaterialManager.Instance().GUTTER
    );

    if (directionOffset < 0) {
      startCap.position.set(-length, 0, 0);
      endCap.position.set(0, 0, 0);
    } else if (directionOffset > 0) {
      startCap.position.set(0, 0, 0);
      endCap.position.set(length, 0, 0);
    } else {
      startCap.position.set(-length / 2, 0, 0);
      endCap.position.set(length / 2, 0, 0);
    }

    let gutterGroup = new Group();
    gutterGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.GUTTER_PATIOS,
      views: views,
    };
    gutterGroup.position.set(pos.x, pos.y, pos.z);
    if (rot) {
      gutterGroup.rotation.set(rot.x, rot.y, rot.z);
    }

    if (clippingCsg) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      mesh.position.set(pos.x, pos.y, pos.z);
      if (rot) {
        mesh.rotation.set(rot.x, rot.y, rot.z);
      }

      mesh.updateMatrix();

      const meshCliped = CSG.toMesh(
        CSG.fromMesh(mesh).subtract(clippingCsg),
        mesh.matrix
      );
      meshCliped.userData = mesh.userData;
      meshCliped.material = mesh.material;
      meshCliped.applyMatrix4(new Matrix4().getInverse(mesh.matrix)); //back to origin
      meshCliped.applyMatrix4(matrixBefore);
      gutterGroup.add(meshCliped);
    } else {
      gutterGroup.add(mesh);
    }

    if (hasStartCap) {
      gutterGroup.add(startCap);
    }
    if (hasEndCap) {
      gutterGroup.add(endCap);
    }

    return gutterGroup;
  }
  public createGutterGroupWithMesh(
    gutterMesh: Mesh,
    gutterCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    hasStartCap?: boolean,
    hasEndCap?: boolean
  ) {
    let startCap = new Mesh(
      gutterCapGeo.geometry,
      MaterialManager.Instance().GUTTER
    );
    let endCap = new Mesh(
      gutterCapGeo.geometry,
      MaterialManager.Instance().GUTTER
    );

    if (directionOffset < 0) {
      startCap.position.set(-length, 0, 0);
      endCap.position.set(0, 0, 0);
    } else if (directionOffset > 0) {
      startCap.position.set(0, 0, 0);
      endCap.position.set(length, 0, 0);
    } else {
      startCap.position.set(-length / 2, 0, 0);
      endCap.position.set(length / 2, 0, 0);
    }

    let gutterGroup = new Group();
    gutterGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.GUTTER_PATIOS,
      views: views,
    };
    gutterGroup.position.set(pos.x, pos.y, pos.z);
    if (rot) {
      gutterGroup.rotation.set(rot.x, rot.y, rot.z);
    }

    gutterGroup.add(gutterMesh);

    if (hasStartCap) {
      gutterGroup.add(startCap);
    }
    if (hasEndCap) {
      gutterGroup.add(endCap);
    }

    return gutterGroup;
  }
  public createPositionHelper(point: Vector3, size: number) {
    let geo = new BufferGeometry();
    geo.setFromPoints([
      new Vector3(point.x - size, point.y, point.z),
      new Vector3(point.x + size, point.y, point.z),
      new Vector3(point.x, point.y - size, point.z),
      new Vector3(point.x, point.y + size, point.z),
    ]);
    return new LineSegments(geo, new LineBasicMaterial({ color: "red" }));
  }

  public getIntersection(line1: Line3, line2: Line3): Vector2 {
    let line1StartX = line1.start.x;
    let line1StartY = line1.start.y;
    let line1EndX = line1.end.x;
    let line1EndY = line1.end.y;
    let line2StartX = line2.start.x;
    let line2StartY = line2.start.y;
    let line2EndX = line2.end.x;
    let line2EndY = line2.end.y;

    // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
    var denominator,
      a,
      b,
      numerator1,
      numerator2,
      result = {
        x: null,
        y: null,
      };
    denominator =
      (line2EndY - line2StartY) * (line1EndX - line1StartX) -
      (line2EndX - line2StartX) * (line1EndY - line1StartY);
    if (denominator == 0) {
      return undefined;
    }
    a = line1StartY - line2StartY;
    b = line1StartX - line2StartX;
    numerator1 = (line2EndX - line2StartX) * a - (line2EndY - line2StartY) * b;
    numerator2 = (line1EndX - line1StartX) * a - (line1EndY - line1StartY) * b;
    a = numerator1 / denominator;
    b = numerator2 / denominator;

    // if we cast these lines infinitely in both directions, they intersect here:
    result.x = line1StartX + a * (line1EndX - line1StartX);
    result.y = line1StartY + a * (line1EndY - line1StartY);
    /*
          // it is worth noting that this should be the same as:
          x = line2StartX + (b * (line2EndX - line2StartX));
          y = line2StartX + (b * (line2EndY - line2StartY));
          */
    // if line1 is a segment and line2 is infinite, they intersect if:
    // if (a > 0 && a < 1) {
    //   result.onLine1 = true;
    // }
    // // if line2 is a segment and line1 is infinite, they intersect if:
    // if (b > 0 && b < 1) {
    //   result.onLine2 = true;
    // }
    // if line1 and line2 are segments, they intersect if both of the above are true
    return new Vector2(result.x, result.y);
  }
  public createMesh(
    geo: BufferGeometry | Geometry,
    material: Material,
    userData: any,
    posX: number,
    posY: number,
    posZ: number,
    rotX: number,
    rotY: number,
    rotZ: number,
    scaleX: number,
    scaleY: number,
    scaleZ: number
  ): Mesh {
    let mesh = new Mesh(geo, material);
    mesh.userData = userData;
    mesh.position.set(posX, posY, posZ);
    mesh.rotation.set(rotX, rotY, rotZ);
    mesh.scale.set(scaleX, scaleY, scaleZ);

    return mesh;
  }
  //NOTE: for bindBuildingInfo() function
  public getInfoFromGeometry(
    app: HomeComponent,
    children: Object3D[],
    geoManager: GeometryManager,
    isCutOut: boolean,
    isRakeCut: boolean
  ): any {
    this.geo_gutterBack = geoManager.getGutter();

    const _roofPatios = [];
    children.map((group) => {
      _roofPatios.push(
        ...group.children.filter(
          (f) => f.userData.type === GEOMETRY_TYPE.ROOF_PATIOS
        )
      );
    });
    if (_roofPatios.length === 0) {
      children.map((group) => {
        if (group.userData.type === GEOMETRY_TYPE.ROOF_PATIOS) {
          _roofPatios.push(group);
        }
        if (group.type == "Group") {
          group.traverse((object) => {
            if (object.userData.type === GEOMETRY_TYPE.ROOF_PATIOS) {
              _roofPatios.push(object);
            }
          });
        }
        return group;
      });
    }
    const elementArray = [];
    children.map((group) => {
      if (
        group.userData.type === GEOMETRY_TYPE.DOWNPIPE ||
        group.userData.type === GEOMETRY_TYPE.SUPERIOR_BEAM
      ) {
        elementArray.push(group);
      }
    });
    children.map((group) => {
      elementArray.push(
        ...group.children.filter(
          (f) =>
            f.userData.type === GEOMETRY_TYPE.FLY_OVER_BRACKET ||
            (f.userData.type === GEOMETRY_TYPE.DOWNPIPE &&
              f.children.length > 0) ||
            (f.userData.type === GEOMETRY_TYPE.SUPERIOR_BEAM &&
              f.children.length > 0) ||
            f.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET ||
            f.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT ||
            f.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_EXT
        )
      );
      // return group;
    });
    _roofPatios.map((group) => {
      elementArray.push(
        ...group.children.filter(
          (f) =>
            f.userData.type === GEOMETRY_TYPE.GUTTER_PATIOS ||
            f.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING ||
            f.userData.type === GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING ||
            f.userData.type === GEOMETRY_TYPE.RECEIVER_CHANEL ||
            f.userData.type === GEOMETRY_TYPE.ROOF_PANEL ||
            f.userData.type === GEOMETRY_TYPE.ZFLASHING
        )
      );
      return group;
    });
    let _beams = [];
    let _internalJoints = [];
    let _normalBeamEndCaps = [];
    let _angleBrackets = 0;
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.SUPERIOR_BEAM)
      .map((beam) => {
        if (beam.userData.angle) {
          _angleBrackets += 2;
        }
        let _length = 0;
        let _position = null;
        if (beam.userData.category) {
          _length =
            beam.children.length > 0
              ? beam.children[0].scale.z !== 1
                ? beam.children[0].scale.z
                : beam.children[0].scale.x
              : beam.scale.x;
          _position =
            beam.children.length > 0
              ? beam.children[0].userData.position
              : beam.userData.position;
        } else {
          beam.children
            .filter((f) => f.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET_BEAM)
            .map((m) => {
              _length = m.scale.x;
              _position = { back: true };
            });
        }
        _beams.push({
          length: _length,
          position: _position,
        });
        //
        if (_.has(beam, ["children"])) {
          beam.children
            .filter((el) => el.userData.type == GEOMETRY_TYPE.INTERNAL_JOINT)
            .forEach((el) => {
              _internalJoints.push({
                code: el.userData.name,
              });
            });
          beam.children
            .filter(
              (el) => el.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP
            )
            .forEach((el) => {
              _normalBeamEndCaps.push({
                code: el.userData.code,
              });
            });
        }
        _beams = _beams.map((m) => {
          return {
            ...m,
            isHouseBeam: !app.chkHasHouseBeam.currentValue
              ? false
              : m.position?.back || m.position?.cutout
              ? true
              : false,
          };
        });
      });
    const _jointGroupJoints = _.groupBy(_internalJoints, (el) => el.code);
    _internalJoints = [];
    Object.entries(_jointGroupJoints).forEach(([key, value]) => {
      _internalJoints.push({
        code: key,
        qty: _.get(value, ["length"], 0),
      });
    });
    const _jointGroupEndCaps = _.groupBy(_normalBeamEndCaps, (el) => el.code);
    _normalBeamEndCaps = [];
    Object.entries(_jointGroupEndCaps).forEach(([key, value]) => {
      _normalBeamEndCaps.push({
        code: key,
        qty: _.get(value, ["length"], 0),
      });
    });
    const _panels = [];
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.ROOF_PANEL)
      .map((panel) => {
        let _length = 0;
        if (isRakeCut || isCutOut) {
          let _boxPanel = new Box3().setFromObject(panel);
          let direction = "z";
          if (
            HomeComponent.ins.isFBRoof &&
            HomeComponent.ins.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF
          ) {
            direction = "x";
          }
          _length = _.round(
            Math.ceil(_boxPanel.max[direction] - _boxPanel.min[direction]) /
              1000,
            2
          );
          _length = _.min([_.round(panel.scale.z, 2), _length]);
        } else {
          _length = _.round(panel.scale.z, 2);
        }
        _panels.push({
          length: _length / this.cos(UI.patiosPitch),
          //HACK: Check
          // length: _.round(Math.ceil(_boxPanel.min.distanceTo(_boxPanel.max)) / 1000, 2)
        });
      });
    let _downpipes = 0;
    let _nozzles = 0;
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.DOWNPIPE)
      .map((downpipe) => {
        const _countDownpipe = downpipe.children.filter(
          (f) => f.userData.type === GEOMETRY_TYPE.DOWNPIPE_NOZZLE
        );
        if (_countDownpipe.length === 0) {
          _downpipes++;
        } else {
          _nozzles++;
        }
      });

    const _gutters = [];
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.GUTTER_PATIOS)
      .map((gutter) => {
        _gutters.push({
          length:
            (gutter.children[0].scale.x * this.geo_gutterBack.length) / 1000,
        });
      });
    const _barges = [];
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING)
      .map((barge) => {
        _barges.push({
          length:
            barge.scale.x != barge.scale.z && barge.scale.x != 1
              ? this.round(barge.scale.x)
              : this.round(barge.scale.z),
        });
      });
    //Drip Barge
    elementArray
      .filter(
        (f) => f.userData.type === GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
      )
      .map((drip) => {
        _barges.push({
          length:
            drip.scale.x != drip.scale.z && drip.scale.x != 1
              ? this.round(drip.scale.x)
              : this.round(drip.scale.z),
        });
      });

    const _zflashings = [];
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.ZFLASHING)
      .map((zflashing) => {
        _zflashings.push({
          length:
            zflashing.scale.x != zflashing.scale.z && zflashing.scale.x != 1
              ? this.round(zflashing.scale.x)
              : this.round(zflashing.scale.z),
        });
      });

    let _recChanels = [];
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.RECEIVER_CHANEL)
      .map((receiver_chanel) => {
        _recChanels.push({
          length:
            receiver_chanel.scale.x != receiver_chanel.scale.z &&
            receiver_chanel.scale.x != 1
              ? this.round(receiver_chanel.scale.x)
              : this.round(receiver_chanel.scale.z),
        });
      });
    let listRecChanels = _.groupBy(_recChanels, (item) => item.length);
    _recChanels = [];
    let _keys = Object.keys(listRecChanels);
    _keys.forEach((el) => {
      _recChanels.push({
        length: +el,
        qty: listRecChanels[el].length,
      });
    });
    //HACK: conflict
    const flyOverBrackets = elementArray.filter(
      (f) =>
        f.userData.type === GEOMETRY_TYPE.FLY_OVER_BRACKET &&
        f.userData.position.back &&
        !f.userData.position.cutout
    ).length;

    const flyOverBracketCutOuts = elementArray.filter(
      (f) =>
        f.userData.type === GEOMETRY_TYPE.FLY_OVER_BRACKET &&
        f.userData.position.back &&
        f.userData.position.cutout
    ).length;

    const extFlyOverBracket =
      elementArray.filter(
        (f) => f.userData.type === GEOMETRY_TYPE.FLY_OVER_BRACKET
      ).length -
      flyOverBracketCutOuts -
      flyOverBrackets;

    return {
      downpipes: _downpipes,
      angleBrackets: _angleBrackets,
      bottomAngleBrackets: 0,
      nozzles: _nozzles,
      extFlyoverBrackets: extFlyOverBracket,
      flyoverBrackets: flyOverBrackets,
      flyoverBracketCutOuts: flyOverBracketCutOuts,
      gutters: _gutters,
      beams: _beams,
      internalJoints: _internalJoints,
      normalBeamEndCaps: _normalBeamEndCaps,
      barges: _barges,
      zflashings: _zflashings,
      receiver_chanels: _recChanels,
      panels: _panels,
      fasciaBrackets: elementArray.filter(
        (f) => f.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET
      ).length,
      fasciaBracketCutouts: elementArray.filter(
        (f) => f.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT
      ).length,
      fasciaBracketExts: elementArray.filter(
        (f) => f.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_EXT
      ).length,
    };
  }
  public getInfoFromGeometryGableRoof(
    app: HomeComponent,
    children: Object3D[],
    geoManager: GeometryManager,
    isCutOut: boolean,
    isRakeCut: boolean
  ): any {
    this.geo_gutterBack = geoManager.getGutter();

    let _centerPosts = [];
    _centerPosts = children.filter(
      (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_CENTER_POST
    );
    const _patios = children.filter(
      (x) => x.userData.category === GEOMETRY_CATEGORY.PATIOS
    );
    _patios.map((value) => {
      _centerPosts.push(
        ...value.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_CENTER_POST
        )
      );
      return value;
    });

    let listCenterPost = _.groupBy(_centerPosts, (item) => item.scale.y);

    _centerPosts = [];
    let _keys = Object.keys(listCenterPost);
    _keys.forEach((el) => {
      _centerPosts.push({
        length: _.round(+el, 2),
        qty: listCenterPost[el].length,
      });
    });

    const _roofPatios = [];
    children.map((group) => {
      _roofPatios.push(
        ...group.children.filter(
          (f) => f.userData.type === GEOMETRY_TYPE.ROOF_PATIOS
        )
      );
    });
    if (_roofPatios.length === 0) {
      children.map((group) => {
        if (group.userData.type === GEOMETRY_TYPE.ROOF_PATIOS) {
          _roofPatios.push(group);
        }
        if (group.type == "Group") {
          group.traverse((object) => {
            if (object.userData.type === GEOMETRY_TYPE.ROOF_PATIOS) {
              _roofPatios.push(object);
            }
          });
        }
        return group;
      });
    }
    const elementArray = [];
    children.map((group) => {
      if (
        group.userData.type === GEOMETRY_TYPE.DOWNPIPE ||
        group.userData.type === GEOMETRY_TYPE.SUPERIOR_BEAM ||
        group.userData.type === GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
      ) {
        elementArray.push(group);
      }
    });
    children.map((group) => {
      elementArray.push(
        ...group.children.filter(
          (f) =>
            f.userData.type === GEOMETRY_TYPE.FLY_OVER_BRACKET ||
            (f.userData.type === GEOMETRY_TYPE.DOWNPIPE &&
              f.children.length > 0) ||
            (f.userData.type === GEOMETRY_TYPE.SUPERIOR_BEAM &&
              f.children.length > 0) ||
            f.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET ||
            f.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT ||
            f.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_EXT ||
            f.userData.type === GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
        )
      );
      // return group;
    });
    _roofPatios.map((group) => {
      elementArray.push(
        ...group.children.filter(
          (f) =>
            f.userData.type === GEOMETRY_TYPE.GUTTER_PATIOS ||
            f.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING ||
            f.userData.type === GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING ||
            f.userData.type === GEOMETRY_TYPE.RECEIVER_CHANEL ||
            f.userData.type === GEOMETRY_TYPE.ROOF_PANEL ||
            f.userData.type === GEOMETRY_TYPE.ZFLASHING
        )
      );
      return group;
    });

    let _ridgeCapping = [];
    children.map((group) => {
      _ridgeCapping.push(
        ...group.children.filter(
          (f) => f.userData.type === GEOMETRY_TYPE.RIDGE_CAPPING
        )
      );
    });

    _ridgeCapping = _ridgeCapping.map((el) => {
      return {
        length: el.userData.length / 1000,
        qty: 1,
      };
    });

    let _beams = [];
    let _internalJoints = [];
    let _normalBeamEndCaps = [];
    let angleBrackets = 0; //Default
    let bottomAngleBrackets = 0; //Default
    elementArray
      .filter(
        (f) =>
          f.userData.type === GEOMETRY_TYPE.SUPERIOR_BEAM ||
          f.userData.type === GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
      )
      .map((beam) => {
        // if (beam.userData.angle) {
        //   _angleBrackets += 2;
        // }
        let _length = 0;
        let _name = null;
        if (beam.userData.category) {
          _length =
            beam.children.length > 0
              ? beam.children[0].scale.z !== 1
                ? beam.children[0].scale.z
                : beam.children[0].scale.x
              : beam.scale.x;
          _name = beam.userData.name || beam.children[0].userData.name;
        } else {
          beam.children
            .filter((f) => f.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET_BEAM)
            .map((m) => {
              _length = m.scale.x;
              _name = beam.userData.name;
            });
        }
        _beams.push({
          length: _.round(_length, 2),
          name:
            beam.userData.type === GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
              ? "RF"
              : _name,
        });
        if (_.has(beam, ["children"])) {
          beam.children
            .filter((el) => el.userData.type == GEOMETRY_TYPE.INTERNAL_JOINT)
            .forEach((el) => {
              _internalJoints.push({
                code: el.userData.name,
              });
            });
          beam.children
            .filter(
              (el) => el.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP
            )
            .forEach((el) => {
              _normalBeamEndCaps.push({
                code: el.userData.code,
              });
            });
        }
        if (beam.userData.type === GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM) {
          angleBrackets += 1;
          bottomAngleBrackets += 1;
        }
      });
    const _jointGroup = _.groupBy(_internalJoints, (el) => el.code);
    _internalJoints = [];
    Object.entries(_jointGroup).forEach(([key, value]) => {
      _internalJoints.push({
        code: key,
        qty: _.get(value, ["length"], 0),
      });
    });
    const _jointGroupEndCaps = _.groupBy(_normalBeamEndCaps, (el) => el.code);
    _normalBeamEndCaps = [];
    Object.entries(_jointGroupEndCaps).forEach(([key, value]) => {
      _normalBeamEndCaps.push({
        code: key,
        qty: _.get(value, ["length"], 0),
      });
    });
    let listBeam = _.groupBy(_beams, (item) => `"${item.length}+${item.name}"`);

    _beams = [];
    _keys = Object.keys(listBeam);
    _keys.forEach((el) => {
      _beams.push({
        ...listBeam[el][0],
        qty: listBeam[el].length,
      });
    });

    let _panels = [];
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.ROOF_PANEL)
      .map((panel) => {
        let _length = 0;

        let direction = "z";
        if (
          HomeComponent.ins.isFBRoof &&
          HomeComponent.ins.patiosRoofType === PATIOS_ROOF_TYPE.GABLE_ROOF
        ) {
          direction = "x";
        }
        let _boxPanel = new Box3().setFromObject(panel);
        _length = _.round(
          Math.ceil(_boxPanel.max[direction] - _boxPanel.min[direction]) / 1000,
          2
        );

        _panels.push({
          length: _length / this.cos(UI.patiosPitch),
          dir: panel.userData.dir,
        });
      });

    let listPanels = _.groupBy(_panels, (item) => `${item.length}-${item.dir}`);
    _panels = [];
    _keys = Object.keys(listPanels);
    _keys.forEach((el) => {
      _panels.push({
        length: +listPanels[el][0].length,
        dir: listPanels[el][0].dir,
        qty: listPanels[el].length,
      });
    });

    let _downpipes = 0;
    let _nozzles = 0;
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.DOWNPIPE)
      .map((downpipe) => {
        const _countDownpipe = downpipe.children.filter(
          (f) => f.userData.type === GEOMETRY_TYPE.DOWNPIPE_NOZZLE
        );
        if (_countDownpipe.length === 0) {
          _downpipes++;
        } else {
          _nozzles++;
        }
      });

    let _gutters = [];
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.GUTTER_PATIOS)
      .map((gutter) => {
        _gutters.push({
          length:
            (gutter.children[0].scale.x * this.geo_gutterBack.length) / 1000,
        });
      });

    let listGutters = _.groupBy(_gutters, (item) => item.length);
    _gutters = [];
    _keys = Object.keys(listGutters);
    _keys.forEach((el) => {
      _gutters.push({
        length: +el,
        qty: listGutters[el].length,
      });
    });

    let _barges = [];
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING)
      .map((barge) => {
        _barges.push({
          length:
            barge.scale.x != barge.scale.z && barge.scale.x != 1
              ? this.round(barge.scale.x)
              : this.round(barge.scale.z),
        });
      });

    //Drip Barge
    elementArray
      .filter(
        (f) => f.userData.type === GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
      )
      .map((drip) => {
        _barges.push({
          length:
            drip.scale.x != drip.scale.z && drip.scale.x != 1
              ? this.round(drip.scale.x)
              : this.round(drip.scale.z),
        });
      });

    let listBarges = _.groupBy(_barges, (item) => item.length);
    _barges = [];
    _keys = Object.keys(listBarges);
    _keys.forEach((el) => {
      _barges.push({
        length: +el,
        qty: listBarges[el].length,
      });
    });

    let _zflashings = [];
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.ZFLASHING)
      .map((zflashing) => {
        _zflashings.push({
          length:
            zflashing.scale.x != zflashing.scale.z && zflashing.scale.x != 1
              ? this.round(zflashing.scale.x)
              : this.round(zflashing.scale.z),
        });
      });

    let listZflashings = _.groupBy(_zflashings, (item) => item.length);
    _zflashings = [];
    _keys = Object.keys(listZflashings);
    _keys.forEach((el) => {
      _zflashings.push({
        length: +el,
        qty: listZflashings[el].length,
      });
    });

    let _recChanels = [];
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.RECEIVER_CHANEL)
      .map((receiver_chanel) => {
        _recChanels.push({
          length:
            receiver_chanel.scale.x != receiver_chanel.scale.z &&
            receiver_chanel.scale.x != 1
              ? this.round(receiver_chanel.scale.x)
              : this.round(receiver_chanel.scale.z),
        });
      });

    let listRecChanels = _.groupBy(_recChanels, (item) => item.length);
    _recChanels = [];
    _keys = Object.keys(listRecChanels);
    _keys.forEach((el) => {
      _recChanels.push({
        length: +el,
        qty: listRecChanels[el].length,
      });
    });

    let _rafterEndCap = [];
    elementArray
      .filter((f) => f.userData.type === GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM)
      .map((beam) => {
        beam.children.forEach((el) => {
          if (el.userData.type === GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP) {
            _rafterEndCap.push({
              name: el.userData.name,
            });
          }
        });
      });

    let listRafterEndCap = _.groupBy(_rafterEndCap, (item) => item.name);

    _rafterEndCap = [];
    _keys = Object.keys(listRafterEndCap);
    _keys.forEach((el) => {
      _rafterEndCap.push({
        code: el,
        qty: listRafterEndCap[el].length,
      });
    });

    const flyOverBrackets = elementArray.filter(
      (f) =>
        f.userData.type === GEOMETRY_TYPE.FLY_OVER_BRACKET &&
        f.userData.position.back &&
        !f.userData.position.cutout
    ).length;
    const flyOverBracketCutOuts = elementArray.filter(
      (f) =>
        f.userData.type === GEOMETRY_TYPE.FLY_OVER_BRACKET &&
        f.userData.position.back &&
        f.userData.position.cutout
    ).length;
    const extFlyOverBracket =
      elementArray.filter(
        (f) => f.userData.type === GEOMETRY_TYPE.FLY_OVER_BRACKET
      ).length -
      flyOverBracketCutOuts -
      flyOverBrackets;

    return {
      centerPost: _centerPosts,
      downpipes: _downpipes,
      angleBrackets: angleBrackets,
      bottomAngleBrackets: bottomAngleBrackets,
      nozzles: _nozzles,
      extFlyoverBrackets: extFlyOverBracket,
      flyoverBrackets: flyOverBrackets,
      flyoverBracketCutOuts: flyOverBracketCutOuts,
      gutters: _gutters,
      beams: _beams,
      internalJoints: _internalJoints,
      normalBeamEndCaps: _normalBeamEndCaps,
      barges: _barges,
      zflashings: _zflashings,
      receiver_chanels: _recChanels,
      panels: _panels,
      ridgeCapping: _ridgeCapping,
      rafterEndCap: _rafterEndCap,
      fasciaBrackets: elementArray.filter(
        (f) => f.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET
      ).length,
      fasciaBracketCutouts: elementArray.filter(
        (f) => f.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT
      ).length,
      fasciaBracketExts: elementArray.filter(
        (f) => f.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_EXT
      ).length,
    };
  }
  public getBeamRakeCutInfo(APP: HomeComponent, cutSide: BUILDING_SIDE) {
    let k, k_, j, l, m, v, a, b;
    if (cutSide == BUILDING_SIDE.LEFT) {
      k = Math.sqrt(
        Math.pow(APP.sldLeftCutHorizontal.currentValue, 2) +
          Math.pow(APP.sldLeftCutVertical.currentValue, 2)
      );
      k_ =
        (k * APP.sldFrontOverhang.currentValue) /
        APP.sldLeftCutHorizontal.currentValue;
      j =
        APP.sldLeftCutVertical.currentValue +
        k_ -
        APP.sldFrontOverhang.currentValue;
      l =
        (j * APP.sldLeftCutHorizontal.currentValue) /
        APP.sldLeftCutVertical.currentValue;
      m = Math.sqrt(Math.pow(j, 2) + Math.pow(l, 2));
      v =
        APP.sldLeftCutVertical.currentValue - APP.sldFrontOverhang.currentValue;

      a = j - (APP.sldSpan.currentValue + APP.sldMultiSpan.currentValue);
      b = a / this.cos(APP.sltRoofPitch.currentValue);
    } else if (cutSide == BUILDING_SIDE.RIGHT) {
      k = Math.sqrt(
        Math.pow(APP.sldRightCutHorizontal.currentValue, 2) +
          Math.pow(APP.sldRightCutVertical.currentValue, 2)
      );
      k_ =
        (k * APP.sldFrontOverhang.currentValue) /
        APP.sldRightCutHorizontal.currentValue;
      j =
        APP.sldRightCutVertical.currentValue +
        k_ -
        APP.sldFrontOverhang.currentValue;
      l =
        (j * APP.sldRightCutHorizontal.currentValue) /
        APP.sldRightCutVertical.currentValue;
      m = Math.sqrt(Math.pow(j, 2) + Math.pow(l, 2));
      v =
        APP.sldRightCutVertical.currentValue -
        APP.sldFrontOverhang.currentValue;

      a = j - (APP.sldSpan.currentValue + APP.sldMultiSpan.currentValue);
      b = a / this.cos(APP.sltRoofPitch.currentValue);
    }

    return {
      k: k == Infinity || isNaN(k) ? 0 : k,
      j: j == Infinity || isNaN(j) ? 0 : j,
      m: m == Infinity || isNaN(m) ? 0 : m,
      v: v == Infinity || isNaN(v) ? 0 : v,
      a: a == Infinity || isNaN(a) ? 0 : a,
      b: b == Infinity || isNaN(b) ? 0 : b,
      l: l == Infinity || isNaN(l) ? 0 : l,
      k_: k_ == Infinity || isNaN(k_) ? 0 : k_,
    };
  }

  public createDownpipeGroup(
    APP: HomeComponent,
    downpipeGroup: Group,
    geoPipe: GeometryInfo,
    geoL: GeometryInfo,
    offsetX: number,
    offsetY: number,
    offsetZ: number,
    offsetZNozzle: number,
    rotationY: number,
    connectLength: number,
    pipeLength: number,
    haveNozzle: boolean
  ) {
    let userData = { type: GEOMETRY_TYPE.DOWNPIPE };
    downpipeGroup.position.set(offsetX, 0, offsetZ);

    if (APP.sltGutterType.currentValue == 0) {
      pipeLength -= 80;
    } else {
      pipeLength -= 60;
    }

    if (connectLength > 100) {
      if (haveNozzle) {
        pipeLength -= 250;

        let meshLBottom = new Mesh(
          geoL.geometry,
          MaterialManager.Instance().DOWNPIPE
        );
        meshLBottom.userData = userData;
        meshLBottom.position.set(0, pipeLength, 20);
        meshLBottom.rotateY(Math.PI / 2);

        let meshLTop = new Mesh(
          geoL.geometry,
          MaterialManager.Instance().DOWNPIPE
        );
        meshLTop.userData = userData;
        meshLTop.rotation.set(Math.PI, Math.PI / 2, 0);
        meshLTop.position.set(0, pipeLength + 230, connectLength - 100);

        let meshPipeConnect = new Mesh(
          geoPipe.geometry,
          MaterialManager.Instance().DOWNPIPE
        );
        meshPipeConnect.userData = userData;
        meshPipeConnect.rotation.set(Math.PI / 2, 0, 0);
        meshPipeConnect.position.set(0, pipeLength + 115, 50);
        meshPipeConnect.scale.set(1, (connectLength - 140) / geoPipe.height, 1);

        downpipeGroup.add(meshLBottom, meshLTop, meshPipeConnect);
      } else {
        pipeLength += 100;
        offsetY += 100;
      }
    }

    let meshPipe = new Mesh(
      geoPipe.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshPipe.userData = userData;
    meshPipe.scale.setY(pipeLength / geoPipe.height);

    downpipeGroup.add(meshPipe);

    //Check intersec eave
    let isIntersect = false;
    let boxDownPipe = new Box3().setFromObject(downpipeGroup);

    for (let eave of APP.eaveManager.listEave) {
      let boxEave = new Box3().setFromObject(eave);

      if (boxEave.intersectsBox(boxDownPipe)) {
        isIntersect = true;
        break;
      }
    }

    if (isIntersect) {
      downpipeGroup.children = [];
      this.createDownpipeNozzle(
        APP,
        downpipeGroup,
        geoPipe,
        offsetX,
        offsetY,
        offsetZNozzle
      );
    }

    return downpipeGroup;
  }
  public createDownpipeNozzle(
    APP: HomeComponent,
    downpipeGroup: Group,
    geoPipe: GeometryInfo,
    posX: number,
    posY: number,
    posZ: number
  ) {
    let meshPipe = new Mesh(
      geoPipe.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshPipe.userData = { type: GEOMETRY_TYPE.DOWNPIPE_NOZZLE };
    meshPipe.scale.setY(100 / geoPipe.height);
    meshPipe.position.set(0, posY - 100 - 100, 0);

    downpipeGroup.add(meshPipe);
    downpipeGroup.position.set(posX, 0, posZ);
    return downpipeGroup;
  }
  public handlerDimDragEvent(
    event,
    startPos,
    startMatrix?: Matrix4,
    startBayLength?: number
  ) {
    if (event.object.userData?.dimType === DIMENSION_LABEL_TYPE.BAY) {
      const obj = event.object as Mesh;
      obj.position.z = 0;
      obj.updateMatrixWorld();
      event.object.position.z = 0;
      event.object.position.y = 0;
      let bayNum = obj.userData.bayNum;
      const pos = obj.position.clone();
      let bayOffset = UI.listBay
        .filter((b) => UI.listBay.findIndex((e) => e == b) < +bayNum)
        .reduce((pre, cur) => {
          return pre + cur.value;
        }, 0);
      let bayStartPos = -UI.totalBayLength / 2 + bayOffset;
      let bayEndPos = bayStartPos + UI.listBay[bayNum].value;
      if (
        UI.existingType == 0 ||
        UI.existingType == 3 ||
        UI.structureType == 4
      ) {
        if (event.object.position.x > bayEndPos) {
          event.object.position.x = bayEndPos;
        }
        if (event.object.position.x < bayStartPos) {
          event.object.position.x = bayStartPos;
        }
        if (event.object.userData?.left) {
          if (event.object.position.x < bayEndPos) {
            event.object.position.x = bayEndPos;
          }
        }
        if (event.object.userData?.right) {
          if (event.object.position.x > bayStartPos) {
            event.object.position.x = bayStartPos;
          }
        }
      } else if (UI.existingType == 1) {
        if (obj.userData.right) {
          if (event.object.position.x < bayStartPos) {
            event.object.position.x = bayStartPos;
          }
          if (event.object.position.x > bayStartPos) {
            event.object.position.x = bayStartPos;
          }
        }
      } else if (UI.existingType == 2) {
        if (obj.userData.left) {
          if (event.object.position.x > bayEndPos) {
            event.object.position.x = bayEndPos;
          }
          if (event.object.position.x < bayEndPos) {
            event.object.position.x = bayEndPos;
          }
        }
      }
      let distance = HomeComponent.ins.dialogEditBay.listBay[bayNum].value;
      if (startPos.x == pos.x) {
        return;
      }
      if (obj.userData.left) {
        if (UI.existingType == 1 && UI.structureType != 4) {
          if (startBayLength) {
            const restStartLength = UI.listBay
              .filter((b) => UI.listBay.findIndex((e) => e == b) != +bayNum)
              .reduce((pre, cur) => {
                return pre + cur.value;
              }, 0);
            const totalBayStartLength = restStartLength + startBayLength;
            const startPointOld = -totalBayStartLength / 2 + bayOffset;
            distance = pos.x - startPointOld;

            // const fit = distance / 2 - startBayLength / 2;
            // event.object.position.x = pos.x - fit;
          }
        } else {
          if (startBayLength) {
            const restStartLength = UI.listBay
              .filter((b) => UI.listBay.findIndex((e) => e == b) != +bayNum)
              .reduce((pre, cur) => {
                return pre + cur.value;
              }, 0);
            const totalBayStartLength = restStartLength + startBayLength;
            const startCenter =
              -totalBayStartLength / 2 + bayOffset + startBayLength / 2;
            distance = 2 * (pos.x - startCenter);
          }
          // if(pos.x > startPos.x){
          //   distance = distance + (pos.x - startPos.x);
          // } else if (pos.x < startPos.x){
          //   distance = distance - (startPos.x - pos.x);
          // }
        }
      } else {
        if (UI.existingType == 2 && UI.structureType != 4) {
          if (startBayLength) {
            const restStartLength = UI.listBay
              .filter((b) => UI.listBay.findIndex((e) => e == b) != +bayNum)
              .reduce((pre, cur) => {
                return pre + cur.value;
              }, 0);
            const totalBayStartLength = restStartLength + startBayLength;
            const endPointOld =
              -totalBayStartLength / 2 + bayOffset + startBayLength;
            distance = endPointOld - pos.x;
          }
        } else {
          if (startBayLength) {
            const restStartLength = UI.listBay
              .filter((b) => UI.listBay.findIndex((e) => e == b) != +bayNum)
              .reduce((pre, cur) => {
                return pre + cur.value;
              }, 0);
            const totalBayStartLength = restStartLength + startBayLength;
            const startCenter =
              -totalBayStartLength / 2 + bayOffset + startBayLength / 2;
            distance = 2 * (startCenter - pos.x);
          }
          // if (pos.x > startPos.x) {
          //   distance = distance - (pos.x - startPos.x);
          // } else if (pos.x < startPos.x) {
          //   distance = distance + (startPos.x - pos.x);
          // }
        }
      }
      distance = Math.round(distance / 10) * 10;
      if (distance < 1000) {
        distance = 1000;
      }
      if (distance > 8000) {
        distance = 8000;
      }
      HomeComponent.ins.dialogEditBay.listBay[bayNum].value = distance;
      HomeComponent.ins.dialogEditBay.onOK();
      // if(obj.userData.left){
      //   if (UI.existingType == 1 && UI.structureType != 4){
      //     event.object.position.x = UI.totalBayLength + bayOffset + distance;
      //   }
      // }
    } else if (
      event.object.userData?.dimType ===
      DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_HORIZONTAL
    ) {
      const obj = event.object as Mesh;
      obj.position.z = 0;
      obj.updateMatrixWorld();
      const pos = obj.position.clone();
      event.object.position.z = 0;
      event.object.position.y = 500;
      if (
        event.object.position.x >
        -UI.totalBayLength / 2 + HomeComponent.ins.sldLeftCutHorizontal.maxValue
      ) {
        event.object.position.x =
          -UI.totalBayLength / 2 +
          HomeComponent.ins.sldLeftCutHorizontal.maxValue;
      }
      if (event.object.position.x < -UI.totalBayLength / 2) {
        event.object.position.x = -UI.totalBayLength / 2;
      }
      if (event.object.userData?.left) {
        if (
          event.object.position.x <
          -UI.totalBayLength / 2 +
            HomeComponent.ins.sldLeftCutHorizontal.minValue
        ) {
          event.object.position.x =
            -UI.totalBayLength / 2 +
            HomeComponent.ins.sldLeftCutHorizontal.minValue;
        }
      }
      if (event.object.userData?.right) {
        if (event.object.position.x > -UI.totalBayLength / 2) {
          event.object.position.x = -UI.totalBayLength / 2;
        }
      }
      let distance = HomeComponent.ins.sldLeftCutHorizontal.currentValue;
      if (obj.userData.left) {
        if (pos.x > startPos.x) {
          distance = distance + (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance - (startPos.x - pos.x);
        }
      } else {
        if (pos.x > startPos.x) {
          distance = distance - (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance + (startPos.x - pos.x);
        }
      }
      distance = Math.round(distance);

      if (distance < HomeComponent.ins.sldLeftCutHorizontal.minValue) {
        distance = HomeComponent.ins.sldLeftCutHorizontal.minValue;
      }
      if (distance > HomeComponent.ins.sldLeftCutHorizontal.maxValue) {
        distance = HomeComponent.ins.sldLeftCutHorizontal.maxValue;
      }
      HomeComponent.ins.sldLeftCutHorizontal.setValue(distance);
    } else if (
      event.object.userData?.dimType ===
      DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_HORIZONTAL
    ) {
      const obj = event.object as Mesh;
      obj.position.z = 0;
      obj.updateMatrixWorld();
      const pos = obj.position.clone();
      event.object.position.z = 0;
      event.object.position.y = 500;
      if (event.object.position.x > UI.totalBayLength / 2) {
        event.object.position.x = UI.totalBayLength / 2;
      }
      if (
        event.object.position.x <
        UI.totalBayLength / 2 - HomeComponent.ins.sldRightCutHorizontal.maxValue
      ) {
        event.object.position.x =
          UI.totalBayLength / 2 -
          HomeComponent.ins.sldRightCutHorizontal.maxValue;
      }
      if (event.object.userData?.left) {
        if (event.object.position.x < UI.totalBayLength / 2) {
          event.object.position.x = UI.totalBayLength / 2;
        }
      }
      if (event.object.userData?.right) {
        if (
          event.object.position.x >
          UI.totalBayLength / 2 -
            HomeComponent.ins.sldRightCutHorizontal.minValue
        ) {
          event.object.position.x =
            UI.totalBayLength / 2 -
            HomeComponent.ins.sldRightCutHorizontal.minValue;
        }
      }
      let distance = HomeComponent.ins.sldRightCutHorizontal.currentValue;
      if (obj.userData.left) {
        if (pos.x > startPos.x) {
          distance = distance + (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance - (startPos.x - pos.x);
        }
      } else {
        if (pos.x > startPos.x) {
          distance = distance - (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance + (startPos.x - pos.x);
        }
      }
      distance = Math.round(distance);

      if (distance < HomeComponent.ins.sldRightCutHorizontal.minValue) {
        distance = HomeComponent.ins.sldRightCutHorizontal.minValue;
      }
      if (distance > HomeComponent.ins.sldRightCutHorizontal.maxValue) {
        distance = HomeComponent.ins.sldRightCutHorizontal.maxValue;
      }
      HomeComponent.ins.sldRightCutHorizontal.setValue(distance);
    } else if (
      event.object.userData?.dimType === DIMENSION_LABEL_TYPE.LEFT_OVERHANG
    ) {
      const obj = event.object as Mesh;
      obj.position.z = 0;
      obj.updateMatrixWorld();
      event.object.position.z = 0;
      event.object.position.y = 0;
      const pos = obj.position.clone();
      if (
        event.object.position.x <
        -UI.totalBayLength / 2 - HomeComponent.ins.sldLeftOverhang.maxValue
      ) {
        event.object.position.x =
          -UI.totalBayLength / 2 - HomeComponent.ins.sldLeftOverhang.maxValue;
      }
      if (event.object.position.x > -UI.totalBayLength / 2) {
        event.object.position.x = -UI.totalBayLength / 2;
      }
      if (event.object.userData?.left) {
        if (event.object.position.x < -UI.totalBayLength / 2) {
          event.object.position.x = -UI.totalBayLength / 2;
        }
      }
      if (event.object.userData?.right) {
        if (
          event.object.position.x >
          -UI.totalBayLength / 2 - HomeComponent.ins.sldLeftOverhang.minValue
        ) {
          event.object.position.x =
            -UI.totalBayLength / 2 - HomeComponent.ins.sldLeftOverhang.minValue;
        }
      }
      let distance = HomeComponent.ins.sldLeftOverhang.currentValue;
      if (obj.userData.left) {
        if (pos.x > startPos.x) {
          distance = distance + (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance - (startPos.x - pos.x);
        }
      } else {
        if (pos.x > startPos.x) {
          distance = distance - (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance + (startPos.x - pos.x);
        }
      }
      distance = Math.round(distance);

      if (distance < HomeComponent.ins.sldLeftOverhang.minValue) {
        distance = HomeComponent.ins.sldLeftOverhang.minValue;
      }
      if (distance > HomeComponent.ins.sldLeftOverhang.maxValue) {
        distance = HomeComponent.ins.sldLeftOverhang.maxValue;
      }
      HomeComponent.ins.sldLeftOverhang.setValue(distance);
    } else if (
      event.object.userData?.dimType === DIMENSION_LABEL_TYPE.RIGHT_OVERHANG
    ) {
      const obj = event.object as Mesh;
      obj.position.z = 0;
      obj.updateMatrixWorld();
      const pos = obj.position.clone();
      event.object.position.z = 0;
      event.object.position.y = 0;
      if (event.object.position.x < UI.totalBayLength / 2) {
        event.object.position.x = UI.totalBayLength / 2;
      }
      if (
        event.object.position.x >
        UI.totalBayLength / 2 + HomeComponent.ins.sldLeftOverhang.maxValue
      ) {
        event.object.position.x =
          UI.totalBayLength / 2 + HomeComponent.ins.sldLeftOverhang.maxValue;
      }
      if (event.object.userData?.left) {
        if (
          event.object.position.x <
          UI.totalBayLength / 2 + HomeComponent.ins.sldLeftOverhang.minValue
        ) {
          event.object.position.x =
            UI.totalBayLength / 2 + HomeComponent.ins.sldLeftOverhang.minValue;
        }
      }
      if (event.object.userData?.right) {
        if (event.object.position.x > UI.totalBayLength / 2) {
          event.object.position.x = UI.totalBayLength / 2;
        }
      }
      let distance = HomeComponent.ins.sldRightOverhang.currentValue;
      if (obj.userData.left) {
        if (pos.x > startPos.x) {
          distance = distance + (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance - (startPos.x - pos.x);
        }
      } else {
        if (pos.x > startPos.x) {
          distance = distance - (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance + (startPos.x - pos.x);
        }
      }
      distance = Math.round(distance);

      if (distance < HomeComponent.ins.sldRightOverhang.minValue) {
        distance = HomeComponent.ins.sldRightOverhang.minValue;
      }
      if (distance > HomeComponent.ins.sldRightOverhang.maxValue) {
        distance = HomeComponent.ins.sldRightOverhang.maxValue;
      }
      HomeComponent.ins.sldRightOverhang.setValue(distance);
    } else if (event.object.userData?.dimType === DIMENSION_LABEL_TYPE.SPAN) {
      const obj = event.object as Mesh;
      obj.position.z = 0;
      obj.updateMatrixWorld();
      const pos = obj.position.clone();
      event.object.position.z = 0;
      event.object.position.y = 0;
      if (event.object.position.x < -UI.span / 2) {
        event.object.position.x = -UI.span / 2;
      }
      if (event.object.position.x > HomeComponent.ins.sldSpan.maxValue / 2) {
        event.object.position.x = HomeComponent.ins.sldSpan.maxValue / 2;
      }
      if (event.object.userData?.left) {
        if (event.object.position.x < HomeComponent.ins.sldSpan.minValue / 2) {
          event.object.position.x = HomeComponent.ins.sldSpan.minValue / 2;
        }
      }
      if (event.object.userData?.right) {
        if (event.object.position.x > -UI.span / 2) {
          event.object.position.x = -UI.span / 2;
        }
      }
      let distance = HomeComponent.ins.sldSpan.currentValue;
      let fit = 0;
      if (obj.userData.left) {
        if (pos.x == UI.span / 2) {
          return;
        }
        // Existing building
        if (UI.structureType == 0) {
          distance = pos.x * 2;
          if (startMatrix) {
            fit =
              distance / 2 -
              (startMatrix.elements[14] + UI.existingWidth1 / 2) / 2;
            distance = distance - fit;
          }
        }
        // Fly over, Back fly over
        else if (UI.structureType == 1 || UI.structureType == 2) {
          distance = pos.x * 2;
          if (startMatrix) {
            fit =
              distance / 2 -
              (startMatrix.elements[14] +
                UI.existingWidth1 / 2 +
                HomeComponent.ins.existingWallManager.geo_existingWallL1.width +
                UI.overhangBack) /
                2;
            distance = distance - fit;
          }
        }
        //Fascia
        else if (UI.structureType == 3 || UI.structureType == 5) {
          distance = pos.x * 2;
          if (startMatrix) {
            fit =
              distance / 2 -
              (startMatrix.elements[14] +
                UI.existingWidth1 / 2 -
                UI.eaveWidth) /
                2;
            distance = distance - fit;
          }
        }
        //Free standing
        else if (UI.structureType == 4) {
          distance = pos.x * 2;
          if (startMatrix) {
            fit = distance / 2 - startMatrix.elements[14] / 2;
            distance = distance - fit;
          }
        }
      } else {
        if (
          UI.structureType == 4 &&
          UI.patiosRoofType == PATIOS_ROOF_TYPE.GABLE_ROOF
        ) {
          distance = -pos.x * 2;
        } else {
          if (pos.x > startPos.x) {
            distance = distance - (pos.x - startPos.x);
          } else if (pos.x < startPos.x) {
            distance = distance + (startPos.x - pos.x);
          }
        }
      }
      distance = Math.round(distance / 100) * 100;

      if (distance < HomeComponent.ins.sldSpan.minValue) {
        distance = HomeComponent.ins.sldSpan.minValue;
      }
      if (distance > HomeComponent.ins.sldSpan.maxValue) {
        distance = HomeComponent.ins.sldSpan.maxValue;
      }
      if (obj.userData.left) {
        event.object.position.x = distance / 2;
      }
      HomeComponent.ins.sldSpan.setValue(distance);
    } else if (
      event.object.userData?.dimType === DIMENSION_LABEL_TYPE.MULTI_SPAN
    ) {
      const obj = event.object as Mesh;
      obj.position.z = 0;
      obj.updateMatrixWorld();
      const pos = obj.position.clone();
      event.object.position.z = 0;
      event.object.position.y = 0;
      if (event.object.position.x < UI.span / 2) {
        event.object.position.x = UI.span / 2;
      }
      if (
        event.object.position.x >
        UI.span / 2 + HomeComponent.ins.sldMultiSpan.maxValue
      ) {
        event.object.position.x =
          UI.span / 2 + HomeComponent.ins.sldMultiSpan.maxValue;
      }
      if (event.object.userData?.left) {
        if (
          event.object.position.x <
          UI.span / 2 + HomeComponent.ins.sldMultiSpan.minValue
        ) {
          event.object.position.x =
            UI.span / 2 + HomeComponent.ins.sldMultiSpan.minValue;
        }
      }
      if (event.object.userData?.right) {
        if (event.object.position.x > UI.span / 2) {
          event.object.position.x = UI.span / 2;
        }
      }
      let distance = HomeComponent.ins.sldMultiSpan.currentValue;
      if (obj.userData.left) {
        if (pos.x > startPos.x) {
          distance = distance + (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance - (startPos.x - pos.x);
        }
      } else {
        if (pos.x > startPos.x) {
          distance = distance - (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance + (startPos.x - pos.x);
        }
      }
      distance = Math.round(distance / 100) * 100;

      if (distance < HomeComponent.ins.sldMultiSpan.minValue) {
        distance = HomeComponent.ins.sldMultiSpan.minValue;
      }
      if (distance > HomeComponent.ins.sldMultiSpan.maxValue) {
        distance = HomeComponent.ins.sldMultiSpan.maxValue;
      }
      HomeComponent.ins.sldMultiSpan.setValue(distance);
    } else if (
      event.object.userData?.dimType ===
      DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_VERTICAL
    ) {
      const obj = event.object as Mesh;
      obj.position.z = 0;
      obj.updateMatrixWorld();
      const pos = obj.position.clone();
      event.object.position.z = 0;
      event.object.position.y = 500;
      if (
        event.object.position.x <
        UI.span / 2 +
          UI.overhangFront +
          UI.multiSpan -
          HomeComponent.ins.sldRightCutVertical.maxValue
      ) {
        event.object.position.x =
          UI.span / 2 +
          UI.overhangFront +
          UI.multiSpan -
          HomeComponent.ins.sldRightCutVertical.maxValue;
      }
      if (
        event.object.position.x >
        UI.span / 2 + UI.overhangFront + UI.multiSpan
      ) {
        event.object.position.x = UI.span / 2 + UI.overhangFront + UI.multiSpan;
      }
      if (event.object.userData?.left) {
        if (
          event.object.position.x <
          UI.span / 2 + UI.overhangFront + UI.multiSpan
        ) {
          event.object.position.x =
            UI.span / 2 + UI.overhangFront + UI.multiSpan;
        }
      }
      if (event.object.userData?.right) {
        if (
          event.object.position.x >
          UI.span / 2 +
            UI.overhangFront +
            UI.multiSpan -
            HomeComponent.ins.sldRightCutVertical.minValue
        ) {
          event.object.position.x =
            UI.span / 2 +
            UI.overhangFront +
            UI.multiSpan -
            HomeComponent.ins.sldRightCutVertical.minValue;
        }
      }
      let distance = HomeComponent.ins.sldRightCutVertical.currentValue;
      if (obj.userData.left) {
        if (pos.x > startPos.x) {
          distance = distance + (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance - (startPos.x - pos.x);
        }
      } else {
        if (pos.x > startPos.x) {
          distance = distance - (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance + (startPos.x - pos.x);
        }
      }
      distance = Math.round(distance);

      if (distance < HomeComponent.ins.sldRightCutVertical.minValue) {
        distance = HomeComponent.ins.sldRightCutVertical.minValue;
      }
      if (distance > HomeComponent.ins.sldRightCutVertical.maxValue) {
        distance = HomeComponent.ins.sldRightCutVertical.maxValue;
      }
      HomeComponent.ins.sldRightCutVertical.setValue(distance);
    } else if (
      event.object.userData?.dimType === DIMENSION_LABEL_TYPE.FRONT_OVERHANG
    ) {
      const obj = event.object as Mesh;
      obj.position.z = 0;
      obj.updateMatrixWorld();
      event.object.position.z = 0;
      event.object.position.y = 0;
      const pos = obj.position.clone();
      if (event.object.position.x < UI.span / 2 + UI.multiSpan) {
        event.object.position.x = UI.span / 2 + UI.multiSpan;
      }
      if (
        event.object.position.x >
        UI.span / 2 + UI.multiSpan + HomeComponent.ins.sldFrontOverhang.maxValue
      ) {
        event.object.position.x =
          UI.span / 2 +
          UI.multiSpan +
          HomeComponent.ins.sldFrontOverhang.maxValue;
      }
      if (event.object.userData?.left) {
        if (
          event.object.position.x <
          UI.span / 2 +
            UI.multiSpan +
            HomeComponent.ins.sldFrontOverhang.minValue
        ) {
          event.object.position.x =
            UI.span / 2 +
            UI.multiSpan +
            HomeComponent.ins.sldFrontOverhang.minValue;
        }
      }
      if (event.object.userData?.right) {
        if (event.object.position.x > UI.span / 2 + UI.multiSpan) {
          event.object.position.x = UI.span / 2 + UI.multiSpan;
        }
      }
      let distance = HomeComponent.ins.sldFrontOverhang.currentValue;
      if (obj.userData.left) {
        if (pos.x > startPos.x) {
          distance = distance + (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance - (startPos.x - pos.x);
        }
      } else {
        if (pos.x > startPos.x) {
          distance = distance - (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance + (startPos.x - pos.x);
        }
      }
      distance = Math.round(distance);

      if (distance < HomeComponent.ins.sldFrontOverhang.minValue) {
        distance = HomeComponent.ins.sldFrontOverhang.minValue;
      }
      if (distance > HomeComponent.ins.sldFrontOverhang.maxValue) {
        distance = HomeComponent.ins.sldFrontOverhang.maxValue;
      }
      HomeComponent.ins.sldFrontOverhang.setValue(distance);
    } else if (
      event.object.userData?.dimType === DIMENSION_LABEL_TYPE.BACK_OVERHANG
    ) {
      const obj = event.object as Mesh;
      obj.position.z = 0;
      obj.updateMatrixWorld();
      const pos = obj.position.clone();
      event.object.position.z = 0;
      event.object.position.y = 0;
      if (
        event.object.position.x <
        -UI.span / 2 - HomeComponent.ins.sldBackOverhang.maxValue
      ) {
        event.object.position.x =
          -UI.span / 2 - HomeComponent.ins.sldBackOverhang.maxValue;
      }
      if (event.object.position.x > -UI.span / 2) {
        event.object.position.x = -UI.span / 2;
      }
      if (event.object.userData?.left) {
        if (event.object.position.x < -UI.span / 2) {
          event.object.position.x = -UI.span / 2;
        }
      }
      if (event.object.userData?.right) {
        if (
          event.object.position.x >
          -UI.span / 2 - HomeComponent.ins.sldBackOverhang.minValue
        ) {
          event.object.position.x =
            -UI.span / 2 - HomeComponent.ins.sldBackOverhang.minValue;
        }
      }
      let distance = HomeComponent.ins.sldBackOverhang.currentValue;
      if (obj.userData.left) {
        if (pos.x > startPos.x) {
          distance = distance + (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance - (startPos.x - pos.x);
        }
      } else {
        if (pos.x > startPos.x) {
          distance = distance - (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance + (startPos.x - pos.x);
        }
      }
      distance = Math.round(distance);

      if (distance < HomeComponent.ins.sldBackOverhang.minValue) {
        distance = HomeComponent.ins.sldBackOverhang.minValue;
      }
      if (distance > HomeComponent.ins.sldBackOverhang.maxValue) {
        distance = HomeComponent.ins.sldBackOverhang.maxValue;
      }
      HomeComponent.ins.sldBackOverhang.setValue(distance);
    } else if (
      event.object.userData?.dimType ===
      DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_VERTICAL
    ) {
      const obj = event.object as Mesh;
      obj.position.z = 0;
      obj.updateMatrixWorld();
      const pos = obj.position.clone();
      event.object.position.z = 0;
      event.object.position.y = 0;
      if (
        event.object.position.x <
        -HomeComponent.ins.sldLeftCutVertical.maxValue / 2
      ) {
        event.object.position.x =
          -HomeComponent.ins.sldLeftCutVertical.maxValue / 2;
      }
      if (event.object.position.x > UI.rakeCutLeftVer / 2) {
        event.object.position.x = UI.rakeCutLeftVer / 2;
      }
      if (event.object.userData?.left) {
        if (event.object.position.x < UI.rakeCutLeftVer / 2) {
          event.object.position.x = UI.rakeCutLeftVer / 2;
        }
      }
      if (event.object.userData?.right) {
        if (
          event.object.position.x >
          -HomeComponent.ins.sldLeftCutVertical.minValue / 2
        ) {
          event.object.position.x =
            -HomeComponent.ins.sldLeftCutVertical.minValue / 2;
        }
      }
      let distance = HomeComponent.ins.sldLeftCutVertical.currentValue;
      if (obj.userData.left) {
        if (pos.x > startPos.x) {
          distance = distance + (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance - (startPos.x - pos.x);
        }
      } else {
        if (pos.x > startPos.x) {
          distance = distance - (pos.x - startPos.x);
        } else if (pos.x < startPos.x) {
          distance = distance + (startPos.x - pos.x);
        }
      }
      distance = Math.round(distance);

      if (distance < HomeComponent.ins.sldLeftCutVertical.minValue) {
        distance = HomeComponent.ins.sldLeftCutVertical.minValue;
      }
      if (distance > HomeComponent.ins.sldLeftCutVertical.maxValue) {
        distance = HomeComponent.ins.sldLeftCutVertical.maxValue;
      }
      HomeComponent.ins.sldLeftCutVertical.setValue(distance);
    }
  }
}
export function getBeamEndCapCode(endCapPreCode, endCapColorCode) {
  const availableCodes = [
    "BSEC110B",
    "BSEC110C",
    "BSEC110MM",
    "BSEC110M",
    "BSEC110SM",
    "BSEC110W",
    "BSCE150B",
    "BSCE150C",
    "BSCE150P",
    "BSCE150W",
    "BSEC160B",
    "BSEC160C",
    "BSEC160MM",
    "BSEC160P",
    "BSEC160SM",
    "BSEC160W",
    "BSCE200B",
    "BSCE200C",
    "BSCE200W",
    "BSEC210B",
    "BSEC210C",
    "BSEC210MM",
    "BSEC210M",
    "BSEC210SM",
    "BSEC210W",
    "BSEC260B",
    "BSEC260C",
    "BSEC260MM",
    "BSEC260M",
    "BSEC260SM",
    "BSEC260W",
  ];
  let code = `${endCapPreCode}${endCapColorCode}`;

  // Paperbark some time = P and sometime = M
  if (!availableCodes.find((el) => el == code)) {
    code = `${endCapPreCode}P`;
  }
  if (!availableCodes.find((el) => el == code)) {
    code = `${endCapPreCode}SM`;
  }

  return code;
}
// Rafter always 160
export function getRafterBeamEndCapCode(beamSize = 160, isTop = false) {
  if (isTop) {
    let colorCode = HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode;
    if (colorCode == "M" && beamSize == 160) {
      colorCode = "P";
    }

    return `BSA${beamSize}15${colorCode}`;
  } else {
    let colorCode = HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode;
    if (colorCode == "M" && beamSize == 160) {
      colorCode = "P";
    }

    return `BSAGB${beamSize}${colorCode}`;
  }
}
export const utils = {
  tan(degrees: number): number {
    return this.round(Math.tan(this.degreesToRadians(degrees)));
  },
  degreesToRadians(degrees: number) {
    var pi = Math.PI;
    return this.round(degrees * (pi / 180));
  },
  round(num: number): number {
    return parseFloat(num.toFixed(3));
  },
  getDimensionValue(value: number) {
    //return parseFloat(`${value/1000}`).toFixed(2) + 'm';
    return Math.round(value).toString(); //parseFloat(`${value/1000}`).toFixed(3);
  },
  getTextCover(
    textGeo: TextBufferGeometry,
    labelType: string,
    pos: Vector3,
    fitZ = 0,
    bayNum?: number
  ) {
    let textSize = new Vector3();
    textGeo.boundingBox.getSize(textSize);

    let textCover = new Mesh(
      new BoxBufferGeometry(textSize.x, textSize.y, textSize.z)
    );
    textCover.position.set(pos.x, pos.y, pos.z + fitZ);
    textCover.userData = { type: GEOMETRY_TYPE.TEXT_COVER, labelType };
    if (bayNum !== undefined) {
      textCover.userData["bayNum"] = bayNum;
    }
    textCover.material["opacity"] = 0;
    textCover.material["transparent"] = true;

    return textCover;
  },
};
