import { BoxBufferGeometry, Mesh } from "three"
import { GeometryManager } from "../core/geometry.manager";
import { MaterialManager } from "../core/material.manager";
import { GeometryInfo } from "src/app/core/models";
import BaseUIControlEntity from "./base.model"

export class DeleteControl extends BaseUIControlEntity {
    geo: BoxBufferGeometry
    lineGeometry: GeometryInfo

    draw(){
        const size = 200;
        this.lineGeometry =  GeometryManager.Instance().getUIControlLine();
        const deleteMaterial = MaterialManager.Instance().HIDDEN_UI_CONTROL;
        const lineMaterial = MaterialManager.Instance().HIGHLIGHT_DIMENSION_TEXT;
        this.geo = new BoxBufferGeometry(size, 10, size)
        this.mesh = new Mesh(this.geo, deleteMaterial)
        this.mesh.userData = this.options.userData
        this.mesh.position.set(this.position[0], this.position[1], this.position[2])
        if(this.rotation){
            this.mesh.rotateY(this.rotation.x);
            this.mesh.rotateY(this.rotation.y);
            this.mesh.rotateZ(this.rotation.z);
        }
        this.mesh.renderOrder = 0
        this.mesh.visible = this.visible

        let line1 = new Mesh(this.lineGeometry.geometry, lineMaterial);
        line1.scale.setX(size * Math.sqrt(2) / this.lineGeometry.length);
        line1.position.set(this.position[0], this.position[1], this.position[2]);
        line1.rotateX(Math.PI / 2);
        line1.rotateZ(Math.PI / 4);

        let line2 = line1.clone();
        line2.rotateZ(Math.PI / 2);

        this.container.add(this.mesh, line1, line2)
    }
}