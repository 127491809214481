import {
  BUILDING_SIDE,
  EXISTING_BUILDING_CONFIG as CONST,
  CUTOUT_ENABLE,
  EXISTING_BUILDING_CONFIG,
  FIT_FLYOVER_BRAKET_ON_ROOF,
  MIN_INTERNAL_BEAM_LENGTH,
  RAKECUT_TYPE,
} from "src/app/app.constants";
import {
  Box3,
  BoxBufferGeometry,
  BoxHelper,
  BufferGeometry,
  Color,
  Geometry,
  Group,
  Line3,
  LineSegments,
  Material,
  Matrix4,
  Mesh,
  Plane,
  PlaneHelper,
  Vector3,
} from "three";
import { PatiosFlyOverManager } from ".";
import { GEOMETRY_CATEGORY, GEOMETRY_TYPE, INIT } from "src/app/app.config";
import { HomeComponent as AppComponent, HomeComponent } from "../../containers/home/home.component";
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import {
  GeometryInfo,
  LineType,
  Print2DView,
  Printing2DGeometry,
  Printing2DLine,
  ViewType,
} from "src/app/core/models";
import { Util, getBeamEndCapCode } from "../utils";
import { UI } from "../ui";
import _ from "lodash";

export class ColumnAndBeamManager {
  private scene: Group;
  private APP: AppComponent;
  private MANAGER: PatiosFlyOverManager;
  private material: Material;
  //private beamMaterial: Material;
  private utils: Util;

  private geometryManager: GeometryManager;

  private geo_superiorPost: GeometryInfo;
  public geo_superiorBeam: GeometryInfo;
  public geo_superiorHouseBeam: GeometryInfo;
  private geo_beamRakecutLeft: GeometryInfo;
  private geo_beamRakecutRight: GeometryInfo;
  private geo_beamStepRakecutLeft: GeometryInfo;
  private geo_beamStepRakecutRight: GeometryInfo;
  private geo_beamEndCap: GeometryInfo;
  private geo_houseBeamEndCap: GeometryInfo;
  public geo_bracket: GeometryInfo;
  private geo_groundBase: GeometryInfo;
  private geo_downPipe: GeometryInfo;
  private geo_downPipeL: GeometryInfo;
  private downpipeGroup: Group;
  private downpipeStepRakeCutGroupLeft: Group;
  private downpipeStepRakeCutGroupRight: Group;
  private eventHandleId: any;
  private objectSizeChangedHandle: any;
  public extraOffsetZ: number;
  private geo_RafterBeam: GeometryInfo;
  private geo_rafterBeamEndCap: GeometryInfo;

  private geoBeamJoint: GeometryInfo;
  private geoHouseBeamJoint: GeometryInfo;

  private frontPostHeight: number;
  //private extraBracketHeight = 250;
  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;

  private deferHandle;
  private deferTimeout = CONST.CUTOUT_DEFFER_TIME_OUT;

  public numberOfBracketsBack = INIT.DEFAULT_BRACKET_NUMBER;
  public numberOfBracketCutout = INIT.DEFAULT_BRACKET_CUTOUT_NUMBER;
  private cutoutExistingBothLength: number;
  private beamLeftCutSizeInfo: any;
  private beamRightCutSizeInfo: any;

  constructor(app: AppComponent, flyOverManager: PatiosFlyOverManager) {
    this.APP = app;
    this.MANAGER = flyOverManager;
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();

    this.scene = flyOverManager.patiosGroup;
    this.material = MaterialManager.Instance().DEFAULT.clone();
    //this.beamMaterial = MaterialManager.Instance().DEFAULT.clone();

    this.registerEvent();
  }

  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      let bracketHeight =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.height / 2;
      let bracketWidth = this.geometryManager.FLY_OVER_BRACKET.S65x3.width;

      this.geo_bracket = new GeometryInfo();
      this.geo_bracket.geometry =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.geometry
          .clone()
          .rotateX(Math.PI / 2)
          .translate(bracketWidth / 2, bracketHeight, bracketWidth / 2);
      this.geo_bracket.width =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.width;
      this.geo_bracket.length =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.length;
      this.geo_bracket.height =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.height;

      this.geo_superiorBeam = this.geometryManager.getBeam();
      this.geo_superiorBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_superiorBeam.length / 2,
          this.geo_superiorBeam.height / 2,
          this.geo_superiorBeam.width / 2
        );

      this.geoBeamJoint = this.geometryManager.getBeamJoint()
      this.geoHouseBeamJoint = this.geometryManager.getHouseBeamJoint()

      this.geo_RafterBeam = this.geometryManager.getBeam();
      this.geo_RafterBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_RafterBeam.length / 2,
          this.geo_RafterBeam.height / 2,
          this.geo_RafterBeam.width / 2
        );

      this.geo_rafterBeamEndCap = this.geometryManager.getBeamEndCap();
      this.geo_rafterBeamEndCap.geometry.translate(
        0,
        this.geo_rafterBeamEndCap.height / 2,
        this.geo_rafterBeamEndCap.length / 2
      );

      this.geo_superiorHouseBeam = this.geometryManager.getHouseBeam();
      this.geo_superiorHouseBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_superiorHouseBeam.length / 2,
          this.geo_superiorHouseBeam.height / 2,
          this.geo_superiorHouseBeam.width / 2
        );

      this.geo_beamRakecutLeft = this.geometryManager.getBeam();
      this.geo_beamRakecutLeft.geometry.translate(
        this.geo_beamRakecutLeft.width / 2,
        this.geo_beamRakecutLeft.height / 2,
        -this.geo_beamRakecutLeft.length / 2
      );

      this.geo_beamRakecutRight = this.geometryManager.getBeam();
      this.geo_beamRakecutRight.geometry.translate(
        -this.geo_beamRakecutRight.width / 2,
        this.geo_beamRakecutRight.height / 2,
        -this.geo_beamRakecutRight.length / 2
      );

      this.geo_beamStepRakecutLeft = this.geometryManager.getBeam();
      this.geo_beamStepRakecutLeft.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_beamStepRakecutLeft.length / 2,
          this.geo_beamStepRakecutLeft.height / 2,
          -this.geo_beamStepRakecutLeft.width / 2
        );

      this.geo_beamStepRakecutRight = this.geometryManager.getBeam();
      this.geo_beamStepRakecutRight.geometry
        .rotateY(Math.PI / 2)
        .translate(
          -this.geo_beamStepRakecutRight.length / 2,
          this.geo_beamStepRakecutRight.height / 2,
          -this.geo_beamStepRakecutRight.width / 2
        );

      this.geo_beamEndCap = this.geometryManager.getBeamEndCap();
      this.geo_beamEndCap.geometry.translate(
        0,
        this.geo_beamEndCap.height / 2,
        0
      );

      this.geo_houseBeamEndCap = this.geometryManager.getHouseBeamEndCap();
      this.geo_houseBeamEndCap.geometry.translate(
        0,
        this.geo_houseBeamEndCap.height / 2,
        0
      );

      this.geo_superiorPost = this.geometryManager.getPost();
      this.geo_superiorPost.geometry
        .rotateX(Math.PI / 2)
        .translate(
          this.geo_superiorPost.width / 2,
          this.geo_superiorPost.height / 2,
          this.geo_superiorPost.width / 2
        );

      this.geo_groundBase = new GeometryInfo();
      this.geo_groundBase.width = 1000;
      this.geo_groundBase.height = 1;
      this.geo_groundBase.length = 1000;
      this.geo_groundBase.geometry = new BoxBufferGeometry(
        this.geo_groundBase.width,
        this.geo_groundBase.height,
        this.geo_groundBase.length
      );
      this.geo_groundBase.geometry.translate(
        0,
        0,
        this.geo_groundBase.length / 2
      );

      this.geo_downPipe = this.geometryManager.getDownPipe();
      this.geo_downPipeL = this.geometryManager.getDownPipeL();

      this.scene.remove(
        ...this.scene.children.filter(
          (c) => c.userData.type == GEOMETRY_TYPE.DOWNPIPE
        )
      );
      this.downpipeGroup = new Group();
      this.downpipeGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeGroup);
      this.downpipeStepRakeCutGroupLeft = new Group();
      this.downpipeStepRakeCutGroupLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupLeft);

      this.downpipeStepRakeCutGroupRight = new Group();
      this.downpipeStepRakeCutGroupRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupRight);
      resolve();
    });
  }

  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(
        ...this.scene.children.filter(
          (x) =>
            x.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET &&
            !x.userData.position.back
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) =>
            x.userData.type == GEOMETRY_TYPE.SUPERIOR_POST &&
            !x.userData.position.back
        )
      );
      // this.scene.remove(...this.scene.children.filter(x => x.userData.type == GEOMETRY_TYPE.MESH_OUTLINE));
      this.scene.remove(
        ...this.scene.children.filter((x) => x.userData.type == "BOXHELPER")
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (o) => o.userData.type == GEOMETRY_TYPE.GROUND_BASE
        )
      );
      this.APP.scene.remove(
        ...this.APP.scene.children.filter(
          (o) =>
            o.userData.type == "OUTLINE" || o.userData.type == "COLUMN_OUTLINE"
        )
      );

      this.beamLeftCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.LEFT
      );
      this.beamRightCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.RIGHT
      );
      this.downpipeGroup.children = [];
      this.downpipeStepRakeCutGroupLeft.children = [];
      this.downpipeStepRakeCutGroupRight.children = [];

      this.extraOffsetZ =
        this.APP.existingWallManager.geo_existingWallL1.width +
        this.APP.eaveManager.backOverhang;
      this.cutoutExistingBothLength =
        (UI.totalBayLength - this.APP.sldExistingLength.currentValue) / 2;

      this.addSuperiorBeam({ front: true });
      this.addSuperiorBeam({ back: true });
      this.addBeamAngleRakecut();
      this.addBeamStepRakecut();
      if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.MANAGER.cutoutCondition
      ) {
        this.addSuperiorBeam({ cutout: true });
      }
      if (UI.multiSpan > 0) {
        this.addSuperiorBeam({ multiSpan: true });
      }
      this.addGround();

      const fitX = this.getContainerOffsetXToFitBraketToExistingWall();
      let offsetX = -UI.totalBayLength / 2;
      let first = true;
      let last = false;
      for (let i = 0; i <= UI.listBay.length; i++) {
        if (i == UI.listBay.length) {
          last = true;
        }
        this.addPostOrBracket(offsetX, {
          front: true,
          first,
          last,
          left: first,
          right: last,
        });

        if (UI.multiSpan > 0) {
          this.addPostOrBracket(offsetX, {
            multiSpan: true,
            first,
            last,
            left: first,
            right: last,
          });
        }
        if (i < UI.listBay.length) {
          let b = UI.listBay[i];
          offsetX += b.value;
        }
        first = false;
      }

      offsetX = -UI.totalBayLength / 2;
      first = true;
      last = false;
      for (let i = 0; i <= UI.listBay.length; i++) {
        if (i == UI.listBay.length) {
          last = true;
        }
        if (first || last) {
          this.addPostAngleRakecut(offsetX + fitX, {
            first,
            last,
            left: first,
            right: last,
          });
        }
        if (i < UI.listBay.length) {
          let b = UI.listBay[i];
          offsetX += b.value;
        }
        first = false;
      }

      this.addPostOrBracketBack();
      this.addPostOrBracketBackCutout();
      this.addPostStepRakecut();
      this.addDownPipe();
      this.addDownPipeStepRakecut();
      this.updateUI();
      this.showBeamOutline();
      resolve();
    });
  }
  public showBeamOutline() {
    this.APP.scene.remove(
      ...this.APP.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.BEAM_OUTLINE
      )
    );

    if (!UI.beamLayoutShow) return;

    const objs = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    const meshes = []
    objs.forEach(el => {
      if(el.type == 'Group') {
        meshes.push(...el.children.filter((o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM))
      }
    })

    for (let o of meshes) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        10
      );
      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);

      var line = new LineSegments(
        outlineGeo,
        MaterialManager.Instance().BEAM_OUTLINE
      );
      line.userData = { type: GEOMETRY_TYPE.BEAM_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  private getContainerOffsetXToFitBraketToExistingWall() {
    let fitX = 0;
    if (UI.existingType == BUILDING_SIDE.RIGHT) {
      fitX = -(
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0)
      );
    }
    if (UI.existingType == BUILDING_SIDE.LEFT) {
      fitX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
    }

    return fitX;
  }
  public addEndRafterBeam(info: any) {
    const {
      numOfInternalRafter,
      numOfSpanBeam,
      numOfMultiSpanBeam,
    } = info;
    this.scene.remove(
      ...this.scene.children.filter(
        (x) =>
          x.userData.type == GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM ||
          x.userData.type == GEOMETRY_TYPE.SUPERIOR_INTERNAL_BEAM
      )
    );
    const fitX = this.getContainerOffsetXToFitBraketToExistingWall();

    const fitIfNotWidth2 =
      this.APP.existingWallManager.geo_existingWallW1.width +
      CONST.EAVE_OFFSET_BACK;

    let endMainLeftX = -UI.totalBayLength / 2 + fitX;
    let endMainRightX = UI.totalBayLength / 2 + fitX;
    if (UI.cutOutType == CUTOUT_ENABLE.YES && this.MANAGER.cutoutCondition) {
      // Enable cut out
      if (UI.existingType == BUILDING_SIDE.LEFT) {
        endMainLeftX =
          -UI.totalBayLength / 2 +
          fitX +
          UI.existingLength2 -
          (UI.existingWidth2 > 0 ? 0 : fitIfNotWidth2);
      } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
        endMainRightX =
          UI.totalBayLength / 2 +
          fitX -
          UI.existingLength2 +
          (UI.existingWidth2 > 0 ? 0 : fitIfNotWidth2);
      } else if (UI.existingType == BUILDING_SIDE.BOTH) {
        endMainLeftX =
          -UI.totalBayLength / 2 +
          fitX +
          UI.existingLength2 -
          (UI.existingWidth2 > 0 ? 0 : fitIfNotWidth2);
        endMainRightX =
          UI.totalBayLength / 2 +
          fitX -
          UI.existingLength2 +
          (UI.existingWidth2 > 0 ? 0 : fitIfNotWidth2);
      }
    }

    // Add end rafter for two end side
    // Left side
    this.addRafterSuperiorBeam(endMainLeftX, {
      span: true,
      first: true,
      last: false,
    });
    if (UI.multiSpan) {
      this.addRafterSuperiorBeam(endMainLeftX, {
        multiSpan: true,
        first: true,
        last: false,
      });
    }

    // Right side
    this.addRafterSuperiorBeam(endMainRightX, {
      span: true,
      first: false,
      last: true,
    });
    if (UI.multiSpan) {
      this.addRafterSuperiorBeam(endMainRightX, {
        multiSpan: true,
        first: false,
        last: true,
      });
    }

    if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      this.addRafterSuperiorBeam(
        -UI.totalBayLength / 2 + fitX + UI.rakeCutLeftHor,
        { rakeCutLeft: true, first: true, last: false }
      );
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      this.addRafterSuperiorBeam(
        UI.totalBayLength / 2 + fitX - UI.rakeCutRightHor,
        { rakeCutRight: true, first: false, last: true }
      );
    }

    this.addInternalRafterBeam(
      numOfInternalRafter,
      numOfSpanBeam,
      numOfMultiSpanBeam
    );

    if (UI.cutOutType == CUTOUT_ENABLE.YES && this.MANAGER.cutoutCondition) {
      // Add rafter beam for cut out
      if (UI.existingType == BUILDING_SIDE.LEFT) {
        endMainLeftX = -UI.totalBayLength / 2 + fitX;
        this.addRafterSuperiorBeam(endMainLeftX, {
          cutOutForSpan: true,
          first: true,
          last: false,
        });
        if (UI.multiSpan) {
          this.addRafterSuperiorBeam(endMainLeftX, {
            cutOutForMultiSpan: true,
            first: true,
            last: false,
          });
        }
      } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
        endMainRightX = UI.totalBayLength / 2 + fitX;
        this.addRafterSuperiorBeam(endMainRightX, {
          cutOutForSpan: true,
          first: false,
          last: true,
        });
        if (UI.multiSpan) {
          this.addRafterSuperiorBeam(endMainRightX, {
            cutOutForMultiSpan: true,
            first: false,
            last: true,
          });
        }
      } else if (UI.existingType == BUILDING_SIDE.BOTH) {
        endMainLeftX = -UI.totalBayLength / 2 + fitX;
        this.addRafterSuperiorBeam(endMainLeftX, {
          cutOutForSpan: true,
          first: true,
          last: false,
        });
        if (UI.multiSpan) {
          this.addRafterSuperiorBeam(endMainLeftX, {
            cutOutForMultiSpan: true,
            first: true,
            last: false,
          });
        }

        endMainRightX = UI.totalBayLength / 2 + fitX;
        this.addRafterSuperiorBeam(endMainRightX, {
          cutOutForSpan: true,
          first: false,
          last: true,
        });
        if (UI.multiSpan) {
          this.addRafterSuperiorBeam(endMainRightX, {
            cutOutForMultiSpan: true,
            first: false,
            last: true,
          });
        }
      }
    }

    // Add rafter beam for rake cut step
  }
  private addInternalRafterBeam(
    numOfRafter: number,
    numOfSpanBeam: number,
    numOfMultiSpanBeam: number
  ) {
    let fitX = this.getContainerOffsetXToFitBraketToExistingWall();

    const fitIfNotWidth2 =
      this.APP.existingWallManager.geo_existingWallW1.width +
      CONST.EAVE_OFFSET_BACK;
    let offsetX = -UI.totalBayLength / 2 + fitX;
    let mainRoofLength = UI.totalBayLength;
    let endCutoutLeft = -UI.totalBayLength / 2 + fitX;
    if (UI.cutOutType == CUTOUT_ENABLE.YES && this.MANAGER.cutoutCondition) {
      // Enable cut out
      if (UI.existingType == BUILDING_SIDE.LEFT) {
        mainRoofLength =
          UI.totalBayLength -
          UI.existingLength2 +
          (UI.existingWidth2 > 0 ? 0 : fitIfNotWidth2);
        offsetX =
          -UI.totalBayLength / 2 +
          fitX +
          UI.existingLength2 -
          (UI.existingWidth2 > 0 ? 0 : fitIfNotWidth2);
      } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
        mainRoofLength =
          UI.totalBayLength -
          UI.existingLength2 +
          (UI.existingWidth2 > 0 ? 0 : fitIfNotWidth2);
      } else if (UI.existingType == BUILDING_SIDE.BOTH) {
        mainRoofLength =
          UI.totalBayLength -
          2 * UI.existingLength2 +
          (UI.existingWidth2 > 0 ? 0 : 2 * fitIfNotWidth2);
        offsetX =
          -UI.totalBayLength / 2 +
          fitX +
          UI.existingLength2 -
          (UI.existingWidth2 > 0 ? 0 : fitIfNotWidth2);
      }
    }

    const mainRoofLeft = offsetX;
    const mainRoofRight = offsetX + mainRoofLength;
    let listRafterPos = []
    const rafterSpacing = UI.totalBayLength / (numOfRafter + 1);
    const maxBeamOverhang = this.utils.getMaximumBeamOverhang()
    for(let i = 0; i < numOfRafter; i++) {
      const offsetXRafter = endCutoutLeft + (i + 1) * rafterSpacing
      if(UI.existingType == BUILDING_SIDE.LEFT || UI.existingType == BUILDING_SIDE.BOTH) {
        if(offsetXRafter > mainRoofLeft - maxBeamOverhang && offsetXRafter < mainRoofLeft + maxBeamOverhang) {
          continue
        }
      }
      if(UI.existingType == BUILDING_SIDE.RIGHT || UI.existingType == BUILDING_SIDE.BOTH) {
        if(offsetXRafter > mainRoofRight - maxBeamOverhang && offsetXRafter < mainRoofRight + maxBeamOverhang) {
          continue
        }
      }
      listRafterPos.push({
        offsetX: offsetXRafter,
        isMainRoof: offsetXRafter >= mainRoofLeft && offsetXRafter <= mainRoofRight
      })
    }

    listRafterPos = this.utils.reSeperateRafterPosition(listRafterPos, offsetX, offsetX + mainRoofLength, endCutoutLeft, endCutoutLeft + UI.totalBayLength )

    listRafterPos.forEach(el => {
      if(el.isMainRoof) {
        this.addRafterSuperiorBeam(el.offsetX, {
          span: true,
          first: false,
          last: false,
        });
        if (UI.multiSpan) {
          this.addRafterSuperiorBeam(el.offsetX, {
            multiSpan: true,
            first: false,
            last: false,
          });
        }
      } else {
        this.addRafterSuperiorBeam(el.offsetX, {
          cutOutForSpan: true,
          first: false,
          last: false,
        });
        if (UI.multiSpan) {
          this.addRafterSuperiorBeam(el.offsetX, {
            cutOutForMultiSpan: true,
            first: false,
            last: false,
          });
        }
      }
    })

    // // Add internal beam in here
    this.addInternalBeam(
      listRafterPos,
      numOfSpanBeam,
      numOfMultiSpanBeam
    );
  }
  private addInternalBeam(
    listRafterPos,
    numOfSpanBeam: number = 0,
    numOfMultiSpanBeam: number = 0
  ) {
    const startZ = -(UI.existingWidth1 / 2 + this.extraOffsetZ);
    if (UI.span > 0) {
      const internalBeamSpacing = UI.span / (numOfSpanBeam + 1);
      for (let i = 0; i < numOfSpanBeam; i++) {
        this.addInternalSuperiorBeam(
          listRafterPos,
          startZ + internalBeamSpacing * (i + 1)
        );
      }
    }
    if (UI.multiSpan > 0) {
      const internalBeamSpacing = UI.multiSpan / (numOfMultiSpanBeam + 1);
      for (let i = 0; i < numOfMultiSpanBeam; i++) {
        this.addInternalSuperiorBeam(
          listRafterPos,
          startZ + UI.span + internalBeamSpacing * (i + 1)
        );
      }
    }
  }
  public addInternalSuperiorBeam(
    listRafterPos,
    offsetZ: number
  ) {
    let fitX = this.getContainerOffsetXToFitBraketToExistingWall();
    const fitIfNotWidth2 =
      this.APP.existingWallManager.geo_existingWallW1.width +
      CONST.EAVE_OFFSET_BACK;

    let endMainLeftX = -UI.totalBayLength / 2 + fitX;
    let endMainRightX = UI.totalBayLength / 2 + fitX;
    let endCutoutLeft = -UI.totalBayLength / 2 + fitX;
    let endCutoutRight = UI.totalBayLength / 2 + fitX;
    if (UI.cutOutType == CUTOUT_ENABLE.YES && this.MANAGER.cutoutCondition) {
      // Enable cut out
      if (UI.existingType == BUILDING_SIDE.LEFT) {
        endMainLeftX =
          -UI.totalBayLength / 2 +
          fitX +
          UI.existingLength2 -
          (UI.existingWidth2 > 0 ? 0 : fitIfNotWidth2);
      } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
        endMainRightX =
          UI.totalBayLength / 2 +
          fitX -
          UI.existingLength2 +
          (UI.existingWidth2 > 0 ? 0 : fitIfNotWidth2);
      } else if (UI.existingType == BUILDING_SIDE.BOTH) {
        endMainLeftX =
          -UI.totalBayLength / 2 +
          fitX +
          UI.existingLength2 -
          (UI.existingWidth2 > 0 ? 0 : fitIfNotWidth2);
        endMainRightX =
          UI.totalBayLength / 2 +
          fitX -
          UI.existingLength2 +
          (UI.existingWidth2 > 0 ? 0 : fitIfNotWidth2);
      }
    }

    // Main roof
    const beamWidth = this.geo_superiorBeam.width;
    const mainRafter = listRafterPos.filter(el => el.isMainRoof)

    for (let i = 0; i <= mainRafter.length; i++) {
      let startX = endMainLeftX;
      if(i > 0) {
        startX = mainRafter[i - 1].offsetX
      }
      let endX = endMainRightX;
      if(i < mainRafter.length) {
        endX = mainRafter[i].offsetX
      }

      if (i == 0) {
        startX += beamWidth;
      } else {
        startX += beamWidth / 2;
      }

      if (i == mainRafter.length) {
        endX -= beamWidth;
      } else {
        endX -= beamWidth / 2;
      }
      this.addInternalBeamFromStartToEnd(startX, endX, offsetZ);
    }

    if (UI.cutOutType == CUTOUT_ENABLE.YES && this.MANAGER.cutoutCondition) {
      if (
        UI.existingType == BUILDING_SIDE.LEFT ||
        UI.existingType == BUILDING_SIDE.BOTH
      ) {
        const cutoutRoofLength =
          this.getCutoutRoofLengthToGenerateCutoutRafterBeam();
        const cutoutLeftRafter = listRafterPos.filter(el => !el.isMainRoof && el.offsetX < endCutoutLeft + cutoutRoofLength)

        if (offsetZ > UI.existingWidth1 / 2 - this.extraOffsetZ) {
          for (let i = 0; i <= cutoutLeftRafter.length; i++) {
            let startX = endCutoutLeft;
            if(i > 0) {
              startX = cutoutLeftRafter[i - 1].offsetX
            }
            let endX = endCutoutLeft + cutoutRoofLength;
            if(i < cutoutLeftRafter.length) {
              endX = cutoutLeftRafter[i].offsetX
            }

            if (i == 0) {
              startX += beamWidth;
            } else {
              startX += beamWidth / 2;
            }

            if (i == cutoutLeftRafter.length) {
            } else {
              endX -= beamWidth / 2;
            }
            this.addInternalBeamFromStartToEnd(startX, endX, offsetZ);
          }
        }
      }

      if (
        UI.existingType == BUILDING_SIDE.RIGHT ||
        UI.existingType == BUILDING_SIDE.BOTH
      ) {
        const cutoutRoofLength =
          this.getCutoutRoofLengthToGenerateCutoutRafterBeam();
        const cutoutRightRafter = listRafterPos.filter(el => !el.isMainRoof && el.offsetX > endCutoutRight - cutoutRoofLength)

        if (
          offsetZ >
          UI.existingWidth1 / 2 - this.extraOffsetZ + beamWidth * 2
        ) {
          for (let i = 0; i <= cutoutRightRafter.length; i++) {
            let startX = endCutoutRight - cutoutRoofLength;
            if(i > 0) {
              startX = cutoutRightRafter[i - 1].offsetX
            }
            let endX = endCutoutRight;
            if(i < cutoutRightRafter.length) {
              endX = cutoutRightRafter[i].offsetX
            }

            if (i == 0) {
              startX += 0;
            } else {
              startX += beamWidth / 2;
            }

            if (i == cutoutRightRafter.length) {
              endX -= beamWidth;
            } else {
              endX -= beamWidth / 2;
            }
            this.addInternalBeamFromStartToEnd(startX, endX, offsetZ);
          }
        }
      }
    }
  }
  public addInternalBeamFromStartToEnd(startX, endX, offsetZ) {
    const { fromX, toX, visible } = this.cutInternalBeamWithRakeCut(
      startX,
      endX,
      offsetZ
    );

    if (!visible) {
      return;
    }

    // Only add internal beam when beam length is greater than 200
    if (Math.abs(toX - fromX) < MIN_INTERNAL_BEAM_LENGTH) {
      return;
    }

    const beamWidth = this.geo_RafterBeam.width;
    const beamHeight = this.geo_superiorBeam.height;
    let scaleX = Math.abs(toX - fromX) / this.geo_superiorBeam.length;

    let views: Print2DView[] = [
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    const offsetY =
      UI.eaveHeight +
      this.getBracketHeight(
        offsetZ + UI.existingWidth1 / 2 + this.extraOffsetZ + beamWidth / 2
      );

    let mesh = new Mesh(
      this.geo_superiorBeam.geometry,
      MaterialManager.Instance().BEAM
    );
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_INTERNAL_BEAM,
      views: views,
    };
    mesh.position.set(fromX, 0, 0);
    mesh.scale.setX(scaleX);

    let capL = new Mesh(
      this.geo_beamEndCap.geometry,
      UI.beamLayoutShow ? MaterialManager.Instance().BRACKET_WARNING.clone() : MaterialManager.Instance().BEAM.clone()
    );
    capL.position.set(
      fromX + this.geo_beamEndCap.length / 2 - 2,
      -this.geo_beamEndCap.height + beamHeight,
      this.geo_beamEndCap.width / 2
    );
    capL.rotateY(Math.PI / 2);
    capL.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: getBeamEndCapCode(this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode),
    };
    let capR = new Mesh(
      this.geo_beamEndCap.geometry,
      UI.beamLayoutShow ? MaterialManager.Instance().BRACKET_WARNING.clone() : MaterialManager.Instance().BEAM.clone()
    );
    capR.position.set(
      toX - this.geo_beamEndCap.length / 2 + 2,
      -this.geo_beamEndCap.height + beamHeight,
      this.geo_beamEndCap.width / 2
    );
    capR.rotateY(-Math.PI / 2);
    capR.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: getBeamEndCapCode(this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode),
    };

    let beamGroup = new Group();
    beamGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_INTERNAL_BEAM,
      views: views,
    };
    beamGroup.position.set(0, offsetY, offsetZ - beamWidth / 2);
    if (this.APP.sltBeamType.currentValue == 0) {
      beamGroup.add(mesh, capL, capR);
    } else {
      beamGroup.add(mesh);
    }

    this.scene.add(beamGroup);
  }
  private cutInternalBeamWithRakeCut(startX, endX, offsetZ) {
    // startZ is start point of rafter beam
    // startZ > endZ
    const fromX = Math.min(startX, endX);
    const toX = Math.max(startX, endX);
    const beamWidth = this.geo_superiorBeam.width;
    const fitX = this.getContainerOffsetXToFitBraketToExistingWall();
    const offsetZFrontBeam =
      UI.span + UI.multiSpan - UI.existingWidth1 / 2 - this.extraOffsetZ;

    if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
      const rakeCutVerLength =
        this.beamLeftCutSizeInfo.v + this.beamLeftCutSizeInfo.k_;
      const rakeCutHorLength = this.beamLeftCutSizeInfo.l;

      const beamLine = new Line3(
        new Vector3(toX, 0, offsetZ + beamWidth / 2),
        new Vector3(fromX, 0, offsetZ + beamWidth / 2)
      );
      const beamRakeCutLength = Math.sqrt(
        Math.pow(rakeCutHorLength, 2) + Math.pow(rakeCutVerLength, 2)
      );
      const cosAngleFrontHor = rakeCutHorLength / beamRakeCutLength;

      const startRakeCut = new Vector3(
        -UI.totalBayLength / 2 + fitX,
        0,
        offsetZFrontBeam - rakeCutVerLength - beamWidth / cosAngleFrontHor
      );
      const endRakeCut = new Vector3(
        -UI.totalBayLength / 2 + fitX + rakeCutHorLength,
        0,
        offsetZFrontBeam - beamWidth / cosAngleFrontHor
      );
      const rakeCutLine = new Line3(startRakeCut, endRakeCut);
      const intersection = this.utils.getIntersectionOnAPoint(
        beamLine,
        rakeCutLine
      );

      if (intersection) {
        if (
          this.utils.isPositive(beamLine.start, beamLine.end, intersection) &&
          this.utils.isPositive(
            rakeCutLine.start,
            rakeCutLine.end,
            intersection
          ) &&
          intersection.x >= fromX &&
          intersection.x <= toX
        ) {
          return {
            fromX: intersection.x,
            toX,
            visible: true,
          };
        } else {
          if (intersection.x > fromX && intersection.x > toX) {
            return {
              fromX,
              toX,
              visible: false,
            };
          }
        }
      }
    } else if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      const beamLine = new Line3(
        new Vector3(fromX, 0, offsetZ + beamWidth / 2),
        new Vector3(toX, 0, offsetZ + beamWidth / 2)
      );
      const startRakeCut = new Vector3(
        -UI.totalBayLength / 2 + fitX + UI.rakeCutLeftHor + beamWidth,
        0,
        offsetZFrontBeam
      );
      const endRakeCut = new Vector3(
        -UI.totalBayLength / 2 + fitX + UI.rakeCutLeftHor + beamWidth,
        0,
        offsetZFrontBeam - UI.rakeCutLeftVer - beamWidth
      );

      const rakeCutLine = new Line3(endRakeCut, startRakeCut);
      const intersection = this.utils.getIntersectionOnAPoint(
        beamLine,
        rakeCutLine,
        true
      );

      if (intersection) {
        if (
          this.utils.isPositive(beamLine.start, beamLine.end, intersection) &&
          this.utils.isPositive(
            rakeCutLine.start,
            rakeCutLine.end,
            intersection
          ) &&
          intersection.x >= fromX &&
          intersection.x <= toX
        ) {
          return {
            fromX: intersection.x,
            toX,
            visible: true,
          };
        } else {
          if (intersection.x > fromX && intersection.x > toX) {
            return {
              fromX,
              toX,
              visible: false,
            };
          }
        }
      }
    }

    if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
      const rakeCutVerLength =
        this.beamRightCutSizeInfo.v + this.beamRightCutSizeInfo.k_;
      const rakeCutHorLength = this.beamRightCutSizeInfo.l;

      const beamLine = new Line3(
        new Vector3(fromX, 0, offsetZ + beamWidth / 2),
        new Vector3(toX, 0, offsetZ + beamWidth / 2)
      );
      const beamRakeCutLength = Math.sqrt(
        Math.pow(rakeCutHorLength, 2) + Math.pow(rakeCutVerLength, 2)
      );
      const cosAngleFrontHor = rakeCutHorLength / beamRakeCutLength;

      const startRakeCut = new Vector3(
        UI.totalBayLength / 2 + fitX,
        0,
        offsetZFrontBeam - rakeCutVerLength - beamWidth / cosAngleFrontHor
      );
      const endRakeCut = new Vector3(
        UI.totalBayLength / 2 + fitX - rakeCutHorLength,
        0,
        offsetZFrontBeam - beamWidth / cosAngleFrontHor
      );
      const rakeCutLine = new Line3(startRakeCut, endRakeCut);
      const intersection = this.utils.getIntersectionOnAPoint(
        beamLine,
        rakeCutLine
      );

      if (intersection) {
        if (
          this.utils.isPositive(beamLine.start, beamLine.end, intersection) &&
          this.utils.isPositive(
            rakeCutLine.start,
            rakeCutLine.end,
            intersection
          ) &&
          intersection.x >= fromX &&
          intersection.x <= toX
        ) {
          return {
            fromX,
            toX: intersection.x,
            visible: true,
          };
        } else {
          if (intersection.x < fromX && intersection.x < toX) {
            return {
              fromX,
              toX,
              visible: false,
            };
          }
        }
      }
    } else if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      const beamLine = new Line3(
        new Vector3(toX, 0, offsetZ + beamWidth / 2),
        new Vector3(fromX, 0, offsetZ + beamWidth / 2)
      );
      const startRakeCut = new Vector3(
        UI.totalBayLength / 2 + fitX - UI.rakeCutRightHor - beamWidth,
        0,
        offsetZFrontBeam
      );
      const endRakeCut = new Vector3(
        UI.totalBayLength / 2 + fitX - UI.rakeCutRightHor - beamWidth,
        0,
        offsetZFrontBeam - UI.rakeCutRightVer - beamWidth
      );

      const rakeCutLine = new Line3(endRakeCut, startRakeCut);
      const intersection = this.utils.getIntersectionOnAPoint(
        beamLine,
        rakeCutLine,
        true
      );

      if (intersection) {
        if (
          this.utils.isPositive(beamLine.start, beamLine.end, intersection) &&
          this.utils.isPositive(
            rakeCutLine.start,
            rakeCutLine.end,
            intersection
          ) &&
          intersection.x >= fromX &&
          intersection.x <= toX
        ) {
          return {
            fromX,
            toX: intersection.x,
            visible: true,
          };
        } else {
          if (intersection.x < fromX && intersection.x < toX) {
            return {
              fromX,
              toX,
              visible: false,
            };
          }
        }
      }
    }

    return {
      fromX,
      toX,
      visible: true,
    };
  }

  public getCutoutRoofLengthToGenerateCutoutRafterBeam() {
    let extraWallWidth =
      this.APP.existingWallManager.geo_existingWallW1.width +
      CONST.EAVE_OFFSET_BACK;
    let beamLength =
      UI.existingLength2 -
      this.APP.existingWallManager.geo_existingWallW1.width;

    if (UI.existingType == BUILDING_SIDE.LEFT) {
      if (UI.existingWidth2 > 0) {
      } else {
        beamLength -= extraWallWidth;
      }

      beamLength += this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width;
    } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
      if (UI.existingWidth2 > 0) {
      } else {
        beamLength -= extraWallWidth;
      }

      beamLength += this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width;
    } else if (UI.existingType == BUILDING_SIDE.BOTH) {
      beamLength = UI.existingLength2 - extraWallWidth;

      if (UI.existingWidth2 > 0) {
        beamLength = UI.existingLength2;
      }
    }

    return beamLength;
  }
  /**
   *
   * @param offsetX : Position x of rafter beam
   */
  public addRafterSuperiorBeam(offsetX, userDataPos: any) {
    const beamWidth = this.geo_superiorBeam.width;
    const beamHeight = this.geo_RafterBeam.height;
    const beamGeoLength = this.geo_RafterBeam.length;
    let mesh;
    let beamLength =
      (UI.span -
        (UI.multiSpan
          ? this.geo_superiorBeam.width * 1.5
          : this.geo_superiorBeam.width * 2)) /
      this.utils.cos(UI.patiosPitch);
    if (userDataPos.multiSpan) {
      beamLength =
        (UI.multiSpan - beamWidth * 1.5) / this.utils.cos(UI.patiosPitch);
    } else if (userDataPos.cutOutForSpan) {
      if (
        UI.multiSpan == 0 &&
        UI.span - UI.existingWidth1 - 2 * beamWidth <= 0
      ) {
        return;
      } else if (
        UI.multiSpan > 0 &&
        UI.span - UI.existingWidth1 - 1.5 * beamWidth <= 0
      ) {
        return;
      }

      if (UI.multiSpan == 0) {
        beamLength =
          (UI.span - beamWidth * 2 - UI.existingWidth1) /
          this.utils.cos(UI.patiosPitch);
      } else {
        beamLength =
          (UI.span - beamWidth * 1.5 - UI.existingWidth1) /
          this.utils.cos(UI.patiosPitch);
      }
    } else if (userDataPos.cutOutForMultiSpan) {
      if (UI.span + UI.multiSpan - UI.existingWidth1 - 2 * beamWidth <= 0) {
        return;
      }

      if (UI.existingWidth1 > UI.span) {
        beamLength =
          (UI.span + UI.multiSpan - beamWidth * 2 - UI.existingWidth1) /
          this.utils.cos(UI.patiosPitch);
      } else {
        beamLength =
          (UI.multiSpan - beamWidth * 1.5) / this.utils.cos(UI.patiosPitch);
      }
    } else if (userDataPos.rakeCutLeft) {
      beamLength =
        (UI.rakeCutLeftVer - beamWidth) / this.utils.cos(UI.patiosPitch);
    } else if (userDataPos.rakeCutRight) {
      beamLength =
        (UI.rakeCutRightVer - beamWidth) / this.utils.cos(UI.patiosPitch);
    }

    let offsetZ = 0;
    let offsetY = 0;

    // Scene position z move back
    // The plane be translated
    let shouldCutByRakeCut = true;
    if (userDataPos.span || userDataPos.cutOutForSpan) {
      offsetY =
        UI.eaveHeight +
        this.totalHeightFromEaveHeightToTopOfFlyoverBraket() -
        (UI.span - (UI.multiSpan ? beamWidth / 2 : beamWidth)) *
          this.utils.tan(UI.patiosPitch);
      offsetZ =
        UI.span -
        (UI.multiSpan ? beamWidth / 2 : beamWidth) -
        UI.existingWidth1 / 2 -
        this.extraOffsetZ;
    } else if (
      userDataPos.multiSpan ||
      userDataPos.cutOutForMultiSpan ||
      userDataPos.rakeCutLeft ||
      userDataPos.rakeCutRight
    ) {
      if (userDataPos.rakeCutLeft || userDataPos.rakeCutRight) {
        shouldCutByRakeCut = false;
      }
      offsetY =
        UI.eaveHeight +
        this.totalHeightFromEaveHeightToTopOfFlyoverBraket() -
        (UI.span + UI.multiSpan - beamWidth) * this.utils.tan(UI.patiosPitch);
      offsetZ =
        UI.span +
        UI.multiSpan -
        beamWidth -
        UI.existingWidth1 / 2 -
        this.extraOffsetZ;
    }

    let offsetXRafter = offsetX;
    if (userDataPos.last) {
      offsetXRafter = offsetX - this.geo_RafterBeam.width;
    } else if (!userDataPos.first && !userDataPos.last) {
      offsetXRafter = offsetX - this.geo_RafterBeam.width / 2;
    }

    if (shouldCutByRakeCut) {
      const { newStartZ, newLength, fitYForNewStartZPoint } =
        this.cutRafterBeamWithRakeCut(offsetZ, offsetXRafter, beamLength);
      if (!_.isNil(newStartZ)) {
        offsetZ = newStartZ;
      }
      if (!_.isNil(newLength)) {
        beamLength = newLength;
      }
      if (!_.isNil(fitYForNewStartZPoint)) {
        offsetY += fitYForNewStartZPoint;
      }
    }

    let scaleX = beamLength / beamGeoLength;
    scaleX += (beamHeight * this.utils.tan(UI.patiosPitch)) / beamGeoLength;

    let views: Print2DView[] = [
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    let material = this.getRafterBeamMaterial(userDataPos, offsetZ, beamLength);
    mesh = new Mesh(this.geo_RafterBeam.geometry, material);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM,
      position: userDataPos,
      views: views,
    };
    mesh.position.set(0, 0, 0);
    mesh.scale.setX(scaleX);

    let { capL, capR } = this.getBeamEndCapForRafterBeam(0, beamLength);

    let beamGroup = new Group();
    beamGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM,
      position: userDataPos,
      views: views,
    };

    beamGroup.position.set(offsetXRafter, offsetY, offsetZ);
    if (this.APP.sltBeamType.currentValue == 0) {
      beamGroup.add(mesh, capL, capR);
    } else {
      beamGroup.add(mesh);
    }

    beamGroup.rotateY(Math.PI / 2);

    beamGroup.rotateZ(this.utils.degreesToRadians(UI.patiosPitch));

    this.scene.add(beamGroup);
  }
  private cutRafterBeamWithRakeCut(offsetZ, offsetX, beamLength) {
    // startZ is start point of rafter beam
    // startZ > endZ
    const beamWidth = this.geo_superiorBeam.width;
    let startZ = offsetZ;
    let endZ = offsetZ - beamLength * this.utils.cos(UI.patiosPitch);
    const fitX = this.getContainerOffsetXToFitBraketToExistingWall();
    const offsetZFrontBeam =
      UI.span + UI.multiSpan - UI.existingWidth1 / 2 - this.extraOffsetZ;

    if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
      const rakeCutVerLength =
        this.beamLeftCutSizeInfo.v + this.beamLeftCutSizeInfo.k_;
      const rakeCutHorLength = this.beamLeftCutSizeInfo.l;

      const beamLine = new Line3(
        new Vector3(offsetX, 0, startZ),
        new Vector3(offsetX, 0, endZ)
      );
      const beamRakeCutLength = Math.sqrt(
        Math.pow(rakeCutHorLength, 2) + Math.pow(rakeCutVerLength, 2)
      );
      const cosAngleFrontHor = rakeCutHorLength / beamRakeCutLength;

      const startRakeCut = new Vector3(
        -UI.totalBayLength / 2 + fitX,
        0,
        offsetZFrontBeam - rakeCutVerLength - beamWidth / cosAngleFrontHor
      );
      const endRakeCut = new Vector3(
        -UI.totalBayLength / 2 + fitX + rakeCutHorLength,
        0,
        offsetZFrontBeam - beamWidth / cosAngleFrontHor
      );

      const intersection = this.utils.getIntersectionOnAPoint(
        beamLine,
        new Line3(startRakeCut, endRakeCut)
      );

      if (intersection && intersection.z < startZ) {
        const newStartZ = intersection.z;
        const newLength =
          Math.abs(newStartZ - endZ) / this.utils.cos(UI.patiosPitch);
        const fitYForNewStartZPoint =
          Math.abs(startZ - newStartZ) * this.utils.tan(UI.patiosPitch);

        return { newStartZ, newLength, fitYForNewStartZPoint };
      }
    } else if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      const beamLine = new Line3(
        new Vector3(offsetX + 2, 0, startZ),
        new Vector3(offsetX + 2, 0, endZ)
      );
      const startRakeCut = new Vector3(
        -UI.totalBayLength / 2 + fitX,
        0,
        offsetZFrontBeam - UI.rakeCutLeftVer - beamWidth
      );
      const endRakeCut = new Vector3(
        -UI.totalBayLength / 2 + fitX + UI.rakeCutLeftHor + beamWidth,
        0,
        offsetZFrontBeam - UI.rakeCutLeftVer - beamWidth
      );

      const intersection = this.utils.getIntersectionOnAPoint(
        beamLine,
        new Line3(endRakeCut, startRakeCut),
        true
      );

      if (intersection && intersection.z < startZ) {
        const newStartZ = intersection.z;
        const newLength =
          Math.abs(newStartZ - endZ) / this.utils.cos(UI.patiosPitch);
        const fitYForNewStartZPoint =
          Math.abs(startZ - newStartZ) * this.utils.tan(UI.patiosPitch);

        return { newStartZ, newLength, fitYForNewStartZPoint };
      }
    }

    if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
      const rakeCutVerLength =
        this.beamRightCutSizeInfo.v + this.beamRightCutSizeInfo.k_;
      const rakeCutHorLength = this.beamRightCutSizeInfo.l;

      const beamLine = new Line3(
        new Vector3(offsetX + beamWidth, 0, startZ),
        new Vector3(offsetX + beamWidth, 0, endZ)
      );
      const beamRakeCutLength = Math.sqrt(
        Math.pow(rakeCutHorLength, 2) + Math.pow(rakeCutVerLength, 2)
      );
      const cosAngleFrontHor = rakeCutHorLength / beamRakeCutLength;

      const startRakeCut = new Vector3(
        UI.totalBayLength / 2 + fitX,
        0,
        offsetZFrontBeam - rakeCutVerLength - beamWidth / cosAngleFrontHor
      );
      const endRakeCut = new Vector3(
        UI.totalBayLength / 2 + fitX - rakeCutHorLength,
        0,
        offsetZFrontBeam - beamWidth / cosAngleFrontHor
      );

      const intersection = this.utils.getIntersectionOnAPoint(
        beamLine,
        new Line3(startRakeCut, endRakeCut)
      );

      if (intersection && intersection.z < startZ) {
        const newStartZ = intersection.z;
        const newLength =
          Math.abs(newStartZ - endZ) / this.utils.cos(UI.patiosPitch);
        const fitYForNewStartZPoint =
          Math.abs(startZ - newStartZ) * this.utils.tan(UI.patiosPitch);

        return { newStartZ, newLength, fitYForNewStartZPoint };
      }
    } else if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      const beamLine = new Line3(
        new Vector3(offsetX + beamWidth - 2, 0, startZ),
        new Vector3(offsetX + beamWidth - 2, 0, endZ)
      );
      const startRakeCut = new Vector3(
        UI.totalBayLength / 2 + fitX,
        0,
        offsetZFrontBeam - UI.rakeCutRightVer - beamWidth
      );
      const endRakeCut = new Vector3(
        UI.totalBayLength / 2 + fitX - UI.rakeCutRightHor - beamWidth,
        0,
        offsetZFrontBeam - UI.rakeCutRightVer - beamWidth
      );

      const intersection = this.utils.getIntersectionOnAPoint(
        beamLine,
        new Line3(endRakeCut, startRakeCut),
        true
      );

      if (intersection && intersection.z < startZ) {
        const newStartZ = intersection.z;
        const newLength =
          Math.abs(newStartZ - endZ) / this.utils.cos(UI.patiosPitch);
        const fitYForNewStartZPoint =
          Math.abs(startZ - newStartZ) * this.utils.tan(UI.patiosPitch);

        return { newStartZ, newLength, fitYForNewStartZPoint };
      }
    }

    return { newStartZ: null, newLength: null, fitYForNewStartZPoint: null };
  }
  private getBeamEndCapForRafterBeam(offsetXL, offsetXR) {
    let capL;
    let capR;
    let matEndCap = this.getRafterBeamEndCapMaterial();

    let checkExec = /\w{1}\d{1,3}x(\d{1,3})/.exec(this.geo_RafterBeam.name);
    let beamSize = checkExec && checkExec.length == 2 ? checkExec[1] : "";
    let lowerBraketName = getBeamEndCapCode(this.geo_rafterBeamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode);
    let higherBraketName = getBeamEndCapCode(this.geo_rafterBeamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode);

    capL = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
    capL.position.set(offsetXL - 2, 0, this.geo_rafterBeamEndCap.width / 2);
    capL.scale.setY(
      (this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
        this.geo_rafterBeamEndCap.length * this.utils.tan(UI.patiosPitch)) /
        this.geo_rafterBeamEndCap.height
    );
    capL.rotateY(Math.PI / 2);
    capL.rotateX(this.utils.degreesToRadians(UI.patiosPitch));
    capL.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: lowerBraketName,
    };

    capR = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
    capR.position.set(
      offsetXR + 2,
      -this.geo_rafterBeamEndCap.length * this.utils.tan(UI.patiosPitch),
      this.geo_rafterBeamEndCap.width / 2
    );
    capR.scale.setY(
      (this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
        this.geo_rafterBeamEndCap.length * this.utils.tan(UI.patiosPitch)) /
        this.geo_rafterBeamEndCap.height
    );
    capR.rotateY(-Math.PI / 2);
    capR.rotateX(-this.utils.degreesToRadians(UI.patiosPitch));
    capR.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: higherBraketName,
    };

    return {
      capL,
      capR,
    };
  }
  private getRafterBeamEndCapMaterial() {
    const matEndCap = UI.beamLayoutShow ? MaterialManager.Instance().BRACKET_WARNING.clone() : MaterialManager.Instance().BEAM.clone();
    let heightPlane =
      UI.existingWallHeight +
      this.totalHeightFromEaveHeightToTopOfFlyoverBraket() +
      this.geo_superiorBeam.height;
    let planeTop = new Plane(new Vector3(0, -1, 0));
    let matrixTranslateTop = new Matrix4().makeTranslation(0, heightPlane, 0);
    let matrixRotateTop = new Matrix4().makeRotationX(
      this.utils.degreesToRadians(UI.patiosPitch)
    ); //tao voi z pitch do
    let matrixTotalTop = matrixTranslateTop.multiply(matrixRotateTop);
    planeTop.applyMatrix4(matrixTotalTop);

    let heightRealBottom =
      UI.existingWallHeight +
      this.totalHeightFromEaveHeightToTopOfFlyoverBraket();

    let planeBottom = new Plane(new Vector3(0, 1, 0));
    let matrixTranslateBottom = new Matrix4().makeTranslation(
      0,
      heightRealBottom,
      0
    );
    let matrixRotateBottom = new Matrix4().makeRotationX(
      this.utils.degreesToRadians(UI.patiosPitch)
    ); //tao voi z pitch do
    let matrixTotalBottom = matrixTranslateBottom.multiply(matrixRotateBottom);
    planeBottom.applyMatrix4(matrixTotalBottom);

    planeTop.translate(
      new Vector3(
        0,
        0,
        -UI.existingWidth1 / 2 - this.extraOffsetZ + this.scene.position.z
      )
    );
    planeBottom.translate(
      new Vector3(
        0,
        0,
        -UI.existingWidth1 / 2 - this.extraOffsetZ + this.scene.position.z
      )
    );
    matEndCap.clippingPlanes = [planeTop, planeBottom];

    return matEndCap;
  }
  private getRafterBeamMaterial(userDataPos, offsetZ, beamLength) {
    let material = MaterialManager.Instance().BEAM.clone();

    const beamWidth = this.geo_superiorBeam.width;
    const braketWidth = this.geo_bracket.width;

    let cutFrontZ = offsetZ + this.extraOffsetZ - braketWidth;
    let cutBackZ =
      offsetZ +
      this.extraOffsetZ -
      braketWidth -
      beamLength * this.utils.cos(UI.patiosPitch);

    if (cutFrontZ != 0 || cutBackZ != 0) {
      let nBottom = new Vector3(0, 0, -1).normalize();
      let pBottom = new Vector3(0, 0, cutFrontZ);
      let planeBottom = new Plane().setFromNormalAndCoplanarPoint(
        nBottom,
        pBottom
      );

      let nVer = new Vector3(0, 0, 1).normalize();
      let pVer = new Vector3(0, 0, cutBackZ);
      let planeVer = new Plane().setFromNormalAndCoplanarPoint(nVer, pVer);

      material.clippingPlanes = [planeBottom, planeVer];
    }

    return material;
  }
  private totalHeightFromEaveHeightToTopOfExistingRoof() {
    const height =
      this.geometryManager.EAVE.EAVE.height +
      UI.fasciaDepth +
      UI.eaveWidth * this.utils.tan(UI.existingRoofPitch) +
      this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.height /
        this.utils.cos(UI.existingRoofPitch);

    return height;
  }
  private totalHeightFromEaveHeightToTopOfFlyoverBraket() {
    const height =
      this.totalHeightFromEaveHeightToTopOfExistingRoof() +
      UI.braketHeight -
      FIT_FLYOVER_BRAKET_ON_ROOF;

    return height;
  }
  private addDownPipeStepRakecut() {
    let fitX = this.getContainerOffsetXToFitBraketToExistingWall();
    if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      let offsetX = -UI.totalBayLength / 2 + 50 + fitX;
      let offsetY =
        this.utils.getHeightByAngle(
          UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
          UI.span + UI.multiSpan + UI.overhangFront - UI.rakeCutLeftVer,
          UI.patiosPitch,
          -1
        ) +
        this.geo_superiorBeam.height -
        30;
      let offsetZ =
        UI.span +
        UI.multiSpan +
        UI.overhangFront -
        UI.rakeCutLeftVer -
        260 -
        UI.existingWidth1 / 2;

      if (this.APP.sltGutterType.currentValue == 0) {
        offsetY -= 80;
      } else {
        offsetY -= 60;
      }

      this.utils.createDownpipeGroup(
        this.APP,
        this.downpipeStepRakeCutGroupLeft,
        this.geo_downPipe,
        this.geo_downPipeL,
        offsetX,
        offsetY,
        offsetZ,
        offsetZ,
        0,
        UI.overhangFront,
        offsetY,
        false
      );
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      let offsetX = UI.totalBayLength / 2 - 50 + fitX;
      let offsetY =
        this.utils.getHeightByAngle(
          UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
          UI.span + UI.multiSpan + UI.overhangFront - UI.rakeCutRightVer,
          UI.patiosPitch,
          -1
        ) +
        this.geo_superiorBeam.height -
        30;
      let offsetZ =
        UI.span +
        UI.multiSpan +
        UI.overhangFront -
        UI.rakeCutRightVer -
        260 -
        UI.existingWidth1 / 2;

      if (this.APP.sltGutterType.currentValue == 0) {
        offsetY -= 80;
      } else {
        offsetY -= 60;
      }

      this.utils.createDownpipeGroup(
        this.APP,
        this.downpipeStepRakeCutGroupRight,
        this.geo_downPipe,
        this.geo_downPipeL,
        offsetX,
        offsetY,
        offsetZ,
        offsetZ,
        0,
        UI.overhangFront,
        offsetY,
        false
      );
    }
  }
  private addDownPipe() {
    let offsetZ = UI.span + UI.multiSpan - 170;
    let offsetX = -UI.totalBayLength / 2 + 50;
    if (UI.existingType == 1) {
      offsetX = UI.totalBayLength / 2 - 50;
    }
    if (UI.existingType != 0) {
      offsetZ -= UI.existingWidth1 / 2;
    }
    //Rakecut
    //Downpipe will be in left
    if (UI.rakeCutLeftType != RAKECUT_TYPE.NONE) {
      if (UI.existingType == 1 && UI.rakeCutRightType == RAKECUT_TYPE.NONE) {
        offsetX = UI.totalBayLength / 2 - 50;
      } else if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
        offsetX = -UI.totalBayLength / 2 + this.beamLeftCutSizeInfo.l;
      } else if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
        offsetX = -UI.totalBayLength / 2 + UI.rakeCutLeftHor;
      }
    }
    //Downpipe will be in right
    else {
      if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
        offsetX = UI.totalBayLength / 2 - this.beamRightCutSizeInfo.l;
      } else if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
        offsetX = UI.totalBayLength / 2 - UI.rakeCutRightHor;
      }
    }

    //this.downpipeGroup.position.set(offsetX, 0, 0);

    let offsetY =
      this.utils.getHeightByAngle(
        UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
        UI.span + UI.multiSpan + UI.overhangFront,
        UI.patiosPitch,
        -1
      ) +
      this.geo_superiorBeam.height +
      90;

    if (this.APP.sltGutterType.currentValue == 0) {
      offsetY -= 80;
    } else {
      offsetY -= 60;
    }

    this.utils.createDownpipeGroup(
      this.APP,
      this.downpipeGroup,
      this.geo_downPipe,
      this.geo_downPipeL,
      offsetX,
      offsetY,
      offsetZ,
      offsetZ,
      0,
      UI.overhangFront,
      offsetY,
      true
    );
  }

  public houseBeamHeight() {
    return this.geo_superiorBeam.height - this.geo_superiorHouseBeam.height;
  }
  private updateUI() {
    this.APP.sldMinHeight.setValue(this.frontPostHeight);
  }
  public getSection(): Printing2DGeometry {
    let objs = this.scene.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_POST ||
        o.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET
    );

    objs = [...objs];

    let lsGeometries: Printing2DLine[] = [];

    for (let o of objs) {
      let box = new BoxHelper(o);
      box.geometry.translate(0, 5000, 0);
      box.userData = { type: "COLUMN_OUTLINE" };
      //this.scene.add(box);

      let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: outlineGeo.vertices,
        views: o.userData.views,
      });
    }
    let beamGroups = this.scene.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM ||
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_INTERNAL_BEAM
    );
    for (let g of beamGroups) {
      for (let c of g.children) {
        c.updateMatrix();

        let objCloned = c.clone();
        objCloned.applyMatrix4(new Matrix4().getInverse(c.matrix));

        const planView = _.find(
          g.userData.views,
          (el) => el.viewType == ViewType.PLAN
        );
        if (planView) {
          this.utils.getPlanVerticesOfBeam(c as Mesh, lsGeometries, [planView]);
        }

        let box = new BoxHelper(objCloned);
        c.updateWorldMatrix(true, true);
        box.geometry.applyMatrix4(c.matrixWorld);
        box.geometry.translate(0, 5000, 0);
        box.userData = { type: "COLUMN_OUTLINE" };

        let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
        lsGeometries.push({
          objectType: g.userData.type,
          vertices: outlineGeo.vertices,
          views: _.filter(
            g.userData.views,
            (el) => el.viewType != ViewType.PLAN
          ),
        });
      }
    }

    let rafterBeamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
    );
    for (let o of rafterBeamGroups) {
      o.children.forEach((el) => {
        this.utils.getOutlineRafterBeam(el as Mesh, lsGeometries);
      });
    }

    return { lines: lsGeometries, texts: [] };
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    //let simplifiedGeo = new Geometry();
    let vertices = geo.getAttribute("position").array;
    let lineGeo = new Geometry();
    for (let i = 0; i < vertices.length; i += 3) {
      //simplifiedGeo.vertices.push();

      lineGeo.vertices.push(
        new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2])
      );
      //lineGeo.vertices.push(new Vector3(vertices[i+3], vertices[i+4], vertices[i+5]));

      //if(i == 18) break;
    }

    //2-3
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    //3-7
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    //7-6
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    //6-2
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );

    //1-4
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    //4-8
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    //8-5
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    //5-1
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );

    //let line = new LineSegments(lineGeo, new LineBasicMaterial({color: new Color('red')}));
    //this.scene.add(line);
    return lineGeo;
  }

  private addGround() {
    let offsetZ = -(
      UI.existingWidth1 / 2 +
      this.extraOffsetZ +
      this.APP.sldBackOverhang.currentValue
    );
    let width = UI.totalBayLength + UI.overhangLeft + UI.overhangRight;
    let length =
      UI.span +
      UI.multiSpan +
      UI.overhangFront +
      this.APP.sldBackOverhang.currentValue;

    let base = new Mesh(
      this.geo_groundBase.geometry,
      MaterialManager.Instance().BASE
    );
    base.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.GROUND_BASE,
    };
    base.position.set(0, 0, offsetZ);
    let offsetX =
      this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
      (this.geo_bracket?.width || 0) / 2;
    if (UI.existingType == 1) {
      base.position.setX(offsetX);
    }
    if (UI.existingType == 2) {
      base.position.setX(-offsetX);
    }
    base.scale.set(
      width / this.geo_groundBase.width,
      1,
      length / this.geo_groundBase.length
    );

    this.scene.add(base);
  }
  private addPostOrBracket(offsetX: number, userDataPos: any): void {
    let meshPost = new Mesh(
      this.geo_superiorPost.geometry,
      MaterialManager.Instance().POST
    );
    meshPost.userData = {
      position: userDataPos,
      type: GEOMETRY_TYPE.SUPERIOR_POST,
      category: GEOMETRY_CATEGORY.PATIOS,
    };

    let meshBracket = new Mesh(
      this.geo_bracket.geometry,
      MaterialManager.Instance().BRACKET
    );
    meshBracket.userData = {
      position: userDataPos,
      type: GEOMETRY_TYPE.FLY_OVER_BRACKET,
      category: GEOMETRY_CATEGORY.PATIOS,
    };

    let offsetY =
      UI.eaveHeight +
      this.totalHeightFromEaveHeightToTopOfExistingRoof() -
      FIT_FLYOVER_BRAKET_ON_ROOF;
    let postOffsetZ = 0;
    let bracketOffsetZ = 0;
    let scalePostY = 1;
    let scaleBracketY = 1;

    let views: Print2DView[];
    if (userDataPos.front) {
      postOffsetZ = UI.span - UI.existingWidth1 / 2 - this.extraOffsetZ;
      bracketOffsetZ = UI.span - UI.existingWidth1 / 2 - this.extraOffsetZ;
      this.frontPostHeight = UI.eaveHeight + this.getBracketHeight(UI.span);
      scalePostY = this.frontPostHeight / this.geo_superiorPost.height;
      scaleBracketY =
        (this.getBracketHeight(UI.span) -
          this.totalHeightFromEaveHeightToTopOfExistingRoof() +
          FIT_FLYOVER_BRAKET_ON_ROOF) /
        this.geometryManager.FLY_OVER_BRACKET.S65x3.height;

      if (UI.multiSpan > 0) {
        postOffsetZ -= this.geo_superiorPost.width / 2;
        bracketOffsetZ -= this.geometryManager.FLY_OVER_BRACKET.S65x3.width / 2;
      } else {
        postOffsetZ -= this.geo_superiorPost.width;
        bracketOffsetZ -= this.geometryManager.FLY_OVER_BRACKET.S65x3.width;
      }

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }
    // if (userDataPos.back) {
    //   postOffsetZ =
    //     -UI.existingWidth1 / 2 - this.extraOffsetZ;
    //   bracketOffsetZ =
    //     -UI.existingWidth1 / 2 - this.extraOffsetZ;
    //   scalePostY =
    //     (UI.eaveHeight +
    //       this.totalHeightFromEaveHeightToTopOfFlyoverBraket()) /
    //     this.geo_superiorPost.height;
    //   scaleBracketY =
    //     this.totalHeightFromEaveHeightToTopOfFlyoverBraket() / this.geo_bracket.height;

    //   views = [
    //     { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
    //     { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
    //     { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    //   ];
    // }
    if (userDataPos.multiSpan) {
      postOffsetZ =
        UI.multiSpan +
        UI.span -
        UI.existingWidth1 / 2 -
        this.geo_superiorPost.width -
        this.extraOffsetZ;
      bracketOffsetZ =
        UI.multiSpan +
        UI.span -
        UI.existingWidth1 / 2 -
        this.geometryManager.FLY_OVER_BRACKET.S65x3.width -
        this.extraOffsetZ;
      scalePostY =
        (UI.eaveHeight + this.getBracketHeight(UI.span + UI.multiSpan)) /
        this.geo_superiorPost.height;
      scaleBracketY =
        (this.getBracketHeight(UI.span + UI.multiSpan) -
          this.totalHeightFromEaveHeightToTopOfExistingRoof() +
          FIT_FLYOVER_BRAKET_ON_ROOF) /
        this.geo_bracket.height;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    meshPost.userData.views = views;
    meshBracket.userData.views = views;

    let bracketOffsetX = offsetX;
    let postOffsetX = offsetX;
    if (userDataPos.first) {
    } else if (userDataPos.last) {
      bracketOffsetX -= this.geo_bracket.width;
      postOffsetX -= this.geo_superiorPost.width;
    } else {
      bracketOffsetX -= this.geo_bracket.width / 2;
      postOffsetX -= this.geo_superiorPost.width / 2;
    }

    let exceedMoveBackLimit = false;
    //Rakecut
    if (
      UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE ||
      UI.rakeCutLeftType == RAKECUT_TYPE.STEP
    ) {
      let moveToBack = false;
      if (UI.multiSpan > 0) {
        if (userDataPos.multiSpan && userDataPos.first) {
          moveToBack = true;
        }
      } else {
        if (!userDataPos.multiSpan && userDataPos.first) {
          moveToBack = true;
        }
      }

      if (moveToBack) {
        if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
          let moveBack = UI.rakeCutLeftVer;
          if (moveBack > 0) {
            postOffsetZ -= moveBack;
            bracketOffsetZ -= moveBack;
            let length = UI.span + UI.multiSpan - moveBack;
            scalePostY =
              this.utils.getHeightByAngle(
                UI.eaveHeight +
                  this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
                length,
                UI.patiosPitch,
                -1
              ) / this.geo_superiorPost.height;
            scaleBracketY =
              (this.getBracketHeight(
                UI.span + UI.multiSpan - UI.rakeCutLeftVer
              ) -
                this.totalHeightFromEaveHeightToTopOfExistingRoof() +
                FIT_FLYOVER_BRAKET_ON_ROOF) /
              this.geo_bracket.height;
          }
        } else if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
          postOffsetX -= UI.overhangLeft;
          bracketOffsetX -= UI.overhangLeft;
          postOffsetZ -=
            this.beamLeftCutSizeInfo.v + this.beamLeftCutSizeInfo.k_;
          bracketOffsetZ -=
            this.beamLeftCutSizeInfo.v + this.beamLeftCutSizeInfo.k_;
          let length =
            UI.span +
            UI.multiSpan -
            (this.beamLeftCutSizeInfo.v + this.beamLeftCutSizeInfo.k_);
          scalePostY =
            this.utils.getHeightByAngle(
              UI.eaveHeight +
                this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
              length,
              UI.patiosPitch,
              -1
            ) / this.geo_superiorPost.height;
          scaleBracketY =
            (this.getBracketHeight(
              UI.span +
                UI.multiSpan -
                (this.beamLeftCutSizeInfo.v + this.beamLeftCutSizeInfo.k_)
            ) -
              this.totalHeightFromEaveHeightToTopOfExistingRoof() +
              FIT_FLYOVER_BRAKET_ON_ROOF) /
            this.geo_bracket.height;
          if (
            UI.rakeCutLeftVer + this.beamLeftCutSizeInfo.k_ >
            this.MANAGER.patiosLength
          ) {
            exceedMoveBackLimit = true;
          }
        }

        //if rake cut is on then do not add post left and right
        //left and right post will be added in individual function
        //return;
      }
    }
    if (
      UI.rakeCutRightType == RAKECUT_TYPE.ANGLE ||
      UI.rakeCutRightType == RAKECUT_TYPE.STEP
    ) {
      let moveToBack = false;
      if (UI.multiSpan > 0) {
        if (userDataPos.multiSpan && userDataPos.last) {
          moveToBack = true;
        }
      } else {
        if (!userDataPos.multiSpan && userDataPos.last) {
          moveToBack = true;
        }
      }

      if (moveToBack) {
        if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
          postOffsetX += UI.overhangRight;
          bracketOffsetX += UI.overhangRight;
          postOffsetZ -=
            this.beamRightCutSizeInfo.v + this.beamRightCutSizeInfo.k_;
          bracketOffsetZ -=
            this.beamRightCutSizeInfo.v + this.beamRightCutSizeInfo.k_;
          let length =
            UI.span +
            UI.multiSpan -
            (this.beamRightCutSizeInfo.v + this.beamRightCutSizeInfo.k_);
          scalePostY =
            this.utils.getHeightByAngle(
              UI.eaveHeight +
                this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
              length,
              UI.patiosPitch,
              -1
            ) / this.geo_superiorPost.height;
          scaleBracketY =
            (this.getBracketHeight(
              UI.span +
                UI.multiSpan -
                (this.beamRightCutSizeInfo.v + this.beamRightCutSizeInfo.k_)
            ) -
              this.totalHeightFromEaveHeightToTopOfExistingRoof() +
              FIT_FLYOVER_BRAKET_ON_ROOF) /
            this.geo_bracket.height;
          if (
            UI.rakeCutRightVer + this.beamRightCutSizeInfo.k_ >
            this.MANAGER.patiosLength
          ) {
            exceedMoveBackLimit = true;
          }
        } else if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
          let moveBack = UI.rakeCutRightVer;
          if (moveBack > 0) {
            postOffsetZ -= moveBack;
            bracketOffsetZ -= moveBack;
            let length = UI.span + UI.multiSpan - moveBack;
            scalePostY =
              this.utils.getHeightByAngle(
                UI.eaveHeight +
                  this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
                length,
                UI.patiosPitch,
                -1
              ) / this.geo_superiorPost.height;
            scaleBracketY =
              (this.getBracketHeight(
                UI.span + UI.multiSpan - UI.rakeCutRightVer
              ) -
                this.totalHeightFromEaveHeightToTopOfExistingRoof() +
                FIT_FLYOVER_BRAKET_ON_ROOF) /
              this.geo_bracket.height;
          }
        }
        //if rake cut is on then do not add post left and right
        //left and right post will be added in individual function
        //return;
      }
    }
    //End rakecut

    //post can not go behind existing wall
    if (exceedMoveBackLimit) {
      return;
    }

    meshPost.position.set(postOffsetX, 0, postOffsetZ);
    meshPost.scale.setY(scalePostY);
    meshBracket.position.set(bracketOffsetX, offsetY, bracketOffsetZ);
    meshBracket.scale.setY(scaleBracketY);
    let boxPost = new Box3().setFromObject(meshPost);
    // let boxPostHelper = new Box3Helper(boxPost);
    // this.APP.scene.add(boxPostHelper);
    boxPost.translate(this.scene.position);
    let isIntersect = false;
    for (let eave of this.APP.eaveManager.listEave) {
      let boxEave = new Box3().setFromObject(eave);
      if (boxPost.intersectsBox(boxEave)) {
        isIntersect = true;
        break;
      }
    }

    if (isIntersect) {
      if (
        meshBracket.userData.position.left &&
        UI.existingType != BUILDING_SIDE.BOTH
      ) {
        meshBracket.translateX(
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
            (this.geo_bracket?.width || 0) / 2
        );
      } else if (
        meshBracket.userData.position.right &&
        UI.existingType != BUILDING_SIDE.BOTH
      ) {
        meshBracket.translateX(
          -(
            this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
            (this.geo_bracket?.width || 0) / 2
          )
        );
      }
      this.scene.add(meshBracket);
    } else {
      if (UI.existingType == 1) {
        meshPost.translateX(
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
            (this.geo_bracket?.width || 0) / 2
        );
      } else if (UI.existingType == 2) {
        meshPost.translateX(
          -(
            this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
            (this.geo_bracket?.width || 0) / 2
          )
        );
      }
      this.scene.add(meshPost);
    }
  }
  public addPostAngleRakecut(offsetX: number, userDataPos: any) {
    if (
      UI.rakeCutLeftType != RAKECUT_TYPE.ANGLE &&
      UI.rakeCutRightType != RAKECUT_TYPE.ANGLE
    ) {
      return;
    }

    let postOffsetZ =
      UI.multiSpan +
      UI.span -
      UI.existingWidth1 / 2 -
      this.geo_superiorPost.width -
      this.extraOffsetZ;
    let bracketOffsetZ =
      UI.multiSpan +
      UI.span -
      UI.existingWidth1 / 2 -
      this.geometryManager.FLY_OVER_BRACKET.S65x3.width -
      this.extraOffsetZ;
    let scalePostY =
      (UI.eaveHeight + this.getBracketHeight(UI.span + UI.multiSpan)) /
      this.geo_superiorPost.height;
    let scaleBracketY =
      this.getBracketHeight(UI.span + UI.multiSpan) / this.geo_bracket.height;
    let offsetY = UI.eaveHeight;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
      if (userDataPos.first) {
        let postLeft = new Mesh(
          this.geo_superiorPost.geometry,
          MaterialManager.Instance().POST
        );
        postLeft.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: GEOMETRY_TYPE.SUPERIOR_POST,
          position: userDataPos,
          views: views,
        };
        postLeft.position.set(
          offsetX + this.beamLeftCutSizeInfo.l,
          0,
          postOffsetZ
        );
        postLeft.scale.setY(scalePostY);

        let bracketLeft = new Mesh(
          this.geo_bracket.geometry,
          MaterialManager.Instance().BRACKET
        );
        bracketLeft.userData = {
          position: userDataPos,
          type: GEOMETRY_TYPE.FLY_OVER_BRACKET,
          category: GEOMETRY_CATEGORY.PATIOS,
          views: views,
        };
        bracketLeft.position.set(
          offsetX + this.beamLeftCutSizeInfo.l,
          offsetY,
          bracketOffsetZ
        );
        bracketLeft.scale.setY(scaleBracketY);

        let boxPost = new Box3().setFromObject(postLeft);
        boxPost.translate(this.scene.position);
        let isIntersect = false;
        for (let eave of this.APP.eaveManager.listEave) {
          let boxEave = new Box3().setFromObject(eave);
          if (boxPost.intersectsBox(boxEave)) {
            isIntersect = true;
            break;
          }
        }

        let isIntersectPost = false;
        this.scene.children
          .filter((el) => el.userData.type == GEOMETRY_TYPE.SUPERIOR_POST)
          .forEach((el) => {
            el.updateWorldMatrix(true, true);
            let boxEl = new Box3().setFromObject(el);
            if (boxPost.intersectsBox(boxEl)) {
              isIntersectPost = true;
            }
          });

        if (isIntersect && !isIntersectPost) {
          this.scene.add(bracketLeft);
        } else if (!isIntersectPost) {
          this.scene.add(postLeft);
        }
      }
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
      if (userDataPos.last) {
        let postLeft = new Mesh(
          this.geo_superiorPost.geometry,
          MaterialManager.Instance().POST
        );
        postLeft.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: GEOMETRY_TYPE.SUPERIOR_POST,
          position: userDataPos,
          views: views,
        };
        postLeft.position.set(
          offsetX - this.beamRightCutSizeInfo.l - this.geo_superiorPost.width,
          0,
          postOffsetZ
        );
        postLeft.scale.setY(scalePostY);

        let bracketLeft = new Mesh(
          this.geo_bracket.geometry,
          MaterialManager.Instance().BRACKET
        );
        bracketLeft.userData = {
          position: userDataPos,
          type: GEOMETRY_TYPE.FLY_OVER_BRACKET,
          category: GEOMETRY_CATEGORY.PATIOS,
          views: views,
        };
        bracketLeft.position.set(
          offsetX - this.beamRightCutSizeInfo.l - this.geo_superiorBeam.width,
          offsetY,
          bracketOffsetZ
        );
        bracketLeft.scale.setY(scaleBracketY);

        let boxPost = new Box3().setFromObject(postLeft);
        boxPost.translate(this.scene.position);
        let isIntersect = false;
        for (let eave of this.APP.eaveManager.listEave) {
          let boxEave = new Box3().setFromObject(eave);
          if (boxPost.intersectsBox(boxEave)) {
            isIntersect = true;
            break;
          }
        }

        let isIntersectPost = false;
        this.scene.children
          .filter((el) => el.userData.type == GEOMETRY_TYPE.SUPERIOR_POST)
          .forEach((el) => {
            el.updateWorldMatrix(true, true);
            let boxEl = new Box3().setFromObject(el);
            if (boxPost.intersectsBox(boxEl)) {
              isIntersectPost = true;
            }
          });

        if (isIntersect && !isIntersectPost) {
          this.scene.add(bracketLeft);
        } else if (!isIntersectPost) {
          this.scene.add(postLeft);
        }
      }
    }
  }
  private addPostStepRakecut() {
    if (
      UI.rakeCutLeftType != RAKECUT_TYPE.STEP &&
      UI.rakeCutRightType != RAKECUT_TYPE.STEP
    ) {
      return;
    }

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    let userDataPos = { rakecut: true };
    let fitX = this.getContainerOffsetXToFitBraketToExistingWall();
    let offsetXL = -UI.totalBayLength / 2 + fitX;
    let offsetXR = UI.totalBayLength / 2 - this.geo_superiorPost.width + fitX;
    let offsetZF =
      UI.span +
      UI.multiSpan -
      UI.existingWidth1 / 2 -
      this.extraOffsetZ -
      this.geo_superiorPost.width;
    let scaleYF =
      this.utils.getHeightByAngle(
        UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
        UI.span + UI.multiSpan,
        UI.patiosPitch,
        -1
      ) / this.geo_superiorPost.height;

    let moveRight = UI.rakeCutLeftHor - UI.overhangLeft;
    if (moveRight > 0) {
      offsetXL += moveRight;
    }

    let moveLeft = UI.rakeCutRightHor - UI.overhangRight;
    if (moveLeft > 0) {
      offsetXR -= moveLeft;
    }

    let posts: Array<Mesh> = [];

    if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      let offsetZB = offsetZF;
      let scaleYB = scaleYF;
      let moveBack = UI.rakeCutLeftVer;
      if (moveBack > 0) {
        offsetZB -= moveBack;
        scaleYB =
          this.utils.getHeightByAngle(
            UI.eaveHeight +
              this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
            UI.span + UI.multiSpan - moveBack,
            UI.patiosPitch,
            -1
          ) / this.geo_superiorPost.height;
      }

      let postFront = new Mesh(
        this.geo_superiorPost.geometry,
        MaterialManager.Instance().POST
      );
      postFront.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: { ...userDataPos, left: true },
        views: views,
      };
      postFront.position.set(offsetXL, 0, offsetZF);
      postFront.scale.setY(scaleYF);
      //this.scene.add(postFront);
      posts.push(postFront);

      let postBack = new Mesh(
        this.geo_superiorPost.geometry,
        MaterialManager.Instance().POST
      );
      postBack.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: { ...userDataPos, left: true },
        views: views,
      };
      postBack.position.set(offsetXL, 0, offsetZB);
      postBack.scale.setY(scaleYB);
      //this.scene.add(postBack);
      posts.push(postBack);
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      let offsetZB = offsetZF;
      let scaleYB = scaleYF;
      let moveBack = UI.rakeCutRightVer;
      if (moveBack > 0) {
        offsetZB -= moveBack;
        scaleYB =
          this.utils.getHeightByAngle(
            UI.eaveHeight +
              this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
            UI.span + UI.multiSpan - moveBack,
            UI.patiosPitch,
            -1
          ) / this.geo_superiorPost.height;
      }

      let postFront = new Mesh(
        this.geo_superiorPost.geometry,
        MaterialManager.Instance().POST
      );
      postFront.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: { ...userDataPos, right: true },
        views: views,
      };
      postFront.position.set(offsetXR, 0, offsetZF);
      postFront.scale.setY(scaleYF);
      //this.scene.add(postFront);
      posts.push(postFront);

      let postBack = new Mesh(
        this.geo_superiorPost.geometry,
        MaterialManager.Instance().POST
      );
      postBack.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: { ...userDataPos, right: true },
        views: views,
      };
      postBack.position.set(offsetXR, 0, offsetZB);
      postBack.scale.setY(scaleYB);
      //this.scene.add(postBack);
      posts.push(postBack);
    }

    for (let post of posts) {
      let boxPost = new Box3().setFromObject(post);
      boxPost.translate(this.scene.position);
      let isIntersect = false;
      for (let eave of this.APP.eaveManager.listEave) {
        let boxEave = new Box3().setFromObject(eave);
        if (boxPost.intersectsBox(boxEave)) {
          isIntersect = true;
          break;
        }
      }

      if (isIntersect) {
        let bracket = new Mesh(
          this.geo_bracket.geometry,
          MaterialManager.Instance().BRACKET
        );
        bracket.userData = {
          position: userDataPos,
          type: GEOMETRY_TYPE.FLY_OVER_BRACKET,
          category: GEOMETRY_CATEGORY.PATIOS,
        };
        bracket.position.set(post.position.x, UI.eaveHeight, post.position.z);
        let height =
          post.scale.y * this.geo_superiorPost.height - UI.eaveHeight;
        let scaleY = height / this.geo_bracket.height;
        bracket.scale.setY(scaleY);
        this.scene.add(bracket);
      } else {
        this.scene.add(post);
      }
    }
  }
  public addPostOrBracketBack() {
    this.scene.remove(
      ...this.scene.children.filter(
        (x) =>
          x.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET &&
          x.userData.position.back
      )
    );
    this.scene.remove(
      ...this.scene.children.filter(
        (x) =>
          x.userData.type == GEOMETRY_TYPE.SUPERIOR_POST &&
          x.userData.position.back
      )
    );

    let first = true;
    let last = false;
    let offsetX = -UI.totalBayLength / 2;
    let userDataPos = { back: true };

    let bracketDistance = UI.totalBayLength / (this.numberOfBracketsBack - 1);
    let cutoutLengthInExistingBoth =
      (UI.totalBayLength - this.APP.sldExistingLength.currentValue) / 2;
    let extraWalWidth =
      this.APP.existingWallManager.geo_existingWallL1.width +
      CONST.EAVE_OFFSET_BACK;

    if (
      this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
      this.MANAGER.cutoutCondition
    ) {
      if (UI.existingType == BUILDING_SIDE.LEFT) {
        if (UI.existingWidth2 > 0) {
          offsetX += UI.existingLength2;
          bracketDistance =
            (UI.totalBayLength - UI.existingLength2) /
            (this.numberOfBracketsBack - 1);
        } else {
          offsetX +=
            UI.existingLength2 -
            (this.APP.existingWallManager.geo_existingWallW1.width +
              CONST.EAVE_OFFSET_BACK);
          bracketDistance =
            (UI.totalBayLength - UI.existingLength2 + extraWalWidth) /
            (this.numberOfBracketsBack - 1);
        }
      } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
        if (UI.existingWidth2 > 0) {
          bracketDistance =
            (UI.totalBayLength - UI.existingLength2) /
            (this.numberOfBracketsBack - 1);
        } else {
          bracketDistance =
            (UI.totalBayLength - UI.existingLength2 + extraWalWidth) /
            (this.numberOfBracketsBack - 1);
        }
      } else if (UI.existingType == BUILDING_SIDE.BOTH) {
        offsetX += cutoutLengthInExistingBoth - extraWalWidth;
        bracketDistance =
          (this.APP.sldExistingLength.currentValue + extraWalWidth * 2) /
          (this.numberOfBracketsBack - 1);
      }
    }

    for (let i = 0; i < this.numberOfBracketsBack; i++) {
      if (i == this.numberOfBracketsBack - 1) {
        last = true;
      }
      let meshPost = new Mesh(
        this.geo_superiorPost.geometry,
        MaterialManager.Instance().POST
      );
      meshPost.userData = {
        position: userDataPos,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        category: GEOMETRY_CATEGORY.PATIOS,
      };

      let meshBracket = new Mesh(
        this.geo_bracket.geometry,
        MaterialManager.Instance().BRACKET
      );
      meshBracket.userData = {
        position: userDataPos,
        type: GEOMETRY_TYPE.FLY_OVER_BRACKET,
        category: GEOMETRY_CATEGORY.PATIOS,
      };

      let bracketOffsetY =
        UI.eaveHeight +
        this.totalHeightFromEaveHeightToTopOfExistingRoof() -
        FIT_FLYOVER_BRAKET_ON_ROOF;
      let postOffsetZ = 0;
      let bracketOffsetZ = 0;

      let scalePostY = 1;
      let scaleBracketY = 1;

      let views: Print2DView[];

      postOffsetZ = -UI.existingWidth1 / 2 - this.extraOffsetZ;
      bracketOffsetZ = -UI.existingWidth1 / 2 - this.extraOffsetZ;
      scalePostY =
        (UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket()) /
        this.geo_superiorPost.height;
      scaleBracketY =
        (UI.braketHeight + this.houseBeamHeight()) /
        this.geometryManager.FLY_OVER_BRACKET.S65x3.height;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];

      meshPost.userData.views = views;
      meshBracket.userData.views = views;

      let bracketOffsetX = offsetX;
      let postOffsetX = offsetX;

      if (first) {
      } else if (last) {
        bracketOffsetX -= this.geo_bracket.width;
        postOffsetX -= this.geo_superiorPost.width;
      } else {
        bracketOffsetX -= this.geo_bracket.width;
        postOffsetX -= this.geo_superiorPost.width;
      }

      meshPost.position.set(postOffsetX, 0, postOffsetZ);
      meshPost.scale.setY(scalePostY);
      meshBracket.position.set(bracketOffsetX, bracketOffsetY, bracketOffsetZ);
      meshBracket.scale.setY(scaleBracketY);

      //Check intersect eave
      let boxPost = new Box3().setFromObject(meshPost);
      boxPost.translate(this.scene.position);

      let isIntersect = false;
      //let isCutoutInterset = false;
      for (let eave of this.APP.eaveManager.listEave) {
        let boxEave = new Box3().setFromObject(eave);
        if (boxPost.intersectsBox(boxEave)) {
          isIntersect = true;
        }
      }

      if (isIntersect) {
        if (i == 0 && (UI.existingType == 1 || UI.existingType == 3)) {
          meshBracket.translateX(
            this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
              (this.geo_bracket?.width || 0) / 2
          );
        } else if (last && (UI.existingType == 2 || UI.existingType == 3)) {
          meshBracket.translateX(
            -(
              this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
              (this.geo_bracket?.width || 0) / 2
            )
          );
        }

        this.scene.add(meshBracket);
      } else {
        this.scene.add(meshPost);
      }

      if (i < this.numberOfBracketsBack) {
        offsetX += bracketDistance;
      }
      first = false;
    }
  }
  public addPostOrBracketBackCutout(): void {
    this.scene.remove(
      ...this.scene.children.filter(
        (x) =>
          x.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET &&
          x.userData.position.back &&
          x.userData.position.cutout
      )
    );
    this.scene.remove(
      ...this.scene.children.filter(
        (x) =>
          x.userData.type == GEOMETRY_TYPE.SUPERIOR_POST &&
          x.userData.position.back &&
          x.userData.position.cutout
      )
    );

    if (
      this.APP.sltCutOut.currentValue != CUTOUT_ENABLE.YES ||
      !this.MANAGER.cutoutCondition
    )
      return;

    let first = true;
    let last = false;

    let offsetXL = -UI.totalBayLength / 2;
    let offsetXR = UI.totalBayLength / 2;

    let userDataPos = { back: true, cutout: true };

    let extraWalWidth =
      this.APP.existingWallManager.geo_existingWallL1.width +
      CONST.EAVE_OFFSET_BACK;
    let bracketDistance =
      (UI.existingLength2 - extraWalWidth) / (this.numberOfBracketCutout - 1);
    if (UI.existingWidth2 > 0) {
      bracketDistance = UI.existingLength2 / (this.numberOfBracketCutout - 1);
    }

    if (
      UI.existingType == BUILDING_SIDE.LEFT ||
      UI.existingType == BUILDING_SIDE.BOTH
    ) {
      for (let i = 0; i < this.numberOfBracketCutout; i++) {
        if (i == this.numberOfBracketCutout - 1) {
          last = true;
        }

        let meshPost = new Mesh(
          this.geo_superiorPost.geometry,
          MaterialManager.Instance().POST
        );
        meshPost.userData = {
          position: userDataPos,
          type: GEOMETRY_TYPE.SUPERIOR_POST,
          category: GEOMETRY_CATEGORY.PATIOS,
        };

        let meshBracket = new Mesh(
          this.geo_bracket.geometry,
          MaterialManager.Instance().BRACKET
        );
        meshBracket.userData = {
          position: userDataPos,
          type: GEOMETRY_TYPE.FLY_OVER_BRACKET,
          category: GEOMETRY_CATEGORY.PATIOS,
        };

        //let offsetX = 0;
        let offsetY =
          UI.eaveHeight +
          this.totalHeightFromEaveHeightToTopOfExistingRoof() -
          FIT_FLYOVER_BRAKET_ON_ROOF;

        let postOffsetZ =
          -UI.existingWidth1 / 2 - this.extraOffsetZ + UI.existingWidth1;
        let bracketOffsetZ =
          -UI.existingWidth1 / 2 - this.extraOffsetZ + UI.existingWidth1;

        let views: Print2DView[];

        let scalePostY =
          this.utils.getHeightByAngle(
            UI.eaveHeight +
              this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
            UI.existingWidth1,
            UI.patiosPitch,
            -1
          ) / this.geo_superiorPost.height;
        let scaleBracketY =
          (this.utils.getHeightByAngle(
            UI.braketHeight,
            UI.existingWidth1,
            UI.patiosPitch,
            -1
          ) +
            this.houseBeamHeight()) /
          this.geometryManager.FLY_OVER_BRACKET.S65x3.height;

        views = [
          { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
          // { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
          { viewType: ViewType.PLAN, lineType: LineType.DASHED },
        ];

        meshPost.userData.views = views;
        meshBracket.userData.views = views;

        let bracketOffsetX = offsetXL;
        let postOffsetX = offsetXL;
        if (first) {
        } else if (last) {
          // Move to dodge internal rafter beam
          bracketOffsetX -= this.geo_bracket.width;
          postOffsetX -= this.geo_superiorPost.width;
        } else {
          bracketOffsetX -= this.geo_bracket.width / 2;
          postOffsetX -= this.geo_superiorPost.width / 2;
        }

        meshPost.position.set(postOffsetX, 0, postOffsetZ);
        meshPost.scale.setY(scalePostY);

        meshBracket.position.set(bracketOffsetX, offsetY, bracketOffsetZ);
        meshBracket.scale.setY(scaleBracketY);

        //Check intersect eave
        let boxPost = new Box3().setFromObject(meshPost);
        boxPost.translate(this.scene.position);
        let isIntersect = false;
        for (let eave of this.APP.eaveManager.listEave) {
          let boxEave = new Box3().setFromObject(eave);
          if (boxPost.intersectsBox(boxEave)) {
            isIntersect = true;
            break;
          }
        }

        if (isIntersect) {
          if (UI.existingType == 1) {
            meshBracket.translateX(
              this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
                (this.geo_bracket?.width || 0) / 2
            );
          }
          this.scene.add(meshBracket);
        } else {
          this.scene.add(meshPost);
        }

        if (i < this.numberOfBracketCutout) {
          offsetXL += bracketDistance;
        }
        first = false;
      }
    }

    if (
      UI.existingType == BUILDING_SIDE.RIGHT ||
      UI.existingType == BUILDING_SIDE.BOTH
    ) {
      first = true;

      for (let i = 0; i < this.numberOfBracketCutout; i++) {
        if (i == this.numberOfBracketCutout - 1) {
          last = true;
        }

        let meshPost = new Mesh(
          this.geo_superiorPost.geometry,
          MaterialManager.Instance().POST
        );
        meshPost.userData = {
          position: userDataPos,
          type: GEOMETRY_TYPE.SUPERIOR_POST,
          category: GEOMETRY_CATEGORY.PATIOS,
        };

        let meshBracket = new Mesh(
          this.geo_bracket.geometry,
          MaterialManager.Instance().BRACKET
        );
        meshBracket.userData = {
          position: userDataPos,
          type: GEOMETRY_TYPE.FLY_OVER_BRACKET,
          category: GEOMETRY_CATEGORY.PATIOS,
        };

        let offsetY =
          UI.eaveHeight +
          this.totalHeightFromEaveHeightToTopOfExistingRoof() -
          FIT_FLYOVER_BRAKET_ON_ROOF;

        let postOffsetZ =
          -UI.existingWidth1 / 2 - this.extraOffsetZ + UI.existingWidth1;
        let bracketOffsetZ =
          -UI.existingWidth1 / 2 - this.extraOffsetZ + UI.existingWidth1;

        let views: Print2DView[];

        let scalePostY =
          this.utils.getHeightByAngle(
            UI.eaveHeight +
              this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
            UI.existingWidth1,
            UI.patiosPitch,
            -1
          ) / this.geo_superiorPost.height;
        let scaleBracketY =
          (this.utils.getHeightByAngle(
            UI.braketHeight,
            UI.existingWidth1,
            UI.patiosPitch,
            -1
          ) +
            this.houseBeamHeight()) /
          this.geometryManager.FLY_OVER_BRACKET.S65x3.height;

        views = [
          // { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
          { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
          { viewType: ViewType.PLAN, lineType: LineType.DASHED },
        ];

        meshPost.userData.views = views;
        meshBracket.userData.views = views;

        let bracketOffsetX = offsetXR;
        let postOffsetX = offsetXR;
        if (first) {
          if (
            UI.existingType == BUILDING_SIDE.RIGHT ||
            UI.existingType == BUILDING_SIDE.BOTH
          ) {
            bracketOffsetX -= this.geo_bracket.width;
            postOffsetX -= this.geo_superiorPost.width;
          }
        } else if (last) {
          // Dodge internal rafter beam
          // bracketOffsetX -= this.geo_bracket.width;
          // postOffsetX -= this.geo_superiorPost.width;
        } else {
          bracketOffsetX -= this.geo_bracket.width / 2;
          postOffsetX -= this.geo_superiorPost.width / 2;
        }

        meshPost.position.set(postOffsetX, 0, postOffsetZ);
        meshPost.scale.setY(scalePostY);

        meshBracket.position.set(bracketOffsetX, offsetY, bracketOffsetZ);
        meshBracket.scale.setY(scaleBracketY);

        //Check intersect eave
        let boxPost = new Box3().setFromObject(meshPost);
        boxPost.translate(this.scene.position);
        let isIntersect = false;
        for (let eave of this.APP.eaveManager.listEave) {
          let boxEave = new Box3().setFromObject(eave);
          if (boxPost.intersectsBox(boxEave)) {
            isIntersect = true;
            break;
          }
        }

        if (isIntersect) {
          if (UI.existingType == 2) {
            meshBracket.translateX(
              -(
                this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
                (this.geo_bracket?.width || 0) / 2
              )
            );
          }

          this.scene.add(meshBracket);
        } else {
          this.scene.add(meshPost);
        }

        if (i < this.numberOfBracketCutout) {
          offsetXR -= bracketDistance;
        }
        first = false;
      }
    }
  }
  public addSuperiorBeam(userDataPos: any) {
    let offsetX = -UI.totalBayLength / 2 - UI.overhangLeft;
    let offsetZ = 0;
    let offsetY = 0;

    let beamLength = UI.totalBayLength + UI.overhangLeft + UI.overhangRight;
    let extraWallWidth =
      this.APP.existingWallManager.geo_existingWallW1.width +
      CONST.EAVE_OFFSET_BACK;
    let views: Print2DView[];

    if (userDataPos.front) {
      offsetY = UI.eaveHeight + this.getBracketHeight(UI.span);
      offsetZ = UI.span - UI.existingWidth1 / 2 - this.extraOffsetZ;
      if (UI.multiSpan > 0) {
        offsetZ -= this.geo_superiorBeam.width / 2;
      } else {
        offsetZ -= this.geo_superiorBeam.width;
      }

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.back) {
      //Add house beam
      offsetY =
        UI.eaveHeight +
        this.totalHeightFromEaveHeightToTopOfFlyoverBraket() +
        this.houseBeamHeight();

      offsetZ = -(UI.existingWidth1 / 2 + this.extraOffsetZ);

      if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.MANAGER.cutoutCondition
      ) {
        if (UI.existingType == BUILDING_SIDE.LEFT) {
          if (UI.existingWidth2 > 0) {
            offsetX += UI.existingLength2;
            beamLength =
              UI.totalBayLength +
              UI.overhangLeft +
              UI.overhangRight -
              UI.existingLength2;
          } else {
            offsetX += UI.existingLength2 - extraWallWidth;
            beamLength =
              UI.totalBayLength +
              UI.overhangLeft +
              UI.overhangRight -
              UI.existingLength2 +
              extraWallWidth;
          }

          offsetX += UI.overhangLeft;
          beamLength -= UI.overhangLeft;
        } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
          if (UI.existingWidth2 > 0) {
            beamLength =
              UI.totalBayLength +
              UI.overhangLeft +
              UI.overhangRight -
              UI.existingLength2;
          } else {
            beamLength =
              UI.totalBayLength +
              UI.overhangLeft +
              UI.overhangRight -
              UI.existingLength2 +
              extraWallWidth;
          }

          beamLength -= UI.overhangRight;
        } else if (UI.existingType == BUILDING_SIDE.BOTH) {
          offsetX +=
            (UI.totalBayLength -
              (this.APP.sldExistingLength.currentValue +
                this.APP.existingWallManager.geo_existingWallW1.width * 2)) /
              2 -
            CONST.EAVE_OFFSET_BACK;
          beamLength =
            this.APP.sldExistingLength.currentValue +
            this.APP.existingWallManager.geo_existingWallW1.width * 2 +
            CONST.EAVE_OFFSET_BACK * 2;
        }
      }

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.multiSpan) {
      offsetY = UI.eaveHeight + this.getBracketHeight(UI.span + UI.multiSpan);
      offsetZ =
        UI.multiSpan +
        UI.span -
        UI.existingWidth1 / 2 -
        this.geo_superiorBeam.width -
        this.extraOffsetZ;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.cutout) {
      offsetY =
        UI.eaveHeight +
        this.getBracketHeight(UI.existingWidth1) +
        this.houseBeamHeight();
      offsetZ = UI.existingWidth1 / 2 - this.extraOffsetZ;
      beamLength =
        UI.existingLength2 -
        this.APP.existingWallManager.geo_existingWallW1.width;

      if (UI.existingType == BUILDING_SIDE.LEFT) {
        views = [
          { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
          { viewType: ViewType.PLAN, lineType: LineType.DASHED },
        ];

        if (UI.existingWidth2 > 0) {
          // beamLength += extraWallWidth;
        } else {
          beamLength -= extraWallWidth;
        }

        beamLength +=
          UI.overhangLeft +
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width;
      } else if (UI.existingType == BUILDING_SIDE.RIGHT) {
        views = [
          { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
          { viewType: ViewType.PLAN, lineType: LineType.DASHED },
        ];

        if (UI.existingWidth2 > 0) {
          // beamLength += extraWallWidth;
          offsetX =
            UI.totalBayLength / 2 +
            UI.overhangRight -
            UI.existingLength2 -
            CONST.EAVE_OFFSET_BACK +
            extraWallWidth;
        } else {
          offsetX =
            UI.totalBayLength / 2 +
            UI.overhangRight -
            UI.existingLength2 +
            this.APP.existingWallManager.geo_existingWallW1.width +
            extraWallWidth;
          beamLength -= extraWallWidth;
        }

        offsetX -=
          UI.overhangRight +
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width;
        beamLength +=
          UI.overhangRight +
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width;
      } else if (UI.existingType == BUILDING_SIDE.BOTH) {
        // Draw beam from start braket to end braket cutout
        beamLength = UI.existingLength2 - extraWallWidth;

        if (UI.existingWidth2 > 0) {
          beamLength = UI.existingLength2;
        }
      }
    }
    //rake cut
    if (
      (UI.multiSpan <= 0 && userDataPos.front) ||
      (UI.multiSpan > 0 && userDataPos.multiSpan)
    ) {
      if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
        offsetX += this.beamLeftCutSizeInfo.l;
        beamLength -= this.beamLeftCutSizeInfo.l;
      } else if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
        offsetX += UI.rakeCutLeftHor;
        beamLength -= UI.rakeCutLeftHor;
      }
      if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
        beamLength -= this.beamRightCutSizeInfo.l;
      } else if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
        beamLength -= UI.rakeCutRightHor;
      }
    }
    const fitX = this.getContainerOffsetXToFitBraketToExistingWall();
    offsetX += fitX;
    if(userDataPos.front || userDataPos.multiSpan) {
      this.cutStandardBeamByCutBeamWithinBayControl(
        this.geo_superiorBeam,
        this.geo_beamEndCap,
        beamLength,
        new Vector3(offsetX, offsetY, offsetZ),
        new Vector3(),
        views,
        1,
        userDataPos,
        this.geoBeamJoint
      )
    } else {
      this.cutStandardBeamToEqualLengthBeam(
        userDataPos.back || userDataPos.cutout
          ? this.geo_superiorHouseBeam
          : this.geo_superiorBeam,
        userDataPos.back || userDataPos.cutout
          ? this.geo_houseBeamEndCap
          : this.geo_beamEndCap,
        beamLength,
        new Vector3(offsetX, offsetY, offsetZ),
        new Vector3(),
        views,
        1,
        userDataPos,
        (userDataPos.back || userDataPos.cutout) ? this.geoHouseBeamJoint : this.geoBeamJoint
      )
    }

    //Add one more beam cutout right
    if (userDataPos.cutout && UI.existingType == BUILDING_SIDE.BOTH) {
      offsetX = UI.totalBayLength / 2 - beamLength;
      this.cutStandardBeamToEqualLengthBeam(
        this.geo_superiorHouseBeam,
        this.geo_houseBeamEndCap,
        beamLength,
        new Vector3(offsetX, offsetY, offsetZ),
        new Vector3(),
        views,
        1,
        userDataPos,
        this.geoHouseBeamJoint
      )
    }
  }
  private cutStandardBeamToEqualLengthBeam(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    jointGeo: GeometryInfo,
  ){
    const beamStartX = pos.x
    const beamEndX = pos.x + length

    const numOfBeam = Math.ceil(length / EXISTING_BUILDING_CONFIG.MAXIMUM_LENGTH_PER_BEAM);
    const lengthPerBeam = Math.abs(beamEndX - beamStartX) / numOfBeam
    for(let i = 0; i < numOfBeam; i++) {
      let beamGroup = this.utils.createBeamGroup(
        beamGeo,
        beamCapGeo,
        lengthPerBeam,
        new Vector3(beamStartX + lengthPerBeam * i, pos.y, pos.z),
        rot,
        views,
        directionOffset,
        userDataPos,
        UI.beamLayoutShow,
        {
          hasStartCap: UI.beamType == 0 && (i == 0),
          hasEndCap: UI.beamType == 0 && (i == numOfBeam - 1 || (i == 0 && numOfBeam == 1)),
          hasStartJoint: i !== 0,
          hasEndJoint: false,
          jointGeo: jointGeo,
        }
      )
      this.scene.add(beamGroup);
    }
  }
  private cutStandardBeamByCutBeamWithinBayControl(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    jointGeo: GeometryInfo,
  ){
    const fitX = this.getContainerOffsetXToFitBraketToExistingWall();
    // Map list cut beam here
    const beamStartX = pos.x
    const beamEndX = pos.x + length

    let endOfBayX = - UI.totalBayLength / 2 + fitX
    let startCutBeamX = beamStartX
    let endCutBeamX = beamStartX

    const beams = this.utils.getListSeperateBeamsByBays(endOfBayX, beamStartX, beamEndX, endCutBeamX, startCutBeamX)

    for(let i = 0; i < beams.length; i++) {
      endCutBeamX = beams[i][1]
      startCutBeamX = beams[i][0]
      let beamGroup = this.utils.createBeamGroup(
        beamGeo,
        beamCapGeo,
        endCutBeamX - startCutBeamX,
        new Vector3(startCutBeamX, pos.y, pos.z),
        rot,
        views,
        directionOffset,
        userDataPos,
        UI.beamLayoutShow,
        {
          hasStartCap: UI.beamType == 0 && (i == 0),
          hasEndCap: UI.beamType == 0 && (i == beams.length - 1 || (i == 0 && beams.length == 1)),
          hasStartJoint: i !== 0,
          hasEndJoint: false,
          jointGeo: jointGeo,
        }
      )
      this.scene.add(beamGroup);
    }
  }
  private addBeamAngleRakecut() {
    if (
      UI.rakeCutLeftType != RAKECUT_TYPE.ANGLE &&
      UI.rakeCutRightType != RAKECUT_TYPE.ANGLE
    ) {
      return;
    }

    let offsetZ =
      UI.multiSpan + UI.span - UI.existingWidth1 / 2 - this.extraOffsetZ;
    let offsetY = this.utils.getHeightByAngle(
      UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
      UI.span + UI.multiSpan,
      UI.patiosPitch,
      -1
    );

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
      let beamLength = this.beamLeftCutSizeInfo.m;

      //beam lenght can not go behind existing wall
      if (
        UI.rakeCutLeftVer + this.beamLeftCutSizeInfo.k_ >
        this.MANAGER.patiosLength
      ) {
        beamLength -= this.beamLeftCutSizeInfo.b;
      }

      let scaleZ = beamLength / this.geo_beamRakecutLeft.length;

      let offsetXStart = 0;
      let offsetXEnd = beamLength;
      let rotY = Math.atan(UI.rakeCutLeftHor / UI.rakeCutLeftVer);
      let rotX = this.utils.degreesToRadians(UI.patiosPitch);

      let offsetX =
        -UI.totalBayLength / 2 - UI.overhangLeft + this.beamLeftCutSizeInfo.l;
      const mesh = new Mesh(
        this.geo_beamRakecutLeft.geometry,
        MaterialManager.Instance().BEAM
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
      };
      mesh.scale.setZ(scaleZ);

      let capL = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capL.position.set(
        this.geo_beamEndCap.width / 2,
        0,
        -(offsetXEnd + this.geo_beamEndCap.length / 2 - 2)
      );
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode( this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode)
      }

      let capR = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capR.position.set(
        this.geo_beamEndCap.width / 2,
        0,
        offsetXStart - this.geo_beamEndCap.length / 2 + 2
      );
      capR.rotateY(Math.PI);
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode( this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode)
      }

      let beamGroup = new Group();
      beamGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
        angle: true,
      };
      if (UI.existingType == BUILDING_SIDE.RIGHT) {
        let fitZ =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        offsetX -= fitZ;
      }
      if (UI.existingType == BUILDING_SIDE.LEFT) {
        let fitZ =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        offsetX += fitZ;
      }
      beamGroup.position.set(offsetX, offsetY, offsetZ);
      beamGroup.rotation.set(rotX, rotY, 0);
      if (this.APP.sltBeamType.currentValue == 0) {
        beamGroup.add(mesh, capL, capR);
      } else {
        beamGroup.add(mesh);
      }
      this.scene.add(beamGroup);
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
      let beamLength = this.beamRightCutSizeInfo.m;

      //beam lenght can not go behind existing wall
      if (
        UI.rakeCutRightVer + this.beamRightCutSizeInfo.k_ >
        this.MANAGER.patiosLength
      ) {
        beamLength -= this.beamRightCutSizeInfo.b;
      }

      let scaleZ = beamLength / this.geo_beamRakecutLeft.length;

      let offsetXStart = 0;
      let offsetXEnd = beamLength;
      let rotY = Math.atan(UI.rakeCutRightHor / UI.rakeCutRightVer);
      let rotX = this.utils.degreesToRadians(UI.patiosPitch);

      let offsetX =
        UI.totalBayLength / 2 + UI.overhangRight - this.beamRightCutSizeInfo.l;
      const mesh = new Mesh(
        this.geo_beamRakecutRight.geometry,
        MaterialManager.Instance().BEAM
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
      };
      mesh.scale.setZ(scaleZ);

      let capL = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capL.position.set(
        -this.geo_beamEndCap.width / 2,
        0,
        -(offsetXEnd + this.geo_beamEndCap.length / 2 - 2)
      );
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode( this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode)
      }

      let capR = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capR.position.set(
        -this.geo_beamEndCap.width / 2,
        0,
        offsetXStart - this.geo_beamEndCap.length / 2 + 2
      );
      capR.rotateY(Math.PI);
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode( this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode)
      }

      let beamGroup = new Group();
      beamGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
        angle: true,
      };
      if (UI.existingType == BUILDING_SIDE.RIGHT) {
        let fitZ =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        offsetX -= fitZ;
      }
      if (UI.existingType == BUILDING_SIDE.LEFT) {
        let fitZ =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        offsetX += fitZ;
      }
      beamGroup.position.set(offsetX, offsetY, offsetZ);
      beamGroup.rotation.set(rotX, -rotY, 0);
      if (this.APP.sltBeamType.currentValue == 0) {
        beamGroup.add(mesh, capL, capR);
      } else {
        beamGroup.add(mesh);
      }
      this.scene.add(beamGroup);
    }
  }
  private addBeamStepRakecut() {
    if (
      UI.rakeCutLeftType != RAKECUT_TYPE.STEP &&
      UI.rakeCutRightType != RAKECUT_TYPE.STEP
    ) {
      return;
    }

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    let offsetXL = -UI.totalBayLength / 2 - UI.overhangLeft;
    let offsetXR = UI.totalBayLength / 2 + UI.overhangRight;

    let offsetY = this.utils.getHeightByAngle(
      UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
      UI.span + UI.multiSpan,
      UI.patiosPitch,
      -1
    );

    if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      let offsetZ =
        UI.span + UI.multiSpan - this.extraOffsetZ - UI.existingWidth1 / 2;
      let moveBack = UI.rakeCutLeftVer;
      if (moveBack > 0) {
        offsetZ -= moveBack;
        offsetY = this.utils.getHeightByAngle(
          UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
          UI.span + UI.multiSpan - moveBack,
          UI.patiosPitch,
          -1
        );
      }

      let beamLength = UI.rakeCutLeftHor + this.geo_superiorPost.width;
      if (UI.rakeCutLeftHor < UI.overhangLeft) {
        beamLength = UI.overhangLeft + this.geo_superiorPost.width;
      }

      let scaleX = beamLength / this.geo_beamStepRakecutLeft.length;

      let beam = new Mesh(
        this.geo_beamStepRakecutLeft.geometry,
        MaterialManager.Instance().BEAM
      );
      beam.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
      };
      beam.scale.setX(scaleX);

      let capL = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capL.position.set(
        this.geo_beamEndCap.length / 2 - 2,
        0,
        -this.geo_beamEndCap.width / 2
      );
      capL.rotateY(Math.PI / 2);
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode( this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode)
      }

      let capR = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capR.position.set(
        beamLength + this.geo_beamEndCap.length / 2 - 2,
        0,
        -this.geo_beamEndCap.width / 2
      );
      capR.rotateY(-Math.PI / 2);
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode( this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode)
      }

      let beamGroup = new Group();
      beamGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
      };
      if (UI.existingType == BUILDING_SIDE.RIGHT) {
        let fitZ =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        offsetXL -= fitZ;
      }
      if (UI.existingType == BUILDING_SIDE.LEFT) {
        let fitZ =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        offsetXL += fitZ;
      }
      beamGroup.position.set(offsetXL, offsetY, offsetZ);
      if (this.APP.sltBeamType.currentValue == 0) {
        beamGroup.add(beam, capL, capR);
      } else {
        beamGroup.add(beam);
      }
      this.scene.add(beamGroup);
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      let offsetZ =
        UI.span + UI.multiSpan - this.extraOffsetZ - UI.existingWidth1 / 2;
      let moveBack = UI.rakeCutRightVer;
      if (moveBack > 0) {
        offsetZ -= moveBack;
        offsetY = this.utils.getHeightByAngle(
          UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket(),
          UI.span + UI.multiSpan - moveBack,
          UI.patiosPitch,
          -1
        );
      }

      let beamLength = UI.rakeCutRightHor + this.geo_superiorPost.width;
      if (UI.rakeCutRightHor < UI.overhangRight) {
        beamLength = UI.overhangRight + this.geo_superiorPost.width;
      }

      let scaleX = beamLength / this.geo_beamStepRakecutRight.length;

      let beam = new Mesh(
        this.geo_beamStepRakecutRight.geometry,
        MaterialManager.Instance().BEAM
      );
      beam.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
      };
      beam.scale.setX(scaleX);

      let capL = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capL.position.set(
        -(beamLength + this.geo_beamEndCap.length / 2 - 2),
        0,
        -this.geo_beamEndCap.width / 2
      );
      capL.rotateY(Math.PI / 2);
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode( this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode)
      }

      let capR = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capR.position.set(
        -(this.geo_beamEndCap.length / 2 - 2),
        0,
        -this.geo_beamEndCap.width / 2
      );
      capR.rotateY(-Math.PI / 2);
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode( this.geo_beamEndCap.name, HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode)
      }

      let beamGroup = new Group();
      beamGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
      };
      if (UI.existingType == BUILDING_SIDE.RIGHT) {
        let fitZ =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        offsetXR -= fitZ;
      }
      if (UI.existingType == BUILDING_SIDE.LEFT) {
        let fitZ =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        offsetXR += fitZ;
      }
      beamGroup.position.set(offsetXR, offsetY, offsetZ);
      //beamGroup.rotateY(Math.PI);
      if (this.APP.sltBeamType.currentValue == 0) {
        beamGroup.add(beam, capL, capR);
      } else {
        beamGroup.add(beam);
      }

      this.scene.add(beamGroup);
    }
  }
  public getBracketHeight(offset): number {
    let angle = UI.patiosPitch;
    return (
      this.totalHeightFromEaveHeightToTopOfFlyoverBraket() -
      this.utils.tan(angle) * offset
    );
  }

  public destroy(): void {
    this.unregisterEvent();
  }

  private registerEvent(): void {
    this.eventHandleId = this.uiChangedDefer.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldSpan,
      this.APP.sldExistingWallHeight,
      this.APP.sldEaveWidth,
      this.APP.sldFasciaDepth,

      this.APP.sldExistingLength,
      this.APP.sldExistingLength2,
      this.APP.sldExistingWidth1,
      this.APP.sldExistingWidth2,

      this.APP.sltExistingType,
      this.APP.dialogEditBay,

      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,
      this.APP.sldFrontOverhang,
      this.APP.sltRoofPitch,
      this.APP.sldMultiSpan,
      this.APP.sldFlyOverBracketHeight,

      this.APP.sltColourBeam,
      this.APP.sltColourPost,
      this.APP.sltColourBracket,
      this.APP.sltColourFasciaBracket,
      this.APP.sltRoofThickness,
      this.APP.sltGutterType,
    ];
    //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));

    this.controlsToRegisterEvent2 = [
      this.APP.sltBeamType,
      this.APP.sltBeamSize,
      this.APP.sltHouseBeamSize,
      this.APP.sltColumnType,
    ];
    this.controlsToRegisterEvent2.forEach((c) =>
      c.addAction(this.objectSizeChangedHandle)
    );
  }
  private unregisterEvent(): void {
    //this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    //this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach((c) =>
      c.removeAction(this.objectSizeChangedHandle)
    );
    this.controlsToRegisterEvent2 = undefined;
  }
  private objectSizeChanged(pre: number, cur: number) {
    this.optimize().then(() => {
      this.load();
      this.MANAGER.loadRafterBeamAndInternalBeam(this.MANAGER.rafterBeamAndInternalBeamInfo)
    });
  }
  uiChangedDefer(previousValue: number, currentValue: number) {
    if (this.APP.sltCutOut.currentValue == 1) {
      if (this.deferHandle) {
        clearTimeout(this.deferHandle);
      }
      this.deferHandle = setTimeout(() => {
        this.load();
      }, this.deferTimeout);
    } else {
      this.load();
    }
  }
}
