// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  version: require("../../package.json").version,
  modelBaseUrl: ".",
  uploadFolder: "superiorsoftware_api/upload/dataFiles",
  mediaFolder: "superiorsoftware_api/media",
  // VPS
  // apiBaseUrl: ".",
  // apiDbUrl: "http://demo.superiorsoftware.net.au:8080/api",
  // uploadUrl: "http://demo.superiorsoftware.net.au:8080/upload/dataFiles",
  // backendUrl: "http://admin.superiorsoftware.net.au/",
  // BUILD
  apiBaseUrl: "https://patios.superiorsoftware.net.au",
  apiDbUrl: "https://patios.superiorsoftware.net.au:9999/api",
  uploadUrl: "https://patios.superiorsoftware.net.au:9999/upload/dataFiles",
  backendUrl: "https://admin.superiorsoftware.net.au/",

  // DEMO
  // apiBaseUrl: ".",
  // apiDbUrl: "https://patios.superiorsoftware.net.au:8080/api",
  // uploadUrl: "https://patios.superiorsoftware.net.au:8080/upload/dataFiles",
  // backendUrl: "https://admin.superiorsoftware.net.au/",
  // Local
  // apiBaseUrl: "http://localhost:5000",
  // apiDbUrl: "http://127.0.0.1:8080/api",
  // uploadUrl: "http://127.0.0.1:8080/upload/dataFiles",
  // backendUrl: "http://localhost:3000/",
};
