import { Observable, Subject } from "rxjs";
import { debounce, debounceTime, map, takeLast } from "rxjs/operators";
import { Group, Material, Object3D } from "three";
import { GeometryManager } from "src/app-ribspan/core/geometry.manager";
import { MaterialManager } from "src/app-ribspan/core/material.manager";
import { HomeComponent } from "src/app-ribspan/containers/home";
import { registerEvents } from "./ui.manager";
export interface IManager {
    init: () => Promise<void>
    optimize: () => Promise<void>
    load: () => Promise<void>
    onUIChange: () => void
    // calculate: () => Promise<void>
}
export class BaseManager implements IManager {
    controls = []
    controlsReload = []

    onUIChangePtr = this.onUIChange.bind(this)
    onGeometryChangePtr = this.onGeometryChange.bind(this)

    APP: HomeComponent
    geometryManager: GeometryManager;
    materialMgr = MaterialManager.Instance()
    container: Group = undefined
    subject: Subject<void>
    
    constructor() {
        this.geometryManager = GeometryManager.Instance();

        this.subject = new Subject()
        this.subject.pipe(debounceTime(500)).subscribe(() => { 
            console.log('subject debounce run')
            this.optimize().then(() => {this.load()}).then(()=>{
                HomeComponent.ins.renderStatic();
            })
        })
    }

    init(): Promise<void> {
        this.APP = HomeComponent.ins

        return new Promise(resolve => {
            this.container = new Group()
            HomeComponent.ins.scene.add(this.container)
            registerEvents(this.controls, this.onUIChangePtr)
            registerEvents(this.controlsReload, this.onGeometryChangePtr)
            resolve()
        })
    }

    load(): Promise<void> {
        return new Promise((resolve) => {
            this.container.children = []

            resolve()
        })
    }
    optimize(): Promise<void> { return Promise.resolve() }
    // calculate(): Promise<void>{ return Promise.resolve() }
    onUIChange(): void {
        this.load()
    }
    onGeometryChange(): void {
        this.subject.next()
    }


    registerEvents(controls, handler){
        controls.forEach(ctrl => {
            ctrl.addAction(handler)
        });
    }
}