<div class="popup-window" [ngClass]="{ 'p-show': visible }">
    <div class="p-header">Rake cut</div>
    <div class="p-body">
        <dropdown-input #inputRakecutType [items]="rakecutTypes" [selected]="0" [disabled]="false">
        </dropdown-input>
    </div>
    <div class="p-footer">
      <button class="ui button mini" (click)="onOK()">OK</button>
      <button class="ui button mini" (click)="onCancel()">Cancel</button>
    </div>
  </div>