<div class="top-nav-bar">
  <div nz-row class="row" nzType="flex" nzJustify="space-between">
    <!-- Logo -->
    <div nz-col class="gutter-row col-logo" nzSpan="7">
      <!-- <div class="logo">
      </div> -->
      <img class="logo" src="../../../assets/images/ui/superior_logo.jpg" />
    </div>
    <!-- Main menu -->
    <div nz-col class="gutter-row col-main-menu" nzSpan="12">
      <div nz-row nzType="flex" nzJustify="start">
        <ul id="nav">
          <li class="current">
            <a href="https://superiorpanels.com.au/"
              ><i class="home icon"></i
            ></a>
          </li>
          <li>
            <a href="#"><i class="th icon"></i>FILE</a>
            <ul>
              <li>
                <a href="#" (click)="onShowOpenModal(0)"
                  ><i class="file icon"></i>NEW</a
                >
              </li>
              <li>
                <a href="#" (click)="onShowOpenModal(1)"
                  ><i class="folder open outline icon"></i>OPEN</a
                >
              </li>
              <li>
                <a href="#"><i class="save icon"></i>SAVE AS...</a>
                <ul>
                  <li>
                    <a href="#" (click)="onSaveAs(1)"
                      ><i class="copy icon"></i>COPY</a
                    >
                  </li>
                  <li
                    [ngClass]="
                      currentBuildingInfo.isOpen &&
                      currentUser.user._id != currentBuildingInfo.userId
                        ? 'div-disabled'
                        : ''
                    "
                  >
                    <a href="#" (click)="onSaveAs(2)"
                      ><i class="share square icon"></i>REVISED</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li [ngClass]="isPricing ? 'div-disabled' : ''">
            <a href="#" (click)="onShowSaveModal()"
              ><i class="cloud upload icon"></i>UPLOAD</a
            >
          </li>

          <li>
            <a href="#"><i class="print icon"></i>PRINT</a>
            <ul>
              <li *ngIf="currentUser.user.roles === objects.roles.admin">
                <a href="#" (click)="onViewBOMTest()"
                  ><i class="list ol icon"></i>BOM
                  <span style="background-color: orange">(for Testing)</span></a
                >
              </li>
              <li>
                <a href="#" (click)="onViewBOM()"
                  ><i class="list ol icon"></i>BOM</a
                >
              </li>
              <li>
                <a href="#" (click)="onOpenFiles(1)"
                  ><i class="file pdf icon"></i>ENGINEERING</a
                >
              </li>
              <li>
                <a href="#" (click)="onOpenFiles(2)"
                  ><i class="file pdf icon"></i>QUOTATION</a
                >
              </li>
            </ul>
          </li>
          <!-- *ngIf="
              currentUser.user.roles === objects.roles.admin ||
              (currentUser.user.roles !== objects.roles.admin &&
                currentUser.user.isTryRibSpanVer)
            " -->
          <li>
            <a href="#" (click)="onOkRibspan()"
              ><i class="sync alternate icon"></i>RIBSPAN PANEL
            </a>
          </li>
          <!-- test -->
          <!-- <li><a (click)="print2D()"><i class="cloud upload icon"></i>TEST</a></li> -->
        </ul>
        <div class="ui red tag label div-job-no">
          {{
            currentBuildingInfo ? currentBuildingInfo.clientInfo.jobNo : "Empty"
          }}
        </div>
      </div>
      <div class="div-status" nz-row nzType="flex">
        <span>
          <b>Status</b>:<i> {{ isPricing ? "Pricing" : statusText }}...</i>
          <span class="red bold" *ngIf="isError">
            (Error: {{ statusError }})
          </span>
        </span>
      </div>
    </div>
    <!-- User info -->
    <div nz-col class="gutter-row col-user-info" nzSpan="5">
      <div class="top-nav-login-info">
        You're logged in as <b>{{ currentUser.user.userName }}</b>
        <!--{{'  '}}<nz-tag [nzColor]="'blue'">{{currentUser.user.roles}}</nz-tag>-->
        <br />
        <button class="ui red basic button btn-logout" (click)="logout()">
          <i class="logout icon"></i> Logout
        </button>
      </div>
    </div>
  </div>
</div>
<nz-modal
  [(nzVisible)]="isVisibleOpenModalRibspan"
  nzWidth="350px"
  (nzOnCancel)="onCancelRibspan()"
  [nzFooter]="null"
  nzWrapClassName="vertical-center-modal"
>
  <div nz-row nzType="flex" nzJustify="space-around" nzAlign="middle">
    <div
      nz-row
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <div nz-col nzSpan="24">
        <div class="cardContainer inactive" data-tooltip="INSULATED PANEL">
          <div class="card-menu" (click)="onCancelRibspan()">
            <div class="side frontHouse">
              <div class="img house1"></div>
            </div>
          </div>
        </div>
      </div>
      <div nz-col nzSpan="24">
        <div class="cardContainer inactive" data-tooltip="RIBSPAN PANEL">
          <div class="card-menu" (click)="onOkRibspan()">
            <span class="beta">BETA</span>
            <div class="side frontHouse">
              <div class="img house11"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nz-modal>

<nz-modal
  [nzStyle]="{ top: '0px' }"
  [(nzVisible)]="isVisibleOpenModal"
  nzTitle="Choose an option"
  nzWidth="80%"
  (nzOnCancel)="onCancel()"
  [nzFooter]="null"
  nzMaskClosable="false"
  nzKeyboard="false"
>
  <nz-spin [nzSpinning]="isOpening" nzTip="Loading...">
    <nz-tabset [nzSelectedIndex]="newOpenType" [nzType]="'card'">
      <nz-tab [nzTitle]="titleTemplateNew">
        <ng-template #titleTemplateNew
          ><i nz-icon nzType="plus" nzTheme="outline"></i>New</ng-template
        >
        <!-- <nz-card nzTitle="Choose a Connection Type"> -->
        <nz-steps [nzCurrent]="currentStepNew">
          <nz-step nzTitle="Existing House Shape"></nz-step>
          <nz-step nzTitle="Connection Type"></nz-step>
          <!-- <nz-step nzTitle="Finished"></nz-step> -->
        </nz-steps>

        <nz-tabset
          [nzSelectedIndex]="currentStepNew"
          [nzTabPosition]="'bottom'"
        >
          <nz-tab nzTitle="">
            <nz-divider></nz-divider>
            <!-- 1 -->
            <div nz-row class="div-search">
              <!-- <div nz-col nzSpan="1"></div> -->
              <div nz-col nzSpan="3">
                <p>Patios Roof Type:</p>
              </div>
              <div nz-col nzSpan="4">
                <!-- <dropdown-input #sltPatiosRoofType [items]="objects.patiosRoofTypeList" [selected]="0"></dropdown-input> -->
                <!-- [ngClass]="
                    currentUser.user.roles === objects.roles.admin
                    ? ''
                    : 'div-disabled'
                  " -->
                <nz-select
                  [(ngModel)]="patiosRoofType"
                  nzPlaceHolder="Choose"
                  style="width: 100%; margin-left: 16px"
                >
                  <nz-option nzValue="flat" nzLabel="Flat Roof"></nz-option>
                  <nz-option nzValue="gable" nzLabel="Gable Roof"></nz-option>
                </nz-select>
              </div>
              <div nz-col nzSpan="16"></div>
            </div>

            <div
              nz-row
              *ngIf="patiosRoofType === 'flat'"
              nzType="flex"
              nzJustify="space-around"
              nzAlign="middle"
            >
              <div nz-col nzSpan="8">
                <!-- <button nzTooltipTitle="prompt text" nzTooltipPlacement="rightTop" nz-button nz-tooltip>RT</button> -->
                <div class="cardContainer inactive" data-tooltip="STANDARD">
                  <div class="card-menu" (click)="onNewExistingHouseType(0)">
                    <div class="side frontHouse">
                      <div class="img house1"></div>
                      <!-- <div class="info">
                          <h3>STANDARD</h3>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div class="cardContainer inactive" data-tooltip="L-SHAPE LEFT">
                  <div class="card-menu" (click)="onNewExistingHouseType(1)">
                    <div class="side frontHouse">
                      <div class="img house2"></div>
                      <!-- <div class="info">
                          <h3>L-SHAPE LEFT</h3>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div
                  class="cardContainer inactive"
                  data-tooltip="L-SHAPE RIGHT"
                >
                  <div class="card-menu" (click)="onNewExistingHouseType(2)">
                    <div class="side frontHouse">
                      <div class="img house3"></div>
                      <!-- <div class="info">
                          <h3>L-SHAPE RIGHT</h3>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              nz-row
              *ngIf="patiosRoofType === 'flat'"
              nzType="flex"
              nzJustify="space-around"
              nzAlign="middle"
            >
              <div nz-col nzSpan="8">
                <div class="cardContainer inactive" data-tooltip="U SHAPE">
                  <div class="card-menu" (click)="onNewExistingHouseType(3)">
                    <div class="side frontHouse">
                      <div class="img house4"></div>
                      <!-- <div class="info">
                          <h3>U SHAPE</h3>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div class="cardContainer inactive" data-tooltip="CUT-OUT LEFT">
                  <div class="card-menu" (click)="onNewExistingHouseType(5)">
                    <div class="side frontHouse">
                      <div class="img house5"></div>
                      <!-- <div class="info">
                          <h3>CUT-OUT LEFT</h3>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div
                  class="cardContainer inactive"
                  data-tooltip="CUT-OUT RIGHT"
                >
                  <div class="card-menu" (click)="onNewExistingHouseType(6)">
                    <div class="side frontHouse">
                      <div class="img house6"></div>
                      <!-- <div class="info">
                          <h3>CUT-OUT RIGHT</h3>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              nz-row
              *ngIf="patiosRoofType === 'flat'"
              nzType="flex"
              nzJustify="space-around"
              nzAlign="middle"
            >
              <div nz-col nzSpan="8">
                <div
                  class="cardContainer inactive"
                  data-tooltip="CUT-OUT LEFT 2"
                >
                  <div class="card-menu" (click)="onNewExistingHouseType(7)">
                    <div class="side frontHouse">
                      <div class="img house7"></div>
                      <!-- <div class="info">
                          <h3>CUT-OUT LEFT 2</h3>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div
                  class="cardContainer inactive"
                  data-tooltip="CUT-OUT RIGHT 2"
                >
                  <div class="card-menu" (click)="onNewExistingHouseType(8)">
                    <div class="side frontHouse">
                      <div class="img house8"></div>
                      <!-- <div class="info">
                          <h3>CUT-OUT RIGHT 2</h3>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div
                  class="cardContainer inactive"
                  data-tooltip="FREE STANDING"
                >
                  <div class="card-menu" (click)="onNewConnectionType(4)">
                    <div class="side frontHouse">
                      <div class="img house9"></div>
                      <!-- <div class="info">
                          <h3>FREE STANDING</h3>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              nz-row
              *ngIf="patiosRoofType === 'gable'"
              nzType="flex"
              nzJustify="space-around"
              nzAlign="middle"
            >
              <div nz-col nzSpan="8">
                <div class="cardContainer inactive" data-tooltip="STANDARD 1">
                  <div class="card-menu" (click)="onNewExistingHouseType(0)">
                    <div class="side frontHouse">
                      <div class="img ghouse1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div class="cardContainer inactive" data-tooltip="STANDARD 2">
                  <div
                    class="card-menu"
                    (click)="onNewExistingHouseType(0, true)"
                  >
                    <div class="side frontHouse">
                      <div class="img ghouse10"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div class="cardContainer inactive" data-tooltip="L-SHAPE LEFT">
                  <div class="card-menu" (click)="onNewExistingHouseType(1)">
                    <div class="side frontHouse">
                      <div class="img ghouse2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              nz-row
              *ngIf="patiosRoofType === 'gable'"
              nzType="flex"
              nzJustify="space-around"
              nzAlign="middle"
            >
              <div nz-col nzSpan="8">
                <div
                  class="cardContainer inactive"
                  data-tooltip="L-SHAPE RIGHT"
                >
                  <div class="card-menu" (click)="onNewExistingHouseType(2)">
                    <div class="side frontHouse">
                      <div class="img ghouse3"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div class="cardContainer inactive" data-tooltip="U SHAPE 1">
                  <div class="card-menu" (click)="onNewExistingHouseType(3)">
                    <div class="side frontHouse">
                      <div class="img ghouse4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div class="cardContainer inactive" data-tooltip="U SHAPE 2">
                  <div
                    class="card-menu"
                    (click)="onNewExistingHouseType(3, true)"
                  >
                    <div class="side frontHouse">
                      <div class="img ghouse11"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              nz-row
              *ngIf="patiosRoofType === 'gable'"
              nzType="flex"
              nzJustify="space-around"
              nzAlign="middle"
            >
              <div nz-col nzSpan="8">
                <div
                  class="cardContainer inactive div-disabled"
                  data-tooltip="CUT-OUT LEFT"
                >
                  <div class="card-menu" (click)="onNewExistingHouseType(5)">
                    <div class="side frontHouse">
                      <div class="img ghouse5"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div
                  class="cardContainer inactive div-disabled"
                  data-tooltip="CUT-OUT RIGHT"
                >
                  <div class="card-menu" (click)="onNewExistingHouseType(6)">
                    <div class="side frontHouse">
                      <div class="img ghouse6"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div
                  class="cardContainer inactive div-disabled"
                  data-tooltip="CUT-OUT LEFT 2"
                >
                  <div class="card-menu" (click)="onNewExistingHouseType(7)">
                    <div class="side frontHouse">
                      <div class="img ghouse7"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              nz-row
              *ngIf="patiosRoofType === 'gable'"
              nzType="flex"
              nzJustify="start"
              nzAlign="middle"
            >
              <div nz-col nzSpan="8">
                <div
                  class="cardContainer inactive div-disabled"
                  data-tooltip="CUT-OUT RIGHT 2"
                >
                  <div class="card-menu" (click)="onNewExistingHouseType(8)">
                    <div class="side frontHouse">
                      <div class="img ghouse8"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div
                  class="cardContainer inactive"
                  data-tooltip="FREE STANDING"
                >
                  <div class="card-menu" (click)="onNewConnectionType(4)">
                    <div class="side frontHouse">
                      <div class="img ghouse9"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nz-tab>
          <nz-tab nzTitle="">
            <nz-divider></nz-divider>
            <div nz-row nzType="flex" nzJustify="space-around" nzAlign="middle">
              <div
                nz-col
                nzSpan="8"
                [ngClass]="
                  patiosRoofType === 'gable' && !isFBRoof ? 'div-disabled' : ''
                "
              >
                <div class="cardContainer inactive">
                  <div class="card-menu" (click)="onNewConnectionType(0)">
                    <div class="side front">
                      <div class="img img1"></div>
                      <div class="info">
                        <h3>EXISTING BUILDING</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div class="cardContainer inactive">
                  <div class="card-menu" (click)="onNewConnectionType(1)">
                    <div class="side front">
                      <div class="img img2"></div>
                      <div class="info">
                        <h3>FLY OVER</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                nz-col
                nzSpan="8"
                [ngClass]="patiosRoofType === 'gable' ? 'div-disabled' : ''"
              >
                <div class="cardContainer inactive">
                  <div class="card-menu" (click)="onNewConnectionType(2)">
                    <div class="side front">
                      <div class="img img3"></div>
                      <div class="info">
                        <h3>BACK FLY OVER</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div nz-row nzType="flex" nzJustify="space-around" nzAlign="middle">
              <div
                nz-col
                nzSpan="8"
                [ngClass]="patiosRoofType === 'gable' ? 'div-disabled' : ''"
              >
                <div class="cardContainer inactive">
                  <div class="card-menu" (click)="onNewConnectionType(3)">
                    <div class="side front">
                      <div class="img img4"></div>
                      <div class="info">
                        <h3>TO FASCIA</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div class="cardContainer inactive">
                  <div class="card-menu" (click)="onNewConnectionType(5)">
                    <div class="side front">
                      <div class="img img2"></div>
                      <div class="info">
                        <h3>FASCIA UPSTAND BRACKET</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                nz-col
                nzSpan="8"
                [ngClass]="patiosRoofType === 'gable' ? 'div-disabled' : ''"
              >
                <div class="cardContainer inactive">
                  <div class="card-menu" (click)="onNewConnectionType(6)">
                    <div class="side front">
                      <div class="img img3"></div>
                      <div class="info">
                        <h3>BACK FASCIA</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nz-tab>
        </nz-tabset>
        <div nz-row nzType="flex" nzJustify="space-between">
          <div nz-col nzSpan="12">
            <button
              class="small basic primary ui button"
              (click)="onPreNew()"
              *ngIf="currentStepNew > 0"
            >
              <i class="arrow alternate circle left outline icon"></i>
              Back
            </button>
            <!-- <button class="small basic primary ui button" (click)="onNextNew()" *ngIf="currentStepNew === 0">
                Next
                <i class="arrow alternate circle right outline icon"></i>
              </button> -->
          </div>
        </div>
      </nz-tab>
      <nz-tab [nzTitle]="titleTemplateOpen">
        <ng-template #titleTemplateOpen
          ><i nz-icon nzType="folder-open" nzTheme="outline"></i
          >Open</ng-template
        >
        <nz-divider nzText="Filter" nzOrientation="left"></nz-divider>
        <div nz-row class="div-search">
          <div nz-col nzSpan="3">
            <p>Job No.:</p>
          </div>
          <div nz-col nzSpan="5">
            <input
              #txtJobNo
              nz-input
              placeholder="Job No..."
              [(ngModel)]="jobNoSearch"
              style="text-transform: uppercase"
            />
          </div>
          <div nz-col nzSpan="1"></div>
          <div nz-col nzSpan="3">
            <p>Client Name:</p>
          </div>
          <div nz-col nzSpan="8">
            <input
              #txtCusName
              nz-input
              placeholder="Client Name..."
              [(ngModel)]="cusNameSearch"
            />
          </div>
          <div nz-col nzSpan="1"></div>
          <div nz-col nzSpan="3">
            <button
              [ngClass]="
                isSearching
                  ? 'small facebook ui button loading'
                  : 'small facebook ui button'
              "
              (click)="onSearch()"
            >
              <i nz-icon nzType="search"></i>
              Search
            </button>
            <!-- <button nz-button [nzType]="'primary'" (click)="onSearch()">
              <i nz-icon nzType="search"></i>
              Search
            </button> -->
          </div>
        </div>
        <nz-divider nzText="Result" nzOrientation="left"></nz-divider>
        <nz-table
          #tOPEN
          [nzData]="dataOPEN"
          nzSize="small"
          [nzLoading]="isSearching"
        >
          <thead>
            <tr>
              <th nzWidth="150px">Quote No.</th>
              <th nzWidth="110px">Quote Date</th>
              <th>Client Name</th>
              <th nzWidth="170px">User Name</th>
              <th nzWidth="90px">Prod.</th>
              <th nzWidth="130px">Total Price</th>
              <th nzWidth="200px">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of tOPEN.data">
              <td>{{ data.clientInfo.jobNo }}</td>
              <td>{{ data.clientInfo.date | date : "dd/MM/yyyy" }}</td>
              <td>{{ data.clientInfo.cusName }}</td>
              <td>{{ data.userId.fullName }}</td>
              <td
                *ngIf="
                  !data?.buildingInfo?.panelType ||
                  data?.buildingInfo?.panelType === 'Insulated'
                "
              >
                <nz-tag [nzColor]="'#2db7f5'">{{
                  data?.buildingInfo?.panelType
                }}</nz-tag>
              </td>
              <td *ngIf="data?.buildingInfo?.panelType === 'RibSpan'">
                <nz-tag [nzColor]="'#87d068'">{{
                  data?.buildingInfo?.panelType
                }}</nz-tag>
              </td>
              <td nzAlign="right">
                {{ data.quoteInfo.totalPrice | currency }}
              </td>
              <td>
                <button
                  (click)="onOkRibspan()"
                  class="ui mini facebook circular icon button btn-margin-left"
                  data-tooltip="Switch to RibSpan"
                  data-position="top center"
                  *ngIf="data?.buildingInfo?.panelType !== panelType"
                >
                  <div class="div-bottom">
                    <i class="retweet icon"></i> Switch to RibSpan
                  </div>
                </button>
                <button
                  *ngIf="data?.buildingInfo?.panelType === panelType"
                  (click)="onOpenById(data._id)"
                  class="ui mini facebook circular icon button btn-margin-left"
                  data-tooltip="Open"
                  data-position="top center"
                >
                  <div class="div-bottom"><i class="folder open icon"></i></div>
                </button>
                <button
                  *ngIf="data?.buildingInfo?.panelType === panelType"
                  (click)="onQuickSaveAs(1, data._id)"
                  class="ui mini green circular icon button btn-margin-left"
                  data-tooltip="Copy"
                  data-position="top center"
                >
                  <div class="div-bottom"><i class="copy icon"></i></div>
                </button>
                <button
                  *ngIf="data?.buildingInfo?.panelType === panelType"
                  (click)="onQuickSaveAs(2, data._id)"
                  class="ui mini green circular icon button btn-margin-left"
                  [ngClass]="{
                    disabled: data.userId._id != currentUser.user._id
                  }"
                  data-tooltip="Revised"
                  data-position="top center"
                >
                  <div class="div-bottom">
                    <i class="share square icon"></i>
                  </div>
                </button>
                <button
                  *ngIf="data?.files?.thumbnailFile"
                  class="ui mini yellow circular icon button btn-margin-left"
                  data-tooltip="Thumbnail"
                  data-position="top center"
                  (click)="onShowThumbnailClick(data)"
                >
                  <div class="div-bottom">
                    <i class="image outline icon"></i>
                  </div>
                </button>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>
    </nz-tabset>
  </nz-spin>
</nz-modal>

<nz-modal
  [nzStyle]="{ top: '0px' }"
  [(nzVisible)]="isVisible"
  nzTitle="Save"
  nzWidth="70%"
  (nzOnCancel)="onCancel()"
  [nzFooter]="null"
>
  <nz-steps [nzCurrent]="currentStep">
    <nz-step nzTitle="Client Information"></nz-step>
    <nz-step nzTitle="BOM"></nz-step>
    <nz-step nzTitle="Quotation"></nz-step>
    <nz-step nzTitle="Saving data"></nz-step>
    <nz-step nzTitle="Finished"></nz-step>
  </nz-steps>
  <nz-tabset [nzSelectedIndex]="currentStep" [nzTabPosition]="'bottom'">
    <nz-tab nzTitle="">
      <nz-divider></nz-divider>
      <!-- 1 -->
      <div nz-row class="div-search">
        <div nz-col nzSpan="5">
          <p>Job No.</p>
        </div>
        <div nz-col nzSpan="7">
          <input
            nz-input
            [(ngModel)]="currentQuoteInfo.clientInfo.jobNo"
            style="text-transform: uppercase"
            [disabled]="true"
          />
        </div>
        <div nz-col nzSpan="1"></div>
        <div nz-col nzSpan="4">
          <p>Date</p>
        </div>
        <div nz-col nzSpan="7">
          <nz-date-picker
            nzDisabled
            nzFormat="dd/MM/yyyy"
            [(ngModel)]="currentQuoteInfo.clientInfo.date"
          >
          </nz-date-picker>
        </div>
      </div>
      <!-- 2 -->
      <div nz-row class="div-search">
        <div nz-col nzSpan="5">
          <p>Client Name</p>
        </div>
        <div nz-col nzSpan="7">
          <input nz-input [(ngModel)]="currentQuoteInfo.clientInfo.cusName" />
        </div>
        <div nz-col nzSpan="1"></div>
        <div nz-col nzSpan="4">
          <p>Site Address</p>
        </div>
        <div nz-col nzSpan="7">
          <input nz-input [(ngModel)]="currentQuoteInfo.clientInfo.address" />
        </div>
      </div>
      <!-- 3 -->
      <div nz-row class="div-search">
        <div nz-col nzSpan="5">
          <p>Suburb</p>
        </div>
        <div nz-col nzSpan="7">
          <input nz-input [(ngModel)]="currentQuoteInfo.clientInfo.suburb" />
        </div>
        <div nz-col nzSpan="1"></div>
        <div nz-col nzSpan="4">
          <p>State</p>
        </div>
        <div nz-col nzSpan="7">
          <input nz-input [(ngModel)]="currentQuoteInfo.clientInfo.state" />
        </div>
      </div>
      <!-- 4 -->
      <div nz-row class="div-search">
        <div nz-col nzSpan="5">
          <p>Postcode</p>
        </div>
        <div nz-col nzSpan="7">
          <input nz-input [(ngModel)]="currentQuoteInfo.clientInfo.postcode" />
        </div>
        <div nz-col nzSpan="1"></div>
        <div nz-col nzSpan="4">
          <p>Email Address</p>
        </div>
        <div nz-col nzSpan="7">
          <input nz-input [(ngModel)]="currentQuoteInfo.clientInfo.email" />
        </div>
      </div>
      <!-- 5 -->
      <div nz-row class="div-search">
        <div nz-col nzSpan="5">
          <p>Phone</p>
        </div>
        <div nz-col nzSpan="7">
          <input nz-input [(ngModel)]="currentQuoteInfo.clientInfo.phone" />
        </div>
        <div nz-col nzSpan="1"></div>
        <div nz-col nzSpan="4">
          <p>Supply Type</p>
        </div>
        <div nz-col nzSpan="7">
          <nz-select
            [(ngModel)]="currentQuoteInfo.clientInfo.supplyType"
            nzPlaceHolder="Choose"
            (ngModelChange)="onSupplyTypeChange()"
            style="width: 100%"
          >
            <nz-option
              nzValue="supply_only"
              nzLabel="Supply Kit Only"
            ></nz-option>
            <nz-option
              nzValue="supply_install"
              nzLabel="Supply and Install"
            ></nz-option>
          </nz-select>
        </div>
      </div>
    </nz-tab>
    <!-- BOM -->
    <nz-tab nzTitle="">
      <panel-bom #panelBOM [boms]="currentBuildingInfo.BOMInfo"></panel-bom>
    </nz-tab>
    <!-- Quotation -->
    <nz-tab nzTitle="">
      <div class="segment-left">
        <a class="ui blue ribbon label">Quote Includes</a>
        <p>{{ currentQuoteInfo.quoteInfo.note }}</p>
      </div>
      <!-- <nz-divider nzText="Quote Includes" nzOrientation="left"></nz-divider> -->
      <!-- <nz-divider nzText="Note" nzOrientation="left"></nz-divider> -->
      <div class="segment-left">
        <a class="ui blue ribbon label">Note</a>
        <textarea
          rows="2"
          nz-input
          [(ngModel)]="currentQuoteInfo.quoteInfo.userNote"
        ></textarea>
      </div>
      <div class="segment-left">
        <a class="ui blue ribbon label">Price</a>
        <!-- Nett -->
        <div
          nz-row
          [style.display]="isVisibleMoreInfo ? '' : 'none'"
          class="div-quote-label"
        >
          <div nz-col nzSpan="16" class="bold">
            <span>Nett</span>
          </div>
          <div nz-col class="text-align-right bold" nzSpan="8">
            <span>{{ currentQuoteInfo.quoteInfo.nett | currency }}</span>
          </div>
        </div>
        <!-- Off Fascia Install -->
        <div
          nz-row
          [style.display]="isVisibleMoreInfo ? '' : 'none'"
          class="div-quote-label"
        >
          <div nz-col nzSpan="16">
            <span>Off Fascia Install</span>
          </div>
          <div nz-col class="text-align-right" nzSpan="8">
            <span>{{
              currentQuoteInfo.quoteInfo.fasciaInstall | currency
            }}</span>
          </div>
        </div>
        <!-- Fly Over Install -->
        <div
          nz-row
          [style.display]="isVisibleMoreInfo ? '' : 'none'"
          class="div-quote-label"
        >
          <div nz-col nzSpan="16">
            <span>Fly Over Install</span>
          </div>
          <div nz-col class="text-align-right" nzSpan="8">
            <span>{{
              currentQuoteInfo.quoteInfo.flyOverInstall | currency
            }}</span>
          </div>
        </div>
        <!-- Install Mark up on material -->
        <div
          nz-row
          [style.display]="isVisibleMoreInfo ? '' : 'none'"
          class="div-quote-label"
        >
          <div nz-col nzSpan="16">
            <span>Install Mark up on material</span>
          </div>
          <div nz-col class="text-align-right" nzSpan="8">
            <span>{{
              currentQuoteInfo.quoteInfo.installMaterial | currency
            }}</span>
          </div>
        </div>
        <!-- Per post install -->
        <div
          nz-row
          [style.display]="isVisibleMoreInfo ? '' : 'none'"
          class="div-quote-label"
        >
          <div nz-col nzSpan="16">
            <span>Per post install</span>
          </div>
          <div nz-col class="text-align-right" nzSpan="8">
            <span>{{
              currentQuoteInfo.quoteInfo.perPostInstall | currency
            }}</span>
          </div>
        </div>
        <!-- Std Additional Costs -->
        <div
          nz-row
          [style.display]="isVisibleMoreInfo ? '' : 'none'"
          class="div-quote-label"
        >
          <div nz-col nzSpan="16">
            <span>Std Additional Costs</span>
          </div>
          <div nz-col class="text-align-right" nzSpan="8">
            <span>{{ currentQuoteInfo.quoteInfo.addCost | currency }}</span>
          </div>
        </div>
        <!-- Mark up on Supply Kits-->
        <div
          nz-row
          [style.display]="isVisibleMoreInfo ? '' : 'none'"
          class="div-quote-label"
        >
          <div nz-col nzSpan="16">
            <span>Mark up on Supply Kits</span>
          </div>
          <div nz-col class="text-align-right" nzSpan="8">
            <span>{{
              currentQuoteInfo.quoteInfo.supplyOnlyKits | currency
            }}</span>
          </div>
        </div>
        <!-- Additional Cost Install -->
        <div nz-row class="div-quote">
          <div nz-col nzSpan="7">
            <span>Additional Cost Install</span>
          </div>
          <div nz-col nzSpan="12">
            <nz-badge [nzCount]="currentQuoteInfo.quoteInfo.costList.length">
              <button
                class="mini facebook ui button"
                (click)="onShowAddCostClick()"
              >
                <i class="plus circle icon"></i>
                <span>Add More</span>
              </button>
            </nz-badge>
            <span>{{ addCostInstallDes }}</span>
          </div>
          <div nz-col nzSpan="5" class="text-align-right">
            <span>{{
              currentQuoteInfo.quoteInfo.addCostInstall | currency
            }}</span>
          </div>
        </div>
        <!-- GST-->
        <div nz-row class="div-quote-label">
          <div nz-col nzSpan="16">
            <span>GST</span>
          </div>
          <div nz-col class="text-align-right" nzSpan="8">
            <span>{{ currentQuoteInfo.quoteInfo.gst | currency }}</span>
          </div>
        </div>
        <!-- Additional Cost Install -->
        <div nz-row class="div-quote-label">
          <div nz-col nzSpan="16" class="bold">
            <span>Total Price</span>
          </div>
          <!-- <div nz-col nzSpan="9">
            <nz-switch [(ngModel)]="isVisibleDiscount" nzTooltipTitle="Show/Hide the discount" nz-tooltip
              nzCheckedChildren="Show discount" nzUnCheckedChildren="Hide discount"></nz-switch>
          </div> -->
          <div nz-col class="text-align-right red bold" nzSpan="8">
            <span>{{ currentQuoteInfo.quoteInfo.totalPrice | currency }}</span>
          </div>
        </div>
        <!-- Deposit (%)-->
        <div nz-row class="div-quote">
          <div nz-col nzSpan="7">
            <p
              *ngIf="currentQuoteInfo.quoteInfo.customText"
              nz-typography
              nzEditable
              [(nzContent)]="currentQuoteInfo.quoteInfo.customText.depositText"
              (nzContentChange)="onCustomTextChange($event, 0)"
            ></p>
            <!-- <span>Deposit</span> -->
          </div>
          <div nz-col nzSpan="8">
            <nz-input-group nzAddOnAfter="%">
              <input
                currencyMask
                nz-input
                [(ngModel)]="currentQuoteInfo.quoteInfo.depositPer"
                (blur)="calcPayment()"
              />
            </nz-input-group>
          </div>
          <div nz-col nzSpan="1">
            <span></span>
          </div>
          <div nz-col class="text-align-right" nzSpan="8">
            <span>{{ currentQuoteInfo.quoteInfo.deposit | currency }}</span>
          </div>
        </div>
        <!-- Stage 1 (%)-->
        <div nz-row class="div-quote">
          <div nz-col nzSpan="7">
            <p
              *ngIf="currentQuoteInfo.quoteInfo.customText"
              nz-typography
              nzEditable
              [(nzContent)]="currentQuoteInfo.quoteInfo.customText.stage1Text"
              (nzContentChange)="onCustomTextChange($event, 1)"
            ></p>
            <!-- <span>Stage 1</span> -->
          </div>
          <div nz-col nzSpan="8">
            <nz-input-group nzAddOnAfter="%">
              <input
                currencyMask
                nz-input
                [(ngModel)]="currentQuoteInfo.quoteInfo.stage1Per"
                (blur)="calcPayment()"
              />
            </nz-input-group>
          </div>
          <div nz-col nzSpan="1">
            <span></span>
          </div>
          <div nz-col class="text-align-right" nzSpan="8">
            <span>{{ currentQuoteInfo.quoteInfo.stage1 | currency }}</span>
          </div>
        </div>
        <!-- Stage 2 (%)-->
        <div nz-row class="div-quote">
          <div nz-col nzSpan="7">
            <p
              *ngIf="currentQuoteInfo.quoteInfo.customText"
              nz-typography
              nzEditable
              [(nzContent)]="currentQuoteInfo.quoteInfo.customText.stage2Text"
              (nzContentChange)="onCustomTextChange($event, 2)"
            ></p>
            <!-- <span>Stage 2</span> -->
          </div>
          <div nz-col nzSpan="8">
            <nz-input-group nzAddOnAfter="%">
              <input
                currencyMask
                nz-input
                [(ngModel)]="currentQuoteInfo.quoteInfo.stage2Per"
                (blur)="calcPayment()"
              />
            </nz-input-group>
          </div>
          <div nz-col nzSpan="1">
            <span></span>
          </div>
          <div nz-col class="text-align-right" nzSpan="8">
            <span>{{ currentQuoteInfo.quoteInfo.stage2 | currency }}</span>
          </div>
        </div>
        <!-- Final Payment-->
        <div nz-row class="div-quote">
          <div nz-col nzSpan="7">
            <nz-form-label>Final Payment</nz-form-label>
          </div>
          <div nz-col nzSpan="9">
            <nz-switch
              [(ngModel)]="isVisibleDiscount"
              nzTooltipTitle="Show/Hide the discount"
              nz-tooltip
              nzCheckedChildren="Show discount"
              nzUnCheckedChildren="Hide discount"
            ></nz-switch>
          </div>
          <div nz-col class="text-align-right" nzSpan="8">
            <span>{{
              currentQuoteInfo.quoteInfo.finalPayment | currency
            }}</span>
          </div>
        </div>
        <!-- Discount -->
        <div
          nz-row
          [style.display]="isVisibleDiscount ? '' : 'none'"
          class="div-quote"
        >
          <div nz-col nzSpan="7">
            <span>Discount</span>
          </div>
          <div nz-col nzSpan="8">
            <nz-input-group nzAddOnAfter="%">
              <input
                currencyMask
                nz-input
                [(ngModel)]="currentQuoteInfo.quoteInfo.discountPer"
                (blur)="calcPayment()"
              />
            </nz-input-group>
          </div>
          <div nz-col nzSpan="1">
            <span></span>
          </div>
          <div nz-col nzSpan="8">
            <nz-input-group
              nzAddOnBefore="$"
              *ngIf="currentQuoteInfo.quoteInfo.discountPer === 0"
            >
              <input
                currencyMask
                nz-input
                (blur)="calcPayment()"
                [(ngModel)]="currentQuoteInfo.quoteInfo.discount"
              />
            </nz-input-group>
            <label
              class="text-align-right"
              style="width: 100%"
              *ngIf="currentQuoteInfo.quoteInfo.discountPer > 0"
              >{{ currentQuoteInfo.quoteInfo.discount | currency }}</label
            >
          </div>
        </div>
        <!-- End -->
      </div>

      <!-- <nz-divider nzText="Price" nzOrientation="left"></nz-divider> -->
    </nz-tab>
    <nz-tab nzTitle="">
      <div class="uploading-div">
        <div class="bg"></div>
        <h3>Uploading, Please wait...</h3>
      </div>
    </nz-tab>
  </nz-tabset>
  <div nz-row nzType="flex" nzJustify="space-between">
    <div nz-col nzSpan="12">
      <button
        class="small basic primary ui button"
        (click)="onPre()"
        *ngIf="currentStep > 0 && currentStep < 3"
      >
        <i class="arrow alternate circle left outline icon"></i>
        Previous
      </button>
      <button
        class="small basic primary ui button"
        (click)="onNext()"
        *ngIf="currentStep < 2"
      >
        Next Step
        <i class="arrow alternate circle right outline icon"></i>
      </button>
      <button
        class="small facebook ui button"
        (click)="onUpload()"
        *ngIf="
          (currentStep === 2 && !currentBuildingInfo.isOpen) ||
          (currentStep === 2 && isSaveAs > 0)
        "
      >
        <i class="cloud upload icon"></i>
        <span>{{ isUploading ? "Uploading..." : "Save & Upload" }}</span>
      </button>
    </div>
    <div nz-col nzSpan="12" class="text-align-right">
      <span
        class="margin-right-5"
        *ngIf="
          (currentStep === 2 && !currentBuildingInfo.isOpen) ||
          (currentStep === 2 && isSaveAs > 0)
        "
        >More Information</span
      >
      <nz-switch
        [(ngModel)]="isVisibleMoreInfo"
        nzTooltipTitle="Show/Hide more information"
        nz-tooltip
        *ngIf="
          (currentStep === 2 && !currentBuildingInfo.isOpen) ||
          (currentStep === 2 && isSaveAs > 0)
        "
        nzCheckedChildren="Show"
        nzUnCheckedChildren="Hide"
        class="margin-right-5"
      ></nz-switch>
    </div>
  </div>
</nz-modal>

<!-- End -->
<div class="left-side-bar">
  <div class="ui styled fluid accordion accordion-bottom">
    <div class="title">
      <i class="dropdown icon"></i>
      Existing House Detail
    </div>
    <!-- <div class="content" [ngClass]="{'active': APP_CONFIG.activeLeftMenu.existingHouse}"> -->
    <div
      [ngClass]="
        currentBuildingInfo.isOpen || sltStructureType.currentValue == 4
          ? 'content div-disabled'
          : 'content'
      "
    >
      <table class="inputs">
        <tr>
          <td class="red">Existing House</td>
        </tr>
        <tr>
          <td>- Roof Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltExistingRoofType
              [items]="objects.roofTypeList"
              [selected]="0"
            ></dropdown-input>
          </td>
        </tr>
        <tr>
          <td>- Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltExistingType
              [items]="objects.existingTypeList"
              [selected]="0"
              (change)="
                uiManager.onExistingTypeChanged(
                  null,
                  +sltExistingType.currentValue
                )
              "
            ></dropdown-input>
          </td>
        </tr>
        <tr>
          <td>- Length (L1):</td>
          <td class="td-content">
            <slider-input
              #sldExistingLength
              [min]="env.existingLength.min"
              [max]="env.existingLength.max"
              step="10"
              [default]="env.existingLength.default"
            ></slider-input>
          </td>
        </tr>
        <tr
          [style.display]="
            sltExistingType.currentValue == 0 ||
            sltStructureType.currentValue == 4
              ? 'none'
              : ''
          "
        >
          <td>- Width (W1):</td>
          <td class="td-content">
            <slider-input
              #sldExistingWidth1
              [min]="env.existingWidth.min"
              [max]="env.existingWidth.max"
              step="10"
              [default]="env.existingWidth.default"
            ></slider-input>
          </td>
        </tr>
        <tr
          [style.display]="
            sltExistingType.currentValue != 0 &&
            sldExistingWidth1.currentValue > 0 &&
            sldExistingLength2Visible
              ? ''
              : 'none'
          "
        >
          <td>- Length (L2):</td>
          <td class="td-content">
            <slider-input
              #sldExistingLength2
              [min]="env.existingLength2.min"
              [max]="env.existingLength2.max"
              step="10"
              [default]="env.existingLength2.default"
            ></slider-input>
          </td>
        </tr>
        <tr
          [style.display]="
            sltExistingType.currentValue != 0 &&
            sldExistingLength2.currentValue > 0 &&
            sldExistingWidth2Visible
              ? ''
              : 'none'
          "
        >
          <td>- Width (W2):</td>
          <td class="td-content">
            <slider-input
              #sldExistingWidth2
              [min]="env.existingWidth2.min"
              [max]="env.existingWidth2.max"
              step="10"
              [default]="env.existingWidth2.default"
            ></slider-input>
          </td>
        </tr>
        <tr>
          <td>Pitch:</td>
          <td class="td-content">
            <dropdown-input
              #sltPitch
              [items]="objects.existingRoofPitchList"
              [selected]="env.existingRoofPitch.default"
            ></dropdown-input>
          </td>
        </tr>
        <tr>
          <td>Fascia depth:</td>
          <td class="td-content">
            <slider-input
              #sldFasciaDepth
              [min]="env.fasciaDepth.min"
              [max]="env.fasciaDepth.max"
              step="10"
              [default]="env.fasciaDepth.default"
            ></slider-input>
          </td>
        </tr>
        <tr>
          <td>Eave Height:</td>
          <td class="td-content">
            <slider-input
              #sldEaveHeight
              [min]="env.eaveHeight.min"
              [max]="env.eaveHeight.max"
              step="10"
              [default]="env.eaveHeight.default"
              (change)="
                uiManager.onExistingWallHeightChanged2(
                  sldEaveHeight.previousValue,
                  sldEaveHeight.currentValue
                )
              "
            >
            </slider-input>
          </td>
        </tr>
        <tr>
          <td>Eave Width:</td>
          <td class="td-content">
            <slider-input
              #sldEaveWidth
              [min]="env.eaveWidth.min"
              [max]="env.eaveWidth.max"
              step="10"
              [default]="env.eaveWidth.default"
            ></slider-input>
          </td>
        </tr>
        <tr>
          <td>Wall Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltWallType
              [items]="objects.wallList"
              [selected]="0"
            ></dropdown-input>
          </td>
        </tr>
        <tr>
          <td>Cutout:</td>
          <td class="td-content">
            <dropdown-input
              #sltCutOut
              [items]="objects.cutOutList"
              [selected]="0"
            ></dropdown-input>
          </td>
        </tr>
        <!-- <tr>
          <td>Superior Flyover Bracket:</td>
          <td class='td-content'>
            <slider-input #sldFlyOverBracketHeight [min]="env.flyOverBracketHeight.min"
              [max]="env.flyOverBracketHeight.max" step="10" [default]="env.flyOverBracketHeight.default" [extraValue]="250">
            </slider-input>
          </td>
        </tr> -->
      </table>
    </div>
    <div class="title active">
      <i class="dropdown icon"></i>
      Patios Information
    </div>
    <!-- <div class="content" [ngClass]="{'active': APP_CONFIG.activeLeftMenu.patiosInformation}"> -->
    <div
      class="content active"
      [ngClass]="{ 'div-disabled': currentBuildingInfo.isOpen }"
    >
      <table class="inputs">
        <tr>
          <td>Connection Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltStructureType
              [items]="
                patiosRoofType === 'flat'
                  ? objects.structureTypeList
                  : isFBRoof
                  ? objects.structureTypeListGableFB
                  : objects.structureTypeListGableLR
              "
              [selected]="0"
            ></dropdown-input>
          </td>
        </tr>
        <tr>
          <td>Superior Flyover Bracket:</td>
          <td class="td-content">
            <slider-input
              #sldFlyOverBracketHeight
              [min]="env.flyOverBracketHeight.min"
              [max]="env.flyOverBracketHeight.max"
              step="10"
              [default]="env.flyOverBracketHeight.default"
              [disabled]="
                sltStructureType.currentValue == 1 ||
                sltStructureType.currentValue == 2
                  ? false
                  : true
              "
            >
              <!-- [extraValue]="250" -->
            </slider-input>
          </td>
        </tr>
        <tr>
          <td>Fascia Upstand Brackets:</td>
          <td class="td-content">
            <dropdown-input
              #sltFasciaUpstandBrackets
              [items]="
                sltUpFasciaUpstandBracket.currentValue == true
                  ? upFasciaBracketList
                  : objects.fasciaBracketList
              "
              [selected]="0"
              [disabled]="sltStructureType.currentValue == 5 ? false : true"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr>
          <td>Up Fascia Upstandard Bracket:</td>
          <td class="td-content">
            <checkbox-input
              #sltUpFasciaUpstandBracket
              (onChange)="onsltUpFasciaUpstandBracketChange($event)"
              [disabled]="true"
            >
              <!-- [disabled]="sltStructureType.currentValue == 5 ? false : true" -->
            </checkbox-input>
          </td>
        </tr>
        <tr>
          <td>Wind Class:</td>
          <td class="td-content">
            <dropdown-input
              #sltWindClass
              [items]="
                patiosRoofType === 'gable'
                  ? objects.windClassListGable
                  : objects.windClassList
              "
              [selected]="0"
            ></dropdown-input>
          </td>
        </tr>
        <tr>
          <td class="label-top">Span (S):</td>
          <td class="td-content">
            <slider-input
              #sldSpan
              [min]="env.span.min"
              [max]="maxSpan"
              step="100"
              [default]="env.span.default"
            >
            </slider-input>
            <div class="ui pointing red basic label max-label">
              {{ maxSpanText }}
            </div>
          </td>
        </tr>
        <tr>
          <td>Multi Span (S2):</td>
          <td class="td-content">
            <slider-input
              #sldMultiSpan
              [min]="env.multiSpan.min"
              [max]="maxSpan"
              step="100"
              [default]="env.multiSpan.default"
            ></slider-input>
          </td>
        </tr>
        <tr>
          <td>Patios Height (H):</td>
          <td class="td-content">
            <slider-input
              #sldBuildingHeight
              [min]="env.height.min"
              [max]="env.height.max"
              step="100"
              [default]="env.height.default"
            ></slider-input>
          </td>
        </tr>
        <tr>
          <td>Front Overhang (O):</td>
          <td class="td-content">
            <slider-input
              #sldFrontOverhang
              [min]="env.frontOverhang.min"
              [max]="env.frontOverhang.max"
              step="1"
              [default]="env.frontOverhang.default"
            ></slider-input>
          </td>
        </tr>
        <tr>
          <td>Back Overhang (O2):</td>
          <td class="td-content">
            <slider-input
              #sldBackOverhang
              [min]="env.backOverhang.min"
              [max]="env.backOverhang.max"
              step="1"
              [default]="env.backOverhang.default"
            ></slider-input>
          </td>
        </tr>
        <tr>
          <td class="label-top">Bay Size - Nominal (B):</td>
          <td class="td-content">
            <slider-input
              #sldBaySize
              [min]="env.baySize.min"
              [max]="maxBaySize"
              step="100"
              [default]="env.baySize.default"
            ></slider-input>
            <div class="ui pointing red basic label max-label">
              {{ maxBaySizeText }}
            </div>
          </td>
        </tr>
        <tr>
          <td>No Of Bay:</td>
          <td class="td-content">
            <slider-input
              #sldNoOfBay
              [min]="env.noOfBay.min"
              [max]="env.noOfBay.max"
              step="1"
              [default]="env.noOfBay.default"
            ></slider-input>
          </td>
        </tr>
        <tr>
          <td>Roof Pitch:</td>
          <td class="td-content">
            <dropdown-input-custom
              #sltRoofPitch
              [items]="
                patiosRoofType == 'flat'
                  ? objects.roofPitchList
                  : objects.gableRoofPitchList
              "
              minCustom="5"
              maxCustom="patiosRoofType === 'flat' ? 15 : 30"
              [selected]="2"
              [hasCustom]="patiosRoofType === 'gable'"
            ></dropdown-input-custom>
          </td>
        </tr>
        <tr>
          <td>
            {{
              patiosRoofType === "flat"
                ? "Beam Left Overhang:"
                : "Left Overhang:"
            }}
          </td>
          <td class="td-content">
            <slider-input
              #sldLeftOverhang
              [min]="env.leftOverhang.min"
              [max]="env.leftOverhang.max"
              step="1"
              [default]="env.leftOverhang.default"
            ></slider-input>
          </td>
        </tr>
        <tr>
          <td>
            {{
              patiosRoofType === "flat"
                ? "Beam Right Overhang:"
                : "Right Overhang:"
            }}
          </td>
          <td class="td-content">
            <slider-input
              #sldRightOverhang
              [min]="env.rightOverhang.min"
              [max]="env.rightOverhang.max"
              step="1"
              [default]="env.rightOverhang.default"
            ></slider-input>
          </td>
        </tr>
        <tr>
          <td class="label-top">Roof Overall Length:</td>
          <td class="td-content text-align-right">
            <slider-input
              class="hide"
              #sldRoofOverallLength
              [min]="env.roofOverallLength.min"
              [max]="env.roofOverallLength.max"
              step="100"
              [default]="env.roofOverallLength.default"
              [disabled]="true"
            >
            </slider-input>
            <!-- <div class="label-info"> -->
            <span>{{ sldRoofOverallLength.currentValue }}</span
            ><br />
            <div class="ui pointing red basic label max-label">
              {{ roofPercent }}
            </div>
            <!-- </div> -->
          </td>
        </tr>
        <tr>
          <td>Roof Overall Width:</td>
          <td class="td-content text-align-right">
            <slider-input
              class="hide"
              #sldRoofOverallWidth
              [min]="env.roofOverallWidth.min"
              [max]="env.roofOverallWidth.max"
              step="100"
              [default]="env.roofOverallWidth.default"
              [disabled]="true"
            ></slider-input>
            <span class="label-info">{{
              sldRoofOverallWidth.currentValue
            }}</span>
          </td>
        </tr>
        <tr>
          <td>Min. Height Under Beam:</td>
          <td class="td-content text-align-right">
            <slider-input
              class="hide"
              #sldMinHeight
              [min]="env.minHeight.min"
              [max]="env.minHeight.max"
              step="100"
              [default]="env.minHeight.default"
              [disabled]="true"
            ></slider-input>
            <span class="label-info">{{ sldMinHeight.currentValue }}</span>
          </td>
        </tr>
        <tr>
          <td class="red">Rake Cut</td>
        </tr>
        <tr>
          <td>- Left Cut</td>
          <td class="td-content">
            <dropdown-input
              #sltLeftCutType
              [items]="objects.rakeCutType"
              [selected]="0"
              [disabled]="false"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr [style.display]="sltLeftCutType.currentValue != 0 ? '' : 'none'">
          <td class="td-level-2">+ Vertical</td>
          <td class="td-content">
            <slider-input
              #sldLeftCutVertical
              [min]="env.rakeCutVertical.min"
              [max]="env.rakeCutVertical.max"
              [default]="env.rakeCutVertical.default"
              [disabled]="false"
              step="100"
            ></slider-input>
          </td>
        </tr>
        <tr [style.display]="sltLeftCutType.currentValue != 0 ? '' : 'none'">
          <td class="td-level-2">+ Horizontal</td>
          <td class="td-content">
            <slider-input
              #sldLeftCutHorizontal
              [min]="env.rakeCutHorizontal.min"
              [max]="env.rakeCutHorizontal.max"
              [default]="env.rakeCutHorizontal.default"
              [disabled]="false"
              step="100"
            ></slider-input>
          </td>
        </tr>
        <tr>
          <td>- Right Cut</td>
          <td class="td-content">
            <dropdown-input
              #sltRightCutType
              [items]="objects.rakeCutType"
              [selected]="0"
              [disabled]="false"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr [style.display]="sltRightCutType.currentValue != 0 ? '' : 'none'">
          <td class="td-level-2">+ Vertical</td>
          <td class="td-content">
            <slider-input
              #sldRightCutVertical
              [min]="env.rakeCutVertical.min"
              [max]="env.rakeCutVertical.max"
              [default]="env.rakeCutVertical.default"
              [disabled]="false"
              step="100"
            ></slider-input>
          </td>
        </tr>
        <tr [style.display]="sltRightCutType.currentValue != 0 ? '' : 'none'">
          <td class="td-level-2">+ Horizontal</td>
          <td class="td-content">
            <slider-input
              #sldRightCutHorizontal
              [min]="env.rakeCutHorizontal.min"
              [max]="env.rakeCutHorizontal.max"
              [default]="env.rakeCutHorizontal.default"
              [disabled]="false"
              step="100"
            ></slider-input>
          </td>
        </tr>
      </table>
    </div>
    <div class="title">
      <i class="dropdown icon"></i>
      Material Detail
    </div>
    <div
      [ngClass]="
        currentBuildingInfo.isOpen ? 'content div-disabled' : 'content'
      "
    >
      <table class="inputs">
        <tr>
          <td>Roof Sheeting Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltRoofSheetingType
              [items]="objects.roofSheetingTypeList"
              [selected]="0"
              (change)="onRoofSheetingTypeChanged()"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr>
          <td class="label-top">Thickness:</td>
          <td class="td-content">
            <dropdown-input
              #sltRoofThickness
              [items]="thicknessList"
              [selected]="1"
            >
            </dropdown-input>
            <div class="ui pointing red basic label max-label">
              {{ maxThickness }}
            </div>
          </td>
        </tr>
        <tr>
          <td>Flat Bottom Colour:</td>
          <td class="td-content">
            <dropdown-input
              #sltFlatBottom
              [items]="objects.flatBottomList"
              [selected]="15000277"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr>
          <td>Barge Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltBargeType
              [items]="objects.roofSheetingTypeList"
              [selected]="0"
              [disabled]="true"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr>
          <td>Drip Barge:</td>
          <td class="td-content">
            <checkbox-input #sltDripBarge></checkbox-input>
          </td>
        </tr>

        <tr>
          <td>Receiver Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltReceiverType
              [items]="objects.roofSheetingTypeList"
              [selected]="0"
              [disabled]="true"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr>
          <td>Z Flashing Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltZFlashingType
              [items]="objects.roofSheetingTypeList"
              [selected]="0"
              [disabled]="true"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr>
          <td>Downpipe Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltDownpipeType
              [items]="objects.downpipeTypeList"
              [selected]="0"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr>
          <td>Gutter Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltGutterType
              [items]="objects.gutterTypeList"
              [selected]="0"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr>
          <td>Beam Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltBeamType
              [items]="objects.beamTypeList"
              [selected]="0"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr>
          <td class="label-top">Beam Size:</td>
          <td class="td-content">
            <dropdown-input #sltBeamSize [items]="beamSizeList" [selected]="1">
            </dropdown-input>
            <div class="ui pointing red basic label max-label">
              {{ maxBeamSizeText }}
            </div>
          </td>
        </tr>
        <tr>
          <td class="label-top">
            House Beam Size:
            <checkbox-input
              #chkHasHouseBeam
              [disabled]="
                sltStructureType.currentValue == 1 ||
                sltStructureType.currentValue == 2 ||
                sltStructureType.currentValue == 6 ||
                sltStructureType.currentValue == 5
                  ? false
                  : true
              "
            >
            </checkbox-input>
          </td>
          <td
            [ngClass]="
              chkHasHouseBeam.currentValue
                ? 'td-content'
                : 'td-content div-disabled'
            "
          >
            <dropdown-input
              #sltHouseBeamSize
              [items]="houseBeamSizeList"
              [selected]="1"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr>
          <td>Column Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltColumnType
              [items]="columnTypeList"
              [selected]="0"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr>
          <td>Base Fixing Type:</td>
          <td class="td-content">
            <dropdown-input
              #sltBaseFixingType
              [items]="baseFixingTypeList"
              [selected]="0"
            >
            </dropdown-input>
          </td>
        </tr>
        <tr>
          <td>
            <span>Rapid Set Cement:</span>
            <nz-tag [nzColor]="'orange'">(5 bags per post)</nz-tag>
          </td>
          <td class="td-content">
            <checkbox-input
              #chkRapidSetCement
              [disabled]="sltBaseFixingType.currentValue == 1 ? false : true"
            >
            </checkbox-input>
          </td>
        </tr>
      </table>
    </div>
    <div class="title">
      <i class="dropdown icon"></i>
      Color Selections
    </div>
    <div
      [ngClass]="
        currentBuildingInfo.isOpen ? 'content div-disabled' : 'content'
      "
    >
      <table class="inputs">
        <tr>
          <td>Roof:</td>
          <td class="td-content">
            <!--<dropdown-input #sltColourRoof [items]="objects.baseColourList" [selected]="15000277"></dropdown-input>-->
            <dropdown-color-input
              #sltColourRoof
              [items]="objects.baseColourList"
              [selected]="15000277"
            >
            </dropdown-color-input>
          </td>
        </tr>
        <tr>
          <td>Barge:</td>
          <td class="td-content">
            <dropdown-color-input
              #sltColourBarge
              [items]="objects.baseColourList"
              [selected]="15000277"
            >
            </dropdown-color-input>
          </td>
        </tr>
        <tr>
          <td>Gutter:</td>
          <td class="td-content">
            <dropdown-color-input
              #sltColourGutter
              [items]="objects.baseColourList"
              [selected]="15000277"
            >
            </dropdown-color-input>
          </td>
        </tr>
        <tr>
          <td>Z Flashing:</td>
          <td class="td-content">
            <dropdown-color-input
              #sltColourZFlashing
              [items]="objects.baseColourList"
              [selected]="15000277"
            >
            </dropdown-color-input>
          </td>
        </tr>
        <tr>
          <td>Post:</td>
          <td class="td-content">
            <dropdown-color-input
              #sltColourPost
              [items]="objects.postColourList"
              [selected]="15000277"
            >
            </dropdown-color-input>
          </td>
        </tr>
        <tr>
          <td>Beam:</td>
          <td class="td-content">
            <dropdown-color-input
              #sltColourBeam
              [items]="objects.beamColourList"
              [selected]="15000277"
            >
            </dropdown-color-input>
          </td>
        </tr>
        <tr>
          <td>Flyover Bracket:</td>
          <td class="td-content">
            <dropdown-color-input
              #sltColourBracket
              [items]="objects.flyoverColourList"
              [selected]="15000277"
            >
            </dropdown-color-input>
          </td>
        </tr>
        <tr>
          <td>Fascia Upstand Bracket:</td>
          <td class="td-content">
            <dropdown-color-input
              #sltColourFasciaBracket
              [items]="objects.fasciaColourList"
              [selected]="15000277"
            >
            </dropdown-color-input>
          </td>
        </tr>
        <tr>
          <td>Downpipe:</td>
          <td class="td-content">
            <dropdown-color-input
              #sltColourDownpipe
              [items]="objects.downpipeColourList"
              [selected]="0"
            >
            </dropdown-color-input>
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="td-content">
            <button
              class="ui facebook tiny button"
              style="width: 100%"
              (click)="onApplyToAll()"
            >
              <i class="tasks icon"></i> <span> Apply To All</span>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <!-- <app-menu></app-menu> -->
</div>
<div
  [ngClass]="
    isVisibleProperty
      ? 'side-bar-btn side-bar-open'
      : 'side-bar-btn side-bar-close'
  "
  (click)="onOpenPropertyClick()"
>
  <span [ngClass]="isVisibleProperty ? 'span-bg close' : 'span-bg open'"></span>
</div>
<div
  #viewerContainer
  [ngClass]="
    isVisibleProperty
      ? 'viewer-container viewer-open'
      : 'viewer-container viewer-close'
  "
>
  <div id="wrapper-gui">
    <div id="floating-gui">
      <div class="ui card">
        <div class="content">
          <!-- <i class="right floated compress icon"></i> -->
          <div class="header">Options</div>
          <!-- <div class="description">
            <p></p>
          </div> -->
        </div>
        <div class="extra content">
          <table class="inputs">
            <tr
              [style.display]="
                currentUser.user.roles === objects.roles.admin ? '' : 'none'
              "
            >
              <td class="td-label">- Panel Direction</td>
              <td
                [ngClass]="
                  currentBuildingInfo.isOpen
                    ? 'div-disabled td-content'
                    : 'td-content'
                "
              >
                <switch-input #sltPanelDirection></switch-input>
              </td>
            </tr>
            <tr>
              <td class="td-label">- Panel Layout</td>
              <td class="td-content">
                <checkbox-input #sltPanelDirectionShow></checkbox-input>
              </td>
            </tr>
            <tr>
              <td class="td-label">- Beam Layout</td>
              <td class="td-content">
                <checkbox-input #sltBeamLayoutShow></checkbox-input>
              </td>
            </tr>
            <tr>
              <td class="td-label">- Edit Bay</td>
              <td class="td-content">
                <button class="ui facebook tiny button" (click)="showEditBay()">
                  <i class="edit outline icon"></i> <span> Edit</span>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div id="pricePanel">
    <h4 nz-title>
      Total Price:
      <span nz-text nzType="danger">{{
        currentBuildingInfo.quoteInfo.totalPrice | currency
      }}</span>
    </h4>
  </div>
  <div class="buttonPanel">
    <toggle-button
      #btnTurnOnPanel
      [activeContent]="'Hide roof'"
      [inactiveContent]="'Show roof'"
      [defaultShow]="true"
      [default]="true"
    ></toggle-button>
    <toggle-button
      #btnTurnOffRakeCutLeft
      [activeContent]="'Turn off rakecut left'"
      [inactiveContent]="'Turn off rakecut left'"
      [defaultShow]="sltLeftCutType.currentValue != 0"
      [default]="true"
      (onClick)="onBtnRakeCutLeftClick($event)"
    ></toggle-button>
    <toggle-button
      #btnTurnOffRakeCutRight
      [activeContent]="'Turn off rakecut right'"
      [inactiveContent]="'Turn off rakecut right'"
      [defaultShow]="sltRightCutType.currentValue != 0"
      [default]="true"
      (onClick)="onBtnRakeCutRightClick($event)"
    ></toggle-button>
    <toggle-button
      #btnTurnOffCutout
      [activeContent]="'Turn off cutout'"
      [inactiveContent]="'Turn off cutout'"
      [defaultShow]="sltCutOut.currentValue != 0"
      [default]="true"
      (onClick)="onBtnCutoutClick($event)"
    ></toggle-button>
    <toggle-button
      #btnEnableControl
      [activeContent]="'Block control'"
      [inactiveContent]="'Enable control'"
      [defaultShow]="true"
      [default]="true"
    ></toggle-button>
  </div>
  <canvas #rendererCanvas id="renderCanvas"></canvas>
  <div id="versionPanel">
    <h5 class="version-color" nz-title>
      SPC Software:
      <span
        nz-typography
        nzTooltipTitle="{{ releaseDate }}"
        nzTooltipPlacement="top"
        nz-tooltip
        ><kbd>{{ version }}</kbd></span
      >
    </h5>
  </div>
</div>

<dialog-edit-bay
  #dialogEditBay
  [noOfBay]="sldNoOfBay.currentValue"
  [baySize]="sldBaySize.currentValue"
>
</dialog-edit-bay>
<dialog-rakecut #dialogRakeCut></dialog-rakecut>
<dialog-dimension #dialogDimension></dialog-dimension>
<dialog-confirm #dialogComfirm></dialog-confirm>
<dialog-add-cost #dialogAddCost></dialog-add-cost>
<dialog-bom #dialogBOM></dialog-bom>

<div class="container">
  <router-outlet></router-outlet>
</div>
