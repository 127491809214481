import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BUILDING_SIDE } from 'src/app/app.constants';
import { HomeComponent } from 'src/app/containers/home';
import { HomeComponent as HomeComponentRibSpan } from 'src/app-ribspan/containers/home';
import { UI } from 'src/app/core/ui';
import { UI as UIRibSpan } from 'src/app-ribspan/core/ui';
import { DropDownInputComponent } from '../dropdown-input/dropdown-input.component';
import { Router } from '@angular/router';



@Component({
  selector: 'dialog-rakecut',
  templateUrl: './dialog-rakecut.component.html',
  styleUrls: ['./dialog-rakecut.component.scss']
})
export class DialogRakeCutComponent implements OnInit {
  @Output('onSubmit') onSubmitEvent = new EventEmitter();
  @Output('onCancel') onCancelEvent = new EventEmitter();
  @ViewChild('inputRakecutType') inputRakecutType = new DropDownInputComponent();

    selectedValue = 0
    rakecutTypes = null
    visible: boolean = false;
    side: number

    constructor(private router: Router){
        if(router.url == '/ribspan'){
            this.rakecutTypes = HomeComponentRibSpan.ins.objects.rakeCutType
        } else {
            this.rakecutTypes = HomeComponent.ins.objects.rakeCutType
        }
    }

    ngOnInit(): void {
        
    }
    ngAfterViewInit(){
        this.inputRakecutType.addAction((pre, cur) => {
            this.selectedValue = cur
        })
    }
    onCancel(){
        this.onCancelEvent.emit();
        this.show(false);
    }
    onOK(){
        if(this.router.url == '/ribspan'){
            if(this.side == BUILDING_SIDE.LEFT){
                HomeComponentRibSpan.ins.sltLeftCutType.setSelected(this.selectedValue)
            }
            if(this.side == BUILDING_SIDE.RIGHT){
                HomeComponentRibSpan.ins.sltRightCutType.setSelected(this.selectedValue)
            }
        } else {
            if(this.side == BUILDING_SIDE.LEFT){
                HomeComponent.ins.sltLeftCutType.setSelected(this.selectedValue)
            }
            if(this.side == BUILDING_SIDE.RIGHT){
                HomeComponent.ins.sltRightCutType.setSelected(this.selectedValue)
            }
        }

        this.onSubmitEvent.emit();
        this.show(false);
    }
    public show(_visible: boolean, side?: number){
        this.visible = _visible;
        if(this.router.url == '/ribspan'){
            if(side === BUILDING_SIDE.LEFT){
                this.inputRakecutType.setSelected(UIRibSpan.rakeCutLeftType)
            }
            if(side === BUILDING_SIDE.RIGHT){
                this.inputRakecutType.setSelected(UIRibSpan.rakeCutRightType)
            }
        } else {
            if(side === BUILDING_SIDE.LEFT){
                this.inputRakecutType.setSelected(UI.rakeCutLeftType)
            }
            if(side === BUILDING_SIDE.RIGHT){
                this.inputRakecutType.setSelected(UI.rakeCutRightType)
            }
        }
        
        this.side = side
    }
}
