import { Scene, Mesh, Object3D, Material, Group, FontLoader, TextBufferGeometry, PlaneBufferGeometry, Vector3, BoxHelper, Box3Helper, Matrix4, Box3, Geometry, LineSegments, LineBasicMaterial, Color, BoxBufferGeometry, ArrowHelper, BufferGeometry, Line, Line3 } from "three";
import { HomeComponent as AppComponent, HomeComponent } from '../../containers/home/home.component';
import { Util } from '../utils';
import { MaterialManager } from '../material.manager';
import { GeometryInfo, ViewType, LineType, IBay, Printing2DGeometry, Printing2DLine, Print2DText, Printing2DGeometryType } from '../models';
import { GEOMETRY_TYPE, DIMENSION_LOCATION, DIMENSION_LABEL_TYPE } from 'src/app/app.config';
import { environment } from 'src/environments/environment';
import { PatiosGableLRFlyOverManager } from '.';
import { GeometryManager } from '../geometry.manager';
import { BUILDING_SIDE, FIT_FLYOVER_BRAKET_ON_ROOF, PANEL_DIRECTION, RAKECUT_TYPE } from 'src/app/app.constants';
import Dimension, { AddDimensionOptions } from "src/app/models/Dimension";
import {CONFIG as conf} from '../../app.config'
import { UI } from "../ui";

export class DimensionManager {
    private scene: Scene;
    private APP: AppComponent;
    private MANAGER: PatiosGableLRFlyOverManager;
    public listText: THREE.Object3D[] = [];
    private utils: Util;


    private eventHandleId: any;

    private material: Material;

    private materialManager: MaterialManager;

    private dimensionPatiosFrontBottom: Object3D;
    private dimensionPatiosFrontRight: Object3D;
    
    private dimensionPatiosRightBottom: Object3D;
    private dimensionPatiosRightLeft: Object3D;
    private dimensionPatiosRightRight: Object3D;

    private dimensionPatiosLeftBottom: Object3D;
    private dimensionPatiosLeftRight: Object3D;
    private dimensionPatiosLeftLeft: Object3D;

    private dimensionPatiosPlanRight: Object3D;
    private dimensionPatiosPlanBottom: Object3D;
    private dimensionPatiosPlanLeft: Object3D;
    private dimensionPlanMiddle: Object3D;
    private dimensionPlanMidleLeft: Object3D;
    private dimensionPatiosPlanLeftOverHang: Object3D;

    private lineCapGeo: GeometryInfo;
    private lineGeo: GeometryInfo;
    private textGeo: TextBufferGeometry;

    private dimensionDistance = 1500;
    private dimensionDistance2 = 500;
    private dimensionDistance3 = 300;

    private textDistance = 5000;

    private totalBaySize: number;
    private box: Box3;

    private font: any
    private textParameter: any;
    private isReady = false;

    private controlsToRegisterEvent: Array<any>;
    private geometryManager: GeometryManager;

    private options : AddDimensionOptions;
    private startPos: Vector3;
    private startMatrix: Matrix4;
    private startBayLength: number;

    constructor(app: AppComponent, flyOverManager: PatiosGableLRFlyOverManager) {
        this.utils = new Util();
        this.materialManager = MaterialManager.Instance();
        this.APP = app;
        this.MANAGER = flyOverManager;
        this.scene = this.APP.scene;
        this.material = MaterialManager.Instance().DIMENSION_TEXT;
        this.geometryManager = GeometryManager.Instance();
        //this.registerEvent();
    }

    
    public optimize() : Promise<void>{
        return new Promise((resolve, reject) => {
            this.lineGeo = this.geometryManager.getDimensionLine();
            this.lineCapGeo = this.geometryManager.getDimensionCap();

            resolve();
        });
    }
    public load(): Promise<void> {    
        return new Promise((resolve, reject) => {
            this.unload();
            
            var loader = new FontLoader();
            
            this.dimensionPatiosFrontBottom = new Group();
            this.dimensionPatiosFrontBottom.userData = { type: GEOMETRY_TYPE.DIMENSION };
            // this.dimensionPatiosFrontBottom.rotation.set(0,Math.PI,0);
            this.dimensionPatiosFrontBottom.visible = false;

            this.dimensionPatiosFrontRight = new Group();
            this.dimensionPatiosFrontRight.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosFrontRight.rotation.set(0,0,-Math.PI/2);
            this.dimensionPatiosFrontRight.visible = false;
            
            this.dimensionPatiosRightBottom = new Group();
            this.dimensionPatiosRightBottom.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosRightBottom.rotation.set(0,-Math.PI/2,0);
            this.dimensionPatiosRightBottom.visible = false;

            this.dimensionPatiosRightLeft = new Group();
            this.dimensionPatiosRightLeft.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosRightLeft.rotation.set(0,-Math.PI/2,-Math.PI/2);
            this.dimensionPatiosRightLeft.visible = false;

            this.dimensionPatiosRightRight = new Group();
            this.dimensionPatiosRightRight.rotation.set(0,-Math.PI/2,-Math.PI/2);
            this.dimensionPatiosRightRight.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosRightRight.visible = false;
            

            this.dimensionPatiosLeftBottom = new Group();
            this.dimensionPatiosLeftBottom.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosLeftBottom.rotation.set(0,-Math.PI/2,0);
            this.dimensionPatiosLeftBottom.visible = false;

            this.dimensionPatiosLeftRight = new Group();
            this.dimensionPatiosLeftRight.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosLeftRight.rotation.set(Math.PI/2,-Math.PI/2,0);
            this.dimensionPatiosLeftRight.visible = false;

            this.dimensionPatiosLeftLeft = new Group();
            this.dimensionPatiosLeftLeft.rotation.set(0, -Math.PI / 2, Math.PI / 2);
            this.dimensionPatiosLeftLeft.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosLeftLeft.visible = false;
            


            this.dimensionPatiosPlanRight = new Group();
            this.dimensionPatiosPlanRight.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosPlanRight.rotation.set(-Math.PI/2, Math.PI, -Math.PI/2);

            this.dimensionPatiosPlanBottom = new Group();
            this.dimensionPatiosPlanBottom.userData = { type: GEOMETRY_TYPE.DIMENSION };
            this.dimensionPatiosPlanBottom.rotation.set(-Math.PI / 2, 0, 0);

            this.dimensionPatiosPlanLeft = new Group();
            this.dimensionPatiosPlanLeft.userData = { type: GEOMETRY_TYPE.DIMENSION , position: { left: true } };
            this.dimensionPatiosPlanLeft.rotation.set(-Math.PI / 2, 0, -Math.PI / 2);

            this.dimensionPlanMiddle = new Group();
            this.dimensionPlanMiddle.userData = { type: GEOMETRY_TYPE.DIMENSION };
            //this.dimensionPlanMiddle.rotation.set(-Math.PI / 2, 0, 0);
            this.dimensionPlanMidleLeft = new Group();

            this.dimensionPatiosPlanLeftOverHang = new Group();

            this.scene.add(this.dimensionPatiosPlanRight);
            this.scene.add(this.dimensionPatiosPlanLeft);
            this.scene.add(this.dimensionPatiosFrontRight);
            this.APP.patiosManager.patiosGroup.add(
                this.dimensionPatiosFrontBottom, 
                //this.dimensionPatiosFrontRight, 
                this.dimensionPatiosRightBottom,
                this.dimensionPatiosRightLeft,
                this.dimensionPatiosRightRight,
                this.dimensionPatiosLeftBottom, 
                this.dimensionPatiosLeftRight,                 
                this.dimensionPatiosPlanBottom,
                this.dimensionPlanMiddle,
                this.dimensionPatiosPlanLeftOverHang,
                this.dimensionPatiosLeftLeft,
                this.dimensionPlanMidleLeft
          );



            loader.load( environment.modelBaseUrl + '/assets/fonts/helvetiker_regular.typeface.json', (font) => {
                this.font  = font;

                this.textParameter = {
                    font: this.font,
                    size: 200,
                    height: 5,
                    curveSegments: 10
                };
                this.options = {
                    container: HomeComponent.ins.scene,
                    start: new Vector3(),
                    end: new Vector3(),
                    linePos: new Vector3(),
                    views: [],
                    allowDrag: true,
                    textParameter: this.textParameter,
                    textPos: new Vector3(),
                    labelType: null,
                    fitZ: 10,
                    hasTextClone: false,
                    axis: 'x',
                    lineLength: 0,
                    subtype: null,
                    textUserData: null,
                    onDragCallback: this.onDimDrag.bind(this),
                    onDragStartCallback: this.onDimDragStart.bind(this),
                }

                this.isReady = true;
                this.draw();
                
                resolve();
            })
        });
    }

    public unload(){
        this.scene.remove(this.dimensionPatiosPlanRight);
        this.scene.remove(this.dimensionPatiosPlanLeft);
        this.scene.remove(this.dimensionPatiosFrontRight);
        if(this.APP.patiosManager && this.APP.patiosManager.patiosGroup){
            this.APP.patiosManager.patiosGroup.remove(
                this.dimensionPatiosFrontBottom, 
                //this.dimensionPatiosFrontRight, 
                this.dimensionPatiosRightBottom,
                this.dimensionPatiosRightLeft,
                this.dimensionPatiosRightRight,
                this.dimensionPatiosLeftBottom, 
                this.dimensionPatiosLeftRight,
                this.dimensionPatiosLeftLeft,
                this.dimensionPatiosPlanBottom,
                this.dimensionPlanMiddle,
                this.dimensionPlanMidleLeft,
                this.dimensionPatiosPlanLeftOverHang
            );
        }
    }
    
    public draw() {
        if (!this.isReady) {
            return;
        }
        // this.dimensionPatiosPlanBottom.children = [];
        // this.dimensionPatiosPlanRight.children = [];
        // this.dimensionPatiosPlanLeft.children = [];
        this.dimensionPatiosPlanBottom.children = this.dimensionPatiosPlanBottom.children.filter( el => 
            el.userData?.groupType == DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_HORIZONTAL
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_HORIZONTAL
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.LEFT_OVERHANG
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.RIGHT_OVERHANG
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.BAY
            );
        this.dimensionPatiosPlanRight.children = this.dimensionPatiosPlanRight.children.filter( el => 
            el.userData?.groupType == DIMENSION_LABEL_TYPE.SPAN
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.MULTI_SPAN
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_VERTICAL
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.FRONT_OVERHANG
            || el.userData?.groupType == DIMENSION_LABEL_TYPE.BACK_OVERHANG
        );
        this.dimensionPatiosPlanLeft.children = this.dimensionPatiosPlanLeft.children.filter( el => 
            el.userData?.groupType == DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_VERTICAL 
        )
        this.dimensionPatiosFrontBottom.children = [];
        this.dimensionPatiosFrontRight.children = [];
        this.dimensionPatiosLeftBottom.children = [];
        this.dimensionPatiosLeftRight.children = [];
        this.dimensionPatiosRightBottom.children = [];
        this.dimensionPatiosRightLeft.children = [];
        this.dimensionPatiosRightRight.children = [];
        this.dimensionPlanMiddle.children = [];
        this.dimensionPatiosPlanLeftOverHang.children = [];
        this.dimensionPatiosLeftLeft.children = [];
        this.dimensionPlanMidleLeft.children = [];
        
        this.totalBaySize = 0;
        for(let b of this.APP.dialogEditBay.listBay){
            this.totalBaySize += b.value;
        }

        this.box = this.utils.getSceneBox();

        this.drawDimensionPatiosPlanBottom();
        this.drawDimensionPatiosPlanRight();
        this.drawDimensionPatiosPlanLeft();

        this.drawDimensionPatiosFrontBottom();
        this.drawDimensionPatiosFrontRight();

        this.drawDimensionPatiosLeftBottom();
        this.drawDimensionPatiosLeftRight();
        this.drawDimensionPatiosLeftLeft();

        this.drawDimensionPatiosRightBottom();
        this.drawDimensionPatiosRightLeft();
        this.drawDimensionPatiosRightRight();

        this.drawDimensionMidle();
        this.drawDimensionMidleLeft();

        this.APP.dimensionManager.draw();
    }
    private totalHeightFromEaveHeightToTopOfExistingRoof(){
        const height = 
          this.geometryManager.EAVE.EAVE.height 
          + UI.fasciaDepth 
          + UI.eaveWidth * this.utils.tan(UI.existingRoofPitch) 
          + this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.height / this.utils.cos(UI.existingRoofPitch)
    
        return height
    }
    private totalHeightFromEaveHeightToTopOfFlyoverBraket(){
        const height = this.totalHeightFromEaveHeightToTopOfExistingRoof() + UI.braketHeight - FIT_FLYOVER_BRAKET_ON_ROOF;
    
        return height
    }
    private drawDimensionMidleLeft() {
        let dimLeft = this.utils.createArrowLine([{ viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }]);
        dimLeft.visible = false;
        dimLeft.position.setX(-300)
        let textLeft = this.utils.createText("Panel direction", [{ viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }], this.textParameter, new Vector3(-500, 0, 650));
        textLeft.visible = false;

        let dimRight = dimLeft.clone()
        dimRight.position.setX(300);
        let textRight = textLeft.clone();
        textRight.position.setX(500);
        
        this.dimensionPlanMidleLeft.add(dimLeft);
        this.dimensionPlanMidleLeft.add(textLeft);
        this.dimensionPlanMidleLeft.add(dimRight);
        this.dimensionPlanMidleLeft.add(textRight);

        let offsetZ =UI.span / 2 - UI.existingWidth1 / 2 - this.MANAGER.patiosGroup.position.z - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
        if (this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.RIGHT_TO_LEFT) {
            let offsetX = this.totalBaySize / 2 + UI.overhangRight;
            if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT){
                offsetX += -this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width - 100 + ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
            }
    
            if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT){
                offsetX += this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + 100 - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
            }

            this.dimensionPlanMidleLeft.position.set(offsetX, 0, offsetZ);
            this.dimensionPlanMidleLeft.rotation.set(0, -Math.PI / 2, 0);
        }
        else {
            let offsetX = - this.totalBaySize / 2 - UI.overhangLeft;
            if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT){
                offsetX += -this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width - 100 + ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
            }
    
            if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT){
                offsetX += this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + 100 - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
            }
            
            this.dimensionPlanMidleLeft.rotation.set(0, Math.PI / 2, 0);
            this.dimensionPlanMidleLeft.position.set(offsetX, 0, offsetZ);
        }
    }
  private drawDimensionMidle() {
    let offsetX = 0;
    if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT){
        offsetX = -this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width - 100 + ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
    }

    if(this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT){
        offsetX = this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + 100 - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0);
    }

    let dimLeft = this.utils.createArrowLine([{ viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }]);
    dimLeft.rotation.set(0, -Math.PI / 2, 0);
    dimLeft.visible = false;

    let textLeft = this.utils.createText(`${UI.patiosPitch} degree`, [{ viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }], this.textParameter, new Vector3(-500, 0, 200));
    textLeft.userData.rotation = new Vector3(0, Math.PI / 2, 0)
    textLeft.visible = false;

    let dimRight = dimLeft.clone()
    dimRight.rotation.set(0, Math.PI / 2, 0);

    let textRight = textLeft.clone();
    textRight.position.setX(500);
    
    this.dimensionPlanMiddle.add(dimLeft);
    this.dimensionPlanMiddle.add(textLeft);
    this.dimensionPlanMiddle.add(dimRight);
    this.dimensionPlanMiddle.add(textRight);

    let offsetZ = - UI.existingWidth1 / 2 - this.MANAGER.patiosGroup.position.z - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0) + UI.span / 2;
    this.dimensionPlanMiddle.rotation.set(0, Math.PI / 2, 0);
    this.dimensionPlanMiddle.position.set(offsetX, 0, offsetZ);
  }
    private drawDimensionPatiosPlanBottom(){
        let offsetX = -this.totalBaySize / 2;
        //let offsetZ = this.box.max.z + this.dimensionDistance2;
        //let offsetZ = this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + this.APP.sldFrontOverhang.currentValue + this.dimensionDistance3 - this.APP.sldExistingWidth1.currentValue/2;
        //if (this.APP.sldExistingWidth1.currentValue > this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) {
        //    offsetZ = this.APP.sldExistingWidth1.currentValue / 2 + this.dimensionDistance2
        //    if(this.APP.sldExistingLength2.currentValue > 0){
        //        offsetZ += 500;
        //    }
        //}

        let views = [ { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS } ];

        //this.dimensionPatiosPlanBottom.position.setZ(offsetZ);
        this.dimensionPatiosPlanBottom.children = this.dimensionPatiosPlanBottom.children.filter(el => 
            el.userData?.groupType != DIMENSION_LABEL_TYPE.BAY
            || (el.userData?.groupType == DIMENSION_LABEL_TYPE.BAY && el.userData?.bayNum < HomeComponent.ins.dialogEditBay.listBay.length)
        );
        
        for(let i = 0; i <= this.APP.dialogEditBay.listBay.length; i++){
            let b: IBay = {index: 0, value: 0, isCut: false};
            if(i < this.APP.dialogEditBay.listBay.length){
                b = this.APP.dialogEditBay.listBay[i];
            }

            // let cap2 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
            // cap2.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
            // cap2.position.setX(offsetX);
            // this.dimensionPatiosPlanBottom.add(cap2);

            if(i < this.APP.dialogEditBay.listBay.length){
                let textStr = this.utils.getDimensionValue(b.value);
                let options : AddDimensionOptions = {... this.options,
                    lineLength: b.value,
                    container: this.dimensionPatiosPlanBottom,
                    views: views,
                    linePos: new Vector3(offsetX + b.value / 2, 0, 0),
                    textPos: new Vector3(offsetX + b.value / 2, -200, 0),
                    labelType: DIMENSION_LABEL_TYPE.BAY,
                    bayNum: i,
                    textUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, position: { bottom: true } }
                }
                let dimBay = this.dimensionPatiosPlanBottom.children.find(el => 
                    el.userData?.groupType == DIMENSION_LABEL_TYPE.BAY 
                    && el.userData?.bayNum == i
                ) as Dimension;
                if(!dimBay){
                    new Dimension(options);
                } else {
                    dimBay.update(options);
                }
            }
            offsetX += b.value;
        }

        //rakecut
        if(this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE){
            let _offsetX = -this.APP.dialogEditBay.totalBaySize/2 + this.APP.sldLeftCutHorizontal.currentValue/2 - this.APP.sldLeftOverhang.currentValue;
            let options : AddDimensionOptions = {... this.options,
                lineLength: HomeComponent.ins.sldLeftCutHorizontal.currentValue,
                container: this.dimensionPatiosPlanBottom,
                views: views,
                linePos: new Vector3(_offsetX, 500, 0),
                textPos: new Vector3(_offsetX, 300, 0),
                labelType: DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_HORIZONTAL,
                subtype: GEOMETRY_TYPE.DIMENSION_LINE_RAKECUT
            }
            let dimRake = this.dimensionPatiosPlanBottom.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_HORIZONTAL) as Dimension;
            if(!dimRake){
                new Dimension(options);
            } else {
                dimRake.update(options);
            }
        } else {
            this.dimensionPatiosPlanBottom.children = this.dimensionPatiosPlanBottom.children.filter(el => el.userData?.groupType != DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_HORIZONTAL)
        }
        if(this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE){
            let _offsetX = this.APP.dialogEditBay.totalBaySize/2 - this.APP.sldRightCutHorizontal.currentValue/2 + this.APP.sldRightOverhang.currentValue;
            let options : AddDimensionOptions = {... this.options,
                lineLength: HomeComponent.ins.sldRightCutHorizontal.currentValue,
                container: this.dimensionPatiosPlanBottom,
                views: views,
                linePos: new Vector3(_offsetX, 500, 0),
                textPos: new Vector3(_offsetX, 500-200, 0),
                labelType: DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_HORIZONTAL,
                subtype: GEOMETRY_TYPE.DIMENSION_LINE_RAKECUT
            }
            let dimRake = this.dimensionPatiosPlanBottom.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_HORIZONTAL) as Dimension;
            if(!dimRake){
                new Dimension(options);
            } else {
                dimRake.update(options);
            }
        } else {
            this.dimensionPatiosPlanBottom.children = this.dimensionPatiosPlanBottom.children.filter(el => el.userData?.groupType != DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_HORIZONTAL)
        }
        //Left over hang
        this.addOverhang(this.dimensionPatiosPlanBottom, offsetX, this.APP.sldLeftOverhang.currentValue, views, "Left", 0, "z", DIMENSION_LABEL_TYPE.LEFT_OVERHANG);
        this.addOverhang(this.dimensionPatiosPlanBottom, offsetX, this.APP.sldRightOverhang.currentValue, views, "Right", 0, "z", DIMENSION_LABEL_TYPE.RIGHT_OVERHANG);
        this.repositionDimension(this.dimensionPatiosPlanBottom, DIMENSION_LOCATION.PLAN_BOTTOM);
    }
    onDimDrag(event){
        this.utils.handlerDimDragEvent(event, this.startPos, this.startMatrix, this.startBayLength);
        this.startPos = event.object.position.clone();
    }
    onDimDragStart(event){
        this.startPos = event.object.position.clone();
        this.startMatrix = event.object.matrixWorld.clone();
        if(event.object.userData?.dimType === DIMENSION_LABEL_TYPE.BAY){
            let bayNum = event.object.userData.bayNum;
            this.startBayLength = UI.listBay[+bayNum].value;
        }
    }
    private drawDimensionPatiosPlanRight(){
        
        let views = [ { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS } ];
        let offsetDimensionZ = this.APP.sldSpan.currentValue/2 
        - this.APP.sldExistingWidth1.currentValue/2 
        - this.APP.existingWallManager.geo_existingWallL1.width 
        - this.APP.eaveManager.backOverhang;

        let _dimensionDistane = this.dimensionDistance2;
        if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
            if(this.APP.sldExistingWidth1.currentValue > 0){
                _dimensionDistane = this.dimensionDistance;
            }
        }

        let dimLength = this.APP.sldSpan.currentValue;

        let textStr = this.utils.getDimensionValue(this.APP.sldSpan.currentValue);

        let options : AddDimensionOptions = {... this.options,
            lineLength: HomeComponent.ins.sldSpan.currentValue,
            container: this.dimensionPatiosPlanRight,
            views: views,
            linePos: new Vector3(0, 0, 0),
            textPos: new Vector3(0, -200, 0),
            labelType: DIMENSION_LABEL_TYPE.SPAN,
            hasTextClone: false,
            textUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0, -Math.PI / 2, 0) },
            textCloneUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0, -Math.PI / 2, 0) },
            textCloneRotation: new Vector3(0, Math.PI, -Math.PI),
            textCloneVisible: false,
            textRotation: new Vector3(0, -Math.PI, 0),
            fitZ: -10
        }
        let dimSpan = this.dimensionPatiosPlanRight.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.SPAN) as Dimension;
        if(!dimSpan){
            new Dimension(options);
        } else {
            dimSpan.update(options);
        }

        if(this.APP.sldMultiSpan.currentValue > 0){
            // let offsetX = this.APP.sldSpan.currentValue/2 + this.APP.sldMultiSpan.currentValue;
            // - this.APP.sldExistingWidth1.currentValue/2 
            // - this.APP.existingWallManager.geo_existingWallL1.width 
            // - this.APP.eaveManager.backOverhang;
            dimLength += this.APP.sldMultiSpan.currentValue;

            let textStr = this.utils.getDimensionValue(this.APP.sldMultiSpan.currentValue);

            let optionsMul : AddDimensionOptions = {... this.options,
                lineLength: HomeComponent.ins.sldMultiSpan.currentValue,
                container: this.dimensionPatiosPlanRight,
                views: views,
                linePos: new Vector3(this.APP.sldSpan.currentValue / 2 + this.APP.sldMultiSpan.currentValue / 2, 0, 0),
                textPos: new Vector3(this.APP.sldSpan.currentValue / 2 + this.APP.sldMultiSpan.currentValue / 2, -200, 0),
                labelType: DIMENSION_LABEL_TYPE.MULTI_SPAN,
                hasTextClone: false,
                textUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0, -Math.PI / 2, 0) },
                textCloneUserData: { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0, -Math.PI / 2, 0) },
                textCloneRotation: new Vector3(0, Math.PI, -Math.PI),
                textCloneVisible: false,
                textRotation: new Vector3(0, -Math.PI, 0),
                fitZ: -10
            }
            let dimMul = this.dimensionPatiosPlanRight.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.MULTI_SPAN) as Dimension;
            if(!dimMul){
                new Dimension(optionsMul);
            } else {
                dimMul.update(optionsMul);
            }
        } else {
            this.dimensionPatiosPlanRight.children = this.dimensionPatiosPlanRight.children.filter(el => el.userData?.groupType != DIMENSION_LABEL_TYPE.MULTI_SPAN)
        }

        let extraDistance = 0;
        //----Rake cut
        if(this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE){
            extraDistance = 500;
            let views = [{ viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }];
        
            let offsetZ = dimLength/2 + UI.multiSpan / 2 + this.APP.sldFrontOverhang.currentValue - this.APP.sldRightCutVertical.currentValue/2;
            
            let optionsRake : AddDimensionOptions = {... this.options,
                lineLength: HomeComponent.ins.sldRightCutVertical.currentValue,
                container: this.dimensionPatiosPlanRight,
                views: views,
                linePos: new Vector3(offsetZ, 500, 0),
                textPos: new Vector3(offsetZ, 300, 0),
                labelType: DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_VERTICAL,
                textRotation: new Vector3( 0, Math.PI, 0),
                fitZ: -10,
                subtype: GEOMETRY_TYPE.DIMENSION_LINE_RAKECUT
            }
            let dimRake = this.dimensionPatiosPlanRight.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_VERTICAL) as Dimension;
            if(!dimRake){
                new Dimension(optionsRake);
            } else {
                dimRake.update(optionsRake);
            }
        } else {
            this.dimensionPatiosPlanRight.children = this.dimensionPatiosPlanRight.children.filter(el => el.userData?.groupType != DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_VERTICAL)
        }
      //----End rake cut
      
      let offsetRight = this.APP.sldSpan.currentValue / 2 + this.APP.sldMultiSpan.currentValue;
      let offsetLeft = this.APP.sldSpan.currentValue / 2;// + this.APP.sldMultiSpan.currentValue;

      this.addOverhang(this.dimensionPatiosPlanRight, offsetRight, this.APP.sldFrontOverhang.currentValue, views, "Right", Math.PI,"y", DIMENSION_LABEL_TYPE.FRONT_OVERHANG);
      this.addOverhang(this.dimensionPatiosPlanRight, offsetLeft, this.APP.sldBackOverhang.currentValue, views, "Left", Math.PI, "y", DIMENSION_LABEL_TYPE.BACK_OVERHANG);

      this.repositionDimension(this.dimensionPatiosPlanRight, DIMENSION_LOCATION.PLAN_RIGHT, extraDistance);
    }
    private drawDimensionPatiosPlanLeft(){
        if(this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.NONE){
            this.dimensionPatiosPlanLeft.children = this.dimensionPatiosPlanLeft.children.filter(el => el.userData?.groupType != DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_VERTICAL)
            return;
        }

        let views = [{ viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }];

        let offsetDimensionZ = this.APP.sldSpan.currentValue
            + this.APP.sldMultiSpan.currentValue
            + this.APP.sldFrontOverhang.currentValue
            - this.APP.sldExistingWidth1.currentValue / 2
            - this.APP.existingWallManager.geo_existingWallL1.width
            - this.APP.eaveManager.backOverhang
            - this.APP.sldLeftCutVertical.currentValue/2;

        let optionsRake : AddDimensionOptions = {... this.options,
            lineLength: HomeComponent.ins.sldLeftCutVertical.currentValue,
            container: this.dimensionPatiosPlanLeft,
            views: views,
            textPos: new Vector3(0, -200, 0),
            labelType: DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_VERTICAL,
        }
        let dimRake = this.dimensionPatiosPlanLeft.children.find(el => el.userData?.groupType == DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_VERTICAL) as Dimension;
        if(!dimRake){
            new Dimension(optionsRake);
        } else {
            dimRake.update(optionsRake);
        }

        //let offsetDimensionX = this.MANAGER.boundingBox.min.x - this.dimensionDistance2;
        this.dimensionPatiosPlanLeft.position.set(0, 0, offsetDimensionZ);
        this.repositionDimension(this.dimensionPatiosPlanLeft, DIMENSION_LOCATION.PLAN_LEFT);
    }
    private drawDimensionPatiosFrontBottom(){
        let dimOffsetY = -this.dimensionDistance2;
        this.dimensionPatiosFrontBottom.position.set(0,dimOffsetY,0);

        let views = [ { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS } ];

        this.dimensionPatiosPlanBottom.children.forEach( groupDim => {
            groupDim.children.filter(c => c.userData.subtype != GEOMETRY_TYPE.DIMENSION_LINE_RAKECUT).forEach(e => {
                let dim = e.clone();
                dim.userData['views'] = views;
                if(dim.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT){
                    dim.rotation.set(0,Math.PI,0);
                    dim.userData['rotation'] = new Vector3(0,0,0);
                    dim.userData['position'] = { ...e.userData.position, bottom: true };
                }
                this.dimensionPatiosFrontBottom.add(dim);
            });
        })
    }
    private drawDimensionPatiosFrontRight(){
        let views = [ { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS } ];

        let _dimensionDistane = this.dimensionDistance2;
        if(this.APP.sltExistingType.currentValue == 2 || this.APP.sltExistingType.currentValue == 3){
            if(this.APP.sldExistingWidth1.currentValue > 0){
                _dimensionDistane = this.dimensionDistance;
            }
        }
        
        let height = UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket() + this.MANAGER.columnAndBeamManager.geo_superiorBeam.height + (UI.span + UI.multiSpan) / 2 * this.utils.tan(UI.patiosPitch);
        let dimOffsetY = height/2;

        let scaleLength = height/this.lineGeo.length

        let cap1 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap1.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap1.position.setX(-dimOffsetY);

        let cap2 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap2.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap2.position.setX(dimOffsetY);

        let line = new Mesh(this.lineGeo.geometry, this.materialManager.DIMENSION_TEXT);
        line.userData = { type: GEOMETRY_TYPE.DIMENSION_LINE, views: views };
        //line.position.setX(this.APP.sldSpan.currentValue/2 + this.APP.sldMultiSpan.currentValue/2);
        line.scale.setX(scaleLength);

        let textStr = this.utils.getDimensionValue(height);
        this.textGeo = new TextBufferGeometry(textStr, this.textParameter);
        this.textGeo.center();

        let text = new Mesh(this.textGeo, MaterialManager.Instance().DIMENSION_TEXT);
        text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, rotation: new Vector3(0,0,-Math.PI/2) };
        text.position.set(0,200,0);
        //text.rotation.set(0,-Math.PI,0);

        this.dimensionPatiosFrontRight.add(line, cap1, cap2, text, ...this.utils.addArrowForLine(line));
        this.repositionDimension(this.dimensionPatiosFrontRight, DIMENSION_LOCATION.FRONT_RIGHT);
    }
    private drawDimensionPatiosLeftBottom(){
        let dimOffsetY = -this.dimensionDistance2;
        let dimOffsetZ = this.dimensionPatiosPlanRight.position.z - (this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + this.APP.eaveManager.backOverhang - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0));
        
        this.dimensionPatiosLeftBottom.position.set(0,dimOffsetY,dimOffsetZ);

        let views = [ 
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            //{ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
        ];

        this.dimensionPatiosPlanRight.children.forEach( groupDim => {
            groupDim.children.forEach(e => {
                 if(e.userData?.subType == GEOMETRY_TYPE.DIMENSION_LINE_RAKECUT)
                     return;
     
                 let dim = e.clone();
                 dim.userData['views'] = views;
                 if (dim.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT) {
                     dim.rotation.set(0, 0, 0);
                     dim.userData['views'] = [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS }];
                     dim.userData['rotation'] = new Vector3(0, 0, 0);
                     dim.userData['position'] = {...e.userData.position, bottom: true}; 
                 }
                 this.dimensionPatiosLeftBottom.add(dim);
             });
         })
    }
    private drawDimensionPatiosLeftRight() {
        let dimOffsetY = UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket() + this.MANAGER.columnAndBeamManager.geo_superiorBeam.height + (UI.span + UI.multiSpan) / 2 * this.utils.tan(UI.patiosPitch);

        let views = [ 
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            //{ viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
        ];

        let offsetLength = dimOffsetY;
        let scaleLength = offsetLength / this.lineGeo.length

        let cap1 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap1.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap1.position.setX(-offsetLength/2);

        let cap2 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap2.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap2.position.setX(offsetLength/2);

        let line = new Mesh(this.lineGeo.geometry, this.materialManager.DIMENSION_TEXT);
        line.userData = { type: GEOMETRY_TYPE.DIMENSION_LINE, views: views };
        line.scale.setX(scaleLength);

        let textStr = this.utils.getDimensionValue(offsetLength);
        this.textGeo = new TextBufferGeometry(textStr, this.textParameter);
        this.textGeo.center();

        let text = new Mesh(this.textGeo, MaterialManager.Instance().DIMENSION_TEXT);
        text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS }], rotation: new Vector3(-Math.PI / 2, 0, 0) };
        text.position.set(0, 200, 0);
        //text.rotation.set(0,-Math.PI,0);

        this.dimensionPatiosLeftRight.add(line, cap1, cap2, text, ...this.utils.addArrowForLine(line));

        this.repositionDimension(this.dimensionPatiosLeftRight, DIMENSION_LOCATION.LEFT_RIGHT);
    }
    private drawDimensionPatiosRightBottom(){
        let dimOffsetY = -this.dimensionDistance2;
        let dimOffsetZ = this.dimensionPatiosPlanRight.position.z - (this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + this.APP.eaveManager.backOverhang - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0));
        
        this.dimensionPatiosRightBottom.position.set(0,dimOffsetY,dimOffsetZ);

        let views = [ { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS } ];

        this.dimensionPatiosPlanRight.children.forEach(groupDim => {
            groupDim.children.forEach(e => {
                if(e.userData?.subType == GEOMETRY_TYPE.DIMENSION_LINE_RAKECUT)
                    return;
                    
                let dim = e.clone();
                dim.userData['views'] = views;
                if (e.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT) {
                    dim.userData['rotation'] = new Vector3(0, 0, 0);
                    dim.userData['position'] = {...e.userData.position, bottom: true};
                }
                this.dimensionPatiosRightBottom.add(dim);
            });
        })
    }
    private drawDimensionPatiosRightLeft(){
        this.dimensionPatiosRightLeft.position.set(0,this.dimensionPatiosLeftRight.position.y,this.dimensionPatiosLeftRight.position.z);

        let views = [ { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS } ];

        this.dimensionPatiosLeftRight.children.forEach(e => {
            let dim = e.clone();
            dim.userData['views'] = views;
            if(e.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT){
                dim.userData['rotation'] = new Vector3(Math.PI/2,0,0);
                dim.rotation.set(0,Math.PI,0);
            }
            this.dimensionPatiosRightLeft.add(dim);
        });

        this.repositionDimension(this.dimensionPatiosRightLeft, DIMENSION_LOCATION.RIGHT_LEFT);
    }
    private drawDimensionPatiosLeftLeft() {        
        let dimOffsetY = UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket() + this.MANAGER.columnAndBeamManager.geo_superiorBeam.height;
        let dimOffsetZ = this.APP.sldExistingWidth1.currentValue / 2 + 1800;

        this.dimensionPatiosLeftLeft.position.set(0, dimOffsetY / 2, -dimOffsetZ);

        let views = [
            { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
            { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS }
        ];

        let offsetLength = dimOffsetY;
        let scaleLength = offsetLength / this.lineGeo.length

        let cap1 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap1.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap1.position.setX(-offsetLength / 2);

        let cap2 = new Mesh(this.lineCapGeo.geometry, this.materialManager.DIMENSION_TEXT);
        cap2.userData = { type: GEOMETRY_TYPE.DIMENSION_CAP, views: views };
        cap2.position.setX(offsetLength / 2);

        let line = new Mesh(this.lineGeo.geometry, this.materialManager.DIMENSION_TEXT);
        line.userData = { type: GEOMETRY_TYPE.DIMENSION_LINE, views: views };
        //line.position.setX(this.APP.sldSpan.currentValue/2 + this.APP.sldMultiSpan.currentValue/2);
        line.scale.setX(scaleLength);

        let textStr = this.utils.getDimensionValue(offsetLength);
        this.textGeo = new TextBufferGeometry(textStr, this.textParameter);
        this.textGeo.center();

        let text = new Mesh(this.textGeo, MaterialManager.Instance().DIMENSION_TEXT);
        text.userData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: [{ viewType: ViewType.LEFT, lineType: LineType.CONTINOUS }], rotation: new Vector3(Math.PI / 2, 0, 0) };
        text.position.set(0, 200, 0);
        //text.rotation.set(0,-Math.PI,0);

        this.dimensionPatiosLeftLeft.add(line, cap1, cap2, text, ...this.utils.addArrowForLine(line));
    }
    private drawDimensionPatiosRightRight(){
        let dimOffsetY = UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket() + this.MANAGER.columnAndBeamManager.geo_superiorBeam.height;
        let dimOffsetZ = this.APP.sldExistingWidth1.currentValue / 2 + 1800;
        
        this.dimensionPatiosRightRight.position.set(0,dimOffsetY/2,-dimOffsetZ);

        let views = [ { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS } ];

        this.dimensionPatiosLeftLeft.children.forEach(e => {
            if(e.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT){
                let dim = e.clone();
                dim.userData['views'] = views;
                dim.userData['rotation'] = new Vector3(-Math.PI/2,0,0);
                dim.rotation.set(0,Math.PI,0);
                dim.position.set(dim.position.x, -dim.position.y, dim.position.z);
                this.dimensionPatiosRightRight.add(dim);
            }
        });
    }

    private addOverhang(groupDim: Object3D, offset: number, overhangValue: number, views: any, side: string, rotateText: number, axis: string, labelType?: string) {
        if (overhangValue == 0){
            let dim = groupDim.children.find(el => el.userData?.groupType == labelType) as Dimension;
            if(dim){
                groupDim.remove(dim)
            }
            return;
        }

        let textStr = this.utils.getDimensionValue(overhangValue);

        let textRotation = new Vector3();
        let textUserData = { type: GEOMETRY_TYPE.DIMENSION_TEXT, value: textStr, views: views, position: { overhang: true }};
        if (axis === "z") {
            textRotation.setZ(rotateText);
            textUserData['rotation'] = new Vector3(0, 0, -rotateText / 2);
            textUserData['position']['bottom'] = true;
        }
        else if (axis === "y") {
            textRotation.setY(rotateText);
            textUserData['rotation'] = new Vector3(0, -rotateText / 2, 0);
        }
        else if (axis === "x") {
            textRotation.setX(rotateText);
            textUserData['rotation'] = new Vector3(-rotateText / 2, 0, 0);
        }

        let linePos = new Vector3();
        if (side === "Left") {
            linePos.setX(-offset - overhangValue / 2);
        }
        else if (side === "Right") {
            linePos.setX(offset + overhangValue / 2);
        }
        let fitZ = 0;
        if(labelType){
            if(axis === 'z'){
                fitZ = 10;
            } else if(axis === 'y'){
                fitZ = -10;
            }
        }
        let options : AddDimensionOptions = {... this.options,
            lineLength: overhangValue,
            container: groupDim,
            views: views,
            linePos: linePos,
            textPos: linePos.clone().setY(linePos.y - 200),
            labelType: labelType ? labelType : null,
            fitZ: fitZ,
            textRotation: textRotation,
            textUserData: textUserData
        }
        let dim = groupDim.children.find(el => el.userData?.groupType == labelType) as Dimension;
        if(!dim){
            new Dimension(options);
        } else {
            dim.update(options)
        }
    }
    private getTextCover(textGeo: TextBufferGeometry, labelType: string, pos: Vector3, fitZ = 0, bayNum?: number){
        let textSize = new Vector3();
        textGeo.boundingBox.getSize(textSize)

        let textCover = new Mesh(new BoxBufferGeometry(textSize.x, textSize.y, textSize.z + fitZ))
        textCover.position.set(pos.x , pos.y, pos.z);
        textCover.userData = { type: GEOMETRY_TYPE.TEXT_COVER, labelType };
        if(bayNum !== undefined){
            textCover.userData['bayNum'] = bayNum;
        }
        textCover.material['opacity'] = 0;
        textCover.material['transparent'] = true;

        return textCover
    }

    public getOutlines(): Printing2DGeometry{
        let lsGeometries: Printing2DLine[] = [];
        let lsText: Print2DText[] = [];
        
        for(let dim of [
            ...this.dimensionPatiosPlanBottom.children,
            ...this.dimensionPatiosPlanRight.children,
            ...this.dimensionPatiosPlanLeft.children,
            ...this.dimensionPatiosFrontBottom.children,
            ...this.dimensionPatiosFrontRight.children,
            ...this.dimensionPatiosLeftBottom.children,
            ...this.dimensionPatiosLeftRight.children,
            ...this.dimensionPatiosRightBottom.children,
            ...this.dimensionPatiosRightLeft.children,
            ...this.dimensionPlanMiddle.children,
            ...this.dimensionPatiosPlanLeftOverHang.children,
            ...this.dimensionPatiosLeftLeft.children,
            ...this.dimensionPatiosRightRight.children,
            ...this.dimensionPlanMidleLeft.children

        ]){
            if(!dim.userData.views){
                // console.log("TEXT NO VIEW", dim);
                continue;
            }

            if(dim.type == 'Group'){
                dim.children.forEach(el => {
                    el.updateMatrix();
                    let _clone = el.clone();
                    _clone.applyMatrix4(new Matrix4().getInverse(el.matrix));
                    let _box = new Box3().setFromObject(_clone);

                    let _points = this.utils.getStartEndPoint(_box);
                    let _line = new Line(new Geometry().setFromPoints(_points));
                    
                    el.updateWorldMatrix(true, true);
                    _line.geometry.applyMatrix4(el.matrixWorld);

                    let points = (_line.geometry as Geometry).vertices;
                    let pos = new Vector3().applyMatrix4(el.matrixWorld);

                    let textRotation = el.userData.rotation || new Vector3();
                    this.getDimInfo(lsGeometries, lsText, el, pos, textRotation, points)
                })
            } else{
                dim.updateMatrix();
                let _clone = dim.clone();
                _clone.applyMatrix4(new Matrix4().getInverse(dim.matrix));
                let _box = new Box3().setFromObject(_clone);

                let _points = this.utils.getStartEndPoint(_box);
                let _line = new Line(new Geometry().setFromPoints(_points));
                
                dim.updateWorldMatrix(true, true);
                _line.geometry.applyMatrix4(dim.matrixWorld);

                let points = (_line.geometry as Geometry).vertices;
                let pos = new Vector3().applyMatrix4(dim.matrixWorld);

                let textRotation = dim.userData.rotation || new Vector3();
                this.getDimInfo(lsGeometries, lsText, dim, pos, textRotation, points)
            }
        }
        
        return { lines: lsGeometries, texts: lsText };
    }
    private getDimInfo(lsGeometries: Printing2DLine[], lsText: Print2DText[], dim: Object3D, pos: Vector3, textRotation: Vector3, points: Vector3[]){
        if (dim.userData.type == GEOMETRY_TYPE.DIMENSION_TEXT && dim.userData.views && dim.userData.value) {
            let box = new Box3().setFromObject(dim.parent);
            let startEnd = this.utils.getStartEndPoint(box);
            let line = new Line3(startEnd[0], startEnd[1]);

            let projectPoint = new Vector3();
            line.closestPointToPoint(pos, true, projectPoint);
            let _pos = new Vector3().addVectors(projectPoint, new Vector3().subVectors(pos, projectPoint).normalize().multiplyScalar(10));

            if(dim.userData.position?.bottom){
                if(dim.userData.position?.overhang){
                    _pos = new Vector3().addVectors(projectPoint, new Vector3().subVectors(pos, projectPoint).normalize().multiplyScalar(180));
                }
                else{
                    _pos = new Vector3().addVectors(projectPoint, new Vector3().subVectors(pos, projectPoint).normalize().multiplyScalar(120));
                }
            }
            else{
                if(dim.userData.position?.overhang){
                    _pos = new Vector3().addVectors(projectPoint, new Vector3().subVectors(pos, projectPoint).normalize().multiplyScalar(100));
                }
            }

            let p = new Vector3().applyMatrix4(dim.matrixWorld);

            lsText.push({
                value: dim.userData.value,
                position: p,
                rotation: textRotation,
                views: dim.userData.views
            });

            
        }
        else if (dim.userData.type == GEOMETRY_TYPE.DIMENSION_LINE) {
            // this.scene.add(new LineSegments(new Geometry().setFromPoints(points), new LineBasicMaterial({color: new Color('red')})));

            lsGeometries.push({
                vertices: points,
                objectType: dim.userData.type,
                views: dim.userData.views
            });
        }
        else if (dim.userData.type == GEOMETRY_TYPE.DIMENSION_CAP) {
            lsGeometries.push({
                vertices: points,
                objectType: dim.userData.type,
                views: dim.userData.views
            });
        }
        else if (dim.userData.type == "Arrow") {
            let geo = ((dim as Line).geometry as Geometry).clone();
            geo.applyMatrix4(dim.matrixWorld);
            lsGeometries.push({
                vertices: geo.vertices,
                objectType: dim.userData.type,
                views: dim.userData.views
            });
        }
    }

    private getGeometryFromBufferGeometry(bufferGeo: BufferGeometry) {
        let points: Vector3[] = [];
        let verties = bufferGeo.getAttribute('position').array;
        for (let i = 0; i < verties.length; i += 3) {
            points.push(new Vector3(verties[i], verties[i + 1], verties[i+2]));
        }

        return new Geometry().setFromPoints(points);
    }
    public destroy(): void {
        this.unregisterEvent();
        this.unload();
    }

    private registerEvent(): void {
        this.eventHandleId = this.uiChanged.bind(this);

        this.controlsToRegisterEvent = [
            this.APP.sldSpan,
            this.APP.sldMultiSpan,
            this.APP.sldFrontOverhang,
            this.APP.sldExistingWidth1,
            this.APP.sldExistingWidth2,
            this.APP.sldExistingLength,
            this.APP.sldExistingLength2,
            this.APP.sltExistingType,
            this.APP.sldExistingWallHeight,
            this.APP.dialogEditBay,
            this.APP.sldLeftOverhang,
            this.APP.sldRightOverhang,
            this.APP.sldBackOverhang,
            this.APP.sldFlyOverBracketHeight,
            this.APP.sltRoofPitch
        ];

        this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));
        
        //this.APP.sldSpan.addAction(this.eventHandleId);
        //this.APP.sldMultiSpan.addAction(this.eventHandleId);
        //this.APP.sldFrontOverhang.addAction(this.eventHandleId);
        //this.APP.sldExistingWidth1.addAction(this.eventHandleId);
        //this.APP.sldExistingWidth2.addAction(this.eventHandleId);
        //this.APP.sldExistingLength.addAction(this.eventHandleId);
        //this.APP.sldExistingLength2.addAction(this.eventHandleId);
        //this.APP.sltExistingType.addAction(this.eventHandleId);
        //this.APP.sldExistingWallHeight.addAction(this.eventHandleId);
        //this.APP.dialogEditBay.addAction(this.eventHandleId);

        //this.APP.sldLeftOverhang.addAction(this.eventHandleId);
        //this.APP.sldRightOverhang.addAction(this.eventHandleId);
        //this.APP.sldBackOverhang.addAction(this.eventHandleId);

        //this.APP.sldFlyOverBracketHeight.addAction(this.eventHandleId);
        //this.APP.sltRoofPitch.addAction(this.eventHandleId);
    }
    private unregisterEvent(): void{
        //this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
        //this.APP.sldSpan.removeAction(this.eventHandleId);
        //this.APP.sldMultiSpan.removeAction(this.eventHandleId);
        //this.APP.sldFrontOverhang.removeAction(this.eventHandleId);
        //this.APP.sldExistingWidth1.removeAction(this.eventHandleId);
        //this.APP.sldExistingWidth2.removeAction(this.eventHandleId);
        //this.APP.sldExistingLength.removeAction(this.eventHandleId);
        //this.APP.sldExistingLength2.removeAction(this.eventHandleId);
        //this.APP.sltExistingType.removeAction(this.eventHandleId);
        //this.APP.sldExistingWallHeight.removeAction(this.eventHandleId);
        //this.APP.dialogEditBay.removeAction(this.eventHandleId);

        //this.APP.sldLeftOverhang.removeAction(this.eventHandleId);
        //this.APP.sldRightOverhang.removeAction(this.eventHandleId);
        //this.APP.sldBackOverhang.removeAction(this.eventHandleId);

        //this.APP.sldFlyOverBracketHeight.removeAction(this.eventHandleId);
        //this.APP.sltRoofPitch.removeAction(this.eventHandleId);
    }


    public uiChanged(preVal: number, curVal: number): void {
        this.draw();
    }
    private calulateBoundingBox(){
        this.APP.scene.remove(...this.APP.scene.children.filter(c => c.userData.type == 'BOX_HELPER_'));
        let patiosBox = this.MANAGER.boundingBox;
       
        let planRightBox = this.APP.utils.getObjectBoundingBox(this.dimensionPatiosPlanRight);
        planRightBox.min.x = patiosBox.max.x;
        planRightBox.max.x = patiosBox.max.x + 500;
        planRightBox.max.y = 10000;
        
        let planLeftBox = this.APP.utils.getObjectBoundingBox(this.dimensionPatiosPlanLeft);
        planLeftBox.min.x = patiosBox.min.x - 500;
        planLeftBox.max.x = patiosBox.min.x;
        planLeftBox.max.y = 10000;

        let planBottomBox = this.APP.utils.getObjectBoundingBox(this.dimensionPatiosPlanBottom);
        let planBottomBoxWidth = planBottomBox.max.z - planBottomBox.min.z;
        planBottomBox.min.z = patiosBox.max.z;
        planBottomBox.max.z = patiosBox.max.z + planBottomBoxWidth;
        planBottomBox.max.y = 10000;
        
        let objs = [];

        objs = this.APP.scene.children.filter(c =>
            (c.userData.type == GEOMETRY_TYPE.EXISTING_ROOF && c.userData.position?.width1 || c.userData.position?.width2 || c.userData.position?.length2)
            || (c.userData.type == GEOMETRY_TYPE.EXISTING_WALL)
            );

        // for(let e of objs){
        //     let boxModelHelper = new BoxHelper(e);
        //     boxModelHelper.userData = {type: 'BOX_HELPER_'};
        //     this.scene.add(boxModelHelper);
        // }

        for(let i = 0; i < 100; i++){
            let intersectBox = false;
            for(let o of objs){
                let objBox = this.APP.utils.getObjectBoundingBox(o);
                if(planRightBox.intersectsBox(objBox)){
                    intersectBox = true;
                    planRightBox.translate(new Vector3(100,0,0))
                }
                if(planLeftBox.intersectsBox(objBox)){
                    intersectBox = true;
                    planLeftBox.translate(new Vector3(-100,0,0))
                }
                if(planBottomBox.intersectsBox(objBox)){
                    intersectBox = true;
                    planBottomBox.translate(new Vector3(0,0,100))
                }
            }
            
            if(!intersectBox)
                break;
        }
        
        // let boxHelper = new Box3Helper(planRightBox);
        // boxHelper.userData = {type: 'BOX_HELPER_'};
        // this.APP.scene.add(boxHelper);

        // let boxHelper2 = new Box3Helper(planLeftBox);
        // boxHelper2.userData = {type: 'BOX_HELPER_'};
        // this.APP.scene.add(boxHelper2);

        // let boxHelper3 = new Box3Helper(planBottomBox);
        // boxHelper3.userData = {type: 'BOX_HELPER_'};
        // this.APP.scene.add(boxHelper3);
        
        return { left: planLeftBox.min.x, right: planRightBox.max.x, front: planBottomBox.max.z };
    }
    private repositionDimension(dimension, location, extraDistance = 0){
        this.APP.scene.remove(...this.APP.scene.children.filter(c => c.userData.type == location));
        this.APP.scene.remove(...this.APP.scene.children.filter(c => c.userData.type == location));

        let patiosBox = this.MANAGER.boundingBox;
       
        if(location == DIMENSION_LOCATION.FRONT_RIGHT){
            let height = UI.eaveHeight + this.totalHeightFromEaveHeightToTopOfFlyoverBraket() + this.MANAGER.columnAndBeamManager.geo_superiorBeam.height + (UI.span + UI.multiSpan) / 2 * this.utils.tan(UI.patiosPitch);
            let dimOffsetY = height/2;
            dimension.position.setY(dimOffsetY);
            let dimBox = this.APP.utils.getObjectBoundingBox(dimension);
            dimBox.min.x = patiosBox.max.x;
            dimBox.max.x = patiosBox.max.x + 500;
            dimBox.max.z = 10000;

            let objs = this.APP.scene.children.filter(c =>
                (c.userData.type == GEOMETRY_TYPE.EXISTING_ROOF && !c.userData.position.length1 && !c.userData.position.length2)
                 || (c.userData.type == GEOMETRY_TYPE.EXISTING_WALL && !c.userData.position.length1 && !c.userData.position.length2)
            );

            if(this.APP.sldExistingWidth2.currentValue > 0){
                objs = this.APP.scene.children.filter(c =>
                    (c.userData.type == GEOMETRY_TYPE.EXISTING_ROOF && !c.userData.position.length1)
                     || (c.userData.type == GEOMETRY_TYPE.EXISTING_WALL && !c.userData.position.length1)
                );
            }
    
            for(let i = 0; i < 100; i++){
                let intersectBox = false;
                for(let o of objs){
                    let objBox = this.APP.utils.getObjectBoundingBox(o);
                    if(dimBox.intersectsBox(objBox)){
                        intersectBox = true;
                        dimBox.translate(new Vector3(100,0,0))
                    }
                }
                
                if(!intersectBox)
                    break;
            }

            dimension.position.setX(dimBox.max.x + extraDistance);
        }
        else if(location == DIMENSION_LOCATION.PLAN_LEFT){
            // let offsetDimensionZ = this.APP.sldSpan.currentValue
            // + this.APP.sldMultiSpan.currentValue
            // + this.APP.sldFrontOverhang.currentValue
            // - this.APP.sldExistingWidth1.currentValue / 2
            // - this.APP.existingWallManager.geo_existingWallL1.width
            // - this.APP.eaveManager.backOverhang;
            // dimension.position.setZ(offsetDimensionZ);

            let dimBox = this.APP.utils.getObjectBoundingBox(dimension);
            dimBox.min.x = patiosBox.min.x - 500;
            dimBox.max.x = patiosBox.min.x;
            dimBox.max.y = 10000;

            let objs = this.APP.scene.children.filter(c =>
                c.userData.type == GEOMETRY_TYPE.EXISTING_ROOF || c.userData.type == GEOMETRY_TYPE.EXISTING_WALL
            );
    
            for(let i = 0; i < 100; i++){
                let intersectBox = false;
                for(let o of objs){
                    let objBox = this.APP.utils.getObjectBoundingBox(o);
                    if(dimBox.intersectsBox(objBox)){
                        intersectBox = true;
                        dimBox.translate(new Vector3(-100,0,0))
                    }
                }
                
                if(!intersectBox)
                    break;
            }
          
            // let boxHelper = new Box3Helper(dimBox);
            // boxHelper.userData = {type: location};
            // this.APP.scene.add(boxHelper);

            
            dimension.position.setX(dimBox.min.x + extraDistance);
            dimension.translateX(this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + this.APP.eaveManager.backOverhang - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0));
        }
        else if(location == DIMENSION_LOCATION.PLAN_RIGHT){
            let offsetDimensionZ = this.APP.sldSpan.currentValue / 2
            - this.APP.sldExistingWidth1.currentValue / 2
            - this.APP.existingWallManager.geo_existingWallL1.width
            - this.APP.eaveManager.backOverhang;
            dimension.position.setZ(offsetDimensionZ);

            let dimBox = this.APP.utils.getObjectBoundingBox(dimension);
            dimBox.min.x = patiosBox.max.x;
            dimBox.max.x = patiosBox.max.x + 500;
            dimBox.max.y = 10000;

            let objs = this.APP.scene.children.filter(c =>
                (c.userData.type == GEOMETRY_TYPE.EXISTING_ROOF)
                 || (c.userData.type == GEOMETRY_TYPE.EXISTING_WALL)
            );
    
            for(let i = 0; i < 100; i++){
                let intersectBox = false;
                for(let o of objs){
                    let objBox = this.APP.utils.getObjectBoundingBox(o);
                    if(dimBox.intersectsBox(objBox)){
                        intersectBox = true;
                        dimBox.translate(new Vector3(100,0,0))
                    }
                }
                
                if(!intersectBox)
                    break;
            }
            
            // let boxHelper = new Box3Helper(dimBox);
            // boxHelper.userData = {type: location};
            // this.APP.scene.add(boxHelper);

            
            dimension.position.setX(dimBox.max.x + extraDistance);
            dimension.translateX(this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + this.APP.eaveManager.backOverhang - ( this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0));
        }
        else if(location == DIMENSION_LOCATION.PLAN_BOTTOM){
            let dimBox = this.APP.utils.getObjectBoundingBox(dimension);
            let width = dimBox.max.z - dimBox.min.z;
            dimBox.min.z = patiosBox.max.z;
            dimBox.max.z = patiosBox.max.z + width;
            dimBox.max.y = 10000;

            let objs = this.APP.scene.children.filter(c =>
                (c.userData.type == GEOMETRY_TYPE.EXISTING_ROOF)
                 || (c.userData.type == GEOMETRY_TYPE.EXISTING_WALL)
            );
    
            for(let i = 0; i < 100; i++){
                let intersectBox = false;
                for(let o of objs){
                    let objBox = this.APP.utils.getObjectBoundingBox(o);
                    if(dimBox.intersectsBox(objBox)){
                        intersectBox = true;
                        dimBox.translate(new Vector3(0,0,100))
                    }
                }
                
                if(!intersectBox)
                    break;
            }
            
            // let boxHelper = new Box3Helper(dimBox);
            // boxHelper.userData = {type: location};
            // this.APP.scene.add(boxHelper);

            
            dimension.position.setZ(dimBox.max.z + extraDistance);

            if (this.APP.sltExistingType.currentValue == 1) {
                dimension.position.setX(this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0)/2)
            } else if(this.APP.sltExistingType.currentValue == 2) {
                dimension.position.setX( -(this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width + (this.MANAGER.columnAndBeamManager.geo_bracket?.width || 0)/2))
            } else {
                dimension.position.setX(0)
            }
        }
        else if(location == DIMENSION_LOCATION.LEFT_RIGHT){
            let dimOffsetY = UI.eaveHeight + + this.totalHeightFromEaveHeightToTopOfFlyoverBraket() + this.MANAGER.columnAndBeamManager.geo_superiorBeam.height + (UI.span + UI.multiSpan) / 2 * this.utils.tan(UI.patiosPitch);
            dimension.position.setY(dimOffsetY / 2);

            let dimBox = this.APP.utils.getObjectBoundingBox(dimension);
            let width = dimBox.max.z - dimBox.min.z;
            dimBox.min.z = patiosBox.max.z;
            dimBox.max.z = patiosBox.max.z + width;
            dimBox.min.x = -10000;
            dimBox.max.x = 10000;

            let objs = this.APP.scene.children.filter(c =>
                (c.userData.type == GEOMETRY_TYPE.EXISTING_ROOF && c.userData.position?.left)
                 || (c.userData.type == GEOMETRY_TYPE.EXISTING_WALL && c.userData.position?.left)
            );
    
            for(let i = 0; i < 100; i++){
                let intersectBox = false;
                for(let o of objs){
                    let objBox = this.APP.utils.getObjectBoundingBox(o);
                    if(dimBox.intersectsBox(objBox)){
                        intersectBox = true;
                        dimBox.translate(new Vector3(0,0,100))
                    }
                }
                
                if(!intersectBox)
                    break;
            }
            
            // let boxHelper = new Box3Helper(dimBox);
            // boxHelper.userData = {type: location};
            // this.APP.scene.add(boxHelper);

            
            dimension.position.setZ(dimBox.max.z + extraDistance);
        }
        else if(location == DIMENSION_LOCATION.RIGHT_LEFT){
            let dimOffsetY = this.dimensionPatiosLeftRight.position.y;
            dimension.position.setY(dimOffsetY);

            let dimBox = this.APP.utils.getObjectBoundingBox(this.dimensionPatiosLeftRight);
            let width = dimBox.max.z - dimBox.min.z;
            dimBox.min.z = patiosBox.max.z;
            dimBox.max.z = patiosBox.max.z + width;
            
            dimBox.min.x = -10000;
            dimBox.max.x = 10000;

            let objs = this.APP.scene.children.filter(c =>
                (c.userData.type == GEOMETRY_TYPE.EXISTING_ROOF && c.userData.position?.right)
                 || (c.userData.type == GEOMETRY_TYPE.EXISTING_WALL && c.userData.position?.right)
            );
    
            for(let i = 0; i < 100; i++){
                let intersectBox = false;
                for(let o of objs){
                    let objBox = this.APP.utils.getObjectBoundingBox(o);
                    if(dimBox.intersectsBox(objBox)){
                        intersectBox = true;
                        dimBox.translate(new Vector3(0,0,100))
                    }
                }
                
                if(!intersectBox)
                    break;
            }
            
            // let boxHelper = new Box3Helper(dimBox);
            // boxHelper.userData = {type: location};
            // this.APP.scene.add(boxHelper);

            
            dimension.position.setZ(dimBox.max.z + extraDistance);
        }
    }
}
