import {
  BUILDING_SIDE,
  CUTOUT_ENABLE,
  EXISTING_BUILDING_CONFIG,
  FIT_FLYOVER_BRAKET_ON_ROOF,
  PANEL_DIRECTION,
  RAKECUT_TYPE,
} from "src/app/app.constants";
import HighLightBox from "src/app/models/HighlightBox";
import {
  BoxBufferGeometry,
  BufferGeometry,
  Color,
  Geometry,
  Group,
  LineSegments,
  Material,
  Matrix4,
  Mesh,
  MeshLambertMaterial,
  Object3D,
  Plane,
  Vector3,
} from "three";
import { CSG } from "three-csg-ts";
import { PatiosBackFlyOverManager } from ".";
import { GEOMETRY_CATEGORY, GEOMETRY_TYPE } from "../../app.config";
import { HomeComponent as AppComponent } from "../../containers/home/home.component";
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import {
  GeometryInfo,
  LineType,
  Print2DView,
  Printing2DGeometry,
  Printing2DLine,
  ViewType,
} from "../models";
import { UI } from "../ui";
import { Util } from "../utils";

export class RoofManager {
  private scene: Group;
  private APP: AppComponent;
  private MANAGER: PatiosBackFlyOverManager;
  private material: Material;
  private utils: Util;

  private geometryManager: GeometryManager;

  private geo_RoofPanel: GeometryInfo;
  private geo_roofPanelBase: GeometryInfo;
  private geo_roofPanelBargeCappingLeft: GeometryInfo;
  private geo_roofPanelBargeCappingRight: GeometryInfo;
  private geo_roofPanelBargeCappingFront: GeometryInfo;
  private geo_bargeCutoutLeftHor: GeometryInfo;
  private geo_bargeCutoutRightHor: GeometryInfo;
  private geo_bargeRakecutLeft: GeometryInfo;
  private geo_bargeRakecutRight: GeometryInfo;
  //private geo_roofPanelBargeCappingBack: BufferGeometry;
  private geo_gutterFront: GeometryInfo;
  private zFlashingGeo: GeometryInfo;
  private geo_gutterCap: GeometryInfo;

  private roofGroup: Group;

  private eventHandleId: any;
  private objectSizeChangedHandle: any;

  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;

  private totalBaySize: number;
  private extraOffsetZ: number;

  private mesh_CutoutLeft: Mesh;
  private csg_cutoutLeft: CSG;
  private mesh_CutoutRight: Mesh;
  private csg_cutoutRight: CSG;
  private geo_cutoutLeft: BoxBufferGeometry;
  private geo_cutoutRight: BoxBufferGeometry;
  private geo_rakecutLeft: BoxBufferGeometry;
  private geo_rakecutRight: BoxBufferGeometry;
  private mesh_rakecutLeft: Mesh;
  private mesh_rakecutRight: Mesh;
  private csg_rakecutLeft: CSG;
  private csg_rakecutRight: CSG;
  private geo_downPipe: GeometryInfo;

  private roofWidth: number;
  private roofLength: number;

  private deferHandle;
  private deferTimeout = EXISTING_BUILDING_CONFIG.CUTOUT_DEFFER_TIME_OUT;
  private cutoutExistingBothLength: number;

  constructor(app: AppComponent, backFlyOverManager: PatiosBackFlyOverManager) {
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.APP = app;
    this.MANAGER = backFlyOverManager;
    this.scene = new Group();
    backFlyOverManager.patiosGroup.add(this.scene)
    this.material = MaterialManager.Instance().ROOF;

    this.registerEvent();
  }

  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.geo_RoofPanel = this.geometryManager.getRoofSheetPanel();
      this.geo_RoofPanel.geometry.translate(
        this.geo_RoofPanel.width / 2 + 2,
        this.geo_RoofPanel.height / 2 - 1,
        this.geo_RoofPanel.length / 2
      );

      this.geo_roofPanelBase = this.geometryManager.getRoofBase();
      this.geo_roofPanelBase.geometry.translate(
        this.geo_roofPanelBase.width / 2,
        this.geo_roofPanelBase.height / 2,
        this.geo_roofPanelBase.length / 2
      );

      this.zFlashingGeo = this.geometryManager.getZFlashing();
      this.zFlashingGeo.geometry.rotateY(Math.PI);
      this.zFlashingGeo.geometry.translate(this.zFlashingGeo.length, -3, 0);

      //Barge capping
      this.geo_roofPanelBargeCappingLeft = this.geometryManager.getBarge();
      this.geo_roofPanelBargeCappingLeft.geometry
        .rotateY(Math.PI)
        .translate(
          this.geo_roofPanelBargeCappingLeft.width / 2 - 3,
          this.geo_roofPanelBargeCappingLeft.height / 2 -
            (this.APP.sltDripBarge.currentValue ? 9 : 1),
          this.geo_roofPanelBargeCappingLeft.length / 2
        );

      this.geo_roofPanelBargeCappingRight = this.geometryManager.getBarge();
      this.geo_roofPanelBargeCappingRight.geometry.translate(
        -this.geo_roofPanelBargeCappingLeft.width / 2 + 3,
        this.geo_roofPanelBargeCappingLeft.height / 2 -
          (this.APP.sltDripBarge.currentValue ? 9 : 1),
        this.geo_roofPanelBargeCappingLeft.length / 2
      );

      this.geo_roofPanelBargeCappingFront = this.geometryManager.getBarge();
      this.geo_roofPanelBargeCappingFront.geometry
        .rotateY(-Math.PI / 2)
        .translate(
          this.geo_roofPanelBargeCappingLeft.length / 2,
          this.geo_roofPanelBargeCappingLeft.height / 2 -
            (this.APP.sltDripBarge.currentValue ? 9 : 1),
          -this.geo_roofPanelBargeCappingLeft.width / 2
        );

      this.geo_bargeRakecutLeft = this.geometryManager.getBarge();
      this.geo_bargeRakecutLeft.geometry
        .rotateY(Math.PI)
        .translate(
          this.geo_bargeRakecutLeft.width / 2,
          this.geo_bargeRakecutLeft.height / 2 -
            (this.APP.sltDripBarge.currentValue ? 9 : 1),
          -this.geo_bargeRakecutLeft.length / 2
        );

      this.geo_bargeRakecutRight = this.geometryManager.getBarge();
      this.geo_bargeRakecutRight.geometry.translate(
        -this.geo_bargeRakecutRight.width / 2,
        this.geo_bargeRakecutRight.height / 2 -
          (this.APP.sltDripBarge.currentValue ? 9 : 1),
        -this.geo_bargeRakecutRight.length / 2
      );

      //Gutter
      this.geo_gutterFront = this.geometryManager.getGutter();
      this.geo_gutterFront.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_gutterFront.length / 2,
          -20,
          this.geo_gutterFront.width / 2
        );

      //Gutter cap
      this.geo_gutterCap = this.geometryManager.getGuterEndCap();
      this.geo_gutterCap.geometry
        .rotateY(Math.PI / 2)
        .translate(0, -20, this.geo_gutterCap.length / 2);

      this.geo_cutoutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutLeft.translate(-500, 500, -500);
      this.geo_cutoutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_cutoutRight.translate(500, 500, -500);

      this.geo_rakecutLeft = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutLeft.translate(-500, 500, 500);
      this.geo_rakecutRight = new BoxBufferGeometry(1000, 1000, 1000);
      this.geo_rakecutRight.translate(500, 500, 500);

      //Barge cutout left
      this.geo_bargeCutoutLeftHor = this.geometryManager.getBarge();
      this.geo_bargeCutoutLeftHor.geometry
        .rotateY(Math.PI / 2)
        .translate(
          -this.geo_bargeCutoutLeftHor.length / 2,
          this.geo_bargeCutoutLeftHor.height / 2 -
            (this.APP.sltDripBarge.currentValue ? 9 : 1),
          this.geo_bargeCutoutLeftHor.width / 2
        );

      //Barge cutout right
      this.geo_bargeCutoutRightHor = this.geometryManager.getBarge();
      this.geo_bargeCutoutRightHor.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_bargeCutoutRightHor.length / 2,
          this.geo_bargeCutoutRightHor.height / 2 -
            (this.APP.sltDripBarge.currentValue ? 9 : 1),
          this.geo_bargeCutoutRightHor.width / 2
        );

      this.geo_downPipe = this.geometryManager.getDownPipe();

      resolve();
    });
  }
  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.ROOF_PATIOS
        )
      );
      this.APP.scene.remove(
        ...this.APP.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SHEET_OUTLINE
        )
      );

      //test
      this.APP.scene.remove(
        ...this.APP.scene.children.filter((x) => x.userData.type == "RAKECUT")
      );
      this.APP.scene.remove(
        ...this.APP.scene.children.filter((x) => x.userData.type == "HELPER")
      );

      this.extraOffsetZ =
        this.APP.existingWallManager.geo_existingWallL1.width +
        this.APP.eaveManager.backOverhang;

      this.totalBaySize = this.APP.dialogEditBay.totalBaySize;

      this.roofLength =
        this.APP.sldSpan.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldFrontOverhang.currentValue;
      const _roofLength =
        Math.pow(this.roofLength, 2) +
        Math.pow(this.getHeightByRoofPitch({ isMultiSpan: true }), 2);
      this.cutoutExistingBothLength =
        (this.totalBaySize -
          (this.APP.sldExistingLength.currentValue +
            this.APP.existingWallManager.geo_existingWallW1.width * 2)) /
        2;

      this.addRoof();
      this.addCutout();
      this.addAngelRakeCut();
      this.addStepRakecut();
      if (UI.rakeCutLeftType == 0) {
        new HighLightBox(this.roofGroup, {
          min: {
            x: -(UI.totalBayLength / 2 + UI.overhangLeft),
            y: 0,
            z: UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront,
          },
          max: {
            x: -(UI.totalBayLength / 2 + UI.overhangLeft) + 300,
            y: 0,
            z:
              UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront - 300,
          },
          userData: { side: BUILDING_SIDE.LEFT },
        });
      }
      if (UI.rakeCutRightType == 0) {
        new HighLightBox(this.roofGroup, {
          min: {
            x: UI.totalBayLength / 2 + UI.overhangRight - 300,
            y: 0,
            z: UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront,
          },
          max: {
            x: UI.totalBayLength / 2 + UI.overhangRight,
            y: 0,
            z:
              UI.span + UI.multiSpan + UI.overhangBack + UI.overhangFront - 300,
          },
          userData: { side: BUILDING_SIDE.RIGHT },
        });
      }

      let offsetLeft =
        this.totalBaySize / 2 +
        this.APP.sldLeftOverhang.currentValue -
        this.MANAGER.patiosGroup.position.x;
      let offsetRight =
        this.totalBaySize / 2 +
        this.APP.sldRightOverhang.currentValue +
        this.MANAGER.patiosGroup.position.x;
      let offsetFront =
        this.APP.sldSpan.currentValue -
        this.APP.sldExistingWidth1.currentValue / 2 +
        this.APP.sldFrontOverhang.currentValue +
        this.APP.sldMultiSpan.currentValue;
      let offsetBack =
        this.APP.sldExistingWidth1.currentValue / 2 +
        this.APP.sldBackOverhang.currentValue;

      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
        let offsetX =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndPurlinManager.geo_bracket?.width || 0);
        offsetRight -= offsetX;
        offsetLeft += offsetX;
      }
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
        let offsetX =
          this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
          100 -
          (this.MANAGER.columnAndPurlinManager.geo_bracket?.width || 0);
        offsetLeft += offsetX;
        offsetRight += offsetX;
      }
      let clipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
      let clipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);
      let clipingPlaneFront = new Plane(new Vector3(0, 0, -1), offsetFront);
      let clipingPlaneBack = new Plane(new Vector3(0, 0, 1), offsetBack);

      // let helper = new PlaneHelper(clipingPlaneLeft, 10000, 0xffff00);
      // helper.name = "HELPER";
      // helper.userData = { type: 'HELPER' };
      // this.APP.scene.add(helper);

      this.material.clippingPlanes = [clipingPlaneLeft, clipingPlaneRight];
      MaterialManager.Instance().ROOF_BASE.clippingPlanes = [
        clipingPlaneLeft,
        clipingPlaneRight,
      ];
      let width =
        this.totalBaySize +
        this.APP.sldLeftOverhang.currentValue +
        this.APP.sldRightOverhang.currentValue;
      this.roofWidth = width;
      this.roofLength =
        this.APP.sldSpan.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldBackOverhang.currentValue +
        this.APP.sldFrontOverhang.currentValue;
      let roofSheetingCount = Math.ceil(width / this.geo_RoofPanel.width);
      let offsetX = -(
        this.totalBaySize / 2 +
        this.APP.sldLeftOverhang.currentValue
      );

      //panel direction
      let panelDirectionOfset = 1;
      if (
        this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.RIGHT_TO_LEFT
      ) {
        offsetX =
          this.totalBaySize / 2 +
          this.APP.sldRightOverhang.currentValue -
          this.geo_RoofPanel.width;
        panelDirectionOfset = -1;
      } else if (
        this.APP.sltPanelDirection.currentValue == PANEL_DIRECTION.LEFT_TO_RIGHT
      ) {
        offsetX = -(
          this.totalBaySize / 2 +
          this.APP.sldLeftOverhang.currentValue
        );
      }

      if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT &&
        this.MANAGER.cutoutCondition
      ) {
        let totalPanelLength = 0;
        let cuted = true;
        for (let index = 0; index < roofSheetingCount; index++) {
          let last = index == roofSheetingCount - 1;
          totalPanelLength += this.geo_RoofPanel.width;
          let isOver =
            totalPanelLength <
            this.APP.sldExistingLength2.currentValue -
              this.APP.existingWallManager.geo_existingWallW1.width -
              100;

          this.addRoofSheeting(offsetX, [this.csg_cutoutLeft]);
          this.addRoofSheetingBase(offsetX, last, [this.csg_cutoutLeft]);

          offsetX += this.geo_RoofPanel.width * panelDirectionOfset;
        }
      } else if (
        this.APP.sltCutOut.currentValue == 1 &&
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT &&
        this.MANAGER.cutoutCondition
      ) {
        let totalPanelLength = 0;
        let cuted = false;
        for (let index = 0; index < roofSheetingCount; index++) {
          let last = index == roofSheetingCount - 1;
          totalPanelLength += this.geo_RoofPanel.width;
          let isOver =
            totalPanelLength >=
            this.roofWidth -
              this.APP.sldExistingLength2.currentValue +
              this.geo_RoofPanel.width;
          cuted =
            totalPanelLength >=
              this.roofWidth - this.APP.sldExistingLength2.currentValue &&
            !isOver;

          this.addRoofSheeting(offsetX, [this.csg_cutoutRight]);
          this.addRoofSheetingBase(offsetX, last, [this.csg_cutoutRight]);

          offsetX += this.geo_RoofPanel.width * panelDirectionOfset;
        }
      } else if (
        this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
        this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH &&
        this.MANAGER.cutoutCondition
      ) {
        let totalPanelLength = 0;
        let cuted = false;
        let cuted2 = false;
        for (let index = 0; index < roofSheetingCount; index++) {
          let last = index == roofSheetingCount - 1;
          totalPanelLength += this.geo_RoofPanel.width;
          let isOver =
            totalPanelLength < this.APP.sldExistingLength2.currentValue;
          let isOver2 =
            totalPanelLength >=
            this.roofWidth -
              this.APP.sldExistingLength2.currentValue +
              this.geo_RoofPanel.width;
          let flag =
            totalPanelLength >=
            this.roofWidth - this.APP.sldExistingLength2.currentValue;

          // if (!cuted && !isOver) {
          //     this.addRoofSheetingBase(offsetX, last, this.csg_cutoutLeft);
          //     this.addRoofSheeting(offsetX, true, this.csg_cutoutLeft);
          //     cuted = true;
          // }
          // else if (!cuted2 && flag) {
          //     this.addRoofSheetingBase(offsetX, last, this.csg_cutoutRight);
          //     this.addRoofSheeting(offsetX, true, this.csg_cutoutRight);
          //     cuted2 = true;
          // }
          // else {
          //     this.addRoofSheetingBase(offsetX, last, []);
          //     this.addRoofSheeting(offsetX, false, []);
          // }

          this.addRoofSheeting(offsetX, [
            this.csg_cutoutLeft,
            this.csg_cutoutRight,
          ]);
          this.addRoofSheetingBase(offsetX, last, [
            this.csg_cutoutLeft,
            this.csg_cutoutRight,
          ]);

          offsetX += this.geo_RoofPanel.width * panelDirectionOfset;
        }
      } else {
        for (let index = 0; index < roofSheetingCount; index++) {
          let last = index == roofSheetingCount - 1;

          this.addRoofSheeting(offsetX, []);
          this.addRoofSheetingBase(offsetX, last, []);

          offsetX += this.geo_RoofPanel.width * panelDirectionOfset;
        }
      }

      this.addZFlashing();
      this.addBargeCapping();
      this.addBargeAngelRakecut();
      this.addBargeStepRakecut();
      this.addGutter();

      //this.getOutLines();
      this.showPanelOutline();
      //this.showPanelOutLineTest();

      this.scene.visible = UI.showRoof

      resolve();
    });
  }
  private getHeightByRoofPitch(userDataPos: any): number {
    if (userDataPos.isSpan) {
      return (
        this.utils.tan(this.APP.sltRoofPitch.currentValue) *
        this.APP.sldSpan.currentValue
      );
    } else if (userDataPos.isMultiSpan) {
      return (
        this.utils.tan(this.APP.sltRoofPitch.currentValue) *
        (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue)
      );
    } else {
      return (
        this.utils.tan(this.APP.sltRoofPitch.currentValue) * this.roofLength
      );
    }
  }
  public getOutLines(): Printing2DGeometry {
    let objs = this.roofGroup.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    );

    let gutterGroupObjs = this.roofGroup.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.GUTTER_PATIOS
    );
    let gutterObjs: Object3D[] = [];
    for (let group of gutterGroupObjs) {
      gutterObjs.push(...group.children);
    }
    objs = [...objs, ...gutterObjs];

    let lsGeometries: Printing2DLine[] = [];

    let offsetLeft =
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue -
      this.MANAGER.patiosGroup.position.x;
    let offsetRight =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);

    for (let o of objs) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        60
      );

      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);
      outlineGeo.translate(0, 5000, 0);

      if (
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
      ) {
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
      }

      // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
      // line.userData = {type: "SHEETING_OUTLINE"};
      // this.APP.scene.add( line );

      let simplifiedGeo = this.simplifyGeo(outlineGeo);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: simplifiedGeo.vertices,
        views: o.userData.views,
      });
    }

    lsGeometries.push({
      objectType: GEOMETRY_TYPE.ROOF_COVER,
      vertices: this.makeRoofPolygon(),
      color: this.utils.convertHexColorToRgb(`#${new Color(UI.panelColor).getHexString()}`),
      views: [
        { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS }
      ]
    });

    return { lines: lsGeometries, texts: [] };
  }
  makeRoofPolygon() {
    const polygon = this.utils.getRoofCoverPolygonForLRRoof()
    this.roofGroup.updateWorldMatrix(true, true)
    return polygon.map(el => (el as Vector3).applyMatrix4(this.roofGroup.matrixWorld))
  }
  public showPanelOutline() {
    if (this.APP.sltPanelDirectionShow.currentValue == false) return;

    let objs = this.roofGroup.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    );

    let offsetLeft =
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue -
      this.MANAGER.patiosGroup.position.x;
    let offsetRight =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);

    for (let o of objs) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        60
      );

      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);
      //outlineGeo.translate(0, 1000, 0);

      if (
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
      ) {
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
        outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
      }

      var line = new LineSegments(
        outlineGeo,
        MaterialManager.Instance().MESH_OUTLINE
      );
      line.userData = { type: GEOMETRY_TYPE.SHEET_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  public showPanelOutLineTest() {
    // if(this.APP.sltPanelDirectionShow.currentValue == false)
    //     return;

    //console.log('show layout');

    let objs = this.roofGroup.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL ||
        o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE
    );

    let offsetLeft =
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue -
      this.MANAGER.patiosGroup.position.x;
    let offsetRight =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let roofClipingPlaneLeft = new Plane(new Vector3(1, 0, 0), offsetLeft);
    let roofClipingPlaneRight = new Plane(new Vector3(-1, 0, 0), offsetRight);

    let flag = false;

    //for (let i = 0; i < objs.length-1; i+=2)
    //for(let o of objs)
    {
      // let o1 = objs[i];
      // let o2 = objs[i+1];

      // o1.material = objs[i].material.clone();
      // o2.material = objs[i+1].material.clone();

      // if(flag){
      //     o1.material.color = new Color('gray');
      //     o2.material.color = new Color('gray');
      // }
      // else{
      //     o1.material.color = new Color('white');
      //     o2.material.color = new Color('white');
      // }

      // flag = !flag;

      let o = objs[7];
      //let newGeo = BufferGeometryUtils.toTrianglesDrawMode(new BufferGeometry().fromGeometry((o as Mesh).geometry), TrianglesDrawMode);

      let tmpGeo = (o as Mesh).geometry as Geometry;
      tmpGeo.computeFaceNormals();
      tmpGeo.mergeVertices();
      tmpGeo.computeVertexNormals();
      //let simpifiedGeo = this.modifier.modify(tmpGeo, Math.floor(tmpGeo.vertices.length * 0.9));

      // let simplified = o.clone() as Mesh;
      // simplified.material = (simplified.material as MeshPhongMaterial).clone();
      // simplified.material.flatShading = true;

      //const count = Math.floor( newGeo.attributes['position'].count * 0.9 ); // number of vertices to remove
      //newGeo = this.modifier.modify( newGeo, count );

      // var rs = ((o as Mesh).geometry as Geometry).mergeVertices();
      // console.log('result merge vertice', rs);

      //let newMesh = new Mesh(simpifiedGeo);

      //let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(newMesh, 90);

      //o.updateWorldMatrix(true, true);
      //outlineGeo.applyMatrix4(o.matrixWorld);
      //outlineGeo.translate(0, 1000, 0);

      // if (o.userData.type == GEOMETRY_TYPE.ROOF_PANEL || o.userData.type == GEOMETRY_TYPE.ROOF_PANEL_BASE) {
      //     outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneRight);
      //     outlineGeo = this.utils.clipOutline(outlineGeo, roofClipingPlaneLeft);
      // }

      // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
      // line.userData = {type: GEOMETRY_TYPE.SHEET_OUTLINE};
      // this.APP.scene.add( line );

      // const wireframe = new WireframeGeometry( simpifiedGeo );
      // let line2 = new LineSegments( wireframe );
      // line2.userData = {type: GEOMETRY_TYPE.SHEET_OUTLINE};
      // line2.material.depthTest = false;
      // line2.material.opacity = 0.25;
      // line2.material.transparent = true;

      // o.updateMatrixWorld();
      // line2.applyMatrix4(o.matrixWorld);

      // line2.translateY(1000);

      // this.APP.scene.add(line2);
    }
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let simplifiedGeo = new Geometry();
    let vertices = geo.getAttribute("position").array;
    for (let i = 0; i < vertices.length; i += 3) {
      simplifiedGeo.vertices.push(
        new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2])
      );
    }
    return simplifiedGeo;
  }
  public addRoof() {
    this.roofGroup = null;
    this.roofGroup = new Group();
    this.roofGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.ROOF_PATIOS,
    };

    let offsetY = this.getRoofOffsetY();
    let offsetZ = -(
      this.APP.sldExistingWidth1.currentValue / 2 +
      this.APP.sldBackOverhang.currentValue +
      this.extraOffsetZ
    );

    this.roofGroup.position.setY(offsetY);
    this.roofGroup.position.setZ(offsetZ);
    this.roofGroup.rotateX(
      this.utils.degreesToRadians(-this.APP.sltRoofPitch.currentValue)
    );

    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndPurlinManager.geo_bracket?.width || 0);
      this.roofGroup.position.setX(-offsetX);
    }

    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      let offsetX =
        this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        100 -
        (this.MANAGER.columnAndPurlinManager.geo_bracket?.width || 0);
      this.roofGroup.position.setX(offsetX);
    }

    this.scene.add(this.roofGroup);
  }
  public addRoofSheeting(offsetX: number, csgObjs: CSG[]) {
    let mesh: Mesh = new Mesh(this.geo_RoofPanel.geometry, this.material);

    let offsetSheet = 0;
    if (
      this.APP.sltRoofSheetingType.currentValue == 0 ||
      this.APP.sltRoofSheetingType.currentValue == 1
    ) {
      offsetSheet = 30;
    } else {
      offsetSheet = 12;
    }

    let scaleZ =
      (this.APP.sldSpan.currentValue +
        this.APP.sldFrontOverhang.currentValue +
        this.APP.sldBackOverhang.currentValue +
        this.APP.sldMultiSpan.currentValue) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue) /
      this.geo_RoofPanel.length;
    let offsetY = this.geo_roofPanelBase.height - offsetSheet;
    let offsetZ = 0;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    mesh.position.set(offsetX, offsetY, offsetZ);
    mesh.scale.setZ(scaleZ);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.ROOF_PANEL,
      views: views,
    };
    let cutoutCondition =
      this.APP.sltCutOut.currentValue == 1 &&
      this.APP.sltExistingType.currentValue != 0 &&
      this.APP.sldExistingLength2.currentValue > 0;
    let rakeCutCondition =
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE ||
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE;

    if (cutoutCondition || rakeCutCondition) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      mesh.position.set(
        offsetX + this.MANAGER.patiosGroup.position.x,
        offsetY,
        offsetZ + this.roofGroup.position.z
      );
      mesh.updateMatrix();

      let meshResult = mesh.clone();

      if (cutoutCondition) {
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(csgObj),
            mesh.matrix
          );
        }
      }
      if (rakeCutCondition) {
        if (this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(this.csg_rakecutLeft),
            mesh.matrix
          );
        }
        if (this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(this.csg_rakecutRight),
            mesh.matrix
          );
        }
      }

      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix));
      meshResult.applyMatrix4(matrixBefore);

      this.roofGroup.add(meshResult);
    } else {
      this.roofGroup.add(mesh);
    }
  }
  public addRoofSheetingBase(offsetX: number, isLast: boolean, csgObjs: CSG[]) {
    let mesh: Mesh = new Mesh(
      this.geo_roofPanelBase.geometry,
      MaterialManager.Instance().ROOF_BASE
    );
    let offsetZ = UI.isUpFasciaUpstandardBracket ? EXISTING_BUILDING_CONFIG.ROOF_BASE_SUBTRACT : 0;
    let scaleZ =
      (this.APP.sldSpan.currentValue +
        this.APP.sldFrontOverhang.currentValue +
        this.APP.sldBackOverhang.currentValue +
        this.APP.sldMultiSpan.currentValue -
        60) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue) /
      this.geo_roofPanelBase.length;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    mesh.position.set(offsetX, 0, offsetZ);
    mesh.scale.setZ(scaleZ);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.ROOF_PANEL_BASE,
      views: views,
    };
    if (isLast) {
      mesh.userData.last = true;
    }

    let cutoutCondition =
      this.APP.sltCutOut.currentValue == 1 &&
      this.APP.sltExistingType.currentValue != 0 &&
      this.APP.sldExistingLength2.currentValue > 0;
    let rakeCutCondition =
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE ||
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE;
    if (cutoutCondition || rakeCutCondition) {
      mesh.updateMatrix();
      let matrixBefore = mesh.matrix.clone();

      mesh.position.set(
        offsetX + this.MANAGER.patiosGroup.position.x,
        0,
        offsetZ + this.roofGroup.position.z
      );
      mesh.updateMatrix();

      let meshResult = mesh.clone();

      if (cutoutCondition) {
        for (let csgObj of csgObjs) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(csgObj),
            mesh.matrix
          );
        }
      }
      if (rakeCutCondition) {
        if (this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(this.csg_rakecutLeft),
            mesh.matrix
          );
        }
        if (this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE) {
          meshResult = CSG.toMesh(
            CSG.fromMesh(meshResult).subtract(this.csg_rakecutRight),
            mesh.matrix
          );
        }
      }

      meshResult.userData = mesh.userData;
      meshResult.material = mesh.material;
      meshResult.applyMatrix4(new Matrix4().getInverse(mesh.matrix));
      meshResult.applyMatrix4(matrixBefore);

      this.roofGroup.add(meshResult);
    } else {
      this.roofGroup.add(mesh);
    }
  }

  addBargeCappingCutRight() {
    //right
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    let offsetRight =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue -
      this.APP.sldExistingLength2.currentValue;
    let scaleZ =
      (this.APP.sldExistingWidth1.currentValue +
        this.APP.existingWallManager.geo_existingWallL2.width +
        20) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue) /
      this.geo_roofPanelBargeCappingLeft.length;
    let mesh = new Mesh(
      this.geo_roofPanelBargeCappingRight.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.setX(offsetRight);
    mesh.scale.setZ(scaleZ);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };
    this.roofGroup.add(mesh);

    //back
    let offsetLeft = offsetRight + this.APP.sldExistingLength2.currentValue;
    let scaleX =
      this.APP.sldExistingLength2.currentValue /
      this.geo_roofPanelBargeCappingFront.length;
    let offsetZ =
      this.APP.sldExistingWidth1.currentValue +
      this.APP.existingWallManager.geo_existingWallL2.width +
      10;
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingFront.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.setX(offsetLeft);
    mesh.position.setZ(offsetZ);
    mesh.rotateY(Math.PI);
    mesh.scale.setX(scaleX);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };

    this.roofGroup.add(mesh);
  }
  addBargeCappingCutLeft() {
    //left
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    let offsetLeft =
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue -
      this.APP.sldExistingLength2.currentValue;
    let scaleZ =
      (this.APP.sldExistingWidth1.currentValue +
        this.APP.existingWallManager.geo_existingWallL2.width +
        50) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue) /
      this.geo_roofPanelBargeCappingLeft.length;
    let mesh = new Mesh(
      this.geo_roofPanelBargeCappingLeft.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.setX(-offsetLeft);
    mesh.scale.setZ(scaleZ);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };
    this.roofGroup.add(mesh);

    //back
    let scaleX =
      this.APP.sldExistingLength2.currentValue /
      this.geo_roofPanelBargeCappingFront.length;
    let offsetZ =
      this.APP.sldExistingWidth1.currentValue +
      this.APP.existingWallManager.geo_existingWallL2.width +
      10;
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingFront.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.setX(-offsetLeft);
    mesh.position.setZ(offsetZ);
    mesh.rotateY(Math.PI);
    mesh.scale.setX(scaleX);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };

    this.roofGroup.add(mesh);
  }
  private addBargeCapping() {
    let mesh: Mesh;

    let extraWallWidth =
      this.APP.existingWallManager.geo_existingWallW1.width +
      EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;

    let offsetXF = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );
    let offsetXL1 =
      -(this.totalBaySize / 2 + this.APP.sldLeftOverhang.currentValue) +
      this.APP.sldExistingLength2.currentValue -
      extraWallWidth;
    let offsetXL2 = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );

    let offsetXR1 =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue -
      this.APP.sldExistingLength2.currentValue +
      this.APP.existingWallManager.geo_existingWallW1.width +
      100;
    let offsetXR2 =
      this.totalBaySize / 2 + this.APP.sldRightOverhang.currentValue;

    let offsetZL = 0;
    let offsetZR = 0;
    let offsetZFront =
      (this.APP.sldSpan.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldFrontOverhang.currentValue +
        this.APP.sldBackOverhang.currentValue) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);

    let bargeFrontLength = this.roofWidth;

    let scaleCutoutVer =
      this.utils.getHypotenuseByCos(
        this.APP.sldExistingWidth1.currentValue +
          this.geo_roofPanelBargeCappingLeft.width,
        this.APP.sltRoofPitch.currentValue
      ) / this.geo_roofPanelBargeCappingLeft.length;
    //let scaleCutoutHor = (this.APP.sldExistingLength2.currentValue - extraWallWidth) / this.geo_bargeCutoutLeftHor.length;

    let bargeLeftLength = this.roofLength;
    let bargeRightLength = this.roofLength;

    let offsetY = 0;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    //cutout
    if (
      this.APP.sltCutOut.currentValue == 1 &&
      this.APP.sldExistingLength2.currentValue > 0 &&
      this.MANAGER.cutoutCondition &&
      (this.APP.sltExistingType.currentValue == 1 ||
        this.APP.sltExistingType.currentValue == 3)
    ) {
      bargeLeftLength -= this.APP.sldExistingWidth1.currentValue;
      offsetZL += this.utils.getHypotenuseByCos(
        this.APP.sldExistingWidth1.currentValue,
        this.APP.sltRoofPitch.currentValue
      );

      let bargeCutoutHorLength =
        this.APP.sldExistingLength2.currentValue +
        this.APP.sldLeftOverhang.currentValue -
        extraWallWidth;

      if (this.APP.sldExistingWidth2.currentValue > 0) {
        offsetXL1 += extraWallWidth;
        //scaleCutoutHor = (this.APP.sldExistingLength2.currentValue ) / this.geo_bargeCutoutLeftHor.length;
        bargeCutoutHorLength += extraWallWidth;
      }

      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        offsetXL1 =
          -this.APP.sldExistingLength.currentValue / 2 -
          this.APP.existingWallManager.geo_existingWallW1.width -
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
        //scaleCutoutHor = ((this.roofWidth - (this.APP.sldExistingLength.currentValue + this.APP.existingWallManager.geo_existingWallW1.width * 2)) / 2 - EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK) / this.geo_bargeCutoutLeftHor.length;
        bargeCutoutHorLength =
          (this.roofWidth -
            (this.APP.sldExistingLength.currentValue +
              this.APP.existingWallManager.geo_existingWallW1.width * 2)) /
            2 -
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
      }

      offsetXL1 +=
        this.APP.sldLeftOverhang.currentValue -
        EXISTING_BUILDING_CONFIG.BARGE_ROOF_OFFSET_X;

      //scaleCutoutHor = bargeCutoutHorLength/this.geo_bargeCutoutLeftHor.length;

      //cutout Hor
      let offsetZ = this.utils.getHypotenuseByCos(
        this.APP.sldExistingWidth1.currentValue,
        this.APP.sltRoofPitch.currentValue
      );
      mesh = new Mesh(
        this.geo_bargeCutoutLeftHor.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXL1, offsetY, offsetZ);
      mesh.scale.setX(
        bargeCutoutHorLength / this.geo_bargeCutoutLeftHor.length
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);

      //cutout Ver
      mesh = new Mesh(
        this.geo_roofPanelBargeCappingLeft.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXL1, offsetY, 0);
      mesh.scale.setZ(scaleCutoutVer);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    }
    if (
      this.APP.sltCutOut.currentValue == 1 &&
      this.APP.sldExistingLength2.currentValue > 0 &&
      this.MANAGER.cutoutCondition &&
      (this.APP.sltExistingType.currentValue == 2 ||
        this.APP.sltExistingType.currentValue == 3)
    ) {
      bargeRightLength -= this.APP.sldExistingWidth1.currentValue;
      let bargeCutoutHorLength =
        this.APP.sldExistingLength2.currentValue +
        this.APP.sldRightOverhang.currentValue -
        extraWallWidth;
      offsetZR += this.utils.getHypotenuseByCos(
        this.APP.sldExistingWidth1.currentValue,
        this.APP.sltRoofPitch.currentValue
      );

      if (this.APP.sldExistingWidth2.currentValue > 0) {
        offsetXR1 -= extraWallWidth;
        //scaleCutoutHor = (this.APP.sldExistingLength2.currentValue ) / this.geo_bargeCutoutLeftHor.length;
        bargeCutoutHorLength += extraWallWidth;
      }

      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        offsetXR1 =
          this.APP.sldExistingLength.currentValue / 2 +
          this.APP.existingWallManager.geo_existingWallW1.width +
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
        //scaleCutoutHor = ((this.roofWidth - (this.APP.sldExistingLength.currentValue + this.APP.existingWallManager.geo_existingWallW1.width * 2)) / 2 - EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK) / this.geo_bargeCutoutLeftHor.length;
        bargeCutoutHorLength =
          (this.roofWidth -
            (this.APP.sldExistingLength.currentValue +
              this.APP.existingWallManager.geo_existingWallW1.width * 2)) /
            2 -
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
      }

      offsetXR1 -=
        this.APP.sldRightOverhang.currentValue -
        EXISTING_BUILDING_CONFIG.BARGE_ROOF_OFFSET_X;

      //cutout hor
      let offsetZ = this.utils.getHypotenuseByCos(
        this.APP.sldExistingWidth1.currentValue,
        this.APP.sltRoofPitch.currentValue
      );
      mesh = new Mesh(
        this.geo_bargeCutoutRightHor.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXR1, offsetY, offsetZ);
      mesh.scale.setX(
        bargeCutoutHorLength / this.geo_bargeCutoutLeftHor.length
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);

      //cutout ver
      mesh = new Mesh(
        this.geo_roofPanelBargeCappingRight.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetXR1, offsetY, 0);
      mesh.scale.setZ(scaleCutoutVer);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    }
    if (
      this.APP.sltCutOut.currentValue == 1 &&
      this.APP.sldExistingLength2.currentValue > 0 &&
      this.MANAGER.cutoutCondition &&
      this.APP.sltExistingType.currentValue == 3
    ) {
    }

    //Rakecut
    if (
      this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE ||
      this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP
    ) {
      offsetXF += this.APP.sldLeftCutHorizontal.currentValue;
      bargeLeftLength -= this.APP.sldLeftCutVertical.currentValue;
      bargeFrontLength -= this.APP.sldLeftCutHorizontal.currentValue;
    }
    if (
      this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE ||
      this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP
    ) {
      bargeRightLength -= this.APP.sldRightCutVertical.currentValue;
      bargeFrontLength -= this.APP.sldRightCutHorizontal.currentValue;
    }

    let scaleXF = bargeFrontLength / this.geo_roofPanelBargeCappingFront.length;
    let scaleZLeft =
      this.utils.getHypotenuseByCos(
        bargeLeftLength,
        this.APP.sltRoofPitch.currentValue
      ) / this.geo_roofPanelBargeCappingLeft.length;
    let scaleZRight =
      this.utils.getHypotenuseByCos(
        bargeRightLength,
        this.APP.sltRoofPitch.currentValue
      ) / this.geo_roofPanelBargeCappingLeft.length;

    //left
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingLeft.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.set(offsetXL2, offsetY, offsetZL);
    mesh.scale.setZ(scaleZLeft);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };

    this.roofGroup.add(mesh);

    //right
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingRight.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.set(offsetXR2, offsetY, offsetZR);
    mesh.scale.setZ(scaleZRight);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };
    this.roofGroup.add(mesh);

    //front
    mesh = new Mesh(
      this.geo_roofPanelBargeCappingFront.geometry,
      MaterialManager.Instance().BARGE
    );
    mesh.position.set(offsetXF, 0, offsetZFront + 2);
    mesh.scale.setX(scaleXF);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: this.APP.sltDripBarge.currentValue
        ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
        : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
      views: views,
    };

    this.roofGroup.add(mesh);
  }
  private addBargeAngelRakecut() {
    if (
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.ANGLE &&
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.ANGLE
    ) {
      return;
    }

    let offsetY = 0;
    let offsetZ =
      (this.APP.sldSpan.currentValue +
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldFrontOverhang.currentValue +
        this.APP.sldBackOverhang.currentValue) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      let length = Math.sqrt(
        Math.pow(this.APP.sldLeftCutVertical.currentValue, 2) +
          Math.pow(this.APP.sldLeftCutHorizontal.currentValue, 2)
      );
      let scaleZ = length / this.geo_bargeRakecutLeft.length;

      let rotY = Math.atan(
        this.APP.sldLeftCutHorizontal.currentValue /
          this.APP.sldLeftCutVertical.currentValue
      );

      let offsetX =
        -this.totalBaySize / 2 -
        this.APP.sldLeftOverhang.currentValue +
        this.APP.sldLeftCutHorizontal.currentValue;
      let mesh = new Mesh(
        this.geo_bargeRakecutLeft.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetX, offsetY, offsetZ);
      mesh.scale.setZ(scaleZ);
      mesh.rotateY(rotY);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      let length = Math.sqrt(
        Math.pow(this.APP.sldRightCutVertical.currentValue, 2) +
          Math.pow(this.APP.sldRightCutHorizontal.currentValue, 2)
      );
      let scaleZ = length / this.geo_bargeRakecutLeft.length;

      let rotY = Math.atan(
        this.APP.sldRightCutHorizontal.currentValue /
          this.APP.sldRightCutVertical.currentValue
      );

      let offsetX =
        this.totalBaySize / 2 +
        this.APP.sldRightOverhang.currentValue -
        this.APP.sldRightCutHorizontal.currentValue;
      let mesh = new Mesh(
        this.geo_bargeRakecutRight.geometry,
        MaterialManager.Instance().BARGE
      );
      mesh.position.set(offsetX, offsetY, offsetZ);
      mesh.scale.setZ(scaleZ);
      mesh.rotateY(-rotY);
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      this.roofGroup.add(mesh);
    }
  }
  private addBargeStepRakecut() {
    if (
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.STEP &&
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.STEP
    ) {
      return;
    }

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    let offsetXL =
      this.APP.dialogEditBay.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue;
    let offsetXR =
      this.APP.dialogEditBay.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue;

    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
      let offsetZ = this.utils.getHypotenuseByCos(
        this.APP.sldSpan.currentValue +
          this.APP.sldMultiSpan.currentValue +
          this.APP.sldFrontOverhang.currentValue +
          this.APP.sldBackOverhang.currentValue -
          this.APP.sldLeftCutVertical.currentValue,
        this.APP.sltRoofPitch.currentValue
      );
      let scaleZ =
        (this.APP.sldLeftCutVertical.currentValue +
          this.geo_roofPanelBargeCappingLeft.width) /
        this.geo_roofPanelBargeCappingLeft.length;
      let scaleX =
        this.APP.sldLeftCutHorizontal.currentValue /
        this.geo_roofPanelBargeCappingLeft.length;

      let bargeVer = new Mesh(
        this.geo_roofPanelBargeCappingLeft.geometry,
        MaterialManager.Instance().BARGE
      );
      bargeVer.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      bargeVer.position.set(
        -offsetXL + this.APP.sldLeftCutHorizontal.currentValue,
        0,
        offsetZ - this.geo_roofPanelBargeCappingLeft.width
      );
      bargeVer.scale.set(1, 1, scaleZ);

      let bargeHor = new Mesh(
        this.geo_roofPanelBargeCappingLeft.geometry,
        MaterialManager.Instance().BARGE
      );
      bargeHor.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      bargeHor.position.set(-offsetXL, 0, offsetZ);
      bargeHor.scale.set(1, 1, scaleX);
      bargeHor.rotateY(Math.PI / 2);

      this.roofGroup.add(bargeVer, bargeHor);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      let offsetZ = this.utils.getHypotenuseByCos(
        this.APP.sldSpan.currentValue +
          this.APP.sldMultiSpan.currentValue +
          this.APP.sldFrontOverhang.currentValue +
          this.APP.sldBackOverhang.currentValue -
          this.APP.sldRightCutVertical.currentValue,
        this.APP.sltRoofPitch.currentValue
      );
      let scaleZ =
        (this.APP.sldRightCutVertical.currentValue +
          this.geo_roofPanelBargeCappingLeft.width) /
        this.geo_roofPanelBargeCappingLeft.length;
      let scaleX =
        this.APP.sldRightCutHorizontal.currentValue /
        this.geo_roofPanelBargeCappingLeft.length;

      let bargeVer = new Mesh(
        this.geo_roofPanelBargeCappingRight.geometry,
        MaterialManager.Instance().BARGE
      );
      bargeVer.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      bargeVer.position.set(
        offsetXR - this.APP.sldRightCutHorizontal.currentValue,
        0,
        offsetZ - this.geo_roofPanelBargeCappingLeft.width
      );
      bargeVer.scale.set(1, 1, scaleZ);

      let bargeHor = new Mesh(
        this.geo_roofPanelBargeCappingRight.geometry,
        MaterialManager.Instance().BARGE
      );
      bargeHor.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: this.APP.sltDripBarge.currentValue
          ? GEOMETRY_TYPE.ROOF_PANEL_DRIP_BARGE_CAPPING
          : GEOMETRY_TYPE.ROOF_PANEL_BARGE_CAPPING,
        views: views,
      };
      bargeHor.position.set(offsetXR, 0, offsetZ);
      bargeHor.scale.set(1, 1, scaleX);
      bargeHor.rotateY(-Math.PI / 2);

      this.roofGroup.add(bargeVer, bargeHor);
    }
  }
  public addGutter() {
    let offsetX = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );
    let offsetZ = -100;
    let offsetY = this.geo_roofPanelBase.height - 25;
    let gutterLength =
      this.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    let extraWallWidth =
      this.APP.existingWallManager.geo_existingWallW1.width +
      EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;

    //cutout
    if (
      this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
      this.MANAGER.cutoutCondition
    ) {
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
        if (this.APP.sldExistingWidth2.currentValue > 0) {
          offsetX += this.APP.sldExistingLength2.currentValue;
          gutterLength -= this.APP.sldExistingLength2.currentValue;
        } else {
          offsetX += this.APP.sldExistingLength2.currentValue - extraWallWidth;
          gutterLength -=
            this.APP.sldExistingLength2.currentValue - extraWallWidth;
        }
        offsetX += this.APP.sldLeftOverhang.currentValue;
        gutterLength -= this.APP.sldLeftOverhang.currentValue;
      } else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
        if (this.APP.sldExistingWidth2.currentValue > 0) {
          gutterLength -= this.APP.sldExistingLength2.currentValue;
        } else {
          gutterLength -=
            this.APP.sldExistingLength2.currentValue - extraWallWidth;
        }
        //offsetX -= this.APP.sldRightOverhang.currentValue;
        gutterLength -= this.APP.sldRightOverhang.currentValue;
      } else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        offsetX +=
          this.cutoutExistingBothLength -
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
        gutterLength =
          this.APP.sldExistingLength.currentValue + extraWallWidth * 2;
      }
    }

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.CONTINOUS },
    ];

    //back
    let gutterGroup = this.utils.createGutterGroup(
      this.geo_gutterFront,
      this.geo_gutterCap,
      gutterLength,
      new Vector3(offsetX, offsetY, offsetZ),
      new Vector3(
        this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue),
        0,
        0
      ),
      views,
      1,
      true,
      true
    );

    this.addDownPipe(gutterGroup, gutterLength);

    this.roofGroup.add(gutterGroup);
  }
  private addDownPipe(gutterGroup: Group, gutterLength: number) {
    let meshPipe = new Mesh(
      this.geo_downPipe.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshPipe.userData = { type: GEOMETRY_TYPE.DOWNPIPE_NOZZLE };
    meshPipe.scale.setY(100 / this.geo_downPipe.height);
    meshPipe.position.set(100, -190, this.geo_downPipe.width / 2 + 20);

    gutterGroup.add(meshPipe);
  }
  public addZFlashing() {
    let offsetX = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );
    let offsetY = this.geo_roofPanelBase.height - 25;
    const offsetZ = 0;

    let scaleX =
      (this.totalBaySize +
        this.APP.sldLeftOverhang.currentValue +
        this.APP.sldRightOverhang.currentValue) /
      this.zFlashingGeo.length;
    let flashingLength =
      this.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    let extraWallWidth =
      this.APP.existingWallManager.geo_existingWallW1.width +
      EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;

    //cutout
    if (
      this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES &&
      this.MANAGER.cutoutCondition
    ) {
      if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
        if (this.APP.sldExistingWidth2.currentValue > 0) {
          offsetX += this.APP.sldExistingLength2.currentValue;
          flashingLength -= this.APP.sldExistingLength2.currentValue;
        } else {
          offsetX += this.APP.sldExistingLength2.currentValue - extraWallWidth;
          flashingLength -=
            this.APP.sldExistingLength2.currentValue - extraWallWidth;
        }

        offsetX += this.APP.sldLeftOverhang.currentValue;
        flashingLength -= this.APP.sldLeftOverhang.currentValue;
      } else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
        if (this.APP.sldExistingWidth2.currentValue > 0) {
          flashingLength -= this.APP.sldExistingLength2.currentValue;
        } else {
          flashingLength -=
            this.APP.sldExistingLength2.currentValue - extraWallWidth;
        }
        //offsetX += this.APP.sldRightOverhang.currentValue;
        flashingLength -= this.APP.sldRightOverhang.currentValue;
      } else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.BOTH) {
        offsetX +=
          this.cutoutExistingBothLength -
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
        flashingLength =
          this.APP.sldExistingLength.currentValue +
          this.APP.existingWallManager.geo_existingWallW1.width * 2 +
          EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK * 2;
      }
    }

    // if (this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE) {
    //     offsetX += (this.APP.sldLeftCutHorizontal.currentValue);
    //     flashingLength -= this.APP.sldLeftCutHorizontal.currentValue;
    // }
    // if(this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE){
    //     flashingLength -= this.APP.sldRightCutHorizontal.currentValue;
    // }

    scaleX = flashingLength / this.zFlashingGeo.length;

    let mesh = new Mesh(
      this.zFlashingGeo.geometry,
      MaterialManager.Instance().ZFLASHING
    );
    mesh.position.set(offsetX, offsetY, offsetZ);
    mesh.scale.set(scaleX, 1, 1);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.ZFLASHING,
      scale: mesh.scale,
    };

    this.roofGroup.add(mesh);
  }
  private totalHeightFromEaveHeightToTopOfExistingRoof(){
    const height = 
      this.geometryManager.EAVE.EAVE.height 
      + UI.fasciaDepth 
      + UI.eaveWidth * this.utils.tan(UI.existingRoofPitch) 
      + this.geometryManager.EXISTING_ROOF.EXISTING_ROOF.height / this.utils.cos(UI.existingRoofPitch)

    return height
  }
  private totalHeightFromEaveHeightToTopOfFlyoverBraket(){
    const height = this.totalHeightFromEaveHeightToTopOfExistingRoof() + UI.braketHeight - FIT_FLYOVER_BRAKET_ON_ROOF;

    return height
  }
  public getRoofOffsetY() {
    return (
      this.APP.sldExistingWallHeight.currentValue +
      this.totalHeightFromEaveHeightToTopOfFlyoverBraket() +
      this.geometryManager.getBeam().height -
      this.utils.tan(this.APP.sltRoofPitch.currentValue) *
        this.APP.sldBackOverhang.currentValue
    );
  }

  public destroy(): void {
    this.unregisterEvent();
  }

  private registerEvent(): void {
    this.eventHandleId = this.uiChangedDefer.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldSpan,
      this.APP.sldExistingWallHeight,
      this.APP.sldEaveWidth,
      this.APP.sldExistingWidth1,

      this.APP.sldFrontOverhang,
      this.APP.sldBackOverhang,
      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,

      this.APP.sltRoofPitch,
      this.APP.sldMultiSpan,
      this.APP.sldFlyOverBracketHeight,

      this.APP.dialogEditBay,
      this.APP.sltBeamType,
      this.APP.sltBeamSize,

      this.APP.sltColourBarge,
      this.APP.sltColourGutter,
      this.APP.sltColourDownpipe,
      this.APP.sltColourRoof,
    ];
    //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));

    this.controlsToRegisterEvent2 = [
      this.APP.sltRoofSheetingType,
      this.APP.sltRoofThickness,
      this.APP.sltBargeType,
      this.APP.sltDripBarge,
      this.APP.sltGutterType,
    ];
    this.controlsToRegisterEvent2.forEach((c) =>
      c.addAction(this.objectSizeChangedHandle)
    );
  }
  private unregisterEvent(): void {
    // this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    // this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach((c) =>
      c.removeAction(this.objectSizeChangedHandle)
    );
    this.controlsToRegisterEvent2 = undefined;
  }
  private objectSizeChanged(preVal: number, curVal: number) {
    this.optimize().then(() => {
      this.load();
    });
  }
  private addCutout() {
    if (this.APP.sltCutOut.currentValue == 1) {
      this.APP.scene.remove(
        ...this.APP.scene.children.filter((x) => x.userData.type == "CUTOUT")
      );

      let offsetX =
        this.APP.sldExistingLength.currentValue / 2 +
        this.APP.existingWallManager.geo_existingWallL2.width +
        100;
      let offsetY = 0;
      let offsetZ =
        this.APP.sldExistingWidth1.currentValue / 2 -
        this.APP.existingWallManager.geo_existingWallL2.width -
        this.APP.sldBackOverhang.currentValue;

      let scaleX = (this.APP.sldExistingLength2.currentValue + 5000) / 1000;
      if (scaleX == 0) scaleX = 1;
      let scaleY = (this.APP.sldExistingWallHeight.currentValue + 5000) / 1000;
      let scaleZ =
        (this.APP.sldExistingWidth1.currentValue +
          this.APP.sldBackOverhang.currentValue +
          5000) /
        1000;
      if (scaleZ == 0) scaleZ = 1;

      // if(this.APP.sltExistingType.currentValue == 3){
      //     offsetX -= 100;
      // }

      if (
        this.APP.sltExistingType.currentValue == 1 ||
        this.APP.sltExistingType.currentValue == 3
      ) {
        this.mesh_CutoutLeft = new Mesh(
          this.geo_cutoutLeft,
          new MeshLambertMaterial({
            color: "red",
            transparent: true,
            opacity: 0.5,
          })
        );
        this.mesh_CutoutLeft.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: "CUTOUT",
        };

        this.mesh_CutoutLeft.position.set(-offsetX, offsetY, offsetZ);
        this.mesh_CutoutLeft.scale.set(scaleX, scaleY, scaleZ);

        //this.APP.scene.add(this.mesh_CutoutLeft);

        this.mesh_CutoutLeft.updateMatrix();
        this.csg_cutoutLeft = CSG.fromMesh(this.mesh_CutoutLeft);
      }
      if (
        this.APP.sltExistingType.currentValue == 2 ||
        this.APP.sltExistingType.currentValue == 3
      ) {
        this.mesh_CutoutRight = new Mesh(
          this.geo_cutoutRight,
          new MeshLambertMaterial({
            color: "red",
            transparent: true,
            opacity: 0.5,
          })
        );
        this.mesh_CutoutRight.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: "CUTOUT",
        };

        this.mesh_CutoutRight.position.set(offsetX, offsetY, offsetZ);
        this.mesh_CutoutRight.scale.set(scaleX, scaleY, scaleZ);

        //this.APP.scene.add(this.mesh_CutoutRight);

        this.mesh_CutoutRight.updateMatrix();
        this.csg_cutoutRight = CSG.fromMesh(this.mesh_CutoutRight);
      }
    }
  }
  private addAngelRakeCut() {
    let offsetXL =
      -this.totalBaySize / 2 -
      this.APP.sldLeftOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let offsetXR =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let offsetY = -100;

    let offsetZ =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue -
      this.extraOffsetZ -
      this.APP.sldExistingWidth1.currentValue / 2;

    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      let angle = Math.atan(
        this.APP.sldLeftCutHorizontal.currentValue /
          this.APP.sldLeftCutVertical.currentValue
      );
      this.mesh_rakecutLeft = new Mesh(
        this.geo_rakecutLeft,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutLeft.position.set(
        offsetXL,
        offsetY,
        offsetZ - this.APP.sldLeftCutVertical.currentValue
      );
      this.mesh_rakecutLeft.scale.set(5, 5, 5);
      this.mesh_rakecutLeft.rotateY(angle);

      //this.APP.scene.add(this.mesh_rakecutLeft);

      this.mesh_rakecutLeft.updateMatrix();
      this.csg_rakecutLeft = CSG.fromMesh(this.mesh_rakecutLeft);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      let angle = Math.atan(
        this.APP.sldRightCutHorizontal.currentValue /
          this.APP.sldRightCutVertical.currentValue
      );
      this.mesh_rakecutRight = new Mesh(
        this.geo_rakecutRight,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutRight.position.set(
        offsetXR,
        offsetY,
        offsetZ - this.APP.sldRightCutVertical.currentValue
      );
      this.mesh_rakecutRight.scale.set(5, 5, 5);
      this.mesh_rakecutRight.rotateY(-angle);

      //this.APP.scene.add(this.mesh_rakecutRight);

      this.mesh_rakecutRight.updateMatrix();
      this.csg_rakecutRight = CSG.fromMesh(this.mesh_rakecutRight);
    }
  }
  private addStepRakecut() {
    let offsetXL =
      -this.totalBaySize / 2 -
      this.APP.sldLeftOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let offsetXR =
      this.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue +
      this.MANAGER.patiosGroup.position.x;
    let offsetY = -100; //this.APP.sldExistingWallHeight.currentValue;

    let offsetZ =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue -
      this.extraOffsetZ -
      this.APP.sldExistingWidth1.currentValue / 2;

    if (this.APP.sltLeftCutType.currentValue == RAKECUT_TYPE.STEP) {
      this.mesh_rakecutLeft = new Mesh(
        this.geo_rakecutLeft,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutLeft.position.set(
        offsetXL + this.APP.sldLeftCutHorizontal.currentValue,
        offsetY,
        offsetZ - this.APP.sldLeftCutVertical.currentValue
      );
      this.mesh_rakecutLeft.scale.set(5, 5, 5);

      //this.APP.scene.add(this.mesh_rakecutLeft);

      this.mesh_rakecutLeft.updateMatrix();
      this.csg_rakecutLeft = CSG.fromMesh(this.mesh_rakecutLeft);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      this.mesh_rakecutRight = new Mesh(
        this.geo_rakecutRight,
        new MeshLambertMaterial({
          color: "red",
          transparent: true,
          opacity: 0.5,
        })
      );
      this.mesh_rakecutRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: "RAKECUT",
      };

      this.mesh_rakecutRight.position.set(
        offsetXR - this.APP.sldRightCutHorizontal.currentValue,
        offsetY,
        offsetZ - this.APP.sldRightCutVertical.currentValue
      );
      this.mesh_rakecutRight.scale.set(5, 5, 5);

      //this.APP.scene.add(this.mesh_rakecutRight);

      this.mesh_rakecutRight.updateMatrix();
      this.csg_rakecutRight = CSG.fromMesh(this.mesh_rakecutRight);
    }
  }
  uiChangedDefer(previousValue: number, currentValue: number) {
    if (this.APP.sltCutOut.currentValue == 1) {
      if (this.deferHandle) {
        clearTimeout(this.deferHandle);
      }
      this.deferHandle = setTimeout(() => {
        this.load();
      }, this.deferTimeout);
    } else {
      this.load();
    }
  }
}
