import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { User } from "../models";

@Injectable({ providedIn: "root" })
export class JobService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<User[]>(`${environment.apiDbUrl}/users`);
  }
  createJob(info: any) {
    return this.http.post<any>(`${environment.apiDbUrl}/job/add`, info).pipe(
      map((newJob) => {
        return newJob;
      })
    );
  }
  UpdateJob(id: any, info: any) {
    return this.http
      .put<any>(`${environment.apiDbUrl}/job/edit/${id}`, info)
      .pipe(
        map((newJob) => {
          return newJob;
        })
      );
  }
  // getBOM(id: any) {
  //     return this.http.get<any>(`${environment.apiDbUrl}/job-bom?id=${id}`);
  // }
  getBOMById(id: any) {
    return this.http.get<any>(
      `${environment.apiDbUrl}/job/get-bom-by-id?id=${id}`
    );
  }
  calcBOMByPara(info: any) {
    return this.http
      .post<any>(`${environment.apiDbUrl}/job/calc-bom-by-para`, info)
      .pipe(
        map((jobInfo) => {
          return jobInfo;
        })
      );
  }

  calcRibSpanInfo(info: any) {
    return this.http
      .post<any>(`${environment.apiDbUrl}/job/calc-rib-span-info`, info)
      .pipe(
        map((jobInfo) => {
          return jobInfo;
        })
      );
  }

  checkData(info: any) {
    return this.http
      .post<any>(`${environment.apiDbUrl}/job/check-data`, info)
      .pipe(
        map((info) => {
          return info;
        })
      );
  }
  calcQuoteInfo(info: any) {
    return this.http
      .post<any>(`${environment.apiDbUrl}/job/calc-quote-info`, info)
      .pipe(
        map((quoteInfo) => {
          return quoteInfo;
        })
      );
  }
  updateJobFiles(id: any, info: any) {
    return this.http
      .put<any>(`${environment.apiDbUrl}/job/edit-files/${id}`, info)
      .pipe(
        map((rs) => {
          return rs;
        })
      );
  }
  getJobByUserId(info: any) {
    return this.http
      .post<any>(`${environment.apiDbUrl}/job/get-by-user-id`, info)
      .pipe(
        map((jobInfo) => {
          return jobInfo;
        })
      );
  }
  genJobNo(info: any) {
    return this.http
      .post<any>(`${environment.apiDbUrl}/job/gen-job-no`, info)
      .pipe(
        map((jobInfo) => {
          return jobInfo;
        })
      );
  }
  genJobNoRevised(info: any) {
    return this.http
      .post<any>(`${environment.apiDbUrl}/job/gen-job-no-revised`, info)
      .pipe(
        map((jobInfo) => {
          return jobInfo;
        })
      );
  }
  getById(id: any) {
    return this.http.get<any>(`${environment.apiDbUrl}/job/get-by-id?id=${id}`);
  }
  copyOrRevisedById(id: any, type: any) {
    return this.http.get<any>(
      `${environment.apiDbUrl}/job/copy-revised-by-id?id=${id}&type=${type}`
    );
  }
}
